export default [
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.100014,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 163.2,
    "out:Total Energy Use Post PV": 163.2,
    "out:Primary Energy": 119.53001,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 55.056178,
    "out:Total CO2/m2": 55.056178,
    "out:Total CO2 (tons)": 115012.3874,
    "out:Klimatpaverkan":0.001* 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 34584.28,
    "out:Running Cost over RSP/MSEK": 6.76084,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 2,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 100,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 217464.9892,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.1,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 0,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": 0
  },
  {
    "ID": "Z00000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.640923,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 159.2,
    "out:Total Energy Use Post PV": 159.2,
    "out:Primary Energy": 116.408646,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 53.616242,
    "out:Total CO2/m2": 53.616242,
    "out:Total CO2 (tons)": 112004.3603,
    "out:Klimatpaverkan":0.001* -3008.02713,
    "out:Initial Cost/MSEK": 0.052225013,
    "out:Running cost/(Apt SEK y)": 33705.84,
    "out:Running Cost over RSP/MSEK": 6.58897,
    "out:LCP/MSEK": 0.119645,
    "out:ROI% old": 47.013597,
    "out:Payback discounted": 3,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 2.5,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 42,
    "out:Return/kSEK/y": 22,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 9315.044807,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 8476.69,
    "out:DH kr savings": 8476.690775,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 208149.9444,
    "out:% savings (space heating)": 4.283469,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.64,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 52225.0125,
    "out:Seasonal Variation ROI (%)": 16,
    "out:Seasonal Variation Payback": 6.25
  },
  {
    "ID": "Z00000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.100014,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 157.2,
    "out:Primary Energy": 116.90501,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.449976,
    "out:CO2 Operational/m2": 52.896274,
    "out:Total CO2/m2": 53.34625,
    "out:Total CO2 (tons)": 111440.3469,
    "out:Klimatpaverkan":0.001* -3572.040573,
    "out:Initial Cost/MSEK": 0.11384,
    "out:Running cost/(Apt SEK y)": 33266.6,
    "out:Running Cost over RSP/MSEK": 6.50304,
    "out:LCP/MSEK": 0.14396,
    "out:ROI% old": 32.35117,
    "out:Payback discounted": 4,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 3.8,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 29,
    "out:Return/kSEK/y": 33,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 13536.72372,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 12318.42,
    "out:DH kr savings": 12318.41858,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 217464.9892,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.1,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 113840,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909
  },
  {
    "ID": "Z00000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.640923,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 152.2,
    "out:Primary Energy": 113.783646,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.449976,
    "out:CO2 Operational/m2": 51.096354,
    "out:Total CO2/m2": 51.54633,
    "out:Total CO2 (tons)": 107680.313,
    "out:Klimatpaverkan":0.001* -7332.074486,
    "out:Initial Cost/MSEK": 0.166065013,
    "out:Running cost/(Apt SEK y)": 32168.52,
    "out:Running Cost over RSP/MSEK": 6.28821,
    "out:LCP/MSEK": 0.306565,
    "out:ROI% old": 40.657915,
    "out:Payback discounted": 3,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 36,
    "out:Return/kSEK/y": 60,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 22851.76853,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 20795.11,
    "out:DH kr savings": 20795.10936,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 208149.9444,
    "out:% savings (space heating)": 4.283469,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.64,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 166065.0125,
    "out:Seasonal Variation ROI (%)": 13,
    "out:Seasonal Variation Payback": 7.692308
  },
  {
    "ID": "Z00000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.100014,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 163.2,
    "out:Total Energy Use Post PV": 158.305876,
    "out:Primary Energy": 110.720587,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.88261,
    "out:CO2 Operational/m2": 34.044189,
    "out:Total CO2/m2": 43.926799,
    "out:Total CO2 (tons)": 91763.10833,
    "out:Klimatpaverkan":0.001* -23249.27912,
    "out:Initial Cost/MSEK": 0.347627861,
    "out:Running cost/(Apt SEK y)": 33457.84,
    "out:Running Cost over RSP/MSEK": 6.54648,
    "out:LCP/MSEK": -0.133268,
    "out:ROI% old": 8.809092,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 3.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 8,
    "out:Return/kSEK/y": 28,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 28161.17,
    "out:DH kr savings": 0,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 217464.9892,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.1,
    "out:Etvv": 25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 347627.8606,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5
  },
  {
    "ID": "Z00000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.640923,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 159.2,
    "out:Total Energy Use Post PV": 154.305876,
    "out:Primary Energy": 107.599223,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.88261,
    "out:CO2 Operational/m2": 32.604253,
    "out:Total CO2/m2": 42.486863,
    "out:Total CO2 (tons)": 88755.08119,
    "out:Klimatpaverkan":0.001* -26257.30625,
    "out:Initial Cost/MSEK": 0.399852873,
    "out:Running cost/(Apt SEK y)": 32579.36,
    "out:Running Cost over RSP/MSEK": 6.37461,
    "out:LCP/MSEK": -0.013623,
    "out:ROI% old": 13.799004,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 5.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 13,
    "out:Return/kSEK/y": 50,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 9315.044807,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 36637.86,
    "out:DH kr savings": 8476.690775,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 208149.9444,
    "out:% savings (space heating)": 4.283469,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.64,
    "out:Etvv": 25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 399852.8731,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111
  },
  {
    "ID": "Z00000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.100014,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 152.305876,
    "out:Primary Energy": 108.095587,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.332586,
    "out:CO2 Operational/m2": 31.884285,
    "out:Total CO2/m2": 42.216871,
    "out:Total CO2 (tons)": 88191.06775,
    "out:Klimatpaverkan":0.001* -26821.31969,
    "out:Initial Cost/MSEK": 0.461467861,
    "out:Running cost/(Apt SEK y)": 32140.16,
    "out:Running Cost over RSP/MSEK": 6.28868,
    "out:LCP/MSEK": 0.010692,
    "out:ROI% old": 14.616712,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 13,
    "out:Return/kSEK/y": 61,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 13536.72372,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 40479.59,
    "out:DH kr savings": 12318.41858,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 217464.9892,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.1,
    "out:Etvv": 21.25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 461467.8606,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111
  },
  {
    "ID": "Z00000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.640923,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 147.305876,
    "out:Primary Energy": 104.974223,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.332586,
    "out:CO2 Operational/m2": 30.084365,
    "out:Total CO2/m2": 40.416951,
    "out:Total CO2 (tons)": 84431.03384,
    "out:Klimatpaverkan":0.001* -30581.35361,
    "out:Initial Cost/MSEK": 0.513692873,
    "out:Running cost/(Apt SEK y)": 31042.08,
    "out:Running Cost over RSP/MSEK": 6.07385,
    "out:LCP/MSEK": 0.173297,
    "out:ROI% old": 19.105079,
    "out:Payback discounted": 6,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 10.9,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 17,
    "out:Return/kSEK/y": 89,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 22851.76853,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 48956.28,
    "out:DH kr savings": 20795.10936,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 208149.9444,
    "out:% savings (space heating)": 4.283469,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.64,
    "out:Etvv": 21.25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 513692.8731,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10
  },
  {
    "ID": "Z00000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.100014,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 163.2,
    "out:Total Energy Use Post PV": 157.330546,
    "out:Primary Energy": 108.964993,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.76522,
    "out:CO2 Operational/m2": -6.591269,
    "out:Total CO2/m2": 13.173951,
    "out:Total CO2 (tons)": 27520.3912,
    "out:Klimatpaverkan":0.001* -87491.99624,
    "out:Initial Cost/MSEK": 0.695255704,
    "out:Running cost/(Apt SEK y)": 32470.56,
    "out:Running Cost over RSP/MSEK": 6.35876,
    "out:LCP/MSEK": -0.293176,
    "out:ROI% old": 8.261709,
    "out:Payback discounted": 15,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 3.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 8,
    "out:Return/kSEK/y": 53,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 52843.14,
    "out:DH kr savings": 0,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 217464.9892,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.1,
    "out:Etvv": 25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 695255.7037,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5
  },
  {
    "ID": "Z00000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.640923,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 159.2,
    "out:Total Energy Use Post PV": 153.330546,
    "out:Primary Energy": 105.843629,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.76522,
    "out:CO2 Operational/m2": -8.031205,
    "out:Total CO2/m2": 11.734015,
    "out:Total CO2 (tons)": 24512.36407,
    "out:Klimatpaverkan":0.001* -90500.02337,
    "out:Initial Cost/MSEK": 0.747480716,
    "out:Running cost/(Apt SEK y)": 31592.08,
    "out:Running Cost over RSP/MSEK": 6.1869,
    "out:LCP/MSEK": -0.173541,
    "out:ROI% old": 10.969036,
    "out:Payback discounted": 11,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 10,
    "out:Return/kSEK/y": 75,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 9315.044807,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 61319.83,
    "out:DH kr savings": 8476.690775,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 208149.9444,
    "out:% savings (space heating)": 4.283469,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.64,
    "out:Etvv": 25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 747480.7162,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5
  },
  {
    "ID": "Z00000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.100014,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 151.330546,
    "out:Primary Energy": 106.339993,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.215196,
    "out:CO2 Operational/m2": -8.751173,
    "out:Total CO2/m2": 11.464023,
    "out:Total CO2 (tons)": 23948.35063,
    "out:Klimatpaverkan":0.001* -91064.03682,
    "out:Initial Cost/MSEK": 0.809095704,
    "out:Running cost/(Apt SEK y)": 31152.88,
    "out:Running Cost over RSP/MSEK": 6.10097,
    "out:LCP/MSEK": -0.149226,
    "out:ROI% old": 11.650926,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 7.9,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 11,
    "out:Return/kSEK/y": 86,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 13536.72372,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 65161.56,
    "out:DH kr savings": 12318.41858,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 217464.9892,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.1,
    "out:Etvv": 21.25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 809095.7037,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5
  },
  {
    "ID": "Z00000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.640923,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 146.330546,
    "out:Primary Energy": 103.218629,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.215196,
    "out:CO2 Operational/m2": -10.551093,
    "out:Total CO2/m2": 9.664103,
    "out:Total CO2 (tons)": 20188.31671,
    "out:Klimatpaverkan":0.001* -94824.07073,
    "out:Initial Cost/MSEK": 0.861320716,
    "out:Running cost/(Apt SEK y)": 30054.8,
    "out:Running Cost over RSP/MSEK": 5.88613,
    "out:LCP/MSEK": 0.013389,
    "out:ROI% old": 14.507787,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 11.6,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 13,
    "out:Return/kSEK/y": 113,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 22851.76853,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 73638.25,
    "out:DH kr savings": 20795.10936,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 208149.9444,
    "out:% savings (space heating)": 4.283469,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.64,
    "out:Etvv": 21.25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 861320.7162,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111
  },
  {
    "ID": "Z00000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.100014,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 155.2,
    "out:Primary Energy": 116.03001,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 52.176306,
    "out:Total CO2/m2": 52.176306,
    "out:Total CO2 (tons)": 108996.3332,
    "out:Klimatpaverkan":0.001* -6016.054261,
    "out:Initial Cost/MSEK": 0.065982813,
    "out:Running cost/(Apt SEK y)": 32827.36,
    "out:Running Cost over RSP/MSEK": 6.4171,
    "out:LCP/MSEK": 0.277757,
    "out:ROI% old": 74.421978,
    "out:Payback discounted": 2,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 5.2,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 67,
    "out:Return/kSEK/y": 44,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 25,
    "out:Payback Time": 4,
    "out:DH kWh savings": 18048.96496,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 16424.56,
    "out:DH kr savings": 16424.55811,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 217464.9892,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.1,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 65982.8125,
    "out:Seasonal Variation ROI (%)": 25,
    "out:Seasonal Variation Payback": 4
  },
  {
    "ID": "Z00000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.640923,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 150.2,
    "out:Primary Energy": 112.908646,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 50.376386,
    "out:Total CO2/m2": 50.376386,
    "out:Total CO2 (tons)": 105236.2993,
    "out:Klimatpaverkan":0.001* -9776.088174,
    "out:Initial Cost/MSEK": 0.118207825,
    "out:Running cost/(Apt SEK y)": 31729.32,
    "out:Running Cost over RSP/MSEK": 6.20227,
    "out:LCP/MSEK": 0.440362,
    "out:ROI% old": 67.504596,
    "out:Payback discounted": 2,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 8.7,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 60,
    "out:Return/kSEK/y": 71,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 21,
    "out:Payback Time": 4.761905,
    "out:DH kWh savings": 27364.00977,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 24901.25,
    "out:DH kr savings": 24901.24889,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 208149.9444,
    "out:% savings (space heating)": 4.283469,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.64,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 118207.825,
    "out:Seasonal Variation ROI (%)": 21,
    "out:Seasonal Variation Payback": 4.761905
  },
  {
    "ID": "Z00000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.100014,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 149.2,
    "out:Primary Energy": 113.93001,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.449976,
    "out:CO2 Operational/m2": 50.016402,
    "out:Total CO2/m2": 50.466378,
    "out:Total CO2 (tons)": 105424.2926,
    "out:Klimatpaverkan":0.001* -9588.094834,
    "out:Initial Cost/MSEK": 0.179822812,
    "out:Running cost/(Apt SEK y)": 31509.68,
    "out:Running Cost over RSP/MSEK": 6.15931,
    "out:LCP/MSEK": 0.421707,
    "out:ROI% old": 47.787517,
    "out:Payback discounted": 3,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 9.4,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 43,
    "out:Return/kSEK/y": 77,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 28878.34394,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 26279.29,
    "out:DH kr savings": 26279.29298,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 217464.9892,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.1,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 179822.8125,
    "out:Seasonal Variation ROI (%)": 15,
    "out:Seasonal Variation Payback": 6.666667
  },
  {
    "ID": "Z00000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.640923,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 145.2,
    "out:Primary Energy": 110.808646,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.449976,
    "out:CO2 Operational/m2": 48.576466,
    "out:Total CO2/m2": 49.026442,
    "out:Total CO2 (tons)": 102416.2655,
    "out:Klimatpaverkan":0.001* -12596.12197,
    "out:Initial Cost/MSEK": 0.232047825,
    "out:Running cost/(Apt SEK y)": 30631.24,
    "out:Running Cost over RSP/MSEK": 5.98744,
    "out:LCP/MSEK": 0.541352,
    "out:ROI% old": 47.613338,
    "out:Payback discounted": 3,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 12.4,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 43,
    "out:Return/kSEK/y": 99,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 38193.38874,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 34755.98,
    "out:DH kr savings": 34755.98376,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 208149.9444,
    "out:% savings (space heating)": 4.283469,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.64,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 232047.825,
    "out:Seasonal Variation ROI (%)": 15,
    "out:Seasonal Variation Payback": 6.666667
  },
  {
    "ID": "Z00000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.100014,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 150.305876,
    "out:Primary Energy": 107.220587,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.88261,
    "out:CO2 Operational/m2": 31.164317,
    "out:Total CO2/m2": 41.046927,
    "out:Total CO2 (tons)": 85747.05406,
    "out:Klimatpaverkan":0.001* -29265.33338,
    "out:Initial Cost/MSEK": 0.413610673,
    "out:Running cost/(Apt SEK y)": 31700.92,
    "out:Running Cost over RSP/MSEK": 6.20275,
    "out:LCP/MSEK": 0.144479,
    "out:ROI% old": 19.275891,
    "out:Payback discounted": 6,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 8.7,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 17,
    "out:Return/kSEK/y": 72,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 18048.96496,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 44585.73,
    "out:DH kr savings": 16424.55811,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 217464.9892,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.1,
    "out:Etvv": 20,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 413610.6731,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909
  },
  {
    "ID": "Z00000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.640923,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 145.305876,
    "out:Primary Energy": 104.099223,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.88261,
    "out:CO2 Operational/m2": 29.364397,
    "out:Total CO2/m2": 39.247007,
    "out:Total CO2 (tons)": 81987.02015,
    "out:Klimatpaverkan":0.001* -33025.36729,
    "out:Initial Cost/MSEK": 0.465835686,
    "out:Running cost/(Apt SEK y)": 30602.88,
    "out:Running Cost over RSP/MSEK": 5.98791,
    "out:LCP/MSEK": 0.307094,
    "out:ROI% old": 23.70333,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 12.4,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 21,
    "out:Return/kSEK/y": 100,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 27364.00977,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 53062.42,
    "out:DH kr savings": 24901.24889,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 208149.9444,
    "out:% savings (space heating)": 4.283469,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.64,
    "out:Etvv": 20,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 465835.6856,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909
  },
  {
    "ID": "Z00000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.100014,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 144.305876,
    "out:Primary Energy": 105.120587,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.332586,
    "out:CO2 Operational/m2": 29.004413,
    "out:Total CO2/m2": 39.336999,
    "out:Total CO2 (tons)": 82175.01349,
    "out:Klimatpaverkan":0.001* -32837.37395,
    "out:Initial Cost/MSEK": 0.527450673,
    "out:Running cost/(Apt SEK y)": 30383.24,
    "out:Running Cost over RSP/MSEK": 5.94495,
    "out:LCP/MSEK": 0.288439,
    "out:ROI% old": 22.097936,
    "out:Payback discounted": 6,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 13.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 20,
    "out:Return/kSEK/y": 105,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 28878.34394,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 54440.47,
    "out:DH kr savings": 26279.29298,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 217464.9892,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.1,
    "out:Etvv": 17,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 527450.6731,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10
  },
  {
    "ID": "Z00000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.640923,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 140.305876,
    "out:Primary Energy": 101.999223,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.332586,
    "out:CO2 Operational/m2": 27.564477,
    "out:Total CO2/m2": 37.897063,
    "out:Total CO2 (tons)": 79166.98636,
    "out:Klimatpaverkan":0.001* -35845.40108,
    "out:Initial Cost/MSEK": 0.579675686,
    "out:Running cost/(Apt SEK y)": 29504.8,
    "out:Running Cost over RSP/MSEK": 5.77308,
    "out:LCP/MSEK": 0.408084,
    "out:ROI% old": 24.342676,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 16.4,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 22,
    "out:Return/kSEK/y": 127,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 38193.38874,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 62917.16,
    "out:DH kr savings": 34755.98376,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 208149.9444,
    "out:% savings (space heating)": 4.283469,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.64,
    "out:Etvv": 17,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 579675.6856,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909
  },
  {
    "ID": "Z00000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.100014,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 149.330546,
    "out:Primary Energy": 105.464993,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.76522,
    "out:CO2 Operational/m2": -9.471141,
    "out:Total CO2/m2": 10.294079,
    "out:Total CO2 (tons)": 21504.33694,
    "out:Klimatpaverkan":0.001* -93508.0505,
    "out:Initial Cost/MSEK": 0.761238516,
    "out:Running cost/(Apt SEK y)": 30713.64,
    "out:Running Cost over RSP/MSEK": 6.01503,
    "out:LCP/MSEK": -0.015429,
    "out:ROI% old": 13.996176,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 9.4,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 13,
    "out:Return/kSEK/y": 97,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 18048.96496,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69267.7,
    "out:DH kr savings": 16424.55811,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 217464.9892,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.1,
    "out:Etvv": 20,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 761238.5162,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111
  },
  {
    "ID": "Z00000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.640923,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 144.330546,
    "out:Primary Energy": 102.343629,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.76522,
    "out:CO2 Operational/m2": -11.271061,
    "out:Total CO2/m2": 8.494159,
    "out:Total CO2 (tons)": 17744.30303,
    "out:Klimatpaverkan":0.001* -97268.08442,
    "out:Initial Cost/MSEK": 0.813463529,
    "out:Running cost/(Apt SEK y)": 29615.6,
    "out:Running Cost over RSP/MSEK": 5.8002,
    "out:LCP/MSEK": 0.147176,
    "out:ROI% old": 16.870367,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 13.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 15,
    "out:Return/kSEK/y": 124,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 27364.00977,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77744.39,
    "out:DH kr savings": 24901.24889,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 208149.9444,
    "out:% savings (space heating)": 4.283469,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.64,
    "out:Etvv": 20,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 813463.5287,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10
  },
  {
    "ID": "Z00000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.100014,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 143.330546,
    "out:Primary Energy": 103.364993,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.215196,
    "out:CO2 Operational/m2": -11.631045,
    "out:Total CO2/m2": 8.584151,
    "out:Total CO2 (tons)": 17932.29637,
    "out:Klimatpaverkan":0.001* -97080.09108,
    "out:Initial Cost/MSEK": 0.875078516,
    "out:Running cost/(Apt SEK y)": 29395.96,
    "out:Running Cost over RSP/MSEK": 5.75723,
    "out:LCP/MSEK": 0.128531,
    "out:ROI% old": 16.383999,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 15,
    "out:Return/kSEK/y": 130,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 28878.34394,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 79122.43,
    "out:DH kr savings": 26279.29298,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 217464.9892,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.1,
    "out:Etvv": 17,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 875078.5162,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111
  },
  {
    "ID": "Z00000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.640923,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 139.330546,
    "out:Primary Energy": 100.243629,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.215196,
    "out:CO2 Operational/m2": -13.070981,
    "out:Total CO2/m2": 7.144215,
    "out:Total CO2 (tons)": 14924.26924,
    "out:Klimatpaverkan":0.001* -100088.1182,
    "out:Initial Cost/MSEK": 0.927303529,
    "out:Running cost/(Apt SEK y)": 28517.52,
    "out:Running Cost over RSP/MSEK": 5.58537,
    "out:LCP/MSEK": 0.248166,
    "out:ROI% old": 18.10888,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 17.3,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 16,
    "out:Return/kSEK/y": 152,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 38193.38874,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 87599.12,
    "out:DH kr savings": 34755.98376,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 208149.9444,
    "out:% savings (space heating)": 4.283469,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.64,
    "out:Etvv": 17,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 927303.5287,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111
  },
  {
    "ID": "Z00000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.694438,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.531493,
    "out:Electricity (inc PV)": 41.11545,
    "out:Total Energy Use Pre PV": 108.531493,
    "out:Total Energy Use Post PV": 106.11545,
    "out:Primary Energy": 112.134975,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.698078,
    "out:CO2 Operational/m2": 29.145465,
    "out:Total CO2/m2": 33.843543,
    "out:Total CO2 (tons)": 70699.18075,
    "out:Klimatpaverkan":0.001* -44313.20669,
    "out:Initial Cost/MSEK": 1.295,
    "out:Running cost/(Apt SEK y)": 20458.56,
    "out:Running Cost over RSP/MSEK": 3.98796,
    "out:LCP/MSEK": 1.47788,
    "out:ROI% old": 30.588858,
    "out:Payback discounted": 4,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 3047.20451,
    "out:Return %": 27,
    "out:Return/kSEK/y": 353,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 25.031993,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 172563.0959,
    "out:EL kWh savings": -52048.38935,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 68550.16,
    "out:DH kr savings": 157032.4173,
    "out:El kr savings": -88482.2619,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 135146.7181,
    "out:% savings (space heating)": 37.853574,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 54.47,
    "out:Etvv": 0,
    "out:Ef": 41.12,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1295000,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.406046,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.479766,
    "out:Electricity (inc PV)": 41.035082,
    "out:Total Energy Use Pre PV": 103.479766,
    "out:Total Energy Use Post PV": 101.035082,
    "out:Primary Energy": 109.0546,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.698078,
    "out:CO2 Operational/m2": 27.338717,
    "out:Total CO2/m2": 32.036795,
    "out:Total CO2 (tons)": 66924.88314,
    "out:Klimatpaverkan":0.001* -48087.5043,
    "out:Initial Cost/MSEK": 1.34725,
    "out:Running cost/(Apt SEK y)": 19353.12,
    "out:Running Cost over RSP/MSEK": 3.7717,
    "out:LCP/MSEK": 1.64189,
    "out:ROI% old": 31.695676,
    "out:Payback discounted": 4,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 61.4,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 3043.58362,
    "out:Return %": 28,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 24.980266,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 181521.5492,
    "out:EL kWh savings": -51880.50055,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 76987.76,
    "out:DH kr savings": 165184.6098,
    "out:El kr savings": -88196.85094,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 126188.2648,
    "out:% savings (space heating)": 41.973066,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 50.27,
    "out:Etvv": 0,
    "out:Ef": 41.04,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1347250,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.985201,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.691868,
    "out:Electricity (inc PV)": 40.595398,
    "out:Total Energy Use Pre PV": 103.691868,
    "out:Total Energy Use Post PV": 101.595398,
    "out:Primary Energy": 109.775201,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.148054,
    "out:CO2 Operational/m2": 27.594692,
    "out:Total CO2/m2": 32.742746,
    "out:Total CO2 (tons)": 68399.61519,
    "out:Klimatpaverkan":0.001* -46612.77226,
    "out:Initial Cost/MSEK": 1.408875,
    "out:Running cost/(Apt SEK y)": 19460.84,
    "out:Running Cost over RSP/MSEK": 3.79304,
    "out:LCP/MSEK": 1.558925,
    "out:ROI% old": 30.092906,
    "out:Payback discounted": 4,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2988.43076,
    "out:Return %": 27,
    "out:Return/kSEK/y": 378,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 24.192368,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 180311.6941,
    "out:EL kWh savings": -50962.00043,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77448.24,
    "out:DH kr savings": 164083.6416,
    "out:El kr savings": -86635.40072,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 127398.1199,
    "out:% savings (space heating)": 41.416722,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.43,
    "out:Etvv": 0,
    "out:Ef": 40.6,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1408875,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.72916,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.630337,
    "out:Electricity (inc PV)": 40.507661,
    "out:Total Energy Use Pre PV": 99.630337,
    "out:Total Energy Use Post PV": 97.507661,
    "out:Primary Energy": 106.698584,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.148054,
    "out:CO2 Operational/m2": 26.146634,
    "out:Total CO2/m2": 31.294688,
    "out:Total CO2 (tons)": 65374.6212,
    "out:Klimatpaverkan":0.001* -49637.76625,
    "out:Initial Cost/MSEK": 1.461125,
    "out:Running cost/(Apt SEK y)": 18573.64,
    "out:Running Cost over RSP/MSEK": 3.61948,
    "out:LCP/MSEK": 1.680235,
    "out:ROI% old": 30.713711,
    "out:Payback discounted": 4,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2984.12359,
    "out:Return %": 27,
    "out:Return/kSEK/y": 400,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 24.130837,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 189202.5662,
    "out:EL kWh savings": -50778.71778,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85850.52,
    "out:DH kr savings": 172174.3352,
    "out:El kr savings": -86323.82023,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 118507.2478,
    "out:% savings (space heating)": 45.505137,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.26,
    "out:Etvv": 0,
    "out:Ef": 40.51,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1461125,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.694438,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.531493,
    "out:Electricity (inc PV)": 32.939764,
    "out:Total Energy Use Pre PV": 108.531493,
    "out:Total Energy Use Post PV": 97.939764,
    "out:Primary Energy": 97.41874,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.580688,
    "out:CO2 Operational/m2": 25.188358,
    "out:Total CO2/m2": 39.769046,
    "out:Total CO2 (tons)": 83077.55992,
    "out:Klimatpaverkan":0.001* -31934.82752,
    "out:Initial Cost/MSEK": 1.642625,
    "out:Running cost/(Apt SEK y)": 19209.84,
    "out:Running Cost over RSP/MSEK": 3.75045,
    "out:LCP/MSEK": 1.367765,
    "out:ROI% old": 26.181004,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2490.838354,
    "out:Return %": 23,
    "out:Return/kSEK/y": 384,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 25.031993,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1264.610578,
    "out:PV (% sold (El))": 6.770135,
    "out:PV (kWh self-consumed)": 17414.64038,
    "out:PV (ratio sold/self-consumed)": 0.072618,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 172563.0959,
    "out:EL kWh savings": -34969.37661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99196.86,
    "out:DH kr savings": 157032.4173,
    "out:El kr savings": -59447.94023,
    "out:El kr sold": 1612.378487,
    "out:El kr saved": 29604.88864,
    "out:El kr return": 31217.26713,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 135146.7181,
    "out:% savings (space heating)": 37.853574,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 54.47,
    "out:Etvv": 0,
    "out:Ef": 32.94,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1642625,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.406046,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.479766,
    "out:Electricity (inc PV)": 32.865101,
    "out:Total Energy Use Pre PV": 103.479766,
    "out:Total Energy Use Post PV": 92.865101,
    "out:Primary Energy": 94.348634,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.580688,
    "out:CO2 Operational/m2": 23.366196,
    "out:Total CO2/m2": 37.946884,
    "out:Total CO2 (tons)": 79271.06246,
    "out:Klimatpaverkan":0.001* -35741.32499,
    "out:Initial Cost/MSEK": 1.694875,
    "out:Running cost/(Apt SEK y)": 18104.56,
    "out:Running Cost over RSP/MSEK": 3.53422,
    "out:LCP/MSEK": 1.531745,
    "out:ROI% old": 27.196443,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 75.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2487.401866,
    "out:Return %": 24,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 24.980266,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1271.295092,
    "out:PV (% sold (El))": 6.805921,
    "out:PV (kWh self-consumed)": 17407.95586,
    "out:PV (ratio sold/self-consumed)": 0.07303,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 181521.5492,
    "out:EL kWh savings": -34813.40556,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107622.72,
    "out:DH kr savings": 165184.6098,
    "out:El kr savings": -59182.78944,
    "out:El kr sold": 1620.901242,
    "out:El kr saved": 29593.52497,
    "out:El kr return": 31214.42621,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 126188.2648,
    "out:% savings (space heating)": 41.973066,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 50.27,
    "out:Etvv": 0,
    "out:Ef": 32.87,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1694875,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.985201,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.691868,
    "out:Electricity (inc PV)": 32.457129,
    "out:Total Energy Use Pre PV": 103.691868,
    "out:Total Energy Use Post PV": 93.457129,
    "out:Primary Energy": 95.126317,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.030664,
    "out:CO2 Operational/m2": 23.380775,
    "out:Total CO2/m2": 38.411439,
    "out:Total CO2 (tons)": 80241.51812,
    "out:Klimatpaverkan":0.001* -34770.86933,
    "out:Initial Cost/MSEK": 1.7565,
    "out:Running cost/(Apt SEK y)": 18214.04,
    "out:Running Cost over RSP/MSEK": 3.55588,
    "out:LCP/MSEK": 1.44846,
    "out:ROI% old": 26.066122,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 75.3,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2435.144769,
    "out:Return %": 23,
    "out:Return/kSEK/y": 409,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 24.192368,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1375.910713,
    "out:PV (% sold (El))": 7.365984,
    "out:PV (kWh self-consumed)": 17303.34024,
    "out:PV (ratio sold/self-consumed)": 0.079517,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 180311.6941,
    "out:EL kWh savings": -33961.15181,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108103.97,
    "out:DH kr savings": 164083.6416,
    "out:El kr savings": -57733.95808,
    "out:El kr sold": 1754.286159,
    "out:El kr saved": 29415.67841,
    "out:El kr return": 31169.96457,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 127398.1199,
    "out:% savings (space heating)": 41.416722,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.43,
    "out:Etvv": 0,
    "out:Ef": 32.46,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1756500,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.72916,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.630337,
    "out:Electricity (inc PV)": 32.3758,
    "out:Total Energy Use Pre PV": 99.630337,
    "out:Total Energy Use Post PV": 89.3758,
    "out:Primary Energy": 92.061234,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.030664,
    "out:CO2 Operational/m2": 21.913345,
    "out:Total CO2/m2": 36.944009,
    "out:Total CO2 (tons)": 77176.05601,
    "out:Klimatpaverkan":0.001* -37836.33144,
    "out:Initial Cost/MSEK": 1.80875,
    "out:Running cost/(Apt SEK y)": 17326.96,
    "out:Running Cost over RSP/MSEK": 3.38235,
    "out:LCP/MSEK": 1.56974,
    "out:ROI% old": 26.6837,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 83.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2431.070676,
    "out:Return %": 24,
    "out:Return/kSEK/y": 431,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 24.130837,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1384.277098,
    "out:PV (% sold (El))": 7.410774,
    "out:PV (kWh self-consumed)": 17294.97386,
    "out:PV (ratio sold/self-consumed)": 0.080039,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 189202.5662,
    "out:EL kWh savings": -33791.25549,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116494.15,
    "out:DH kr savings": 172174.3352,
    "out:El kr savings": -57445.13432,
    "out:El kr sold": 1764.9533,
    "out:El kr saved": 29401.45556,
    "out:El kr return": 31166.40886,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 118507.2478,
    "out:% savings (space heating)": 45.505137,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.26,
    "out:Etvv": 0,
    "out:Ef": 32.38,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1808750,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.694438,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.531493,
    "out:Electricity (inc PV)": 29.694417,
    "out:Total Energy Use Pre PV": 108.531493,
    "out:Total Energy Use Post PV": 94.694417,
    "out:Primary Energy": 91.577116,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.463298,
    "out:CO2 Operational/m2": -2.667095,
    "out:Total CO2/m2": 21.796203,
    "out:Total CO2 (tons)": 45532.28058,
    "out:Klimatpaverkan":0.001* -69480.10687,
    "out:Initial Cost/MSEK": 1.99025,
    "out:Running cost/(Apt SEK y)": 18133.12,
    "out:Running Cost over RSP/MSEK": 3.54537,
    "out:LCP/MSEK": 1.22522,
    "out:ROI% old": 23.080159,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2240.893626,
    "out:Return %": 21,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 25.031993,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 12643.65048,
    "out:PV (% sold (El))": 33.844105,
    "out:PV (kWh self-consumed)": 24714.85144,
    "out:PV (ratio sold/self-consumed)": 0.511581,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 172563.0959,
    "out:EL kWh savings": -28189.84486,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 125230.34,
    "out:DH kr savings": 157032.4173,
    "out:El kr savings": -47922.73626,
    "out:El kr sold": 16120.65436,
    "out:El kr saved": 42015.24744,
    "out:El kr return": 58135.9018,
    "out:% solar/total": 73,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 135146.7181,
    "out:% savings (space heating)": 37.853574,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 54.47,
    "out:Etvv": 0,
    "out:Ef": 29.69,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1990250,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.406046,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.479766,
    "out:Electricity (inc PV)": 29.627992,
    "out:Total Energy Use Pre PV": 103.479766,
    "out:Total Energy Use Post PV": 89.627992,
    "out:Primary Energy": 88.521838,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.463298,
    "out:CO2 Operational/m2": -4.516963,
    "out:Total CO2/m2": 19.946335,
    "out:Total CO2 (tons)": 41667.90526,
    "out:Klimatpaverkan":0.001* -73344.48218,
    "out:Initial Cost/MSEK": 2.0425,
    "out:Running cost/(Apt SEK y)": 17028,
    "out:Running Cost over RSP/MSEK": 3.32918,
    "out:LCP/MSEK": 1.38916,
    "out:ROI% old": 24.001818,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 81.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2237.850153,
    "out:Return %": 21,
    "out:Return/kSEK/y": 439,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 24.980266,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 12661.59842,
    "out:PV (% sold (El))": 33.892147,
    "out:PV (kWh self-consumed)": 24696.90349,
    "out:PV (ratio sold/self-consumed)": 0.51268,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 181521.5492,
    "out:EL kWh savings": -28051.083,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 133641.31,
    "out:DH kr savings": 165184.6098,
    "out:El kr savings": -47686.84109,
    "out:El kr sold": 16143.53799,
    "out:El kr saved": 41984.73593,
    "out:El kr return": 58128.27392,
    "out:% solar/total": 73,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 126188.2648,
    "out:% savings (space heating)": 41.973066,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 50.27,
    "out:Etvv": 0,
    "out:Ef": 29.63,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2042500,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.985201,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.691868,
    "out:Electricity (inc PV)": 29.264975,
    "out:Total Energy Use Pre PV": 103.691868,
    "out:Total Energy Use Post PV": 90.264975,
    "out:Primary Energy": 89.38044,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.913274,
    "out:CO2 Operational/m2": -4.921332,
    "out:Total CO2/m2": 19.991942,
    "out:Total CO2 (tons)": 41763.17831,
    "out:Klimatpaverkan":0.001* -73249.20913,
    "out:Initial Cost/MSEK": 2.104125,
    "out:Running cost/(Apt SEK y)": 17140.4,
    "out:Running Cost over RSP/MSEK": 3.35141,
    "out:LCP/MSEK": 1.305305,
    "out:ROI% old": 23.147932,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 81.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2191.552269,
    "out:Return %": 21,
    "out:Return/kSEK/y": 436,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 24.192368,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 12937.38444,
    "out:PV (% sold (El))": 34.630362,
    "out:PV (kWh self-consumed)": 24421.11747,
    "out:PV (ratio sold/self-consumed)": 0.529762,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 180311.6941,
    "out:EL kWh savings": -27292.74027,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134181.15,
    "out:DH kr savings": 164083.6416,
    "out:El kr savings": -46397.65847,
    "out:El kr sold": 16495.16517,
    "out:El kr saved": 41515.8997,
    "out:El kr return": 58011.06486,
    "out:% solar/total": 72,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 127398.1199,
    "out:% savings (space heating)": 41.416722,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.43,
    "out:Etvv": 0,
    "out:Ef": 29.26,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2104125,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.72916,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.630337,
    "out:Electricity (inc PV)": 29.192601,
    "out:Total Energy Use Pre PV": 99.630337,
    "out:Total Energy Use Post PV": 86.192601,
    "out:Primary Energy": 86.331476,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.913274,
    "out:CO2 Operational/m2": -6.421297,
    "out:Total CO2/m2": 18.491977,
    "out:Total CO2 (tons)": 38629.75057,
    "out:Klimatpaverkan":0.001* -76382.63688,
    "out:Initial Cost/MSEK": 2.156375,
    "out:Running cost/(Apt SEK y)": 16253.56,
    "out:Running Cost over RSP/MSEK": 3.17792,
    "out:LCP/MSEK": 1.426545,
    "out:ROI% old": 23.736396,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 89.5,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2187.941549,
    "out:Return %": 21,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 24.130837,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 12959.10242,
    "out:PV (% sold (El))": 34.688496,
    "out:PV (kWh self-consumed)": 24399.39949,
    "out:PV (ratio sold/self-consumed)": 0.531124,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 189202.5662,
    "out:EL kWh savings": -27141.55095,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142556.55,
    "out:DH kr savings": 172174.3352,
    "out:El kr savings": -46140.63661,
    "out:El kr sold": 16522.85558,
    "out:El kr saved": 41478.97914,
    "out:El kr return": 58001.83472,
    "out:% solar/total": 72,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 118507.2478,
    "out:% savings (space heating)": 45.505137,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.26,
    "out:Etvv": 0,
    "out:Ef": 29.19,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2156375,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.766494,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.406627,
    "out:Electricity (inc PV)": 40.420189,
    "out:Total Energy Use Pre PV": 102.406627,
    "out:Total Energy Use Post PV": 100.420189,
    "out:Primary Energy": 108.989559,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.698078,
    "out:CO2 Operational/m2": 27.197054,
    "out:Total CO2/m2": 31.895132,
    "out:Total CO2 (tons)": 66628.94906,
    "out:Klimatpaverkan":0.001* -48383.43839,
    "out:Initial Cost/MSEK": 1.361,
    "out:Running cost/(Apt SEK y)": 19200.68,
    "out:Running Cost over RSP/MSEK": 3.74224,
    "out:LCP/MSEK": 1.6576,
    "out:ROI% old": 31.684686,
    "out:Payback discounted": 4,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2968.46389,
    "out:Return %": 28,
    "out:Return/kSEK/y": 385,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.907127,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 182857.5737,
    "out:EL kWh savings": -50595.98872,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 80387.21,
    "out:DH kr savings": 166400.3921,
    "out:El kr savings": -86013.18083,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 124852.2403,
    "out:% savings (space heating)": 42.587429,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.76,
    "out:Etvv": 0,
    "out:Ef": 40.42,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1361000,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.52165,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.341704,
    "out:Electricity (inc PV)": 40.330148,
    "out:Total Energy Use Pre PV": 98.341704,
    "out:Total Energy Use Post PV": 96.330148,
    "out:Primary Energy": 105.914115,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.698078,
    "out:CO2 Operational/m2": 25.748548,
    "out:Total CO2/m2": 30.446626,
    "out:Total CO2 (tons)": 63603.01919,
    "out:Klimatpaverkan":0.001* -51409.36825,
    "out:Initial Cost/MSEK": 1.41325,
    "out:Running cost/(Apt SEK y)": 18313.04,
    "out:Running Cost over RSP/MSEK": 3.56859,
    "out:LCP/MSEK": 1.779,
    "out:ROI% old": 32.268581,
    "out:Payback discounted": 4,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 69.8,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2963.91928,
    "out:Return %": 29,
    "out:Return/kSEK/y": 407,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.842204,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 191725.0553,
    "out:EL kWh savings": -50407.89302,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88776.38,
    "out:DH kr savings": 174469.8003,
    "out:El kr savings": -85693.41814,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 115984.7587,
    "out:% savings (space heating)": 46.665089,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 47.6,
    "out:Etvv": 0,
    "out:Ef": 40.33,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1413250,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.874638,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.712038,
    "out:Electricity (inc PV)": 39.996291,
    "out:Total Energy Use Pre PV": 98.712038,
    "out:Total Energy Use Post PV": 96.996291,
    "out:Primary Energy": 107.105747,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.148054,
    "out:CO2 Operational/m2": 26.025411,
    "out:Total CO2/m2": 31.173465,
    "out:Total CO2 (tons)": 65121.38628,
    "out:Klimatpaverkan":0.001* -49891.00117,
    "out:Initial Cost/MSEK": 1.474875,
    "out:Running cost/(Apt SEK y)": 18443.2,
    "out:Running Cost over RSP/MSEK": 3.59427,
    "out:LCP/MSEK": 1.691695,
    "out:ROI% old": 30.671558,
    "out:Payback discounted": 4,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 68,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2919.84266,
    "out:Return %": 27,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 23.212538,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 188898.6626,
    "out:EL kWh savings": -49710.46556,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 87389.99,
    "out:DH kr savings": 171897.7829,
    "out:El kr savings": -84507.79145,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 118811.1514,
    "out:% savings (space heating)": 45.365389,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 50.16,
    "out:Etvv": 0,
    "out:Ef": 40,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1474875,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.654282,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.639694,
    "out:Electricity (inc PV)": 39.900615,
    "out:Total Energy Use Pre PV": 94.639694,
    "out:Total Energy Use Post PV": 92.900615,
    "out:Primary Energy": 104.033179,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.148054,
    "out:CO2 Operational/m2": 24.575925,
    "out:Total CO2/m2": 29.723979,
    "out:Total CO2 (tons)": 62093.40919,
    "out:Klimatpaverkan":0.001* -52918.97825,
    "out:Initial Cost/MSEK": 1.527,
    "out:Running cost/(Apt SEK y)": 17554.48,
    "out:Running Cost over RSP/MSEK": 3.42042,
    "out:LCP/MSEK": 1.81342,
    "out:ROI% old": 31.251006,
    "out:Payback discounted": 4,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 75.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2914.77858,
    "out:Return %": 28,
    "out:Return/kSEK/y": 426,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 23.140194,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 197714.9887,
    "out:EL kWh savings": -49510.59834,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 95752.62,
    "out:DH kr savings": 179920.6397,
    "out:El kr savings": -84168.01718,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 109994.8253,
    "out:% savings (space heating)": 49.419525,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 46.02,
    "out:Etvv": 0,
    "out:Ef": 39.9,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1527000,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.766494,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.406627,
    "out:Electricity (inc PV)": 32.294759,
    "out:Total Energy Use Pre PV": 102.406627,
    "out:Total Energy Use Post PV": 92.294759,
    "out:Primary Energy": 94.363785,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.580688,
    "out:CO2 Operational/m2": 22.89271,
    "out:Total CO2/m2": 37.473398,
    "out:Total CO2 (tons)": 78281.94993,
    "out:Klimatpaverkan":0.001* -36730.43751,
    "out:Initial Cost/MSEK": 1.708625,
    "out:Running cost/(Apt SEK y)": 17954.56,
    "out:Running Cost over RSP/MSEK": 3.5052,
    "out:LCP/MSEK": 1.547015,
    "out:ROI% old": 27.220217,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 77.2,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2416.266651,
    "out:Return %": 24,
    "out:Return/kSEK/y": 416,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.907127,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1414.965525,
    "out:PV (% sold (El))": 7.575066,
    "out:PV (kWh self-consumed)": 17264.28543,
    "out:PV (ratio sold/self-consumed)": 0.081959,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 182857.5737,
    "out:EL kWh savings": -33621.96079,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111047.14,
    "out:DH kr savings": 166400.3921,
    "out:El kr savings": -57157.33334,
    "out:El kr sold": 1804.081044,
    "out:El kr saved": 29349.28523,
    "out:El kr return": 31153.36628,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 124852.2403,
    "out:% savings (space heating)": 42.587429,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.76,
    "out:Etvv": 0,
    "out:Ef": 32.29,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1708625,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.52165,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.341704,
    "out:Electricity (inc PV)": 32.211362,
    "out:Total Energy Use Pre PV": 98.341704,
    "out:Total Energy Use Post PV": 88.211362,
    "out:Primary Energy": 91.300301,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.580688,
    "out:CO2 Operational/m2": 21.423401,
    "out:Total CO2/m2": 36.004089,
    "out:Total CO2 (tons)": 75212.56259,
    "out:Klimatpaverkan":0.001* -39799.82486,
    "out:Initial Cost/MSEK": 1.760875,
    "out:Running cost/(Apt SEK y)": 17067.04,
    "out:Running Cost over RSP/MSEK": 3.33159,
    "out:LCP/MSEK": 1.668375,
    "out:ROI% old": 27.82099,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 85.2,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2411.972777,
    "out:Return %": 25,
    "out:Return/kSEK/y": 438,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.842204,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1423.907683,
    "out:PV (% sold (El))": 7.622938,
    "out:PV (kWh self-consumed)": 17255.34327,
    "out:PV (ratio sold/self-consumed)": 0.08252,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 191725.0553,
    "out:EL kWh savings": -33447.74441,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119424.12,
    "out:DH kr savings": 174469.8003,
    "out:El kr savings": -56861.16549,
    "out:El kr sold": 1815.482296,
    "out:El kr saved": 29334.08356,
    "out:El kr return": 31149.56586,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 115984.7587,
    "out:% savings (space heating)": 46.665089,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 47.6,
    "out:Etvv": 0,
    "out:Ef": 32.21,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1760875,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.874638,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.712038,
    "out:Electricity (inc PV)": 31.902367,
    "out:Total Energy Use Pre PV": 98.712038,
    "out:Total Energy Use Post PV": 88.902367,
    "out:Primary Energy": 92.536684,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.030664,
    "out:CO2 Operational/m2": 21.493845,
    "out:Total CO2/m2": 36.524509,
    "out:Total CO2 (tons)": 76299.72027,
    "out:Klimatpaverkan":0.001* -38712.66718,
    "out:Initial Cost/MSEK": 1.8225,
    "out:Running cost/(Apt SEK y)": 17198.72,
    "out:Running Cost over RSP/MSEK": 3.35754,
    "out:LCP/MSEK": 1.5808,
    "out:ROI% old": 26.676857,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 83.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2370.386284,
    "out:Return %": 24,
    "out:Return/kSEK/y": 435,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 23.212538,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1512.977133,
    "out:PV (% sold (El))": 8.099774,
    "out:PV (kWh self-consumed)": 17166.27382,
    "out:PV (ratio sold/self-consumed)": 0.088137,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 188898.6626,
    "out:EL kWh savings": -32802.25368,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118063,
    "out:DH kr savings": 171897.7829,
    "out:El kr savings": -55763.83125,
    "out:El kr sold": 1929.045845,
    "out:El kr saved": 29182.6655,
    "out:El kr return": 31111.71134,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 118811.1514,
    "out:% savings (space heating)": 45.365389,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 50.16,
    "out:Etvv": 0,
    "out:Ef": 31.9,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1822500,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.654282,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.639694,
    "out:Electricity (inc PV)": 31.8139,
    "out:Total Energy Use Pre PV": 94.639694,
    "out:Total Energy Use Post PV": 84.8139,
    "out:Primary Energy": 89.477092,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.030664,
    "out:CO2 Operational/m2": 20.020117,
    "out:Total CO2/m2": 35.050781,
    "out:Total CO2 (tons)": 73221.10163,
    "out:Klimatpaverkan":0.001* -41791.28582,
    "out:Initial Cost/MSEK": 1.874625,
    "out:Running cost/(Apt SEK y)": 16310.16,
    "out:Running Cost over RSP/MSEK": 3.18372,
    "out:LCP/MSEK": 1.702495,
    "out:ROI% old": 27.2597,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 91.8,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2365.615091,
    "out:Return %": 24,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 23.140194,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1523.398595,
    "out:PV (% sold (El))": 8.155566,
    "out:PV (kWh self-consumed)": 17155.85236,
    "out:PV (ratio sold/self-consumed)": 0.088798,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 197714.9887,
    "out:EL kWh savings": -32617.44606,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126413.31,
    "out:DH kr savings": 179920.6397,
    "out:El kr savings": -55449.65831,
    "out:El kr sold": 1942.333208,
    "out:El kr saved": 29164.94902,
    "out:El kr return": 31107.28222,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 109994.8253,
    "out:% savings (space heating)": 49.419525,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 46.02,
    "out:Etvv": 0,
    "out:Ef": 31.81,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1874625,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.766494,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.406627,
    "out:Electricity (inc PV)": 29.120465,
    "out:Total Energy Use Pre PV": 102.406627,
    "out:Total Energy Use Post PV": 89.120465,
    "out:Primary Energy": 88.650056,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.463298,
    "out:CO2 Operational/m2": -5.559959,
    "out:Total CO2/m2": 18.903339,
    "out:Total CO2 (tons)": 39489.08602,
    "out:Klimatpaverkan":0.001* -75523.30142,
    "out:Initial Cost/MSEK": 2.05625,
    "out:Running cost/(Apt SEK y)": 16881.96,
    "out:Running Cost over RSP/MSEK": 3.30094,
    "out:LCP/MSEK": 1.40365,
    "out:ROI% old": 24.037516,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 83.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2174.819731,
    "out:Return %": 22,
    "out:Return/kSEK/y": 443,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.907127,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 13038.20514,
    "out:PV (% sold (El))": 34.900235,
    "out:PV (kWh self-consumed)": 24320.29678,
    "out:PV (ratio sold/self-consumed)": 0.536104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 182857.5737,
    "out:EL kWh savings": -26990.8588,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137139.64,
    "out:DH kr savings": 166400.3921,
    "out:El kr savings": -45884.45996,
    "out:El kr sold": 16623.71155,
    "out:El kr saved": 41344.50452,
    "out:El kr return": 57968.21607,
    "out:% solar/total": 72,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 124852.2403,
    "out:% savings (space heating)": 42.587429,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.76,
    "out:Etvv": 0,
    "out:Ef": 29.12,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2056250,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.52165,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.341704,
    "out:Electricity (inc PV)": 29.046236,
    "out:Total Energy Use Pre PV": 98.341704,
    "out:Total Energy Use Post PV": 85.046236,
    "out:Primary Energy": 85.603074,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.463298,
    "out:CO2 Operational/m2": -7.063449,
    "out:Total CO2/m2": 17.399849,
    "out:Total CO2 (tons)": 36348.29455,
    "out:Klimatpaverkan":0.001* -78664.0929,
    "out:Initial Cost/MSEK": 2.1085,
    "out:Running cost/(Apt SEK y)": 15994.68,
    "out:Running Cost over RSP/MSEK": 3.12737,
    "out:LCP/MSEK": 1.52497,
    "out:ROI% old": 24.617839,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 91.8,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2171.013321,
    "out:Return %": 22,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.842204,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 13061.16526,
    "out:PV (% sold (El))": 34.961694,
    "out:PV (kWh self-consumed)": 24297.33666,
    "out:PV (ratio sold/self-consumed)": 0.537555,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 191725.0553,
    "out:EL kWh savings": -26835.79438,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145501.94,
    "out:DH kr savings": 174469.8003,
    "out:El kr savings": -45620.85044,
    "out:El kr sold": 16652.9857,
    "out:El kr saved": 41305.47231,
    "out:El kr return": 57958.45802,
    "out:% solar/total": 72,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 115984.7587,
    "out:% savings (space heating)": 46.665089,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 47.6,
    "out:Etvv": 0,
    "out:Ef": 29.05,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2108500,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.874638,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.712038,
    "out:Electricity (inc PV)": 28.771195,
    "out:Total Energy Use Pre PV": 98.712038,
    "out:Total Energy Use Post PV": 85.771195,
    "out:Primary Energy": 86.900574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.913274,
    "out:CO2 Operational/m2": -7.322856,
    "out:Total CO2/m2": 17.590418,
    "out:Total CO2 (tons)": 36746.3933,
    "out:Klimatpaverkan":0.001* -78265.99415,
    "out:Initial Cost/MSEK": 2.170125,
    "out:Running cost/(Apt SEK y)": 16128.68,
    "out:Running Cost over RSP/MSEK": 3.15377,
    "out:LCP/MSEK": 1.436945,
    "out:ROI% old": 23.744978,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 89.5,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2134.136242,
    "out:Return %": 21,
    "out:Return/kSEK/y": 461,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 23.212538,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 13285.39707,
    "out:PV (% sold (El))": 35.561911,
    "out:PV (kWh self-consumed)": 24073.10484,
    "out:PV (ratio sold/self-consumed)": 0.551877,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 188898.6626,
    "out:EL kWh savings": -26261.23357,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144192.57,
    "out:DH kr savings": 171897.7829,
    "out:El kr savings": -44644.09707,
    "out:El kr sold": 16938.88126,
    "out:El kr saved": 40924.27823,
    "out:El kr return": 57863.1595,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 118811.1514,
    "out:% savings (space heating)": 45.365389,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 50.16,
    "out:Etvv": 0,
    "out:Ef": 28.77,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2170125,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.654282,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.639694,
    "out:Electricity (inc PV)": 28.692393,
    "out:Total Energy Use Pre PV": 94.639694,
    "out:Total Energy Use Post PV": 81.692393,
    "out:Primary Energy": 83.858379,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.913274,
    "out:CO2 Operational/m2": -8.834325,
    "out:Total CO2/m2": 16.078949,
    "out:Total CO2 (tons)": 33588.93369,
    "out:Klimatpaverkan":0.001* -81423.45375,
    "out:Initial Cost/MSEK": 2.22225,
    "out:Running cost/(Apt SEK y)": 15240.36,
    "out:Running Cost over RSP/MSEK": 2.98,
    "out:LCP/MSEK": 1.55859,
    "out:ROI% old": 24.305096,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 98.8,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2129.904031,
    "out:Return %": 22,
    "out:Return/kSEK/y": 484,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 23.140194,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 13311.31197,
    "out:PV (% sold (El))": 35.631279,
    "out:PV (kWh self-consumed)": 24047.18995,
    "out:PV (ratio sold/self-consumed)": 0.55355,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 197714.9887,
    "out:EL kWh savings": -26096.61615,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152528.32,
    "out:DH kr savings": 179920.6397,
    "out:El kr savings": -44364.24745,
    "out:El kr sold": 16971.92276,
    "out:El kr saved": 40880.22291,
    "out:El kr return": 57852.14566,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 109994.8253,
    "out:% savings (space heating)": 49.419525,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 46.02,
    "out:Etvv": 0,
    "out:Ef": 28.69,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2222250,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.989462,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.684516,
    "out:Electricity (inc PV)": 53.831901,
    "out:Total Energy Use Pre PV": 75.684516,
    "out:Total Energy Use Post PV": 71.831901,
    "out:Primary Energy": 105.649586,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.250572,
    "out:CO2 Operational/m2": 14.09453,
    "out:Total CO2/m2": 19.345102,
    "out:Total CO2 (tons)": 40411.92918,
    "out:Klimatpaverkan":0.001* -74600.45826,
    "out:Initial Cost/MSEK": 2.35575,
    "out:Running cost/(Apt SEK y)": 12147.24,
    "out:Running Cost over RSP/MSEK": 2.35709,
    "out:LCP/MSEK": 2.048,
    "out:ROI% old": 26.705174,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 126.4,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 4037.91612,
    "out:Return %": 24,
    "out:Return/kSEK/y": 561,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 39.185016,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 270129.8176,
    "out:EL kWh savings": -78613.06279,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 112175.93,
    "out:DH kr savings": 245818.134,
    "out:El kr savings": -133642.2067,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 37579.99644,
    "out:% savings (space heating)": 82.719059,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 12.5,
    "out:Etvv": 0,
    "out:Ef": 53.83,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2355750,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.97608,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.246422,
    "out:Electricity (inc PV)": 53.262915,
    "out:Total Energy Use Pre PV": 72.246422,
    "out:Total Energy Use Post PV": 68.262915,
    "out:Primary Energy": 102.818405,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.250572,
    "out:CO2 Operational/m2": 12.956746,
    "out:Total CO2/m2": 18.207318,
    "out:Total CO2 (tons)": 38035.09775,
    "out:Klimatpaverkan":0.001* -76977.28969,
    "out:Initial Cost/MSEK": 2.408,
    "out:Running cost/(Apt SEK y)": 11426.16,
    "out:Running Cost over RSP/MSEK": 2.21617,
    "out:LCP/MSEK": 2.13667,
    "out:ROI% old": 26.961735,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 4007.24954,
    "out:Return %": 24,
    "out:Return/kSEK/y": 579,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 38.746922,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 276424.7743,
    "out:EL kWh savings": -77424.45071,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119924.98,
    "out:DH kr savings": 251546.5446,
    "out:El kr savings": -131621.5662,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 31285.03972,
    "out:% savings (space heating)": 85.613758,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 9.92,
    "out:Etvv": 0,
    "out:Ef": 53.26,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2408000,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.924274,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.346694,
    "out:Electricity (inc PV)": 52.997938,
    "out:Total Energy Use Pre PV": 72.346694,
    "out:Total Energy Use Post PV": 68.997938,
    "out:Primary Energy": 103.449907,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.700548,
    "out:CO2 Operational/m2": 13.197958,
    "out:Total CO2/m2": 18.898506,
    "out:Total CO2 (tons)": 39478.98988,
    "out:Klimatpaverkan":0.001* -75533.39756,
    "out:Initial Cost/MSEK": 2.469625,
    "out:Running cost/(Apt SEK y)": 11517.96,
    "out:Running Cost over RSP/MSEK": 2.23443,
    "out:LCP/MSEK": 2.056785,
    "out:ROI% old": 26.183327,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 136.2,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3944.26858,
    "out:Return %": 23,
    "out:Return/kSEK/y": 577,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 37.847194,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 274443.9965,
    "out:EL kWh savings": -76870.9136,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119063.48,
    "out:DH kr savings": 249744.0368,
    "out:El kr savings": -130680.5531,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 33265.81753,
    "out:% savings (space heating)": 84.702909,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 11.51,
    "out:Etvv": 0,
    "out:Ef": 53,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2469625,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.047635,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.867163,
    "out:Electricity (inc PV)": 52.400375,
    "out:Total Energy Use Pre PV": 68.867163,
    "out:Total Energy Use Post PV": 65.400375,
    "out:Primary Energy": 100.633299,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.700548,
    "out:CO2 Operational/m2": 12.054704,
    "out:Total CO2/m2": 17.755252,
    "out:Total CO2 (tons)": 37090.73162,
    "out:Klimatpaverkan":0.001* -77921.65582,
    "out:Initial Cost/MSEK": 2.521875,
    "out:Running cost/(Apt SEK y)": 10791,
    "out:Running Cost over RSP/MSEK": 2.09236,
    "out:LCP/MSEK": 2.146605,
    "out:ROI% old": 26.445629,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 150.8,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3910.70141,
    "out:Return %": 24,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 37.367663,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 280453.297,
    "out:EL kWh savings": -75622.60415,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126654.07,
    "out:DH kr savings": 255212.5003,
    "out:El kr savings": -128558.4271,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 27256.517,
    "out:% savings (space heating)": 87.46625,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 9.02,
    "out:Etvv": 0,
    "out:Ef": 52.4,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2521875,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.989462,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.684516,
    "out:Electricity (inc PV)": 45.043978,
    "out:Total Energy Use Pre PV": 75.684516,
    "out:Total Energy Use Post PV": 63.043978,
    "out:Primary Energy": 89.831324,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.133182,
    "out:CO2 Operational/m2": 12.68134,
    "out:Total CO2/m2": 27.814522,
    "out:Total CO2 (tons)": 58104.55242,
    "out:Klimatpaverkan":0.001* -56907.83502,
    "out:Initial Cost/MSEK": 2.703375,
    "out:Running cost/(Apt SEK y)": 10879.36,
    "out:Running Cost over RSP/MSEK": 2.11614,
    "out:LCP/MSEK": 1.941325,
    "out:ROI% old": 24.544452,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 158.7,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 3454.179694,
    "out:Return %": 22,
    "out:Return/kSEK/y": 593,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 39.185016,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 136.400847,
    "out:PV (% sold (El))": 0.730227,
    "out:PV (kWh self-consumed)": 18542.85011,
    "out:PV (ratio sold/self-consumed)": 0.007356,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 270129.8176,
    "out:EL kWh savings": -60255.0866,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143558.4,
    "out:DH kr savings": 245818.134,
    "out:El kr savings": -102433.6472,
    "out:El kr sold": 173.9110793,
    "out:El kr saved": 31522.84519,
    "out:El kr return": 31696.75627,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 37579.99644,
    "out:% savings (space heating)": 82.719059,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 12.5,
    "out:Etvv": 0,
    "out:Ef": 45.04,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2703375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.97608,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.246422,
    "out:Electricity (inc PV)": 44.491154,
    "out:Total Energy Use Pre PV": 72.246422,
    "out:Total Energy Use Post PV": 59.491154,
    "out:Primary Energy": 87.029235,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.133182,
    "out:CO2 Operational/m2": 11.50962,
    "out:Total CO2/m2": 26.642802,
    "out:Total CO2 (tons)": 55656.82867,
    "out:Klimatpaverkan":0.001* -59355.55877,
    "out:Initial Cost/MSEK": 2.755625,
    "out:Running cost/(Apt SEK y)": 10158.56,
    "out:Running Cost over RSP/MSEK": 1.97526,
    "out:LCP/MSEK": 2.029955,
    "out:ROI% old": 24.809409,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 176.3,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3423.768515,
    "out:Return %": 22,
    "out:Return/kSEK/y": 611,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 38.746922,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 153.020015,
    "out:PV (% sold (El))": 0.819198,
    "out:PV (kWh self-consumed)": 18526.23094,
    "out:PV (ratio sold/self-consumed)": 0.00826,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 276424.7743,
    "out:EL kWh savings": -59100.23695,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151271.24,
    "out:DH kr savings": 251546.5446,
    "out:El kr savings": -100470.4028,
    "out:El kr sold": 195.1005185,
    "out:El kr saved": 31494.5926,
    "out:El kr return": 31689.69312,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 31285.03972,
    "out:% savings (space heating)": 85.613758,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 9.92,
    "out:Etvv": 0,
    "out:Ef": 44.49,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2755625,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.924274,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.346694,
    "out:Electricity (inc PV)": 44.233883,
    "out:Total Energy Use Pre PV": 72.346694,
    "out:Total Energy Use Post PV": 60.233883,
    "out:Primary Energy": 87.674608,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.583158,
    "out:CO2 Operational/m2": 11.672334,
    "out:Total CO2/m2": 27.255492,
    "out:Total CO2 (tons)": 56936.73843,
    "out:Klimatpaverkan":0.001* -58075.64901,
    "out:Initial Cost/MSEK": 2.81725,
    "out:Running cost/(Apt SEK y)": 10251,
    "out:Running Cost over RSP/MSEK": 1.99363,
    "out:LCP/MSEK": 1.94996,
    "out:ROI% old": 24.173574,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 171.7,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3361.404311,
    "out:Return %": 22,
    "out:Return/kSEK/y": 608,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 37.847194,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 190.654173,
    "out:PV (% sold (El))": 1.020674,
    "out:PV (kWh self-consumed)": 18488.59678,
    "out:PV (ratio sold/self-consumed)": 0.010312,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 274443.9965,
    "out:EL kWh savings": -58562.79768,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150430.36,
    "out:DH kr savings": 249744.0368,
    "out:El kr savings": -99556.75605,
    "out:El kr sold": 243.0840708,
    "out:El kr saved": 31430.61453,
    "out:El kr return": 31673.6986,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 33265.81753,
    "out:% savings (space heating)": 84.702909,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 11.51,
    "out:Etvv": 0,
    "out:Ef": 44.23,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2817250,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.047635,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.867163,
    "out:Electricity (inc PV)": 43.654416,
    "out:Total Energy Use Pre PV": 68.867163,
    "out:Total Energy Use Post PV": 56.654416,
    "out:Primary Energy": 84.890573,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.583158,
    "out:CO2 Operational/m2": 10.482388,
    "out:Total CO2/m2": 26.065546,
    "out:Total CO2 (tons)": 54450.94056,
    "out:Klimatpaverkan":0.001* -60561.44689,
    "out:Initial Cost/MSEK": 2.8695,
    "out:Running cost/(Apt SEK y)": 9524.44,
    "out:Running Cost over RSP/MSEK": 1.85163,
    "out:LCP/MSEK": 2.03971,
    "out:ROI% old": 24.440346,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 186,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3328.21932,
    "out:Return %": 22,
    "out:Return/kSEK/y": 626,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 37.367663,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 212.714512,
    "out:PV (% sold (El))": 1.138774,
    "out:PV (kWh self-consumed)": 18466.53644,
    "out:PV (ratio sold/self-consumed)": 0.011519,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 280453.297,
    "out:EL kWh savings": -57352.29078,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157984.82,
    "out:DH kr savings": 255212.5003,
    "out:El kr savings": -97498.89433,
    "out:El kr sold": 271.2110032,
    "out:El kr saved": 31393.11195,
    "out:El kr return": 31664.32296,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 27256.517,
    "out:% savings (space heating)": 87.46625,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 9.02,
    "out:Etvv": 0,
    "out:Ef": 43.65,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2869500,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.989462,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.684516,
    "out:Electricity (inc PV)": 40.41856,
    "out:Total Energy Use Pre PV": 75.684516,
    "out:Total Energy Use Post PV": 58.41856,
    "out:Primary Energy": 81.505572,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.015792,
    "out:CO2 Operational/m2": -7.300204,
    "out:Total CO2/m2": 17.715588,
    "out:Total CO2 (tons)": 37007.8735,
    "out:Klimatpaverkan":0.001* -78004.51394,
    "out:Initial Cost/MSEK": 3.051,
    "out:Running cost/(Apt SEK y)": 9747.4,
    "out:Running Cost over RSP/MSEK": 1.90037,
    "out:LCP/MSEK": 1.80947,
    "out:ROI% old": 22.758206,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 181,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 3094.567349,
    "out:Return %": 20,
    "out:Return/kSEK/y": 621,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 39.185016,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 8268.278715,
    "out:PV (% sold (El))": 22.132254,
    "out:PV (kWh self-consumed)": 29090.2232,
    "out:PV (ratio sold/self-consumed)": 0.284229,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 270129.8176,
    "out:EL kWh savings": -50592.58574,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 170352.79,
    "out:DH kr savings": 245818.134,
    "out:El kr savings": -86007.39576,
    "out:El kr sold": 10542.05536,
    "out:El kr saved": 49453.37943,
    "out:El kr return": 59995.4348,
    "out:% solar/total": 86,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 37579.99644,
    "out:% savings (space heating)": 82.719059,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 12.5,
    "out:Etvv": 0,
    "out:Ef": 40.42,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3051000,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.97608,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.246422,
    "out:Electricity (inc PV)": 39.928668,
    "out:Total Energy Use Pre PV": 72.246422,
    "out:Total Energy Use Post PV": 54.928668,
    "out:Primary Energy": 78.81676,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.015792,
    "out:CO2 Operational/m2": -8.717685,
    "out:Total CO2/m2": 16.298107,
    "out:Total CO2 (tons)": 34046.75488,
    "out:Klimatpaverkan":0.001* -80965.63257,
    "out:Initial Cost/MSEK": 3.10325,
    "out:Running cost/(Apt SEK y)": 9028.32,
    "out:Running Cost over RSP/MSEK": 1.75982,
    "out:LCP/MSEK": 1.89777,
    "out:ROI% old": 23.022039,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 196.4,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3067.552761,
    "out:Return %": 21,
    "out:Return/kSEK/y": 639,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 38.746922,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 8385.734688,
    "out:PV (% sold (El))": 22.446657,
    "out:PV (kWh self-consumed)": 28972.76722,
    "out:PV (ratio sold/self-consumed)": 0.289435,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 276424.7743,
    "out:EL kWh savings": -49569.20107,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177970.71,
    "out:DH kr savings": 251546.5446,
    "out:El kr savings": -84267.64182,
    "out:El kr sold": 10691.81173,
    "out:El kr saved": 49253.70428,
    "out:El kr return": 59945.51601,
    "out:% solar/total": 86,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 31285.03972,
    "out:% savings (space heating)": 85.613758,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 9.92,
    "out:Etvv": 0,
    "out:Ef": 39.93,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3103250,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.924274,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.346694,
    "out:Electricity (inc PV)": 39.70088,
    "out:Total Energy Use Pre PV": 72.346694,
    "out:Total Energy Use Post PV": 55.70088,
    "out:Primary Energy": 79.515203,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.465768,
    "out:CO2 Operational/m2": -9.057611,
    "out:Total CO2/m2": 16.408157,
    "out:Total CO2 (tons)": 34276.64939,
    "out:Klimatpaverkan":0.001* -80735.73805,
    "out:Initial Cost/MSEK": 3.164875,
    "out:Running cost/(Apt SEK y)": 9124.28,
    "out:Running Cost over RSP/MSEK": 1.77887,
    "out:LCP/MSEK": 1.817095,
    "out:ROI% old": 22.487776,
    "out:Payback discounted": 6,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 191.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3012.182859,
    "out:Return %": 20,
    "out:Return/kSEK/y": 636,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 37.847194,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 8630.248172,
    "out:PV (% sold (El))": 23.101162,
    "out:PV (kWh self-consumed)": 28728.25374,
    "out:PV (ratio sold/self-consumed)": 0.30041,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 274443.9965,
    "out:EL kWh savings": -49093.35181,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 177288.91,
    "out:DH kr savings": 249744.0368,
    "out:El kr savings": -83458.69808,
    "out:El kr sold": 11003.56642,
    "out:El kr saved": 48838.03136,
    "out:El kr return": 59841.59778,
    "out:% solar/total": 85,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 33265.81753,
    "out:% savings (space heating)": 84.702909,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 11.51,
    "out:Etvv": 0,
    "out:Ef": 39.7,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3164875,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.047635,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.867163,
    "out:Electricity (inc PV)": 39.187883,
    "out:Total Energy Use Pre PV": 68.867163,
    "out:Total Energy Use Post PV": 52.187883,
    "out:Primary Energy": 76.850813,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.465768,
    "out:CO2 Operational/m2": -10.514795,
    "out:Total CO2/m2": 14.950973,
    "out:Total CO2 (tons)": 31232.59118,
    "out:Klimatpaverkan":0.001* -83779.79627,
    "out:Initial Cost/MSEK": 3.217125,
    "out:Running cost/(Apt SEK y)": 8399.6,
    "out:Running Cost over RSP/MSEK": 1.63723,
    "out:LCP/MSEK": 1.906485,
    "out:ROI% old": 22.751503,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 213.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 2982.733711,
    "out:Return %": 20,
    "out:Return/kSEK/y": 655,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 37.367663,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 8762.576321,
    "out:PV (% sold (El))": 23.455374,
    "out:PV (kWh self-consumed)": 28595.92559,
    "out:PV (ratio sold/self-consumed)": 0.306427,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 280453.297,
    "out:EL kWh savings": -48021.70078,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184747.89,
    "out:DH kr savings": 255212.5003,
    "out:El kr savings": -81636.89133,
    "out:El kr sold": 11172.28481,
    "out:El kr saved": 48613.07351,
    "out:El kr return": 59785.35832,
    "out:% solar/total": 84,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 27256.517,
    "out:% savings (space heating)": 87.46625,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 9.02,
    "out:Etvv": 0,
    "out:Ef": 39.19,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3217125,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.261836,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.892887,
    "out:Electricity (inc PV)": 52.717247,
    "out:Total Energy Use Pre PV": 70.892887,
    "out:Total Energy Use Post PV": 67.717247,
    "out:Primary Energy": 102.718059,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.250572,
    "out:CO2 Operational/m2": 12.778068,
    "out:Total CO2/m2": 18.02864,
    "out:Total CO2 (tons)": 37661.83931,
    "out:Klimatpaverkan":0.001* -77350.54814,
    "out:Initial Cost/MSEK": 2.42175,
    "out:Running cost/(Apt SEK y)": 11233.88,
    "out:Running Cost over RSP/MSEK": 2.17901,
    "out:LCP/MSEK": 2.16008,
    "out:ROI% old": 27.027858,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3912.50209,
    "out:Return %": 24,
    "out:Return/kSEK/y": 584,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 37.393387,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 275827.8298,
    "out:EL kWh savings": -76284.54994,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121319.59,
    "out:DH kr savings": 251003.3252,
    "out:El kr savings": -129683.7349,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 31881.98416,
    "out:% savings (space heating)": 85.339257,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 11.18,
    "out:Etvv": 0,
    "out:Ef": 52.72,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2421750,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.436465,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.39782,
    "out:Electricity (inc PV)": 52.108957,
    "out:Total Energy Use Pre PV": 67.39782,
    "out:Total Energy Use Post PV": 64.108957,
    "out:Primary Energy": 99.906924,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.250572,
    "out:CO2 Operational/m2": 11.632763,
    "out:Total CO2/m2": 16.883335,
    "out:Total CO2 (tons)": 35269.29651,
    "out:Klimatpaverkan":0.001* -79743.09094,
    "out:Initial Cost/MSEK": 2.474,
    "out:Running cost/(Apt SEK y)": 10504.72,
    "out:Running Cost over RSP/MSEK": 2.03651,
    "out:LCP/MSEK": 2.25033,
    "out:ROI% old": 27.279882,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 154.7,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3877.8474,
    "out:Return %": 24,
    "out:Return/kSEK/y": 602,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 36.89832,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 281730.0315,
    "out:EL kWh savings": -75013.83179,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128850.81,
    "out:DH kr savings": 256374.3286,
    "out:El kr savings": -127523.514,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 25979.78252,
    "out:% savings (space heating)": 88.053349,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 8.73,
    "out:Etvv": 0,
    "out:Ef": 52.11,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2474000,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.728337,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.786675,
    "out:Electricity (inc PV)": 52.038548,
    "out:Total Energy Use Pre PV": 68.786675,
    "out:Total Energy Use Post PV": 66.038548,
    "out:Primary Energy": 100.964195,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.700548,
    "out:CO2 Operational/m2": 12.272055,
    "out:Total CO2/m2": 17.972603,
    "out:Total CO2 (tons)": 37544.77798,
    "out:Klimatpaverkan":0.001* -77467.60946,
    "out:Initial Cost/MSEK": 2.535625,
    "out:Running cost/(Apt SEK y)": 10857.12,
    "out:Running Cost over RSP/MSEK": 2.10567,
    "out:LCP/MSEK": 2.119545,
    "out:ROI% old": 26.227233,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 147,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3835.06725,
    "out:Return %": 23,
    "out:Return/kSEK/y": 593,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 36.287175,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 279031.3101,
    "out:EL kWh savings": -74866.74734,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126645.02,
    "out:DH kr savings": 253918.4922,
    "out:El kr savings": -127273.4705,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 28678.50387,
    "out:% savings (space heating)": 86.812358,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 10.42,
    "out:Etvv": 0,
    "out:Ef": 52.04,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2535625,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.038496,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.250538,
    "out:Electricity (inc PV)": 51.401467,
    "out:Total Energy Use Pre PV": 65.250538,
    "out:Total Energy Use Post PV": 62.401467,
    "out:Primary Energy": 98.167743,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 5.700548,
    "out:CO2 Operational/m2": 11.121329,
    "out:Total CO2/m2": 16.821877,
    "out:Total CO2 (tons)": 35140.91071,
    "out:Klimatpaverkan":0.001* -79871.47674,
    "out:Initial Cost/MSEK": 2.58775,
    "out:Running cost/(Apt SEK y)": 10122.12,
    "out:Running Cost over RSP/MSEK": 1.96205,
    "out:LCP/MSEK": 2.21104,
    "out:ROI% old": 26.491795,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 162.9,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3797.53766,
    "out:Return %": 24,
    "out:Return/kSEK/y": 612,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 35.751038,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 284650.3895,
    "out:EL kWh savings": -73535.88477,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134020.85,
    "out:DH kr savings": 259031.8545,
    "out:El kr savings": -125011.0041,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 23059.42448,
    "out:% savings (space heating)": 89.396259,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 8.06,
    "out:Etvv": 0,
    "out:Ef": 51.4,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2587750,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.261836,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.892887,
    "out:Electricity (inc PV)": 43.961627,
    "out:Total Energy Use Pre PV": 70.892887,
    "out:Total Energy Use Post PV": 58.961627,
    "out:Primary Energy": 86.957943,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.133182,
    "out:CO2 Operational/m2": 11.20836,
    "out:Total CO2/m2": 26.341542,
    "out:Total CO2 (tons)": 55027.49636,
    "out:Klimatpaverkan":0.001* -59984.89109,
    "out:Initial Cost/MSEK": 2.769375,
    "out:Running cost/(Apt SEK y)": 9967.28,
    "out:Running Cost over RSP/MSEK": 1.93827,
    "out:LCP/MSEK": 2.053195,
    "out:ROI% old": 24.877042,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 176.3,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3329.998472,
    "out:Return %": 22,
    "out:Return/kSEK/y": 615,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 37.393387,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 211.468703,
    "out:PV (% sold (El))": 1.132105,
    "out:PV (kWh self-consumed)": 18467.78225,
    "out:PV (ratio sold/self-consumed)": 0.011451,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 275827.8298,
    "out:EL kWh savings": -57994.05474,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152683.05,
    "out:DH kr savings": 251003.3252,
    "out:El kr savings": -98589.89305,
    "out:El kr sold": 269.6225965,
    "out:El kr saved": 31395.22983,
    "out:El kr return": 31664.85243,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 31881.98416,
    "out:% savings (space heating)": 85.339257,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 11.18,
    "out:Etvv": 0,
    "out:Ef": 43.96,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2769375,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.436465,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.39782,
    "out:Electricity (inc PV)": 43.372179,
    "out:Total Energy Use Pre PV": 67.39782,
    "out:Total Energy Use Post PV": 55.372179,
    "out:Primary Energy": 84.180723,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.133182,
    "out:CO2 Operational/m2": 10.01118,
    "out:Total CO2/m2": 25.144362,
    "out:Total CO2 (tons)": 52526.58665,
    "out:Klimatpaverkan":0.001* -62485.80079,
    "out:Initial Cost/MSEK": 2.821625,
    "out:Running cost/(Apt SEK y)": 9238.56,
    "out:Running Cost over RSP/MSEK": 1.79585,
    "out:LCP/MSEK": 2.143365,
    "out:ROI% old": 25.13744,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 196.4,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3295.779022,
    "out:Return %": 22,
    "out:Return/kSEK/y": 634,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 36.89832,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 235.990251,
    "out:PV (% sold (El))": 1.263382,
    "out:PV (kWh self-consumed)": 18443.26071,
    "out:PV (ratio sold/self-consumed)": 0.012795,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 281730.0315,
    "out:EL kWh savings": -56762.69753,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160178.63,
    "out:DH kr savings": 256374.3286,
    "out:El kr savings": -96496.5858,
    "out:El kr sold": 300.8875705,
    "out:El kr saved": 31353.5432,
    "out:El kr return": 31654.43077,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 25979.78252,
    "out:% savings (space heating)": 88.053349,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 8.73,
    "out:Etvv": 0,
    "out:Ef": 43.37,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2821625,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.728337,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.786675,
    "out:Electricity (inc PV)": 43.304044,
    "out:Total Energy Use Pre PV": 68.786675,
    "out:Total Energy Use Post PV": 57.304044,
    "out:Primary Energy": 85.242088,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.583158,
    "out:CO2 Operational/m2": 10.581288,
    "out:Total CO2/m2": 26.164446,
    "out:Total CO2 (tons)": 54657.54271,
    "out:Klimatpaverkan":0.001* -60354.84473,
    "out:Initial Cost/MSEK": 2.88325,
    "out:Running cost/(Apt SEK y)": 9591.48,
    "out:Running Cost over RSP/MSEK": 1.86509,
    "out:LCP/MSEK": 2.0125,
    "out:ROI% old": 24.257101,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 186,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3253.599255,
    "out:Return %": 22,
    "out:Return/kSEK/y": 625,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 36.287175,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 267.982729,
    "out:PV (% sold (El))": 1.434655,
    "out:PV (kWh self-consumed)": 18411.26823,
    "out:PV (ratio sold/self-consumed)": 0.014555,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 279031.3101,
    "out:EL kWh savings": -56620.36347,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158005.55,
    "out:DH kr savings": 253918.4922,
    "out:El kr savings": -96254.61791,
    "out:El kr sold": 341.6779797,
    "out:El kr saved": 31299.15599,
    "out:El kr return": 31640.83397,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 28678.50387,
    "out:% savings (space heating)": 86.812358,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 10.42,
    "out:Etvv": 0,
    "out:Ef": 43.3,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2883250,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.038496,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.250538,
    "out:Electricity (inc PV)": 42.688136,
    "out:Total Energy Use Pre PV": 65.250538,
    "out:Total Energy Use Post PV": 53.688136,
    "out:Primary Energy": 82.483747,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.583158,
    "out:CO2 Operational/m2": 9.365533,
    "out:Total CO2/m2": 24.948691,
    "out:Total CO2 (tons)": 52117.82982,
    "out:Klimatpaverkan":0.001* -62894.55762,
    "out:Initial Cost/MSEK": 2.935375,
    "out:Running cost/(Apt SEK y)": 8857,
    "out:Running Cost over RSP/MSEK": 1.72156,
    "out:LCP/MSEK": 2.103905,
    "out:ROI% old": 24.524878,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 201.9,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3216.655161,
    "out:Return %": 22,
    "out:Return/kSEK/y": 643,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 35.751038,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 297.361103,
    "out:PV (% sold (El))": 1.591933,
    "out:PV (kWh self-consumed)": 18381.88985,
    "out:PV (ratio sold/self-consumed)": 0.016177,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 284650.3895,
    "out:EL kWh savings": -55333.73131,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165343.65,
    "out:DH kr savings": 259031.8545,
    "out:El kr savings": -94067.34322,
    "out:El kr sold": 379.1354067,
    "out:El kr saved": 31249.21275,
    "out:El kr return": 31628.34816,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 23059.42448,
    "out:% savings (space heating)": 89.396259,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 8.06,
    "out:Etvv": 0,
    "out:Ef": 42.69,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2935375,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.261836,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.892887,
    "out:Electricity (inc PV)": 39.459781,
    "out:Total Energy Use Pre PV": 70.892887,
    "out:Total Energy Use Post PV": 54.459781,
    "out:Primary Energy": 78.85462,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.015792,
    "out:CO2 Operational/m2": -9.774518,
    "out:Total CO2/m2": 15.241274,
    "out:Total CO2 (tons)": 31839.03013,
    "out:Klimatpaverkan":0.001* -83173.35731,
    "out:Initial Cost/MSEK": 3.117,
    "out:Running cost/(Apt SEK y)": 8842.36,
    "out:Running Cost over RSP/MSEK": 1.72385,
    "out:LCP/MSEK": 1.91999,
    "out:ROI% old": 23.085338,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 201.9,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 2984.31247,
    "out:Return %": 21,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 37.393387,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 8755.455775,
    "out:PV (% sold (El))": 23.436314,
    "out:PV (kWh self-consumed)": 28603.04614,
    "out:PV (ratio sold/self-consumed)": 0.306102,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 275827.8298,
    "out:EL kWh savings": -48589.69586,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179564.05,
    "out:DH kr savings": 251003.3252,
    "out:El kr savings": -82602.48296,
    "out:El kr sold": 11163.20611,
    "out:El kr saved": 48625.17843,
    "out:El kr return": 59788.38455,
    "out:% solar/total": 85,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 31881.98416,
    "out:% savings (space heating)": 85.339257,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 11.18,
    "out:Etvv": 0,
    "out:Ef": 39.46,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3117000,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.436465,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.39782,
    "out:Electricity (inc PV)": 38.938212,
    "out:Total Energy Use Pre PV": 67.39782,
    "out:Total Energy Use Post PV": 50.938212,
    "out:Primary Energy": 76.199583,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.015792,
    "out:CO2 Operational/m2": -11.246837,
    "out:Total CO2/m2": 13.768955,
    "out:Total CO2 (tons)": 28763.3549,
    "out:Klimatpaverkan":0.001* -86249.03255,
    "out:Initial Cost/MSEK": 3.16925,
    "out:Running cost/(Apt SEK y)": 8115.52,
    "out:Running Cost over RSP/MSEK": 1.5818,
    "out:LCP/MSEK": 2.00979,
    "out:ROI% old": 23.345046,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 219.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 2953.951159,
    "out:Return %": 21,
    "out:Return/kSEK/y": 662,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 36.89832,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 8893.088466,
    "out:PV (% sold (El))": 23.804725,
    "out:PV (kWh self-consumed)": 28465.41345,
    "out:PV (ratio sold/self-consumed)": 0.312417,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 281730.0315,
    "out:EL kWh savings": -47500.13792,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186962.78,
    "out:DH kr savings": 256374.3286,
    "out:El kr savings": -80750.23446,
    "out:El kr sold": 11338.68779,
    "out:El kr saved": 48391.20286,
    "out:El kr return": 59729.89065,
    "out:% solar/total": 84,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 25979.78252,
    "out:% savings (space heating)": 88.053349,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 8.73,
    "out:Etvv": 0,
    "out:Ef": 38.94,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3169250,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.728337,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.786675,
    "out:Electricity (inc PV)": 38.877931,
    "out:Total Energy Use Pre PV": 68.786675,
    "out:Total Energy Use Post PV": 52.877931,
    "out:Primary Energy": 77.275085,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.465768,
    "out:CO2 Operational/m2": -11.015798,
    "out:Total CO2/m2": 14.44997,
    "out:Total CO2 (tons)": 30185.99562,
    "out:Klimatpaverkan":0.001* -84826.39182,
    "out:Initial Cost/MSEK": 3.230875,
    "out:Running cost/(Apt SEK y)": 8470.88,
    "out:Running Cost over RSP/MSEK": 1.65151,
    "out:LCP/MSEK": 1.878455,
    "out:ROI% old": 22.591536,
    "out:Payback discounted": 6,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 207.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 2916.53216,
    "out:Return %": 20,
    "out:Return/kSEK/y": 653,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 36.287175,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 9065.01767,
    "out:PV (% sold (El))": 24.264939,
    "out:PV (kWh self-consumed)": 28293.48424,
    "out:PV (ratio sold/self-consumed)": 0.320392,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 279031.3101,
    "out:EL kWh savings": -47374.21088,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184940.23,
    "out:DH kr savings": 253918.4922,
    "out:El kr savings": -80536.15849,
    "out:El kr sold": 11557.89753,
    "out:El kr saved": 48098.92321,
    "out:El kr return": 59656.82074,
    "out:% solar/total": 84,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 28678.50387,
    "out:% savings (space heating)": 86.812358,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 10.42,
    "out:Etvv": 0,
    "out:Ef": 38.88,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3230875,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.038496,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.250538,
    "out:Electricity (inc PV)": 38.333313,
    "out:Total Energy Use Pre PV": 65.250538,
    "out:Total Energy Use Post PV": 49.333313,
    "out:Primary Energy": 74.645065,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 25.465768,
    "out:CO2 Operational/m2": -12.529001,
    "out:Total CO2/m2": 12.936767,
    "out:Total CO2 (tons)": 27024.91369,
    "out:Klimatpaverkan":0.001* -87987.47376,
    "out:Initial Cost/MSEK": 3.283,
    "out:Running cost/(Apt SEK y)": 7738.44,
    "out:Running Cost over RSP/MSEK": 1.50838,
    "out:LCP/MSEK": 1.96946,
    "out:ROI% old": 22.855663,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 232.7,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 2883.760259,
    "out:Return %": 20,
    "out:Return/kSEK/y": 671,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 35.751038,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 9217.591335,
    "out:PV (% sold (El))": 24.673343,
    "out:PV (kWh self-consumed)": 28140.91058,
    "out:PV (ratio sold/self-consumed)": 0.327551,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 284650.3895,
    "out:EL kWh savings": -46236.50356,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192182.23,
    "out:DH kr savings": 259031.8545,
    "out:El kr savings": -78602.05605,
    "out:El kr sold": 11752.42895,
    "out:El kr saved": 47839.54798,
    "out:El kr return": 59591.97693,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 23059.42448,
    "out:% savings (space heating)": 89.396259,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 8.06,
    "out:Etvv": 0,
    "out:Ef": 38.33,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3283000,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z01000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.495819,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 147.2,
    "out:Primary Energy": 107.907073,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.433273,
    "out:CO2 Operational/m2": 49.296434,
    "out:Total CO2/m2": 60.729707,
    "out:Total CO2 (tons)": 126864.3928,
    "out:Klimatpaverkan":0.001* 11852.00534,
    "out:Initial Cost/MSEK": 1.726,
    "out:Running cost/(Apt SEK y)": 31070.48,
    "out:Running Cost over RSP/MSEK": 6.07337,
    "out:LCP/MSEK": -1.03853,
    "out:ROI% old": 5.690035,
    "out:Payback discounted": 21,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 10.9,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 5,
    "out:Return/kSEK/y": 88,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 34686.17351,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 31564.42,
    "out:DH kr savings": 31564.4179,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 182778.8157,
    "out:% savings (space heating)": 15.950233,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.5,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1726000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.791463,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 143.2,
    "out:Primary Energy": 105.314024,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.433273,
    "out:CO2 Operational/m2": 47.856498,
    "out:Total CO2/m2": 59.289771,
    "out:Total CO2 (tons)": 123856.3657,
    "out:Klimatpaverkan":0.001* 8843.978207,
    "out:Initial Cost/MSEK": 1.77825,
    "out:Running cost/(Apt SEK y)": 30192.04,
    "out:Running Cost over RSP/MSEK": 5.90151,
    "out:LCP/MSEK": -0.91892,
    "out:ROI% old": 6.903497,
    "out:Payback discounted": 18,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 6,
    "out:Return/kSEK/y": 110,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 42424.57407,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 38606.36,
    "out:DH kr savings": 38606.3624,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 175040.4151,
    "out:% savings (space heating)": 19.508692,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.79,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1778250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.495819,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 140.2,
    "out:Primary Energy": 105.282073,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.883249,
    "out:CO2 Operational/m2": 46.776546,
    "out:Total CO2/m2": 58.659795,
    "out:Total CO2 (tons)": 122540.3454,
    "out:Klimatpaverkan":0.001* 7527.957982,
    "out:Initial Cost/MSEK": 1.839875,
    "out:Running cost/(Apt SEK y)": 29533.2,
    "out:Running Cost over RSP/MSEK": 5.77261,
    "out:LCP/MSEK": -0.851645,
    "out:ROI% old": 7.673114,
    "out:Payback discounted": 16,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 16.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 7,
    "out:Return/kSEK/y": 126,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 48222.89723,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 43882.84,
    "out:DH kr savings": 43882.83648,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 182778.8157,
    "out:% savings (space heating)": 15.950233,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.5,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1839875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.791463,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 137.2,
    "out:Total Energy Use Post PV": 137.2,
    "out:Primary Energy": 102.689024,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.883249,
    "out:CO2 Operational/m2": 45.696594,
    "out:Total CO2/m2": 57.579843,
    "out:Total CO2 (tons)": 120284.3251,
    "out:Klimatpaverkan":0.001* 5271.937634,
    "out:Initial Cost/MSEK": 1.892125,
    "out:Running cost/(Apt SEK y)": 28874.36,
    "out:Running Cost over RSP/MSEK": 5.64371,
    "out:LCP/MSEK": -0.774995,
    "out:ROI% old": 8.434432,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 8,
    "out:Return/kSEK/y": 143,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 55961.29779,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 50924.78,
    "out:DH kr savings": 50924.78099,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 175040.4151,
    "out:% savings (space heating)": 19.508692,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.79,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1892125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.495819,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 142.305876,
    "out:Primary Energy": 99.09765,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.315883,
    "out:CO2 Operational/m2": 28.284445,
    "out:Total CO2/m2": 49.600328,
    "out:Total CO2 (tons)": 103615.1137,
    "out:Klimatpaverkan":0.001* -11397.27378,
    "out:Initial Cost/MSEK": 2.073625,
    "out:Running cost/(Apt SEK y)": 29944.04,
    "out:Running Cost over RSP/MSEK": 5.85901,
    "out:LCP/MSEK": -1.171795,
    "out:ROI% old": 6.212929,
    "out:Payback discounted": 20,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 14.8,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 6,
    "out:Return/kSEK/y": 116,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 34686.17351,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 59725.59,
    "out:DH kr savings": 31564.4179,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 182778.8157,
    "out:% savings (space heating)": 15.950233,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.5,
    "out:Etvv": 25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2073625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.791463,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 138.305876,
    "out:Primary Energy": 96.504601,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.315883,
    "out:CO2 Operational/m2": 26.844509,
    "out:Total CO2/m2": 48.160392,
    "out:Total CO2 (tons)": 100607.0865,
    "out:Klimatpaverkan":0.001* -14405.30091,
    "out:Initial Cost/MSEK": 2.125875,
    "out:Running cost/(Apt SEK y)": 29065.56,
    "out:Running Cost over RSP/MSEK": 5.68715,
    "out:LCP/MSEK": -1.052185,
    "out:ROI% old": 7.215113,
    "out:Payback discounted": 17,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 18.1,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 6,
    "out:Return/kSEK/y": 138,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 42424.57407,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 66767.53,
    "out:DH kr savings": 38606.3624,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 175040.4151,
    "out:% savings (space heating)": 19.508692,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.79,
    "out:Etvv": 25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2125875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.495819,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 135.305876,
    "out:Primary Energy": 96.47265,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.765859,
    "out:CO2 Operational/m2": 25.764557,
    "out:Total CO2/m2": 47.530416,
    "out:Total CO2 (tons)": 99291.06631,
    "out:Klimatpaverkan":0.001* -15721.32114,
    "out:Initial Cost/MSEK": 2.1875,
    "out:Running cost/(Apt SEK y)": 28406.76,
    "out:Running Cost over RSP/MSEK": 5.55825,
    "out:LCP/MSEK": -0.98491,
    "out:ROI% old": 7.853649,
    "out:Payback discounted": 15,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 20.7,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 7,
    "out:Return/kSEK/y": 154,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 48222.89723,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 72044.01,
    "out:DH kr savings": 43882.83648,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 182778.8157,
    "out:% savings (space heating)": 15.950233,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.5,
    "out:Etvv": 21.25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2187500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.791463,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 137.2,
    "out:Total Energy Use Post PV": 132.305876,
    "out:Primary Energy": 93.879601,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.765859,
    "out:CO2 Operational/m2": 24.684605,
    "out:Total CO2/m2": 46.450464,
    "out:Total CO2 (tons)": 97035.04596,
    "out:Klimatpaverkan":0.001* -17977.34149,
    "out:Initial Cost/MSEK": 2.23975,
    "out:Running cost/(Apt SEK y)": 27747.92,
    "out:Running Cost over RSP/MSEK": 5.42935,
    "out:LCP/MSEK": -0.90826,
    "out:ROI% old": 8.492593,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 23.5,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 8,
    "out:Return/kSEK/y": 171,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 55961.29779,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 79085.95,
    "out:DH kr savings": 50924.78099,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 175040.4151,
    "out:% savings (space heating)": 19.508692,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.79,
    "out:Etvv": 21.25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2239750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.495819,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 141.330546,
    "out:Primary Energy": 97.342056,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.198493,
    "out:CO2 Operational/m2": -12.351013,
    "out:Total CO2/m2": 18.84748,
    "out:Total CO2 (tons)": 39372.39654,
    "out:Klimatpaverkan":0.001* -75639.99091,
    "out:Initial Cost/MSEK": 2.42125,
    "out:Running cost/(Apt SEK y)": 28956.76,
    "out:Running Cost over RSP/MSEK": 5.6713,
    "out:LCP/MSEK": -1.33171,
    "out:ROI% old": 6.428439,
    "out:Payback discounted": 19,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 15.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 6,
    "out:Return/kSEK/y": 141,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 34686.17351,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 84407.56,
    "out:DH kr savings": 31564.4179,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 182778.8157,
    "out:% savings (space heating)": 15.950233,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.5,
    "out:Etvv": 25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2421250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.791463,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 137.330546,
    "out:Primary Energy": 94.749007,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.198493,
    "out:CO2 Operational/m2": -13.790949,
    "out:Total CO2/m2": 17.407544,
    "out:Total CO2 (tons)": 36364.36941,
    "out:Klimatpaverkan":0.001* -78648.01804,
    "out:Initial Cost/MSEK": 2.4735,
    "out:Running cost/(Apt SEK y)": 28078.28,
    "out:Running Cost over RSP/MSEK": 5.49943,
    "out:LCP/MSEK": -1.21209,
    "out:ROI% old": 7.285281,
    "out:Payback discounted": 17,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 7,
    "out:Return/kSEK/y": 163,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 42424.57407,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 91449.5,
    "out:DH kr savings": 38606.3624,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 175040.4151,
    "out:% savings (space heating)": 19.508692,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.79,
    "out:Etvv": 25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2473500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.495819,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 134.330546,
    "out:Primary Energy": 94.717056,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.648469,
    "out:CO2 Operational/m2": -14.870901,
    "out:Total CO2/m2": 16.777568,
    "out:Total CO2 (tons)": 35048.34918,
    "out:Klimatpaverkan":0.001* -79964.03826,
    "out:Initial Cost/MSEK": 2.535125,
    "out:Running cost/(Apt SEK y)": 27419.48,
    "out:Running Cost over RSP/MSEK": 5.37053,
    "out:LCP/MSEK": -1.144815,
    "out:ROI% old": 7.834553,
    "out:Payback discounted": 16,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 21.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 7,
    "out:Return/kSEK/y": 179,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 48222.89723,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96725.97,
    "out:DH kr savings": 43882.83648,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 182778.8157,
    "out:% savings (space heating)": 15.950233,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.5,
    "out:Etvv": 21.25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2535125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.791463,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 137.2,
    "out:Total Energy Use Post PV": 131.330546,
    "out:Primary Energy": 92.124007,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.648469,
    "out:CO2 Operational/m2": -15.950853,
    "out:Total CO2/m2": 15.697616,
    "out:Total CO2 (tons)": 32792.32883,
    "out:Klimatpaverkan":0.001* -82220.05861,
    "out:Initial Cost/MSEK": 2.587375,
    "out:Running cost/(Apt SEK y)": 26760.64,
    "out:Running Cost over RSP/MSEK": 5.24163,
    "out:LCP/MSEK": -1.068165,
    "out:ROI% old": 8.388038,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 8,
    "out:Return/kSEK/y": 196,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 55961.29779,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 103767.92,
    "out:DH kr savings": 50924.78099,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 175040.4151,
    "out:% savings (space heating)": 19.508692,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.79,
    "out:Etvv": 21.25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2587375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.495819,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 138.2,
    "out:Primary Energy": 104.407073,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.433273,
    "out:CO2 Operational/m2": 46.056578,
    "out:Total CO2/m2": 57.489851,
    "out:Total CO2 (tons)": 120096.3317,
    "out:Klimatpaverkan":0.001* 5083.944294,
    "out:Initial Cost/MSEK": 1.792,
    "out:Running cost/(Apt SEK y)": 29093.96,
    "out:Running Cost over RSP/MSEK": 5.68667,
    "out:LCP/MSEK": -0.71783,
    "out:ROI% old": 8.563217,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 18.1,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 8,
    "out:Return/kSEK/y": 137,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 52735.13847,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 47988.98,
    "out:DH kr savings": 47988.97601,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 182778.8157,
    "out:% savings (space heating)": 15.950233,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.5,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1792000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.791463,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 134.2,
    "out:Primary Energy": 101.814024,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.433273,
    "out:CO2 Operational/m2": 44.616642,
    "out:Total CO2/m2": 56.049915,
    "out:Total CO2 (tons)": 117088.3046,
    "out:Klimatpaverkan":0.001* 2075.917164,
    "out:Initial Cost/MSEK": 1.84425,
    "out:Running cost/(Apt SEK y)": 28215.52,
    "out:Running Cost over RSP/MSEK": 5.51481,
    "out:LCP/MSEK": -0.59822,
    "out:ROI% old": 9.651852,
    "out:Payback discounted": 12,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 21.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 9,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 60473.53903,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 55030.92,
    "out:DH kr savings": 55030.92052,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 175040.4151,
    "out:% savings (space heating)": 19.508692,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.79,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1844250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.495819,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 133.2,
    "out:Primary Energy": 102.307073,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.883249,
    "out:CO2 Operational/m2": 44.256658,
    "out:Total CO2/m2": 56.139907,
    "out:Total CO2 (tons)": 117276.2979,
    "out:Klimatpaverkan":0.001* 2263.910503,
    "out:Initial Cost/MSEK": 1.905875,
    "out:Running cost/(Apt SEK y)": 27995.88,
    "out:Running Cost over RSP/MSEK": 5.47184,
    "out:LCP/MSEK": -0.616875,
    "out:ROI% old": 9.661854,
    "out:Payback discounted": 12,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 9,
    "out:Return/kSEK/y": 165,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 63564.51745,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 57843.71,
    "out:DH kr savings": 57843.71088,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 182778.8157,
    "out:% savings (space heating)": 15.950233,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.5,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1905875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.791463,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 129.2,
    "out:Primary Energy": 99.714024,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.883249,
    "out:CO2 Operational/m2": 42.816722,
    "out:Total CO2/m2": 54.699971,
    "out:Total CO2 (tons)": 114268.2708,
    "out:Klimatpaverkan":0.001* -744.116627,
    "out:Initial Cost/MSEK": 1.958,
    "out:Running cost/(Apt SEK y)": 27117.44,
    "out:Running Cost over RSP/MSEK": 5.29998,
    "out:LCP/MSEK": -0.49714,
    "out:ROI% old": 10.658544,
    "out:Payback discounted": 11,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 10,
    "out:Return/kSEK/y": 187,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 71302.918,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 64885.66,
    "out:DH kr savings": 64885.65538,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 175040.4151,
    "out:% savings (space heating)": 19.508692,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.79,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1958000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.495819,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 133.305876,
    "out:Primary Energy": 95.59765,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.315883,
    "out:CO2 Operational/m2": 25.044589,
    "out:Total CO2/m2": 46.360472,
    "out:Total CO2 (tons)": 96847.05262,
    "out:Klimatpaverkan":0.001* -18165.33483,
    "out:Initial Cost/MSEK": 2.139625,
    "out:Running cost/(Apt SEK y)": 27967.52,
    "out:Running Cost over RSP/MSEK": 5.47232,
    "out:LCP/MSEK": -0.851105,
    "out:ROI% old": 8.60311,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 8,
    "out:Return/kSEK/y": 165,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 52735.13847,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 76150.15,
    "out:DH kr savings": 47988.97601,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 182778.8157,
    "out:% savings (space heating)": 15.950233,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.5,
    "out:Etvv": 20,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2139625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.791463,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 129.305876,
    "out:Primary Energy": 93.004601,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.315883,
    "out:CO2 Operational/m2": 23.604653,
    "out:Total CO2/m2": 44.920536,
    "out:Total CO2 (tons)": 93839.02549,
    "out:Klimatpaverkan":0.001* -21173.36196,
    "out:Initial Cost/MSEK": 2.191875,
    "out:Running cost/(Apt SEK y)": 27089.08,
    "out:Running Cost over RSP/MSEK": 5.30045,
    "out:LCP/MSEK": -0.731485,
    "out:ROI% old": 9.518204,
    "out:Payback discounted": 13,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 9,
    "out:Return/kSEK/y": 187,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 60473.53903,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83192.09,
    "out:DH kr savings": 55030.92052,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 175040.4151,
    "out:% savings (space heating)": 19.508692,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.79,
    "out:Etvv": 20,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2191875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.495819,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 128.305876,
    "out:Primary Energy": 93.49765,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.765859,
    "out:CO2 Operational/m2": 23.244669,
    "out:Total CO2/m2": 45.010528,
    "out:Total CO2 (tons)": 94027.01883,
    "out:Klimatpaverkan":0.001* -20985.36862,
    "out:Initial Cost/MSEK": 2.2535,
    "out:Running cost/(Apt SEK y)": 26869.44,
    "out:Running Cost over RSP/MSEK": 5.25748,
    "out:LCP/MSEK": -0.75014,
    "out:ROI% old": 9.530318,
    "out:Payback discounted": 13,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 27.3,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 9,
    "out:Return/kSEK/y": 193,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 63564.51745,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 86004.88,
    "out:DH kr savings": 57843.71088,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 182778.8157,
    "out:% savings (space heating)": 15.950233,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.5,
    "out:Etvv": 17,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2253500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.791463,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 124.305876,
    "out:Primary Energy": 90.904601,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.765859,
    "out:CO2 Operational/m2": 21.804733,
    "out:Total CO2/m2": 43.570592,
    "out:Total CO2 (tons)": 91018.9917,
    "out:Klimatpaverkan":0.001* -23993.39575,
    "out:Initial Cost/MSEK": 2.305625,
    "out:Running cost/(Apt SEK y)": 25991,
    "out:Running Cost over RSP/MSEK": 5.08562,
    "out:LCP/MSEK": -0.630405,
    "out:ROI% old": 10.379708,
    "out:Payback discounted": 12,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 9,
    "out:Return/kSEK/y": 215,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 71302.918,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93046.83,
    "out:DH kr savings": 64885.65538,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 175040.4151,
    "out:% savings (space heating)": 19.508692,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.79,
    "out:Etvv": 17,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2305625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.495819,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 132.330546,
    "out:Primary Energy": 93.842056,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.198493,
    "out:CO2 Operational/m2": -15.590869,
    "out:Total CO2/m2": 15.607624,
    "out:Total CO2 (tons)": 32604.3355,
    "out:Klimatpaverkan":0.001* -82408.05195,
    "out:Initial Cost/MSEK": 2.48725,
    "out:Running cost/(Apt SEK y)": 26980.24,
    "out:Running Cost over RSP/MSEK": 5.2846,
    "out:LCP/MSEK": -1.01101,
    "out:ROI% old": 8.4789,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 23.5,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 8,
    "out:Return/kSEK/y": 190,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 52735.13847,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 100832.11,
    "out:DH kr savings": 47988.97601,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 182778.8157,
    "out:% savings (space heating)": 15.950233,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.5,
    "out:Etvv": 20,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2487250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.791463,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 128.330546,
    "out:Primary Energy": 91.249007,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.198493,
    "out:CO2 Operational/m2": -17.030805,
    "out:Total CO2/m2": 14.167688,
    "out:Total CO2 (tons)": 29596.30836,
    "out:Klimatpaverkan":0.001* -85416.07908,
    "out:Initial Cost/MSEK": 2.5395,
    "out:Running cost/(Apt SEK y)": 26101.8,
    "out:Running Cost over RSP/MSEK": 5.11274,
    "out:LCP/MSEK": -0.8914,
    "out:ROI% old": 9.271229,
    "out:Payback discounted": 13,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 27.3,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 8,
    "out:Return/kSEK/y": 212,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 60473.53903,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107874.06,
    "out:DH kr savings": 55030.92052,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 175040.4151,
    "out:% savings (space heating)": 19.508692,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.79,
    "out:Etvv": 20,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2539500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.495819,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 127.330546,
    "out:Primary Energy": 91.742056,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.648469,
    "out:CO2 Operational/m2": -17.390789,
    "out:Total CO2/m2": 14.25768,
    "out:Total CO2 (tons)": 29784.3017,
    "out:Klimatpaverkan":0.001* -85228.08574,
    "out:Initial Cost/MSEK": 2.601125,
    "out:Running cost/(Apt SEK y)": 25882.16,
    "out:Running Cost over RSP/MSEK": 5.06977,
    "out:LCP/MSEK": -0.910055,
    "out:ROI% old": 9.287575,
    "out:Payback discounted": 13,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 28.3,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 8,
    "out:Return/kSEK/y": 218,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 63564.51745,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110686.85,
    "out:DH kr savings": 57843.71088,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 182778.8157,
    "out:% savings (space heating)": 15.950233,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.5,
    "out:Etvv": 17,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2601125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.791463,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 123.330546,
    "out:Primary Energy": 89.149007,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.648469,
    "out:CO2 Operational/m2": -18.830725,
    "out:Total CO2/m2": 12.817744,
    "out:Total CO2 (tons)": 26776.27457,
    "out:Klimatpaverkan":0.001* -88236.11287,
    "out:Initial Cost/MSEK": 2.65325,
    "out:Running cost/(Apt SEK y)": 25003.72,
    "out:Running Cost over RSP/MSEK": 4.8979,
    "out:LCP/MSEK": -0.79031,
    "out:ROI% old": 10.030502,
    "out:Payback discounted": 12,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 32.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 9,
    "out:Return/kSEK/y": 240,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 71302.918,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117728.79,
    "out:DH kr savings": 64885.65538,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 175040.4151,
    "out:% savings (space heating)": 19.508692,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.79,
    "out:Etvv": 17,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2653250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.583101,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.079111,
    "out:Electricity (inc PV)": 40.497595,
    "out:Total Energy Use Pre PV": 93.079111,
    "out:Total Energy Use Post PV": 90.497595,
    "out:Primary Energy": 100.827145,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.131351,
    "out:CO2 Operational/m2": 23.685987,
    "out:Total CO2/m2": 39.817338,
    "out:Total CO2 (tons)": 83178.44194,
    "out:Klimatpaverkan":0.001* -31833.94551,
    "out:Initial Cost/MSEK": 3.021,
    "out:Running cost/(Apt SEK y)": 17100.08,
    "out:Running Cost over RSP/MSEK": 3.33104,
    "out:LCP/MSEK": 0.4088,
    "out:ROI% old": 16.218849,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 81.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 3015.53777,
    "out:Return %": 14,
    "out:Return/kSEK/y": 437,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 24.579611,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 204130.6876,
    "out:EL kWh savings": -50757.6899,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99470.85,
    "out:DH kr savings": 185758.9257,
    "out:El kr savings": -86288.07283,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 103579.1265,
    "out:% savings (space heating)": 52.369746,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 39.9,
    "out:Etvv": 0,
    "out:Ef": 40.5,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3021000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.047464,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.027984,
    "out:Electricity (inc PV)": 40.410444,
    "out:Total Energy Use Pre PV": 89.027984,
    "out:Total Energy Use Post PV": 86.410444,
    "out:Primary Energy": 98.278543,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.131351,
    "out:CO2 Operational/m2": 22.239302,
    "out:Total CO2/m2": 38.370653,
    "out:Total CO2 (tons)": 80156.31614,
    "out:Klimatpaverkan":0.001* -34856.0713,
    "out:Initial Cost/MSEK": 3.07325,
    "out:Running cost/(Apt SEK y)": 16214.4,
    "out:Running Cost over RSP/MSEK": 3.15777,
    "out:LCP/MSEK": 0.52982,
    "out:ROI% old": 16.748533,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 89.5,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 3011.95888,
    "out:Return %": 15,
    "out:Return/kSEK/y": 459,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 24.528484,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 211516.6353,
    "out:EL kWh savings": -50575.63141,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 106501.56,
    "out:DH kr savings": 192480.1381,
    "out:El kr savings": -85978.5734,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 96193.17873,
    "out:% savings (space heating)": 55.766131,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 36.49,
    "out:Etvv": 0,
    "out:Ef": 40.41,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3073250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.05845,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.183551,
    "out:Electricity (inc PV)": 39.936099,
    "out:Total Energy Use Pre PV": 88.183551,
    "out:Total Energy Use Post PV": 85.936099,
    "out:Primary Energy": 98.488507,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.581327,
    "out:CO2 Operational/m2": 22.12783,
    "out:Total CO2/m2": 38.709157,
    "out:Total CO2 (tons)": 80863.45119,
    "out:Klimatpaverkan":0.001* -34148.93625,
    "out:Initial Cost/MSEK": 3.134875,
    "out:Running cost/(Apt SEK y)": 16094.44,
    "out:Running Cost over RSP/MSEK": 3.13458,
    "out:LCP/MSEK": 0.491385,
    "out:ROI% old": 16.52497,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 89.5,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2952.84857,
    "out:Return %": 15,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.684051,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 211493.6855,
    "out:EL kWh savings": -49584.72444,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108165.22,
    "out:DH kr savings": 192459.2538,
    "out:El kr savings": -84294.03154,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 96216.12849,
    "out:% savings (space heating)": 55.755578,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 38.01,
    "out:Etvv": 0,
    "out:Ef": 39.94,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3134875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.556595,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.122187,
    "out:Electricity (inc PV)": 39.840086,
    "out:Total Energy Use Pre PV": 85.122187,
    "out:Total Energy Use Post PV": 82.840086,
    "out:Primary Energy": 95.944425,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.581327,
    "out:CO2 Operational/m2": 21.039778,
    "out:Total CO2/m2": 37.621105,
    "out:Total CO2 (tons)": 78590.50994,
    "out:Klimatpaverkan":0.001* -36421.8775,
    "out:Initial Cost/MSEK": 3.187125,
    "out:Running cost/(Apt SEK y)": 15426.88,
    "out:Running Cost over RSP/MSEK": 3.004,
    "out:LCP/MSEK": 0.569715,
    "out:ROI% old": 16.839359,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 96.4,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2948.55309,
    "out:Return %": 15,
    "out:Return/kSEK/y": 479,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.622687,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 218809.0626,
    "out:EL kWh savings": -49384.15322,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115163.19,
    "out:DH kr savings": 199116.247,
    "out:El kr savings": -83953.06048,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 88900.75138,
    "out:% savings (space heating)": 59.119511,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 34.62,
    "out:Etvv": 0,
    "out:Ef": 39.84,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3187125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.583101,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.079111,
    "out:Electricity (inc PV)": 32.366472,
    "out:Total Energy Use Pre PV": 93.079111,
    "out:Total Energy Use Post PV": 82.366472,
    "out:Primary Energy": 86.191124,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.013961,
    "out:CO2 Operational/m2": 19.592231,
    "out:Total CO2/m2": 45.606192,
    "out:Total CO2 (tons)": 95271.36127,
    "out:Klimatpaverkan":0.001* -19741.02618,
    "out:Initial Cost/MSEK": 3.368625,
    "out:Running cost/(Apt SEK y)": 15852.4,
    "out:Running Cost over RSP/MSEK": 3.09372,
    "out:LCP/MSEK": 0.298495,
    "out:ROI% old": 15.551576,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 98.8,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2460.808181,
    "out:Return %": 14,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 24.579611,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1323.950287,
    "out:PV (% sold (El))": 7.087813,
    "out:PV (kWh self-consumed)": 17355.30067,
    "out:PV (ratio sold/self-consumed)": 0.076285,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 204130.6876,
    "out:EL kWh savings": -33771.76929,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130034.95,
    "out:DH kr savings": 185758.9257,
    "out:El kr savings": -57412.00779,
    "out:El kr sold": 1688.036616,
    "out:El kr saved": 29504.01114,
    "out:El kr return": 31192.04775,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 103579.1265,
    "out:% savings (space heating)": 52.369746,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 39.9,
    "out:Etvv": 0,
    "out:Ef": 32.37,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3368625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.047464,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.027984,
    "out:Electricity (inc PV)": 32.285732,
    "out:Total Energy Use Pre PV": 89.027984,
    "out:Total Energy Use Post PV": 78.285732,
    "out:Primary Energy": 83.654062,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.013961,
    "out:CO2 Operational/m2": 18.129845,
    "out:Total CO2/m2": 44.143806,
    "out:Total CO2 (tons)": 92216.43607,
    "out:Klimatpaverkan":0.001* -22795.95137,
    "out:Initial Cost/MSEK": 3.420875,
    "out:Running cost/(Apt SEK y)": 14966.8,
    "out:Running Cost over RSP/MSEK": 2.92047,
    "out:LCP/MSEK": 0.419495,
    "out:ROI% old": 16.037543,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 109,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2457.417627,
    "out:Return %": 14,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 24.528484,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1330.741385,
    "out:PV (% sold (El))": 7.124169,
    "out:PV (kWh self-consumed)": 17348.50957,
    "out:PV (ratio sold/self-consumed)": 0.076706,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211516.6353,
    "out:EL kWh savings": -33603.10338,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 137051.56,
    "out:DH kr savings": 192480.1381,
    "out:El kr savings": -57125.27575,
    "out:El kr sold": 1696.695265,
    "out:El kr saved": 29492.46627,
    "out:El kr return": 31189.16154,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 96193.17873,
    "out:% savings (space heating)": 55.766131,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 36.49,
    "out:Etvv": 0,
    "out:Ef": 32.29,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3420875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.05845,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.183551,
    "out:Electricity (inc PV)": 31.8467,
    "out:Total Energy Use Pre PV": 88.183551,
    "out:Total Energy Use Post PV": 77.8467,
    "out:Primary Energy": 83.927589,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.463937,
    "out:CO2 Operational/m2": 17.751645,
    "out:Total CO2/m2": 44.215582,
    "out:Total CO2 (tons)": 92366.37618,
    "out:Klimatpaverkan":0.001* -22646.01127,
    "out:Initial Cost/MSEK": 3.4825,
    "out:Running cost/(Apt SEK y)": 14848.84,
    "out:Running Cost over RSP/MSEK": 2.89765,
    "out:LCP/MSEK": 0.38069,
    "out:ROI% old": 15.847359,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 109,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2401.517595,
    "out:Return %": 14,
    "out:Return/kSEK/y": 493,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.684051,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1445.857243,
    "out:PV (% sold (El))": 7.740446,
    "out:PV (kWh self-consumed)": 17233.39371,
    "out:PV (ratio sold/self-consumed)": 0.083899,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211493.6855,
    "out:EL kWh savings": -32685.96528,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138736.58,
    "out:DH kr savings": 192459.2538,
    "out:El kr savings": -55566.14098,
    "out:El kr sold": 1843.467984,
    "out:El kr saved": 29296.76931,
    "out:El kr return": 31140.2373,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 96216.12849,
    "out:% savings (space heating)": 55.755578,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 38.01,
    "out:Etvv": 0,
    "out:Ef": 31.85,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3482500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.556595,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.122187,
    "out:Electricity (inc PV)": 31.757957,
    "out:Total Energy Use Pre PV": 85.122187,
    "out:Total Energy Use Post PV": 74.757957,
    "out:Primary Energy": 81.396593,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.463937,
    "out:CO2 Operational/m2": 16.643644,
    "out:Total CO2/m2": 43.107581,
    "out:Total CO2 (tons)": 90051.76145,
    "out:Klimatpaverkan":0.001* -24960.62599,
    "out:Initial Cost/MSEK": 3.53475,
    "out:Running cost/(Apt SEK y)": 14181.4,
    "out:Running Cost over RSP/MSEK": 2.76709,
    "out:LCP/MSEK": 0.459,
    "out:ROI% old": 16.140766,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 117.3,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2397.462668,
    "out:Return %": 14,
    "out:Return/kSEK/y": 510,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.622687,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1454.455201,
    "out:PV (% sold (El))": 7.786475,
    "out:PV (kWh self-consumed)": 17224.79576,
    "out:PV (ratio sold/self-consumed)": 0.08444,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 218809.0626,
    "out:EL kWh savings": -32500.5811,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145719.69,
    "out:DH kr savings": 199116.247,
    "out:El kr savings": -55250.98788,
    "out:El kr sold": 1854.430381,
    "out:El kr saved": 29282.15278,
    "out:El kr return": 31136.58316,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 88900.75138,
    "out:% savings (space heating)": 59.119511,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 34.62,
    "out:Etvv": 0,
    "out:Ef": 31.76,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3534750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.583101,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.079111,
    "out:Electricity (inc PV)": 29.184299,
    "out:Total Energy Use Pre PV": 93.079111,
    "out:Total Energy Use Post PV": 79.184299,
    "out:Primary Energy": 80.463212,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.896571,
    "out:CO2 Operational/m2": -8.504559,
    "out:Total CO2/m2": 27.392012,
    "out:Total CO2 (tons)": 57221.92879,
    "out:Klimatpaverkan":0.001* -57790.45865,
    "out:Initial Cost/MSEK": 3.71625,
    "out:Running cost/(Apt SEK y)": 14777.32,
    "out:Running Cost over RSP/MSEK": 2.88897,
    "out:LCP/MSEK": 0.15562,
    "out:ROI% old": 14.883936,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2214.292686,
    "out:Return %": 13,
    "out:Return/kSEK/y": 495,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 24.579611,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 12801.11639,
    "out:PV (% sold (El))": 34.265604,
    "out:PV (kWh self-consumed)": 24557.38552,
    "out:PV (ratio sold/self-consumed)": 0.521274,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 204130.6876,
    "out:EL kWh savings": -27124.20806,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155969.2,
    "out:DH kr savings": 185758.9257,
    "out:El kr savings": -46111.15371,
    "out:El kr sold": 16321.4234,
    "out:El kr saved": 41747.55539,
    "out:El kr return": 58068.97879,
    "out:% solar/total": 73,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 103579.1265,
    "out:% savings (space heating)": 52.369746,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 39.9,
    "out:Etvv": 0,
    "out:Ef": 29.18,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3716250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.047464,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.027984,
    "out:Electricity (inc PV)": 29.112429,
    "out:Total Energy Use Pre PV": 89.027984,
    "out:Total Energy Use Post PV": 75.112429,
    "out:Primary Energy": 77.942116,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.896571,
    "out:CO2 Operational/m2": -9.994112,
    "out:Total CO2/m2": 25.902459,
    "out:Total CO2 (tons)": 54110.25172,
    "out:Klimatpaverkan":0.001* -60902.13573,
    "out:Initial Cost/MSEK": 3.7685,
    "out:Running cost/(Apt SEK y)": 13891.92,
    "out:Running Cost over RSP/MSEK": 2.71575,
    "out:LCP/MSEK": 0.27659,
    "out:ROI% old": 15.334218,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 117.3,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2211.288731,
    "out:Return %": 14,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 24.528484,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 12819.06076,
    "out:PV (% sold (El))": 34.313637,
    "out:PV (kWh self-consumed)": 24539.44116,
    "out:PV (ratio sold/self-consumed)": 0.522386,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211516.6353,
    "out:EL kWh savings": -26974.07159,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162968.52,
    "out:DH kr savings": 192480.1381,
    "out:El kr savings": -45855.92171,
    "out:El kr sold": 16344.30246,
    "out:El kr saved": 41717.04997,
    "out:El kr return": 58061.35243,
    "out:% solar/total": 73,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 96193.17873,
    "out:% savings (space heating)": 55.766131,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 36.49,
    "out:Etvv": 0,
    "out:Ef": 29.11,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3768500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.05845,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.183551,
    "out:Electricity (inc PV)": 28.721619,
    "out:Total Energy Use Pre PV": 88.183551,
    "out:Total Energy Use Post PV": 74.721619,
    "out:Primary Energy": 78.302443,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.346547,
    "out:CO2 Operational/m2": -10.818352,
    "out:Total CO2/m2": 25.528195,
    "out:Total CO2 (tons)": 53328.41401,
    "out:Klimatpaverkan":0.001* -61683.97344,
    "out:Initial Cost/MSEK": 3.830125,
    "out:Running cost/(Apt SEK y)": 13777.04,
    "out:Running Cost over RSP/MSEK": 2.69354,
    "out:LCP/MSEK": 0.237175,
    "out:ROI% old": 15.170337,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 117.3,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2161.744048,
    "out:Return %": 14,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.684051,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 13117.27694,
    "out:PV (% sold (El))": 35.111892,
    "out:PV (kWh self-consumed)": 24241.22498,
    "out:PV (ratio sold/self-consumed)": 0.541114,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211493.6855,
    "out:EL kWh savings": -26157.66928,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164715.74,
    "out:DH kr savings": 192459.2538,
    "out:El kr savings": -44468.03777,
    "out:El kr sold": 16724.5281,
    "out:El kr saved": 41210.08246,
    "out:El kr return": 57934.61055,
    "out:% solar/total": 72,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 96216.12849,
    "out:% savings (space heating)": 55.755578,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 38.01,
    "out:Etvv": 0,
    "out:Ef": 28.72,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3830125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.556595,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.122187,
    "out:Electricity (inc PV)": 28.642546,
    "out:Total Energy Use Pre PV": 85.122187,
    "out:Total Energy Use Post PV": 71.642546,
    "out:Primary Energy": 75.788853,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.346547,
    "out:CO2 Operational/m2": -11.95855,
    "out:Total CO2/m2": 24.387997,
    "out:Total CO2 (tons)": 50946.53973,
    "out:Klimatpaverkan":0.001* -64065.84771,
    "out:Initial Cost/MSEK": 3.882375,
    "out:Running cost/(Apt SEK y)": 13109.88,
    "out:Running Cost over RSP/MSEK": 2.56303,
    "out:LCP/MSEK": 0.315435,
    "out:ROI% old": 15.446399,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 126.4,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2158.148756,
    "out:Return %": 14,
    "out:Return/kSEK/y": 537,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.622687,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 13139.08936,
    "out:PV (% sold (El))": 35.170279,
    "out:PV (kWh self-consumed)": 24219.41256,
    "out:PV (ratio sold/self-consumed)": 0.542502,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 218809.0626,
    "out:EL kWh savings": -25992.48574,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171681.36,
    "out:DH kr savings": 199116.247,
    "out:El kr savings": -44187.22575,
    "out:El kr sold": 16752.33893,
    "out:El kr saved": 41173.00135,
    "out:El kr return": 57925.34027,
    "out:% solar/total": 72,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 88900.75138,
    "out:% savings (space heating)": 59.119511,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 34.62,
    "out:Etvv": 0,
    "out:Ef": 28.64,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3882375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.901789,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.879509,
    "out:Electricity (inc PV)": 39.746829,
    "out:Total Energy Use Pre PV": 86.879509,
    "out:Total Energy Use Post PV": 84.746829,
    "out:Primary Energy": 97.710036,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.131351,
    "out:CO2 Operational/m2": 21.72771,
    "out:Total CO2/m2": 37.859061,
    "out:Total CO2 (tons)": 79087.60016,
    "out:Klimatpaverkan":0.001* -35924.78728,
    "out:Initial Cost/MSEK": 3.087,
    "out:Running cost/(Apt SEK y)": 15831.64,
    "out:Running Cost over RSP/MSEK": 3.08327,
    "out:LCP/MSEK": 0.59057,
    "out:ROI% old": 17.018696,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 91.8,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2931.56563,
    "out:Return %": 15,
    "out:Return/kSEK/y": 469,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.380009,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213909.951,
    "out:EL kWh savings": -49189.3393,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111036.18,
    "out:DH kr savings": 194658.0554,
    "out:El kr savings": -83621.8768,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 93799.863,
    "out:% savings (space heating)": 56.866683,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 37.38,
    "out:Etvv": 0,
    "out:Ef": 39.75,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3087000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.412362,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.814379,
    "out:Electricity (inc PV)": 39.647317,
    "out:Total Energy Use Pre PV": 82.814379,
    "out:Total Energy Use Post PV": 80.647317,
    "out:Primary Energy": 95.167738,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.131351,
    "out:CO2 Operational/m2": 20.279177,
    "out:Total CO2/m2": 36.410528,
    "out:Total CO2 (tons)": 76061.61389,
    "out:Klimatpaverkan":0.001* -38950.77355,
    "out:Initial Cost/MSEK": 3.13925,
    "out:Running cost/(Apt SEK y)": 14943.92,
    "out:Running Cost over RSP/MSEK": 2.90962,
    "out:LCP/MSEK": 0.71197,
    "out:ROI% old": 17.52566,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 101.2,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2927.00653,
    "out:Return %": 16,
    "out:Return/kSEK/y": 491,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.314879,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221199.366,
    "out:EL kWh savings": -48981.45867,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118022.94,
    "out:DH kr savings": 201291.4231,
    "out:El kr savings": -83268.47974,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 86510.44799,
    "out:% savings (space heating)": 60.218678,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 34,
    "out:Etvv": 0,
    "out:Ef": 39.65,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3139250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.164579,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.138057,
    "out:Electricity (inc PV)": 39.287778,
    "out:Total Energy Use Pre PV": 83.138057,
    "out:Total Energy Use Post PV": 81.287778,
    "out:Primary Energy": 95.844151,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.581327,
    "out:CO2 Operational/m2": 20.549881,
    "out:Total CO2/m2": 37.131208,
    "out:Total CO2 (tons)": 77567.11483,
    "out:Klimatpaverkan":0.001* -37445.27262,
    "out:Initial Cost/MSEK": 3.200875,
    "out:Running cost/(Apt SEK y)": 15067.48,
    "out:Running Cost over RSP/MSEK": 2.93402,
    "out:LCP/MSEK": 0.625945,
    "out:ROI% old": 17.079348,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 101.2,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2879.66399,
    "out:Return %": 15,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.638557,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 219627.9843,
    "out:EL kWh savings": -48230.38149,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117869.82,
    "out:DH kr savings": 199861.4657,
    "out:El kr savings": -81991.64854,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 88081.82973,
    "out:% savings (space heating)": 59.496087,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 35.89,
    "out:Etvv": 0,
    "out:Ef": 39.29,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3200875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.709224,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.062603,
    "out:Electricity (inc PV)": 39.178441,
    "out:Total Energy Use Pre PV": 80.062603,
    "out:Total Energy Use Post PV": 78.178441,
    "out:Primary Energy": 93.306865,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.581327,
    "out:CO2 Operational/m2": 19.459968,
    "out:Total CO2/m2": 36.041295,
    "out:Total CO2 (tons)": 75290.28594,
    "out:Klimatpaverkan":0.001* -39722.1015,
    "out:Initial Cost/MSEK": 3.253,
    "out:Running cost/(Apt SEK y)": 14397.92,
    "out:Running Cost over RSP/MSEK": 2.80304,
    "out:LCP/MSEK": 0.7048,
    "out:ROI% old": 17.380879,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 109,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2874.38221,
    "out:Return %": 16,
    "out:Return/kSEK/y": 505,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.563103,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 226846.2229,
    "out:EL kWh savings": -48001.97644,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124826.7,
    "out:DH kr savings": 206430.0628,
    "out:El kr savings": -81603.35995,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 80863.59116,
    "out:% savings (space heating)": 62.815352,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 32.55,
    "out:Etvv": 0,
    "out:Ef": 39.18,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3253000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.901789,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.879509,
    "out:Electricity (inc PV)": 31.671777,
    "out:Total Energy Use Pre PV": 86.879509,
    "out:Total Energy Use Post PV": 76.671777,
    "out:Primary Energy": 83.174943,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.013961,
    "out:CO2 Operational/m2": 17.251862,
    "out:Total CO2/m2": 43.265823,
    "out:Total CO2 (tons)": 90382.32908,
    "out:Klimatpaverkan":0.001* -24630.05836,
    "out:Initial Cost/MSEK": 3.434625,
    "out:Running cost/(Apt SEK y)": 14586.76,
    "out:Running Cost over RSP/MSEK": 2.84647,
    "out:LCP/MSEK": 0.479745,
    "out:ROI% old": 16.281129,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 111.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2381.436589,
    "out:Return %": 15,
    "out:Return/kSEK/y": 500,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.380009,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1488.943964,
    "out:PV (% sold (El))": 7.971112,
    "out:PV (kWh self-consumed)": 17190.30699,
    "out:PV (ratio sold/self-consumed)": 0.086615,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213909.951,
    "out:EL kWh savings": -32320.55103,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141611.52,
    "out:DH kr savings": 194658.0554,
    "out:El kr savings": -54944.93676,
    "out:El kr sold": 1898.403554,
    "out:El kr saved": 29223.52189,
    "out:El kr return": 31121.92544,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 93799.863,
    "out:% savings (space heating)": 56.866683,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 37.38,
    "out:Etvv": 0,
    "out:Ef": 31.67,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3434625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.412362,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.814379,
    "out:Electricity (inc PV)": 31.579831,
    "out:Total Energy Use Pre PV": 82.814379,
    "out:Total Energy Use Post PV": 72.579831,
    "out:Primary Energy": 80.646263,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.013961,
    "out:CO2 Operational/m2": 15.781724,
    "out:Total CO2/m2": 41.795685,
    "out:Total CO2 (tons)": 87311.20996,
    "out:Klimatpaverkan":0.001* -27701.17749,
    "out:Initial Cost/MSEK": 3.486875,
    "out:Running cost/(Apt SEK y)": 13699.2,
    "out:Running Cost over RSP/MSEK": 2.67284,
    "out:LCP/MSEK": 0.601125,
    "out:ROI% old": 16.748521,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 123.3,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2377.138245,
    "out:Return %": 15,
    "out:Return/kSEK/y": 522,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.314879,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1498.267408,
    "out:PV (% sold (El))": 8.021025,
    "out:PV (kWh self-consumed)": 17180.98355,
    "out:PV (ratio sold/self-consumed)": 0.087205,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221199.366,
    "out:EL kWh savings": -32128.47579,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148583.31,
    "out:DH kr savings": 201291.4231,
    "out:El kr savings": -54618.40884,
    "out:El kr sold": 1910.290945,
    "out:El kr saved": 29207.67203,
    "out:El kr return": 31117.96298,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 86510.44799,
    "out:% savings (space heating)": 60.218678,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 34,
    "out:Etvv": 0,
    "out:Ef": 31.58,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3486875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.164579,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.138057,
    "out:Electricity (inc PV)": 31.247973,
    "out:Total Energy Use Pre PV": 83.138057,
    "out:Total Energy Use Post PV": 73.247973,
    "out:Primary Energy": 81.372502,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.463937,
    "out:CO2 Operational/m2": 15.823118,
    "out:Total CO2/m2": 42.287055,
    "out:Total CO2 (tons)": 88337.68217,
    "out:Klimatpaverkan":0.001* -26674.70528,
    "out:Initial Cost/MSEK": 3.5485,
    "out:Running cost/(Apt SEK y)": 13824.44,
    "out:Running Cost over RSP/MSEK": 2.69755,
    "out:LCP/MSEK": 0.51479,
    "out:ROI% old": 16.35818,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 123.3,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2332.570308,
    "out:Return %": 15,
    "out:Return/kSEK/y": 519,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.638557,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1596.890592,
    "out:PV (% sold (El))": 8.549008,
    "out:PV (kWh self-consumed)": 17082.36036,
    "out:PV (ratio sold/self-consumed)": 0.093482,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 219627.9843,
    "out:EL kWh savings": -31435.22424,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148457.62,
    "out:DH kr savings": 199861.4657,
    "out:El kr savings": -53439.8812,
    "out:El kr sold": 2036.035505,
    "out:El kr saved": 29040.01262,
    "out:El kr return": 31076.04812,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 88081.82973,
    "out:% savings (space heating)": 59.496087,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 35.89,
    "out:Etvv": 0,
    "out:Ef": 31.25,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3548500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.709224,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.062603,
    "out:Electricity (inc PV)": 31.14723,
    "out:Total Energy Use Pre PV": 80.062603,
    "out:Total Energy Use Post PV": 70.14723,
    "out:Primary Energy": 78.850685,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.463937,
    "out:CO2 Operational/m2": 14.707073,
    "out:Total CO2/m2": 41.17101,
    "out:Total CO2 (tons)": 86006.26352,
    "out:Klimatpaverkan":0.001* -29006.12392,
    "out:Initial Cost/MSEK": 3.600625,
    "out:Running cost/(Apt SEK y)": 13155.08,
    "out:Running Cost over RSP/MSEK": 2.56662,
    "out:LCP/MSEK": 0.593595,
    "out:ROI% old": 16.640841,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 132.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2327.605697,
    "out:Return %": 15,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.563103,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1608.071593,
    "out:PV (% sold (El))": 8.608866,
    "out:PV (kWh self-consumed)": 17071.17936,
    "out:PV (ratio sold/self-consumed)": 0.094198,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 226846.2229,
    "out:EL kWh savings": -31224.77205,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155398.24,
    "out:DH kr savings": 206430.0628,
    "out:El kr savings": -53082.11248,
    "out:El kr sold": 2050.291282,
    "out:El kr saved": 29021.00492,
    "out:El kr return": 31071.2962,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 80863.59116,
    "out:% savings (space heating)": 62.815352,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 32.55,
    "out:Etvv": 0,
    "out:Ef": 31.15,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3600625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.901789,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.879509,
    "out:Electricity (inc PV)": 28.565784,
    "out:Total Energy Use Pre PV": 86.879509,
    "out:Total Energy Use Post PV": 73.565784,
    "out:Primary Energy": 77.584155,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.896571,
    "out:CO2 Operational/m2": -11.47736,
    "out:Total CO2/m2": 24.419211,
    "out:Total CO2 (tons)": 51011.7458,
    "out:Klimatpaverkan":0.001* -64000.64165,
    "out:Initial Cost/MSEK": 3.78225,
    "out:Running cost/(Apt SEK y)": 13516.08,
    "out:Running Cost over RSP/MSEK": 2.64258,
    "out:LCP/MSEK": 0.33601,
    "out:ROI% old": 15.554838,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 120.3,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2143.937092,
    "out:Return %": 14,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.380009,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 13225.49559,
    "out:PV (% sold (El))": 35.401568,
    "out:PV (kWh self-consumed)": 24133.00633,
    "out:PV (ratio sold/self-consumed)": 0.548025,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213909.951,
    "out:EL kWh savings": -25832.12987,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167605.94,
    "out:DH kr savings": 194658.0554,
    "out:El kr savings": -43914.62079,
    "out:El kr sold": 16862.50687,
    "out:El kr saved": 41026.11075,
    "out:El kr return": 57888.61763,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 93799.863,
    "out:% savings (space heating)": 56.866683,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 37.38,
    "out:Etvv": 0,
    "out:Ef": 28.57,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3782250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.412362,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.814379,
    "out:Electricity (inc PV)": 28.483913,
    "out:Total Energy Use Pre PV": 82.814379,
    "out:Total Energy Use Post PV": 69.483913,
    "out:Primary Energy": 75.07361,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.896571,
    "out:CO2 Operational/m2": -12.981526,
    "out:Total CO2/m2": 22.915045,
    "out:Total CO2 (tons)": 47869.54216,
    "out:Klimatpaverkan":0.001* -67142.84529,
    "out:Initial Cost/MSEK": 3.8345,
    "out:Running cost/(Apt SEK y)": 12628.76,
    "out:Running Cost over RSP/MSEK": 2.469,
    "out:LCP/MSEK": 0.45734,
    "out:ROI% old": 15.989568,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 136.2,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2140.124821,
    "out:Return %": 14,
    "out:Return/kSEK/y": 549,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.314879,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 13248.77313,
    "out:PV (% sold (El))": 35.463877,
    "out:PV (kWh self-consumed)": 24109.72878,
    "out:PV (ratio sold/self-consumed)": 0.54952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 221199.366,
    "out:EL kWh savings": -25661.10131,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174559.74,
    "out:DH kr savings": 201291.4231,
    "out:El kr savings": -43623.87222,
    "out:El kr sold": 16892.18574,
    "out:El kr saved": 40986.53892,
    "out:El kr return": 57878.72467,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 86510.44799,
    "out:% savings (space heating)": 60.218678,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 34,
    "out:Etvv": 0,
    "out:Ef": 28.48,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3834500,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z01000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.164579,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.138057,
    "out:Electricity (inc PV)": 28.188284,
    "out:Total Energy Use Pre PV": 83.138057,
    "out:Total Energy Use Post PV": 70.188284,
    "out:Primary Energy": 75.865062,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.346547,
    "out:CO2 Operational/m2": -13.292223,
    "out:Total CO2/m2": 23.054324,
    "out:Total CO2 (tons)": 48160.49607,
    "out:Klimatpaverkan":0.001* -66851.89138,
    "out:Initial Cost/MSEK": 3.896125,
    "out:Running cost/(Apt SEK y)": 12756.44,
    "out:Running Cost over RSP/MSEK": 2.49419,
    "out:LCP/MSEK": 0.370525,
    "out:ROI% old": 15.644299,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 132.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2100.584303,
    "out:Return %": 14,
    "out:Return/kSEK/y": 546,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.638557,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 13491.7782,
    "out:PV (% sold (El))": 36.114345,
    "out:PV (kWh self-consumed)": 23866.72371,
    "out:PV (ratio sold/self-consumed)": 0.565297,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 219627.9843,
    "out:EL kWh savings": -25043.53216,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 174489.48,
    "out:DH kr savings": 199861.4657,
    "out:El kr savings": -42574.00467,
    "out:El kr sold": 17202.01721,
    "out:El kr saved": 40573.43031,
    "out:El kr return": 57775.44751,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 88081.82973,
    "out:% savings (space heating)": 59.496087,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 35.89,
    "out:Etvv": 0,
    "out:Ef": 28.19,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3896125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.709224,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.062603,
    "out:Electricity (inc PV)": 28.098459,
    "out:Total Energy Use Pre PV": 80.062603,
    "out:Total Energy Use Post PV": 67.098459,
    "out:Primary Energy": 73.362897,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.346547,
    "out:CO2 Operational/m2": -14.4474,
    "out:Total CO2/m2": 21.899147,
    "out:Total CO2 (tons)": 45747.33065,
    "out:Klimatpaverkan":0.001* -69265.05679,
    "out:Initial Cost/MSEK": 3.94825,
    "out:Running cost/(Apt SEK y)": 12087.36,
    "out:Running Cost over RSP/MSEK": 2.3633,
    "out:LCP/MSEK": 0.44929,
    "out:ROI% old": 15.911353,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 143.3,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2096.178005,
    "out:Return %": 14,
    "out:Return/kSEK/y": 562,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.563103,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 13519.10293,
    "out:PV (% sold (El))": 36.187487,
    "out:PV (kWh self-consumed)": 23839.39898,
    "out:PV (ratio sold/self-consumed)": 0.567091,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 226846.2229,
    "out:EL kWh savings": -24855.88768,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181411.91,
    "out:DH kr savings": 206430.0628,
    "out:El kr savings": -42255.00906,
    "out:El kr sold": 17236.85624,
    "out:El kr saved": 40526.97827,
    "out:El kr return": 57763.83451,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 80863.59116,
    "out:% savings (space heating)": 62.815352,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 32.55,
    "out:Etvv": 0,
    "out:Ef": 28.1,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3948250,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z01000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.597032,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.499132,
    "out:Electricity (inc PV)": 51.063431,
    "out:Total Energy Use Pre PV": 64.499132,
    "out:Total Energy Use Post PV": 60.063431,
    "out:Primary Energy": 95.43857,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.683845,
    "out:CO2 Operational/m2": 10.566185,
    "out:Total CO2/m2": 27.25003,
    "out:Total CO2 (tons)": 56925.32831,
    "out:Klimatpaverkan":0.001* -58087.05913,
    "out:Initial Cost/MSEK": 4.08175,
    "out:Running cost/(Apt SEK y)": 9860.28,
    "out:Running Cost over RSP/MSEK": 1.9104,
    "out:LCP/MSEK": 0.76869,
    "out:ROI% old": 16.976052,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 171.7,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3884.93924,
    "out:Return %": 15,
    "out:Return/kSEK/y": 618,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 36.999632,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 289750.6092,
    "out:EL kWh savings": -72829.72737,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 139862.52,
    "out:DH kr savings": 263673.0544,
    "out:El kr savings": -123810.5365,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 17959.20478,
    "out:% savings (space heating)": 91.741565,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 5.03,
    "out:Etvv": 0,
    "out:Ef": 51.06,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4081750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.662632,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.962782,
    "out:Electricity (inc PV)": 50.326028,
    "out:Total Energy Use Pre PV": 61.962782,
    "out:Total Energy Use Post PV": 57.326028,
    "out:Primary Energy": 93.221593,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.683845,
    "out:CO2 Operational/m2": 9.775415,
    "out:Total CO2/m2": 26.45926,
    "out:Total CO2 (tons)": 55273.40933,
    "out:Klimatpaverkan":0.001* -59738.97812,
    "out:Initial Cost/MSEK": 4.134,
    "out:Running cost/(Apt SEK y)": 9344.88,
    "out:Running Cost over RSP/MSEK": 1.80974,
    "out:LCP/MSEK": 0.8171,
    "out:ROI% old": 17.109337,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 186,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3847.39474,
    "out:Return %": 15,
    "out:Return/kSEK/y": 631,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 36.463282,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293791.5719,
    "out:EL kWh savings": -71289.29208,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146158.53,
    "out:DH kr savings": 267350.3305,
    "out:El kr savings": -121191.7965,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 13918.24207,
    "out:% savings (space heating)": 93.599778,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.76,
    "out:Etvv": 0,
    "out:Ef": 50.33,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4134000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.138616,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.97744,
    "out:Electricity (inc PV)": 50.089527,
    "out:Total Energy Use Pre PV": 60.97744,
    "out:Total Energy Use Post PV": 57.089527,
    "out:Primary Energy": 93.310259,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.133821,
    "out:CO2 Operational/m2": 9.645342,
    "out:Total CO2/m2": 26.779163,
    "out:Total CO2 (tons)": 55941.68688,
    "out:Klimatpaverkan":0.001* -59070.70057,
    "out:Initial Cost/MSEK": 4.195625,
    "out:Running cost/(Apt SEK y)": 9204.88,
    "out:Running Cost over RSP/MSEK": 1.78268,
    "out:LCP/MSEK": 0.782535,
    "out:ROI% old": 16.950173,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 186,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3778.4208,
    "out:Return %": 15,
    "out:Return/kSEK/y": 634,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 35.47794,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 292797.2411,
    "out:EL kWh savings": -70795.24136,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146093.58,
    "out:DH kr savings": 266445.4894,
    "out:El kr savings": -120351.9103,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 14912.57292,
    "out:% savings (space heating)": 93.142541,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.5,
    "out:Etvv": 0,
    "out:Ef": 50.09,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4195625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.441834,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.369085,
    "out:Electricity (inc PV)": 49.320793,
    "out:Total Energy Use Pre PV": 58.369085,
    "out:Total Energy Use Post PV": 54.320793,
    "out:Primary Energy": 91.109265,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.133821,
    "out:CO2 Operational/m2": 8.845066,
    "out:Total CO2/m2": 25.978887,
    "out:Total CO2 (tons)": 54269.90986,
    "out:Klimatpaverkan":0.001* -60742.47759,
    "out:Initial Cost/MSEK": 4.247875,
    "out:Running cost/(Apt SEK y)": 8679.24,
    "out:Running Cost over RSP/MSEK": 1.68005,
    "out:LCP/MSEK": 0.832915,
    "out:ROI% old": 17.086829,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 201.9,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3735.83595,
    "out:Return %": 15,
    "out:Return/kSEK/y": 648,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.869585,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 296341.8197,
    "out:EL kWh savings": -69189.35559,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152049.15,
    "out:DH kr savings": 269671.0559,
    "out:El kr savings": -117621.9045,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 11367.99435,
    "out:% savings (space heating)": 94.772494,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.33,
    "out:Etvv": 0,
    "out:Ef": 49.32,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4247875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.597032,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.499132,
    "out:Electricity (inc PV)": 42.361938,
    "out:Total Energy Use Pre PV": 64.499132,
    "out:Total Energy Use Post PV": 51.361938,
    "out:Primary Energy": 79.775882,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.566455,
    "out:CO2 Operational/m2": 8.95553,
    "out:Total CO2/m2": 35.521985,
    "out:Total CO2 (tons)": 74205.44706,
    "out:Klimatpaverkan":0.001* -40806.94039,
    "out:Initial Cost/MSEK": 4.429375,
    "out:Running cost/(Apt SEK y)": 8594,
    "out:Running Cost over RSP/MSEK": 1.66971,
    "out:LCP/MSEK": 0.661755,
    "out:ROI% old": 16.420021,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 219.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3302.778102,
    "out:Return %": 15,
    "out:Return/kSEK/y": 650,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 36.999632,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 230.852045,
    "out:PV (% sold (El))": 1.235874,
    "out:PV (kWh self-consumed)": 18448.39891,
    "out:PV (ratio sold/self-consumed)": 0.012513,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 289750.6092,
    "out:EL kWh savings": -54652.3035,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171058.47,
    "out:DH kr savings": 263673.0544,
    "out:El kr savings": -92908.91595,
    "out:El kr sold": 294.3363576,
    "out:El kr saved": 31362.27815,
    "out:El kr return": 31656.61451,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 17959.20478,
    "out:% savings (space heating)": 91.741565,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 5.03,
    "out:Etvv": 0,
    "out:Ef": 42.36,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4429375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.662632,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.962782,
    "out:Electricity (inc PV)": 41.651597,
    "out:Total Energy Use Pre PV": 61.962782,
    "out:Total Energy Use Post PV": 48.651597,
    "out:Primary Energy": 77.607618,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.566455,
    "out:CO2 Operational/m2": 8.105145,
    "out:Total CO2/m2": 34.6716,
    "out:Total CO2 (tons)": 72428.9923,
    "out:Klimatpaverkan":0.001* -42583.39514,
    "out:Initial Cost/MSEK": 4.481625,
    "out:Running cost/(Apt SEK y)": 8079.08,
    "out:Running Cost over RSP/MSEK": 1.56913,
    "out:LCP/MSEK": 0.710085,
    "out:ROI% old": 16.549195,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 232.7,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3265.746449,
    "out:Return %": 15,
    "out:Return/kSEK/y": 663,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 36.463282,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 258.54646,
    "out:PV (% sold (El))": 1.384137,
    "out:PV (kWh self-consumed)": 18420.7045,
    "out:PV (ratio sold/self-consumed)": 0.014036,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293791.5719,
    "out:EL kWh savings": -53168.40074,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177293.7,
    "out:DH kr savings": 267350.3305,
    "out:El kr savings": -90386.28126,
    "out:El kr sold": 329.6467359,
    "out:El kr saved": 31315.19764,
    "out:El kr return": 31644.84438,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 13918.24207,
    "out:% savings (space heating)": 93.599778,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.76,
    "out:Etvv": 0,
    "out:Ef": 41.65,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4481625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.138616,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.97744,
    "out:Electricity (inc PV)": 41.424156,
    "out:Total Energy Use Pre PV": 60.97744,
    "out:Total Energy Use Post PV": 48.424156,
    "out:Primary Energy": 77.712591,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.016431,
    "out:CO2 Operational/m2": 7.854951,
    "out:Total CO2/m2": 34.871382,
    "out:Total CO2 (tons)": 72846.33701,
    "out:Klimatpaverkan":0.001* -42166.05043,
    "out:Initial Cost/MSEK": 4.54325,
    "out:Running cost/(Apt SEK y)": 7940.04,
    "out:Running Cost over RSP/MSEK": 1.54225,
    "out:LCP/MSEK": 0.67534,
    "out:ROI% old": 16.409241,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 239.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3197.858104,
    "out:Return %": 15,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 35.47794,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 312.811033,
    "out:PV (% sold (El))": 1.674644,
    "out:PV (kWh self-consumed)": 18366.43992,
    "out:PV (ratio sold/self-consumed)": 0.017032,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 292797.2411,
    "out:EL kWh savings": -52693.27636,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 177265.75,
    "out:DH kr savings": 266445.4894,
    "out:El kr savings": -89578.56982,
    "out:El kr sold": 398.8340671,
    "out:El kr saved": 31222.94787,
    "out:El kr return": 31621.78194,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 14912.57292,
    "out:% savings (space heating)": 93.142541,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.5,
    "out:Etvv": 0,
    "out:Ef": 41.42,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4543250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.441834,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.369085,
    "out:Electricity (inc PV)": 40.686422,
    "out:Total Energy Use Pre PV": 58.369085,
    "out:Total Energy Use Post PV": 45.686422,
    "out:Primary Energy": 75.567398,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.016431,
    "out:CO2 Operational/m2": 6.97423,
    "out:Total CO2/m2": 33.990661,
    "out:Total CO2 (tons)": 71006.51034,
    "out:Klimatpaverkan":0.001* -44005.8771,
    "out:Initial Cost/MSEK": 4.5955,
    "out:Running cost/(Apt SEK y)": 7415,
    "out:Running Cost over RSP/MSEK": 1.43972,
    "out:LCP/MSEK": 0.72562,
    "out:ROI% old": 16.541399,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 254.3,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3156.040041,
    "out:Return %": 15,
    "out:Return/kSEK/y": 679,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.869585,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 348.653786,
    "out:PV (% sold (El))": 1.86653,
    "out:PV (kWh self-consumed)": 18330.59717,
    "out:PV (ratio sold/self-consumed)": 0.01902,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 296341.8197,
    "out:EL kWh savings": -51152.14961,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 183156.94,
    "out:DH kr savings": 269671.0559,
    "out:El kr savings": -86958.65434,
    "out:El kr sold": 444.5335773,
    "out:El kr saved": 31162.01519,
    "out:El kr return": 31606.54877,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 11367.99435,
    "out:% savings (space heating)": 94.772494,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.33,
    "out:Etvv": 0,
    "out:Ef": 40.69,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4595500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.597032,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.499132,
    "out:Electricity (inc PV)": 38.04493,
    "out:Total Energy Use Pre PV": 64.499132,
    "out:Total Energy Use Post PV": 47.04493,
    "out:Primary Energy": 72.005268,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.449065,
    "out:CO2 Operational/m2": -12.246245,
    "out:Total CO2/m2": 24.20282,
    "out:Total CO2 (tons)": 50559.70487,
    "out:Klimatpaverkan":0.001* -64452.68257,
    "out:Initial Cost/MSEK": 4.777,
    "out:Running cost/(Apt SEK y)": 7470.6,
    "out:Running Cost over RSP/MSEK": 1.45559,
    "out:LCP/MSEK": 0.52825,
    "out:ROI% old": 15.865457,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 246.8,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2960.160651,
    "out:Return %": 14,
    "out:Return/kSEK/y": 678,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 36.999632,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 8864.837861,
    "out:PV (% sold (El))": 23.729104,
    "out:PV (kWh self-consumed)": 28493.66405,
    "out:PV (ratio sold/self-consumed)": 0.311116,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 289750.6092,
    "out:EL kWh savings": -45634.07131,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 197397.8,
    "out:DH kr savings": 263673.0544,
    "out:El kr savings": -77577.92123,
    "out:El kr sold": 11302.66827,
    "out:El kr saved": 48439.22889,
    "out:El kr return": 59741.89716,
    "out:% solar/total": 84,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 17959.20478,
    "out:% savings (space heating)": 91.741565,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 5.03,
    "out:Etvv": 0,
    "out:Ef": 38.04,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4777000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.662632,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.962782,
    "out:Electricity (inc PV)": 37.417132,
    "out:Total Energy Use Pre PV": 61.962782,
    "out:Total Energy Use Post PV": 44.417132,
    "out:Primary Energy": 69.985581,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.449065,
    "out:CO2 Operational/m2": -13.394235,
    "out:Total CO2/m2": 23.05483,
    "out:Total CO2 (tons)": 48161.5531,
    "out:Klimatpaverkan":0.001* -66850.83434,
    "out:Initial Cost/MSEK": 4.82925,
    "out:Running cost/(Apt SEK y)": 6957.76,
    "out:Running Cost over RSP/MSEK": 1.35542,
    "out:LCP/MSEK": 0.57617,
    "out:ROI% old": 15.99012,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 270.5,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2927.307806,
    "out:Return %": 14,
    "out:Return/kSEK/y": 691,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 36.463282,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 9015.228213,
    "out:PV (% sold (El))": 24.131664,
    "out:PV (kWh self-consumed)": 28343.2737,
    "out:PV (ratio sold/self-consumed)": 0.318073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293791.5719,
    "out:EL kWh savings": -44322.60093,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 203496.32,
    "out:DH kr savings": 267350.3305,
    "out:El kr savings": -75348.42158,
    "out:El kr sold": 11494.41597,
    "out:El kr saved": 48183.56529,
    "out:El kr return": 59677.98126,
    "out:% solar/total": 84,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 13918.24207,
    "out:% savings (space heating)": 93.599778,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.76,
    "out:Etvv": 0,
    "out:Ef": 37.42,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4829250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.138616,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.97744,
    "out:Electricity (inc PV)": 37.216198,
    "out:Total Energy Use Pre PV": 60.97744,
    "out:Total Energy Use Post PV": 44.216198,
    "out:Primary Energy": 70.138266,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.899041,
    "out:CO2 Operational/m2": -14.191117,
    "out:Total CO2/m2": 22.707924,
    "out:Total CO2 (tons)": 47436.86627,
    "out:Klimatpaverkan":0.001* -67575.52117,
    "out:Initial Cost/MSEK": 4.890875,
    "out:Running cost/(Apt SEK y)": 6822.56,
    "out:Running Cost over RSP/MSEK": 1.32928,
    "out:LCP/MSEK": 0.540685,
    "out:ROI% old": 15.864996,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 270.5,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2867.086461,
    "out:Return %": 14,
    "out:Return/kSEK/y": 694,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 35.47794,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 9296.044436,
    "out:PV (% sold (El))": 24.883344,
    "out:PV (kWh self-consumed)": 28062.45748,
    "out:PV (ratio sold/self-consumed)": 0.331263,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 292797.2411,
    "out:EL kWh savings": -43902.84969,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 203663.1,
    "out:DH kr savings": 266445.4894,
    "out:El kr savings": -74634.84447,
    "out:El kr sold": 11852.45666,
    "out:El kr saved": 47706.17771,
    "out:El kr return": 59558.63437,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 14912.57292,
    "out:% savings (space heating)": 93.142541,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.5,
    "out:Etvv": 0,
    "out:Ef": 37.22,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4890875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.441834,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.369085,
    "out:Electricity (inc PV)": 36.563884,
    "out:Total Energy Use Pre PV": 58.369085,
    "out:Total Energy Use Post PV": 41.563884,
    "out:Primary Energy": 68.146829,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.899041,
    "out:CO2 Operational/m2": -15.409808,
    "out:Total CO2/m2": 21.489233,
    "out:Total CO2 (tons)": 44891.02007,
    "out:Klimatpaverkan":0.001* -70121.36737,
    "out:Initial Cost/MSEK": 4.943125,
    "out:Running cost/(Apt SEK y)": 6299.88,
    "out:Running Cost over RSP/MSEK": 1.22721,
    "out:LCP/MSEK": 0.590505,
    "out:ROI% old": 15.992284,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 288.1,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2829.991984,
    "out:Return %": 14,
    "out:Return/kSEK/y": 707,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.869585,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 9472.249303,
    "out:PV (% sold (El))": 25.355003,
    "out:PV (kWh self-consumed)": 27886.25261,
    "out:PV (ratio sold/self-consumed)": 0.339675,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 296341.8197,
    "out:EL kWh savings": -42540.16537,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209429.89,
    "out:DH kr savings": 269671.0559,
    "out:El kr savings": -72318.28112,
    "out:El kr sold": 12077.11786,
    "out:El kr saved": 47406.62944,
    "out:El kr return": 59483.7473,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 11367.99435,
    "out:% savings (space heating)": 94.772494,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.33,
    "out:Etvv": 0,
    "out:Ef": 36.56,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4943125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.697683,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.456511,
    "out:Electricity (inc PV)": 49.760806,
    "out:Total Energy Use Pre PV": 60.456511,
    "out:Total Energy Use Post PV": 56.760806,
    "out:Primary Energy": 92.602907,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.683845,
    "out:CO2 Operational/m2": 9.576575,
    "out:Total CO2/m2": 26.26042,
    "out:Total CO2 (tons)": 54858.03245,
    "out:Klimatpaverkan":0.001* -60154.35499,
    "out:Initial Cost/MSEK": 4.14775,
    "out:Running cost/(Apt SEK y)": 9130.88,
    "out:Running Cost over RSP/MSEK": 1.76838,
    "out:LCP/MSEK": 0.84471,
    "out:ROI% old": 17.195071,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 186,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3741.95577,
    "out:Return %": 15,
    "out:Return/kSEK/y": 636,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 34.957011,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293718.3504,
    "out:EL kWh savings": -70108.543,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148099.18,
    "out:DH kr savings": 267283.6988,
    "out:El kr savings": -119184.5231,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 13991.46363,
    "out:% savings (space heating)": 93.566107,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.33,
    "out:Etvv": 0,
    "out:Ef": 49.76,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4147750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.086458,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.822229,
    "out:Electricity (inc PV)": 48.982667,
    "out:Total Energy Use Pre PV": 57.822229,
    "out:Total Energy Use Post PV": 53.982667,
    "out:Primary Energy": 90.406709,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.683845,
    "out:CO2 Operational/m2": 8.772877,
    "out:Total CO2/m2": 25.456722,
    "out:Total CO2 (tons)": 53179.10687,
    "out:Klimatpaverkan":0.001* -61833.28057,
    "out:Initial Cost/MSEK": 4.2,
    "out:Running cost/(Apt SEK y)": 8601.56,
    "out:Running Cost over RSP/MSEK": 1.66504,
    "out:LCP/MSEK": 0.8958,
    "out:ROI% old": 17.332653,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 201.9,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3697.55603,
    "out:Return %": 15,
    "out:Return/kSEK/y": 650,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 34.322729,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 297084.2003,
    "out:EL kWh savings": -68483.01018,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 153925.5,
    "out:DH kr savings": 270346.6223,
    "out:El kr savings": -116421.1173,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 10625.61368,
    "out:% savings (space heating)": 95.113874,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.2,
    "out:Etvv": 0,
    "out:Ef": 48.98,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4200000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.736332,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.17692,
    "out:Electricity (inc PV)": 48.966022,
    "out:Total Energy Use Pre PV": 58.17692,
    "out:Total Energy Use Post PV": 54.966022,
    "out:Primary Energy": 90.908248,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.133821,
    "out:CO2 Operational/m2": 9.047675,
    "out:Total CO2/m2": 26.181496,
    "out:Total CO2 (tons)": 54693.16017,
    "out:Klimatpaverkan":0.001* -60319.22727,
    "out:Initial Cost/MSEK": 4.261625,
    "out:Running cost/(Apt SEK y)": 8729.52,
    "out:Running Cost over RSP/MSEK": 1.69029,
    "out:LCP/MSEK": 0.808925,
    "out:ROI% old": 16.997373,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 196.4,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3652.3844,
    "out:Return %": 15,
    "out:Return/kSEK/y": 646,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 33.67742,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 295726.6132,
    "out:EL kWh savings": -68448.23877,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152749.21,
    "out:DH kr savings": 269111.218,
    "out:El kr savings": -116362.0059,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 11983.20084,
    "out:% savings (space heating)": 94.489595,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.96,
    "out:Etvv": 0,
    "out:Ef": 48.97,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4261625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.323573,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.482497,
    "out:Electricity (inc PV)": 48.165839,
    "out:Total Energy Use Pre PV": 55.482497,
    "out:Total Energy Use Post PV": 52.165839,
    "out:Primary Energy": 88.723292,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.133821,
    "out:CO2 Operational/m2": 8.236037,
    "out:Total CO2/m2": 25.369858,
    "out:Total CO2 (tons)": 52997.64792,
    "out:Klimatpaverkan":0.001* -62014.73952,
    "out:Initial Cost/MSEK": 4.31375,
    "out:Running cost/(Apt SEK y)": 8191.64,
    "out:Running Cost over RSP/MSEK": 1.58529,
    "out:LCP/MSEK": 0.8618,
    "out:ROI% old": 17.139711,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 213.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3603.77479,
    "out:Return %": 15,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.982997,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298677.8675,
    "out:EL kWh savings": -66776.65602,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158276.54,
    "out:DH kr savings": 271796.8594,
    "out:El kr savings": -113520.3152,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 9031.946479,
    "out:% savings (space heating)": 95.846712,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.89,
    "out:Etvv": 0,
    "out:Ef": 48.17,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4313750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.697683,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.456511,
    "out:Electricity (inc PV)": 41.108435,
    "out:Total Energy Use Pre PV": 60.456511,
    "out:Total Energy Use Post PV": 48.108435,
    "out:Primary Energy": 77.028639,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.566455,
    "out:CO2 Operational/m2": 7.717582,
    "out:Total CO2/m2": 34.284037,
    "out:Total CO2 (tons)": 71619.37297,
    "out:Klimatpaverkan":0.001* -43393.01447,
    "out:Initial Cost/MSEK": 4.495375,
    "out:Running cost/(Apt SEK y)": 7866.56,
    "out:Running Cost over RSP/MSEK": 1.52804,
    "out:LCP/MSEK": 0.737425,
    "out:ROI% old": 16.629155,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 239.6,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3162.044938,
    "out:Return %": 15,
    "out:Return/kSEK/y": 668,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 34.957011,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 343.4121,
    "out:PV (% sold (El))": 1.838468,
    "out:PV (kWh self-consumed)": 18335.83886,
    "out:PV (ratio sold/self-consumed)": 0.018729,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293718.3504,
    "out:EL kWh savings": -52033.73501,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179264.2,
    "out:DH kr savings": 267283.6988,
    "out:El kr savings": -88457.34952,
    "out:El kr sold": 437.8504274,
    "out:El kr saved": 31170.92606,
    "out:El kr return": 31608.77648,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 13991.46363,
    "out:% savings (space heating)": 93.566107,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.33,
    "out:Etvv": 0,
    "out:Ef": 41.11,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4495375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.086458,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.822229,
    "out:Electricity (inc PV)": 40.362573,
    "out:Total Energy Use Pre PV": 57.822229,
    "out:Total Energy Use Post PV": 45.362573,
    "out:Primary Energy": 74.890539,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 26.566455,
    "out:CO2 Operational/m2": 6.826246,
    "out:Total CO2/m2": 33.392701,
    "out:Total CO2 (tons)": 69757.37156,
    "out:Klimatpaverkan":0.001* -45255.01589,
    "out:Initial Cost/MSEK": 4.547625,
    "out:Running cost/(Apt SEK y)": 7337.88,
    "out:Running Cost over RSP/MSEK": 1.42481,
    "out:LCP/MSEK": 0.788405,
    "out:ROI% old": 16.762376,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 262.2,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3118.513818,
    "out:Return %": 15,
    "out:Return/kSEK/y": 681,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 34.322729,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 382.126847,
    "out:PV (% sold (El))": 2.045729,
    "out:PV (kWh self-consumed)": 18297.12411,
    "out:PV (ratio sold/self-consumed)": 0.020885,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 297084.2003,
    "out:EL kWh savings": -50475.62887,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185025.26,
    "out:DH kr savings": 270346.6223,
    "out:El kr savings": -85808.56907,
    "out:El kr sold": 487.2117297,
    "out:El kr saved": 31105.11099,
    "out:El kr return": 31592.32272,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 10625.61368,
    "out:% savings (space heating)": 95.113874,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.2,
    "out:Etvv": 0,
    "out:Ef": 40.36,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4547625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.736332,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.17692,
    "out:Electricity (inc PV)": 40.346642,
    "out:Total Energy Use Pre PV": 58.17692,
    "out:Total Energy Use Post PV": 46.346642,
    "out:Primary Energy": 75.393364,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.016431,
    "out:CO2 Operational/m2": 7.007294,
    "out:Total CO2/m2": 34.023725,
    "out:Total CO2 (tons)": 71075.58106,
    "out:Klimatpaverkan":0.001* -43936.80639,
    "out:Initial Cost/MSEK": 4.60925,
    "out:Running cost/(Apt SEK y)": 7466.52,
    "out:Running Cost over RSP/MSEK": 1.45018,
    "out:LCP/MSEK": 0.70141,
    "out:ROI% old": 16.459635,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 254.3,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3074.309258,
    "out:Return %": 15,
    "out:Return/kSEK/y": 678,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 33.67742,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 423.593293,
    "out:PV (% sold (El))": 2.267721,
    "out:PV (kWh self-consumed)": 18255.65766,
    "out:PV (ratio sold/self-consumed)": 0.023203,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 295726.6132,
    "out:EL kWh savings": -50442.349,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183899.31,
    "out:DH kr savings": 269111.218,
    "out:El kr savings": -85751.9933,
    "out:El kr sold": 540.0814488,
    "out:El kr saved": 31034.61803,
    "out:El kr return": 31574.69948,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 11983.20084,
    "out:% savings (space heating)": 94.489595,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.96,
    "out:Etvv": 0,
    "out:Ef": 40.35,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4609250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.323573,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.482497,
    "out:Electricity (inc PV)": 39.582095,
    "out:Total Energy Use Pre PV": 55.482497,
    "out:Total Energy Use Post PV": 43.582095,
    "out:Primary Energy": 73.272553,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 27.016431,
    "out:CO2 Operational/m2": 6.088862,
    "out:Total CO2/m2": 33.105293,
    "out:Total CO2 (tons)": 69156.97608,
    "out:Klimatpaverkan":0.001* -45855.41137,
    "out:Initial Cost/MSEK": 4.661375,
    "out:Running cost/(Apt SEK y)": 6929.48,
    "out:Running Cost over RSP/MSEK": 1.34533,
    "out:LCP/MSEK": 0.754135,
    "out:ROI% old": 16.596912,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 270.5,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3026.83315,
    "out:Return %": 15,
    "out:Return/kSEK/y": 691,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.982997,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 471.266369,
    "out:PV (% sold (El))": 2.52294,
    "out:PV (kWh self-consumed)": 18207.98459,
    "out:PV (ratio sold/self-consumed)": 0.025882,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298677.8675,
    "out:EL kWh savings": -48845.20988,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 189360.87,
    "out:DH kr savings": 271796.8594,
    "out:El kr savings": -83036.85679,
    "out:El kr sold": 600.8646202,
    "out:El kr saved": 30953.5738,
    "out:El kr return": 31554.43842,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 9031.946479,
    "out:% savings (space heating)": 95.846712,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.89,
    "out:Etvv": 0,
    "out:Ef": 39.58,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4661375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.697683,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.456511,
    "out:Electricity (inc PV)": 36.937117,
    "out:Total Energy Use Pre PV": 60.456511,
    "out:Total Energy Use Post PV": 43.937117,
    "out:Primary Energy": 69.520267,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.449065,
    "out:CO2 Operational/m2": -14.617848,
    "out:Total CO2/m2": 21.831217,
    "out:Total CO2 (tons)": 45605.42484,
    "out:Klimatpaverkan":0.001* -69406.9626,
    "out:Initial Cost/MSEK": 4.843,
    "out:Running cost/(Apt SEK y)": 6751.12,
    "out:Running Cost over RSP/MSEK": 1.31546,
    "out:LCP/MSEK": 0.60238,
    "out:ROI% old": 16.062594,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 270.5,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2835.318774,
    "out:Return %": 14,
    "out:Return/kSEK/y": 696,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 34.957011,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 9446.797735,
    "out:PV (% sold (El))": 25.286875,
    "out:PV (kWh self-consumed)": 27911.70418,
    "out:PV (ratio sold/self-consumed)": 0.338453,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293718.3504,
    "out:EL kWh savings": -43319.84932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205684.62,
    "out:DH kr savings": 267283.6988,
    "out:El kr savings": -73643.74384,
    "out:El kr sold": 12044.66711,
    "out:El kr saved": 47449.8971,
    "out:El kr return": 59494.56421,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 13991.46363,
    "out:% savings (space heating)": 93.566107,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.33,
    "out:Etvv": 0,
    "out:Ef": 36.94,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4843000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.086458,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.822229,
    "out:Electricity (inc PV)": 36.277385,
    "out:Total Energy Use Pre PV": 57.822229,
    "out:Total Energy Use Post PV": 41.277385,
    "out:Primary Energy": 67.537201,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.449065,
    "out:CO2 Operational/m2": -15.862804,
    "out:Total CO2/m2": 20.586261,
    "out:Total CO2 (tons)": 43004.71105,
    "out:Klimatpaverkan":0.001* -72007.6764,
    "out:Initial Cost/MSEK": 4.89525,
    "out:Running cost/(Apt SEK y)": 6224.96,
    "out:Running Cost over RSP/MSEK": 1.21271,
    "out:LCP/MSEK": 0.65288,
    "out:ROI% old": 16.191001,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 297.6,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2796.705335,
    "out:Return %": 14,
    "out:Return/kSEK/y": 709,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 34.322729,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 9632.675787,
    "out:PV (% sold (El))": 25.784427,
    "out:PV (kWh self-consumed)": 27725.82613,
    "out:PV (ratio sold/self-consumed)": 0.347426,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 297084.2003,
    "out:EL kWh savings": -41941.66879,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211327.45,
    "out:DH kr savings": 270346.6223,
    "out:El kr savings": -71300.83694,
    "out:El kr sold": 12281.66163,
    "out:El kr saved": 47133.90441,
    "out:El kr return": 59415.56604,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 10625.61368,
    "out:% savings (space heating)": 95.113874,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.2,
    "out:Etvv": 0,
    "out:Ef": 36.28,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4895250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.736332,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.17692,
    "out:Electricity (inc PV)": 36.263294,
    "out:Total Energy Use Pre PV": 58.17692,
    "out:Total Energy Use Post PV": 42.263294,
    "out:Primary Energy": 68.043337,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.899041,
    "out:CO2 Operational/m2": -16.0431,
    "out:Total CO2/m2": 20.855941,
    "out:Total CO2 (tons)": 43568.07272,
    "out:Klimatpaverkan":0.001* -71444.31472,
    "out:Initial Cost/MSEK": 4.956875,
    "out:Running cost/(Apt SEK y)": 6356.16,
    "out:Running Cost over RSP/MSEK": 1.23858,
    "out:LCP/MSEK": 0.565385,
    "out:ROI% old": 15.915154,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 288.1,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2757.497501,
    "out:Return %": 14,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 33.67742,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 9824.417947,
    "out:PV (% sold (El))": 26.297676,
    "out:PV (kWh self-consumed)": 27534.08397,
    "out:PV (ratio sold/self-consumed)": 0.356809,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 295726.6132,
    "out:EL kWh savings": -41912.23268,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210386.56,
    "out:DH kr savings": 269111.218,
    "out:El kr savings": -71250.79556,
    "out:El kr sold": 12526.13288,
    "out:El kr saved": 46807.94274,
    "out:El kr return": 59334.07562,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 11983.20084,
    "out:% savings (space heating)": 94.489595,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.96,
    "out:Etvv": 0,
    "out:Ef": 36.26,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4956875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.323573,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.482497,
    "out:Electricity (inc PV)": 35.58675,
    "out:Total Energy Use Pre PV": 55.482497,
    "out:Total Energy Use Post PV": 39.58675,
    "out:Primary Energy": 66.080932,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.899041,
    "out:CO2 Operational/m2": -17.352074,
    "out:Total CO2/m2": 19.546967,
    "out:Total CO2 (tons)": 40833.62528,
    "out:Klimatpaverkan":0.001* -74178.76216,
    "out:Initial Cost/MSEK": 5.009,
    "out:Running cost/(Apt SEK y)": 5821.84,
    "out:Running Cost over RSP/MSEK": 1.13425,
    "out:LCP/MSEK": 0.61759,
    "out:ROI% old": 16.047087,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 307.5,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2715.397292,
    "out:Return %": 14,
    "out:Return/kSEK/y": 719,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.982997,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 10034.40755,
    "out:PV (% sold (El))": 26.85977,
    "out:PV (kWh self-consumed)": 27324.09436,
    "out:PV (ratio sold/self-consumed)": 0.367237,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298677.8675,
    "out:EL kWh savings": -40498.93188,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215742.54,
    "out:DH kr savings": 271796.8594,
    "out:El kr savings": -68848.18419,
    "out:El kr sold": 12793.86963,
    "out:El kr saved": 46450.96041,
    "out:El kr return": 59244.83004,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 9031.946479,
    "out:% savings (space heating)": 95.846712,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.89,
    "out:Etvv": 0,
    "out:Ef": 35.59,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5009000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.321099,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 140.2,
    "out:Primary Energy": 102.884769,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.036414,
    "out:CO2 Operational/m2": 46.776546,
    "out:Total CO2/m2": 60.81296,
    "out:Total CO2 (tons)": 127038.3083,
    "out:Klimatpaverkan":0.001* 12025.9209,
    "out:Initial Cost/MSEK": 2.12,
    "out:Running cost/(Apt SEK y)": 29533.2,
    "out:Running Cost over RSP/MSEK": 5.77261,
    "out:LCP/MSEK": -1.13177,
    "out:ROI% old": 6.659232,
    "out:Payback discounted": 18,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 16.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 6,
    "out:Return/kSEK/y": 126,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 49674.16834,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 45203.49,
    "out:DH kr savings": 45203.49319,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 167790.8209,
    "out:% savings (space heating)": 22.842375,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 80.32,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2120000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.942867,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 136.2,
    "out:Primary Energy": 100.520007,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.036414,
    "out:CO2 Operational/m2": 45.33661,
    "out:Total CO2/m2": 59.373024,
    "out:Total CO2 (tons)": 124030.2812,
    "out:Klimatpaverkan":0.001* 9017.893772,
    "out:Initial Cost/MSEK": 2.17225,
    "out:Running cost/(Apt SEK y)": 28654.72,
    "out:Running Cost over RSP/MSEK": 5.60074,
    "out:LCP/MSEK": -1.01215,
    "out:ROI% old": 7.629351,
    "out:Payback discounted": 16,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 19.9,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 7,
    "out:Return/kSEK/y": 148,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 56731.2964,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 51625.48,
    "out:DH kr savings": 51625.47973,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 160733.6928,
    "out:% savings (space heating)": 26.087554,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2172250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.321099,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 133.2,
    "out:Primary Energy": 100.259769,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.48639,
    "out:CO2 Operational/m2": 44.256658,
    "out:Total CO2/m2": 58.743048,
    "out:Total CO2 (tons)": 122714.261,
    "out:Klimatpaverkan":0.001* 7701.873547,
    "out:Initial Cost/MSEK": 2.233875,
    "out:Running cost/(Apt SEK y)": 27995.88,
    "out:Running Cost over RSP/MSEK": 5.47184,
    "out:LCP/MSEK": -0.944875,
    "out:ROI% old": 8.243203,
    "out:Payback discounted": 15,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 7,
    "out:Return/kSEK/y": 165,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 63210.89206,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 57521.91,
    "out:DH kr savings": 57521.91177,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 167790.8209,
    "out:% savings (space heating)": 22.842375,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 80.32,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2233875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.942867,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 130.2,
    "out:Primary Energy": 97.895007,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.48639,
    "out:CO2 Operational/m2": 43.176706,
    "out:Total CO2/m2": 57.663096,
    "out:Total CO2 (tons)": 120458.2406,
    "out:Klimatpaverkan":0.001* 5445.853199,
    "out:Initial Cost/MSEK": 2.286125,
    "out:Running cost/(Apt SEK y)": 27337.08,
    "out:Running Cost over RSP/MSEK": 5.34294,
    "out:LCP/MSEK": -0.868225,
    "out:ROI% old": 8.860283,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 25.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 8,
    "out:Return/kSEK/y": 181,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 70268.02012,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 63943.9,
    "out:DH kr savings": 63943.89831,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 160733.6928,
    "out:% savings (space heating)": 26.087554,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2286125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.321099,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 135.305876,
    "out:Primary Energy": 94.075346,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.919024,
    "out:CO2 Operational/m2": 25.764557,
    "out:Total CO2/m2": 49.683581,
    "out:Total CO2 (tons)": 103789.0292,
    "out:Klimatpaverkan":0.001* -11223.35822,
    "out:Initial Cost/MSEK": 2.467625,
    "out:Running cost/(Apt SEK y)": 28406.76,
    "out:Running Cost over RSP/MSEK": 5.55825,
    "out:LCP/MSEK": -1.265035,
    "out:ROI% old": 6.962102,
    "out:Payback discounted": 17,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 20.7,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 6,
    "out:Return/kSEK/y": 154,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 49674.16834,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 73364.67,
    "out:DH kr savings": 45203.49319,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 167790.8209,
    "out:% savings (space heating)": 22.842375,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 80.32,
    "out:Etvv": 25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2467625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.942867,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 131.305876,
    "out:Primary Energy": 91.710584,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.919024,
    "out:CO2 Operational/m2": 24.324621,
    "out:Total CO2/m2": 48.243645,
    "out:Total CO2 (tons)": 100781.0021,
    "out:Klimatpaverkan":0.001* -14231.38535,
    "out:Initial Cost/MSEK": 2.519875,
    "out:Running cost/(Apt SEK y)": 27528.28,
    "out:Running Cost over RSP/MSEK": 5.38638,
    "out:LCP/MSEK": -1.145415,
    "out:ROI% old": 7.79211,
    "out:Payback discounted": 16,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 7,
    "out:Return/kSEK/y": 176,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 56731.2964,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 79786.65,
    "out:DH kr savings": 51625.47973,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 160733.6928,
    "out:% savings (space heating)": 26.087554,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2519875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.321099,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 128.305876,
    "out:Primary Energy": 91.450346,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.369,
    "out:CO2 Operational/m2": 23.244669,
    "out:Total CO2/m2": 47.613669,
    "out:Total CO2 (tons)": 99464.98187,
    "out:Klimatpaverkan":0.001* -15547.40557,
    "out:Initial Cost/MSEK": 2.5815,
    "out:Running cost/(Apt SEK y)": 26869.44,
    "out:Running Cost over RSP/MSEK": 5.25748,
    "out:LCP/MSEK": -1.07814,
    "out:ROI% old": 8.319416,
    "out:Payback discounted": 15,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 27.3,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 7,
    "out:Return/kSEK/y": 193,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 63210.89206,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85683.08,
    "out:DH kr savings": 57521.91177,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 167790.8209,
    "out:% savings (space heating)": 22.842375,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 80.32,
    "out:Etvv": 21.25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2581500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.942867,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 125.305876,
    "out:Primary Energy": 89.085584,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.369,
    "out:CO2 Operational/m2": 22.164717,
    "out:Total CO2/m2": 46.533717,
    "out:Total CO2 (tons)": 97208.96152,
    "out:Klimatpaverkan":0.001* -17803.42592,
    "out:Initial Cost/MSEK": 2.63375,
    "out:Running cost/(Apt SEK y)": 26210.6,
    "out:Running Cost over RSP/MSEK": 5.12858,
    "out:LCP/MSEK": -1.00149,
    "out:ROI% old": 8.853536,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 30.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 8,
    "out:Return/kSEK/y": 209,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 70268.02012,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 92105.07,
    "out:DH kr savings": 63943.89831,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 160733.6928,
    "out:% savings (space heating)": 26.087554,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 21.25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2633750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.321099,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 134.330546,
    "out:Primary Energy": 92.319752,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.801634,
    "out:CO2 Operational/m2": -14.870901,
    "out:Total CO2/m2": 18.930733,
    "out:Total CO2 (tons)": 39546.3121,
    "out:Klimatpaverkan":0.001* -75466.07534,
    "out:Initial Cost/MSEK": 2.81525,
    "out:Running cost/(Apt SEK y)": 27419.48,
    "out:Running Cost over RSP/MSEK": 5.37053,
    "out:LCP/MSEK": -1.42494,
    "out:ROI% old": 7.054994,
    "out:Payback discounted": 17,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 21.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 6,
    "out:Return/kSEK/y": 179,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 49674.16834,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98046.63,
    "out:DH kr savings": 45203.49319,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 167790.8209,
    "out:% savings (space heating)": 22.842375,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 80.32,
    "out:Etvv": 25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2815250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.942867,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 130.330546,
    "out:Primary Energy": 89.95499,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.801634,
    "out:CO2 Operational/m2": -16.310837,
    "out:Total CO2/m2": 17.490797,
    "out:Total CO2 (tons)": 36538.28497,
    "out:Klimatpaverkan":0.001* -78474.10247,
    "out:Initial Cost/MSEK": 2.8675,
    "out:Running cost/(Apt SEK y)": 26541,
    "out:Running Cost over RSP/MSEK": 5.19867,
    "out:LCP/MSEK": -1.30533,
    "out:ROI% old": 7.782638,
    "out:Payback discounted": 16,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 25.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 7,
    "out:Return/kSEK/y": 201,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 56731.2964,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104468.62,
    "out:DH kr savings": 51625.47973,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 160733.6928,
    "out:% savings (space heating)": 26.087554,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2867500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.321099,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 127.330546,
    "out:Primary Energy": 89.694752,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.25161,
    "out:CO2 Operational/m2": -17.390789,
    "out:Total CO2/m2": 16.860821,
    "out:Total CO2 (tons)": 35222.26475,
    "out:Klimatpaverkan":0.001* -79790.1227,
    "out:Initial Cost/MSEK": 2.929125,
    "out:Running cost/(Apt SEK y)": 25882.16,
    "out:Running Cost over RSP/MSEK": 5.06977,
    "out:LCP/MSEK": -1.238055,
    "out:ROI% old": 8.247563,
    "out:Payback discounted": 15,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 28.3,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 7,
    "out:Return/kSEK/y": 218,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 63210.89206,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110365.05,
    "out:DH kr savings": 57521.91177,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 167790.8209,
    "out:% savings (space heating)": 22.842375,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 80.32,
    "out:Etvv": 21.25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2929125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.942867,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 124.330546,
    "out:Primary Energy": 87.32999,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.25161,
    "out:CO2 Operational/m2": -18.470741,
    "out:Total CO2/m2": 15.780869,
    "out:Total CO2 (tons)": 32966.2444,
    "out:Klimatpaverkan":0.001* -82046.14305,
    "out:Initial Cost/MSEK": 2.981375,
    "out:Running cost/(Apt SEK y)": 25223.32,
    "out:Running Cost over RSP/MSEK": 4.94087,
    "out:LCP/MSEK": -1.161405,
    "out:ROI% old": 8.720665,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 8,
    "out:Return/kSEK/y": 234,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 70268.02012,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116787.04,
    "out:DH kr savings": 63943.89831,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 160733.6928,
    "out:% savings (space heating)": 26.087554,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 21.25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2981375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.321099,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 131.2,
    "out:Primary Energy": 99.384769,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.036414,
    "out:CO2 Operational/m2": 43.53669,
    "out:Total CO2/m2": 57.573104,
    "out:Total CO2 (tons)": 120270.2473,
    "out:Klimatpaverkan":0.001* 5257.859859,
    "out:Initial Cost/MSEK": 2.186,
    "out:Running cost/(Apt SEK y)": 27556.68,
    "out:Running Cost over RSP/MSEK": 5.38591,
    "out:LCP/MSEK": -0.81107,
    "out:ROI% old": 8.985296,
    "out:Payback discounted": 13,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 8,
    "out:Return/kSEK/y": 176,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 67723.1333,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 61628.05,
    "out:DH kr savings": 61628.0513,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 167790.8209,
    "out:% savings (space heating)": 22.842375,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 80.32,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2186000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.942867,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 128.2,
    "out:Total Energy Use Post PV": 128.2,
    "out:Primary Energy": 97.020007,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.036414,
    "out:CO2 Operational/m2": 42.456738,
    "out:Total CO2/m2": 56.493152,
    "out:Total CO2 (tons)": 118014.227,
    "out:Klimatpaverkan":0.001* 3001.839511,
    "out:Initial Cost/MSEK": 2.23825,
    "out:Running cost/(Apt SEK y)": 26897.84,
    "out:Running Cost over RSP/MSEK": 5.25701,
    "out:LCP/MSEK": -0.73442,
    "out:ROI% old": 9.598251,
    "out:Payback discounted": 13,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 27.3,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 9,
    "out:Return/kSEK/y": 192,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 74780.26136,
    "out:EL kWh savings": 2.23e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 68050.04,
    "out:DH kr savings": 68050.03784,
    "out:El kr savings": 3.79e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 160733.6928,
    "out:% savings (space heating)": 26.087554,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2238250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.321099,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 126.2,
    "out:Primary Energy": 97.284769,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.48639,
    "out:CO2 Operational/m2": 41.73677,
    "out:Total CO2/m2": 56.22316,
    "out:Total CO2 (tons)": 117450.2135,
    "out:Klimatpaverkan":0.001* 2437.826068,
    "out:Initial Cost/MSEK": 2.299875,
    "out:Running cost/(Apt SEK y)": 26458.6,
    "out:Running Cost over RSP/MSEK": 5.17108,
    "out:LCP/MSEK": -0.710115,
    "out:ROI% old": 9.874822,
    "out:Payback discounted": 12,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 29.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 9,
    "out:Return/kSEK/y": 203,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 78552.51227,
    "out:EL kWh savings": -7.42e-12,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 71482.79,
    "out:DH kr savings": 71482.78617,
    "out:El kr savings": -1.26e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 167790.8209,
    "out:% savings (space heating)": 22.842375,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 80.32,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2299875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.942867,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 122.2,
    "out:Total Energy Use Post PV": 122.2,
    "out:Primary Energy": 94.920007,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.48639,
    "out:CO2 Operational/m2": 40.296834,
    "out:Total CO2/m2": 54.783224,
    "out:Total CO2 (tons)": 114442.1864,
    "out:Klimatpaverkan":0.001* -570.201062,
    "out:Initial Cost/MSEK": 2.352,
    "out:Running cost/(Apt SEK y)": 25580.16,
    "out:Running Cost over RSP/MSEK": 4.99921,
    "out:LCP/MSEK": -0.59037,
    "out:ROI% old": 10.699891,
    "out:Payback discounted": 11,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 10,
    "out:Return/kSEK/y": 225,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 85609.64034,
    "out:EL kWh savings": -7.42e-12,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77904.77,
    "out:DH kr savings": 77904.77271,
    "out:El kr savings": -1.26e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 160733.6928,
    "out:% savings (space heating)": 26.087554,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2352000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.321099,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 126.305876,
    "out:Primary Energy": 90.575346,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.919024,
    "out:CO2 Operational/m2": 22.524701,
    "out:Total CO2/m2": 46.443725,
    "out:Total CO2 (tons)": 97020.96818,
    "out:Klimatpaverkan":0.001* -17991.41926,
    "out:Initial Cost/MSEK": 2.533625,
    "out:Running cost/(Apt SEK y)": 26430.24,
    "out:Running Cost over RSP/MSEK": 5.17155,
    "out:LCP/MSEK": -0.944335,
    "out:ROI% old": 8.96113,
    "out:Payback discounted": 13,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 29.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 8,
    "out:Return/kSEK/y": 204,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 67723.1333,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 89789.22,
    "out:DH kr savings": 61628.0513,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 167790.8209,
    "out:% savings (space heating)": 22.842375,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 80.32,
    "out:Etvv": 20,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2533625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.942867,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 128.2,
    "out:Total Energy Use Post PV": 123.305876,
    "out:Primary Energy": 88.210584,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.919024,
    "out:CO2 Operational/m2": 21.444749,
    "out:Total CO2/m2": 45.363773,
    "out:Total CO2 (tons)": 94764.94784,
    "out:Klimatpaverkan":0.001* -20247.43961,
    "out:Initial Cost/MSEK": 2.585875,
    "out:Running cost/(Apt SEK y)": 25771.4,
    "out:Running Cost over RSP/MSEK": 5.04265,
    "out:LCP/MSEK": -0.867685,
    "out:ROI% old": 9.492172,
    "out:Payback discounted": 13,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 32.5,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 9,
    "out:Return/kSEK/y": 220,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 74780.26136,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 96211.21,
    "out:DH kr savings": 68050.03784,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 160733.6928,
    "out:% savings (space heating)": 26.087554,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 20,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2585875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.321099,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 121.305876,
    "out:Primary Energy": 88.475346,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.369,
    "out:CO2 Operational/m2": 20.724781,
    "out:Total CO2/m2": 45.093781,
    "out:Total CO2 (tons)": 94200.93439,
    "out:Klimatpaverkan":0.001* -20811.45305,
    "out:Initial Cost/MSEK": 2.6475,
    "out:Running cost/(Apt SEK y)": 25332.16,
    "out:Running Cost over RSP/MSEK": 4.95672,
    "out:LCP/MSEK": -0.84338,
    "out:ROI% old": 9.734898,
    "out:Payback discounted": 12,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 9,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 78552.51227,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99643.96,
    "out:DH kr savings": 71482.78617,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 167790.8209,
    "out:% savings (space heating)": 22.842375,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 80.32,
    "out:Etvv": 17,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2647500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.942867,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 122.2,
    "out:Total Energy Use Post PV": 117.305876,
    "out:Primary Energy": 86.110584,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.369,
    "out:CO2 Operational/m2": 19.284845,
    "out:Total CO2/m2": 43.653845,
    "out:Total CO2 (tons)": 91192.90726,
    "out:Klimatpaverkan":0.001* -23819.48018,
    "out:Initial Cost/MSEK": 2.699625,
    "out:Running cost/(Apt SEK y)": 24453.72,
    "out:Running Cost over RSP/MSEK": 4.78485,
    "out:LCP/MSEK": -0.723635,
    "out:ROI% old": 10.456426,
    "out:Payback discounted": 12,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 799.264191,
    "out:Return %": 9,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 8455.423286,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 10223.82767,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85609.64034,
    "out:EL kWh savings": 10223.82785,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 106065.94,
    "out:DH kr savings": 77904.77271,
    "out:El kr savings": 17380.50734,
    "out:El kr sold": 10780.66469,
    "out:El kr saved": 17380.50704,
    "out:El kr return": 28161.17173,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 160733.6928,
    "out:% savings (space heating)": 26.087554,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 17,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2699625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.321099,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 125.330546,
    "out:Primary Energy": 88.819752,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.801634,
    "out:CO2 Operational/m2": -18.110757,
    "out:Total CO2/m2": 15.690877,
    "out:Total CO2 (tons)": 32778.25106,
    "out:Klimatpaverkan":0.001* -82234.13638,
    "out:Initial Cost/MSEK": 2.88125,
    "out:Running cost/(Apt SEK y)": 25442.96,
    "out:Running Cost over RSP/MSEK": 4.98384,
    "out:LCP/MSEK": -1.10425,
    "out:ROI% old": 8.81066,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 30.4,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 8,
    "out:Return/kSEK/y": 229,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 67723.1333,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114471.19,
    "out:DH kr savings": 61628.0513,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 167790.8209,
    "out:% savings (space heating)": 22.842375,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 80.32,
    "out:Etvv": 20,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2881250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.942867,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 128.2,
    "out:Total Energy Use Post PV": 122.330546,
    "out:Primary Energy": 86.45499,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.801634,
    "out:CO2 Operational/m2": -19.190709,
    "out:Total CO2/m2": 14.610925,
    "out:Total CO2 (tons)": 30522.23071,
    "out:Klimatpaverkan":0.001* -84490.15673,
    "out:Initial Cost/MSEK": 2.9335,
    "out:Running cost/(Apt SEK y)": 24784.12,
    "out:Running Cost over RSP/MSEK": 4.85494,
    "out:LCP/MSEK": -1.0276,
    "out:ROI% old": 9.281453,
    "out:Payback discounted": 13,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 8,
    "out:Return/kSEK/y": 245,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 74780.26136,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 120893.18,
    "out:DH kr savings": 68050.03784,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 160733.6928,
    "out:% savings (space heating)": 26.087554,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 20,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2933500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.321099,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 120.330546,
    "out:Primary Energy": 86.719752,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.25161,
    "out:CO2 Operational/m2": -19.910677,
    "out:Total CO2/m2": 14.340933,
    "out:Total CO2 (tons)": 29958.21727,
    "out:Klimatpaverkan":0.001* -85054.17018,
    "out:Initial Cost/MSEK": 2.995125,
    "out:Running cost/(Apt SEK y)": 24344.88,
    "out:Running Cost over RSP/MSEK": 4.769,
    "out:LCP/MSEK": -1.003285,
    "out:ROI% old": 9.500391,
    "out:Payback discounted": 13,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 35.8,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 9,
    "out:Return/kSEK/y": 256,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 78552.51227,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124325.92,
    "out:DH kr savings": 71482.78617,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 167790.8209,
    "out:% savings (space heating)": 22.842375,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 80.32,
    "out:Etvv": 17,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2995125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.942867,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 122.2,
    "out:Total Energy Use Post PV": 116.330546,
    "out:Primary Energy": 84.35499,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.25161,
    "out:CO2 Operational/m2": -21.350613,
    "out:Total CO2/m2": 12.900997,
    "out:Total CO2 (tons)": 26950.19014,
    "out:Klimatpaverkan":0.001* -88062.19731,
    "out:Initial Cost/MSEK": 3.04725,
    "out:Running cost/(Apt SEK y)": 23466.44,
    "out:Running Cost over RSP/MSEK": 4.59714,
    "out:LCP/MSEK": -0.88355,
    "out:ROI% old": 10.143572,
    "out:Payback discounted": 12,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 40.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 728.936757,
    "out:Return %": 9,
    "out:Return/kSEK/y": 278,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 25097.20845,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 12261.29346,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85609.64034,
    "out:EL kWh savings": 12261.29278,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130747.91,
    "out:DH kr savings": 77904.77271,
    "out:El kr savings": 20844.19772,
    "out:El kr sold": 31998.94077,
    "out:El kr saved": 20844.19889,
    "out:El kr return": 52843.13966,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 160733.6928,
    "out:% savings (space heating)": 26.087554,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 17,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3047250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.919464,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.924238,
    "out:Electricity (inc PV)": 40.25481,
    "out:Total Energy Use Pre PV": 85.924238,
    "out:Total Energy Use Post PV": 83.25481,
    "out:Primary Energy": 95.92866,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.734492,
    "out:CO2 Operational/m2": 21.145655,
    "out:Total CO2/m2": 39.880147,
    "out:Total CO2 (tons)": 83309.64997,
    "out:Klimatpaverkan":0.001* -31702.73747,
    "out:Initial Cost/MSEK": 3.415,
    "out:Running cost/(Apt SEK y)": 15540.8,
    "out:Running Cost over RSP/MSEK": 3.02602,
    "out:LCP/MSEK": 0.31982,
    "out:ROI% old": 15.623593,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 96.4,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 3004.69666,
    "out:Return %": 14,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 24.424738,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 218051.0291,
    "out:EL kWh savings": -50250.5119,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113000.57,
    "out:DH kr savings": 198426.4365,
    "out:El kr savings": -85425.87023,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 89658.78493,
    "out:% savings (space heating)": 58.770934,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 33.53,
    "out:Etvv": 0,
    "out:Ef": 40.25,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3415000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.716552,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.871111,
    "out:Electricity (inc PV)": 40.159681,
    "out:Total Energy Use Pre PV": 82.871111,
    "out:Total Energy Use Post PV": 80.159681,
    "out:Primary Energy": 93.612415,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.734492,
    "out:CO2 Operational/m2": 20.05869,
    "out:Total CO2/m2": 38.793182,
    "out:Total CO2 (tons)": 81038.97947,
    "out:Klimatpaverkan":0.001* -33973.40798,
    "out:Initial Cost/MSEK": 3.46725,
    "out:Running cost/(Apt SEK y)": 14874.44,
    "out:Running Cost over RSP/MSEK": 2.89566,
    "out:LCP/MSEK": 0.39793,
    "out:ROI% old": 15.92526,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 103.8,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 3000.97777,
    "out:Return %": 14,
    "out:Return/kSEK/y": 493,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 24.371611,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 224741.9141,
    "out:EL kWh savings": -50051.78736,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119427.1,
    "out:DH kr savings": 204515.1418,
    "out:El kr savings": -85088.03852,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 82967.89993,
    "out:% savings (space heating)": 61.847698,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 30.46,
    "out:Etvv": 0,
    "out:Ef": 40.16,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3467250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.479443,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.003032,
    "out:Electricity (inc PV)": 39.670633,
    "out:Total Energy Use Pre PV": 81.003032,
    "out:Total Energy Use Post PV": 78.670633,
    "out:Primary Energy": 93.60159,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.184468,
    "out:CO2 Operational/m2": 19.584112,
    "out:Total CO2/m2": 38.76858,
    "out:Total CO2 (tons)": 80987.58587,
    "out:Klimatpaverkan":0.001* -34024.80157,
    "out:Initial Cost/MSEK": 3.528875,
    "out:Running cost/(Apt SEK y)": 14531.52,
    "out:Running Cost over RSP/MSEK": 2.82886,
    "out:LCP/MSEK": 0.403105,
    "out:ROI% old": 15.917578,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2940.21224,
    "out:Return %": 14,
    "out:Return/kSEK/y": 501,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.503532,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 225237.2349,
    "out:EL kWh savings": -49030.16581,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121614.6,
    "out:DH kr savings": 204965.8838,
    "out:El kr savings": -83351.28188,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 82472.57909,
    "out:% savings (space heating)": 62.075468,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 31.71,
    "out:Etvv": 0,
    "out:Ef": 39.67,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3528875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 36.316942,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.937659,
    "out:Electricity (inc PV)": 39.563816,
    "out:Total Energy Use Pre PV": 77.937659,
    "out:Total Energy Use Post PV": 75.563816,
    "out:Primary Energy": 91.291304,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.184468,
    "out:CO2 Operational/m2": 18.49553,
    "out:Total CO2/m2": 37.679998,
    "out:Total CO2 (tons)": 78713.53745,
    "out:Klimatpaverkan":0.001* -36298.84999,
    "out:Initial Cost/MSEK": 3.581125,
    "out:Running cost/(Apt SEK y)": 13863.36,
    "out:Running Cost over RSP/MSEK": 2.69817,
    "out:LCP/MSEK": 0.481545,
    "out:ROI% old": 16.206679,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 114.5,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 2935.63613,
    "out:Return %": 14,
    "out:Return/kSEK/y": 518,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.438159,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231843.7013,
    "out:EL kWh savings": -48807.02504,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128005.83,
    "out:DH kr savings": 210977.7682,
    "out:El kr savings": -82971.94256,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 75866.11268,
    "out:% savings (space heating)": 65.113413,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 28.68,
    "out:Etvv": 0,
    "out:Ef": 39.56,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3581125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.919464,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.924238,
    "out:Electricity (inc PV)": 32.141603,
    "out:Total Energy Use Pre PV": 85.924238,
    "out:Total Energy Use Post PV": 75.141603,
    "out:Primary Energy": 81.324887,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.617102,
    "out:CO2 Operational/m2": 17.004196,
    "out:Total CO2/m2": 45.621298,
    "out:Total CO2 (tons)": 95302.91771,
    "out:Klimatpaverkan":0.001* -19709.46974,
    "out:Initial Cost/MSEK": 3.762625,
    "out:Running cost/(Apt SEK y)": 14293.48,
    "out:Running Cost over RSP/MSEK": 2.78877,
    "out:LCP/MSEK": 0.209445,
    "out:ROI% old": 15.080923,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 117.3,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2450.539623,
    "out:Return %": 13,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 24.424738,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1344.579456,
    "out:PV (% sold (El))": 7.198251,
    "out:PV (kWh self-consumed)": 17334.6715,
    "out:PV (ratio sold/self-consumed)": 0.077566,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 218051.0291,
    "out:EL kWh savings": -33302.01782,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143527.34,
    "out:DH kr savings": 198426.4365,
    "out:El kr savings": -56613.43029,
    "out:El kr sold": 1714.338806,
    "out:El kr saved": 29468.94155,
    "out:El kr return": 31183.28036,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 89658.78493,
    "out:% savings (space heating)": 58.770934,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 33.53,
    "out:Etvv": 0,
    "out:Ef": 32.14,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3762625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.716552,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.871111,
    "out:Electricity (inc PV)": 32.053539,
    "out:Total Energy Use Pre PV": 82.871111,
    "out:Total Energy Use Post PV": 72.053539,
    "out:Primary Energy": 79.021359,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.617102,
    "out:CO2 Operational/m2": 15.900752,
    "out:Total CO2/m2": 44.517854,
    "out:Total CO2 (tons)": 92997.82256,
    "out:Klimatpaverkan":0.001* -22014.56489,
    "out:Initial Cost/MSEK": 3.814875,
    "out:Running cost/(Apt SEK y)": 13627.2,
    "out:Running Cost over RSP/MSEK": 2.65843,
    "out:LCP/MSEK": 0.287535,
    "out:ROI% old": 15.362458,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 126.4,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2447.018676,
    "out:Return %": 14,
    "out:Return/kSEK/y": 524,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 24.371611,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1351.699555,
    "out:PV (% sold (El))": 7.236369,
    "out:PV (kWh self-consumed)": 17327.5514,
    "out:PV (ratio sold/self-consumed)": 0.078009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 224741.9141,
    "out:EL kWh savings": -33118.05207,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149937.87,
    "out:DH kr savings": 204515.1418,
    "out:El kr savings": -56300.68852,
    "out:El kr sold": 1723.416933,
    "out:El kr saved": 29456.83738,
    "out:El kr return": 31180.25431,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 82967.89993,
    "out:% savings (space heating)": 61.847698,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 30.46,
    "out:Etvv": 0,
    "out:Ef": 32.05,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3814875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.479443,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.003032,
    "out:Electricity (inc PV)": 31.601374,
    "out:Total Energy Use Pre PV": 81.003032,
    "out:Total Energy Use Post PV": 70.601374,
    "out:Primary Energy": 79.076924,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.067078,
    "out:CO2 Operational/m2": 15.148989,
    "out:Total CO2/m2": 44.216067,
    "out:Total CO2 (tons)": 92367.38934,
    "out:Klimatpaverkan":0.001* -22644.9981,
    "out:Initial Cost/MSEK": 3.8765,
    "out:Running cost/(Apt SEK y)": 13286.32,
    "out:Running Cost over RSP/MSEK": 2.59201,
    "out:LCP/MSEK": 0.29233,
    "out:ROI% old": 15.363012,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 129.6,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2389.591937,
    "out:Return %": 14,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.503532,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1471.341365,
    "out:PV (% sold (El))": 7.876876,
    "out:PV (kWh self-consumed)": 17207.90959,
    "out:PV (ratio sold/self-consumed)": 0.085504,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 225237.2349,
    "out:EL kWh savings": -32173.47913,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152146.93,
    "out:DH kr savings": 204965.8838,
    "out:El kr savings": -54694.91451,
    "out:El kr sold": 1875.960241,
    "out:El kr saved": 29253.4463,
    "out:El kr return": 31129.40655,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 82472.57909,
    "out:% savings (space heating)": 62.075468,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 31.71,
    "out:Etvv": 0,
    "out:Ef": 31.6,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3876500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 36.316942,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.937659,
    "out:Electricity (inc PV)": 31.502696,
    "out:Total Energy Use Pre PV": 77.937659,
    "out:Total Energy Use Post PV": 67.502696,
    "out:Primary Energy": 76.781288,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.067078,
    "out:CO2 Operational/m2": 14.038899,
    "out:Total CO2/m2": 43.105977,
    "out:Total CO2 (tons)": 90048.4107,
    "out:Klimatpaverkan":0.001* -24963.97675,
    "out:Initial Cost/MSEK": 3.92875,
    "out:Running cost/(Apt SEK y)": 12618.36,
    "out:Running Cost over RSP/MSEK": 2.46134,
    "out:LCP/MSEK": 0.37075,
    "out:ROI% old": 15.633835,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 2385.275282,
    "out:Return %": 14,
    "out:Return/kSEK/y": 549,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.438159,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1480.642035,
    "out:PV (% sold (El))": 7.926667,
    "out:PV (kWh self-consumed)": 17198.60892,
    "out:PV (ratio sold/self-consumed)": 0.086091,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231843.7013,
    "out:EL kWh savings": -31967.34073,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158521.11,
    "out:DH kr savings": 210977.7682,
    "out:El kr savings": -54344.47924,
    "out:El kr sold": 1887.818594,
    "out:El kr saved": 29237.63517,
    "out:El kr return": 31125.45376,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 75866.11268,
    "out:% savings (space heating)": 65.113413,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 28.68,
    "out:Etvv": 0,
    "out:Ef": 31.5,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3928750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.919464,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.924238,
    "out:Electricity (inc PV)": 28.984155,
    "out:Total Energy Use Pre PV": 85.924238,
    "out:Total Energy Use Post PV": 71.984155,
    "out:Primary Energy": 75.641481,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.499712,
    "out:CO2 Operational/m2": -11.174822,
    "out:Total CO2/m2": 27.32489,
    "out:Total CO2 (tons)": 57081.71089,
    "out:Klimatpaverkan":0.001* -57930.67655,
    "out:Initial Cost/MSEK": 4.11025,
    "out:Running cost/(Apt SEK y)": 13218.96,
    "out:Running Cost over RSP/MSEK": 2.58412,
    "out:LCP/MSEK": 0.06647,
    "out:ROI% old": 14.51674,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 126.4,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2205.194539,
    "out:Return %": 13,
    "out:Return/kSEK/y": 534,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 24.424738,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 12855.48572,
    "out:PV (% sold (El))": 34.411138,
    "out:PV (kWh self-consumed)": 24503.0162,
    "out:PV (ratio sold/self-consumed)": 0.524649,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 218051.0291,
    "out:EL kWh savings": -26706.10713,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169416.8,
    "out:DH kr savings": 198426.4365,
    "out:El kr savings": -45400.38213,
    "out:El kr sold": 16390.74429,
    "out:El kr saved": 41655.12753,
    "out:El kr return": 58045.87182,
    "out:% solar/total": 72,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 89658.78493,
    "out:% savings (space heating)": 58.770934,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 33.53,
    "out:Etvv": 0,
    "out:Ef": 28.98,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4110250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.716552,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.871111,
    "out:Electricity (inc PV)": 28.905782,
    "out:Total Energy Use Pre PV": 82.871111,
    "out:Total Energy Use Post PV": 68.905782,
    "out:Primary Energy": 73.355397,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.499712,
    "out:CO2 Operational/m2": -12.306435,
    "out:Total CO2/m2": 26.193277,
    "out:Total CO2 (tons)": 54717.77069,
    "out:Klimatpaverkan":0.001* -60294.61676,
    "out:Initial Cost/MSEK": 4.1625,
    "out:Running cost/(Apt SEK y)": 12552.92,
    "out:Running Cost over RSP/MSEK": 2.45383,
    "out:LCP/MSEK": 0.14451,
    "out:ROI% old": 14.781673,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 136.2,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2202.074567,
    "out:Return %": 13,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 24.371611,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 12874.18337,
    "out:PV (% sold (El))": 34.461187,
    "out:PV (kWh self-consumed)": 24484.31854,
    "out:PV (ratio sold/self-consumed)": 0.525813,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 224741.9141,
    "out:EL kWh savings": -26542.38589,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175807.67,
    "out:DH kr savings": 204515.1418,
    "out:El kr savings": -45122.05601,
    "out:El kr sold": 16414.58379,
    "out:El kr saved": 41623.34152,
    "out:El kr return": 58037.92532,
    "out:% solar/total": 72,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 82967.89993,
    "out:% savings (space heating)": 61.847698,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 30.46,
    "out:Etvv": 0,
    "out:Ef": 28.91,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4162500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.479443,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.003032,
    "out:Electricity (inc PV)": 28.503091,
    "out:Total Energy Use Pre PV": 81.003032,
    "out:Total Energy Use Post PV": 67.503091,
    "out:Primary Energy": 73.500015,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.949688,
    "out:CO2 Operational/m2": -13.51562,
    "out:Total CO2/m2": 25.434068,
    "out:Total CO2 (tons)": 53131.78265,
    "out:Klimatpaverkan":0.001* -61880.60479,
    "out:Initial Cost/MSEK": 4.224125,
    "out:Running cost/(Apt SEK y)": 12215.2,
    "out:Running Cost over RSP/MSEK": 2.38803,
    "out:LCP/MSEK": 0.148685,
    "out:ROI% old": 14.788557,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2151.169412,
    "out:Return %": 13,
    "out:Return/kSEK/y": 559,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.503532,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 13181.46075,
    "out:PV (% sold (El))": 35.283697,
    "out:PV (kWh self-consumed)": 24177.04116,
    "out:PV (ratio sold/self-consumed)": 0.545206,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 225237.2349,
    "out:EL kWh savings": -25701.16416,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178080.27,
    "out:DH kr savings": 204965.8838,
    "out:El kr savings": -43691.97907,
    "out:El kr sold": 16806.36246,
    "out:El kr saved": 41100.96997,
    "out:El kr return": 57907.33243,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 82472.57909,
    "out:% savings (space heating)": 62.075468,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 31.71,
    "out:Etvv": 0,
    "out:Ef": 28.5,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4224125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 36.316942,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.937659,
    "out:Electricity (inc PV)": 28.415244,
    "out:Total Energy Use Pre PV": 77.937659,
    "out:Total Energy Use Post PV": 64.415244,
    "out:Primary Energy": 71.223874,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.949688,
    "out:CO2 Operational/m2": -14.659928,
    "out:Total CO2/m2": 24.28976,
    "out:Total CO2 (tons)": 50741.32258,
    "out:Klimatpaverkan":0.001* -64271.06486,
    "out:Initial Cost/MSEK": 4.276375,
    "out:Running cost/(Apt SEK y)": 11547.48,
    "out:Running Cost over RSP/MSEK": 2.25741,
    "out:LCP/MSEK": 0.227055,
    "out:ROI% old": 15.044217,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 154.7,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 2147.341498,
    "out:Return %": 13,
    "out:Return/kSEK/y": 576,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.438159,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 13204.75141,
    "out:PV (% sold (El))": 35.346041,
    "out:PV (kWh self-consumed)": 24153.75051,
    "out:PV (ratio sold/self-consumed)": 0.546696,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231843.7013,
    "out:EL kWh savings": -25517.65173,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184433.82,
    "out:DH kr savings": 210977.7682,
    "out:El kr savings": -43380.00794,
    "out:El kr sold": 16836.05804,
    "out:El kr saved": 41061.37586,
    "out:El kr return": 57897.4339,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 75866.11268,
    "out:% savings (space heating)": 65.113413,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 28.68,
    "out:Etvv": 0,
    "out:Ef": 28.42,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4276375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.355653,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.689029,
    "out:Electricity (inc PV)": 39.4732,
    "out:Total Energy Use Pre PV": 79.689029,
    "out:Total Energy Use Post PV": 77.4732,
    "out:Primary Energy": 92.827282,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.734492,
    "out:CO2 Operational/m2": 19.182677,
    "out:Total CO2/m2": 37.917169,
    "out:Total CO2 (tons)": 79208.98781,
    "out:Klimatpaverkan":0.001* -35803.39964,
    "out:Initial Cost/MSEK": 3.481,
    "out:Running cost/(Apt SEK y)": 14267.28,
    "out:Running Cost over RSP/MSEK": 2.77728,
    "out:LCP/MSEK": 0.50256,
    "out:ROI% old": 16.348176,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 111.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 2918.23203,
    "out:Return %": 15,
    "out:Return/kSEK/y": 508,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.189529,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 227584.8329,
    "out:EL kWh savings": -48617.72816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124452.06,
    "out:DH kr savings": 207102.1979,
    "out:El kr savings": -82650.13787,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 80124.98113,
    "out:% savings (space heating)": 63.154997,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 31.11,
    "out:Etvv": 0,
    "out:Ef": 39.47,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3481000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.208217,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.619091,
    "out:Electricity (inc PV)": 39.361896,
    "out:Total Energy Use Pre PV": 76.619091,
    "out:Total Energy Use Post PV": 74.361896,
    "out:Primary Energy": 90.519284,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.734492,
    "out:CO2 Operational/m2": 18.093493,
    "out:Total CO2/m2": 36.827985,
    "out:Total CO2 (tons)": 76933.6818,
    "out:Klimatpaverkan":0.001* -38078.70564,
    "out:Initial Cost/MSEK": 3.53325,
    "out:Running cost/(Apt SEK y)": 13598.52,
    "out:Running Cost over RSP/MSEK": 2.64646,
    "out:LCP/MSEK": 0.58113,
    "out:ROI% old": 16.635352,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 120.3,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 35,
    "out:El bought/kWh/m2": 2913.33637,
    "out:Return %": 15,
    "out:Return/kSEK/y": 525,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.119591,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 234159.8285,
    "out:EL kWh savings": -48385.21404,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130830.58,
    "out:DH kr savings": 213085.4439,
    "out:El kr savings": -82254.86387,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 73549.98552,
    "out:% savings (space heating)": 66.17847,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 28.1,
    "out:Etvv": 0,
    "out:Ef": 39.36,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3533250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.707955,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.920453,
    "out:Electricity (inc PV)": 38.99287,
    "out:Total Energy Use Pre PV": 76.920453,
    "out:Total Energy Use Post PV": 74.99287,
    "out:Primary Energy": 90.972251,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.184468,
    "out:CO2 Operational/m2": 18.361251,
    "out:Total CO2/m2": 37.545719,
    "out:Total CO2 (tons)": 78433.02854,
    "out:Klimatpaverkan":0.001* -36579.3589,
    "out:Initial Cost/MSEK": 3.594875,
    "out:Running cost/(Apt SEK y)": 13718.88,
    "out:Running Cost over RSP/MSEK": 2.67024,
    "out:LCP/MSEK": 0.495725,
    "out:ROI% old": 16.255681,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 117.3,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 2864.43171,
    "out:Return %": 15,
    "out:Return/kSEK/y": 522,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.420953,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 233115.8755,
    "out:EL kWh savings": -47614.31851,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131191.11,
    "out:DH kr savings": 212135.4467,
    "out:El kr savings": -80944.34147,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 74593.93849,
    "out:% savings (space heating)": 65.698415,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 29.69,
    "out:Etvv": 0,
    "out:Ef": 38.99,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3594875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 32.602149,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.837899,
    "out:Electricity (inc PV)": 38.869009,
    "out:Total Energy Use Pre PV": 73.837899,
    "out:Total Energy Use Post PV": 71.869009,
    "out:Primary Energy": 88.670658,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.184468,
    "out:CO2 Operational/m2": 17.270401,
    "out:Total CO2/m2": 36.454869,
    "out:Total CO2 (tons)": 76154.24227,
    "out:Klimatpaverkan":0.001* -38858.14518,
    "out:Initial Cost/MSEK": 3.647,
    "out:Running cost/(Apt SEK y)": 13048.32,
    "out:Running Cost over RSP/MSEK": 2.53908,
    "out:LCP/MSEK": 0.57476,
    "out:ROI% old": 16.537115,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 126.4,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 33,
    "out:El bought/kWh/m2": 2858.65293,
    "out:Return %": 15,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.338399,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 239603.906,
    "out:EL kWh savings": -47355.57281,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 137535.08,
    "out:DH kr savings": 218039.5545,
    "out:El kr savings": -80504.47378,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 68105.90798,
    "out:% savings (space heating)": 68.681898,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 26.72,
    "out:Etvv": 0,
    "out:Ef": 38.87,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3647000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.355653,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.689029,
    "out:Electricity (inc PV)": 31.419021,
    "out:Total Energy Use Pre PV": 79.689029,
    "out:Total Energy Use Post PV": 69.419021,
    "out:Primary Energy": 78.32976,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.617102,
    "out:CO2 Operational/m2": 14.643414,
    "out:Total CO2/m2": 43.260516,
    "out:Total CO2 (tons)": 90371.24276,
    "out:Klimatpaverkan":0.001* -24641.14469,
    "out:Initial Cost/MSEK": 3.828625,
    "out:Running cost/(Apt SEK y)": 13022.88,
    "out:Running Cost over RSP/MSEK": 2.54056,
    "out:LCP/MSEK": 0.391655,
    "out:ROI% old": 15.747093,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 136.2,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 2368.86865,
    "out:Return %": 14,
    "out:Return/kSEK/y": 539,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.189529,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1516.285773,
    "out:PV (% sold (El))": 8.117487,
    "out:PV (kWh self-consumed)": 17162.96518,
    "out:PV (ratio sold/self-consumed)": 0.088346,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 227584.8329,
    "out:EL kWh savings": -31792.54361,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154988.14,
    "out:DH kr savings": 207102.1979,
    "out:El kr savings": -54047.32413,
    "out:El kr sold": 1933.264361,
    "out:El kr saved": 29177.04081,
    "out:El kr return": 31110.30517,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 80124.98113,
    "out:% savings (space heating)": 63.154997,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 31.11,
    "out:Etvv": 0,
    "out:Ef": 31.42,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3828625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.208217,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.619091,
    "out:Electricity (inc PV)": 31.316311,
    "out:Total Energy Use Pre PV": 76.619091,
    "out:Total Energy Use Post PV": 66.316311,
    "out:Primary Energy": 76.037231,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.617102,
    "out:CO2 Operational/m2": 13.530765,
    "out:Total CO2/m2": 42.147867,
    "out:Total CO2 (tons)": 88046.91836,
    "out:Klimatpaverkan":0.001* -26965.46909,
    "out:Initial Cost/MSEK": 3.880875,
    "out:Running cost/(Apt SEK y)": 12354.28,
    "out:Running Cost over RSP/MSEK": 2.40977,
    "out:LCP/MSEK": 0.470195,
    "out:ROI% old": 16.016528,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 147,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 35,
    "out:El bought/kWh/m2": 2364.256571,
    "out:Return %": 14,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.119591,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1526.375997,
    "out:PV (% sold (El))": 8.171505,
    "out:PV (kWh self-consumed)": 17152.87496,
    "out:PV (ratio sold/self-consumed)": 0.088987,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 234159.8285,
    "out:EL kWh savings": -31577.98236,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161349,
    "out:DH kr savings": 213085.4439,
    "out:El kr savings": -53682.57,
    "out:El kr sold": 1946.129396,
    "out:El kr saved": 29159.88743,
    "out:El kr return": 31106.01683,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 73549.98552,
    "out:% savings (space heating)": 66.17847,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 28.1,
    "out:Etvv": 0,
    "out:Ef": 31.32,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3880875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.707955,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.920453,
    "out:Electricity (inc PV)": 30.976379,
    "out:Total Energy Use Pre PV": 76.920453,
    "out:Total Energy Use Post PV": 66.976379,
    "out:Primary Energy": 76.542567,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.067078,
    "out:CO2 Operational/m2": 13.558828,
    "out:Total CO2/m2": 42.625906,
    "out:Total CO2 (tons)": 89045.5421,
    "out:Klimatpaverkan":0.001* -25966.84534,
    "out:Initial Cost/MSEK": 3.9425,
    "out:Running cost/(Apt SEK y)": 12476.4,
    "out:Running Cost over RSP/MSEK": 2.43388,
    "out:LCP/MSEK": 0.38446,
    "out:ROI% old": 15.678811,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 143.3,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 2318.257073,
    "out:Return %": 14,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.420953,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1629.248527,
    "out:PV (% sold (El))": 8.722237,
    "out:PV (kWh self-consumed)": 17050.00243,
    "out:PV (ratio sold/self-consumed)": 0.095557,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 233115.8755,
    "out:EL kWh savings": -30867.86421,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 161737.37,
    "out:DH kr savings": 212135.4467,
    "out:El kr savings": -52475.36916,
    "out:El kr sold": 2077.291872,
    "out:El kr saved": 28985.00413,
    "out:El kr return": 31062.296,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 74593.93849,
    "out:% savings (space heating)": 65.698415,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 29.69,
    "out:Etvv": 0,
    "out:Ef": 30.98,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3942500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 32.602149,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.837899,
    "out:Electricity (inc PV)": 30.862402,
    "out:Total Energy Use Pre PV": 73.837899,
    "out:Total Energy Use Post PV": 63.862402,
    "out:Primary Energy": 74.258766,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.067078,
    "out:CO2 Operational/m2": 12.439029,
    "out:Total CO2/m2": 41.506107,
    "out:Total CO2 (tons)": 86706.28135,
    "out:Klimatpaverkan":0.001* -28306.1061,
    "out:Initial Cost/MSEK": 3.994625,
    "out:Running cost/(Apt SEK y)": 11806.04,
    "out:Running Cost over RSP/MSEK": 2.30276,
    "out:LCP/MSEK": 0.463455,
    "out:ROI% old": 15.943138,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 154.7,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 33,
    "out:El bought/kWh/m2": 2312.8304,
    "out:Return %": 14,
    "out:Return/kSEK/y": 569,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.338399,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 1641.66896,
    "out:PV (% sold (El))": 8.78873,
    "out:PV (kWh self-consumed)": 17037.582,
    "out:PV (ratio sold/self-consumed)": 0.096356,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 239603.906,
    "out:EL kWh savings": -30629.76619,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168062.08,
    "out:DH kr savings": 218039.5545,
    "out:El kr savings": -52070.60253,
    "out:El kr sold": 2093.127924,
    "out:El kr saved": 28963.88939,
    "out:El kr return": 31057.01732,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 68105.90798,
    "out:% savings (space heating)": 68.681898,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 26.72,
    "out:Etvv": 0,
    "out:Ef": 30.86,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3994625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.355653,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.689029,
    "out:Electricity (inc PV)": 28.340732,
    "out:Total Energy Use Pre PV": 79.689029,
    "out:Total Energy Use Post PV": 66.340732,
    "out:Primary Energy": 72.78884,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.499712,
    "out:CO2 Operational/m2": -14.185295,
    "out:Total CO2/m2": 24.314417,
    "out:Total CO2 (tons)": 50792.83107,
    "out:Klimatpaverkan":0.001* -64219.55638,
    "out:Initial Cost/MSEK": 4.17625,
    "out:Running cost/(Apt SEK y)": 11952.88,
    "out:Running Cost over RSP/MSEK": 2.3368,
    "out:LCP/MSEK": 0.24779,
    "out:ROI% old": 15.13333,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 147,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 2132.790077,
    "out:Return %": 14,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.189529,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 13293.63849,
    "out:PV (% sold (El))": 35.583971,
    "out:PV (kWh self-consumed)": 24064.86342,
    "out:PV (ratio sold/self-consumed)": 0.552409,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 227584.8329,
    "out:EL kWh savings": -25361.99612,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180936.19,
    "out:DH kr savings": 207102.1979,
    "out:El kr savings": -43115.3934,
    "out:El kr sold": 16949.38907,
    "out:El kr saved": 40910.26782,
    "out:El kr return": 57859.65689,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 80124.98113,
    "out:% savings (space heating)": 63.154997,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 31.11,
    "out:Etvv": 0,
    "out:Ef": 28.34,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4176250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.208217,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.619091,
    "out:Electricity (inc PV)": 28.249216,
    "out:Total Energy Use Pre PV": 76.619091,
    "out:Total Energy Use Post PV": 63.249216,
    "out:Primary Energy": 70.51646,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.499712,
    "out:CO2 Operational/m2": -15.334423,
    "out:Total CO2/m2": 23.165289,
    "out:Total CO2 (tons)": 48392.30202,
    "out:Klimatpaverkan":0.001* -66620.08543,
    "out:Initial Cost/MSEK": 4.2285,
    "out:Running cost/(Apt SEK y)": 11284.56,
    "out:Running Cost over RSP/MSEK": 2.20606,
    "out:LCP/MSEK": 0.32628,
    "out:ROI% old": 15.38803,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 158.7,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 35,
    "out:El bought/kWh/m2": 2128.69891,
    "out:Return %": 14,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 23.119591,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 13318.69652,
    "out:PV (% sold (El))": 35.651045,
    "out:PV (kWh self-consumed)": 24039.8054,
    "out:PV (ratio sold/self-consumed)": 0.554027,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 234159.8285,
    "out:EL kWh savings": -25170.81914,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 187276.39,
    "out:DH kr savings": 213085.4439,
    "out:El kr savings": -42790.39254,
    "out:El kr sold": 16981.33806,
    "out:El kr saved": 40867.66918,
    "out:El kr return": 57849.00723,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 73549.98552,
    "out:% savings (space heating)": 66.17847,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 28.1,
    "out:Etvv": 0,
    "out:Ef": 28.25,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4228500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.707955,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.920453,
    "out:Electricity (inc PV)": 27.946117,
    "out:Total Energy Use Pre PV": 76.920453,
    "out:Total Energy Use Post PV": 63.946117,
    "out:Primary Energy": 71.088096,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.949688,
    "out:CO2 Operational/m2": -15.669289,
    "out:Total CO2/m2": 23.280399,
    "out:Total CO2 (tons)": 48632.76687,
    "out:Klimatpaverkan":0.001* -66379.62057,
    "out:Initial Cost/MSEK": 4.290125,
    "out:Running cost/(Apt SEK y)": 11409.2,
    "out:Running Cost over RSP/MSEK": 2.23067,
    "out:LCP/MSEK": 0.240045,
    "out:ROI% old": 15.085042,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 154.7,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 2087.879698,
    "out:Return %": 14,
    "out:Return/kSEK/y": 579,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.420953,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 13570.69917,
    "out:PV (% sold (El))": 36.325598,
    "out:PV (kWh self-consumed)": 23787.80275,
    "out:PV (ratio sold/self-consumed)": 0.57049,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 233115.8755,
    "out:EL kWh savings": -24537.64516,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 187724.09,
    "out:DH kr savings": 212135.4467,
    "out:El kr savings": -41713.99676,
    "out:El kr sold": 17302.64144,
    "out:El kr saved": 40439.26467,
    "out:El kr return": 57741.9061,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 74593.93849,
    "out:% savings (space heating)": 65.698415,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 29.69,
    "out:Etvv": 0,
    "out:Ef": 27.95,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4290125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 32.602149,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.837899,
    "out:Electricity (inc PV)": 27.844534,
    "out:Total Energy Use Pre PV": 73.837899,
    "out:Total Energy Use Post PV": 60.844534,
    "out:Primary Energy": 68.826603,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.949688,
    "out:CO2 Operational/m2": -16.83181,
    "out:Total CO2/m2": 22.117878,
    "out:Total CO2 (tons)": 46204.25984,
    "out:Klimatpaverkan":0.001* -68808.12761,
    "out:Initial Cost/MSEK": 4.34225,
    "out:Running cost/(Apt SEK y)": 10739.16,
    "out:Running Cost over RSP/MSEK": 2.0996,
    "out:LCP/MSEK": 0.31899,
    "out:ROI% old": 15.33517,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 167.2,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 33,
    "out:El bought/kWh/m2": 2083.062064,
    "out:Return %": 14,
    "out:Return/kSEK/y": 596,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.338399,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 13600.6978,
    "out:PV (% sold (El))": 36.405897,
    "out:PV (kWh self-consumed)": 23757.80411,
    "out:PV (ratio sold/self-consumed)": 0.572473,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 239603.906,
    "out:EL kWh savings": -24325.43821,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194027.2,
    "out:DH kr savings": 218039.5545,
    "out:El kr savings": -41353.24496,
    "out:El kr sold": 17340.8897,
    "out:El kr saved": 40388.26699,
    "out:El kr return": 57729.15668,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 68105.90798,
    "out:% savings (space heating)": 68.681898,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 26.72,
    "out:Etvv": 0,
    "out:Ef": 27.84,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4342250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.278575,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.330568,
    "out:Electricity (inc PV)": 49.533048,
    "out:Total Energy Use Pre PV": 59.330568,
    "out:Total Energy Use Post PV": 54.533048,
    "out:Primary Energy": 91.196759,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.286986,
    "out:CO2 Operational/m2": 8.97199,
    "out:Total CO2/m2": 28.258976,
    "out:Total CO2 (tons)": 59033.01709,
    "out:Klimatpaverkan":0.001* -55979.37036,
    "out:Initial Cost/MSEK": 4.47575,
    "out:Running cost/(Apt SEK y)": 8815.84,
    "out:Running Cost over RSP/MSEK": 1.70645,
    "out:LCP/MSEK": 0.57864,
    "out:ROI% old": 16.132619,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 196.4,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3803.13976,
    "out:Return %": 14,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.831068,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 296682.8678,
    "out:EL kWh savings": -69632.75641,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151605.72,
    "out:DH kr savings": 269981.4097,
    "out:El kr savings": -118375.6859,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 11026.94621,
    "out:% savings (space heating)": 94.929323,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.91,
    "out:Etvv": 0,
    "out:Ef": 49.53,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4475750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.996338,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.695418,
    "out:Electricity (inc PV)": 48.760421,
    "out:Total Energy Use Pre PV": 57.695418,
    "out:Total Energy Use Post PV": 52.760421,
    "out:Primary Energy": 89.226038,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.286986,
    "out:CO2 Operational/m2": 8.528161,
    "out:Total CO2/m2": 27.815147,
    "out:Total CO2 (tons)": 58105.85805,
    "out:Klimatpaverkan":0.001* -56906.5294,
    "out:Initial Cost/MSEK": 4.528,
    "out:Running cost/(Apt SEK y)": 8506,
    "out:Running Cost over RSP/MSEK": 1.64604,
    "out:LCP/MSEK": 0.5868,
    "out:ROI% old": 16.137052,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 207.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3758.67926,
    "out:Return %": 14,
    "out:Return/kSEK/y": 652,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.195918,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 299361.4616,
    "out:EL kWh savings": -68018.73816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156787.08,
    "out:DH kr savings": 272418.9301,
    "out:El kr savings": -115631.8549,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 8348.352376,
    "out:% savings (space heating)": 96.161059,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.08,
    "out:Etvv": 0,
    "out:Ef": 48.76,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4528000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.291571,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.666023,
    "out:Electricity (inc PV)": 48.504579,
    "out:Total Energy Use Pre PV": 56.666023,
    "out:Total Energy Use Post PV": 52.504579,
    "out:Primary Energy": 89.096277,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.736962,
    "out:CO2 Operational/m2": 8.392272,
    "out:Total CO2/m2": 28.129234,
    "out:Total CO2 (tons)": 58761.98597,
    "out:Klimatpaverkan":0.001* -56250.40147,
    "out:Initial Cost/MSEK": 4.589625,
    "out:Running cost/(Apt SEK y)": 8359.76,
    "out:Running Cost over RSP/MSEK": 1.61778,
    "out:LCP/MSEK": 0.553435,
    "out:ROI% old": 16.008342,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 207.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3686.62161,
    "out:Return %": 14,
    "out:Return/kSEK/y": 656,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.166523,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 298744.7197,
    "out:EL kWh savings": -67484.28407,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157134.41,
    "out:DH kr savings": 271857.6949,
    "out:El kr savings": -114723.2829,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 8965.094282,
    "out:% savings (space heating)": 95.877454,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.55,
    "out:Etvv": 0,
    "out:Ef": 48.5,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4589625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.199145,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.973355,
    "out:Electricity (inc PV)": 47.712094,
    "out:Total Energy Use Pre PV": 54.973355,
    "out:Total Energy Use Post PV": 50.712094,
    "out:Primary Energy": 87.135685,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.736962,
    "out:CO2 Operational/m2": 7.940851,
    "out:Total CO2/m2": 27.677813,
    "out:Total CO2 (tons)": 57818.96724,
    "out:Klimatpaverkan":0.001* -57193.4202,
    "out:Initial Cost/MSEK": 4.641875,
    "out:Running cost/(Apt SEK y)": 8041.76,
    "out:Running Cost over RSP/MSEK": 1.5558,
    "out:LCP/MSEK": 0.563165,
    "out:ROI% old": 16.018896,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 219.6,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3638.13485,
    "out:Return %": 14,
    "out:Return/kSEK/y": 664,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 33.473855,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 301026.7983,
    "out:EL kWh savings": -65828.78245,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162025.46,
    "out:DH kr savings": 273934.3864,
    "out:El kr savings": -111908.9302,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 6683.015741,
    "out:% savings (space heating)": 96.926854,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 1.79,
    "out:Etvv": 0,
    "out:Ef": 47.71,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4641875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.278575,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.330568,
    "out:Electricity (inc PV)": 40.889888,
    "out:Total Energy Use Pre PV": 59.330568,
    "out:Total Energy Use Post PV": 45.889888,
    "out:Primary Energy": 75.639071,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.169596,
    "out:CO2 Operational/m2": 7.226144,
    "out:Total CO2/m2": 36.39574,
    "out:Total CO2 (tons)": 76030.72175,
    "out:Klimatpaverkan":0.001* -38981.66569,
    "out:Initial Cost/MSEK": 4.823375,
    "out:Running cost/(Apt SEK y)": 7550.64,
    "out:Running Cost over RSP/MSEK": 1.46595,
    "out:LCP/MSEK": 0.471515,
    "out:ROI% old": 15.682232,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 254.3,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3222.16642,
    "out:Return %": 14,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.831068,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 292.941854,
    "out:PV (% sold (El))": 1.568274,
    "out:PV (kWh self-consumed)": 18386.3091,
    "out:PV (ratio sold/self-consumed)": 0.015933,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 296682.8678,
    "out:EL kWh savings": -51577.1902,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182673.69,
    "out:DH kr savings": 269981.4097,
    "out:El kr savings": -87681.22335,
    "out:El kr sold": 373.5008639,
    "out:El kr saved": 31256.72547,
    "out:El kr return": 31630.22634,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 11026.94621,
    "out:% savings (space heating)": 94.929323,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.91,
    "out:Etvv": 0,
    "out:Ef": 40.89,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4823375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.996338,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.695418,
    "out:Electricity (inc PV)": 40.149953,
    "out:Total Energy Use Pre PV": 57.695418,
    "out:Total Energy Use Post PV": 44.149953,
    "out:Primary Energy": 73.727195,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.169596,
    "out:CO2 Operational/m2": 6.70102,
    "out:Total CO2/m2": 35.870616,
    "out:Total CO2 (tons)": 74933.73741,
    "out:Klimatpaverkan":0.001* -40078.65003,
    "out:Initial Cost/MSEK": 4.875625,
    "out:Running cost/(Apt SEK y)": 7241.4,
    "out:Running Cost over RSP/MSEK": 1.40565,
    "out:LCP/MSEK": 0.479565,
    "out:ROI% old": 15.690853,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 270.5,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3178.462616,
    "out:Return %": 14,
    "out:Return/kSEK/y": 684,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.195918,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 329.250914,
    "out:PV (% sold (El))": 1.762656,
    "out:PV (kWh self-consumed)": 18350.00004,
    "out:PV (ratio sold/self-consumed)": 0.017943,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 299361.4616,
    "out:EL kWh savings": -50031.46556,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 187785.23,
    "out:DH kr savings": 272418.9301,
    "out:El kr savings": -85053.49146,
    "out:El kr sold": 419.794915,
    "out:El kr saved": 31195.00007,
    "out:El kr return": 31614.79499,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 8348.352376,
    "out:% savings (space heating)": 96.161059,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.08,
    "out:Etvv": 0,
    "out:Ef": 40.15,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4875625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.291571,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.666023,
    "out:Electricity (inc PV)": 39.905432,
    "out:Total Energy Use Pre PV": 56.666023,
    "out:Total Energy Use Post PV": 43.905432,
    "out:Primary Energy": 73.617813,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.619572,
    "out:CO2 Operational/m2": 6.423391,
    "out:Total CO2/m2": 36.042963,
    "out:Total CO2 (tons)": 75293.7704,
    "out:Klimatpaverkan":0.001* -39718.61705,
    "out:Initial Cost/MSEK": 4.93725,
    "out:Running cost/(Apt SEK y)": 7096.24,
    "out:Running Cost over RSP/MSEK": 1.37758,
    "out:LCP/MSEK": 0.44601,
    "out:ROI% old": 15.576224,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 270.5,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3107.805761,
    "out:Return %": 14,
    "out:Return/kSEK/y": 687,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.166523,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 391.992558,
    "out:PV (% sold (El))": 2.098545,
    "out:PV (kWh self-consumed)": 18287.2584,
    "out:PV (ratio sold/self-consumed)": 0.021435,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298744.7197,
    "out:EL kWh savings": -49520.66106,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188172.36,
    "out:DH kr savings": 271857.6949,
    "out:El kr savings": -84185.12379,
    "out:El kr sold": 499.7905116,
    "out:El kr saved": 31088.33928,
    "out:El kr return": 31588.12979,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 8965.094282,
    "out:% savings (space heating)": 95.877454,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.55,
    "out:Etvv": 0,
    "out:Ef": 39.91,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4937250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.199145,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.973355,
    "out:Electricity (inc PV)": 39.149544,
    "out:Total Energy Use Pre PV": 54.973355,
    "out:Total Energy Use Post PV": 42.149544,
    "out:Primary Energy": 71.723095,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.619572,
    "out:CO2 Operational/m2": 5.869809,
    "out:Total CO2/m2": 35.489381,
    "out:Total CO2 (tons)": 74137.33728,
    "out:Klimatpaverkan":0.001* -40875.05016,
    "out:Initial Cost/MSEK": 4.9895,
    "out:Running cost/(Apt SEK y)": 6779,
    "out:Running Cost over RSP/MSEK": 1.31574,
    "out:LCP/MSEK": 0.4556,
    "out:ROI% old": 15.590168,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 288.1,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3060.382076,
    "out:Return %": 14,
    "out:Return/kSEK/y": 695,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 33.473855,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 437.266837,
    "out:PV (% sold (El))": 2.340923,
    "out:PV (kWh self-consumed)": 18241.98412,
    "out:PV (ratio sold/self-consumed)": 0.02397,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 301026.7983,
    "out:EL kWh savings": -47941.61059,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192991.16,
    "out:DH kr savings": 273934.3864,
    "out:El kr savings": -81500.738,
    "out:El kr sold": 557.5152175,
    "out:El kr saved": 31011.373,
    "out:El kr return": 31568.88822,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 6683.015741,
    "out:% savings (space heating)": 96.926854,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 1.79,
    "out:Etvv": 0,
    "out:Ef": 39.15,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4989500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.278575,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.330568,
    "out:Electricity (inc PV)": 36.743899,
    "out:Total Energy Use Pre PV": 59.330568,
    "out:Total Energy Use Post PV": 41.743899,
    "out:Primary Energy": 68.176291,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.052206,
    "out:CO2 Operational/m2": -14.623988,
    "out:Total CO2/m2": 24.428218,
    "out:Total CO2 (tons)": 51030.56142,
    "out:Klimatpaverkan":0.001* -63981.82602,
    "out:Initial Cost/MSEK": 5.171,
    "out:Running cost/(Apt SEK y)": 6431.76,
    "out:Running Cost over RSP/MSEK": 1.25271,
    "out:LCP/MSEK": 0.33713,
    "out:ROI% old": 15.21709,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 288.1,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2888.648913,
    "out:Return %": 14,
    "out:Return/kSEK/y": 704,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.831068,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 9194.724946,
    "out:PV (% sold (El))": 24.612135,
    "out:PV (kWh self-consumed)": 28163.77697,
    "out:PV (ratio sold/self-consumed)": 0.326473,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 296682.8678,
    "out:EL kWh savings": -42916.2168,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 208747.12,
    "out:DH kr savings": 269981.4097,
    "out:El kr savings": -72957.56857,
    "out:El kr sold": 11723.27431,
    "out:El kr saved": 47878.42084,
    "out:El kr return": 59601.69515,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 11026.94621,
    "out:% savings (space heating)": 94.929323,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.91,
    "out:Etvv": 0,
    "out:Ef": 36.74,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5171000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.996338,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.695418,
    "out:Electricity (inc PV)": 36.089316,
    "out:Total Energy Use Pre PV": 57.695418,
    "out:Total Energy Use Post PV": 40.089316,
    "out:Primary Energy": 66.418049,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.052206,
    "out:CO2 Operational/m2": -15.501612,
    "out:Total CO2/m2": 23.550594,
    "out:Total CO2 (tons)": 49197.20438,
    "out:Klimatpaverkan":0.001* -65815.18306,
    "out:Initial Cost/MSEK": 5.22325,
    "out:Running cost/(Apt SEK y)": 6125.04,
    "out:Running Cost over RSP/MSEK": 1.19289,
    "out:LCP/MSEK": 0.3447,
    "out:ROI% old": 15.228477,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 307.5,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2849.881844,
    "out:Return %": 14,
    "out:Return/kSEK/y": 711,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.195918,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 9377.474235,
    "out:PV (% sold (El))": 25.101312,
    "out:PV (kWh self-consumed)": 27981.02768,
    "out:PV (ratio sold/self-consumed)": 0.335137,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 299361.4616,
    "out:EL kWh savings": -41548.79254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 213742.26,
    "out:DH kr savings": 272418.9301,
    "out:El kr savings": -70632.94732,
    "out:El kr sold": 11956.27965,
    "out:El kr saved": 47567.74705,
    "out:El kr return": 59524.0267,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 8348.352376,
    "out:% savings (space heating)": 96.161059,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.08,
    "out:Etvv": 0,
    "out:Ef": 36.09,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5223250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.291571,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.666023,
    "out:Electricity (inc PV)": 35.872995,
    "out:Total Energy Use Pre PV": 56.666023,
    "out:Total Energy Use Post PV": 39.872995,
    "out:Primary Energy": 66.359426,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.502182,
    "out:CO2 Operational/m2": -16.352994,
    "out:Total CO2/m2": 23.149188,
    "out:Total CO2 (tons)": 48358.66702,
    "out:Klimatpaverkan":0.001* -66653.72042,
    "out:Initial Cost/MSEK": 5.284875,
    "out:Running cost/(Apt SEK y)": 5983.92,
    "out:Running Cost over RSP/MSEK": 1.1656,
    "out:LCP/MSEK": 0.310365,
    "out:ROI% old": 15.124672,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 307.5,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2787.207387,
    "out:Return %": 14,
    "out:Return/kSEK/y": 715,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.166523,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 9678.79755,
    "out:PV (% sold (El))": 25.907885,
    "out:PV (kWh self-consumed)": 27679.70436,
    "out:PV (ratio sold/self-consumed)": 0.349671,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298744.7197,
    "out:EL kWh savings": -41096.89785,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214333.44,
    "out:DH kr savings": 271857.6949,
    "out:El kr savings": -69864.72634,
    "out:El kr sold": 12340.46688,
    "out:El kr saved": 47055.49742,
    "out:El kr return": 59395.96429,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 8965.094282,
    "out:% savings (space heating)": 95.877454,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.55,
    "out:Etvv": 0,
    "out:Ef": 35.87,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5284875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.199145,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.973355,
    "out:Electricity (inc PV)": 35.203708,
    "out:Total Energy Use Pre PV": 54.973355,
    "out:Total Energy Use Post PV": 38.203708,
    "out:Primary Energy": 64.62059,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.502182,
    "out:CO2 Operational/m2": -17.294853,
    "out:Total CO2/m2": 22.207329,
    "out:Total CO2 (tons)": 46391.12303,
    "out:Klimatpaverkan":0.001* -68621.26442,
    "out:Initial Cost/MSEK": 5.337125,
    "out:Running cost/(Apt SEK y)": 5669.44,
    "out:Running Cost over RSP/MSEK": 1.10429,
    "out:LCP/MSEK": 0.319425,
    "out:ROI% old": 15.140709,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 328.9,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2745.145994,
    "out:Return %": 14,
    "out:Return/kSEK/y": 723,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 33.473855,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 9885.57365,
    "out:PV (% sold (El))": 26.461376,
    "out:PV (kWh self-consumed)": 27472.92826,
    "out:PV (ratio sold/self-consumed)": 0.35983,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 301026.7983,
    "out:EL kWh savings": -39698.75692,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 219050.61,
    "out:DH kr savings": 273934.3864,
    "out:El kr savings": -67487.88676,
    "out:El kr sold": 12604.1064,
    "out:El kr saved": 46703.97805,
    "out:El kr return": 59308.08445,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 6683.015741,
    "out:% savings (space heating)": 96.926854,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 1.79,
    "out:Etvv": 0,
    "out:Ef": 35.2,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5337125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.002239,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.099154,
    "out:Electricity (inc PV)": 48.159526,
    "out:Total Energy Use Pre PV": 56.099154,
    "out:Total Energy Use Post PV": 52.159526,
    "out:Primary Energy": 88.397255,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.286986,
    "out:CO2 Operational/m2": 8.317441,
    "out:Total CO2/m2": 27.604427,
    "out:Total CO2 (tons)": 57665.66385,
    "out:Klimatpaverkan":0.001* -57346.7236,
    "out:Initial Cost/MSEK": 4.54175,
    "out:Running cost/(Apt SEK y)": 8279.24,
    "out:Running Cost over RSP/MSEK": 1.60222,
    "out:LCP/MSEK": 0.61687,
    "out:ROI% old": 16.22603,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 213.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3646.94078,
    "out:Return %": 14,
    "out:Return/kSEK/y": 658,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 33.599654,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 299349.1344,
    "out:EL kWh savings": -66763.46816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158909.82,
    "out:DH kr savings": 272407.7123,
    "out:El kr savings": -113497.8959,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 8360.679568,
    "out:% savings (space heating)": 96.155391,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.44,
    "out:Etvv": 0,
    "out:Ef": 48.16,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4541750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.964272,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.389983,
    "out:Electricity (inc PV)": 47.360866,
    "out:Total Energy Use Pre PV": 54.389983,
    "out:Total Energy Use Post PV": 50.360866,
    "out:Primary Energy": 86.439811,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.286986,
    "out:CO2 Operational/m2": 7.863841,
    "out:Total CO2/m2": 27.150827,
    "out:Total CO2 (tons)": 56718.09319,
    "out:Klimatpaverkan":0.001* -58294.29426,
    "out:Initial Cost/MSEK": 4.594,
    "out:Running cost/(Apt SEK y)": 7958.88,
    "out:Running Cost over RSP/MSEK": 1.53978,
    "out:LCP/MSEK": 0.62706,
    "out:ROI% old": 16.235649,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 226,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3597.29881,
    "out:Return %": 14,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 32.890483,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 301517.4481,
    "out:EL kWh savings": -65095.06696,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163719.26,
    "out:DH kr savings": 274380.8778,
    "out:El kr savings": -110661.6138,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 6192.365909,
    "out:% savings (space heating)": 97.152477,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 1.7,
    "out:Etvv": 0,
    "out:Ef": 47.36,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4594000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.374741,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.718396,
    "out:Electricity (inc PV)": 47.327162,
    "out:Total Energy Use Pre PV": 53.718396,
    "out:Total Energy Use Post PV": 50.327162,
    "out:Primary Energy": 86.721761,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.736962,
    "out:CO2 Operational/m2": 7.775186,
    "out:Total CO2/m2": 27.512148,
    "out:Total CO2 (tons)": 57472.89296,
    "out:Klimatpaverkan":0.001* -57539.49448,
    "out:Initial Cost/MSEK": 4.655625,
    "out:Running cost/(Apt SEK y)": 7863.48,
    "out:Running Cost over RSP/MSEK": 1.52134,
    "out:LCP/MSEK": 0.583875,
    "out:ROI% old": 16.077326,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 226,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3550.28772,
    "out:Return %": 14,
    "out:Return/kSEK/y": 668,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.218896,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 300659.9781,
    "out:EL kWh savings": -65024.65929,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163058.66,
    "out:DH kr savings": 273600.5801,
    "out:El kr savings": -110541.9208,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 7049.835886,
    "out:% savings (space heating)": 96.758174,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.19,
    "out:Etvv": 0,
    "out:Ef": 47.33,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4655625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.457682,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 49.972586,
    "out:Electricity (inc PV)": 46.514312,
    "out:Total Energy Use Pre PV": 51.972586,
    "out:Total Energy Use Post PV": 48.514312,
    "out:Primary Energy": 84.771554,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.736962,
    "out:CO2 Operational/m2": 7.316749,
    "out:Total CO2/m2": 27.053711,
    "out:Total CO2 (tons)": 56515.21781,
    "out:Klimatpaverkan":0.001* -58497.16964,
    "out:Initial Cost/MSEK": 4.70775,
    "out:Running cost/(Apt SEK y)": 7537.92,
    "out:Running Cost over RSP/MSEK": 1.4579,
    "out:LCP/MSEK": 0.59519,
    "out:ROI% old": 16.091824,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 232.7,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3498.08102,
    "out:Return %": 14,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 31.473086,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 302575.7149,
    "out:EL kWh savings": -63326.61517,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167688.65,
    "out:DH kr savings": 275343.9006,
    "out:El kr savings": -107655.2458,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 5134.099109,
    "out:% savings (space heating)": 97.639115,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 1.49,
    "out:Etvv": 0,
    "out:Ef": 46.51,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4707750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.002239,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.099154,
    "out:Electricity (inc PV)": 39.576072,
    "out:Total Energy Use Pre PV": 56.099154,
    "out:Total Energy Use Post PV": 43.576072,
    "out:Primary Energy": 72.947038,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.169596,
    "out:CO2 Operational/m2": 6.265409,
    "out:Total CO2/m2": 35.435005,
    "out:Total CO2 (tons)": 74023.74579,
    "out:Klimatpaverkan":0.001* -40988.64166,
    "out:Initial Cost/MSEK": 4.889375,
    "out:Running cost/(Apt SEK y)": 7016.36,
    "out:Running Cost over RSP/MSEK": 1.36213,
    "out:LCP/MSEK": 0.509335,
    "out:ROI% old": 15.773883,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 270.5,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3068.987805,
    "out:Return %": 14,
    "out:Return/kSEK/y": 689,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 33.599654,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 428.777025,
    "out:PV (% sold (El))": 2.295472,
    "out:PV (kWh self-consumed)": 18250.47393,
    "out:PV (ratio sold/self-consumed)": 0.023494,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 299349.1344,
    "out:EL kWh savings": -48832.62783,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189938.94,
    "out:DH kr savings": 272407.7123,
    "out:El kr savings": -83015.4673,
    "out:El kr sold": 546.6907071,
    "out:El kr saved": 31025.80568,
    "out:El kr return": 31572.49639,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 8360.679568,
    "out:% savings (space heating)": 96.155391,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.44,
    "out:Etvv": 0,
    "out:Ef": 39.58,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4889375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.964272,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.389983,
    "out:Electricity (inc PV)": 38.815158,
    "out:Total Energy Use Pre PV": 54.389983,
    "out:Total Energy Use Post PV": 41.815158,
    "out:Primary Energy": 71.057536,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.169596,
    "out:CO2 Operational/m2": 5.70192,
    "out:Total CO2/m2": 34.871516,
    "out:Total CO2 (tons)": 72846.61694,
    "out:Klimatpaverkan":0.001* -42165.7705,
    "out:Initial Cost/MSEK": 4.941625,
    "out:Running cost/(Apt SEK y)": 6696.84,
    "out:Running Cost over RSP/MSEK": 1.29984,
    "out:LCP/MSEK": 0.519375,
    "out:ROI% old": 15.787172,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 288.1,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3020.515482,
    "out:Return %": 14,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 32.890483,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 477.926937,
    "out:PV (% sold (El))": 2.558598,
    "out:PV (kWh self-consumed)": 18201.32402,
    "out:PV (ratio sold/self-consumed)": 0.026258,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 301517.4481,
    "out:EL kWh savings": -47243.07804,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194677,
    "out:DH kr savings": 274380.8778,
    "out:El kr savings": -80313.23267,
    "out:El kr sold": 609.3568443,
    "out:El kr saved": 30942.25083,
    "out:El kr return": 31551.60768,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 6192.365909,
    "out:% savings (space heating)": 97.152477,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 1.7,
    "out:Etvv": 0,
    "out:Ef": 38.82,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4941625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.374741,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.718396,
    "out:Electricity (inc PV)": 38.783089,
    "out:Total Energy Use Pre PV": 53.718396,
    "out:Total Energy Use Post PV": 41.783089,
    "out:Primary Energy": 71.342429,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.619572,
    "out:CO2 Operational/m2": 5.502169,
    "out:Total CO2/m2": 35.121741,
    "out:Total CO2 (tons)": 73369.33711,
    "out:Klimatpaverkan":0.001* -41643.05034,
    "out:Initial Cost/MSEK": 5.00325,
    "out:Running cost/(Apt SEK y)": 6602.28,
    "out:Running Cost over RSP/MSEK": 1.28156,
    "out:LCP/MSEK": 0.47603,
    "out:ROI% old": 15.644917,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 288.1,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2974.706655,
    "out:Return %": 14,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.218896,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 527.862618,
    "out:PV (% sold (El))": 2.82593,
    "out:PV (kWh self-consumed)": 18151.38834,
    "out:PV (ratio sold/self-consumed)": 0.029081,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 300659.9781,
    "out:EL kWh savings": -47176.08588,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194074.26,
    "out:DH kr savings": 273600.5801,
    "out:El kr savings": -80199.346,
    "out:El kr sold": 673.0248381,
    "out:El kr saved": 30857.36017,
    "out:El kr return": 31530.38501,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 7049.835886,
    "out:% savings (space heating)": 96.758174,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.19,
    "out:Etvv": 0,
    "out:Ef": 38.78,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5003250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.457682,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 49.972586,
    "out:Electricity (inc PV)": 38.010506,
    "out:Total Energy Use Pre PV": 51.972586,
    "out:Total Energy Use Post PV": 40.010506,
    "out:Primary Energy": 69.464703,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 29.619572,
    "out:CO2 Operational/m2": 4.911555,
    "out:Total CO2/m2": 34.531127,
    "out:Total CO2 (tons)": 72135.54412,
    "out:Klimatpaverkan":0.001* -42876.84332,
    "out:Initial Cost/MSEK": 5.055375,
    "out:Running cost/(Apt SEK y)": 6277.72,
    "out:Running Cost over RSP/MSEK": 1.21829,
    "out:LCP/MSEK": 0.487175,
    "out:ROI% old": 15.662396,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 307.5,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2923.945658,
    "out:Return %": 14,
    "out:Return/kSEK/y": 708,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 31.473086,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 586.873843,
    "out:PV (% sold (El))": 3.141849,
    "out:PV (kWh self-consumed)": 18092.37711,
    "out:PV (ratio sold/self-consumed)": 0.032438,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 302575.7149,
    "out:EL kWh savings": -45562.15955,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198636.49,
    "out:DH kr savings": 275343.9006,
    "out:El kr savings": -77455.67124,
    "out:El kr sold": 748.2641498,
    "out:El kr saved": 30757.04109,
    "out:El kr return": 31505.30524,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 5134.099109,
    "out:% savings (space heating)": 97.639115,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 1.49,
    "out:Etvv": 0,
    "out:Ef": 38.01,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5055375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.002239,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.099154,
    "out:Electricity (inc PV)": 35.581417,
    "out:Total Energy Use Pre PV": 56.099154,
    "out:Total Energy Use Post PV": 39.581417,
    "out:Primary Energy": 65.756659,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.052206,
    "out:CO2 Operational/m2": -16.82864,
    "out:Total CO2/m2": 22.223566,
    "out:Total CO2 (tons)": 46425.04213,
    "out:Klimatpaverkan":0.001* -68587.34531,
    "out:Initial Cost/MSEK": 5.237,
    "out:Running cost/(Apt SEK y)": 5906.28,
    "out:Running Cost over RSP/MSEK": 1.15058,
    "out:LCP/MSEK": 0.37326,
    "out:ROI% old": 15.303909,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 307.5,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2752.778045,
    "out:Return %": 14,
    "out:Return/kSEK/y": 717,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 33.599654,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 9847.750626,
    "out:PV (% sold (El))": 26.360133,
    "out:PV (kWh self-consumed)": 27510.75129,
    "out:PV (ratio sold/self-consumed)": 0.35796,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 299349.1344,
    "out:EL kWh savings": -40487.79124,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 216134.35,
    "out:DH kr savings": 272407.7123,
    "out:El kr savings": -68829.2451,
    "out:El kr sold": 12555.88205,
    "out:El kr saved": 46768.27719,
    "out:El kr return": 59324.15923,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 8360.679568,
    "out:% savings (space heating)": 96.155391,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.44,
    "out:Etvv": 0,
    "out:Ef": 35.58,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5237000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.964272,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.389983,
    "out:Electricity (inc PV)": 34.907797,
    "out:Total Energy Use Pre PV": 54.389983,
    "out:Total Energy Use Post PV": 37.907797,
    "out:Primary Energy": 64.024287,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.052206,
    "out:CO2 Operational/m2": -17.791159,
    "out:Total CO2/m2": 21.261047,
    "out:Total CO2 (tons)": 44414.33939,
    "out:Klimatpaverkan":0.001* -70598.04806,
    "out:Initial Cost/MSEK": 5.28925,
    "out:Running cost/(Apt SEK y)": 5589.56,
    "out:Running Cost over RSP/MSEK": 1.08884,
    "out:LCP/MSEK": 0.38275,
    "out:ROI% old": 15.319482,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 328.9,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2709.796068,
    "out:Return %": 14,
    "out:Return/kSEK/y": 725,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 32.890483,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 10062.62368,
    "out:PV (% sold (El))": 26.935298,
    "out:PV (kWh self-consumed)": 27295.87823,
    "out:PV (ratio sold/self-consumed)": 0.36865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 301517.4481,
    "out:EL kWh savings": -39080.59867,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220773.71,
    "out:DH kr savings": 274380.8778,
    "out:El kr savings": -66437.01774,
    "out:El kr sold": 12829.8452,
    "out:El kr saved": 46402.99299,
    "out:El kr return": 59232.83818,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 6192.365909,
    "out:% savings (space heating)": 97.152477,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 1.7,
    "out:Etvv": 0,
    "out:Ef": 34.91,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5289250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.374741,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.718396,
    "out:Electricity (inc PV)": 34.879443,
    "out:Total Energy Use Pre PV": 53.718396,
    "out:Total Energy Use Post PV": 37.879443,
    "out:Primary Energy": 64.315866,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.502182,
    "out:CO2 Operational/m2": -18.369893,
    "out:Total CO2/m2": 21.132289,
    "out:Total CO2 (tons)": 44145.36385,
    "out:Klimatpaverkan":0.001* -70867.02359,
    "out:Initial Cost/MSEK": 5.350875,
    "out:Running cost/(Apt SEK y)": 5497.68,
    "out:Running Cost over RSP/MSEK": 1.07107,
    "out:LCP/MSEK": 0.338895,
    "out:ROI% old": 15.190493,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 328.9,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2669.188749,
    "out:Return %": 14,
    "out:Return/kSEK/y": 727,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.218896,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 10269.39308,
    "out:PV (% sold (El))": 27.488771,
    "out:PV (kWh self-consumed)": 27089.10883,
    "out:PV (ratio sold/self-consumed)": 0.379097,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 300659.9781,
    "out:EL kWh savings": -39021.36715,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220357.73,
    "out:DH kr savings": 273600.5801,
    "out:El kr savings": -66336.32415,
    "out:El kr sold": 13093.47618,
    "out:El kr saved": 46051.48501,
    "out:El kr return": 59144.96119,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 7049.835886,
    "out:% savings (space heating)": 96.758174,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.19,
    "out:Etvv": 0,
    "out:Ef": 34.88,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5350875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.457682,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 49.972586,
    "out:Electricity (inc PV)": 34.196132,
    "out:Total Energy Use Pre PV": 51.972586,
    "out:Total Energy Use Post PV": 36.196132,
    "out:Primary Energy": 62.59883,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.502182,
    "out:CO2 Operational/m2": -19.382108,
    "out:Total CO2/m2": 20.120074,
    "out:Total CO2 (tons)": 42030.84614,
    "out:Klimatpaverkan":0.001* -72981.54131,
    "out:Initial Cost/MSEK": 5.403,
    "out:Running cost/(Apt SEK y)": 5176.12,
    "out:Running Cost over RSP/MSEK": 1.00838,
    "out:LCP/MSEK": 0.34946,
    "out:ROI% old": 15.209698,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 352.8,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2624.209256,
    "out:Return %": 14,
    "out:Return/kSEK/y": 735,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1183200,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 59160,
    "out:EAHP (replacement cost)": 885636.4074,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 31.473086,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 10502.92031,
    "out:PV (% sold (El))": 28.113869,
    "out:PV (kWh self-consumed)": 26855.58161,
    "out:PV (ratio sold/self-consumed)": 0.391089,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 302575.7149,
    "out:EL kWh savings": -37593.93008,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 224825.44,
    "out:DH kr savings": 275343.9006,
    "out:El kr savings": -63909.68113,
    "out:El kr sold": 13391.22339,
    "out:El kr saved": 45654.48873,
    "out:El kr return": 59045.71212,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 5134.099109,
    "out:% savings (space heating)": 97.639115,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 1.49,
    "out:Etvv": 0,
    "out:Ef": 34.2,
    "out:heatPumpQuantity": 8,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5403000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.798772,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 139.799,
    "out:Total Energy Use Post PV": 139.799,
    "out:Primary Energy": 107.99734,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.525399,
    "out:CO2 Operational/m2": 45.58373,
    "out:Total CO2/m2": 47.109129,
    "out:Total CO2 (tons)": 98410.99752,
    "out:Klimatpaverkan":0.001* -16601.38992,
    "out:Initial Cost/MSEK": 0.462793397,
    "out:Running cost/(Apt SEK y)": 29088.44,
    "out:Running Cost over RSP/MSEK": 5.68404,
    "out:LCP/MSEK": 0.614007,
    "out:ROI% old": 33.239146,
    "out:Payback discounted": 4,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 16.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 30,
    "out:Return/kSEK/y": 137,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 59121.31117,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 37467.96,
    "out:DH kr savings": 53800.39316,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 158343.6784,
    "out:% savings (space heating)": 27.186588,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.8,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 462793.3975,
    "out:Seasonal Variation ROI (%)": -4,
    "out:Seasonal Variation Payback": -999
  },
  {
    "ID": "Z00000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.339681,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 135.799,
    "out:Total Energy Use Post PV": 135.799,
    "out:Primary Energy": 104.875977,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.525399,
    "out:CO2 Operational/m2": 44.143794,
    "out:Total CO2/m2": 45.669193,
    "out:Total CO2 (tons)": 95402.97039,
    "out:Klimatpaverkan":0.001* -19609.41705,
    "out:Initial Cost/MSEK": 0.51501841,
    "out:Running cost/(Apt SEK y)": 28209.96,
    "out:Running Cost over RSP/MSEK": 5.51217,
    "out:LCP/MSEK": 0.733652,
    "out:ROI% old": 34.635932,
    "out:Payback discounted": 4,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 19.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 31,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 68436.35598,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 45944.65,
    "out:DH kr savings": 62277.08394,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 149028.6336,
    "out:% savings (space heating)": 31.470057,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.34,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 515018.41,
    "out:Seasonal Variation ROI (%)": -2,
    "out:Seasonal Variation Payback": -999
  },
  {
    "ID": "Z00000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.798772,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 133.799,
    "out:Total Energy Use Post PV": 133.799,
    "out:Primary Energy": 105.37234,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.975375,
    "out:CO2 Operational/m2": 43.423826,
    "out:Total CO2/m2": 45.399201,
    "out:Total CO2 (tons)": 94838.95695,
    "out:Klimatpaverkan":0.001* -20173.4305,
    "out:Initial Cost/MSEK": 0.576633397,
    "out:Running cost/(Apt SEK y)": 27770.76,
    "out:Running Cost over RSP/MSEK": 5.42624,
    "out:LCP/MSEK": 0.757967,
    "out:ROI% old": 33.06384,
    "out:Payback discounted": 4,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 21.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 30,
    "out:Return/kSEK/y": 170,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 72658.03489,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 49786.38,
    "out:DH kr savings": 66118.81175,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 158343.6784,
    "out:% savings (space heating)": 27.186588,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.8,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 576633.3975,
    "out:Seasonal Variation ROI (%)": -1,
    "out:Seasonal Variation Payback": -999
  },
  {
    "ID": "Z00000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.339681,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 128.799,
    "out:Total Energy Use Post PV": 128.799,
    "out:Primary Energy": 102.250977,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.975375,
    "out:CO2 Operational/m2": 41.623906,
    "out:Total CO2/m2": 43.599281,
    "out:Total CO2 (tons)": 91078.92304,
    "out:Klimatpaverkan":0.001* -23933.46441,
    "out:Initial Cost/MSEK": 0.62885841,
    "out:Running cost/(Apt SEK y)": 26672.68,
    "out:Running Cost over RSP/MSEK": 5.21141,
    "out:LCP/MSEK": 0.920572,
    "out:ROI% old": 35.198248,
    "out:Payback discounted": 4,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 31,
    "out:Return/kSEK/y": 198,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 81973.0797,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 58263.07,
    "out:DH kr savings": 74595.50252,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 149028.6336,
    "out:% savings (space heating)": 31.470057,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.34,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 628858.41,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.798772,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 139.799,
    "out:Total Energy Use Post PV": 134.046908,
    "out:Primary Energy": 97.643574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.408009,
    "out:CO2 Operational/m2": 28.860726,
    "out:Total CO2/m2": 40.268735,
    "out:Total CO2 (tons)": 84121.41053,
    "out:Klimatpaverkan":0.001* -30890.97692,
    "out:Initial Cost/MSEK": 0.810421258,
    "out:Running cost/(Apt SEK y)": 27931.52,
    "out:Running Cost over RSP/MSEK": 5.46386,
    "out:LCP/MSEK": 0.486559,
    "out:ROI% old": 22.862537,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 21.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 21,
    "out:Return/kSEK/y": 166,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 59121.31117,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 66390.86,
    "out:DH kr savings": 53800.39316,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 158343.6784,
    "out:% savings (space heating)": 27.186588,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.8,
    "out:Etvv": 25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 810421.2581,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.339681,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 135.799,
    "out:Total Energy Use Post PV": 130.046908,
    "out:Primary Energy": 94.522211,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.408009,
    "out:CO2 Operational/m2": 27.42079,
    "out:Total CO2/m2": 38.828799,
    "out:Total CO2 (tons)": 81113.3834,
    "out:Klimatpaverkan":0.001* -33899.00405,
    "out:Initial Cost/MSEK": 0.862646271,
    "out:Running cost/(Apt SEK y)": 27053.04,
    "out:Running Cost over RSP/MSEK": 5.29199,
    "out:LCP/MSEK": 0.606204,
    "out:ROI% old": 24.324653,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 25.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 22,
    "out:Return/kSEK/y": 188,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 68436.35598,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 74867.55,
    "out:DH kr savings": 62277.08394,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 149028.6336,
    "out:% savings (space heating)": 31.470057,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.34,
    "out:Etvv": 25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 862646.2706,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.798772,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 133.799,
    "out:Total Energy Use Post PV": 128.046908,
    "out:Primary Energy": 95.018574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.857985,
    "out:CO2 Operational/m2": 26.700822,
    "out:Total CO2/m2": 38.558807,
    "out:Total CO2 (tons)": 80549.36996,
    "out:Klimatpaverkan":0.001* -34463.01749,
    "out:Initial Cost/MSEK": 0.924261258,
    "out:Running cost/(Apt SEK y)": 26613.84,
    "out:Running Cost over RSP/MSEK": 5.20606,
    "out:LCP/MSEK": 0.630519,
    "out:ROI% old": 24.031239,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 27.3,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 22,
    "out:Return/kSEK/y": 199,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 72658.03489,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 78709.28,
    "out:DH kr savings": 66118.81175,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 158343.6784,
    "out:% savings (space heating)": 27.186588,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.8,
    "out:Etvv": 21.25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 924261.2581,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z00000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.339681,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 128.799,
    "out:Total Energy Use Post PV": 123.046908,
    "out:Primary Energy": 91.897211,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.857985,
    "out:CO2 Operational/m2": 24.900902,
    "out:Total CO2/m2": 36.758887,
    "out:Total CO2 (tons)": 76789.33604,
    "out:Klimatpaverkan":0.001* -38223.0514,
    "out:Initial Cost/MSEK": 0.976486271,
    "out:Running cost/(Apt SEK y)": 25515.76,
    "out:Running Cost over RSP/MSEK": 4.99122,
    "out:LCP/MSEK": 0.793134,
    "out:ROI% old": 25.889033,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 32.5,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 23,
    "out:Return/kSEK/y": 227,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 81973.0797,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 87185.97,
    "out:DH kr savings": 74595.50252,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 149028.6336,
    "out:% savings (space heating)": 31.470057,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.34,
    "out:Etvv": 21.25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 976486.2706,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z00000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.798772,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 139.799,
    "out:Total Energy Use Post PV": 132.669833,
    "out:Primary Energy": 95.164839,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.290619,
    "out:CO2 Operational/m2": -9.748936,
    "out:Total CO2/m2": 11.541683,
    "out:Total CO2 (tons)": 24110.58241,
    "out:Klimatpaverkan":0.001* -90901.80503,
    "out:Initial Cost/MSEK": 1.158049101,
    "out:Running cost/(Apt SEK y)": 26929.96,
    "out:Running Cost over RSP/MSEK": 5.27339,
    "out:LCP/MSEK": 0.329401,
    "out:ROI% old": 18.34921,
    "out:Payback discounted": 7,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 17,
    "out:Return/kSEK/y": 191,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 59121.31117,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91429.5,
    "out:DH kr savings": 53800.39316,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 158343.6784,
    "out:% savings (space heating)": 27.186588,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.8,
    "out:Etvv": 25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1158049.101,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z00000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.339681,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 135.799,
    "out:Total Energy Use Post PV": 128.669833,
    "out:Primary Energy": 92.043476,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.290619,
    "out:CO2 Operational/m2": -11.188872,
    "out:Total CO2/m2": 10.101747,
    "out:Total CO2 (tons)": 21102.55528,
    "out:Klimatpaverkan":0.001* -93909.83216,
    "out:Initial Cost/MSEK": 1.210274114,
    "out:Running cost/(Apt SEK y)": 26051.52,
    "out:Running Cost over RSP/MSEK": 5.10152,
    "out:LCP/MSEK": 0.449046,
    "out:ROI% old": 19.586118,
    "out:Payback discounted": 6,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 18,
    "out:Return/kSEK/y": 213,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 68436.35598,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99906.2,
    "out:DH kr savings": 62277.08394,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 149028.6336,
    "out:% savings (space heating)": 31.470057,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.34,
    "out:Etvv": 25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1210274.114,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.798772,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 133.799,
    "out:Total Energy Use Post PV": 126.669833,
    "out:Primary Energy": 92.539839,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.740595,
    "out:CO2 Operational/m2": -11.90884,
    "out:Total CO2/m2": 9.831755,
    "out:Total CO2 (tons)": 20538.54184,
    "out:Klimatpaverkan":0.001* -94473.84561,
    "out:Initial Cost/MSEK": 1.271875,
    "out:Running cost/(Apt SEK y)": 25612.28,
    "out:Running Cost over RSP/MSEK": 5.01559,
    "out:LCP/MSEK": 0.473375,
    "out:ROI% old": 19.602668,
    "out:Payback discounted": 6,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 28.3,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 18,
    "out:Return/kSEK/y": 224,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 72658.03489,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 103747.92,
    "out:DH kr savings": 66118.81175,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 158343.6784,
    "out:% savings (space heating)": 27.186588,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.8,
    "out:Etvv": 21.25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1271875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.339681,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 128.799,
    "out:Total Energy Use Post PV": 121.669833,
    "out:Primary Energy": 89.418476,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.740595,
    "out:CO2 Operational/m2": -13.70876,
    "out:Total CO2/m2": 8.031835,
    "out:Total CO2 (tons)": 16778.50793,
    "out:Klimatpaverkan":0.001* -98233.87952,
    "out:Initial Cost/MSEK": 1.324125,
    "out:Running cost/(Apt SEK y)": 24514.24,
    "out:Running Cost over RSP/MSEK": 4.80076,
    "out:LCP/MSEK": 0.635955,
    "out:ROI% old": 21.146903,
    "out:Payback discounted": 6,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 19,
    "out:Return/kSEK/y": 252,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 81973.0797,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112224.61,
    "out:DH kr savings": 74595.50252,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 149028.6336,
    "out:% savings (space heating)": 31.470057,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.34,
    "out:Etvv": 21.25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1324125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.798772,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 130.799,
    "out:Total Energy Use Post PV": 130.799,
    "out:Primary Energy": 104.49734,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.525399,
    "out:CO2 Operational/m2": 42.343874,
    "out:Total CO2/m2": 43.869273,
    "out:Total CO2 (tons)": 91642.93648,
    "out:Klimatpaverkan":0.001* -23369.45097,
    "out:Initial Cost/MSEK": 0.52877621,
    "out:Running cost/(Apt SEK y)": 27111.92,
    "out:Running Cost over RSP/MSEK": 5.29734,
    "out:LCP/MSEK": 0.934724,
    "out:ROI% old": 39.538736,
    "out:Payback discounted": 3,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 35,
    "out:Return/kSEK/y": 187,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 77170.27613,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 53892.52,
    "out:DH kr savings": 70224.95128,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 158343.6784,
    "out:% savings (space heating)": 27.186588,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.8,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 528776.21,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999
  },
  {
    "ID": "Z00000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.339681,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 126.799,
    "out:Total Energy Use Post PV": 126.799,
    "out:Primary Energy": 101.375977,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.525399,
    "out:CO2 Operational/m2": 40.903938,
    "out:Total CO2/m2": 42.429337,
    "out:Total CO2 (tons)": 88634.90935,
    "out:Klimatpaverkan":0.001* -26377.4781,
    "out:Initial Cost/MSEK": 0.581001222,
    "out:Running cost/(Apt SEK y)": 26233.48,
    "out:Running Cost over RSP/MSEK": 5.12548,
    "out:LCP/MSEK": 1.054359,
    "out:ROI% old": 40.21039,
    "out:Payback discounted": 3,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 28.3,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 36,
    "out:Return/kSEK/y": 209,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 86485.32094,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 62369.21,
    "out:DH kr savings": 78701.64205,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 149028.6336,
    "out:% savings (space heating)": 31.470057,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.34,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 581001.2225,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.798772,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 125.799,
    "out:Total Energy Use Post PV": 125.799,
    "out:Primary Energy": 102.39734,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.975375,
    "out:CO2 Operational/m2": 40.543954,
    "out:Total CO2/m2": 42.519329,
    "out:Total CO2 (tons)": 88822.90269,
    "out:Klimatpaverkan":0.001* -26189.48476,
    "out:Initial Cost/MSEK": 0.64261621,
    "out:Running cost/(Apt SEK y)": 26013.84,
    "out:Running Cost over RSP/MSEK": 5.08251,
    "out:LCP/MSEK": 1.035714,
    "out:ROI% old": 37.310206,
    "out:Payback discounted": 4,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 29.4,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 33,
    "out:Return/kSEK/y": 214,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 87999.6551,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 63747.25,
    "out:DH kr savings": 80079.68614,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 158343.6784,
    "out:% savings (space heating)": 27.186588,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.8,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 642616.21,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.339681,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 121.799,
    "out:Total Energy Use Post PV": 121.799,
    "out:Primary Energy": 99.275977,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 1.975375,
    "out:CO2 Operational/m2": 39.104018,
    "out:Total CO2/m2": 41.079393,
    "out:Total CO2 (tons)": 85814.87556,
    "out:Klimatpaverkan":0.001* -29197.51189,
    "out:Initial Cost/MSEK": 0.694841222,
    "out:Running cost/(Apt SEK y)": 25135.4,
    "out:Running Cost over RSP/MSEK": 4.91064,
    "out:LCP/MSEK": 1.155359,
    "out:ROI% old": 38.039523,
    "out:Payback discounted": 3,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 34,
    "out:Return/kSEK/y": 236,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 97314.69991,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 72223.94,
    "out:DH kr savings": 88556.37692,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 149028.6336,
    "out:% savings (space heating)": 31.470057,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.34,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 694841.2225,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z00000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.798772,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 130.799,
    "out:Total Energy Use Post PV": 125.046908,
    "out:Primary Energy": 94.143574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.408009,
    "out:CO2 Operational/m2": 25.62087,
    "out:Total CO2/m2": 37.028879,
    "out:Total CO2 (tons)": 77353.34949,
    "out:Klimatpaverkan":0.001* -37659.03796,
    "out:Initial Cost/MSEK": 0.876404071,
    "out:Running cost/(Apt SEK y)": 25955,
    "out:Running Cost over RSP/MSEK": 5.07716,
    "out:LCP/MSEK": 0.807276,
    "out:ROI% old": 27.444614,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 30.4,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 25,
    "out:Return/kSEK/y": 216,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 77170.27613,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82815.42,
    "out:DH kr savings": 70224.95128,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 158343.6784,
    "out:% savings (space heating)": 27.186588,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.8,
    "out:Etvv": 20,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 876404.0706,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z00000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.339681,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 126.799,
    "out:Total Energy Use Post PV": 121.046908,
    "out:Primary Energy": 91.022211,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.408009,
    "out:CO2 Operational/m2": 24.180934,
    "out:Total CO2/m2": 35.588943,
    "out:Total CO2 (tons)": 74345.32236,
    "out:Klimatpaverkan":0.001* -40667.06509,
    "out:Initial Cost/MSEK": 0.928629083,
    "out:Running cost/(Apt SEK y)": 25076.56,
    "out:Running Cost over RSP/MSEK": 4.90529,
    "out:LCP/MSEK": 0.926921,
    "out:ROI% old": 28.545151,
    "out:Payback discounted": 4,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 26,
    "out:Return/kSEK/y": 238,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 86485.32094,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 91292.11,
    "out:DH kr savings": 78701.64205,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 149028.6336,
    "out:% savings (space heating)": 31.470057,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.34,
    "out:Etvv": 20,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 928629.0831,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.798772,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 125.799,
    "out:Total Energy Use Post PV": 120.046908,
    "out:Primary Energy": 92.043574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.857985,
    "out:CO2 Operational/m2": 23.82095,
    "out:Total CO2/m2": 35.678935,
    "out:Total CO2 (tons)": 74533.3157,
    "out:Klimatpaverkan":0.001* -40479.07175,
    "out:Initial Cost/MSEK": 0.990244071,
    "out:Running cost/(Apt SEK y)": 24856.92,
    "out:Running Cost over RSP/MSEK": 4.86232,
    "out:LCP/MSEK": 0.908276,
    "out:ROI% old": 27.388919,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 35.8,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 25,
    "out:Return/kSEK/y": 243,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 87999.6551,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 92670.15,
    "out:DH kr savings": 80079.68614,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 158343.6784,
    "out:% savings (space heating)": 27.186588,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.8,
    "out:Etvv": 17,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 990244.0706,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.339681,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 121.799,
    "out:Total Energy Use Post PV": 116.046908,
    "out:Primary Energy": 88.922211,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.857985,
    "out:CO2 Operational/m2": 22.381014,
    "out:Total CO2/m2": 34.238999,
    "out:Total CO2 (tons)": 71525.28856,
    "out:Klimatpaverkan":0.001* -43487.09888,
    "out:Initial Cost/MSEK": 1.042469083,
    "out:Running cost/(Apt SEK y)": 23978.48,
    "out:Running Cost over RSP/MSEK": 4.69046,
    "out:LCP/MSEK": 1.027911,
    "out:ROI% old": 28.371927,
    "out:Payback discounted": 4,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 40.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 25,
    "out:Return/kSEK/y": 265,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 97314.69991,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 101146.84,
    "out:DH kr savings": 88556.37692,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 149028.6336,
    "out:% savings (space heating)": 31.470057,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.34,
    "out:Etvv": 17,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1042469.083,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.798772,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 130.799,
    "out:Total Energy Use Post PV": 123.669833,
    "out:Primary Energy": 91.664839,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.290619,
    "out:CO2 Operational/m2": -12.988792,
    "out:Total CO2/m2": 8.301827,
    "out:Total CO2 (tons)": 17342.52137,
    "out:Klimatpaverkan":0.001* -97669.86608,
    "out:Initial Cost/MSEK": 1.224031914,
    "out:Running cost/(Apt SEK y)": 24953.44,
    "out:Running Cost over RSP/MSEK": 4.88669,
    "out:LCP/MSEK": 0.650118,
    "out:ROI% old": 21.873263,
    "out:Payback discounted": 6,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 20,
    "out:Return/kSEK/y": 241,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 77170.27613,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 107854.06,
    "out:DH kr savings": 70224.95128,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 158343.6784,
    "out:% savings (space heating)": 27.186588,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.8,
    "out:Etvv": 20,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1224031.914,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.339681,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 126.799,
    "out:Total Energy Use Post PV": 119.669833,
    "out:Primary Energy": 88.543476,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.290619,
    "out:CO2 Operational/m2": -14.428728,
    "out:Total CO2/m2": 6.861891,
    "out:Total CO2 (tons)": 14334.49424,
    "out:Klimatpaverkan":0.001* -100677.8932,
    "out:Initial Cost/MSEK": 1.27625,
    "out:Running cost/(Apt SEK y)": 24075,
    "out:Running Cost over RSP/MSEK": 4.71483,
    "out:LCP/MSEK": 0.76976,
    "out:ROI% old": 22.902029,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 35.8,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 21,
    "out:Return/kSEK/y": 263,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 86485.32094,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116330.75,
    "out:DH kr savings": 78701.64205,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 149028.6336,
    "out:% savings (space heating)": 31.470057,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.34,
    "out:Etvv": 20,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1276250,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.798772,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 125.799,
    "out:Total Energy Use Post PV": 118.669833,
    "out:Primary Energy": 89.564839,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.740595,
    "out:CO2 Operational/m2": -14.788712,
    "out:Total CO2/m2": 6.951883,
    "out:Total CO2 (tons)": 14522.48758,
    "out:Klimatpaverkan":0.001* -100489.8999,
    "out:Initial Cost/MSEK": 1.337875,
    "out:Running cost/(Apt SEK y)": 23855.4,
    "out:Running Cost over RSP/MSEK": 4.67186,
    "out:LCP/MSEK": 0.751105,
    "out:ROI% old": 22.305949,
    "out:Payback discounted": 6,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 37,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 20,
    "out:Return/kSEK/y": 268,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 87999.6551,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117708.8,
    "out:DH kr savings": 80079.68614,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 158343.6784,
    "out:% savings (space heating)": 27.186588,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.8,
    "out:Etvv": 17,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1337875,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.339681,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 121.799,
    "out:Total Energy Use Post PV": 114.669833,
    "out:Primary Energy": 86.443476,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.740595,
    "out:CO2 Operational/m2": -16.228648,
    "out:Total CO2/m2": 5.511947,
    "out:Total CO2 (tons)": 11514.46045,
    "out:Klimatpaverkan":0.001* -103497.927,
    "out:Initial Cost/MSEK": 1.390125,
    "out:Running cost/(Apt SEK y)": 22976.92,
    "out:Running Cost over RSP/MSEK": 4.49999,
    "out:LCP/MSEK": 0.870725,
    "out:ROI% old": 23.233779,
    "out:Payback discounted": 5,
    "out:Atemp": 2089,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 21,
    "out:Return/kSEK/y": 290,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 97314.69991,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126185.49,
    "out:DH kr savings": 88556.37692,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 149028.6336,
    "out:% savings (space heating)": 31.470057,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.34,
    "out:Etvv": 17,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1390125,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z01000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.194577,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 122.799,
    "out:Total Energy Use Post PV": 122.799,
    "out:Primary Energy": 96.374404,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.958672,
    "out:CO2 Operational/m2": 39.464002,
    "out:Total CO2/m2": 52.422674,
    "out:Total CO2 (tons)": 109510.9961,
    "out:Klimatpaverkan":0.001* -5501.391367,
    "out:Initial Cost/MSEK": 2.18875,
    "out:Running cost/(Apt SEK y)": 25355,
    "out:Running Cost over RSP/MSEK": 4.95361,
    "out:LCP/MSEK": -0.38152,
    "out:ROI% old": 11.795578,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 32.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 11,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 93807.48468,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69032.38,
    "out:DH kr savings": 85364.81106,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 123657.5049,
    "out:% savings (space heating)": 43.136822,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 59.19,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2188750,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.490221,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 119.799,
    "out:Total Energy Use Post PV": 119.799,
    "out:Primary Energy": 93.781355,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.958672,
    "out:CO2 Operational/m2": 38.38405,
    "out:Total CO2/m2": 51.342722,
    "out:Total CO2 (tons)": 107254.9757,
    "out:Klimatpaverkan":0.001* -7757.411715,
    "out:Initial Cost/MSEK": 2.241,
    "out:Running cost/(Apt SEK y)": 24696.16,
    "out:Running Cost over RSP/MSEK": 4.82471,
    "out:LCP/MSEK": -0.30487,
    "out:ROI% old": 12.342258,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 35.8,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 11,
    "out:Return/kSEK/y": 247,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 101545.8852,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 76074.32,
    "out:DH kr savings": 92406.75557,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 115919.1044,
    "out:% savings (space heating)": 46.69528,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.49,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2241000,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.194577,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 116.799,
    "out:Total Energy Use Post PV": 116.799,
    "out:Primary Energy": 93.749404,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.408648,
    "out:CO2 Operational/m2": 37.304098,
    "out:Total CO2/m2": 50.712746,
    "out:Total CO2 (tons)": 105938.9555,
    "out:Klimatpaverkan":0.001* -9073.431941,
    "out:Initial Cost/MSEK": 2.302625,
    "out:Running cost/(Apt SEK y)": 24037.32,
    "out:Running Cost over RSP/MSEK": 4.69581,
    "out:LCP/MSEK": -0.237595,
    "out:ROI% old": 12.811651,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 11,
    "out:Return/kSEK/y": 264,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 107344.2084,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 81350.8,
    "out:DH kr savings": 97683.22964,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 123657.5049,
    "out:% savings (space heating)": 43.136822,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 59.19,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2302625,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.490221,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 112.799,
    "out:Total Energy Use Post PV": 112.799,
    "out:Primary Energy": 91.156355,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.408648,
    "out:CO2 Operational/m2": 35.864162,
    "out:Total CO2/m2": 49.27281,
    "out:Total CO2 (tons)": 102930.9284,
    "out:Klimatpaverkan":0.001* -12081.45907,
    "out:Initial Cost/MSEK": 2.354875,
    "out:Running cost/(Apt SEK y)": 23158.88,
    "out:Running Cost over RSP/MSEK": 4.52394,
    "out:LCP/MSEK": -0.117975,
    "out:ROI% old": 13.570026,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 44.2,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 286,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 115082.609,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88392.74,
    "out:DH kr savings": 104725.1742,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 115919.1044,
    "out:% savings (space heating)": 46.69528,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.49,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2354875,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.194577,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 122.799,
    "out:Total Energy Use Post PV": 117.046908,
    "out:Primary Energy": 86.020638,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.841282,
    "out:CO2 Operational/m2": 22.740998,
    "out:Total CO2/m2": 45.58228,
    "out:Total CO2 (tons)": 95221.40908,
    "out:Klimatpaverkan":0.001* -19790.97836,
    "out:Initial Cost/MSEK": 2.536375,
    "out:Running cost/(Apt SEK y)": 24198.08,
    "out:Running Cost over RSP/MSEK": 4.73343,
    "out:LCP/MSEK": -0.508965,
    "out:ROI% old": 11.419053,
    "out:Payback discounted": 11,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 10,
    "out:Return/kSEK/y": 260,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 93807.48468,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 97955.28,
    "out:DH kr savings": 85364.81106,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 123657.5049,
    "out:% savings (space heating)": 43.136822,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 59.19,
    "out:Etvv": 25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2536375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.490221,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 119.799,
    "out:Total Energy Use Post PV": 114.046908,
    "out:Primary Energy": 83.427589,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.841282,
    "out:CO2 Operational/m2": 21.661046,
    "out:Total CO2/m2": 44.502328,
    "out:Total CO2 (tons)": 92965.38874,
    "out:Klimatpaverkan":0.001* -22046.99871,
    "out:Initial Cost/MSEK": 2.588625,
    "out:Running cost/(Apt SEK y)": 23539.24,
    "out:Running Cost over RSP/MSEK": 4.60452,
    "out:LCP/MSEK": -0.432305,
    "out:ROI% old": 11.899974,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 43,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 11,
    "out:Return/kSEK/y": 276,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 101545.8852,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104997.22,
    "out:DH kr savings": 92406.75557,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 115919.1044,
    "out:% savings (space heating)": 46.69528,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.49,
    "out:Etvv": 25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2588625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.194577,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 116.799,
    "out:Total Energy Use Post PV": 111.046908,
    "out:Primary Energy": 83.395638,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.291258,
    "out:CO2 Operational/m2": 20.581094,
    "out:Total CO2/m2": 43.872352,
    "out:Total CO2 (tons)": 91649.36851,
    "out:Klimatpaverkan":0.001* -23363.01893,
    "out:Initial Cost/MSEK": 2.65025,
    "out:Running cost/(Apt SEK y)": 22880.4,
    "out:Running Cost over RSP/MSEK": 4.47562,
    "out:LCP/MSEK": -0.36503,
    "out:ROI% old": 12.318083,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 46.8,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 11,
    "out:Return/kSEK/y": 293,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 107344.2084,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110273.7,
    "out:DH kr savings": 97683.22964,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 123657.5049,
    "out:% savings (space heating)": 43.136822,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 59.19,
    "out:Etvv": 21.25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2650250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.490221,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 112.799,
    "out:Total Energy Use Post PV": 107.046908,
    "out:Primary Energy": 80.802589,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.291258,
    "out:CO2 Operational/m2": 19.141158,
    "out:Total CO2/m2": 42.432416,
    "out:Total CO2 (tons)": 88641.34138,
    "out:Klimatpaverkan":0.001* -26371.04606,
    "out:Initial Cost/MSEK": 2.7025,
    "out:Running cost/(Apt SEK y)": 22001.96,
    "out:Running Cost over RSP/MSEK": 4.30376,
    "out:LCP/MSEK": -0.24542,
    "out:ROI% old": 12.988397,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 52.3,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 12,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 115082.609,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117315.64,
    "out:DH kr savings": 104725.1742,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 115919.1044,
    "out:% savings (space heating)": 46.69528,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.49,
    "out:Etvv": 21.25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2702500,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.194577,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 122.799,
    "out:Total Energy Use Post PV": 115.669833,
    "out:Primary Energy": 83.541903,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.723892,
    "out:CO2 Operational/m2": -15.868664,
    "out:Total CO2/m2": 16.855228,
    "out:Total CO2 (tons)": 35210.58097,
    "out:Klimatpaverkan":0.001* -79801.80648,
    "out:Initial Cost/MSEK": 2.884,
    "out:Running cost/(Apt SEK y)": 23196.56,
    "out:Running Cost over RSP/MSEK": 4.54296,
    "out:LCP/MSEK": -0.66612,
    "out:ROI% old": 10.98613,
    "out:Payback discounted": 11,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 40.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 10,
    "out:Return/kSEK/y": 285,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 93807.48468,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 122993.92,
    "out:DH kr savings": 85364.81106,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 123657.5049,
    "out:% savings (space heating)": 43.136822,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 59.19,
    "out:Etvv": 25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2884000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.490221,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 119.799,
    "out:Total Energy Use Post PV": 112.669833,
    "out:Primary Energy": 80.948854,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.723892,
    "out:CO2 Operational/m2": -16.948616,
    "out:Total CO2/m2": 15.775276,
    "out:Total CO2 (tons)": 32954.56062,
    "out:Klimatpaverkan":0.001* -82057.82683,
    "out:Initial Cost/MSEK": 2.93625,
    "out:Running cost/(Apt SEK y)": 22537.72,
    "out:Running Cost over RSP/MSEK": 4.41406,
    "out:LCP/MSEK": -0.58947,
    "out:ROI% old": 11.41777,
    "out:Payback discounted": 11,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 44.2,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 10,
    "out:Return/kSEK/y": 301,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 101545.8852,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130035.87,
    "out:DH kr savings": 92406.75557,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 115919.1044,
    "out:% savings (space heating)": 46.69528,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.49,
    "out:Etvv": 25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2936250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.194577,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 116.799,
    "out:Total Energy Use Post PV": 109.669833,
    "out:Primary Energy": 80.916903,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.173868,
    "out:CO2 Operational/m2": -18.028568,
    "out:Total CO2/m2": 15.1453,
    "out:Total CO2 (tons)": 31638.54039,
    "out:Klimatpaverkan":0.001* -83373.84705,
    "out:Initial Cost/MSEK": 2.997875,
    "out:Running cost/(Apt SEK y)": 21878.88,
    "out:Running Cost over RSP/MSEK": 4.28516,
    "out:LCP/MSEK": -0.522195,
    "out:ROI% old": 11.797309,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 48.2,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 11,
    "out:Return/kSEK/y": 318,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 107344.2084,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135312.34,
    "out:DH kr savings": 97683.22964,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 123657.5049,
    "out:% savings (space heating)": 43.136822,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 59.19,
    "out:Etvv": 21.25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2997875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.490221,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 112.799,
    "out:Total Energy Use Post PV": 105.669833,
    "out:Primary Energy": 78.323854,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.173868,
    "out:CO2 Operational/m2": -19.468504,
    "out:Total CO2/m2": 13.705364,
    "out:Total CO2 (tons)": 28630.51326,
    "out:Klimatpaverkan":0.001* -86381.87418,
    "out:Initial Cost/MSEK": 3.050125,
    "out:Running cost/(Apt SEK y)": 21000.44,
    "out:Running Cost over RSP/MSEK": 4.1133,
    "out:LCP/MSEK": -0.402585,
    "out:ROI% old": 12.400148,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 11,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 115082.609,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142354.29,
    "out:DH kr savings": 104725.1742,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 115919.1044,
    "out:% savings (space heating)": 46.69528,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.49,
    "out:Etvv": 21.25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3050125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.194577,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 114.799,
    "out:Total Energy Use Post PV": 114.799,
    "out:Primary Energy": 92.874404,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.958672,
    "out:CO2 Operational/m2": 36.58413,
    "out:Total CO2/m2": 49.542802,
    "out:Total CO2 (tons)": 103494.9418,
    "out:Klimatpaverkan":0.001* -11517.44563,
    "out:Initial Cost/MSEK": 2.25475,
    "out:Running cost/(Apt SEK y)": 23598.12,
    "out:Running Cost over RSP/MSEK": 4.60988,
    "out:LCP/MSEK": -0.10379,
    "out:ROI% old": 13.628118,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 275,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 111856.4496,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85456.94,
    "out:DH kr savings": 101789.3692,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 123657.5049,
    "out:% savings (space heating)": 43.136822,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 59.19,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2254750,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.490221,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 110.799,
    "out:Total Energy Use Post PV": 110.799,
    "out:Primary Energy": 90.281355,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.958672,
    "out:CO2 Operational/m2": 35.144194,
    "out:Total CO2/m2": 48.102866,
    "out:Total CO2 (tons)": 100486.9147,
    "out:Klimatpaverkan":0.001* -14525.47276,
    "out:Initial Cost/MSEK": 2.307,
    "out:Running cost/(Apt SEK y)": 22719.68,
    "out:Running Cost over RSP/MSEK": 4.43801,
    "out:LCP/MSEK": 0.01583,
    "out:ROI% old": 14.383739,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 46.8,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 297,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 119594.8502,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 92498.88,
    "out:DH kr savings": 108831.3137,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 115919.1044,
    "out:% savings (space heating)": 46.69528,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.49,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2307000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.194577,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 109.799,
    "out:Total Energy Use Post PV": 109.799,
    "out:Primary Energy": 90.774404,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.408648,
    "out:CO2 Operational/m2": 34.78421,
    "out:Total CO2/m2": 48.192858,
    "out:Total CO2 (tons)": 100674.908,
    "out:Klimatpaverkan":0.001* -14337.47942,
    "out:Initial Cost/MSEK": 2.368625,
    "out:Running cost/(Apt SEK y)": 22500.04,
    "out:Running Cost over RSP/MSEK": 4.39504,
    "out:LCP/MSEK": -0.002825,
    "out:ROI% old": 14.268676,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 48.2,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 302,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122685.8286,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95311.67,
    "out:DH kr savings": 111644.104,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 123657.5049,
    "out:% savings (space heating)": 43.136822,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 59.19,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2368625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.490221,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 105.799,
    "out:Primary Energy": 88.181355,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.408648,
    "out:CO2 Operational/m2": 33.344274,
    "out:Total CO2/m2": 46.752922,
    "out:Total CO2 (tons)": 97666.88089,
    "out:Klimatpaverkan":0.001* -17345.50655,
    "out:Initial Cost/MSEK": 2.420875,
    "out:Running cost/(Apt SEK y)": 21621.6,
    "out:Running Cost over RSP/MSEK": 4.22318,
    "out:LCP/MSEK": 0.116785,
    "out:ROI% old": 14.974869,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 324,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 130424.2292,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102353.62,
    "out:DH kr savings": 118686.0485,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 115919.1044,
    "out:% savings (space heating)": 46.69528,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.49,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2420875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.194577,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 114.799,
    "out:Total Energy Use Post PV": 109.046908,
    "out:Primary Energy": 82.520638,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.841282,
    "out:CO2 Operational/m2": 19.861126,
    "out:Total CO2/m2": 42.702408,
    "out:Total CO2 (tons)": 89205.35482,
    "out:Klimatpaverkan":0.001* -25807.03262,
    "out:Initial Cost/MSEK": 2.602375,
    "out:Running cost/(Apt SEK y)": 22441.2,
    "out:Running Cost over RSP/MSEK": 4.38969,
    "out:LCP/MSEK": -0.231225,
    "out:ROI% old": 13.016407,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 49.5,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 12,
    "out:Return/kSEK/y": 304,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 111856.4496,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114379.83,
    "out:DH kr savings": 101789.3692,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 123657.5049,
    "out:% savings (space heating)": 43.136822,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 59.19,
    "out:Etvv": 20,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2602375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.490221,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 110.799,
    "out:Total Energy Use Post PV": 105.046908,
    "out:Primary Energy": 79.927589,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.841282,
    "out:CO2 Operational/m2": 18.42119,
    "out:Total CO2/m2": 41.262472,
    "out:Total CO2 (tons)": 86197.32769,
    "out:Klimatpaverkan":0.001* -28815.05975,
    "out:Initial Cost/MSEK": 2.654625,
    "out:Running cost/(Apt SEK y)": 21562.76,
    "out:Running Cost over RSP/MSEK": 4.21783,
    "out:LCP/MSEK": -0.111615,
    "out:ROI% old": 13.685064,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 55.2,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 12,
    "out:Return/kSEK/y": 326,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 119594.8502,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121421.78,
    "out:DH kr savings": 108831.3137,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 115919.1044,
    "out:% savings (space heating)": 46.69528,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.49,
    "out:Etvv": 20,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2654625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.194577,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 109.799,
    "out:Total Energy Use Post PV": 104.046908,
    "out:Primary Energy": 80.420638,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.291258,
    "out:CO2 Operational/m2": 18.061206,
    "out:Total CO2/m2": 41.352464,
    "out:Total CO2 (tons)": 86385.32103,
    "out:Klimatpaverkan":0.001* -28627.06641,
    "out:Initial Cost/MSEK": 2.71625,
    "out:Running cost/(Apt SEK y)": 21343.12,
    "out:Running Cost over RSP/MSEK": 4.17486,
    "out:LCP/MSEK": -0.13027,
    "out:ROI% old": 13.600579,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 12,
    "out:Return/kSEK/y": 331,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 122685.8286,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124234.57,
    "out:DH kr savings": 111644.104,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 123657.5049,
    "out:% savings (space heating)": 43.136822,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 59.19,
    "out:Etvv": 17,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2716250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.490221,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 100.046908,
    "out:Primary Energy": 77.827589,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.291258,
    "out:CO2 Operational/m2": 16.62127,
    "out:Total CO2/m2": 39.912528,
    "out:Total CO2 (tons)": 83377.2939,
    "out:Klimatpaverkan":0.001* -31635.09354,
    "out:Initial Cost/MSEK": 2.7685,
    "out:Running cost/(Apt SEK y)": 20464.68,
    "out:Running Cost over RSP/MSEK": 4.00299,
    "out:LCP/MSEK": -0.01065,
    "out:ROI% old": 14.230759,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 13,
    "out:Return/kSEK/y": 353,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 130424.2292,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131276.51,
    "out:DH kr savings": 118686.0485,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 115919.1044,
    "out:% savings (space heating)": 46.69528,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.49,
    "out:Etvv": 17,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2768500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.194577,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 114.799,
    "out:Total Energy Use Post PV": 107.669833,
    "out:Primary Energy": 80.041903,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.723892,
    "out:CO2 Operational/m2": -18.748536,
    "out:Total CO2/m2": 13.975356,
    "out:Total CO2 (tons)": 29194.52671,
    "out:Klimatpaverkan":0.001* -85817.86074,
    "out:Initial Cost/MSEK": 2.95,
    "out:Running cost/(Apt SEK y)": 21439.64,
    "out:Running Cost over RSP/MSEK": 4.19923,
    "out:LCP/MSEK": -0.38839,
    "out:ROI% old": 12.404891,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 50.9,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 11,
    "out:Return/kSEK/y": 329,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 111856.4496,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 139418.48,
    "out:DH kr savings": 101789.3692,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 123657.5049,
    "out:% savings (space heating)": 43.136822,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 59.19,
    "out:Etvv": 20,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2950000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.490221,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 110.799,
    "out:Total Energy Use Post PV": 103.669833,
    "out:Primary Energy": 77.448854,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.723892,
    "out:CO2 Operational/m2": -20.188472,
    "out:Total CO2/m2": 12.53542,
    "out:Total CO2 (tons)": 26186.49958,
    "out:Klimatpaverkan":0.001* -88825.88787,
    "out:Initial Cost/MSEK": 3.00225,
    "out:Running cost/(Apt SEK y)": 20561.2,
    "out:Running Cost over RSP/MSEK": 4.02736,
    "out:LCP/MSEK": -0.26877,
    "out:ROI% old": 13.006816,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 12,
    "out:Return/kSEK/y": 351,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 119594.8502,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146460.42,
    "out:DH kr savings": 108831.3137,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 115919.1044,
    "out:% savings (space heating)": 46.69528,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.49,
    "out:Etvv": 20,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3002250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.194577,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 109.799,
    "out:Total Energy Use Post PV": 102.669833,
    "out:Primary Energy": 77.941903,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.173868,
    "out:CO2 Operational/m2": -20.548456,
    "out:Total CO2/m2": 12.625412,
    "out:Total CO2 (tons)": 26374.49292,
    "out:Klimatpaverkan":0.001* -88637.89453,
    "out:Initial Cost/MSEK": 3.063875,
    "out:Running cost/(Apt SEK y)": 20341.6,
    "out:Running Cost over RSP/MSEK": 3.98439,
    "out:LCP/MSEK": -0.287425,
    "out:ROI% old": 12.945558,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 58.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 12,
    "out:Return/kSEK/y": 356,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 122685.8286,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149273.22,
    "out:DH kr savings": 111644.104,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 123657.5049,
    "out:% savings (space heating)": 43.136822,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 59.19,
    "out:Etvv": 17,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3063875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.490221,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 98.669833,
    "out:Primary Energy": 75.348854,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.173868,
    "out:CO2 Operational/m2": -21.988392,
    "out:Total CO2/m2": 11.185476,
    "out:Total CO2 (tons)": 23366.46578,
    "out:Klimatpaverkan":0.001* -91645.92166,
    "out:Initial Cost/MSEK": 3.116125,
    "out:Running cost/(Apt SEK y)": 19463.12,
    "out:Running Cost over RSP/MSEK": 3.81253,
    "out:LCP/MSEK": -0.167815,
    "out:ROI% old": 13.516375,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 64.6,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 12,
    "out:Return/kSEK/y": 378,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 130424.2292,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156315.16,
    "out:DH kr savings": 118686.0485,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 115919.1044,
    "out:% savings (space heating)": 46.69528,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.49,
    "out:Etvv": 17,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3116125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.019857,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 115.799,
    "out:Total Energy Use Post PV": 115.799,
    "out:Primary Energy": 91.3521,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.561813,
    "out:CO2 Operational/m2": 36.944114,
    "out:Total CO2/m2": 52.505927,
    "out:Total CO2 (tons)": 109684.9116,
    "out:Klimatpaverkan":0.001* -5327.475803,
    "out:Initial Cost/MSEK": 2.58275,
    "out:Running cost/(Apt SEK y)": 23817.72,
    "out:Running Cost over RSP/MSEK": 4.65284,
    "out:LCP/MSEK": -0.47475,
    "out:ROI% old": 11.659776,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 40.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 10,
    "out:Return/kSEK/y": 269,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 108795.4795,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82671.45,
    "out:DH kr savings": 99003.88635,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 108669.5101,
    "out:% savings (space heating)": 50.028963,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.02,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2582750,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z02000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.641625,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 112.799,
    "out:Total Energy Use Post PV": 112.799,
    "out:Primary Energy": 88.987337,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.561813,
    "out:CO2 Operational/m2": 35.864162,
    "out:Total CO2/m2": 51.425975,
    "out:Total CO2 (tons)": 107428.8913,
    "out:Klimatpaverkan":0.001* -7583.49615,
    "out:Initial Cost/MSEK": 2.635,
    "out:Running cost/(Apt SEK y)": 23158.88,
    "out:Running Cost over RSP/MSEK": 4.52394,
    "out:LCP/MSEK": -0.3981,
    "out:ROI% old": 12.127406,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 44.2,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 11,
    "out:Return/kSEK/y": 286,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 115852.6076,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 89093.44,
    "out:DH kr savings": 105425.8729,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101612.382,
    "out:% savings (space heating)": 53.274142,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.64,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2635000,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z02000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.019857,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 109.799,
    "out:Total Energy Use Post PV": 109.799,
    "out:Primary Energy": 88.7271,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.011789,
    "out:CO2 Operational/m2": 34.78421,
    "out:Total CO2/m2": 50.795999,
    "out:Total CO2 (tons)": 106112.8711,
    "out:Klimatpaverkan":0.001* -8899.516376,
    "out:Initial Cost/MSEK": 2.696625,
    "out:Running cost/(Apt SEK y)": 22500.04,
    "out:Running Cost over RSP/MSEK": 4.39504,
    "out:LCP/MSEK": -0.330825,
    "out:ROI% old": 12.533127,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 48.2,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 11,
    "out:Return/kSEK/y": 302,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122332.2032,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 94989.87,
    "out:DH kr savings": 111322.3049,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 108669.5101,
    "out:% savings (space heating)": 50.028963,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.02,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2696625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.641625,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 105.799,
    "out:Primary Energy": 86.362337,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.011789,
    "out:CO2 Operational/m2": 33.344274,
    "out:Total CO2/m2": 49.356063,
    "out:Total CO2 (tons)": 103104.8439,
    "out:Klimatpaverkan":0.001* -11907.54351,
    "out:Initial Cost/MSEK": 2.748875,
    "out:Running cost/(Apt SEK y)": 21621.6,
    "out:Running Cost over RSP/MSEK": 4.22318,
    "out:LCP/MSEK": -0.211215,
    "out:ROI% old": 13.188044,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 324,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 129389.3313,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 101411.86,
    "out:DH kr savings": 117744.2915,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101612.382,
    "out:% savings (space heating)": 53.274142,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.64,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2748875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.019857,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 115.799,
    "out:Total Energy Use Post PV": 110.046908,
    "out:Primary Energy": 80.998334,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.444423,
    "out:CO2 Operational/m2": 20.22111,
    "out:Total CO2/m2": 45.665533,
    "out:Total CO2 (tons)": 95395.32465,
    "out:Klimatpaverkan":0.001* -19617.0628,
    "out:Initial Cost/MSEK": 2.930375,
    "out:Running cost/(Apt SEK y)": 22660.8,
    "out:Running Cost over RSP/MSEK": 4.43266,
    "out:LCP/MSEK": -0.602195,
    "out:ROI% old": 11.349986,
    "out:Payback discounted": 11,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 48.2,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 10,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 108795.4795,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111594.35,
    "out:DH kr savings": 99003.88635,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 108669.5101,
    "out:% savings (space heating)": 50.028963,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.02,
    "out:Etvv": 25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2930375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.641625,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 112.799,
    "out:Total Energy Use Post PV": 107.046908,
    "out:Primary Energy": 78.633571,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.444423,
    "out:CO2 Operational/m2": 19.141158,
    "out:Total CO2/m2": 44.585581,
    "out:Total CO2 (tons)": 93139.3043,
    "out:Klimatpaverkan":0.001* -21873.08314,
    "out:Initial Cost/MSEK": 2.982625,
    "out:Running cost/(Apt SEK y)": 22001.96,
    "out:Running Cost over RSP/MSEK": 4.30376,
    "out:LCP/MSEK": -0.525545,
    "out:ROI% old": 11.76854,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 52.3,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 11,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 115852.6076,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118016.34,
    "out:DH kr savings": 105425.8729,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101612.382,
    "out:% savings (space heating)": 53.274142,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.64,
    "out:Etvv": 25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2982625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.019857,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 109.799,
    "out:Total Energy Use Post PV": 104.046908,
    "out:Primary Energy": 78.373334,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.894399,
    "out:CO2 Operational/m2": 18.061206,
    "out:Total CO2/m2": 43.955605,
    "out:Total CO2 (tons)": 91823.28408,
    "out:Klimatpaverkan":0.001* -23189.10337,
    "out:Initial Cost/MSEK": 3.04425,
    "out:Running cost/(Apt SEK y)": 21343.12,
    "out:Running Cost over RSP/MSEK": 4.17486,
    "out:LCP/MSEK": -0.45827,
    "out:ROI% old": 12.135196,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 11,
    "out:Return/kSEK/y": 331,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122332.2032,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123912.77,
    "out:DH kr savings": 111322.3049,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 108669.5101,
    "out:% savings (space heating)": 50.028963,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.02,
    "out:Etvv": 21.25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3044250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.641625,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 100.046908,
    "out:Primary Energy": 76.008571,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.894399,
    "out:CO2 Operational/m2": 16.62127,
    "out:Total CO2/m2": 42.515669,
    "out:Total CO2 (tons)": 88815.25695,
    "out:Klimatpaverkan":0.001* -26197.1305,
    "out:Initial Cost/MSEK": 3.0965,
    "out:Running cost/(Apt SEK y)": 20464.68,
    "out:Running Cost over RSP/MSEK": 4.00299,
    "out:LCP/MSEK": -0.33865,
    "out:ROI% old": 12.723351,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 11,
    "out:Return/kSEK/y": 353,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 129389.3313,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130334.76,
    "out:DH kr savings": 117744.2915,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101612.382,
    "out:% savings (space heating)": 53.274142,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.64,
    "out:Etvv": 21.25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3096500,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.019857,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 115.799,
    "out:Total Energy Use Post PV": 108.669833,
    "out:Primary Energy": 78.519599,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.327033,
    "out:CO2 Operational/m2": -18.388552,
    "out:Total CO2/m2": 16.938481,
    "out:Total CO2 (tons)": 35384.49653,
    "out:Klimatpaverkan":0.001* -79627.89091,
    "out:Initial Cost/MSEK": 3.278,
    "out:Running cost/(Apt SEK y)": 21659.28,
    "out:Running Cost over RSP/MSEK": 4.2422,
    "out:LCP/MSEK": -0.75936,
    "out:ROI% old": 10.976379,
    "out:Payback discounted": 11,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 49.5,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 10,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 108795.4795,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136633,
    "out:DH kr savings": 99003.88635,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 108669.5101,
    "out:% savings (space heating)": 50.028963,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.02,
    "out:Etvv": 25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3278000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.641625,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 112.799,
    "out:Total Energy Use Post PV": 105.669833,
    "out:Primary Energy": 76.154836,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.327033,
    "out:CO2 Operational/m2": -19.468504,
    "out:Total CO2/m2": 15.858529,
    "out:Total CO2 (tons)": 33128.47618,
    "out:Klimatpaverkan":0.001* -81883.91126,
    "out:Initial Cost/MSEK": 3.33025,
    "out:Running cost/(Apt SEK y)": 21000.44,
    "out:Running Cost over RSP/MSEK": 4.1133,
    "out:LCP/MSEK": -0.68271,
    "out:ROI% old": 11.357105,
    "out:Payback discounted": 11,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 10,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 115852.6076,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143054.98,
    "out:DH kr savings": 105425.8729,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101612.382,
    "out:% savings (space heating)": 53.274142,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.64,
    "out:Etvv": 25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3330250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.019857,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 109.799,
    "out:Total Energy Use Post PV": 102.669833,
    "out:Primary Energy": 75.894599,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.777009,
    "out:CO2 Operational/m2": -20.548456,
    "out:Total CO2/m2": 15.228553,
    "out:Total CO2 (tons)": 31812.45596,
    "out:Klimatpaverkan":0.001* -83199.93149,
    "out:Initial Cost/MSEK": 3.391875,
    "out:Running cost/(Apt SEK y)": 20341.6,
    "out:Running Cost over RSP/MSEK": 3.98439,
    "out:LCP/MSEK": -0.615425,
    "out:ROI% old": 11.693701,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 58.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 10,
    "out:Return/kSEK/y": 356,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122332.2032,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148951.42,
    "out:DH kr savings": 111322.3049,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 108669.5101,
    "out:% savings (space heating)": 50.028963,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.02,
    "out:Etvv": 21.25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3391875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.641625,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 98.669833,
    "out:Primary Energy": 73.529836,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.777009,
    "out:CO2 Operational/m2": -21.988392,
    "out:Total CO2/m2": 13.788617,
    "out:Total CO2 (tons)": 28804.42883,
    "out:Klimatpaverkan":0.001* -86207.95862,
    "out:Initial Cost/MSEK": 3.444125,
    "out:Running cost/(Apt SEK y)": 19463.12,
    "out:Running Cost over RSP/MSEK": 3.81253,
    "out:LCP/MSEK": -0.495815,
    "out:ROI% old": 12.229148,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 64.6,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 11,
    "out:Return/kSEK/y": 378,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 129389.3313,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155373.4,
    "out:DH kr savings": 117744.2915,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101612.382,
    "out:% savings (space heating)": 53.274142,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.64,
    "out:Etvv": 21.25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3444125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.019857,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 107.799,
    "out:Total Energy Use Post PV": 107.799,
    "out:Primary Energy": 87.8521,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.561813,
    "out:CO2 Operational/m2": 34.064242,
    "out:Total CO2/m2": 49.626055,
    "out:Total CO2 (tons)": 103668.8574,
    "out:Klimatpaverkan":0.001* -11343.53006,
    "out:Initial Cost/MSEK": 2.64875,
    "out:Running cost/(Apt SEK y)": 22060.84,
    "out:Running Cost over RSP/MSEK": 4.30911,
    "out:LCP/MSEK": -0.19702,
    "out:ROI% old": 13.223111,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 50.9,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 313,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 126844.4445,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99096.01,
    "out:DH kr savings": 115428.4445,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 108669.5101,
    "out:% savings (space heating)": 50.028963,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.02,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2648750,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.641625,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 103.799,
    "out:Total Energy Use Post PV": 103.799,
    "out:Primary Energy": 85.487337,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.561813,
    "out:CO2 Operational/m2": 32.624306,
    "out:Total CO2/m2": 48.186119,
    "out:Total CO2 (tons)": 100660.8303,
    "out:Klimatpaverkan":0.001* -14351.55719,
    "out:Initial Cost/MSEK": 2.701,
    "out:Running cost/(Apt SEK y)": 21182.36,
    "out:Running Cost over RSP/MSEK": 4.13725,
    "out:LCP/MSEK": -0.07741,
    "out:ROI% old": 13.876289,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 335,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 133901.5725,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105518,
    "out:DH kr savings": 121850.431,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101612.382,
    "out:% savings (space heating)": 53.274142,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.64,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2701000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.019857,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 101.799,
    "out:Total Energy Use Post PV": 101.799,
    "out:Primary Energy": 85.7521,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.011789,
    "out:CO2 Operational/m2": 31.904338,
    "out:Total CO2/m2": 47.916127,
    "out:Total CO2 (tons)": 100096.8168,
    "out:Klimatpaverkan":0.001* -14915.57064,
    "out:Initial Cost/MSEK": 2.762625,
    "out:Running cost/(Apt SEK y)": 20743.16,
    "out:Running Cost over RSP/MSEK": 4.05131,
    "out:LCP/MSEK": -0.053095,
    "out:ROI% old": 14.011157,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 346,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 137673.8234,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108950.75,
    "out:DH kr savings": 125283.1793,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 108669.5101,
    "out:% savings (space heating)": 50.028963,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.02,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2762625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.641625,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 98.799,
    "out:Total Energy Use Post PV": 98.799,
    "out:Primary Energy": 83.387337,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.011789,
    "out:CO2 Operational/m2": 30.824386,
    "out:Total CO2/m2": 46.836175,
    "out:Total CO2 (tons)": 97840.79646,
    "out:Klimatpaverkan":0.001* -17171.59099,
    "out:Initial Cost/MSEK": 2.814875,
    "out:Running cost/(Apt SEK y)": 20084.32,
    "out:Running Cost over RSP/MSEK": 3.92241,
    "out:LCP/MSEK": 0.023555,
    "out:ROI% old": 14.405258,
    "out:Payback discounted": 8,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 64.6,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144730.9515,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115372.73,
    "out:DH kr savings": 131705.1659,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101612.382,
    "out:% savings (space heating)": 53.274142,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.64,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2814875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.019857,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 107.799,
    "out:Total Energy Use Post PV": 102.046908,
    "out:Primary Energy": 77.498334,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.444423,
    "out:CO2 Operational/m2": 17.341238,
    "out:Total CO2/m2": 42.785661,
    "out:Total CO2 (tons)": 89379.27039,
    "out:Klimatpaverkan":0.001* -25633.11706,
    "out:Initial Cost/MSEK": 2.996375,
    "out:Running cost/(Apt SEK y)": 20903.92,
    "out:Running Cost over RSP/MSEK": 4.08893,
    "out:LCP/MSEK": -0.324465,
    "out:ROI% old": 12.738774,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 11,
    "out:Return/kSEK/y": 342,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 126844.4445,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128018.91,
    "out:DH kr savings": 115428.4445,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 108669.5101,
    "out:% savings (space heating)": 50.028963,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.02,
    "out:Etvv": 20,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2996375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.641625,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 103.799,
    "out:Total Energy Use Post PV": 98.046908,
    "out:Primary Energy": 75.133571,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.444423,
    "out:CO2 Operational/m2": 15.901302,
    "out:Total CO2/m2": 41.345725,
    "out:Total CO2 (tons)": 86371.24326,
    "out:Klimatpaverkan":0.001* -28641.14419,
    "out:Initial Cost/MSEK": 3.048625,
    "out:Running cost/(Apt SEK y)": 20025.44,
    "out:Running Cost over RSP/MSEK": 3.91706,
    "out:LCP/MSEK": -0.204845,
    "out:ROI% old": 13.32582,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 12,
    "out:Return/kSEK/y": 364,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 133901.5725,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134440.9,
    "out:DH kr savings": 121850.431,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101612.382,
    "out:% savings (space heating)": 53.274142,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.64,
    "out:Etvv": 20,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3048625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.019857,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 101.799,
    "out:Total Energy Use Post PV": 96.046908,
    "out:Primary Energy": 75.398334,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.894399,
    "out:CO2 Operational/m2": 15.181334,
    "out:Total CO2/m2": 41.075733,
    "out:Total CO2 (tons)": 85807.22981,
    "out:Klimatpaverkan":0.001* -29205.15763,
    "out:Initial Cost/MSEK": 3.11025,
    "out:Running cost/(Apt SEK y)": 19586.24,
    "out:Running Cost over RSP/MSEK": 3.83113,
    "out:LCP/MSEK": -0.18054,
    "out:ROI% old": 13.456475,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 69.8,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 12,
    "out:Return/kSEK/y": 375,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 137673.8234,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137873.64,
    "out:DH kr savings": 125283.1793,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 108669.5101,
    "out:% savings (space heating)": 50.028963,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.02,
    "out:Etvv": 17,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3110250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.641625,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 98.799,
    "out:Total Energy Use Post PV": 93.046908,
    "out:Primary Energy": 73.033571,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 25.894399,
    "out:CO2 Operational/m2": 14.101382,
    "out:Total CO2/m2": 39.995781,
    "out:Total CO2 (tons)": 83551.20947,
    "out:Klimatpaverkan":0.001* -31461.17798,
    "out:Initial Cost/MSEK": 3.1625,
    "out:Running cost/(Apt SEK y)": 18927.4,
    "out:Running Cost over RSP/MSEK": 3.70223,
    "out:LCP/MSEK": -0.10389,
    "out:ROI% old": 13.81642,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 75.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 12,
    "out:Return/kSEK/y": 391,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 144730.9515,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 144295.63,
    "out:DH kr savings": 131705.1659,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101612.382,
    "out:% savings (space heating)": 53.274142,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.64,
    "out:Etvv": 17,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3162500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.019857,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 107.799,
    "out:Total Energy Use Post PV": 100.669833,
    "out:Primary Energy": 75.019599,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.327033,
    "out:CO2 Operational/m2": -21.268424,
    "out:Total CO2/m2": 14.058609,
    "out:Total CO2 (tons)": 29368.44227,
    "out:Klimatpaverkan":0.001* -85643.94517,
    "out:Initial Cost/MSEK": 3.344,
    "out:Running cost/(Apt SEK y)": 19902.36,
    "out:Running Cost over RSP/MSEK": 3.89846,
    "out:LCP/MSEK": -0.48162,
    "out:ROI% old": 12.228213,
    "out:Payback discounted": 10,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 61.4,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 11,
    "out:Return/kSEK/y": 367,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 126844.4445,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 153057.56,
    "out:DH kr savings": 115428.4445,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 108669.5101,
    "out:% savings (space heating)": 50.028963,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.02,
    "out:Etvv": 20,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3344000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.641625,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 103.799,
    "out:Total Energy Use Post PV": 96.669833,
    "out:Primary Energy": 72.654836,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.327033,
    "out:CO2 Operational/m2": -22.70836,
    "out:Total CO2/m2": 12.618673,
    "out:Total CO2 (tons)": 26360.41514,
    "out:Klimatpaverkan":0.001* -88651.9723,
    "out:Initial Cost/MSEK": 3.39625,
    "out:Running cost/(Apt SEK y)": 19023.92,
    "out:Running Cost over RSP/MSEK": 3.7266,
    "out:LCP/MSEK": -0.36201,
    "out:ROI% old": 12.762984,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 68,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 11,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 133901.5725,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159479.54,
    "out:DH kr savings": 121850.431,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101612.382,
    "out:% savings (space heating)": 53.274142,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.64,
    "out:Etvv": 20,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3396250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.019857,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 101.799,
    "out:Total Energy Use Post PV": 94.669833,
    "out:Primary Energy": 72.919599,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.777009,
    "out:CO2 Operational/m2": -23.428328,
    "out:Total CO2/m2": 12.348681,
    "out:Total CO2 (tons)": 25796.4017,
    "out:Klimatpaverkan":0.001* -89215.98575,
    "out:Initial Cost/MSEK": 3.457875,
    "out:Running cost/(Apt SEK y)": 18584.68,
    "out:Running Cost over RSP/MSEK": 3.64066,
    "out:LCP/MSEK": -0.337695,
    "out:ROI% old": 12.890576,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 12,
    "out:Return/kSEK/y": 400,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 137673.8234,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162912.29,
    "out:DH kr savings": 125283.1793,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 108669.5101,
    "out:% savings (space heating)": 50.028963,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.02,
    "out:Etvv": 17,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3457875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.641625,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 98.799,
    "out:Total Energy Use Post PV": 91.669833,
    "out:Primary Energy": 70.554836,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.777009,
    "out:CO2 Operational/m2": -24.50828,
    "out:Total CO2/m2": 11.268729,
    "out:Total CO2 (tons)": 23540.38135,
    "out:Klimatpaverkan":0.001* -91472.0061,
    "out:Initial Cost/MSEK": 3.510125,
    "out:Running cost/(Apt SEK y)": 17925.84,
    "out:Running Cost over RSP/MSEK": 3.51176,
    "out:LCP/MSEK": -0.261045,
    "out:ROI% old": 13.223298,
    "out:Payback discounted": 9,
    "out:Atemp": 2089,
    "out:Umean": 0.41,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 77.2,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 12,
    "out:Return/kSEK/y": 416,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 144730.9515,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169334.28,
    "out:DH kr savings": 131705.1659,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101612.382,
    "out:% savings (space heating)": 53.274142,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.64,
    "out:Etvv": 17,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3510125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z00000000000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.802203,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 112.799,
    "out:Total Energy Use Post PV": 112.799,
    "out:Primary Energy": 89.099742,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.5866,
    "out:CO2 Operational/m2": 35.864162,
    "out:Total CO2/m2": 73.450762,
    "out:Total CO2 (tons)": 153438.684,
    "out:Klimatpaverkan":0.001* 38426.29654,
    "out:Initial Cost/MSEK": 4.44675,
    "out:Running cost/(Apt SEK y)": 23158.88,
    "out:Running Cost over RSP/MSEK": 4.52394,
    "out:LCP/MSEK": -2.20985,
    "out:ROI% old": 7.186308,
    "out:Payback discounted": 17,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 44.2,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 6,
    "out:Return/kSEK/y": 286,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 115517.1606,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88788.18,
    "out:DH kr savings": 105120.6161,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101947.829,
    "out:% savings (space heating)": 53.119889,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.8,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4446750,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.570228,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 109.799,
    "out:Total Energy Use Post PV": 109.799,
    "out:Primary Energy": 86.83736,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.5866,
    "out:CO2 Operational/m2": 34.78421,
    "out:Total CO2/m2": 72.37081,
    "out:Total CO2 (tons)": 151182.6636,
    "out:Klimatpaverkan":0.001* 36170.27619,
    "out:Initial Cost/MSEK": 4.499,
    "out:Running cost/(Apt SEK y)": 22500.04,
    "out:Running Cost over RSP/MSEK": 4.39504,
    "out:LCP/MSEK": -2.1332,
    "out:ROI% old": 7.512146,
    "out:Payback discounted": 16,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 48.2,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 302,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 122268.7577,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 94932.14,
    "out:DH kr savings": 111264.5695,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 95196.23193,
    "out:% savings (space heating)": 56.224571,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 45.57,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4499000,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.802203,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 106.799,
    "out:Total Energy Use Post PV": 106.799,
    "out:Primary Energy": 86.474742,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.036576,
    "out:CO2 Operational/m2": 33.704258,
    "out:Total CO2/m2": 71.740834,
    "out:Total CO2 (tons)": 149866.6434,
    "out:Klimatpaverkan":0.001* 34854.25596,
    "out:Initial Cost/MSEK": 4.560625,
    "out:Running cost/(Apt SEK y)": 21841.2,
    "out:Running Cost over RSP/MSEK": 4.26614,
    "out:LCP/MSEK": -2.065925,
    "out:ROI% old": 7.814405,
    "out:Payback discounted": 15,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 52.3,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 319,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 129053.8843,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 101106.6,
    "out:DH kr savings": 117439.0347,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101947.829,
    "out:% savings (space heating)": 53.119889,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.8,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4560625,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.570228,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 102.799,
    "out:Total Energy Use Post PV": 102.799,
    "out:Primary Energy": 84.21236,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.036576,
    "out:CO2 Operational/m2": 32.264322,
    "out:Total CO2/m2": 70.300898,
    "out:Total CO2 (tons)": 146858.6163,
    "out:Klimatpaverkan":0.001* 31846.22883,
    "out:Initial Cost/MSEK": 4.612875,
    "out:Running cost/(Apt SEK y)": 20962.76,
    "out:Running Cost over RSP/MSEK": 4.09428,
    "out:LCP/MSEK": -1.946315,
    "out:ROI% old": 8.258128,
    "out:Payback discounted": 15,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 58.3,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 341,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 135805.4814,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107250.55,
    "out:DH kr savings": 123582.9881,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 95196.23193,
    "out:% savings (space heating)": 56.224571,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 45.57,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4612875,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.802203,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 112.799,
    "out:Total Energy Use Post PV": 107.046908,
    "out:Primary Energy": 78.745976,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.46921,
    "out:CO2 Operational/m2": 19.141158,
    "out:Total CO2/m2": 66.610368,
    "out:Total CO2 (tons)": 139149.097,
    "out:Klimatpaverkan":0.001* 24136.70954,
    "out:Initial Cost/MSEK": 4.794375,
    "out:Running cost/(Apt SEK y)": 22001.96,
    "out:Running Cost over RSP/MSEK": 4.30376,
    "out:LCP/MSEK": -2.337295,
    "out:ROI% old": 7.321318,
    "out:Payback discounted": 17,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 52.3,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 7,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 115517.1606,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117711.08,
    "out:DH kr savings": 105120.6161,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101947.829,
    "out:% savings (space heating)": 53.119889,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.8,
    "out:Etvv": 25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4794375,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.570228,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 109.799,
    "out:Total Energy Use Post PV": 104.046908,
    "out:Primary Energy": 76.483594,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.46921,
    "out:CO2 Operational/m2": 18.061206,
    "out:Total CO2/m2": 65.530416,
    "out:Total CO2 (tons)": 136893.0766,
    "out:Klimatpaverkan":0.001* 21880.68919,
    "out:Initial Cost/MSEK": 4.846625,
    "out:Running cost/(Apt SEK y)": 21343.12,
    "out:Running Cost over RSP/MSEK": 4.17486,
    "out:LCP/MSEK": -2.260645,
    "out:ROI% old": 7.622329,
    "out:Payback discounted": 16,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 7,
    "out:Return/kSEK/y": 331,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 122268.7577,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123855.03,
    "out:DH kr savings": 111264.5695,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 95196.23193,
    "out:% savings (space heating)": 56.224571,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 45.57,
    "out:Etvv": 25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4846625,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.802203,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 106.799,
    "out:Total Energy Use Post PV": 101.046908,
    "out:Primary Energy": 76.120976,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.919186,
    "out:CO2 Operational/m2": 16.981254,
    "out:Total CO2/m2": 64.90044,
    "out:Total CO2 (tons)": 135577.0564,
    "out:Klimatpaverkan":0.001* 20564.66897,
    "out:Initial Cost/MSEK": 4.90825,
    "out:Running cost/(Apt SEK y)": 20684.28,
    "out:Running Cost over RSP/MSEK": 4.04596,
    "out:LCP/MSEK": -2.19337,
    "out:ROI% old": 7.901798,
    "out:Payback discounted": 15,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 61.4,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 7,
    "out:Return/kSEK/y": 347,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 129053.8843,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130029.5,
    "out:DH kr savings": 117439.0347,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101947.829,
    "out:% savings (space heating)": 53.119889,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.8,
    "out:Etvv": 21.25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4908250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.570228,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 102.799,
    "out:Total Energy Use Post PV": 97.046908,
    "out:Primary Energy": 73.858594,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.919186,
    "out:CO2 Operational/m2": 15.541318,
    "out:Total CO2/m2": 63.460504,
    "out:Total CO2 (tons)": 132569.0293,
    "out:Klimatpaverkan":0.001* 17556.64184,
    "out:Initial Cost/MSEK": 4.9605,
    "out:Running cost/(Apt SEK y)": 19805.84,
    "out:Running Cost over RSP/MSEK": 3.87409,
    "out:LCP/MSEK": -2.07375,
    "out:ROI% old": 8.313534,
    "out:Payback discounted": 15,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 68,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 7,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 135805.4814,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 136173.45,
    "out:DH kr savings": 123582.9881,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 95196.23193,
    "out:% savings (space heating)": 56.224571,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 45.57,
    "out:Etvv": 21.25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4960500,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.802203,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 112.799,
    "out:Total Energy Use Post PV": 105.669833,
    "out:Primary Energy": 76.267241,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 57.35182,
    "out:CO2 Operational/m2": -19.468504,
    "out:Total CO2/m2": 37.883316,
    "out:Total CO2 (tons)": 79138.26887,
    "out:Klimatpaverkan":0.001* -35874.11858,
    "out:Initial Cost/MSEK": 5.142,
    "out:Running cost/(Apt SEK y)": 21000.44,
    "out:Running Cost over RSP/MSEK": 4.1133,
    "out:LCP/MSEK": -2.49446,
    "out:ROI% old": 7.355504,
    "out:Payback discounted": 16,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 7,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 115517.1606,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142749.73,
    "out:DH kr savings": 105120.6161,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101947.829,
    "out:% savings (space heating)": 53.119889,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.8,
    "out:Etvv": 25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5142000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.570228,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 109.799,
    "out:Total Energy Use Post PV": 102.669833,
    "out:Primary Energy": 74.004859,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 57.35182,
    "out:CO2 Operational/m2": -20.548456,
    "out:Total CO2/m2": 36.803364,
    "out:Total CO2 (tons)": 76882.24852,
    "out:Klimatpaverkan":0.001* -38130.13892,
    "out:Initial Cost/MSEK": 5.19425,
    "out:Running cost/(Apt SEK y)": 20341.6,
    "out:Running Cost over RSP/MSEK": 3.98439,
    "out:LCP/MSEK": -2.4178,
    "out:ROI% old": 7.636054,
    "out:Payback discounted": 16,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 58.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 7,
    "out:Return/kSEK/y": 356,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 122268.7577,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148893.68,
    "out:DH kr savings": 111264.5695,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 95196.23193,
    "out:% savings (space heating)": 56.224571,
    "out:Total_Water Heating (DH)": 90244.8248,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 45.57,
    "out:Etvv": 25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5194250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.802203,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 106.799,
    "out:Total Energy Use Post PV": 99.669833,
    "out:Primary Energy": 73.642241,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 57.801796,
    "out:CO2 Operational/m2": -21.628408,
    "out:Total CO2/m2": 36.173388,
    "out:Total CO2 (tons)": 75566.2283,
    "out:Klimatpaverkan":0.001* -39446.15915,
    "out:Initial Cost/MSEK": 5.255875,
    "out:Running cost/(Apt SEK y)": 19682.76,
    "out:Running Cost over RSP/MSEK": 3.8555,
    "out:LCP/MSEK": -2.350535,
    "out:ROI% old": 7.89685,
    "out:Payback discounted": 15,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 7,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 129053.8843,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155068.15,
    "out:DH kr savings": 117439.0347,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101947.829,
    "out:% savings (space heating)": 53.119889,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.8,
    "out:Etvv": 21.25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5255875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.570228,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 102.799,
    "out:Total Energy Use Post PV": 95.669833,
    "out:Primary Energy": 71.379859,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 57.801796,
    "out:CO2 Operational/m2": -23.068344,
    "out:Total CO2/m2": 34.733452,
    "out:Total CO2 (tons)": 72558.20117,
    "out:Klimatpaverkan":0.001* -42454.18628,
    "out:Initial Cost/MSEK": 5.308125,
    "out:Running cost/(Apt SEK y)": 18804.28,
    "out:Running Cost over RSP/MSEK": 3.68363,
    "out:LCP/MSEK": -2.230915,
    "out:ROI% old": 8.281671,
    "out:Payback discounted": 15,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 69.8,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 7,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 135805.4814,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161212.1,
    "out:DH kr savings": 123582.9881,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 95196.23193,
    "out:% savings (space heating)": 56.224571,
    "out:Total_Water Heating (DH)": 76708.10108,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 45.57,
    "out:Etvv": 21.25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5308125,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.802203,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 103.799,
    "out:Total Energy Use Post PV": 103.799,
    "out:Primary Energy": 85.599742,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.5866,
    "out:CO2 Operational/m2": 32.624306,
    "out:Total CO2/m2": 70.210906,
    "out:Total CO2 (tons)": 146670.6229,
    "out:Klimatpaverkan":0.001* 31658.23549,
    "out:Initial Cost/MSEK": 4.51275,
    "out:Running cost/(Apt SEK y)": 21182.36,
    "out:Running Cost over RSP/MSEK": 4.13725,
    "out:LCP/MSEK": -1.88916,
    "out:ROI% old": 8.305325,
    "out:Payback discounted": 15,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 335,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 133566.1255,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105212.74,
    "out:DH kr savings": 121545.1742,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101947.829,
    "out:% savings (space heating)": 53.119889,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.8,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4512750,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.570228,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 100.799,
    "out:Total Energy Use Post PV": 100.799,
    "out:Primary Energy": 83.33736,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.5866,
    "out:CO2 Operational/m2": 31.544354,
    "out:Total CO2/m2": 69.130954,
    "out:Total CO2 (tons)": 144414.6026,
    "out:Klimatpaverkan":0.001* 29402.21514,
    "out:Initial Cost/MSEK": 4.565,
    "out:Running cost/(Apt SEK y)": 20523.52,
    "out:Running Cost over RSP/MSEK": 4.00834,
    "out:LCP/MSEK": -1.8125,
    "out:ROI% old": 8.613675,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 61.4,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 352,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 140317.7226,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111356.69,
    "out:DH kr savings": 127689.1276,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 95196.23193,
    "out:% savings (space heating)": 56.224571,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 45.57,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4565000,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.802203,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 98.799,
    "out:Total Energy Use Post PV": 98.799,
    "out:Primary Energy": 83.499742,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.036576,
    "out:CO2 Operational/m2": 30.824386,
    "out:Total CO2/m2": 68.860962,
    "out:Total CO2 (tons)": 143850.5891,
    "out:Klimatpaverkan":0.001* 28838.2017,
    "out:Initial Cost/MSEK": 4.626625,
    "out:Running cost/(Apt SEK y)": 20084.32,
    "out:Running Cost over RSP/MSEK": 3.92241,
    "out:LCP/MSEK": -1.788195,
    "out:ROI% old": 8.764272,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 64.6,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 144395.5045,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115067.48,
    "out:DH kr savings": 131399.9091,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101947.829,
    "out:% savings (space heating)": 53.119889,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.8,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4626625,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.570228,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 95.799,
    "out:Total Energy Use Post PV": 95.799,
    "out:Primary Energy": 81.23736,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.036576,
    "out:CO2 Operational/m2": 29.744434,
    "out:Total CO2/m2": 67.78101,
    "out:Total CO2 (tons)": 141594.5688,
    "out:Klimatpaverkan":0.001* 26582.18135,
    "out:Initial Cost/MSEK": 4.67875,
    "out:Running cost/(Apt SEK y)": 19425.48,
    "out:Running Cost over RSP/MSEK": 3.79351,
    "out:LCP/MSEK": -1.71142,
    "out:ROI% old": 9.060204,
    "out:Payback discounted": 13,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 69.8,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 379,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 151147.1016,
    "out:EL kWh savings": -9607.31364,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121211.43,
    "out:DH kr savings": 137543.8625,
    "out:El kr savings": -16332.43319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 95196.23193,
    "out:% savings (space heating)": 56.224571,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 45.57,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4678750,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.802203,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 103.799,
    "out:Total Energy Use Post PV": 98.046908,
    "out:Primary Energy": 75.245976,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.46921,
    "out:CO2 Operational/m2": 15.901302,
    "out:Total CO2/m2": 63.370512,
    "out:Total CO2 (tons)": 132381.0359,
    "out:Klimatpaverkan":0.001* 17368.6485,
    "out:Initial Cost/MSEK": 4.860375,
    "out:Running cost/(Apt SEK y)": 20025.44,
    "out:Running Cost over RSP/MSEK": 3.91706,
    "out:LCP/MSEK": -2.016595,
    "out:ROI% old": 8.358497,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 7,
    "out:Return/kSEK/y": 364,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 133566.1255,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134135.64,
    "out:DH kr savings": 121545.1742,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101947.829,
    "out:% savings (space heating)": 53.119889,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.8,
    "out:Etvv": 20,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4860375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.570228,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 100.799,
    "out:Total Energy Use Post PV": 95.046908,
    "out:Primary Energy": 72.983594,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.46921,
    "out:CO2 Operational/m2": 14.82135,
    "out:Total CO2/m2": 62.29056,
    "out:Total CO2 (tons)": 130125.0156,
    "out:Klimatpaverkan":0.001* 15112.62815,
    "out:Initial Cost/MSEK": 4.912625,
    "out:Running cost/(Apt SEK y)": 19366.64,
    "out:Running Cost over RSP/MSEK": 3.78816,
    "out:LCP/MSEK": -1.939945,
    "out:ROI% old": 8.644433,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 8,
    "out:Return/kSEK/y": 380,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 140317.7226,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140279.59,
    "out:DH kr savings": 127689.1276,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 95196.23193,
    "out:% savings (space heating)": 56.224571,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 45.57,
    "out:Etvv": 20,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4912625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.802203,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 98.799,
    "out:Total Energy Use Post PV": 93.046908,
    "out:Primary Energy": 73.145976,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.919186,
    "out:CO2 Operational/m2": 14.101382,
    "out:Total CO2/m2": 62.020568,
    "out:Total CO2 (tons)": 129561.0022,
    "out:Klimatpaverkan":0.001* 14548.61471,
    "out:Initial Cost/MSEK": 4.97425,
    "out:Running cost/(Apt SEK y)": 18927.4,
    "out:Running Cost over RSP/MSEK": 3.70223,
    "out:LCP/MSEK": -1.91564,
    "out:ROI% old": 8.784124,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 75.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 8,
    "out:Return/kSEK/y": 391,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 144395.5045,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143990.37,
    "out:DH kr savings": 131399.9091,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101947.829,
    "out:% savings (space heating)": 53.119889,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.8,
    "out:Etvv": 17,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4974250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.570228,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 95.799,
    "out:Total Energy Use Post PV": 90.046908,
    "out:Primary Energy": 70.883594,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.919186,
    "out:CO2 Operational/m2": 13.02143,
    "out:Total CO2/m2": 60.940616,
    "out:Total CO2 (tons)": 127304.9818,
    "out:Klimatpaverkan":0.001* 12292.59436,
    "out:Initial Cost/MSEK": 5.026375,
    "out:Running cost/(Apt SEK y)": 18268.56,
    "out:Running Cost over RSP/MSEK": 3.57333,
    "out:LCP/MSEK": -1.838865,
    "out:ROI% old": 9.059383,
    "out:Payback discounted": 13,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 81.1,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 1063.732667,
    "out:Return %": 8,
    "out:Return/kSEK/y": 408,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 19.908176,
    "out:PV (m2 panels)": 104.450029,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 18679.25096,
    "out:PV (kWh sold)": 6663.128452,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 12016.1225,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 151147.1016,
    "out:EL kWh savings": 2408.80985,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 150134.33,
    "out:DH kr savings": 137543.8625,
    "out:El kr savings": 4094.976745,
    "out:El kr sold": 8495.488777,
    "out:El kr saved": 20427.40826,
    "out:El kr return": 28922.89703,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 95196.23193,
    "out:% savings (space heating)": 56.224571,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 45.57,
    "out:Etvv": 17,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5026375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.802203,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 103.799,
    "out:Total Energy Use Post PV": 96.669833,
    "out:Primary Energy": 72.767241,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 57.35182,
    "out:CO2 Operational/m2": -22.70836,
    "out:Total CO2/m2": 34.64346,
    "out:Total CO2 (tons)": 72370.20783,
    "out:Klimatpaverkan":0.001* -42642.17962,
    "out:Initial Cost/MSEK": 5.208,
    "out:Running cost/(Apt SEK y)": 19023.92,
    "out:Running Cost over RSP/MSEK": 3.7266,
    "out:LCP/MSEK": -2.17376,
    "out:ROI% old": 8.32302,
    "out:Payback discounted": 15,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 68,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 7,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 133566.1255,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159174.29,
    "out:DH kr savings": 121545.1742,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101947.829,
    "out:% savings (space heating)": 53.119889,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.8,
    "out:Etvv": 20,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5208000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.570228,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 100.799,
    "out:Total Energy Use Post PV": 93.669833,
    "out:Primary Energy": 70.504859,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 57.35182,
    "out:CO2 Operational/m2": -23.788312,
    "out:Total CO2/m2": 33.563508,
    "out:Total CO2 (tons)": 70114.18748,
    "out:Klimatpaverkan":0.001* -44898.19997,
    "out:Initial Cost/MSEK": 5.26025,
    "out:Running cost/(Apt SEK y)": 18365.08,
    "out:Running Cost over RSP/MSEK": 3.5977,
    "out:LCP/MSEK": -2.09711,
    "out:ROI% old": 8.590412,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 73.4,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 8,
    "out:Return/kSEK/y": 405,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 140317.7226,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165318.24,
    "out:DH kr savings": 127689.1276,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 95196.23193,
    "out:% savings (space heating)": 56.224571,
    "out:Total_Water Heating (DH)": 72195.85984,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 45.57,
    "out:Etvv": 20,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5260250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.802203,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 98.799,
    "out:Total Energy Use Post PV": 91.669833,
    "out:Primary Energy": 70.667241,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 57.801796,
    "out:CO2 Operational/m2": -24.50828,
    "out:Total CO2/m2": 33.293516,
    "out:Total CO2 (tons)": 69550.17403,
    "out:Klimatpaverkan":0.001* -45462.21341,
    "out:Initial Cost/MSEK": 5.321875,
    "out:Running cost/(Apt SEK y)": 17925.84,
    "out:Running Cost over RSP/MSEK": 3.51176,
    "out:LCP/MSEK": -2.072795,
    "out:ROI% old": 8.721631,
    "out:Payback discounted": 14,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 77.2,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 8,
    "out:Return/kSEK/y": 416,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 144395.5045,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169029.02,
    "out:DH kr savings": 131399.9091,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 101947.829,
    "out:% savings (space heating)": 53.119889,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.8,
    "out:Etvv": 17,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5321875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.570228,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 95.799,
    "out:Total Energy Use Post PV": 88.669833,
    "out:Primary Energy": 68.404859,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 57.801796,
    "out:CO2 Operational/m2": -25.588232,
    "out:Total CO2/m2": 32.213564,
    "out:Total CO2 (tons)": 67294.15369,
    "out:Klimatpaverkan":0.001* -47718.23376,
    "out:Initial Cost/MSEK": 5.374,
    "out:Running cost/(Apt SEK y)": 17267,
    "out:Running Cost over RSP/MSEK": 3.38286,
    "out:LCP/MSEK": -1.99602,
    "out:ROI% old": 8.979691,
    "out:Payback discounted": 13,
    "out:Atemp": 2089,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 417.8001149,
    "out:Facade surface/m2": 965.5477508,
    "out:Window surface/m2": 354.0570772,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 83.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 965.462892,
    "out:Return %": 8,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 54454.37487,
    "out:EAHP (pipework)": 54287.90242,
    "out:EAHP (natural ventilation grills)": 119015.625,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 39.816351,
    "out:PV (m2 panels)": 208.900058,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 37358.50191,
    "out:PV (kWh sold)": 22465.668,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 14892.83391,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 788027.3838,
    "out:FTX (Diffusers)": 138937.5,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152585.8975,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 25,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 308750,
    "out:DH (pipework)": 343750,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.09,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 352738.1921,
    "out:FTX (Facade)": 2638986.836,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 151147.1016,
    "out:EL kWh savings": 5285.520315,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 52225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175172.97,
    "out:DH kr savings": 137543.8625,
    "out:El kr savings": 8985.384536,
    "out:El kr sold": 28643.7267,
    "out:El kr saved": 25317.81765,
    "out:El kr return": 53961.54435,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 33841.8093,
    "out:Total_Space Heating (DH)": 95196.23193,
    "out:% savings (space heating)": 56.224571,
    "out:Total_Water Heating (DH)": 61366.48086,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 45.57,
    "out:Etvv": 17,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5374000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  }
]