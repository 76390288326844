import { Address } from "../../buildings";
import type { Dataset } from "../types";
import Fagelbacksgatan37 from "./Fagelbacksgatan37";

import Lonngatan40A from "./Lonngatan40A";
import ThomsonsVag22 from "./ThomsonsVag22";
import Visbyringen4 from "./Visbyringen4";
import Visbyringen18 from "./Visbyringen18";
import Visbyringen24 from "./Visbyringen24";
import Visbyringen34 from "./Visbyringen34";
import Oslogatan15 from "./Oslogatan15";
import Oslogatan17 from "./Oslogatan17";
import Oslogatan19 from "./Oslogatan19";
import Oslogatan21 from "./Oslogatan21";
import Folkparksvägen3 from "./Folkparksvägen3";
import Plåtslagarevägen4 from "./Plåtslagarevägen4";
import Vandürensväg45 from "./Vandürensväg45";

export const BuildingData: Record<Address, Dataset> = {
  "Lönngatan 40": Lonngatan40A,
  "Fågelbacksgatan 37": Fagelbacksgatan37,
  "Thomsons Väg 22": ThomsonsVag22,
  [Address.visbyringen4]: Visbyringen4,
  [Address.visbyringen18]: Visbyringen18,
  [Address.visbyringen24]: Visbyringen24,
  [Address.visbyringen34]: Visbyringen34,
  [Address.oslogatan15]: Oslogatan15,
  [Address.oslogatan17]: Oslogatan17,
  [Address.oslogatan19]: Oslogatan19,
  [Address.oslogatan21]: Oslogatan21,
  [Address.folkparksvägen3]: Folkparksvägen3,
  [Address.plåtslagarevägen4]: Plåtslagarevägen4,
  [Address.vandürensväg45]: Vandürensväg45,
};
