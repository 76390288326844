export default [
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.595345,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 164.2,
    "out:Total Energy Use Post PV": 164.2,
    "out:Primary Energy": 119.876742,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 55.416162,
    "out:Total CO2/m2": 55.416162,
    "out:Total CO2 (tons)": 133774.5532,
    "out:Klimatpaverkan":0.001* 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 33515.43333,
    "out:Running Cost over RSP/MSEK": 7.86231,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 2,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 100,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 252493.0466,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 104284.7518,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.6,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 0,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": 0
  },
  {
    "ID": "Z00000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.113739,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 159.2,
    "out:Total Energy Use Post PV": 159.2,
    "out:Primary Energy": 116.739617,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 53.616242,
    "out:Total CO2/m2": 53.616242,
    "out:Total CO2 (tons)": 129429.5484,
    "out:Klimatpaverkan":0.001* -4345.004872,
    "out:Initial Cost/MSEK": 0.060349975,
    "out:Running cost/(Apt SEK y)": 32458.03333,
    "out:Running Cost over RSP/MSEK": 7.61406,
    "out:LCP/MSEK": 0.1879,
    "out:ROI% old": 58.764375,
    "out:Payback discounted": 2,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 3.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 53,
    "out:Return/kSEK/y": 32,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 10818.59333,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 9844.92,
    "out:DH kr savings": 9844.919931,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 241674.4533,
    "out:% savings (space heating)": 4.284709,
    "out:Total_Water Heating (DH)": 104284.7518,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.11,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 60349.975,
    "out:Seasonal Variation ROI (%)": 16,
    "out:Seasonal Variation Payback": 6.25
  },
  {
    "ID": "Z00000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.595345,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 157.2,
    "out:Primary Energy": 117.251742,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.311516,
    "out:CO2 Operational/m2": 52.896274,
    "out:Total CO2/m2": 53.20779,
    "out:Total CO2 (tons)": 128443.5457,
    "out:Klimatpaverkan":0.001* -5331.007544,
    "out:Initial Cost/MSEK": 0.091072,
    "out:Running cost/(Apt SEK y)": 32035.06667,
    "out:Running Cost over RSP/MSEK": 7.51476,
    "out:LCP/MSEK": 0.256478,
    "out:ROI% old": 54.517305,
    "out:Payback discounted": 3,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 4.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 49,
    "out:Return/kSEK/y": 44,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 15642.71277,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 14234.87,
    "out:DH kr savings": 14234.86862,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 252493.0466,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 88642.03902,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.6,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 91072,
    "out:Seasonal Variation ROI (%)": 16,
    "out:Seasonal Variation Payback": 6.25
  },
  {
    "ID": "Z00000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.113739,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 153.2,
    "out:Total Energy Use Post PV": 153.2,
    "out:Primary Energy": 114.114617,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.311516,
    "out:CO2 Operational/m2": 51.456338,
    "out:Total CO2/m2": 51.767854,
    "out:Total CO2 (tons)": 124967.5418,
    "out:Klimatpaverkan":0.001* -8807.011441,
    "out:Initial Cost/MSEK": 0.151421975,
    "out:Running cost/(Apt SEK y)": 31189.13333,
    "out:Running Cost over RSP/MSEK": 7.31615,
    "out:LCP/MSEK": 0.394738,
    "out:ROI% old": 51.526773,
    "out:Payback discounted": 3,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 46,
    "out:Return/kSEK/y": 70,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 26461.3061,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 24079.79,
    "out:DH kr savings": 24079.78855,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 241674.4533,
    "out:% savings (space heating)": 4.284709,
    "out:Total_Water Heating (DH)": 88642.03902,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.11,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 151421.975,
    "out:Seasonal Variation ROI (%)": 16,
    "out:Seasonal Variation Payback": 6.25
  },
  {
    "ID": "Z00000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.595345,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 155.2,
    "out:Primary Energy": 116.376742,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 52.176306,
    "out:Total CO2/m2": 52.176306,
    "out:Total CO2 (tons)": 125953.5445,
    "out:Klimatpaverkan":0.001* -7821.008769,
    "out:Initial Cost/MSEK": 0.079179375,
    "out:Running cost/(Apt SEK y)": 31612.1,
    "out:Running Cost over RSP/MSEK": 7.41545,
    "out:LCP/MSEK": 0.367681,
    "out:ROI% old": 80.623449,
    "out:Payback discounted": 2,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 5.8,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 72,
    "out:Return/kSEK/y": 57,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 24,
    "out:Payback Time": 4.166667,
    "out:DH kWh savings": 20856.95036,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 18979.82,
    "out:DH kr savings": 18979.82483,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 252493.0466,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 83427.80143,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.6,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 79179.375,
    "out:Seasonal Variation ROI (%)": 24,
    "out:Seasonal Variation Payback": 4.166667
  },
  {
    "ID": "Z00000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.113739,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 151.2,
    "out:Total Energy Use Post PV": 151.2,
    "out:Primary Energy": 113.239617,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 50.73637,
    "out:Total CO2/m2": 50.73637,
    "out:Total CO2 (tons)": 122477.5406,
    "out:Klimatpaverkan":0.001* -11297.01267,
    "out:Initial Cost/MSEK": 0.13952935,
    "out:Running cost/(Apt SEK y)": 30766.16667,
    "out:Running Cost over RSP/MSEK": 7.21685,
    "out:LCP/MSEK": 0.505931,
    "out:ROI% old": 66.08543,
    "out:Payback discounted": 2,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 8.6,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 59,
    "out:Return/kSEK/y": 82,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 21,
    "out:Payback Time": 4.761905,
    "out:DH kWh savings": 31675.54369,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 28824.74,
    "out:DH kr savings": 28824.74476,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 241674.4533,
    "out:% savings (space heating)": 4.284709,
    "out:Total_Water Heating (DH)": 83427.80143,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.11,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 139529.35,
    "out:Seasonal Variation ROI (%)": 21,
    "out:Seasonal Variation Payback": 4.761905
  },
  {
    "ID": "Z00000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.595345,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 150.2,
    "out:Primary Energy": 114.276742,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.311516,
    "out:CO2 Operational/m2": 50.376386,
    "out:Total CO2/m2": 50.687902,
    "out:Total CO2 (tons)": 122360.5389,
    "out:Klimatpaverkan":0.001* -11414.01436,
    "out:Initial Cost/MSEK": 0.170251375,
    "out:Running cost/(Apt SEK y)": 30554.7,
    "out:Running Cost over RSP/MSEK": 7.1672,
    "out:LCP/MSEK": 0.524859,
    "out:ROI% old": 58.326359,
    "out:Payback discounted": 2,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 9.3,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 52,
    "out:Return/kSEK/y": 89,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 33371.12057,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 30367.72,
    "out:DH kr savings": 30367.71972,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 252493.0466,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 70913.63122,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.6,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 170251.375,
    "out:Seasonal Variation ROI (%)": 18,
    "out:Seasonal Variation Payback": 5.555556
  },
  {
    "ID": "Z00000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.113739,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 145.2,
    "out:Primary Energy": 111.139617,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.311516,
    "out:CO2 Operational/m2": 48.576466,
    "out:Total CO2/m2": 48.887982,
    "out:Total CO2 (tons)": 118015.534,
    "out:Klimatpaverkan":0.001* -15759.01924,
    "out:Initial Cost/MSEK": 0.23060135,
    "out:Running cost/(Apt SEK y)": 29497.26667,
    "out:Running Cost over RSP/MSEK": 6.91894,
    "out:LCP/MSEK": 0.712769,
    "out:ROI% old": 58.441611,
    "out:Payback discounted": 2,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.1,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 52,
    "out:Return/kSEK/y": 121,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 44189.7139,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 40212.64,
    "out:DH kr savings": 40212.63965,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 241674.4533,
    "out:% savings (space heating)": 4.284709,
    "out:Total_Water Heating (DH)": 70913.63122,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.11,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 230601.35,
    "out:Seasonal Variation ROI (%)": 17,
    "out:Seasonal Variation Payback": 5.882353
  },
  {
    "ID": "Z00000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.297107,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.771693,
    "out:Electricity (inc PV)": 38.388987,
    "out:Total Energy Use Pre PV": 114.771693,
    "out:Total Energy Use Post PV": 112.388987,
    "out:Primary Energy": 113.872202,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.065573,
    "out:CO2 Operational/m2": 32.021006,
    "out:Total CO2/m2": 36.086579,
    "out:Total CO2 (tons)": 87112.96143,
    "out:Klimatpaverkan":0.001* -46661.59179,
    "out:Initial Cost/MSEK": 1.33575,
    "out:Running cost/(Apt SEK y)": 21227,
    "out:Running Cost over RSP/MSEK": 4.96769,
    "out:LCP/MSEK": 1.55887,
    "out:ROI% old": 30.957675,
    "out:Payback discounted": 4,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 46.4,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 2854.01851,
    "out:Return %": 28,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 22.272193,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 177424.665,
    "out:EL kWh savings": -53564.18986,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 70397.32,
    "out:DH kr savings": 161456.4451,
    "out:El kr savings": -91059.12275,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 179353.1334,
    "out:% savings (space heating)": 28.9671,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 63.96,
    "out:Etvv": 0,
    "out:Ef": 38.39,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1335750,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.920754,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.739798,
    "out:Electricity (inc PV)": 38.338328,
    "out:Total Energy Use Pre PV": 110.739798,
    "out:Total Energy Use Post PV": 108.338328,
    "out:Primary Energy": 110.760914,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.065573,
    "out:CO2 Operational/m2": 30.576859,
    "out:Total CO2/m2": 34.642432,
    "out:Total CO2 (tons)": 83626.79219,
    "out:Klimatpaverkan":0.001* -50147.76104,
    "out:Initial Cost/MSEK": 1.396125,
    "out:Running cost/(Apt SEK y)": 20376.7,
    "out:Running Cost over RSP/MSEK": 4.76807,
    "out:LCP/MSEK": 1.698115,
    "out:ROI% old": 31.661512,
    "out:Payback discounted": 4,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 51.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 2851.78586,
    "out:Return %": 28,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 22.240298,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 187989.1762,
    "out:EL kWh savings": -53441.89909,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 80218.92,
    "out:DH kr savings": 171070.1504,
    "out:El kr savings": -90851.22845,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 168788.6221,
    "out:% savings (space heating)": 33.15118,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 59.65,
    "out:Etvv": 0,
    "out:Ef": 38.34,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1396125,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.559701,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.940604,
    "out:Electricity (inc PV)": 37.878535,
    "out:Total Energy Use Pre PV": 110.940604,
    "out:Total Energy Use Post PV": 108.878535,
    "out:Primary Energy": 111.507533,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.377089,
    "out:CO2 Operational/m2": 30.831343,
    "out:Total CO2/m2": 35.208432,
    "out:Total CO2 (tons)": 84993.11556,
    "out:Klimatpaverkan":0.001* -48781.43767,
    "out:Initial Cost/MSEK": 1.426875,
    "out:Running cost/(Apt SEK y)": 20478.86667,
    "out:Running Cost over RSP/MSEK": 4.79237,
    "out:LCP/MSEK": 1.643065,
    "out:ROI% old": 30.735899,
    "out:Payback discounted": 4,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 50.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 2795.84228,
    "out:Return %": 27,
    "out:Return/kSEK/y": 391,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 21.441104,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 186446.7589,
    "out:EL kWh savings": -52331.9593,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 80702.22,
    "out:DH kr savings": 169666.5506,
    "out:El kr savings": -88964.3308,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 170331.0395,
    "out:% savings (space heating)": 32.540305,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 61.89,
    "out:Etvv": 0,
    "out:Ef": 37.88,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1426875,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.201795,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.903119,
    "out:Electricity (inc PV)": 37.822391,
    "out:Total Energy Use Pre PV": 105.903119,
    "out:Total Energy Use Post PV": 103.822391,
    "out:Primary Energy": 108.399042,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.377089,
    "out:CO2 Operational/m2": 29.026475,
    "out:Total CO2/m2": 33.403564,
    "out:Total CO2 (tons)": 80636.16622,
    "out:Klimatpaverkan":0.001* -53138.38701,
    "out:Initial Cost/MSEK": 1.487125,
    "out:Running cost/(Apt SEK y)": 19416.3,
    "out:Running Cost over RSP/MSEK": 4.54292,
    "out:LCP/MSEK": 1.832265,
    "out:ROI% old": 31.886934,
    "out:Payback discounted": 4,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 57.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 2793.21833,
    "out:Return %": 28,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 21.403619,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 196966.7391,
    "out:EL kWh savings": -52196.42774,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 90505.81,
    "out:DH kr savings": 179239.7326,
    "out:El kr savings": -88733.92716,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 159811.0592,
    "out:% savings (space heating)": 36.706748,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 57.6,
    "out:Etvv": 0,
    "out:Ef": 37.82,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1487125,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.327504,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.659452,
    "out:Electricity (inc PV)": 37.706699,
    "out:Total Energy Use Pre PV": 108.659452,
    "out:Total Energy Use Post PV": 106.706699,
    "out:Primary Energy": 110.72017,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.065573,
    "out:CO2 Operational/m2": 30.074261,
    "out:Total CO2/m2": 34.139834,
    "out:Total CO2 (tons)": 82413.52118,
    "out:Klimatpaverkan":0.001* -51361.03205,
    "out:Initial Cost/MSEK": 1.414875,
    "out:Running cost/(Apt SEK y)": 20017.43333,
    "out:Running Cost over RSP/MSEK": 4.68415,
    "out:LCP/MSEK": 1.763285,
    "out:ROI% old": 32.089256,
    "out:Payback discounted": 4,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2776.16164,
    "out:Return %": 29,
    "out:Return/kSEK/y": 405,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 21.159952,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 189421.2811,
    "out:EL kWh savings": -51917.14739,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 84114.22,
    "out:DH kr savings": 172373.3658,
    "out:El kr savings": -88259.15055,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 167356.5173,
    "out:% savings (space heating)": 33.718366,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 61.21,
    "out:Etvv": 0,
    "out:Ef": 37.71,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1414875,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.977391,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.619605,
    "out:Electricity (inc PV)": 37.649003,
    "out:Total Energy Use Pre PV": 104.619605,
    "out:Total Energy Use Post PV": 102.649003,
    "out:Primary Energy": 107.612469,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.065573,
    "out:CO2 Operational/m2": 28.629065,
    "out:Total CO2/m2": 32.694638,
    "out:Total CO2 (tons)": 78924.81965,
    "out:Klimatpaverkan":0.001* -54849.73358,
    "out:Initial Cost/MSEK": 1.47525,
    "out:Running cost/(Apt SEK y)": 19166.06667,
    "out:Running Cost over RSP/MSEK": 4.48428,
    "out:LCP/MSEK": 1.90278,
    "out:ROI% old": 32.711453,
    "out:Payback discounted": 4,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 59.2,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2773.37235,
    "out:Return %": 29,
    "out:Return/kSEK/y": 430,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 21.120105,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 199922.449,
    "out:EL kWh savings": -51777.86931,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93907.05,
    "out:DH kr savings": 181929.4286,
    "out:El kr savings": -88022.37782,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 156855.3494,
    "out:% savings (space heating)": 37.877359,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 56.92,
    "out:Etvv": 0,
    "out:Ef": 37.65,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1475250,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.40021,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.975602,
    "out:Electricity (inc PV)": 37.291522,
    "out:Total Energy Use Pre PV": 104.975602,
    "out:Total Energy Use Post PV": 103.291522,
    "out:Primary Energy": 108.83191,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.377089,
    "out:CO2 Operational/m2": 28.904035,
    "out:Total CO2/m2": 33.281124,
    "out:Total CO2 (tons)": 80340.59619,
    "out:Klimatpaverkan":0.001* -53433.95703,
    "out:Initial Cost/MSEK": 1.506,
    "out:Running cost/(Apt SEK y)": 19289.43333,
    "out:Running Cost over RSP/MSEK": 4.5135,
    "out:LCP/MSEK": 1.84281,
    "out:ROI% old": 31.766363,
    "out:Payback discounted": 4,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 59.2,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 2728.29214,
    "out:Return %": 28,
    "out:Return/kSEK/y": 427,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 20.476102,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 196487.7655,
    "out:EL kWh savings": -50914.91057,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 92248.52,
    "out:DH kr savings": 178803.8666,
    "out:El kr savings": -86555.34797,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 160290.0328,
    "out:% savings (space heating)": 36.517051,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 59.58,
    "out:Etvv": 0,
    "out:Ef": 37.29,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1506000,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.067703,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.93042,
    "out:Electricity (inc PV)": 37.230552,
    "out:Total Energy Use Pre PV": 100.93042,
    "out:Total Energy Use Post PV": 99.230552,
    "out:Primary Energy": 105.72588,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.377089,
    "out:CO2 Operational/m2": 27.458135,
    "out:Total CO2/m2": 31.835224,
    "out:Total CO2 (tons)": 76850.19521,
    "out:Klimatpaverkan":0.001* -56924.35802,
    "out:Initial Cost/MSEK": 1.566375,
    "out:Running cost/(Apt SEK y)": 18437.33333,
    "out:Running Cost over RSP/MSEK": 4.31346,
    "out:LCP/MSEK": 1.982475,
    "out:ROI% old": 32.36636,
    "out:Payback discounted": 4,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 65.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2725.1294,
    "out:Return %": 29,
    "out:Return/kSEK/y": 452,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 20.43092,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 206946.4326,
    "out:EL kWh savings": -50767.72906,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102016.11,
    "out:DH kr savings": 188321.2537,
    "out:El kr savings": -86305.1394,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 149831.3658,
    "out:% savings (space heating)": 40.659211,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.3,
    "out:Etvv": 0,
    "out:Ef": 37.23,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1566375,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.111095,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.585636,
    "out:Electricity (inc PV)": 52.871569,
    "out:Total Energy Use Pre PV": 78.585636,
    "out:Total Energy Use Post PV": 74.871569,
    "out:Primary Energy": 106.486087,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.663212,
    "out:CO2 Operational/m2": 15.389405,
    "out:Total CO2/m2": 20.052617,
    "out:Total CO2 (tons)": 48406.99506,
    "out:Klimatpaverkan":0.001* -85367.55817,
    "out:Initial Cost/MSEK": 2.661625,
    "out:Running cost/(Apt SEK y)": 12393.13333,
    "out:Running Cost over RSP/MSEK": 2.88754,
    "out:LCP/MSEK": 2.313145,
    "out:ROI% old": 26.701035,
    "out:Payback discounted": 5,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 118.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 3960.99452,
    "out:Return %": 24,
    "out:Return/kSEK/y": 634,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 38.086136,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 303401.6397,
    "out:EL kWh savings": -88525.12664,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 125602.78,
    "out:DH kr savings": 276095.4921,
    "out:El kr savings": -150492.7153,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 53376.15865,
    "out:% savings (space heating)": 78.860345,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 16.17,
    "out:Etvv": 0,
    "out:Ef": 52.87,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2661625,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.832927,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.220958,
    "out:Electricity (inc PV)": 52.395355,
    "out:Total Energy Use Pre PV": 75.220958,
    "out:Total Energy Use Post PV": 71.395355,
    "out:Primary Energy": 103.591832,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.663212,
    "out:CO2 Operational/m2": 14.261312,
    "out:Total CO2/m2": 18.924524,
    "out:Total CO2 (tons)": 45683.77982,
    "out:Klimatpaverkan":0.001* -88090.77341,
    "out:Initial Cost/MSEK": 2.722,
    "out:Running cost/(Apt SEK y)": 11708.8,
    "out:Running Cost over RSP/MSEK": 2.72702,
    "out:LCP/MSEK": 2.41329,
    "out:ROI% old": 26.951244,
    "out:Payback discounted": 5,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 3935.46706,
    "out:Return %": 24,
    "out:Return/kSEK/y": 654,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 37.721458,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 311315.1336,
    "out:EL kWh savings": -87375.54658,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134758.34,
    "out:DH kr savings": 283296.7716,
    "out:El kr savings": -148538.4292,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 45462.66476,
    "out:% savings (space heating)": 81.994488,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 13.26,
    "out:Etvv": 0,
    "out:Ef": 52.4,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2722000,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.8994,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.292211,
    "out:Electricity (inc PV)": 52.07189,
    "out:Total Energy Use Pre PV": 75.292211,
    "out:Total Energy Use Post PV": 72.07189,
    "out:Primary Energy": 104.268923,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.974728,
    "out:CO2 Operational/m2": 14.498694,
    "out:Total CO2/m2": 19.473422,
    "out:Total CO2 (tons)": 47008.81898,
    "out:Klimatpaverkan":0.001* -86765.73425,
    "out:Initial Cost/MSEK": 2.75275,
    "out:Running cost/(Apt SEK y)": 11793.26667,
    "out:Running Cost over RSP/MSEK": 2.7472,
    "out:LCP/MSEK": 2.36236,
    "out:ROI% old": 26.545455,
    "out:Payback discounted": 5,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 127.8,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3870.45477,
    "out:Return %": 24,
    "out:Return/kSEK/y": 652,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 36.792711,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 308740.669,
    "out:EL kWh savings": -86594.70243,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133743.01,
    "out:DH kr savings": 280954.0088,
    "out:El kr savings": -147210.9941,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 48037.12939,
    "out:% savings (space heating)": 80.97487,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 15.06,
    "out:Etvv": 0,
    "out:Ef": 52.07,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2752750,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.732895,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.893695,
    "out:Electricity (inc PV)": 51.569413,
    "out:Total Energy Use Pre PV": 71.893695,
    "out:Total Energy Use Post PV": 68.569413,
    "out:Primary Energy": 101.388062,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.974728,
    "out:CO2 Operational/m2": 13.366135,
    "out:Total CO2/m2": 18.340863,
    "out:Total CO2 (tons)": 44274.82281,
    "out:Klimatpaverkan":0.001* -89499.73041,
    "out:Initial Cost/MSEK": 2.813,
    "out:Running cost/(Apt SEK y)": 11104.3,
    "out:Running Cost over RSP/MSEK": 2.58561,
    "out:LCP/MSEK": 2.4637,
    "out:ROI% old": 26.797522,
    "out:Payback discounted": 5,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 137.7,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3842.55865,
    "out:Return %": 24,
    "out:Return/kSEK/y": 672,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 36.394195,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 316384.6085,
    "out:EL kWh savings": -85381.72351,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142761.06,
    "out:DH kr savings": 287909.9938,
    "out:El kr savings": -145148.93,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 40393.18986,
    "out:% savings (space heating)": 84.002257,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 12.23,
    "out:Etvv": 0,
    "out:Ef": 51.57,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2813000,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.182296,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.854969,
    "out:Electricity (inc PV)": 51.802453,
    "out:Total Energy Use Pre PV": 73.854969,
    "out:Total Energy Use Post PV": 70.802453,
    "out:Primary Energy": 103.531336,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.663212,
    "out:CO2 Operational/m2": 14.080991,
    "out:Total CO2/m2": 18.744203,
    "out:Total CO2 (tons)": 45248.48512,
    "out:Klimatpaverkan":0.001* -88526.0681,
    "out:Initial Cost/MSEK": 2.74075,
    "out:Running cost/(Apt SEK y)": 11521.96667,
    "out:Running Cost over RSP/MSEK": 2.68368,
    "out:LCP/MSEK": 2.43788,
    "out:ROI% old": 26.992768,
    "out:Payback discounted": 5,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 3839.84783,
    "out:Return %": 24,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 36.355469,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 310471.7572,
    "out:EL kWh savings": -85944.28181,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136424.02,
    "out:DH kr savings": 282529.2991,
    "out:El kr savings": -146105.2791,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 46306.04114,
    "out:% savings (space heating)": 81.660469,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 14.7,
    "out:Etvv": 0,
    "out:Ef": 51.8,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2740750,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.060358,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.442949,
    "out:Electricity (inc PV)": 51.291137,
    "out:Total Energy Use Pre PV": 70.442949,
    "out:Total Energy Use Post PV": 67.291137,
    "out:Primary Energy": 100.654984,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.663212,
    "out:CO2 Operational/m2": 12.946649,
    "out:Total CO2/m2": 17.609861,
    "out:Total CO2 (tons)": 42510.1848,
    "out:Klimatpaverkan":0.001* -91264.36842,
    "out:Initial Cost/MSEK": 2.801125,
    "out:Running cost/(Apt SEK y)": 10831.13333,
    "out:Running Cost over RSP/MSEK": 2.52166,
    "out:LCP/MSEK": 2.539525,
    "out:ROI% old": 27.237271,
    "out:Payback discounted": 5,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 144.8,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3811.00643,
    "out:Return %": 24,
    "out:Return/kSEK/y": 681,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 35.943449,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 318008.1121,
    "out:EL kWh savings": -84709.96556,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145380.44,
    "out:DH kr savings": 289387.382,
    "out:El kr savings": -144006.9414,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 38769.68629,
    "out:% savings (space heating)": 84.645246,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 11.9,
    "out:Etvv": 0,
    "out:Ef": 51.29,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2801125,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.514892,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.789334,
    "out:Electricity (inc PV)": 51.150092,
    "out:Total Energy Use Pre PV": 71.789334,
    "out:Total Energy Use Post PV": 69.150092,
    "out:Primary Energy": 101.764039,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.974728,
    "out:CO2 Operational/m2": 13.580334,
    "out:Total CO2/m2": 18.555062,
    "out:Total CO2 (tons)": 44791.89896,
    "out:Klimatpaverkan":0.001* -88982.65426,
    "out:Initial Cost/MSEK": 2.831875,
    "out:Running cost/(Apt SEK y)": 11164.7,
    "out:Running Cost over RSP/MSEK": 2.60022,
    "out:LCP/MSEK": 2.430215,
    "out:ROI% old": 26.545209,
    "out:Payback discounted": 5,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 137.7,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 3765.25338,
    "out:Return %": 24,
    "out:Return/kSEK/y": 671,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 35.289834,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 314496.8686,
    "out:EL kWh savings": -84369.48308,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142764.03,
    "out:DH kr savings": 286192.1505,
    "out:El kr savings": -143428.1212,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 42280.92974,
    "out:% savings (space heating)": 83.254616,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 13.85,
    "out:Etvv": 0,
    "out:Ef": 51.15,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2831875,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.505537,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.343197,
    "out:Electricity (inc PV)": 50.617952,
    "out:Total Energy Use Pre PV": 68.343197,
    "out:Total Energy Use Post PV": 65.617952,
    "out:Primary Energy": 98.898307,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.974728,
    "out:CO2 Operational/m2": 12.441489,
    "out:Total CO2/m2": 17.416217,
    "out:Total CO2 (tons)": 42042.7284,
    "out:Klimatpaverkan":0.001* -91731.82482,
    "out:Initial Cost/MSEK": 2.89225,
    "out:Running cost/(Apt SEK y)": 10469.23333,
    "out:Running Cost over RSP/MSEK": 2.43711,
    "out:LCP/MSEK": 2.53295,
    "out:ROI% old": 26.796735,
    "out:Payback discounted": 5,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 148.5,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3734.02379,
    "out:Return %": 24,
    "out:Return/kSEK/y": 691,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 34.843697,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 321761.4482,
    "out:EL kWh savings": -83084.89772,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151558.59,
    "out:DH kr savings": 292802.9179,
    "out:El kr savings": -141244.3261,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 35016.35013,
    "out:% savings (space heating)": 86.131757,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 11.12,
    "out:Etvv": 0,
    "out:Ef": 50.62,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2892250,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z01000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.457322,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 149.2,
    "out:Primary Energy": 109.280125,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.700481,
    "out:CO2 Operational/m2": 50.016402,
    "out:Total CO2/m2": 60.716883,
    "out:Total CO2 (tons)": 146570.4878,
    "out:Klimatpaverkan":0.001* 12795.93458,
    "out:Initial Cost/MSEK": 1.857875,
    "out:Running cost/(Apt SEK y)": 30343.2,
    "out:Running Cost over RSP/MSEK": 7.11755,
    "out:LCP/MSEK": -1.113115,
    "out:ROI% old": 5.726665,
    "out:Payback discounted": 21,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 10.1,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 5,
    "out:Return/kSEK/y": 95,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 36543.17039,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 33254.29,
    "out:DH kr savings": 33254.28505,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 215949.8762,
    "out:% savings (space heating)": 14.472941,
    "out:Total_Water Heating (DH)": 104284.7518,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.46,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1857875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.663808,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 145.2,
    "out:Primary Energy": 106.624666,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.700481,
    "out:CO2 Operational/m2": 48.576466,
    "out:Total CO2/m2": 59.276947,
    "out:Total CO2 (tons)": 143094.4839,
    "out:Klimatpaverkan":0.001* 9319.930681,
    "out:Initial Cost/MSEK": 1.91825,
    "out:Running cost/(Apt SEK y)": 29497.26667,
    "out:Running Cost over RSP/MSEK": 6.91894,
    "out:LCP/MSEK": -0.97488,
    "out:ROI% old": 7.025525,
    "out:Payback discounted": 17,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.1,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 6,
    "out:Return/kSEK/y": 121,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 45700.71052,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 41587.65,
    "out:DH kr savings": 41587.64657,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 206792.3361,
    "out:% savings (space heating)": 18.09979,
    "out:Total_Water Heating (DH)": 104284.7518,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.66,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1918250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.457322,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 142.2,
    "out:Primary Energy": 106.655125,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.011997,
    "out:CO2 Operational/m2": 47.496514,
    "out:Total CO2/m2": 58.508511,
    "out:Total CO2 (tons)": 141239.4803,
    "out:Klimatpaverkan":0.001* 7464.927035,
    "out:Initial Cost/MSEK": 1.949,
    "out:Running cost/(Apt SEK y)": 28862.83333,
    "out:Running Cost over RSP/MSEK": 6.76999,
    "out:LCP/MSEK": -0.85668,
    "out:ROI% old": 8.00645,
    "out:Payback discounted": 15,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 7,
    "out:Return/kSEK/y": 140,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 52185.88316,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 47489.15,
    "out:DH kr savings": 47489.15367,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 215949.8762,
    "out:% savings (space heating)": 14.472941,
    "out:Total_Water Heating (DH)": 88642.03902,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.46,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1949000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.663808,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 138.2,
    "out:Primary Energy": 103.999666,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.011997,
    "out:CO2 Operational/m2": 46.056578,
    "out:Total CO2/m2": 57.068575,
    "out:Total CO2 (tons)": 137763.4764,
    "out:Klimatpaverkan":0.001* 3988.923137,
    "out:Initial Cost/MSEK": 2.00925,
    "out:Running cost/(Apt SEK y)": 28016.9,
    "out:Running Cost over RSP/MSEK": 6.57139,
    "out:LCP/MSEK": -0.71833,
    "out:ROI% old": 9.178407,
    "out:Payback discounted": 13,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 18.8,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 8,
    "out:Return/kSEK/y": 165,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 61343.42329,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 55822.52,
    "out:DH kr savings": 55822.51519,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 206792.3361,
    "out:% savings (space heating)": 18.09979,
    "out:Total_Water Heating (DH)": 88642.03902,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.66,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2009250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.457322,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 140.2,
    "out:Primary Energy": 105.780125,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.700481,
    "out:CO2 Operational/m2": 46.776546,
    "out:Total CO2/m2": 57.477027,
    "out:Total CO2 (tons)": 138749.479,
    "out:Klimatpaverkan":0.001* 4974.92581,
    "out:Initial Cost/MSEK": 1.937,
    "out:Running cost/(Apt SEK y)": 28439.86667,
    "out:Running Cost over RSP/MSEK": 6.67069,
    "out:LCP/MSEK": -0.74538,
    "out:ROI% old": 8.788406,
    "out:Payback discounted": 14,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.1,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 8,
    "out:Return/kSEK/y": 152,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 57400.12074,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 52234.11,
    "out:DH kr savings": 52234.10988,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 215949.8762,
    "out:% savings (space heating)": 14.472941,
    "out:Total_Water Heating (DH)": 83427.80143,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.46,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1937000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.663808,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 136.2,
    "out:Primary Energy": 103.124666,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.700481,
    "out:CO2 Operational/m2": 45.33661,
    "out:Total CO2/m2": 56.037091,
    "out:Total CO2 (tons)": 135273.4751,
    "out:Klimatpaverkan":0.001* 1498.921913,
    "out:Initial Cost/MSEK": 1.997375,
    "out:Running cost/(Apt SEK y)": 27593.93333,
    "out:Running Cost over RSP/MSEK": 6.47208,
    "out:LCP/MSEK": -0.607145,
    "out:ROI% old": 9.943265,
    "out:Payback discounted": 12,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 9,
    "out:Return/kSEK/y": 178,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 66557.66088,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 60567.47,
    "out:DH kr savings": 60567.4714,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 206792.3361,
    "out:% savings (space heating)": 18.09979,
    "out:Total_Water Heating (DH)": 83427.80143,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.66,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1997375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.457322,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 135.2,
    "out:Total Energy Use Post PV": 135.2,
    "out:Primary Energy": 103.680125,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.011997,
    "out:CO2 Operational/m2": 44.976626,
    "out:Total CO2/m2": 55.988623,
    "out:Total CO2 (tons)": 135156.4734,
    "out:Klimatpaverkan":0.001* 1381.920215,
    "out:Initial Cost/MSEK": 2.028125,
    "out:Running cost/(Apt SEK y)": 27382.46667,
    "out:Running Cost over RSP/MSEK": 6.42243,
    "out:LCP/MSEK": -0.588245,
    "out:ROI% old": 10.142232,
    "out:Payback discounted": 12,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 21.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 9,
    "out:Return/kSEK/y": 184,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 69914.29096,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 63622,
    "out:DH kr savings": 63622.00477,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 215949.8762,
    "out:% savings (space heating)": 14.472941,
    "out:Total_Water Heating (DH)": 70913.63122,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.46,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2028125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.663808,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 131.2,
    "out:Primary Energy": 101.024666,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.011997,
    "out:CO2 Operational/m2": 43.53669,
    "out:Total CO2/m2": 54.548687,
    "out:Total CO2 (tons)": 131680.4695,
    "out:Klimatpaverkan":0.001* -2094.083682,
    "out:Initial Cost/MSEK": 2.0885,
    "out:Running cost/(Apt SEK y)": 26536.53333,
    "out:Running Cost over RSP/MSEK": 6.22383,
    "out:LCP/MSEK": -0.45002,
    "out:ROI% old": 11.207497,
    "out:Payback discounted": 11,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 10,
    "out:Return/kSEK/y": 209,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 79071.83109,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71955.37,
    "out:DH kr savings": 71955.36629,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 206792.3361,
    "out:% savings (space heating)": 18.09979,
    "out:Total_Water Heating (DH)": 70913.63122,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.66,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2088500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.149141,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.471676,
    "out:Electricity (inc PV)": 37.949545,
    "out:Total Energy Use Pre PV": 100.471676,
    "out:Total Energy Use Post PV": 97.949545,
    "out:Primary Energy": 103.499703,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.766054,
    "out:CO2 Operational/m2": 26.941625,
    "out:Total CO2/m2": 41.707679,
    "out:Total CO2 (tons)": 100682.2906,
    "out:Klimatpaverkan":0.001* -33092.26266,
    "out:Initial Cost/MSEK": 3.193625,
    "out:Running cost/(Apt SEK y)": 18225.2,
    "out:Running Cost over RSP/MSEK": 4.26306,
    "out:LCP/MSEK": 0.405625,
    "out:ROI% old": 16.100155,
    "out:Payback discounted": 8,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 67.3,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2833.01732,
    "out:Return %": 14,
    "out:Return/kSEK/y": 459,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 21.972176,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 211577.8391,
    "out:EL kWh savings": -52503.37736,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 103280.09,
    "out:DH kr savings": 192535.8336,
    "out:El kr savings": -89255.74151,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 145199.9592,
    "out:% savings (space heating)": 42.493482,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 50.27,
    "out:Etvv": 0,
    "out:Ef": 37.95,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3193625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.457881,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.44069,
    "out:Electricity (inc PV)": 37.899936,
    "out:Total Energy Use Pre PV": 96.44069,
    "out:Total Energy Use Post PV": 93.899936,
    "out:Primary Energy": 100.869543,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.766054,
    "out:CO2 Operational/m2": 25.497599,
    "out:Total CO2/m2": 40.263653,
    "out:Total CO2 (tons)": 97196.41341,
    "out:Klimatpaverkan":0.001* -36578.13982,
    "out:Initial Cost/MSEK": 3.254,
    "out:Running cost/(Apt SEK y)": 17375.03333,
    "out:Running Cost over RSP/MSEK": 4.06347,
    "out:LCP/MSEK": 0.54484,
    "out:ROI% old": 16.677671,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 74.5,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2830.8483,
    "out:Return %": 15,
    "out:Return/kSEK/y": 484,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 21.94119,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 220488.5366,
    "out:EL kWh savings": -52383.62129,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111592.41,
    "out:DH kr savings": 200644.5683,
    "out:El kr savings": -89052.15619,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 136289.2617,
    "out:% savings (space heating)": 46.022568,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 46.64,
    "out:Etvv": 0,
    "out:Ef": 37.9,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3254000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.57426,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.591337,
    "out:Electricity (inc PV)": 37.411419,
    "out:Total Energy Use Pre PV": 96.591337,
    "out:Total Energy Use Post PV": 94.411419,
    "out:Primary Energy": 101.149147,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.07757,
    "out:CO2 Operational/m2": 25.745461,
    "out:Total CO2/m2": 40.823031,
    "out:Total CO2 (tons)": 98546.75128,
    "out:Klimatpaverkan":0.001* -35227.80195,
    "out:Initial Cost/MSEK": 3.28475,
    "out:Running cost/(Apt SEK y)": 17470.33333,
    "out:Running Cost over RSP/MSEK": 4.08617,
    "out:LCP/MSEK": 0.49139,
    "out:ROI% old": 16.42282,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 74.5,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2771.39359,
    "out:Return %": 15,
    "out:Return/kSEK/y": 481,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 21.091837,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 220207.5979,
    "out:EL kWh savings": -51204.34179,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113341.53,
    "out:DH kr savings": 200388.9141,
    "out:El kr savings": -87047.38105,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 136570.2005,
    "out:% savings (space heating)": 45.911302,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.3,
    "out:Etvv": 0,
    "out:Ef": 37.41,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3284750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.904925,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.553707,
    "out:Electricity (inc PV)": 37.356676,
    "out:Total Energy Use Pre PV": 92.553707,
    "out:Total Energy Use Post PV": 90.356676,
    "out:Primary Energy": 98.521606,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.07757,
    "out:CO2 Operational/m2": 24.300558,
    "out:Total CO2/m2": 39.378128,
    "out:Total CO2 (tons)": 95058.75705,
    "out:Klimatpaverkan":0.001* -38715.79618,
    "out:Initial Cost/MSEK": 3.345,
    "out:Running cost/(Apt SEK y)": 16619.23333,
    "out:Running Cost over RSP/MSEK": 3.88637,
    "out:LCP/MSEK": 0.63094,
    "out:ROI% old": 16.980312,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 82.2,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2768.75949,
    "out:Return %": 15,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 21.054207,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 229065.3685,
    "out:EL kWh savings": -51072.19225,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121626.76,
    "out:DH kr savings": 208449.4853,
    "out:El kr savings": -86822.72683,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 127712.4299,
    "out:% savings (space heating)": 49.419427,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 44.69,
    "out:Etvv": 0,
    "out:Ef": 37.36,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3345000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.39869,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.293025,
    "out:Electricity (inc PV)": 37.230408,
    "out:Total Energy Use Pre PV": 94.293025,
    "out:Total Energy Use Post PV": 92.230408,
    "out:Primary Energy": 100.366464,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.766054,
    "out:CO2 Operational/m2": 24.986114,
    "out:Total CO2/m2": 39.752168,
    "out:Total CO2 (tons)": 95961.68919,
    "out:Klimatpaverkan":0.001* -37812.86404,
    "out:Initial Cost/MSEK": 3.27275,
    "out:Running cost/(Apt SEK y)": 17006.56667,
    "out:Running Cost over RSP/MSEK": 3.97741,
    "out:LCP/MSEK": 0.61215,
    "out:ROI% old": 16.957779,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 78.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2750.51175,
    "out:Return %": 15,
    "out:Return/kSEK/y": 495,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 20.793525,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 223045.4225,
    "out:EL kWh savings": -50767.38144,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116666.79,
    "out:DH kr savings": 202971.3345,
    "out:El kr savings": -86304.54845,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 133732.3758,
    "out:% savings (space heating)": 47.035224,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 47.65,
    "out:Etvv": 0,
    "out:Ef": 37.23,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3272750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.736787,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.253143,
    "out:Electricity (inc PV)": 37.174244,
    "out:Total Energy Use Pre PV": 91.253143,
    "out:Total Energy Use Post PV": 89.174244,
    "out:Primary Energy": 97.739645,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.766054,
    "out:CO2 Operational/m2": 23.900897,
    "out:Total CO2/m2": 38.666951,
    "out:Total CO2 (tons)": 93341.97656,
    "out:Klimatpaverkan":0.001* -40432.57666,
    "out:Initial Cost/MSEK": 3.333125,
    "out:Running cost/(Apt SEK y)": 16366.66667,
    "out:Running Cost over RSP/MSEK": 3.82719,
    "out:LCP/MSEK": 0.701995,
    "out:ROI% old": 17.294452,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2747.72001,
    "out:Return %": 15,
    "out:Return/kSEK/y": 514,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 20.753643,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231885.2523,
    "out:EL kWh savings": -50631.80161,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124941.52,
    "out:DH kr savings": 211015.5796,
    "out:El kr savings": -86074.06273,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 124892.5461,
    "out:% savings (space heating)": 50.536243,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 44.04,
    "out:Etvv": 0,
    "out:Ef": 37.17,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3333125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.60485,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.568734,
    "out:Electricity (inc PV)": 36.793502,
    "out:Total Energy Use Pre PV": 91.568734,
    "out:Total Energy Use Post PV": 89.793502,
    "out:Primary Energy": 98.489287,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.07757,
    "out:CO2 Operational/m2": 24.170533,
    "out:Total CO2/m2": 39.248103,
    "out:Total CO2 (tons)": 94744.87684,
    "out:Klimatpaverkan":0.001* -39029.67638,
    "out:Initial Cost/MSEK": 3.363875,
    "out:Running cost/(Apt SEK y)": 16484.5,
    "out:Running Cost over RSP/MSEK": 3.85512,
    "out:LCP/MSEK": 0.643315,
    "out:ROI% old": 17.017746,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 82.2,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2699.81138,
    "out:Return %": 15,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 20.069234,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 229789.7492,
    "out:EL kWh savings": -49712.69085,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124597.1,
    "out:DH kr savings": 209108.6718,
    "out:El kr savings": -84511.57444,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 126988.0492,
    "out:% savings (space heating)": 49.706318,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 46.09,
    "out:Etvv": 0,
    "out:Ef": 36.79,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3363875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.956994,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.524595,
    "out:Electricity (inc PV)": 36.733047,
    "out:Total Energy Use Pre PV": 87.524595,
    "out:Total Energy Use Post PV": 85.733047,
    "out:Primary Energy": 95.864657,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.07757,
    "out:CO2 Operational/m2": 22.724771,
    "out:Total CO2/m2": 37.802341,
    "out:Total CO2 (tons)": 91254.80899,
    "out:Klimatpaverkan":0.001* -42519.74424,
    "out:Initial Cost/MSEK": 3.42425,
    "out:Running cost/(Apt SEK y)": 15632.53333,
    "out:Running Cost over RSP/MSEK": 3.65512,
    "out:LCP/MSEK": 0.78294,
    "out:ROI% old": 17.552081,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 90.7,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2696.72165,
    "out:Return %": 16,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 20.025095,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 238595.6695,
    "out:EL kWh savings": -49566.75254,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132858.58,
    "out:DH kr savings": 217122.0592,
    "out:El kr savings": -84263.47932,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 118182.1289,
    "out:% savings (space heating)": 53.193908,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 42.49,
    "out:Etvv": 0,
    "out:Ef": 36.73,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3424250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.644187,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.867117,
    "out:Electricity (inc PV)": 50.740641,
    "out:Total Energy Use Pre PV": 67.867117,
    "out:Total Energy Use Post PV": 63.740641,
    "out:Primary Energy": 96.976244,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.363693,
    "out:CO2 Operational/m2": 11.92269,
    "out:Total CO2/m2": 27.286383,
    "out:Total CO2 (tons)": 65869.29811,
    "out:Klimatpaverkan":0.001* -67905.25511,
    "out:Initial Cost/MSEK": 4.5195,
    "out:Running cost/(Apt SEK y)": 10254.73333,
    "out:Running Cost over RSP/MSEK": 2.38616,
    "out:LCP/MSEK": 0.95665,
    "out:ROI% old": 17.309595,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 156.2,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3840.69819,
    "out:Return %": 15,
    "out:Return/kSEK/y": 698,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 36.367617,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 326254.7451,
    "out:EL kWh savings": -83381.06883,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155144,
    "out:DH kr savings": 296891.818,
    "out:El kr savings": -141747.817,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 30523.05331,
    "out:% savings (space heating)": 87.911329,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 8.06,
    "out:Etvv": 0,
    "out:Ef": 50.74,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4519500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.268576,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.437449,
    "out:Electricity (inc PV)": 50.158695,
    "out:Total Energy Use Pre PV": 64.437449,
    "out:Total Energy Use Post PV": 60.158695,
    "out:Primary Energy": 94.617577,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.363693,
    "out:CO2 Operational/m2": 10.786019,
    "out:Total CO2/m2": 26.149712,
    "out:Total CO2 (tons)": 63125.37559,
    "out:Klimatpaverkan":0.001* -70649.17764,
    "out:Initial Cost/MSEK": 4.579875,
    "out:Running cost/(Apt SEK y)": 9561.5,
    "out:Running Cost over RSP/MSEK": 2.22357,
    "out:LCP/MSEK": 1.058865,
    "out:ROI% old": 17.588565,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 173.3,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3810.62143,
    "out:Return %": 16,
    "out:Return/kSEK/y": 719,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.937949,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 331989.4674,
    "out:EL kWh savings": -81976.25183,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162750.79,
    "out:DH kr savings": 302110.4153,
    "out:El kr savings": -139359.6281,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 24788.331,
    "out:% savings (space heating)": 90.182569,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 6.19,
    "out:Etvv": 0,
    "out:Ef": 50.16,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4579875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.889542,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.435191,
    "out:Electricity (inc PV)": 49.843253,
    "out:Total Energy Use Pre PV": 64.435191,
    "out:Total Energy Use Post PV": 60.843253,
    "out:Primary Energy": 94.808912,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.675209,
    "out:CO2 Operational/m2": 11.013697,
    "out:Total CO2/m2": 26.688906,
    "out:Total CO2 (tons)": 64426.9893,
    "out:Klimatpaverkan":0.001* -69347.56393,
    "out:Initial Cost/MSEK": 4.610625,
    "out:Running cost/(Apt SEK y)": 9635.866667,
    "out:Running Cost over RSP/MSEK": 2.24143,
    "out:LCP/MSEK": 1.010255,
    "out:ROI% old": 17.415922,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 168.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3740.46337,
    "out:Return %": 16,
    "out:Return/kSEK/y": 716,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.935691,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 330490.4561,
    "out:EL kWh savings": -81214.7752,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162681.2,
    "out:DH kr savings": 300746.3151,
    "out:El kr savings": -138065.1178,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 26287.34224,
    "out:% savings (space heating)": 89.588885,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 7.27,
    "out:Etvv": 0,
    "out:Ef": 49.84,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4610625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.651772,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.963754,
    "out:Electricity (inc PV)": 49.218759,
    "out:Total Energy Use Pre PV": 61.963754,
    "out:Total Energy Use Post PV": 58.218759,
    "out:Primary Energy": 92.471943,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.675209,
    "out:CO2 Operational/m2": 10.231495,
    "out:Total CO2/m2": 25.906704,
    "out:Total CO2 (tons)": 62538.75454,
    "out:Klimatpaverkan":0.001* -71235.79868,
    "out:Initial Cost/MSEK": 4.670875,
    "out:Running cost/(Apt SEK y)": 9148.433333,
    "out:Running Cost over RSP/MSEK": 2.12717,
    "out:LCP/MSEK": 1.064265,
    "out:ROI% old": 17.540733,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 182.8,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3707.46278,
    "out:Return %": 16,
    "out:Return/kSEK/y": 731,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.464254,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 335892.4304,
    "out:EL kWh savings": -79707.24738,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170159.79,
    "out:DH kr savings": 305662.1117,
    "out:El kr savings": -135502.3205,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 20885.36795,
    "out:% savings (space heating)": 91.728339,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 5.54,
    "out:Etvv": 0,
    "out:Ef": 49.22,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4670875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.333527,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.949135,
    "out:Electricity (inc PV)": 49.540002,
    "out:Total Energy Use Pre PV": 62.949135,
    "out:Total Energy Use Post PV": 59.540002,
    "out:Primary Energy": 94.088572,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.363693,
    "out:CO2 Operational/m2": 10.589549,
    "out:Total CO2/m2": 25.953242,
    "out:Total CO2 (tons)": 62651.09722,
    "out:Klimatpaverkan":0.001* -71123.456,
    "out:Initial Cost/MSEK": 4.598625,
    "out:Running cost/(Apt SEK y)": 9357.9,
    "out:Running Cost over RSP/MSEK": 2.17636,
    "out:LCP/MSEK": 1.087325,
    "out:ROI% old": 17.66351,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 173.3,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3706.43945,
    "out:Return %": 16,
    "out:Return/kSEK/y": 725,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 34.449635,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 331832.6757,
    "out:EL kWh savings": -80482.72762,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165147.1,
    "out:DH kr savings": 301967.7349,
    "out:El kr savings": -136820.637,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 24945.12265,
    "out:% savings (space heating)": 90.120471,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 7.02,
    "out:Etvv": 0,
    "out:Ef": 49.54,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4598625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.153315,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.460255,
    "out:Electricity (inc PV)": 48.900906,
    "out:Total Energy Use Pre PV": 60.460255,
    "out:Total Energy Use Post PV": 56.900906,
    "out:Primary Energy": 91.759049,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.363693,
    "out:CO2 Operational/m2": 9.805045,
    "out:Total CO2/m2": 25.168738,
    "out:Total CO2 (tons)": 60757.30544,
    "out:Klimatpaverkan":0.001* -73017.24778,
    "out:Initial Cost/MSEK": 4.659,
    "out:Running cost/(Apt SEK y)": 8868.066667,
    "out:Running Cost over RSP/MSEK": 2.06155,
    "out:LCP/MSEK": 1.14176,
    "out:ROI% old": 17.786649,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 187.7,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3672.21785,
    "out:Return %": 16,
    "out:Return/kSEK/y": 739,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 33.960755,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 337095.7051,
    "out:EL kWh savings": -78939.95059,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172559.18,
    "out:DH kr savings": 306757.0916,
    "out:El kr savings": -134197.916,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 19682.09331,
    "out:% savings (space heating)": 92.204897,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 5.34,
    "out:Etvv": 0,
    "out:Ef": 48.9,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4659000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.052632,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.766376,
    "out:Electricity (inc PV)": 48.802685,
    "out:Total Energy Use Pre PV": 60.766376,
    "out:Total Energy Use Post PV": 57.802685,
    "out:Primary Energy": 92.364601,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.675209,
    "out:CO2 Operational/m2": 10.073432,
    "out:Total CO2/m2": 25.748641,
    "out:Total CO2 (tons)": 62157.19064,
    "out:Klimatpaverkan":0.001* -71617.36259,
    "out:Initial Cost/MSEK": 4.68975,
    "out:Running cost/(Apt SEK y)": 8984.633333,
    "out:Running Cost over RSP/MSEK": 2.08918,
    "out:LCP/MSEK": 1.08338,
    "out:ROI% old": 17.58586,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 182.8,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3623.64632,
    "out:Return %": 16,
    "out:Return/kSEK/y": 736,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 33.266876,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 334924.7548,
    "out:EL kWh savings": -78702.84521,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 170986.69,
    "out:DH kr savings": 304781.5269,
    "out:El kr savings": -133794.8368,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 21853.04355,
    "out:% savings (space heating)": 91.345091,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 6.46,
    "out:Etvv": 0,
    "out:Ef": 48.8,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4689750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.035571,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.228057,
    "out:Electricity (inc PV)": 48.128509,
    "out:Total Energy Use Pre PV": 58.228057,
    "out:Total Energy Use Post PV": 55.128509,
    "out:Primary Energy": 90.052971,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.675209,
    "out:CO2 Operational/m2": 9.282401,
    "out:Total CO2/m2": 24.95761,
    "out:Total CO2 (tons)": 60247.64269,
    "out:Klimatpaverkan":0.001* -73526.91054,
    "out:Initial Cost/MSEK": 4.750125,
    "out:Running cost/(Apt SEK y)": 8488.033333,
    "out:Running Cost over RSP/MSEK": 1.9728,
    "out:LCP/MSEK": 1.139385,
    "out:ROI% old": 17.712346,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 198.2,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3585.96399,
    "out:Return %": 16,
    "out:Return/kSEK/y": 751,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.728557,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 339793.9378,
    "out:EL kWh savings": -77075.38509,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178184.33,
    "out:DH kr savings": 309212.4834,
    "out:El kr savings": -131028.1547,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 16983.86054,
    "out:% savings (space heating)": 93.273533,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.89,
    "out:Etvv": 0,
    "out:Ef": 48.13,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4750125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.967308,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 142.2,
    "out:Primary Energy": 104.737116,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.125278,
    "out:CO2 Operational/m2": 47.496514,
    "out:Total CO2/m2": 60.621792,
    "out:Total CO2 (tons)": 146340.9382,
    "out:Klimatpaverkan":0.001* 12566.38501,
    "out:Initial Cost/MSEK": 2.282,
    "out:Running cost/(Apt SEK y)": 28862.83333,
    "out:Running Cost over RSP/MSEK": 6.76999,
    "out:LCP/MSEK": -1.18968,
    "out:ROI% old": 6.838112,
    "out:Payback discounted": 18,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 6,
    "out:Return/kSEK/y": 140,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 52210.05766,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 47511.15,
    "out:DH kr savings": 47511.15247,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 200282.9889,
    "out:% savings (space heating)": 20.67782,
    "out:Total_Water Heating (DH)": 104284.7518,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.97,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2282000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.468794,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 139.2,
    "out:Total Energy Use Post PV": 139.2,
    "out:Primary Energy": 102.288156,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.125278,
    "out:CO2 Operational/m2": 46.416562,
    "out:Total CO2/m2": 59.54184,
    "out:Total CO2 (tons)": 143733.9353,
    "out:Klimatpaverkan":0.001* 9959.382087,
    "out:Initial Cost/MSEK": 2.342375,
    "out:Running cost/(Apt SEK y)": 28228.4,
    "out:Running Cost over RSP/MSEK": 6.62104,
    "out:LCP/MSEK": -1.101105,
    "out:ROI% old": 7.570277,
    "out:Payback discounted": 16,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 18,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 7,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 60655.46656,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 55196.47,
    "out:DH kr savings": 55196.47457,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 191837.58,
    "out:% savings (space heating)": 24.022628,
    "out:Total_Water Heating (DH)": 104284.7518,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.47,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2342375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.967308,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 136.2,
    "out:Primary Energy": 102.112116,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.436794,
    "out:CO2 Operational/m2": 45.33661,
    "out:Total CO2/m2": 58.773404,
    "out:Total CO2 (tons)": 141878.9317,
    "out:Klimatpaverkan":0.001* 8104.378441,
    "out:Initial Cost/MSEK": 2.373125,
    "out:Running cost/(Apt SEK y)": 27593.93333,
    "out:Running Cost over RSP/MSEK": 6.47208,
    "out:LCP/MSEK": -0.982895,
    "out:ROI% old": 8.368893,
    "out:Payback discounted": 14,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 7,
    "out:Return/kSEK/y": 178,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 67852.77043,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 61746.02,
    "out:DH kr savings": 61746.02109,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 200282.9889,
    "out:% savings (space heating)": 20.67782,
    "out:Total_Water Heating (DH)": 88642.03902,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.97,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2373125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.468794,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 132.2,
    "out:Primary Energy": 99.663156,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.436794,
    "out:CO2 Operational/m2": 43.896674,
    "out:Total CO2/m2": 57.333468,
    "out:Total CO2 (tons)": 138402.9278,
    "out:Klimatpaverkan":0.001* 4628.374544,
    "out:Initial Cost/MSEK": 2.433375,
    "out:Running cost/(Apt SEK y)": 26748,
    "out:Running Cost over RSP/MSEK": 6.27348,
    "out:LCP/MSEK": -0.844545,
    "out:ROI% old": 9.327609,
    "out:Payback discounted": 13,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 24.2,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 8,
    "out:Return/kSEK/y": 203,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 76298.17932,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 69431.34,
    "out:DH kr savings": 69431.34318,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 191837.58,
    "out:% savings (space heating)": 24.022628,
    "out:Total_Water Heating (DH)": 88642.03902,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.47,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2433375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.967308,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 134.2,
    "out:Primary Energy": 101.237116,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.125278,
    "out:CO2 Operational/m2": 44.616642,
    "out:Total CO2/m2": 57.74192,
    "out:Total CO2 (tons)": 139388.9304,
    "out:Klimatpaverkan":0.001* 5614.377216,
    "out:Initial Cost/MSEK": 2.361125,
    "out:Running cost/(Apt SEK y)": 27170.96667,
    "out:Running Cost over RSP/MSEK": 6.37278,
    "out:LCP/MSEK": -0.871595,
    "out:ROI% old": 9.012229,
    "out:Payback discounted": 13,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 22.4,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 8,
    "out:Return/kSEK/y": 190,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 73067.00802,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 66490.98,
    "out:DH kr savings": 66490.9773,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 200282.9889,
    "out:% savings (space heating)": 20.67782,
    "out:Total_Water Heating (DH)": 83427.80143,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.97,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2361125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.468794,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 130.2,
    "out:Primary Energy": 98.788156,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.125278,
    "out:CO2 Operational/m2": 43.176706,
    "out:Total CO2/m2": 56.301984,
    "out:Total CO2 (tons)": 135912.9265,
    "out:Klimatpaverkan":0.001* 2138.373319,
    "out:Initial Cost/MSEK": 2.4215,
    "out:Running cost/(Apt SEK y)": 26325.03333,
    "out:Running Cost over RSP/MSEK": 6.17418,
    "out:LCP/MSEK": -0.73337,
    "out:ROI% old": 9.959175,
    "out:Payback discounted": 12,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 9,
    "out:Return/kSEK/y": 216,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 81512.41691,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 74176.3,
    "out:DH kr savings": 74176.29939,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 191837.58,
    "out:% savings (space heating)": 24.022628,
    "out:Total_Water Heating (DH)": 83427.80143,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.47,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2421500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.967308,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 128.2,
    "out:Total Energy Use Post PV": 128.2,
    "out:Primary Energy": 99.137116,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.436794,
    "out:CO2 Operational/m2": 42.456738,
    "out:Total CO2/m2": 55.893532,
    "out:Total CO2 (tons)": 134926.9239,
    "out:Klimatpaverkan":0.001* 1152.370647,
    "out:Initial Cost/MSEK": 2.45225,
    "out:Running cost/(Apt SEK y)": 25902.06667,
    "out:Running Cost over RSP/MSEK": 6.07488,
    "out:LCP/MSEK": -0.66482,
    "out:ROI% old": 10.41277,
    "out:Payback discounted": 12,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 9,
    "out:Return/kSEK/y": 228,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 85581.17824,
    "out:EL kWh savings": 2.57e-11,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77878.87,
    "out:DH kr savings": 77878.8722,
    "out:El kr savings": 4.37e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 200282.9889,
    "out:% savings (space heating)": 20.67782,
    "out:Total_Water Heating (DH)": 70913.63122,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.97,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2452250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.468794,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 125.2,
    "out:Total Energy Use Post PV": 125.2,
    "out:Primary Energy": 96.688156,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.436794,
    "out:CO2 Operational/m2": 41.376786,
    "out:Total CO2/m2": 54.81358,
    "out:Total CO2 (tons)": 132319.9209,
    "out:Klimatpaverkan":0.001* -1454.632276,
    "out:Initial Cost/MSEK": 2.512625,
    "out:Running cost/(Apt SEK y)": 25267.63333,
    "out:Running Cost over RSP/MSEK": 5.92592,
    "out:LCP/MSEK": -0.576235,
    "out:ROI% old": 11.009488,
    "out:Payback discounted": 11,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 31.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 10,
    "out:Return/kSEK/y": 247,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 94026.58713,
    "out:EL kWh savings": -8.58e-12,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85564.19,
    "out:DH kr savings": 85564.19429,
    "out:El kr savings": -1.46e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 191837.58,
    "out:% savings (space heating)": 24.022628,
    "out:Total_Water Heating (DH)": 70913.63122,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.47,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2512625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.961973,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.379905,
    "out:Electricity (inc PV)": 37.805547,
    "out:Total Energy Use Pre PV": 94.379905,
    "out:Total Energy Use Post PV": 91.805547,
    "out:Primary Energy": 99.030133,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.190851,
    "out:CO2 Operational/m2": 24.769606,
    "out:Total CO2/m2": 41.960457,
    "out:Total CO2 (tons)": 101292.4964,
    "out:Klimatpaverkan":0.001* -32482.05685,
    "out:Initial Cost/MSEK": 3.61775,
    "out:Running cost/(Apt SEK y)": 16943.73333,
    "out:Running Cost over RSP/MSEK": 3.96224,
    "out:LCP/MSEK": 0.28232,
    "out:ROI% old": 15.400535,
    "out:Payback discounted": 8,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 78.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 2826.59335,
    "out:Return %": 14,
    "out:Return/kSEK/y": 497,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 21.880405,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226513.6558,
    "out:EL kWh savings": -52155.76635,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117462.62,
    "out:DH kr savings": 206127.4268,
    "out:El kr savings": -88664.80279,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 130264.1426,
    "out:% savings (space heating)": 48.40882,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 44.26,
    "out:Etvv": 0,
    "out:Ef": 37.81,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3617750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.570925,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.347339,
    "out:Electricity (inc PV)": 37.755285,
    "out:Total Energy Use Pre PV": 91.347339,
    "out:Total Energy Use Post PV": 88.755285,
    "out:Primary Energy": 96.606806,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.190851,
    "out:CO2 Operational/m2": 23.685356,
    "out:Total CO2/m2": 40.876207,
    "out:Total CO2 (tons)": 98675.11808,
    "out:Klimatpaverkan":0.001* -35099.43514,
    "out:Initial Cost/MSEK": 3.678125,
    "out:Running cost/(Apt SEK y)": 16304.83333,
    "out:Running Cost over RSP/MSEK": 3.81225,
    "out:LCP/MSEK": 0.371935,
    "out:ROI% old": 15.730297,
    "out:Payback discounted": 8,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2824.31373,
    "out:Return %": 14,
    "out:Return/kSEK/y": 516,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 21.847839,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 234699.6419,
    "out:EL kWh savings": -52034.43393,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 125118.14,
    "out:DH kr savings": 213576.6741,
    "out:El kr savings": -88458.53769,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 122078.1565,
    "out:% savings (space heating)": 51.650884,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 40.92,
    "out:Etvv": 0,
    "out:Ef": 37.76,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3678125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.45519,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.47893,
    "out:Electricity (inc PV)": 37.256751,
    "out:Total Energy Use Pre PV": 89.47893,
    "out:Total Energy Use Post PV": 87.256751,
    "out:Primary Energy": 96.685018,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.502367,
    "out:CO2 Operational/m2": 23.210735,
    "out:Total CO2/m2": 40.713102,
    "out:Total CO2 (tons)": 98281.38279,
    "out:Klimatpaverkan":0.001* -35493.17043,
    "out:Initial Cost/MSEK": 3.708875,
    "out:Running cost/(Apt SEK y)": 15974.56667,
    "out:Running Cost over RSP/MSEK": 3.73505,
    "out:LCP/MSEK": 0.418385,
    "out:ROI% old": 15.897235,
    "out:Payback discounted": 8,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 88.5,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2763.5251,
    "out:Return %": 14,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 20.97943,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 234979.026,
    "out:EL kWh savings": -50830.97342,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127418.26,
    "out:DH kr savings": 213830.9137,
    "out:El kr savings": -86412.6548,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 121798.7724,
    "out:% savings (space heating)": 51.761534,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 42.32,
    "out:Etvv": 0,
    "out:Ef": 37.26,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3708875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.080619,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.441371,
    "out:Electricity (inc PV)": 37.199409,
    "out:Total Energy Use Pre PV": 86.441371,
    "out:Total Energy Use Post PV": 84.199409,
    "out:Primary Energy": 94.265302,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.502367,
    "out:CO2 Operational/m2": 22.125825,
    "out:Total CO2/m2": 39.628192,
    "out:Total CO2 (tons)": 95662.41126,
    "out:Klimatpaverkan":0.001* -38112.14196,
    "out:Initial Cost/MSEK": 3.769125,
    "out:Running cost/(Apt SEK y)": 15335,
    "out:Running Cost over RSP/MSEK": 3.5849,
    "out:LCP/MSEK": 0.508285,
    "out:ROI% old": 16.212213,
    "out:Payback discounted": 8,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 95.2,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2760.89597,
    "out:Return %": 14,
    "out:Return/kSEK/y": 545,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 20.941871,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 243125.2366,
    "out:EL kWh savings": -50692.54989,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135066.63,
    "out:DH kr savings": 221243.9654,
    "out:El kr savings": -86177.33481,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 113652.5617,
    "out:% savings (space heating)": 54.987845,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 39.01,
    "out:Etvv": 0,
    "out:Ef": 37.2,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3769125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.297419,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.175224,
    "out:Electricity (inc PV)": 37.071727,
    "out:Total Energy Use Pre PV": 88.175224,
    "out:Total Energy Use Post PV": 86.071727,
    "out:Primary Energy": 95.90438,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.190851,
    "out:CO2 Operational/m2": 22.810659,
    "out:Total CO2/m2": 40.00151,
    "out:Total CO2 (tons)": 96563.6005,
    "out:Klimatpaverkan":0.001* -37210.95273,
    "out:Initial Cost/MSEK": 3.696875,
    "out:Running cost/(Apt SEK y)": 15721.53333,
    "out:Running Cost over RSP/MSEK": 3.67576,
    "out:LCP/MSEK": 0.489675,
    "out:ROI% old": 16.17795,
    "out:Payback discounted": 8,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 90.7,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2742.26568,
    "out:Return %": 14,
    "out:Return/kSEK/y": 534,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 20.675724,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 237773.8839,
    "out:EL kWh savings": -50384.32569,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130720.88,
    "out:DH kr savings": 216374.2344,
    "out:El kr savings": -85653.35366,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 119003.9145,
    "out:% savings (space heating)": 52.868439,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 41.68,
    "out:Etvv": 0,
    "out:Ef": 37.07,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3696875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.929925,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.135521,
    "out:Electricity (inc PV)": 37.011625,
    "out:Total Energy Use Pre PV": 85.135521,
    "out:Total Energy Use Post PV": 83.011625,
    "out:Primary Energy": 93.486073,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.190851,
    "out:CO2 Operational/m2": 21.725466,
    "out:Total CO2/m2": 38.916317,
    "out:Total CO2 (tons)": 93943.94581,
    "out:Klimatpaverkan":0.001* -39830.60742,
    "out:Initial Cost/MSEK": 3.75725,
    "out:Running cost/(Apt SEK y)": 15081.66667,
    "out:Running Cost over RSP/MSEK": 3.52555,
    "out:LCP/MSEK": 0.57951,
    "out:ROI% old": 16.489112,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 97.6,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2739.48647,
    "out:Return %": 15,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 20.636021,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 245903.0107,
    "out:EL kWh savings": -50239.23952,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138365.03,
    "out:DH kr savings": 223771.7397,
    "out:El kr savings": -85406.70719,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 110874.7877,
    "out:% savings (space heating)": 56.087984,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 38.38,
    "out:Etvv": 0,
    "out:Ef": 37.01,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3757250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.546459,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.437939,
    "out:Electricity (inc PV)": 36.622724,
    "out:Total Energy Use Pre PV": 85.437939,
    "out:Total Energy Use Post PV": 83.622724,
    "out:Primary Energy": 94.033372,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.502367,
    "out:CO2 Operational/m2": 21.993363,
    "out:Total CO2/m2": 39.49573,
    "out:Total CO2 (tons)": 95342.64814,
    "out:Klimatpaverkan":0.001* -38431.90508,
    "out:Initial Cost/MSEK": 3.788,
    "out:Running cost/(Apt SEK y)": 15197.73333,
    "out:Running Cost over RSP/MSEK": 3.55307,
    "out:LCP/MSEK": 0.52124,
    "out:ROI% old": 16.251471,
    "out:Payback discounted": 8,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 95.2,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2690.65573,
    "out:Return %": 15,
    "out:Return/kSEK/y": 550,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 19.938439,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 244414.6983,
    "out:EL kWh savings": -49300.43294,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138606.64,
    "out:DH kr savings": 222417.3754,
    "out:El kr savings": -83810.73601,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 112363.1001,
    "out:% savings (space heating)": 55.498537,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 40.16,
    "out:Etvv": 0,
    "out:Ef": 36.62,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3788000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.196582,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 38.392898,
    "out:Electricity (inc PV)": 36.556651,
    "out:Total Energy Use Pre PV": 81.392898,
    "out:Total Energy Use Post PV": 79.556651,
    "out:Primary Energy": 91.61811,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.502367,
    "out:CO2 Operational/m2": 20.547482,
    "out:Total CO2/m2": 38.049849,
    "out:Total CO2 (tons)": 91852.29302,
    "out:Klimatpaverkan":0.001* -41922.2602,
    "out:Initial Cost/MSEK": 3.848375,
    "out:Running cost/(Apt SEK y)": 14345.63333,
    "out:Running Cost over RSP/MSEK": 3.35304,
    "out:LCP/MSEK": 0.660895,
    "out:ROI% old": 16.73905,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 105,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2687.50286,
    "out:Return %": 15,
    "out:Return/kSEK/y": 575,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.8931,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 19.893398,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252501.2976,
    "out:EL kWh savings": -49140.9328,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146236.6,
    "out:DH kr savings": 229776.1808,
    "out:El kr savings": -83539.58575,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 104276.5007,
    "out:% savings (space heating)": 58.701239,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 36.88,
    "out:Etvv": 0,
    "out:Ef": 36.56,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3848375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.885823,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.039344,
    "out:Electricity (inc PV)": 49.63366,
    "out:Total Energy Use Pre PV": 63.039344,
    "out:Total Energy Use Post PV": 58.63366,
    "out:Primary Energy": 92.997795,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.78849,
    "out:CO2 Operational/m2": 10.373482,
    "out:Total CO2/m2": 28.161972,
    "out:Total CO2 (tons)": 67982.96898,
    "out:Klimatpaverkan":0.001* -65791.58425,
    "out:Initial Cost/MSEK": 4.943625,
    "out:Running cost/(Apt SEK y)": 9295.566667,
    "out:Running Cost over RSP/MSEK": 2.16129,
    "out:LCP/MSEK": 0.757395,
    "out:ROI% old": 16.474377,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 178,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3782.75408,
    "out:Return %": 15,
    "out:Return/kSEK/y": 727,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.539844,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 335327.4316,
    "out:EL kWh savings": -80708.81793,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167942.97,
    "out:DH kr savings": 305147.9627,
    "out:El kr savings": -137204.9905,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 21450.36681,
    "out:% savings (space heating)": 91.504571,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 5.22,
    "out:Etvv": 0,
    "out:Ef": 49.63,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4943625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.962364,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.562055,
    "out:Electricity (inc PV)": 48.961943,
    "out:Total Energy Use Pre PV": 60.562055,
    "out:Total Energy Use Post PV": 55.961943,
    "out:Primary Energy": 90.891409,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.78849,
    "out:CO2 Operational/m2": 9.590508,
    "out:Total CO2/m2": 27.378998,
    "out:Total CO2 (tons)": 66092.87062,
    "out:Klimatpaverkan":0.001* -67681.68261,
    "out:Initial Cost/MSEK": 5.004,
    "out:Running cost/(Apt SEK y)": 8807.3,
    "out:Running Cost over RSP/MSEK": 2.04685,
    "out:LCP/MSEK": 0.81146,
    "out:ROI% old": 16.602318,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 192.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3749.34385,
    "out:Return %": 15,
    "out:Return/kSEK/y": 741,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.062555,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 339970.6594,
    "out:EL kWh savings": -79087.29384,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174924.9,
    "out:DH kr savings": 309373.3001,
    "out:El kr savings": -134448.3995,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 16807.13893,
    "out:% savings (space heating)": 93.343524,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.94,
    "out:Etvv": 0,
    "out:Ef": 48.96,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5004000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.402663,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.52515,
    "out:Electricity (inc PV)": 48.664784,
    "out:Total Energy Use Pre PV": 59.52515,
    "out:Total Energy Use Post PV": 55.664784,
    "out:Primary Energy": 90.866921,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.100006,
    "out:CO2 Operational/m2": 9.453628,
    "out:Total CO2/m2": 27.553634,
    "out:Total CO2 (tons)": 66514.44173,
    "out:Klimatpaverkan":0.001* -67260.1115,
    "out:Initial Cost/MSEK": 5.03475,
    "out:Running cost/(Apt SEK y)": 8665.466667,
    "out:Running Cost over RSP/MSEK": 2.01395,
    "out:LCP/MSEK": 0.81361,
    "out:ROI% old": 16.59427,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 192.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3676.7605,
    "out:Return %": 15,
    "out:Return/kSEK/y": 745,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.02565,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 338907.7782,
    "out:EL kWh savings": -78369.95235,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175177.16,
    "out:DH kr savings": 308406.0781,
    "out:El kr savings": -133228.919,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 17870.02022,
    "out:% savings (space heating)": 92.922569,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.67,
    "out:Etvv": 0,
    "out:Ef": 48.66,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5034750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.693859,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.982814,
    "out:Electricity (inc PV)": 47.961181,
    "out:Total Energy Use Pre PV": 57.982814,
    "out:Total Energy Use Post PV": 53.961181,
    "out:Primary Energy": 88.7768,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.100006,
    "out:CO2 Operational/m2": 9.022051,
    "out:Total CO2/m2": 27.122057,
    "out:Total CO2 (tons)": 65472.61533,
    "out:Klimatpaverkan":0.001* -68301.93789,
    "out:Initial Cost/MSEK": 5.095,
    "out:Running cost/(Apt SEK y)": 8379.8,
    "out:Running Cost over RSP/MSEK": 1.9471,
    "out:LCP/MSEK": 0.82021,
    "out:ROI% old": 16.585476,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 203.7,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3638.79698,
    "out:Return %": 15,
    "out:Return/kSEK/y": 754,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 33.483314,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 343032.8291,
    "out:EL kWh savings": -76671.45549,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181818.4,
    "out:DH kr savings": 312159.8745,
    "out:El kr savings": -130341.4743,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 13744.96927,
    "out:% savings (space heating)": 94.556298,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.5,
    "out:Etvv": 0,
    "out:Ef": 47.96,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5095000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.949006,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.008076,
    "out:Electricity (inc PV)": 48.338158,
    "out:Total Energy Use Pre PV": 59.008076,
    "out:Total Energy Use Post PV": 55.338158,
    "out:Primary Energy": 90.1585,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.78849,
    "out:CO2 Operational/m2": 9.38537,
    "out:Total CO2/m2": 27.17386,
    "out:Total CO2 (tons)": 65597.66771,
    "out:Klimatpaverkan":0.001* -68176.88551,
    "out:Initial Cost/MSEK": 5.02275,
    "out:Running cost/(Apt SEK y)": 8594.733333,
    "out:Running Cost over RSP/MSEK": 1.99755,
    "out:LCP/MSEK": 0.84201,
    "out:ROI% old": 16.680561,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 198.2,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3640.56532,
    "out:Return %": 15,
    "out:Return/kSEK/y": 748,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 33.508576,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 340002.9056,
    "out:EL kWh savings": -77581.47755,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 177514.13,
    "out:DH kr savings": 309402.6441,
    "out:El kr savings": -131888.5118,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 16774.89273,
    "out:% savings (space heating)": 93.356295,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.5,
    "out:Etvv": 0,
    "out:Ef": 48.34,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5022750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.320007,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.441557,
    "out:Electricity (inc PV)": 47.624815,
    "out:Total Energy Use Pre PV": 56.441557,
    "out:Total Energy Use Post PV": 52.624815,
    "out:Primary Energy": 88.073345,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.78849,
    "out:CO2 Operational/m2": 8.590617,
    "out:Total CO2/m2": 26.379107,
    "out:Total CO2 (tons)": 63679.13486,
    "out:Klimatpaverkan":0.001* -70095.41837,
    "out:Initial Cost/MSEK": 5.083125,
    "out:Running cost/(Apt SEK y)": 8094.266667,
    "out:Running Cost over RSP/MSEK": 1.88027,
    "out:LCP/MSEK": 0.898915,
    "out:ROI% old": 16.812043,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 209.4,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3600.90899,
    "out:Return %": 15,
    "out:Return/kSEK/y": 763,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 32.942057,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 343935.3074,
    "out:EL kWh savings": -75859.46834,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184020.03,
    "out:DH kr savings": 312981.1297,
    "out:El kr savings": -128961.0962,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 12842.49096,
    "out:% savings (space heating)": 94.913725,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.36,
    "out:Etvv": 0,
    "out:Ef": 47.62,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5083125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.956633,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.737886,
    "out:Electricity (inc PV)": 47.546444,
    "out:Total Energy Use Pre PV": 56.737886,
    "out:Total Energy Use Post PV": 53.546444,
    "out:Primary Energy": 88.462274,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.100006,
    "out:CO2 Operational/m2": 8.857711,
    "out:Total CO2/m2": 26.957717,
    "out:Total CO2 (tons)": 65075.89875,
    "out:Klimatpaverkan":0.001* -68698.65447,
    "out:Initial Cost/MSEK": 5.113875,
    "out:Running cost/(Apt SEK y)": 8209.5,
    "out:Running Cost over RSP/MSEK": 1.9076,
    "out:LCP/MSEK": 0.840835,
    "out:ROI% old": 16.634604,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 203.7,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3551.65202,
    "out:Return %": 15,
    "out:Return/kSEK/y": 759,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.238386,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 342398.493,
    "out:EL kWh savings": -75670.28083,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182943.15,
    "out:DH kr savings": 311582.6286,
    "out:El kr savings": -128639.4774,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 14379.30541,
    "out:% savings (space heating)": 94.305069,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.11,
    "out:Etvv": 0,
    "out:Ef": 47.55,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5113875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.523844,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.111909,
    "out:Electricity (inc PV)": 46.811499,
    "out:Total Energy Use Pre PV": 55.111909,
    "out:Total Energy Use Post PV": 51.811499,
    "out:Primary Energy": 86.388136,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.100006,
    "out:CO2 Operational/m2": 8.415093,
    "out:Total CO2/m2": 26.515099,
    "out:Total CO2 (tons)": 64007.4194,
    "out:Klimatpaverkan":0.001* -69767.13383,
    "out:Initial Cost/MSEK": 5.17425,
    "out:Running cost/(Apt SEK y)": 7912.4,
    "out:Running Cost over RSP/MSEK": 1.83809,
    "out:LCP/MSEK": 0.84997,
    "out:ROI% old": 16.632417,
    "out:Payback discounted": 7,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 215.4,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3507.83363,
    "out:Return %": 15,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.286,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 31.612409,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 345857.244,
    "out:EL kWh savings": -73896.12442,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 189106.68,
    "out:DH kr savings": 314730.092,
    "out:El kr savings": -125623.4115,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 10920.55437,
    "out:% savings (space heating)": 95.674909,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.04,
    "out:Etvv": 0,
    "out:Ef": 46.81,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5174250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.67429,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 140.799,
    "out:Total Energy Use Post PV": 140.799,
    "out:Primary Energy": 108.610203,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.320033,
    "out:CO2 Operational/m2": 45.943714,
    "out:Total CO2/m2": 47.263747,
    "out:Total CO2 (tons)": 114094.6325,
    "out:Klimatpaverkan":0.001* -19679.92071,
    "out:Initial Cost/MSEK": 0.462998923,
    "out:Running cost/(Apt SEK y)": 28223.06667,
    "out:Running Cost over RSP/MSEK": 6.61799,
    "out:LCP/MSEK": 0.781321,
    "out:ROI% old": 38.393178,
    "out:Payback discounted": 3,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 16.3,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 34,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 67401.39454,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 42461.9,
    "out:DH kr savings": 61335.26903,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 185091.651,
    "out:% savings (space heating)": 26.694357,
    "out:Total_Water Heating (DH)": 104284.7518,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.67,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 462998.9226,
    "out:Seasonal Variation ROI (%)": -4,
    "out:Seasonal Variation Payback": -999
  },
  {
    "ID": "Z00000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.192684,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 135.799,
    "out:Total Energy Use Post PV": 135.799,
    "out:Primary Energy": 105.473079,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.320033,
    "out:CO2 Operational/m2": 44.143794,
    "out:Total CO2/m2": 45.463827,
    "out:Total CO2 (tons)": 109749.6276,
    "out:Klimatpaverkan":0.001* -24024.92558,
    "out:Initial Cost/MSEK": 0.523348898,
    "out:Running cost/(Apt SEK y)": 27165.63333,
    "out:Running Cost over RSP/MSEK": 6.36973,
    "out:LCP/MSEK": 0.969231,
    "out:ROI% old": 40.742555,
    "out:Payback discounted": 3,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 36,
    "out:Return/kSEK/y": 190,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 78219.98787,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 52306.82,
    "out:DH kr savings": 71180.18896,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 174273.0576,
    "out:% savings (space heating)": 30.979067,
    "out:Total_Water Heating (DH)": 104284.7518,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 523348.8976,
    "out:Seasonal Variation ROI (%)": -2,
    "out:Seasonal Variation Payback": -999
  },
  {
    "ID": "Z00000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.67429,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 133.799,
    "out:Total Energy Use Post PV": 133.799,
    "out:Primary Energy": 105.985203,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.631549,
    "out:CO2 Operational/m2": 43.423826,
    "out:Total CO2/m2": 45.055375,
    "out:Total CO2 (tons)": 108763.625,
    "out:Klimatpaverkan":0.001* -25010.92826,
    "out:Initial Cost/MSEK": 0.554070923,
    "out:Running cost/(Apt SEK y)": 26742.66667,
    "out:Running Cost over RSP/MSEK": 6.27043,
    "out:LCP/MSEK": 1.037809,
    "out:ROI% old": 41.04374,
    "out:Payback discounted": 3,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 22.4,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 37,
    "out:Return/kSEK/y": 203,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 83044.1073,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 56696.77,
    "out:DH kr savings": 75570.13765,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 185091.651,
    "out:% savings (space heating)": 26.694357,
    "out:Total_Water Heating (DH)": 88642.03902,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.67,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 554070.9226,
    "out:Seasonal Variation ROI (%)": -1,
    "out:Seasonal Variation Payback": -999
  },
  {
    "ID": "Z00000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.192684,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 129.799,
    "out:Total Energy Use Post PV": 129.799,
    "out:Primary Energy": 102.848079,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.631549,
    "out:CO2 Operational/m2": 41.98389,
    "out:Total CO2/m2": 43.615439,
    "out:Total CO2 (tons)": 105287.6211,
    "out:Klimatpaverkan":0.001* -28486.93215,
    "out:Initial Cost/MSEK": 0.614420898,
    "out:Running cost/(Apt SEK y)": 25896.73333,
    "out:Running Cost over RSP/MSEK": 6.07183,
    "out:LCP/MSEK": 1.176059,
    "out:ROI% old": 41.629908,
    "out:Payback discounted": 3,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 37,
    "out:Return/kSEK/y": 229,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 93862.70064,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 66541.69,
    "out:DH kr savings": 85415.05758,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 174273.0576,
    "out:% savings (space heating)": 30.979067,
    "out:Total_Water Heating (DH)": 88642.03902,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 614420.8976,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.67429,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 131.799,
    "out:Total Energy Use Post PV": 131.799,
    "out:Primary Energy": 105.110203,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.320033,
    "out:CO2 Operational/m2": 42.703858,
    "out:Total CO2/m2": 44.023891,
    "out:Total CO2 (tons)": 106273.6237,
    "out:Klimatpaverkan":0.001* -27500.92948,
    "out:Initial Cost/MSEK": 0.542178298,
    "out:Running cost/(Apt SEK y)": 26319.7,
    "out:Running Cost over RSP/MSEK": 6.17113,
    "out:LCP/MSEK": 1.149002,
    "out:ROI% old": 44.56046,
    "out:Payback discounted": 3,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 24.2,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 40,
    "out:Return/kSEK/y": 216,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 88258.34489,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 61441.73,
    "out:DH kr savings": 80315.09385,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 185091.651,
    "out:% savings (space heating)": 26.694357,
    "out:Total_Water Heating (DH)": 83427.80143,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.67,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 542178.2976,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999
  },
  {
    "ID": "Z00000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.192684,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 127.799,
    "out:Total Energy Use Post PV": 127.799,
    "out:Primary Energy": 101.973079,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.320033,
    "out:CO2 Operational/m2": 41.263922,
    "out:Total CO2/m2": 42.583955,
    "out:Total CO2 (tons)": 102797.6198,
    "out:Klimatpaverkan":0.001* -30976.93338,
    "out:Initial Cost/MSEK": 0.602528273,
    "out:Running cost/(Apt SEK y)": 25473.76667,
    "out:Running Cost over RSP/MSEK": 5.97253,
    "out:LCP/MSEK": 1.287252,
    "out:ROI% old": 44.805959,
    "out:Payback discounted": 3,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 40,
    "out:Return/kSEK/y": 241,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 99076.93822,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71286.65,
    "out:DH kr savings": 90160.01378,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 174273.0576,
    "out:% savings (space heating)": 30.979067,
    "out:Total_Water Heating (DH)": 83427.80143,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 602528.2726,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.67429,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 126.799,
    "out:Total Energy Use Post PV": 126.799,
    "out:Primary Energy": 103.010203,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.631549,
    "out:CO2 Operational/m2": 40.903938,
    "out:Total CO2/m2": 42.535487,
    "out:Total CO2 (tons)": 102680.6181,
    "out:Klimatpaverkan":0.001* -31093.93508,
    "out:Initial Cost/MSEK": 0.633250298,
    "out:Running cost/(Apt SEK y)": 25262.3,
    "out:Running Cost over RSP/MSEK": 5.92288,
    "out:LCP/MSEK": 1.30618,
    "out:ROI% old": 43.752278,
    "out:Payback discounted": 3,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.1,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 39,
    "out:Return/kSEK/y": 248,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 100772.5151,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 72829.62,
    "out:DH kr savings": 91702.98875,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 185091.651,
    "out:% savings (space heating)": 26.694357,
    "out:Total_Water Heating (DH)": 70913.63122,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.67,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 633250.2976,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.192684,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 122.799,
    "out:Total Energy Use Post PV": 122.799,
    "out:Primary Energy": 99.873079,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 1.631549,
    "out:CO2 Operational/m2": 39.464002,
    "out:Total CO2/m2": 41.095551,
    "out:Total CO2 (tons)": 99204.61425,
    "out:Klimatpaverkan":0.001* -34569.93897,
    "out:Initial Cost/MSEK": 0.693600273,
    "out:Running cost/(Apt SEK y)": 24416.36667,
    "out:Running Cost over RSP/MSEK": 5.72427,
    "out:LCP/MSEK": 1.44444,
    "out:ROI% old": 44.036068,
    "out:Payback discounted": 3,
    "out:Atemp": 2414,
    "out:Umean": 0.75,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 39,
    "out:Return/kSEK/y": 273,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 111591.1084,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 82674.54,
    "out:DH kr savings": 101547.9087,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 174273.0576,
    "out:% savings (space heating)": 30.979067,
    "out:Total_Water Heating (DH)": 70913.63122,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 693600.2726,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.536267,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 125.799,
    "out:Total Energy Use Post PV": 125.799,
    "out:Primary Energy": 98.013587,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.020514,
    "out:CO2 Operational/m2": 40.543954,
    "out:Total CO2/m2": 52.564468,
    "out:Total CO2 (tons)": 126890.5671,
    "out:Klimatpaverkan":0.001* -6883.986134,
    "out:Initial Cost/MSEK": 2.320875,
    "out:Running cost/(Apt SEK y)": 25050.8,
    "out:Running Cost over RSP/MSEK": 5.87322,
    "out:LCP/MSEK": -0.331785,
    "out:ROI% old": 12.243473,
    "out:Payback discounted": 10,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 11,
    "out:Return/kSEK/y": 254,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 103944.5649,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 75716.19,
    "out:DH kr savings": 94589.55408,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 148548.4806,
    "out:% savings (space heating)": 41.167298,
    "out:Total_Water Heating (DH)": 104284.7518,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 61.54,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2320875,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.742753,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 121.799,
    "out:Total Energy Use Post PV": 121.799,
    "out:Primary Energy": 95.358127,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.020514,
    "out:CO2 Operational/m2": 39.104018,
    "out:Total CO2/m2": 51.124532,
    "out:Total CO2 (tons)": 123414.5632,
    "out:Klimatpaverkan":0.001* -10359.99003,
    "out:Initial Cost/MSEK": 2.38125,
    "out:Running cost/(Apt SEK y)": 24204.9,
    "out:Running Cost over RSP/MSEK": 5.67462,
    "out:LCP/MSEK": -0.19356,
    "out:ROI% old": 13.124499,
    "out:Payback discounted": 9,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 34.4,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 279,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 113102.1051,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 84049.55,
    "out:DH kr savings": 102922.9156,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 139390.9405,
    "out:% savings (space heating)": 44.794147,
    "out:Total_Water Heating (DH)": 104284.7518,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 57.74,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2381250,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.536267,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 118.799,
    "out:Total Energy Use Post PV": 118.799,
    "out:Primary Energy": 95.388587,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.33203,
    "out:CO2 Operational/m2": 38.024066,
    "out:Total CO2/m2": 50.356096,
    "out:Total CO2 (tons)": 121559.5595,
    "out:Klimatpaverkan":0.001* -12214.99368,
    "out:Initial Cost/MSEK": 2.412,
    "out:Running cost/(Apt SEK y)": 23570.43333,
    "out:Running Cost over RSP/MSEK": 5.52567,
    "out:LCP/MSEK": -0.07536,
    "out:ROI% old": 13.839375,
    "out:Payback discounted": 9,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 37.8,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 119587.2777,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 89951.06,
    "out:DH kr savings": 108824.4227,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 148548.4806,
    "out:% savings (space heating)": 41.167298,
    "out:Total_Water Heating (DH)": 88642.03902,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 61.54,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2412000,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.742753,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 114.799,
    "out:Total Energy Use Post PV": 114.799,
    "out:Primary Energy": 92.733127,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.33203,
    "out:CO2 Operational/m2": 36.58413,
    "out:Total CO2/m2": 48.91616,
    "out:Total CO2 (tons)": 118083.5557,
    "out:Klimatpaverkan":0.001* -15690.99757,
    "out:Initial Cost/MSEK": 2.47225,
    "out:Running cost/(Apt SEK y)": 22724.5,
    "out:Running Cost over RSP/MSEK": 5.32706,
    "out:LCP/MSEK": 0.063,
    "out:ROI% old": 14.649755,
    "out:Payback discounted": 8,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 42.6,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 324,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 128744.8178,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 98284.42,
    "out:DH kr savings": 117157.7842,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 139390.9405,
    "out:% savings (space heating)": 44.794147,
    "out:Total_Water Heating (DH)": 88642.03902,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 57.74,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2472250,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.536267,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 116.799,
    "out:Total Energy Use Post PV": 116.799,
    "out:Primary Energy": 94.513587,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.020514,
    "out:CO2 Operational/m2": 37.304098,
    "out:Total CO2/m2": 49.324612,
    "out:Total CO2 (tons)": 119069.5583,
    "out:Klimatpaverkan":0.001* -14704.9949,
    "out:Initial Cost/MSEK": 2.4,
    "out:Running cost/(Apt SEK y)": 23147.46667,
    "out:Running Cost over RSP/MSEK": 5.42636,
    "out:LCP/MSEK": 0.03595,
    "out:ROI% old": 14.499702,
    "out:Payback discounted": 8,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 40.2,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 124801.5153,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 94696.01,
    "out:DH kr savings": 113569.3789,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 148548.4806,
    "out:% savings (space heating)": 41.167298,
    "out:Total_Water Heating (DH)": 83427.80143,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 61.54,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2400000,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.742753,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 112.799,
    "out:Total Energy Use Post PV": 112.799,
    "out:Primary Energy": 91.858127,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.020514,
    "out:CO2 Operational/m2": 35.864162,
    "out:Total CO2/m2": 47.884676,
    "out:Total CO2 (tons)": 115593.5544,
    "out:Klimatpaverkan":0.001* -18180.9988,
    "out:Initial Cost/MSEK": 2.460375,
    "out:Running cost/(Apt SEK y)": 22301.53333,
    "out:Running Cost over RSP/MSEK": 5.22776,
    "out:LCP/MSEK": 0.174175,
    "out:ROI% old": 15.297029,
    "out:Payback discounted": 8,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 45.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 14,
    "out:Return/kSEK/y": 336,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 133959.0554,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 103029.37,
    "out:DH kr savings": 121902.7404,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 139390.9405,
    "out:% savings (space heating)": 44.794147,
    "out:Total_Water Heating (DH)": 83427.80143,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 57.74,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2460375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.536267,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 111.799,
    "out:Total Energy Use Post PV": 111.799,
    "out:Primary Energy": 92.413587,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.33203,
    "out:CO2 Operational/m2": 35.504178,
    "out:Total CO2/m2": 47.836208,
    "out:Total CO2 (tons)": 115476.5527,
    "out:Klimatpaverkan":0.001* -18298.0005,
    "out:Initial Cost/MSEK": 2.491125,
    "out:Running cost/(Apt SEK y)": 22090.06667,
    "out:Running Cost over RSP/MSEK": 5.17811,
    "out:LCP/MSEK": 0.193075,
    "out:ROI% old": 15.392931,
    "out:Payback discounted": 8,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 46.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 14,
    "out:Return/kSEK/y": 343,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 137315.6855,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 106083.91,
    "out:DH kr savings": 124957.2738,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 148548.4806,
    "out:% savings (space heating)": 41.167298,
    "out:Total_Water Heating (DH)": 70913.63122,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 61.54,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2491125,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.742753,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 107.799,
    "out:Total Energy Use Post PV": 107.799,
    "out:Primary Energy": 89.758127,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.33203,
    "out:CO2 Operational/m2": 34.064242,
    "out:Total CO2/m2": 46.396272,
    "out:Total CO2 (tons)": 112000.5488,
    "out:Klimatpaverkan":0.001* -21774.00439,
    "out:Initial Cost/MSEK": 2.5515,
    "out:Running cost/(Apt SEK y)": 21244.13333,
    "out:Running Cost over RSP/MSEK": 4.97951,
    "out:LCP/MSEK": 0.3313,
    "out:ROI% old": 16.140646,
    "out:Payback discounted": 8,
    "out:Atemp": 2414,
    "out:Umean": 0.58,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 51.9,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 14,
    "out:Return/kSEK/y": 368,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146473.2256,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114417.27,
    "out:DH kr savings": 133290.6353,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 139390.9405,
    "out:% savings (space heating)": 44.794147,
    "out:Total_Water Heating (DH)": 70913.63122,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 57.74,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2551500,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.046253,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 118.799,
    "out:Total Energy Use Post PV": 118.799,
    "out:Primary Energy": 93.470577,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.445311,
    "out:CO2 Operational/m2": 38.024066,
    "out:Total CO2/m2": 52.469377,
    "out:Total CO2 (tons)": 126661.0175,
    "out:Klimatpaverkan":0.001* -7113.535702,
    "out:Initial Cost/MSEK": 2.745,
    "out:Running cost/(Apt SEK y)": 23570.43333,
    "out:Running Cost over RSP/MSEK": 5.52567,
    "out:LCP/MSEK": -0.40836,
    "out:ROI% old": 12.1605,
    "out:Payback discounted": 10,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 37.8,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 11,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 119611.4522,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 89973.05,
    "out:DH kr savings": 108846.4215,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 132881.5933,
    "out:% savings (space heating)": 47.372177,
    "out:Total_Water Heating (DH)": 104284.7518,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.05,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2745000,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z02000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.547739,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 115.799,
    "out:Total Energy Use Post PV": 115.799,
    "out:Primary Energy": 91.021617,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.445311,
    "out:CO2 Operational/m2": 36.944114,
    "out:Total CO2/m2": 51.389425,
    "out:Total CO2 (tons)": 124054.0146,
    "out:Klimatpaverkan":0.001* -9720.538625,
    "out:Initial Cost/MSEK": 2.805375,
    "out:Running cost/(Apt SEK y)": 22936,
    "out:Running Cost over RSP/MSEK": 5.37671,
    "out:LCP/MSEK": -0.319775,
    "out:ROI% old": 12.657335,
    "out:Payback discounted": 10,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 11,
    "out:Return/kSEK/y": 317,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 128056.8611,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 97658.38,
    "out:DH kr savings": 116531.7436,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 124436.1844,
    "out:% savings (space heating)": 50.716986,
    "out:Total_Water Heating (DH)": 104284.7518,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.55,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2805375,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z02000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.046253,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 112.799,
    "out:Total Energy Use Post PV": 112.799,
    "out:Primary Energy": 90.845577,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.756827,
    "out:CO2 Operational/m2": 35.864162,
    "out:Total CO2/m2": 50.620989,
    "out:Total CO2 (tons)": 122199.011,
    "out:Klimatpaverkan":0.001* -11575.54227,
    "out:Initial Cost/MSEK": 2.836125,
    "out:Running cost/(Apt SEK y)": 22301.53333,
    "out:Running Cost over RSP/MSEK": 5.22776,
    "out:LCP/MSEK": -0.201575,
    "out:ROI% old": 13.27037,
    "out:Payback discounted": 9,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 45.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 336,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135254.165,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 104207.92,
    "out:DH kr savings": 123081.2901,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 132881.5933,
    "out:% savings (space heating)": 47.372177,
    "out:Total_Water Heating (DH)": 88642.03902,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.05,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2836125,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.547739,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 108.799,
    "out:Total Energy Use Post PV": 108.799,
    "out:Primary Energy": 88.396617,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.756827,
    "out:CO2 Operational/m2": 34.424226,
    "out:Total CO2/m2": 49.181053,
    "out:Total CO2 (tons)": 118723.0071,
    "out:Klimatpaverkan":0.001* -15051.54617,
    "out:Initial Cost/MSEK": 2.896375,
    "out:Running cost/(Apt SEK y)": 21455.6,
    "out:Running Cost over RSP/MSEK": 5.02916,
    "out:LCP/MSEK": -0.063225,
    "out:ROI% old": 13.973871,
    "out:Payback discounted": 9,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 50.5,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 143699.5739,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111893.24,
    "out:DH kr savings": 130766.6122,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 124436.1844,
    "out:% savings (space heating)": 50.716986,
    "out:Total_Water Heating (DH)": 88642.03902,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.55,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2896375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.046253,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 110.799,
    "out:Total Energy Use Post PV": 110.799,
    "out:Primary Energy": 89.970577,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.445311,
    "out:CO2 Operational/m2": 35.144194,
    "out:Total CO2/m2": 49.589505,
    "out:Total CO2 (tons)": 119709.0097,
    "out:Klimatpaverkan":0.001* -14065.5435,
    "out:Initial Cost/MSEK": 2.824125,
    "out:Running cost/(Apt SEK y)": 21878.56667,
    "out:Running Cost over RSP/MSEK": 5.12846,
    "out:LCP/MSEK": -0.090275,
    "out:ROI% old": 13.829062,
    "out:Payback discounted": 9,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 47.7,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 349,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 140468.4026,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108952.88,
    "out:DH kr savings": 127826.2463,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 132881.5933,
    "out:% savings (space heating)": 47.372177,
    "out:Total_Water Heating (DH)": 83427.80143,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.05,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2824125,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.547739,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 106.799,
    "out:Total Energy Use Post PV": 106.799,
    "out:Primary Energy": 87.521617,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.445311,
    "out:CO2 Operational/m2": 33.704258,
    "out:Total CO2/m2": 48.149569,
    "out:Total CO2 (tons)": 116233.0058,
    "out:Klimatpaverkan":0.001* -17541.54739,
    "out:Initial Cost/MSEK": 2.8845,
    "out:Running cost/(Apt SEK y)": 21032.63333,
    "out:Running Cost over RSP/MSEK": 4.92985,
    "out:LCP/MSEK": 0.04796,
    "out:ROI% old": 14.52324,
    "out:Payback discounted": 8,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 374,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 148913.8114,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116638.2,
    "out:DH kr savings": 135511.5684,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 124436.1844,
    "out:% savings (space heating)": 50.716986,
    "out:Total_Water Heating (DH)": 83427.80143,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.55,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2884500,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.046253,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 104.799,
    "out:Total Energy Use Post PV": 104.799,
    "out:Primary Energy": 87.870577,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.756827,
    "out:CO2 Operational/m2": 32.98429,
    "out:Total CO2/m2": 47.741117,
    "out:Total CO2 (tons)": 115247.0032,
    "out:Klimatpaverkan":0.001* -18527.55007,
    "out:Initial Cost/MSEK": 2.91525,
    "out:Running cost/(Apt SEK y)": 20609.7,
    "out:Running Cost over RSP/MSEK": 4.83055,
    "out:LCP/MSEK": 0.11651,
    "out:ROI% old": 14.856653,
    "out:Payback discounted": 8,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 56.2,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 387,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 152982.5728,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120340.77,
    "out:DH kr savings": 139214.1412,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 132881.5933,
    "out:% savings (space heating)": 47.372177,
    "out:Total_Water Heating (DH)": 70913.63122,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.05,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2915250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.547739,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 101.799,
    "out:Total Energy Use Post PV": 101.799,
    "out:Primary Energy": 85.421617,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.756827,
    "out:CO2 Operational/m2": 31.904338,
    "out:Total CO2/m2": 46.661165,
    "out:Total CO2 (tons)": 112640.0002,
    "out:Klimatpaverkan":0.001* -21134.55299,
    "out:Initial Cost/MSEK": 2.975625,
    "out:Running cost/(Apt SEK y)": 19975.23333,
    "out:Running Cost over RSP/MSEK": 4.6816,
    "out:LCP/MSEK": 0.205085,
    "out:ROI% old": 15.270309,
    "out:Payback discounted": 8,
    "out:Atemp": 2414,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 60.8,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 14,
    "out:Return/kSEK/y": 406,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 161427.9817,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128026.1,
    "out:DH kr savings": 146899.4633,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 124436.1844,
    "out:% savings (space heating)": 50.716986,
    "out:Total_Water Heating (DH)": 70913.63122,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.55,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2975625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.080957,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 115.799,
    "out:Total Energy Use Post PV": 115.799,
    "out:Primary Energy": 91.39487,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.547492,
    "out:CO2 Operational/m2": 36.944114,
    "out:Total CO2/m2": 71.491606,
    "out:Total CO2 (tons)": 172580.6571,
    "out:Klimatpaverkan":0.001* 38806.10388,
    "out:Initial Cost/MSEK": 4.691375,
    "out:Running cost/(Apt SEK y)": 22936,
    "out:Running Cost over RSP/MSEK": 5.37671,
    "out:LCP/MSEK": -2.205775,
    "out:ROI% old": 7.568905,
    "out:Payback discounted": 16,
    "out:Atemp": 2414,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 317,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 821241.8012,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 126769.6744,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96487.04,
    "out:DH kr savings": 115360.4037,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 125723.3711,
    "out:% savings (space heating)": 50.207195,
    "out:Total_Water Heating (DH)": 104284.7518,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.08,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4691375,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.717229,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 112.799,
    "out:Total Energy Use Post PV": 112.799,
    "out:Primary Energy": 89.04026,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.547492,
    "out:CO2 Operational/m2": 35.864162,
    "out:Total CO2/m2": 70.411654,
    "out:Total CO2 (tons)": 169973.6542,
    "out:Klimatpaverkan":0.001* 36199.10095,
    "out:Initial Cost/MSEK": 4.75175,
    "out:Running cost/(Apt SEK y)": 22301.53333,
    "out:Running Cost over RSP/MSEK": 5.22776,
    "out:LCP/MSEK": -2.1172,
    "out:ROI% old": 7.920541,
    "out:Payback discounted": 15,
    "out:Atemp": 2414,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 45.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 336,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 821241.8012,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 134889.7096,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 103876.27,
    "out:DH kr savings": 122749.6357,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 117603.336,
    "out:% savings (space heating)": 53.423139,
    "out:Total_Water Heating (DH)": 104284.7518,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.72,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4751750,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.080957,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 109.799,
    "out:Total Energy Use Post PV": 109.799,
    "out:Primary Energy": 88.76987,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.859008,
    "out:CO2 Operational/m2": 34.78421,
    "out:Total CO2/m2": 69.643218,
    "out:Total CO2 (tons)": 168118.6505,
    "out:Klimatpaverkan":0.001* 34344.09731,
    "out:Initial Cost/MSEK": 4.7825,
    "out:Running cost/(Apt SEK y)": 21667.1,
    "out:Running Cost over RSP/MSEK": 5.07881,
    "out:LCP/MSEK": -1.999,
    "out:ROI% old": 8.31454,
    "out:Payback discounted": 14,
    "out:Atemp": 2414,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 49.1,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 821241.8012,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 142412.3872,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110721.9,
    "out:DH kr savings": 129595.2723,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 125723.3711,
    "out:% savings (space heating)": 50.207195,
    "out:Total_Water Heating (DH)": 88642.03902,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.08,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4782500,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.717229,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 105.799,
    "out:Primary Energy": 86.41526,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.859008,
    "out:CO2 Operational/m2": 33.344274,
    "out:Total CO2/m2": 68.203282,
    "out:Total CO2 (tons)": 164642.6466,
    "out:Klimatpaverkan":0.001* 30868.09341,
    "out:Initial Cost/MSEK": 4.84275,
    "out:Running cost/(Apt SEK y)": 20821.16667,
    "out:Running Cost over RSP/MSEK": 4.8802,
    "out:LCP/MSEK": -1.86064,
    "out:ROI% old": 8.796979,
    "out:Payback discounted": 14,
    "out:Atemp": 2414,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 54.7,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 821241.8012,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 150532.4223,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118111.14,
    "out:DH kr savings": 136984.5043,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 117603.336,
    "out:% savings (space heating)": 53.423139,
    "out:Total_Water Heating (DH)": 88642.03902,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.72,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4842750,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.080957,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 107.799,
    "out:Total Energy Use Post PV": 107.799,
    "out:Primary Energy": 87.89487,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.547492,
    "out:CO2 Operational/m2": 34.064242,
    "out:Total CO2/m2": 68.611734,
    "out:Total CO2 (tons)": 165628.6493,
    "out:Klimatpaverkan":0.001* 31854.09608,
    "out:Initial Cost/MSEK": 4.7705,
    "out:Running cost/(Apt SEK y)": 21244.13333,
    "out:Running Cost over RSP/MSEK": 4.97951,
    "out:LCP/MSEK": -1.8877,
    "out:ROI% old": 8.632818,
    "out:Payback discounted": 14,
    "out:Atemp": 2414,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 51.9,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 368,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 821241.8012,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 147626.6248,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115466.86,
    "out:DH kr savings": 134340.2285,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 125723.3711,
    "out:% savings (space heating)": 50.207195,
    "out:Total_Water Heating (DH)": 83427.80143,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.08,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4770500,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.717229,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 103.799,
    "out:Total Energy Use Post PV": 103.799,
    "out:Primary Energy": 85.54026,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.547492,
    "out:CO2 Operational/m2": 32.624306,
    "out:Total CO2/m2": 67.171798,
    "out:Total CO2 (tons)": 162152.6454,
    "out:Klimatpaverkan":0.001* 28378.09218,
    "out:Initial Cost/MSEK": 4.830875,
    "out:Running cost/(Apt SEK y)": 20398.2,
    "out:Running Cost over RSP/MSEK": 4.7809,
    "out:LCP/MSEK": -1.749465,
    "out:ROI% old": 9.11225,
    "out:Payback discounted": 13,
    "out:Atemp": 2414,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 57.7,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 821241.8012,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 155746.6599,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 122856.09,
    "out:DH kr savings": 141729.4605,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 117603.336,
    "out:% savings (space heating)": 53.423139,
    "out:Total_Water Heating (DH)": 83427.80143,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.72,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4830875,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.080957,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 101.799,
    "out:Total Energy Use Post PV": 101.799,
    "out:Primary Energy": 85.79487,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.859008,
    "out:CO2 Operational/m2": 31.904338,
    "out:Total CO2/m2": 66.763346,
    "out:Total CO2 (tons)": 161166.6427,
    "out:Klimatpaverkan":0.001* 27392.08951,
    "out:Initial Cost/MSEK": 4.861625,
    "out:Running cost/(Apt SEK y)": 19975.23333,
    "out:Running Cost over RSP/MSEK": 4.6816,
    "out:LCP/MSEK": -1.680915,
    "out:ROI% old": 9.346405,
    "out:Payback discounted": 13,
    "out:Atemp": 2414,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 60.8,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 406,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 821241.8012,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 160140.795,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126854.76,
    "out:DH kr savings": 145728.1234,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 125723.3711,
    "out:% savings (space heating)": 50.207195,
    "out:Total_Water Heating (DH)": 70913.63122,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 52.08,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4861625,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.717229,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 98.799,
    "out:Total Energy Use Post PV": 98.799,
    "out:Primary Energy": 83.44026,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.859008,
    "out:CO2 Operational/m2": 30.824386,
    "out:Total CO2/m2": 65.683394,
    "out:Total CO2 (tons)": 158559.6398,
    "out:Klimatpaverkan":0.001* 24785.08659,
    "out:Initial Cost/MSEK": 4.922,
    "out:Running cost/(Apt SEK y)": 19340.8,
    "out:Running Cost over RSP/MSEK": 4.53265,
    "out:LCP/MSEK": -1.59234,
    "out:ROI% old": 9.664074,
    "out:Payback discounted": 12,
    "out:Atemp": 2414,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 402.3331473,
    "out:Facade surface/m2": 1039.321187,
    "out:Window surface/m2": 567.3206191,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 65.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 9,
    "out:Return/kSEK/y": 425,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 62926.16757,
    "out:EAHP (pipework)": 62733.796,
    "out:EAHP (natural ventilation grills)": 142818.75,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 821241.8012,
    "out:FTX (Diffusers)": 148585.9375,
    "out:FTX (Dampers)": 78281.25,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 152791.4226,
    "out:FTX (kWh heat recovery efficiency)": 27.921055,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 30,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 370500,
    "out:DH (pipework)": 412500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 339679.8177,
    "out:FTX (Facade)": 2840620.702,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 168260.8301,
    "out:EL kWh savings": -11101.98087,
    "out:Sewage heat recovery cost": 91072,
    "out:Balancing hydronic system cost": 60349.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134243.99,
    "out:DH kr savings": 153117.3554,
    "out:El kr savings": -18873.36747,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 39106.78192,
    "out:Total_Space Heating (DH)": 117603.336,
    "out:% savings (space heating)": 53.423139,
    "out:Total_Water Heating (DH)": 70913.63122,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 4,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.72,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4922000,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  }
]