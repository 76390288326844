import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { type SelectChangeEvent } from "@mui/material/Select/Select";
import { type ReactElement, type ReactNode, useCallback } from "react";

export interface InstallationConfigProps {
  installationValue: number;
  label: string;
  options: Readonly<Readonly<[string, number]>[]>;
  onChange?: ([label, value]: [string, number]) => void;
}

export const InstallationConfig = ({
  installationValue,
  label,
  options,
  onChange,
}: InstallationConfigProps): ReactElement => {
  const handleChange = useCallback(
    (event: SelectChangeEvent<number>, _child: ReactNode) => {
      if (onChange !== undefined) {
        onChange([label, Number(event.target.value)]);
      }
    },
    [label, onChange],
  );

  return (
    <FormControl fullWidth={true}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId={label}
        id={label}
        value={installationValue}
        label={label}
        onChange={handleChange}
      >
        {options.map(([label, value]) => (
          <MenuItem key={label} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
