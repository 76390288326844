import type { InputMap } from "packages/dataAccess";
import {
  type InstallationItem,
  InstallationItems,
} from "packages/installations";

export const Plåtslagaren1A: Record<InstallationItem, InputMap> = {
  [InstallationItems.nuläge]: new Map([]),

  [InstallationItems.imdTappvarmvatten]: new Map([
    ["in:DHW individual metering", 1],
  ]),

  [InstallationItems.solceller]: new Map([["in:PV_System_Roof_Coverage", 1]]),

  [InstallationItems.takisolering]: new Map([
    ["in:Roof_Insulation_Thickness", 1],
    ["in:Roof_Insulation_Type", 0],
  ]),

  [InstallationItems.fönster]: new Map([["in:Window_Type", 0]]),

  [InstallationItems.fasadisolering]: new Map([
    ["in:Facade_Insulation_Thickness", 1],
    ["in:Facade_Insulation_Type", 0],
  ]),

  [InstallationItems.avloppsvärmeväxlare]: new Map([
    ["in:Sewage heat recovery", 1],
  ]),

  [InstallationItems.injustering]: new Map([
    ["in:Injustering av varmesystem", 1],
  ]),

  [InstallationItems.frånluftsvärmepump]: new Map([["in:EAHP", 1]]),

  [InstallationItems.improvedahu]: new Map([["in:Improve AHU", 1]]),

  [InstallationItems.ftx]: new Map([["in:FTX only", 1]]),

  [InstallationItems.ftxFasadintegrerad]: new Map([
    ["in:Facade Integrated FTX", 1],
  ]),
};
