export default [
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.610424,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 163.2,
    "out:Total Energy Use Post PV": 163.2,
    "out:Primary Energy": 119.187297,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 55.056178,
    "out:Total CO2/m2": 55.056178,
    "out:Total CO2 (tons)": 164891.4047,
    "out:Klimatpaverkan":0.001* 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 36458.02941,
    "out:Running Cost over RSP/MSEK": 9.6929,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 1,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 100,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": -8.51e-11,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": -7.75e-11,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 310309.7411,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 103.61,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 0,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": 0
  },
  {
    "ID": "Z00000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.173586,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 158.2,
    "out:Total Energy Use Post PV": 158.2,
    "out:Primary Energy": 116.08151,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 53.256258,
    "out:Total CO2/m2": 53.256258,
    "out:Total CO2 (tons)": 159500.7047,
    "out:Klimatpaverkan":0.001* -5390.699971,
    "out:Initial Cost/MSEK": 0.074874163,
    "out:Running cost/(Apt SEK y)": 35300.47059,
    "out:Running Cost over RSP/MSEK": 9.3849,
    "out:LCP/MSEK": 0.233126,
    "out:ROI% old": 58.765265,
    "out:Payback discounted": 2,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 3.2,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 53,
    "out:Return/kSEK/y": 39,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 13288.17921,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 12092.24,
    "out:DH kr savings": 12092.24308,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 297021.5619,
    "out:% savings (space heating)": 4.282231,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.17,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 74874.1625,
    "out:Seasonal Variation ROI (%)": 16,
    "out:Seasonal Variation Payback": 6.25
  },
  {
    "ID": "Z00000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.610424,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 156.2,
    "out:Primary Energy": 116.562297,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.376632,
    "out:CO2 Operational/m2": 52.53629,
    "out:Total CO2/m2": 52.912922,
    "out:Total CO2 (tons)": 158472.4249,
    "out:Klimatpaverkan":0.001* -6418.979765,
    "out:Initial Cost/MSEK": 0.136608,
    "out:Running cost/(Apt SEK y)": 34837.44118,
    "out:Running Cost over RSP/MSEK": 9.2617,
    "out:LCP/MSEK": 0.294592,
    "out:ROI% old": 45.092528,
    "out:Payback discounted": 3,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 4.5,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 40,
    "out:Return/kSEK/y": 55,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 19407.38245,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 17660.72,
    "out:DH kr savings": 17660.71803,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 310309.7411,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 103.61,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 136608,
    "out:Seasonal Variation ROI (%)": 13,
    "out:Seasonal Variation Payback": 7.692308
  },
  {
    "ID": "Z00000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.173586,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 152.2,
    "out:Primary Energy": 113.45651,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.376632,
    "out:CO2 Operational/m2": 51.096354,
    "out:Total CO2/m2": 51.472986,
    "out:Total CO2 (tons)": 154159.865,
    "out:Klimatpaverkan":0.001* -10731.53974,
    "out:Initial Cost/MSEK": 0.211482163,
    "out:Running cost/(Apt SEK y)": 33911.41177,
    "out:Running Cost over RSP/MSEK": 9.0153,
    "out:LCP/MSEK": 0.466118,
    "out:ROI% old": 45.772182,
    "out:Payback discounted": 3,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 41,
    "out:Return/kSEK/y": 87,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 32695.56165,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 29752.96,
    "out:DH kr savings": 29752.9611,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 297021.5619,
    "out:% savings (space heating)": 4.282231,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.17,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 211482.1625,
    "out:Seasonal Variation ROI (%)": 14,
    "out:Seasonal Variation Payback": 7.142857
  },
  {
    "ID": "Z00000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.610424,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 163.2,
    "out:Total Energy Use Post PV": 158.409285,
    "out:Primary Energy": 110.56401,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.276909,
    "out:CO2 Operational/m2": 36.315913,
    "out:Total CO2/m2": 45.592822,
    "out:Total CO2 (tons)": 136548.9712,
    "out:Klimatpaverkan":0.001* -28342.43351,
    "out:Initial Cost/MSEK": 0.467842425,
    "out:Running cost/(Apt SEK y)": 35335.97059,
    "out:Running Cost over RSP/MSEK": 9.4025,
    "out:LCP/MSEK": -0.177442,
    "out:ROI% old": 8.867455,
    "out:Payback discounted": 14,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 3.2,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 8,
    "out:Return/kSEK/y": 38,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": -8.51e-11,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 38149.88,
    "out:DH kr savings": -7.75e-11,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 310309.7411,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 103.61,
    "out:Etvv": 25,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 467842.4245,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5
  },
  {
    "ID": "Z00000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.173586,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 158.2,
    "out:Total Energy Use Post PV": 153.409285,
    "out:Primary Energy": 107.458223,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.276909,
    "out:CO2 Operational/m2": 34.515993,
    "out:Total CO2/m2": 43.792902,
    "out:Total CO2 (tons)": 131158.2712,
    "out:Klimatpaverkan":0.001* -33733.13348,
    "out:Initial Cost/MSEK": 0.542716587,
    "out:Running cost/(Apt SEK y)": 34178.41177,
    "out:Running Cost over RSP/MSEK": 9.0945,
    "out:LCP/MSEK": 0.055683,
    "out:ROI% old": 15.751447,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 14,
    "out:Return/kSEK/y": 78,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 13288.17921,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 50242.12,
    "out:DH kr savings": 12092.24308,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 297021.5619,
    "out:% savings (space heating)": 4.282231,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.17,
    "out:Etvv": 25,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 542716.587,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111
  },
  {
    "ID": "Z00000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.610424,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 151.409285,
    "out:Primary Energy": 107.93901,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.653541,
    "out:CO2 Operational/m2": 33.796025,
    "out:Total CO2/m2": 43.449566,
    "out:Total CO2 (tons)": 130129.9914,
    "out:Klimatpaverkan":0.001* -34761.41327,
    "out:Initial Cost/MSEK": 0.604450425,
    "out:Running cost/(Apt SEK y)": 33715.38235,
    "out:Running Cost over RSP/MSEK": 8.9713,
    "out:LCP/MSEK": 0.11715,
    "out:ROI% old": 17.054453,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 7.9,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 15,
    "out:Return/kSEK/y": 93,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 19407.38245,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 55810.6,
    "out:DH kr savings": 17660.71803,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 310309.7411,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 103.61,
    "out:Etvv": 21.25,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 604450.4245,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111
  },
  {
    "ID": "Z00000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.173586,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 147.409285,
    "out:Primary Energy": 104.833223,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.653541,
    "out:CO2 Operational/m2": 32.356089,
    "out:Total CO2/m2": 42.00963,
    "out:Total CO2 (tons)": 125817.4315,
    "out:Klimatpaverkan":0.001* -39073.97325,
    "out:Initial Cost/MSEK": 0.679324587,
    "out:Running cost/(Apt SEK y)": 32789.35294,
    "out:Running Cost over RSP/MSEK": 8.7249,
    "out:LCP/MSEK": 0.288675,
    "out:ROI% old": 20.356353,
    "out:Payback discounted": 6,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 10.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 18,
    "out:Return/kSEK/y": 125,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 32695.56165,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 67902.84,
    "out:DH kr savings": 29752.9611,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 297021.5619,
    "out:% savings (space heating)": 4.282231,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.17,
    "out:Etvv": 21.25,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 679324.587,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10
  },
  {
    "ID": "Z00000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.610424,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 163.2,
    "out:Total Energy Use Post PV": 157.404548,
    "out:Primary Energy": 108.755483,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.553818,
    "out:CO2 Operational/m2": -1.380702,
    "out:Total CO2/m2": 17.173116,
    "out:Total CO2 (tons)": 51432.90587,
    "out:Klimatpaverkan":0.001* -113458.4988,
    "out:Initial Cost/MSEK": 0.935684867,
    "out:Running cost/(Apt SEK y)": 34355.64706,
    "out:Running Cost over RSP/MSEK": 9.149,
    "out:LCP/MSEK": -0.391785,
    "out:ROI% old": 8.304078,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 3.8,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 8,
    "out:Return/kSEK/y": 71,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": -8.51e-11,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 71480.74,
    "out:DH kr savings": -7.75e-11,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 310309.7411,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 103.61,
    "out:Etvv": 25,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 935684.8666,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5
  },
  {
    "ID": "Z00000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.173586,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 158.2,
    "out:Total Energy Use Post PV": 152.404548,
    "out:Primary Energy": 105.649696,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.553818,
    "out:CO2 Operational/m2": -3.180622,
    "out:Total CO2/m2": 15.373196,
    "out:Total CO2 (tons)": 46042.2059,
    "out:Klimatpaverkan":0.001* -118849.1988,
    "out:Initial Cost/MSEK": 1.010559029,
    "out:Running cost/(Apt SEK y)": 33198.08824,
    "out:Running Cost over RSP/MSEK": 8.841,
    "out:LCP/MSEK": -0.158659,
    "out:ROI% old": 12.042839,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 11,
    "out:Return/kSEK/y": 111,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 13288.17921,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83572.98,
    "out:DH kr savings": 12092.24308,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 297021.5619,
    "out:% savings (space heating)": 4.282231,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.17,
    "out:Etvv": 25,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1010559.029,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5
  },
  {
    "ID": "Z00000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.610424,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 150.404548,
    "out:Primary Energy": 106.130483,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.93045,
    "out:CO2 Operational/m2": -3.90059,
    "out:Total CO2/m2": 15.02986,
    "out:Total CO2 (tons)": 45013.9261,
    "out:Klimatpaverkan":0.001* -119877.4786,
    "out:Initial Cost/MSEK": 1.072292867,
    "out:Running cost/(Apt SEK y)": 32735.08824,
    "out:Running Cost over RSP/MSEK": 8.7178,
    "out:LCP/MSEK": -0.097193,
    "out:ROI% old": 12.990854,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 8.7,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 12,
    "out:Return/kSEK/y": 127,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 19407.38245,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 89141.46,
    "out:DH kr savings": 17660.71803,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 310309.7411,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 103.61,
    "out:Etvv": 21.25,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1072292.867,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5
  },
  {
    "ID": "Z00000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.173586,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 146.404548,
    "out:Primary Energy": 103.024696,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.93045,
    "out:CO2 Operational/m2": -5.340526,
    "out:Total CO2/m2": 13.589924,
    "out:Total CO2 (tons)": 40701.36613,
    "out:Klimatpaverkan":0.001* -124190.0386,
    "out:Initial Cost/MSEK": 1.147167029,
    "out:Running cost/(Apt SEK y)": 31809.02941,
    "out:Running Cost over RSP/MSEK": 8.4714,
    "out:LCP/MSEK": 0.074333,
    "out:ROI% old": 15.211386,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 11.6,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 14,
    "out:Return/kSEK/y": 158,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 32695.56165,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 101233.7,
    "out:DH kr savings": 29752.9611,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 297021.5619,
    "out:% savings (space heating)": 4.282231,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.17,
    "out:Etvv": 21.25,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1147167.029,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111
  },
  {
    "ID": "Z00000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.610424,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 154.2,
    "out:Primary Energy": 115.687297,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 51.816322,
    "out:Total CO2/m2": 51.816322,
    "out:Total CO2 (tons)": 155188.1448,
    "out:Klimatpaverkan":0.001* -9703.259948,
    "out:Initial Cost/MSEK": 0.089736625,
    "out:Running cost/(Apt SEK y)": 34374.44118,
    "out:Running Cost over RSP/MSEK": 9.1385,
    "out:LCP/MSEK": 0.464663,
    "out:ROI% old": 88.258278,
    "out:Payback discounted": 2,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 5.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 79,
    "out:Return/kSEK/y": 71,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 26,
    "out:Payback Time": 3.846154,
    "out:DH kWh savings": 25876.50993,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 23547.62,
    "out:DH kr savings": 23547.62404,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 310309.7411,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 103.61,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 89736.625,
    "out:Seasonal Variation ROI (%)": 26,
    "out:Seasonal Variation Payback": 3.846154
  },
  {
    "ID": "Z00000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.173586,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 150.2,
    "out:Primary Energy": 112.58151,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 50.376386,
    "out:Total CO2/m2": 50.376386,
    "out:Total CO2 (tons)": 150875.5848,
    "out:Klimatpaverkan":0.001* -14015.81993,
    "out:Initial Cost/MSEK": 0.164610788,
    "out:Running cost/(Apt SEK y)": 33448.38235,
    "out:Running Cost over RSP/MSEK": 8.8921,
    "out:LCP/MSEK": 0.636189,
    "out:ROI% old": 69.497268,
    "out:Payback discounted": 2,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 8.7,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 62,
    "out:Return/kSEK/y": 102,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 39164.68913,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 35639.87,
    "out:DH kr savings": 35639.86711,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 297021.5619,
    "out:% savings (space heating)": 4.282231,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.17,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 164610.7875,
    "out:Seasonal Variation ROI (%)": 22,
    "out:Seasonal Variation Payback": 4.545455
  },
  {
    "ID": "Z00000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.610424,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 149.2,
    "out:Primary Energy": 113.587297,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.376632,
    "out:CO2 Operational/m2": 50.016402,
    "out:Total CO2/m2": 50.393034,
    "out:Total CO2 (tons)": 150925.445,
    "out:Klimatpaverkan":0.001* -13965.95972,
    "out:Initial Cost/MSEK": 0.226344625,
    "out:Running cost/(Apt SEK y)": 33216.88235,
    "out:Running Cost over RSP/MSEK": 8.8305,
    "out:LCP/MSEK": 0.636055,
    "out:ROI% old": 54.430274,
    "out:Payback discounted": 3,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 9.4,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 49,
    "out:Return/kSEK/y": 110,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 41402.41589,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 37676.2,
    "out:DH kr savings": 37676.19846,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 310309.7411,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 103.61,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 226344.625,
    "out:Seasonal Variation ROI (%)": 17,
    "out:Seasonal Variation Payback": 5.882353
  },
  {
    "ID": "Z00000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.173586,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 145.2,
    "out:Primary Energy": 110.48151,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.376632,
    "out:CO2 Operational/m2": 48.576466,
    "out:Total CO2/m2": 48.953098,
    "out:Total CO2 (tons)": 146612.885,
    "out:Klimatpaverkan":0.001* -18278.5197,
    "out:Initial Cost/MSEK": 0.301218788,
    "out:Running cost/(Apt SEK y)": 32290.82353,
    "out:Running Cost over RSP/MSEK": 8.5841,
    "out:LCP/MSEK": 0.807581,
    "out:ROI% old": 52.586361,
    "out:Payback discounted": 3,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 12.4,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 47,
    "out:Return/kSEK/y": 142,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 54690.59509,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 49768.44,
    "out:DH kr savings": 49768.44153,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 297021.5619,
    "out:% savings (space heating)": 4.282231,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.17,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 301218.7875,
    "out:Seasonal Variation ROI (%)": 17,
    "out:Seasonal Variation Payback": 5.882353
  },
  {
    "ID": "Z00000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.610424,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 149.409285,
    "out:Primary Energy": 107.06401,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.276909,
    "out:CO2 Operational/m2": 33.076057,
    "out:Total CO2/m2": 42.352966,
    "out:Total CO2 (tons)": 126845.7113,
    "out:Klimatpaverkan":0.001* -38045.69346,
    "out:Initial Cost/MSEK": 0.55757905,
    "out:Running cost/(Apt SEK y)": 33252.38235,
    "out:Running Cost over RSP/MSEK": 8.8481,
    "out:LCP/MSEK": 0.287221,
    "out:ROI% old": 21.644593,
    "out:Payback discounted": 6,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 9.4,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 20,
    "out:Return/kSEK/y": 109,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 25876.50993,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 61697.5,
    "out:DH kr savings": 23547.62404,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 310309.7411,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 103.61,
    "out:Etvv": 20,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 557579.0495,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909
  },
  {
    "ID": "Z00000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.173586,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 145.409285,
    "out:Primary Energy": 103.958223,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.276909,
    "out:CO2 Operational/m2": 31.636121,
    "out:Total CO2/m2": 40.91303,
    "out:Total CO2 (tons)": 122533.1513,
    "out:Klimatpaverkan":0.001* -42358.25343,
    "out:Initial Cost/MSEK": 0.632453212,
    "out:Running cost/(Apt SEK y)": 32326.32353,
    "out:Running Cost over RSP/MSEK": 8.6017,
    "out:LCP/MSEK": 0.458747,
    "out:ROI% old": 24.647786,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 12.4,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 22,
    "out:Return/kSEK/y": 140,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 39164.68913,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 73789.75,
    "out:DH kr savings": 35639.86711,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 297021.5619,
    "out:% savings (space heating)": 4.282231,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.17,
    "out:Etvv": 20,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 632453.212,
    "out:Seasonal Variation ROI (%)": 12,
    "out:Seasonal Variation Payback": 8.333333
  },
  {
    "ID": "Z00000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.610424,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 144.409285,
    "out:Primary Energy": 104.96401,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.653541,
    "out:CO2 Operational/m2": 31.276137,
    "out:Total CO2/m2": 40.929678,
    "out:Total CO2 (tons)": 122583.0115,
    "out:Klimatpaverkan":0.001* -42308.39323,
    "out:Initial Cost/MSEK": 0.69418705,
    "out:Running cost/(Apt SEK y)": 32094.82353,
    "out:Running Cost over RSP/MSEK": 8.54009,
    "out:LCP/MSEK": 0.458623,
    "out:ROI% old": 23.723742,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 13.2,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 21,
    "out:Return/kSEK/y": 148,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 41402.41589,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 75826.08,
    "out:DH kr savings": 37676.19846,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 310309.7411,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 103.61,
    "out:Etvv": 17,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 694187.0495,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909
  },
  {
    "ID": "Z00000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.173586,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 140.409285,
    "out:Primary Energy": 101.858223,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.653541,
    "out:CO2 Operational/m2": 29.836201,
    "out:Total CO2/m2": 39.489742,
    "out:Total CO2 (tons)": 118270.4515,
    "out:Klimatpaverkan":0.001* -46620.95321,
    "out:Initial Cost/MSEK": 0.769061212,
    "out:Running cost/(Apt SEK y)": 31168.76471,
    "out:Running Cost over RSP/MSEK": 8.29369,
    "out:LCP/MSEK": 0.630149,
    "out:ROI% old": 25.991058,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 16.4,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 23,
    "out:Return/kSEK/y": 180,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 54690.59509,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 87918.32,
    "out:DH kr savings": 49768.44153,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 297021.5619,
    "out:% savings (space heating)": 4.282231,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.17,
    "out:Etvv": 17,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 769061.212,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909
  },
  {
    "ID": "Z00000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.610424,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 148.404548,
    "out:Primary Energy": 105.255483,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.553818,
    "out:CO2 Operational/m2": -4.620558,
    "out:Total CO2/m2": 13.93326,
    "out:Total CO2 (tons)": 41729.64592,
    "out:Klimatpaverkan":0.001* -123161.7588,
    "out:Initial Cost/MSEK": 1.025421492,
    "out:Running cost/(Apt SEK y)": 32272.05882,
    "out:Running Cost over RSP/MSEK": 8.5946,
    "out:LCP/MSEK": 0.072879,
    "out:ROI% old": 15.301025,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 10.1,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 14,
    "out:Return/kSEK/y": 142,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 25876.50993,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95028.36,
    "out:DH kr savings": 23547.62404,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 310309.7411,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 103.61,
    "out:Etvv": 20,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1025421.492,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111
  },
  {
    "ID": "Z00000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.173586,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 144.404548,
    "out:Primary Energy": 102.149696,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.553818,
    "out:CO2 Operational/m2": -6.060494,
    "out:Total CO2/m2": 12.493324,
    "out:Total CO2 (tons)": 37417.08594,
    "out:Klimatpaverkan":0.001* -127474.3188,
    "out:Initial Cost/MSEK": 1.100295654,
    "out:Running cost/(Apt SEK y)": 31346,
    "out:Running Cost over RSP/MSEK": 8.34819,
    "out:LCP/MSEK": 0.244414,
    "out:ROI% old": 17.459074,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 13.2,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 16,
    "out:Return/kSEK/y": 174,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 39164.68913,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107120.6,
    "out:DH kr savings": 35639.86711,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 297021.5619,
    "out:% savings (space heating)": 4.282231,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.17,
    "out:Etvv": 20,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1100295.654,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10
  },
  {
    "ID": "Z00000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.610424,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 143.404548,
    "out:Primary Energy": 103.155483,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.93045,
    "out:CO2 Operational/m2": -6.420478,
    "out:Total CO2/m2": 12.509972,
    "out:Total CO2 (tons)": 37466.94614,
    "out:Klimatpaverkan":0.001* -127424.4586,
    "out:Initial Cost/MSEK": 1.162029492,
    "out:Running cost/(Apt SEK y)": 31114.5,
    "out:Running Cost over RSP/MSEK": 8.28659,
    "out:LCP/MSEK": 0.244281,
    "out:ROI% old": 17.288841,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 16,
    "out:Return/kSEK/y": 182,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 41402.41589,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 109156.94,
    "out:DH kr savings": 37676.19846,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 310309.7411,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 103.61,
    "out:Etvv": 17,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1162029.492,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111
  },
  {
    "ID": "Z00000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.173586,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 139.404548,
    "out:Primary Energy": 100.049696,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.93045,
    "out:CO2 Operational/m2": -7.860414,
    "out:Total CO2/m2": 11.070036,
    "out:Total CO2 (tons)": 33154.38617,
    "out:Klimatpaverkan":0.001* -131737.0185,
    "out:Initial Cost/MSEK": 1.236903654,
    "out:Running cost/(Apt SEK y)": 30188.44118,
    "out:Running Cost over RSP/MSEK": 8.04019,
    "out:LCP/MSEK": 0.415806,
    "out:ROI% old": 19.088102,
    "out:Payback discounted": 6,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 17.3,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 17,
    "out:Return/kSEK/y": 213,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 54690.59509,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121249.18,
    "out:DH kr savings": 49768.44153,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 297021.5619,
    "out:% savings (space heating)": 4.282231,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.17,
    "out:Etvv": 17,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1236903.654,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10
  },
  {
    "ID": "Z00000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.614646,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.89216,
    "out:Electricity (inc PV)": 39.842274,
    "out:Total Energy Use Pre PV": 111.89216,
    "out:Total Energy Use Post PV": 109.842274,
    "out:Primary Energy": 112.441582,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.893475,
    "out:CO2 Operational/m2": 30.72898,
    "out:Total CO2/m2": 34.622455,
    "out:Total CO2 (tons)": 103693.0903,
    "out:Klimatpaverkan":0.001* -61198.31436,
    "out:Initial Cost/MSEK": 1.65025,
    "out:Running cost/(Apt SEK y)": 22479.02941,
    "out:Running Cost over RSP/MSEK": 5.96094,
    "out:LCP/MSEK": 2.08171,
    "out:ROI% old": 32.306447,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 48.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 2932.4512,
    "out:Return %": 29,
    "out:Return/kSEK/y": 475,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.39266,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231198.7631,
    "out:EL kWh savings": -70807.81689,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 90017.59,
    "out:DH kr savings": 210390.8744,
    "out:El kr savings": -120373.2887,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 208493.5276,
    "out:% savings (space heating)": 32.811156,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 58.18,
    "out:Etvv": 0,
    "out:Ef": 39.84,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1650250,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.308645,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.852513,
    "out:Electricity (inc PV)": 39.767624,
    "out:Total Energy Use Pre PV": 106.852513,
    "out:Total Energy Use Post PV": 104.767624,
    "out:Primary Energy": 109.373868,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.893475,
    "out:CO2 Operational/m2": 28.923827,
    "out:Total CO2/m2": 32.817302,
    "out:Total CO2 (tons)": 98286.71772,
    "out:Klimatpaverkan":0.001* -66604.68699,
    "out:Initial Cost/MSEK": 1.725125,
    "out:Running cost/(Apt SEK y)": 21315.55882,
    "out:Running Cost over RSP/MSEK": 5.65138,
    "out:LCP/MSEK": 2.316395,
    "out:ROI% old": 33.46772,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 55.2,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2929.67591,
    "out:Return %": 30,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.353013,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 244095.0915,
    "out:EL kWh savings": -70584.24264,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102133.32,
    "out:DH kr savings": 222126.5333,
    "out:El kr savings": -119993.2125,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 195597.1992,
    "out:% savings (space heating)": 36.96711,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 53.99,
    "out:Etvv": 0,
    "out:Ef": 39.77,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1725125,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.439111,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.193838,
    "out:Electricity (inc PV)": 39.385373,
    "out:Total Energy Use Pre PV": 106.193838,
    "out:Total Energy Use Post PV": 104.385373,
    "out:Primary Energy": 110.0496,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.270107,
    "out:CO2 Operational/m2": 28.836876,
    "out:Total CO2/m2": 33.106983,
    "out:Total CO2 (tons)": 99154.30258,
    "out:Klimatpaverkan":0.001* -65737.10213,
    "out:Initial Cost/MSEK": 1.786875,
    "out:Running cost/(Apt SEK y)": 21216.91177,
    "out:Running Cost over RSP/MSEK": 5.62545,
    "out:LCP/MSEK": 2.280575,
    "out:ROI% old": 32.518463,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2883.56866,
    "out:Return %": 29,
    "out:Return/kSEK/y": 518,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.694338,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 243704.3503,
    "out:EL kWh savings": -69439.41373,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 103723.96,
    "out:DH kr savings": 221770.9587,
    "out:El kr savings": -118047.0033,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 195987.9405,
    "out:% savings (space heating)": 36.84119,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.94,
    "out:Etvv": 0,
    "out:Ef": 39.39,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1786875,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.171283,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.142622,
    "out:Electricity (inc PV)": 39.302438,
    "out:Total Energy Use Pre PV": 102.142622,
    "out:Total Energy Use Post PV": 100.302438,
    "out:Primary Energy": 106.986111,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.270107,
    "out:CO2 Operational/m2": 27.390179,
    "out:Total CO2/m2": 31.660286,
    "out:Total CO2 (tons)": 94821.49364,
    "out:Klimatpaverkan":0.001* -70069.91107,
    "out:Initial Cost/MSEK": 1.86175,
    "out:Running cost/(Apt SEK y)": 20283.20588,
    "out:Running Cost over RSP/MSEK": 5.37704,
    "out:LCP/MSEK": 2.45411,
    "out:ROI% old": 33.116768,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2879.98354,
    "out:Return %": 30,
    "out:Return/kSEK/y": 550,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.643122,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 256486.3518,
    "out:EL kWh savings": -69191.02619,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115777.84,
    "out:DH kr savings": 233402.5802,
    "out:El kr savings": -117624.7445,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 183205.9389,
    "out:% savings (space heating)": 40.9603,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.77,
    "out:Etvv": 0,
    "out:Ef": 39.3,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1861750,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.614646,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.89216,
    "out:Electricity (inc PV)": 32.082131,
    "out:Total Energy Use Pre PV": 111.89216,
    "out:Total Energy Use Post PV": 102.082131,
    "out:Primary Energy": 98.473325,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.170384,
    "out:CO2 Operational/m2": 27.310076,
    "out:Total CO2/m2": 40.48046,
    "out:Total CO2 (tons)": 121237.6187,
    "out:Klimatpaverkan":0.001* -43653.78606,
    "out:Initial Cost/MSEK": 2.118125,
    "out:Running cost/(Apt SEK y)": 21241.08824,
    "out:Running Cost over RSP/MSEK": 5.64073,
    "out:LCP/MSEK": 1.934045,
    "out:ROI% old": 27.329899,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 2406.670394,
    "out:Return %": 24,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.39266,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1518.688697,
    "out:PV (% sold (El))": 6.041215,
    "out:PV (kWh self-consumed)": 23620.10887,
    "out:PV (ratio sold/self-consumed)": 0.064296,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 231198.7631,
    "out:EL kWh savings": -47566.44913,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131464.24,
    "out:DH kr savings": 210390.8744,
    "out:El kr savings": -80862.96353,
    "out:El kr sold": 1936.328088,
    "out:El kr saved": 40154.18508,
    "out:El kr return": 42090.51317,
    "out:% solar/total": 49,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 208493.5276,
    "out:% savings (space heating)": 32.811156,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 58.18,
    "out:Etvv": 0,
    "out:Ef": 32.08,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2118125,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.308645,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.852513,
    "out:Electricity (inc PV)": 32.012399,
    "out:Total Energy Use Pre PV": 106.852513,
    "out:Total Energy Use Post PV": 97.012399,
    "out:Primary Energy": 95.414463,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.170384,
    "out:CO2 Operational/m2": 25.493897,
    "out:Total CO2/m2": 38.664281,
    "out:Total CO2 (tons)": 115798.2235,
    "out:Klimatpaverkan":0.001* -49093.18119,
    "out:Initial Cost/MSEK": 2.193,
    "out:Running cost/(Apt SEK y)": 20077.67647,
    "out:Running Cost over RSP/MSEK": 5.33119,
    "out:LCP/MSEK": 2.16871,
    "out:ROI% old": 28.413198,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 68,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2404.025187,
    "out:Return %": 25,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.353013,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1525.566059,
    "out:PV (% sold (El))": 6.068572,
    "out:PV (kWh self-consumed)": 23613.23151,
    "out:PV (ratio sold/self-consumed)": 0.064606,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 244095.0915,
    "out:EL kWh savings": -47357.60414,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143563.7,
    "out:DH kr savings": 222126.5333,
    "out:El kr savings": -80507.92703,
    "out:El kr sold": 1945.096725,
    "out:El kr saved": 40142.49356,
    "out:El kr return": 42087.59029,
    "out:% solar/total": 49,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 195597.1992,
    "out:% savings (space heating)": 36.96711,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 53.99,
    "out:Etvv": 0,
    "out:Ef": 32.01,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2193000,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.439111,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.193838,
    "out:Electricity (inc PV)": 31.655798,
    "out:Total Energy Use Pre PV": 106.193838,
    "out:Total Energy Use Post PV": 96.655798,
    "out:Primary Energy": 96.136365,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.547016,
    "out:CO2 Operational/m2": 25.219444,
    "out:Total CO2/m2": 38.76646,
    "out:Total CO2 (tons)": 116104.2462,
    "out:Klimatpaverkan":0.001* -48787.15852,
    "out:Initial Cost/MSEK": 2.25475,
    "out:Running cost/(Apt SEK y)": 19980.5,
    "out:Running Cost over RSP/MSEK": 5.30563,
    "out:LCP/MSEK": 2.13252,
    "out:ROI% old": 27.797,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 68,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2360.141722,
    "out:Return %": 25,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.694338,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1641.825018,
    "out:PV (% sold (El))": 6.53104,
    "out:PV (kWh self-consumed)": 23496.97255,
    "out:PV (ratio sold/self-consumed)": 0.069874,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 243704.3503,
    "out:EL kWh savings": -46289.59611,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145171.97,
    "out:DH kr savings": 221770.9587,
    "out:El kr savings": -78692.31339,
    "out:El kr sold": 2093.326897,
    "out:El kr saved": 39944.85333,
    "out:El kr return": 42038.18023,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 195987.9405,
    "out:% savings (space heating)": 36.84119,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.94,
    "out:Etvv": 0,
    "out:Ef": 31.66,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2254750,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.171283,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.142622,
    "out:Electricity (inc PV)": 31.578529,
    "out:Total Energy Use Pre PV": 102.142622,
    "out:Total Energy Use Post PV": 92.578529,
    "out:Primary Energy": 93.083075,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.547016,
    "out:CO2 Operational/m2": 23.757829,
    "out:Total CO2/m2": 37.304845,
    "out:Total CO2 (tons)": 111726.7583,
    "out:Klimatpaverkan":0.001* -53164.64637,
    "out:Initial Cost/MSEK": 2.329625,
    "out:Running cost/(Apt SEK y)": 19046.91177,
    "out:Running Cost over RSP/MSEK": 5.05724,
    "out:LCP/MSEK": 2.306035,
    "out:ROI% old": 28.42677,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 75.3,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2356.73444,
    "out:Return %": 25,
    "out:Return/kSEK/y": 592,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.643122,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1651.02485,
    "out:PV (% sold (El))": 6.567637,
    "out:PV (kWh self-consumed)": 23487.77272,
    "out:PV (ratio sold/self-consumed)": 0.070293,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 256486.3518,
    "out:EL kWh savings": -46058.17805,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157208.73,
    "out:DH kr savings": 233402.5802,
    "out:El kr savings": -78298.90269,
    "out:El kr sold": 2105.056684,
    "out:El kr saved": 39929.21362,
    "out:El kr return": 42034.2703,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 183205.9389,
    "out:% savings (space heating)": 40.9603,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.77,
    "out:Etvv": 0,
    "out:Ef": 31.58,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2329625,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.614646,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.89216,
    "out:Electricity (inc PV)": 28.907859,
    "out:Total Energy Use Pre PV": 111.89216,
    "out:Total Energy Use Post PV": 98.907859,
    "out:Primary Energy": 92.759635,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.447293,
    "out:CO2 Operational/m2": 1.717491,
    "out:Total CO2/m2": 24.164784,
    "out:Total CO2 (tons)": 72372.7168,
    "out:Klimatpaverkan":0.001* -92518.68791,
    "out:Initial Cost/MSEK": 2.585875,
    "out:Running cost/(Apt SEK y)": 20171.5,
    "out:Running Cost over RSP/MSEK": 5.36365,
    "out:LCP/MSEK": 1.743375,
    "out:ROI% old": 23.917022,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 64.6,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 2164.18353,
    "out:Return %": 21,
    "out:Return/kSEK/y": 554,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.39266,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 16506.62089,
    "out:PV (% sold (El))": 32.830967,
    "out:PV (kWh self-consumed)": 33770.97443,
    "out:PV (ratio sold/self-consumed)": 0.488781,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 231198.7631,
    "out:EL kWh savings": -38059.61106,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166735.48,
    "out:DH kr savings": 210390.8744,
    "out:El kr savings": -64701.33881,
    "out:El kr sold": 21045.94163,
    "out:El kr saved": 57410.65653,
    "out:El kr return": 78456.59815,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 208493.5276,
    "out:% savings (space heating)": 32.811156,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 58.18,
    "out:Etvv": 0,
    "out:Ef": 28.91,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2585875,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.308645,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.852513,
    "out:Electricity (inc PV)": 28.845847,
    "out:Total Energy Use Pre PV": 106.852513,
    "out:Total Energy Use Post PV": 93.845847,
    "out:Primary Energy": 89.71467,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.447293,
    "out:CO2 Operational/m2": -0.12025,
    "out:Total CO2/m2": 22.327043,
    "out:Total CO2 (tons)": 66868.7442,
    "out:Klimatpaverkan":0.001* -98022.66051,
    "out:Initial Cost/MSEK": 2.66075,
    "out:Running cost/(Apt SEK y)": 19008.23529,
    "out:Running Cost over RSP/MSEK": 5.05415,
    "out:LCP/MSEK": 1.978,
    "out:ROI% old": 24.905706,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 73.4,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2161.842672,
    "out:Return %": 22,
    "out:Return/kSEK/y": 593,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.353013,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 16526.12149,
    "out:PV (% sold (El))": 32.869753,
    "out:PV (kWh self-consumed)": 33751.47383,
    "out:PV (ratio sold/self-consumed)": 0.489641,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 244095.0915,
    "out:EL kWh savings": -37873.88721,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178811.73,
    "out:DH kr savings": 222126.5333,
    "out:El kr savings": -64385.60825,
    "out:El kr sold": 21070.80489,
    "out:El kr saved": 57377.50551,
    "out:El kr return": 78448.3104,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 195597.1992,
    "out:% savings (space heating)": 36.96711,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 53.99,
    "out:Etvv": 0,
    "out:Ef": 28.85,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2660750,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.439111,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.193838,
    "out:Electricity (inc PV)": 28.528595,
    "out:Total Energy Use Pre PV": 106.193838,
    "out:Total Energy Use Post PV": 93.528595,
    "out:Primary Energy": 90.5074,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.823925,
    "out:CO2 Operational/m2": -0.751155,
    "out:Total CO2/m2": 22.07277,
    "out:Total CO2 (tons)": 66107.2051,
    "out:Klimatpaverkan":0.001* -98784.19961,
    "out:Initial Cost/MSEK": 2.7225,
    "out:Running cost/(Apt SEK y)": 18913.67647,
    "out:Running Cost over RSP/MSEK": 5.02928,
    "out:LCP/MSEK": 1.94112,
    "out:ROI% old": 24.471311,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 73.4,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2123.000234,
    "out:Return %": 22,
    "out:Return/kSEK/y": 597,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.694338,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 16851.78842,
    "out:PV (% sold (El))": 33.517491,
    "out:PV (kWh self-consumed)": 33425.80689,
    "out:PV (ratio sold/self-consumed)": 0.504155,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 243704.3503,
    "out:EL kWh savings": -36923.72812,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180486.65,
    "out:DH kr savings": 221770.9587,
    "out:El kr savings": -62770.3378,
    "out:El kr sold": 21486.03024,
    "out:El kr saved": 56823.87171,
    "out:El kr return": 78309.90195,
    "out:% solar/total": 69,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 195987.9405,
    "out:% savings (space heating)": 36.84119,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.94,
    "out:Etvv": 0,
    "out:Ef": 28.53,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2722500,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.171283,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.142622,
    "out:Electricity (inc PV)": 28.459824,
    "out:Total Energy Use Pre PV": 102.142622,
    "out:Total Energy Use Post PV": 89.459824,
    "out:Primary Energy": 87.469406,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.823925,
    "out:CO2 Operational/m2": -2.24036,
    "out:Total CO2/m2": 20.583565,
    "out:Total CO2 (tons)": 61647.08612,
    "out:Klimatpaverkan":0.001* -103244.3186,
    "out:Initial Cost/MSEK": 2.797375,
    "out:Running cost/(Apt SEK y)": 17980.29412,
    "out:Running Cost over RSP/MSEK": 4.78095,
    "out:LCP/MSEK": 2.114575,
    "out:ROI% old": 25.084486,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 83.1,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2119.983615,
    "out:Return %": 22,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.643122,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 16877.22885,
    "out:PV (% sold (El))": 33.568091,
    "out:PV (kWh self-consumed)": 33400.36646,
    "out:PV (ratio sold/self-consumed)": 0.505301,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 256486.3518,
    "out:EL kWh savings": -36717.76128,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192500.85,
    "out:DH kr savings": 233402.5802,
    "out:El kr savings": -62420.19418,
    "out:El kr sold": 21518.46678,
    "out:El kr saved": 56780.62299,
    "out:El kr return": 78299.08977,
    "out:% solar/total": 69,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 183205.9389,
    "out:% savings (space heating)": 40.9603,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.77,
    "out:Etvv": 0,
    "out:Ef": 28.46,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2797375,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.074234,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.952891,
    "out:Electricity (inc PV)": 39.230493,
    "out:Total Energy Use Pre PV": 104.952891,
    "out:Total Energy Use Post PV": 103.230493,
    "out:Primary Energy": 109.253589,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.893475,
    "out:CO2 Operational/m2": 28.445085,
    "out:Total CO2/m2": 32.33856,
    "out:Total CO2 (tons)": 96852.9015,
    "out:Klimatpaverkan":0.001* -68038.50321,
    "out:Initial Cost/MSEK": 1.74,
    "out:Running cost/(Apt SEK y)": 20949.32353,
    "out:Running Cost over RSP/MSEK": 5.55437,
    "out:LCP/MSEK": 2.39853,
    "out:ROI% old": 33.978079,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 58.3,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2866.70237,
    "out:Return %": 30,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.453391,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 247792.111,
    "out:EL kWh savings": -68975.55333,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108232.38,
    "out:DH kr savings": 225490.8211,
    "out:El kr savings": -117258.4407,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 191900.1797,
    "out:% savings (space heating)": 38.158506,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.2,
    "out:Etvv": 0,
    "out:Ef": 39.23,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1740000,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.818189,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.898105,
    "out:Electricity (inc PV)": 39.145051,
    "out:Total Energy Use Pre PV": 100.898105,
    "out:Total Energy Use Post PV": 99.145051,
    "out:Primary Energy": 106.19138,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.893475,
    "out:CO2 Operational/m2": 26.997917,
    "out:Total CO2/m2": 30.891392,
    "out:Total CO2 (tons)": 92518.68192,
    "out:Klimatpaverkan":0.001* -72372.72279,
    "out:Initial Cost/MSEK": 1.814875,
    "out:Running cost/(Apt SEK y)": 20015.08824,
    "out:Running Cost over RSP/MSEK": 5.30581,
    "out:LCP/MSEK": 2.572215,
    "out:ROI% old": 34.532799,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 64.6,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2862.86735,
    "out:Return %": 31,
    "out:Return/kSEK/y": 559,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.398605,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 260538.8229,
    "out:EL kWh savings": -68719.65741,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 120266.91,
    "out:DH kr savings": 237090.3289,
    "out:El kr savings": -116823.4176,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 179153.4678,
    "out:% savings (space heating)": 42.266244,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.04,
    "out:Etvv": 0,
    "out:Ef": 39.15,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1814875,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.845379,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.360423,
    "out:Electricity (inc PV)": 38.85444,
    "out:Total Energy Use Pre PV": 101.360423,
    "out:Total Energy Use Post PV": 99.85444,
    "out:Primary Energy": 107.345377,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.270107,
    "out:CO2 Operational/m2": 27.286923,
    "out:Total CO2/m2": 31.55703,
    "out:Total CO2 (tons)": 94512.24539,
    "out:Klimatpaverkan":0.001* -70379.15932,
    "out:Initial Cost/MSEK": 1.876625,
    "out:Running cost/(Apt SEK y)": 20166.05882,
    "out:Running Cost over RSP/MSEK": 5.34625,
    "out:LCP/MSEK": 2.470025,
    "out:ROI% old": 33.088656,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2825.22961,
    "out:Return %": 30,
    "out:Return/kSEK/y": 554,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.860923,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 257462.4234,
    "out:EL kWh savings": -67849.28722,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118947.02,
    "out:DH kr savings": 234290.8053,
    "out:El kr savings": -115343.7883,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 182229.8673,
    "out:% savings (space heating)": 41.274848,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 53.44,
    "out:Etvv": 0,
    "out:Ef": 38.85,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1876625,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.622817,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.295491,
    "out:Electricity (inc PV)": 38.761525,
    "out:Total Energy Use Pre PV": 97.295491,
    "out:Total Energy Use Post PV": 95.761525,
    "out:Primary Energy": 104.286978,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.270107,
    "out:CO2 Operational/m2": 25.838415,
    "out:Total CO2/m2": 30.108522,
    "out:Total CO2 (tons)": 90174.01256,
    "out:Klimatpaverkan":0.001* -74717.39215,
    "out:Initial Cost/MSEK": 1.9515,
    "out:Running cost/(Apt SEK y)": 19230.29412,
    "out:Running Cost over RSP/MSEK": 5.09729,
    "out:LCP/MSEK": 2.64411,
    "out:ROI% old": 33.641594,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 69.8,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2820.68437,
    "out:Return %": 30,
    "out:Return/kSEK/y": 586,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.795991,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 270108.8548,
    "out:EL kWh savings": -67571.00992,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130928.34,
    "out:DH kr savings": 245799.0579,
    "out:El kr savings": -114870.7169,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 169583.4359,
    "out:% savings (space heating)": 45.35027,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 49.31,
    "out:Etvv": 0,
    "out:Ef": 38.76,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1951500,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.074234,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.952891,
    "out:Electricity (inc PV)": 31.511519,
    "out:Total Energy Use Pre PV": 104.952891,
    "out:Total Energy Use Post PV": 95.511519,
    "out:Primary Energy": 95.359436,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.170384,
    "out:CO2 Operational/m2": 24.757019,
    "out:Total CO2/m2": 37.927403,
    "out:Total CO2 (tons)": 113591.2986,
    "out:Klimatpaverkan":0.001* -51300.10606,
    "out:Initial Cost/MSEK": 2.207875,
    "out:Running cost/(Apt SEK y)": 19713.44118,
    "out:Running Cost over RSP/MSEK": 5.23468,
    "out:LCP/MSEK": 2.250345,
    "out:ROI% old": 28.846224,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 69.8,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2344.1186,
    "out:Return %": 26,
    "out:Return/kSEK/y": 569,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.453391,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1685.466016,
    "out:PV (% sold (El))": 6.704641,
    "out:PV (kWh self-consumed)": 23453.33155,
    "out:PV (ratio sold/self-consumed)": 0.071865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 247792.111,
    "out:EL kWh savings": -45857.48535,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149682.07,
    "out:DH kr savings": 225490.8211,
    "out:El kr savings": -77957.7251,
    "out:El kr sold": 2148.96917,
    "out:El kr saved": 39870.66364,
    "out:El kr return": 42019.63281,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 191900.1797,
    "out:% savings (space heating)": 38.158506,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.2,
    "out:Etvv": 0,
    "out:Ef": 31.51,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2207875,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.818189,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.898105,
    "out:Electricity (inc PV)": 31.431959,
    "out:Total Energy Use Pre PV": 100.898105,
    "out:Total Energy Use Post PV": 91.431959,
    "out:Primary Energy": 92.307814,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.170384,
    "out:CO2 Operational/m2": 23.29363,
    "out:Total CO2/m2": 36.464014,
    "out:Total CO2 (tons)": 109208.4977,
    "out:Klimatpaverkan":0.001* -55682.90699,
    "out:Initial Cost/MSEK": 2.28275,
    "out:Running cost/(Apt SEK y)": 18779.32353,
    "out:Running Cost over RSP/MSEK": 4.98615,
    "out:LCP/MSEK": 2.424,
    "out:ROI% old": 29.455387,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 79.1,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2340.477536,
    "out:Return %": 26,
    "out:Return/kSEK/y": 601,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.398605,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1695.542686,
    "out:PV (% sold (El))": 6.744725,
    "out:PV (kWh self-consumed)": 23443.25488,
    "out:PV (ratio sold/self-consumed)": 0.072325,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 260538.8229,
    "out:EL kWh savings": -45619.20582,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161699.5,
    "out:DH kr savings": 237090.3289,
    "out:El kr savings": -77552.6499,
    "out:El kr sold": 2161.816925,
    "out:El kr saved": 39853.5333,
    "out:El kr return": 42015.35022,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 179153.4678,
    "out:% savings (space heating)": 42.266244,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.04,
    "out:Etvv": 0,
    "out:Ef": 31.43,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2282750,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.845379,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.360423,
    "out:Electricity (inc PV)": 31.161598,
    "out:Total Energy Use Pre PV": 101.360423,
    "out:Total Energy Use Post PV": 92.161598,
    "out:Primary Energy": 93.498261,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.547016,
    "out:CO2 Operational/m2": 23.41998,
    "out:Total CO2/m2": 36.966996,
    "out:Total CO2 (tons)": 110714.9119,
    "out:Klimatpaverkan":0.001* -54176.49278,
    "out:Initial Cost/MSEK": 2.344375,
    "out:Running cost/(Apt SEK y)": 18931.58824,
    "out:Running Cost over RSP/MSEK": 5.02691,
    "out:LCP/MSEK": 2.321615,
    "out:ROI% old": 28.432738,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 77.2,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2304.788222,
    "out:Return %": 25,
    "out:Return/kSEK/y": 596,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.860923,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1796.718521,
    "out:PV (% sold (El))": 7.147194,
    "out:PV (kWh self-consumed)": 23342.07905,
    "out:PV (ratio sold/self-consumed)": 0.076973,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 257462.4234,
    "out:EL kWh savings": -44809.4837,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160405.5,
    "out:DH kr savings": 234290.8053,
    "out:El kr savings": -76176.1223,
    "out:El kr sold": 2290.816114,
    "out:El kr saved": 39681.53438,
    "out:El kr return": 41972.35049,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 182229.8673,
    "out:% savings (space heating)": 41.274848,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 53.44,
    "out:Etvv": 0,
    "out:Ef": 31.16,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2344375,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.622817,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.295491,
    "out:Electricity (inc PV)": 31.075206,
    "out:Total Energy Use Pre PV": 97.295491,
    "out:Total Energy Use Post PV": 88.075206,
    "out:Primary Energy": 90.451604,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.547016,
    "out:CO2 Operational/m2": 21.951423,
    "out:Total CO2/m2": 35.498439,
    "out:Total CO2 (tons)": 106316.633,
    "out:Klimatpaverkan":0.001* -58574.77169,
    "out:Initial Cost/MSEK": 2.41925,
    "out:Running cost/(Apt SEK y)": 17995.97059,
    "out:Running Cost over RSP/MSEK": 4.77799,
    "out:LCP/MSEK": 2.49566,
    "out:ROI% old": 29.022631,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 85.2,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2300.483716,
    "out:Return %": 26,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.795991,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1809.157866,
    "out:PV (% sold (El))": 7.196676,
    "out:PV (kWh self-consumed)": 23329.6397,
    "out:PV (ratio sold/self-consumed)": 0.077548,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 270108.8548,
    "out:EL kWh savings": -44550.74257,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172369.47,
    "out:DH kr savings": 245799.0579,
    "out:El kr savings": -75736.26236,
    "out:El kr sold": 2306.67628,
    "out:El kr saved": 39660.38749,
    "out:El kr return": 41967.06377,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 169583.4359,
    "out:% savings (space heating)": 45.35027,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 49.31,
    "out:Etvv": 0,
    "out:Ef": 31.08,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2419250,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.074234,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.952891,
    "out:Electricity (inc PV)": 28.400189,
    "out:Total Energy Use Pre PV": 104.952891,
    "out:Total Energy Use Post PV": 92.400189,
    "out:Primary Energy": 89.759042,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.447293,
    "out:CO2 Operational/m2": -1.343211,
    "out:Total CO2/m2": 21.104082,
    "out:Total CO2 (tons)": 63206.01706,
    "out:Klimatpaverkan":0.001* -101685.3876,
    "out:Initial Cost/MSEK": 2.675625,
    "out:Running cost/(Apt SEK y)": 18647.58824,
    "out:Running Cost over RSP/MSEK": 4.95859,
    "out:LCP/MSEK": 2.058685,
    "out:ROI% old": 25.277459,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 77.2,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2108.812915,
    "out:Return %": 23,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.453391,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 16971.51775,
    "out:PV (% sold (El))": 33.755627,
    "out:PV (kWh self-consumed)": 33306.07756,
    "out:PV (ratio sold/self-consumed)": 0.509562,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 247792.111,
    "out:EL kWh savings": -36539.15646,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185012.94,
    "out:DH kr savings": 225490.8211,
    "out:El kr savings": -62116.56598,
    "out:El kr sold": 21638.68513,
    "out:El kr saved": 56620.33186,
    "out:El kr return": 78259.01699,
    "out:% solar/total": 69,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 191900.1797,
    "out:% savings (space heating)": 38.158506,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.2,
    "out:Etvv": 0,
    "out:Ef": 28.4,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2675625,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.818189,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.898105,
    "out:Electricity (inc PV)": 28.32939,
    "out:Total Energy Use Pre PV": 100.898105,
    "out:Total Energy Use Post PV": 88.32939,
    "out:Primary Energy": 86.72319,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.447293,
    "out:CO2 Operational/m2": -2.836004,
    "out:Total CO2/m2": 19.611289,
    "out:Total CO2 (tons)": 58735.15215,
    "out:Klimatpaverkan":0.001* -106156.2526,
    "out:Initial Cost/MSEK": 2.7505,
    "out:Running cost/(Apt SEK y)": 17713.67647,
    "out:Running Cost over RSP/MSEK": 4.71012,
    "out:LCP/MSEK": 2.23228,
    "out:ROI% old": 25.879866,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 85.2,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2105.588549,
    "out:Return %": 23,
    "out:Return/kSEK/y": 637,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.398605,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 16998.77113,
    "out:PV (% sold (El))": 33.809833,
    "out:PV (kWh self-consumed)": 33278.82418,
    "out:PV (ratio sold/self-consumed)": 0.510798,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 260538.8229,
    "out:EL kWh savings": -36327.11583,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 197007.67,
    "out:DH kr savings": 237090.3289,
    "out:El kr savings": -61756.09691,
    "out:El kr sold": 21673.43319,
    "out:El kr saved": 56574.0011,
    "out:El kr return": 78247.4343,
    "out:% solar/total": 69,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 179153.4678,
    "out:% savings (space heating)": 42.266244,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.04,
    "out:Etvv": 0,
    "out:Ef": 28.33,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2750500,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.845379,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.360423,
    "out:Electricity (inc PV)": 28.088702,
    "out:Total Energy Use Pre PV": 101.360423,
    "out:Total Energy Use Post PV": 89.088702,
    "out:Primary Energy": 87.967049,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.823925,
    "out:CO2 Operational/m2": -2.996413,
    "out:Total CO2/m2": 19.827512,
    "out:Total CO2 (tons)": 59382.73277,
    "out:Klimatpaverkan":0.001* -105508.6719,
    "out:Initial Cost/MSEK": 2.81225,
    "out:Running cost/(Apt SEK y)": 17868.02941,
    "out:Running Cost over RSP/MSEK": 4.75143,
    "out:LCP/MSEK": 2.12922,
    "out:ROI% old": 25.101761,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 83.1,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2073.974208,
    "out:Return %": 22,
    "out:Return/kSEK/y": 632,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.860923,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 17267.3681,
    "out:PV (% sold (El))": 34.344061,
    "out:PV (kWh self-consumed)": 33010.22722,
    "out:PV (ratio sold/self-consumed)": 0.523091,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 257462.4234,
    "out:EL kWh savings": -35606.26335,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195776.05,
    "out:DH kr savings": 234290.8053,
    "out:El kr savings": -60530.6477,
    "out:El kr sold": 22015.89432,
    "out:El kr saved": 56117.38627,
    "out:El kr return": 78133.28059,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 182229.8673,
    "out:% savings (space heating)": 41.274848,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 53.44,
    "out:Etvv": 0,
    "out:Ef": 28.09,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2812250,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.622817,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.295491,
    "out:Electricity (inc PV)": 28.011813,
    "out:Total Energy Use Pre PV": 97.295491,
    "out:Total Energy Use Post PV": 85.011813,
    "out:Primary Energy": 84.937496,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.823925,
    "out:CO2 Operational/m2": -4.499423,
    "out:Total CO2/m2": 18.324502,
    "out:Total CO2 (tons)": 54881.26828,
    "out:Klimatpaverkan":0.001* -110010.1364,
    "out:Initial Cost/MSEK": 2.887125,
    "out:Running cost/(Apt SEK y)": 16932.67647,
    "out:Running Cost over RSP/MSEK": 4.50258,
    "out:LCP/MSEK": 2.303195,
    "out:ROI% old": 25.682098,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 91.8,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2070.160163,
    "out:Return %": 23,
    "out:Return/kSEK/y": 664,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.795991,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 17300.05496,
    "out:PV (% sold (El))": 34.409074,
    "out:PV (kWh self-consumed)": 32977.54035,
    "out:PV (ratio sold/self-consumed)": 0.524601,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 270108.8548,
    "out:EL kWh savings": -35375.98338,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207717.46,
    "out:DH kr savings": 245799.0579,
    "out:El kr savings": -60139.17174,
    "out:El kr sold": 22057.57008,
    "out:El kr saved": 56061.81859,
    "out:El kr return": 78119.38867,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 169583.4359,
    "out:% savings (space heating)": 45.35027,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 49.31,
    "out:Etvv": 0,
    "out:Ef": 28.01,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2887125,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.027661,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.009428,
    "out:Electricity (inc PV)": 52.535485,
    "out:Total Energy Use Pre PV": 79.009428,
    "out:Total Energy Use Post PV": 75.535485,
    "out:Primary Energy": 105.968044,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.375184,
    "out:CO2 Operational/m2": 15.673325,
    "out:Total CO2/m2": 20.048509,
    "out:Total CO2 (tons)": 60044.61137,
    "out:Klimatpaverkan":0.001* -104846.7933,
    "out:Initial Cost/MSEK": 2.976125,
    "out:Running cost/(Apt SEK y)": 13712.05882,
    "out:Running Cost over RSP/MSEK": 3.62139,
    "out:LCP/MSEK": 3.095385,
    "out:ROI% old": 29.143889,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 114.5,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 3920.65996,
    "out:Return %": 26,
    "out:Return/kSEK/y": 773,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 37.509928,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 370725.2191,
    "out:EL kWh savings": -108823.5577,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152359.9,
    "out:DH kr savings": 337359.9494,
    "out:El kr savings": -185000.0481,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 68967.07159,
    "out:% savings (space heating)": 77.774764,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 16.29,
    "out:Etvv": 0,
    "out:Ef": 52.54,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2976125,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.743026,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.660276,
    "out:Electricity (inc PV)": 52.06929,
    "out:Total Energy Use Pre PV": 75.660276,
    "out:Total Energy Use Post PV": 72.06929,
    "out:Primary Energy": 103.100019,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.375184,
    "out:CO2 Operational/m2": 14.547282,
    "out:Total CO2/m2": 18.922466,
    "out:Total CO2 (tons)": 56672.15039,
    "out:Klimatpaverkan":0.001* -108219.2543,
    "out:Initial Cost/MSEK": 3.051,
    "out:Running cost/(Apt SEK y)": 12965.26471,
    "out:Running Cost over RSP/MSEK": 3.42285,
    "out:LCP/MSEK": 3.21905,
    "out:ROI% old": 29.35829,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 126.4,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3896.21932,
    "out:Return %": 26,
    "out:Return/kSEK/y": 799,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 37.160776,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 380562.5907,
    "out:EL kWh savings": -107427.3193,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163685.51,
    "out:DH kr savings": 346311.9575,
    "out:El kr savings": -182626.4428,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 59129.70004,
    "out:% savings (space heating)": 80.944942,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 13.39,
    "out:Etvv": 0,
    "out:Ef": 52.07,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3051000,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.505834,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.809982,
    "out:Electricity (inc PV)": 51.778973,
    "out:Total Energy Use Pre PV": 75.809982,
    "out:Total Energy Use Post PV": 72.778973,
    "out:Primary Energy": 103.728866,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.751816,
    "out:CO2 Operational/m2": 14.79502,
    "out:Total CO2/m2": 19.546836,
    "out:Total CO2 (tons)": 58542.11757,
    "out:Klimatpaverkan":0.001* -106349.2871,
    "out:Initial Cost/MSEK": 3.11275,
    "out:Running cost/(Apt SEK y)": 13069.44118,
    "out:Running Cost over RSP/MSEK": 3.45098,
    "out:LCP/MSEK": 3.12917,
    "out:ROI% old": 28.646787,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 123.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 21,
    "out:El bought/kWh/m2": 3836.69874,
    "out:Return %": 26,
    "out:Return/kSEK/y": 795,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 36.310482,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 378278.0063,
    "out:EL kWh savings": -106557.8296,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163084.68,
    "out:DH kr savings": 344232.9858,
    "out:El kr savings": -181148.3104,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 61414.28439,
    "out:% savings (space heating)": 80.208715,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 15.04,
    "out:Etvv": 0,
    "out:Ef": 51.78,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3112750,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.350401,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.421677,
    "out:Electricity (inc PV)": 51.28232,
    "out:Total Energy Use Pre PV": 71.421677,
    "out:Total Energy Use Post PV": 68.28232,
    "out:Primary Energy": 100.876372,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.751816,
    "out:CO2 Operational/m2": 13.303825,
    "out:Total CO2/m2": 18.055641,
    "out:Total CO2 (tons)": 54076.03861,
    "out:Klimatpaverkan":0.001* -110815.3661,
    "out:Initial Cost/MSEK": 3.187625,
    "out:Running cost/(Apt SEK y)": 12085.23529,
    "out:Running Cost over RSP/MSEK": 3.1893,
    "out:LCP/MSEK": 3.315975,
    "out:ROI% old": 29.146644,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3809.51739,
    "out:Return %": 26,
    "out:Return/kSEK/y": 829,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 35.922177,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 387728.4222,
    "out:EL kWh savings": -105070.3706,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174213.23,
    "out:DH kr savings": 352832.8642,
    "out:El kr savings": -178619.63,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 51963.86849,
    "out:% savings (space heating)": 83.254194,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 12.24,
    "out:Etvv": 0,
    "out:Ef": 51.28,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3187625,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.027661,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.009428,
    "out:Electricity (inc PV)": 44.235272,
    "out:Total Energy Use Pre PV": 79.009428,
    "out:Total Energy Use Post PV": 67.235272,
    "out:Primary Energy": 91.027661,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.652093,
    "out:CO2 Operational/m2": 14.458282,
    "out:Total CO2/m2": 28.110375,
    "out:Total CO2 (tons)": 84189.62938,
    "out:Klimatpaverkan":0.001* -80701.77533,
    "out:Initial Cost/MSEK": 3.444,
    "out:Running cost/(Apt SEK y)": 12456.41177,
    "out:Running Cost over RSP/MSEK": 3.29691,
    "out:LCP/MSEK": 2.95199,
    "out:ROI% old": 26.530571,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 143.3,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 3371.91529,
    "out:Return %": 24,
    "out:Return/kSEK/y": 816,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 37.509928,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 102.133759,
    "out:PV (% sold (El))": 0.406279,
    "out:PV (kWh self-consumed)": 25036.66381,
    "out:PV (ratio sold/self-consumed)": 0.004079,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 370725.2191,
    "out:EL kWh savings": -83964.69841,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194750.18,
    "out:DH kr savings": 337359.9494,
    "out:El kr savings": -142739.9873,
    "out:El kr sold": 130.2205424,
    "out:El kr saved": 42562.32847,
    "out:El kr return": 42692.54902,
    "out:% solar/total": 52,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 68967.07159,
    "out:% savings (space heating)": 77.774764,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 16.29,
    "out:Etvv": 0,
    "out:Ef": 44.24,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3444000,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.743026,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.660276,
    "out:Electricity (inc PV)": 43.780026,
    "out:Total Energy Use Pre PV": 75.660276,
    "out:Total Energy Use Post PV": 63.780026,
    "out:Primary Energy": 88.179344,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.652093,
    "out:CO2 Operational/m2": 13.315489,
    "out:Total CO2/m2": 26.967582,
    "out:Total CO2 (tons)": 80767.00271,
    "out:Klimatpaverkan":0.001* -84124.402,
    "out:Initial Cost/MSEK": 3.518875,
    "out:Running cost/(Apt SEK y)": 11709.76471,
    "out:Running Cost over RSP/MSEK": 3.0984,
    "out:LCP/MSEK": 3.075625,
    "out:ROI% old": 26.771949,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 154.7,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3347.588565,
    "out:Return %": 24,
    "out:Return/kSEK/y": 841,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 37.160776,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 115.145233,
    "out:PV (% sold (El))": 0.458038,
    "out:PV (kWh self-consumed)": 25023.65233,
    "out:PV (ratio sold/self-consumed)": 0.004601,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 380562.5907,
    "out:EL kWh savings": -82601.25193,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 206036.64,
    "out:DH kr savings": 346311.9575,
    "out:El kr savings": -140422.1283,
    "out:El kr sold": 146.8101719,
    "out:El kr saved": 42540.20897,
    "out:El kr return": 42687.01914,
    "out:% solar/total": 52,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 59129.70004,
    "out:% savings (space heating)": 80.944942,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 13.39,
    "out:Etvv": 0,
    "out:Ef": 43.78,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3518875,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.505834,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.809982,
    "out:Electricity (inc PV)": 43.496843,
    "out:Total Energy Use Pre PV": 75.809982,
    "out:Total Energy Use Post PV": 64.496843,
    "out:Primary Energy": 88.821032,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.028725,
    "out:CO2 Operational/m2": 13.514279,
    "out:Total CO2/m2": 27.543004,
    "out:Total CO2 (tons)": 82490.37228,
    "out:Klimatpaverkan":0.001* -82401.03243,
    "out:Initial Cost/MSEK": 3.580625,
    "out:Running cost/(Apt SEK y)": 11814.38235,
    "out:Running Cost over RSP/MSEK": 3.12662,
    "out:LCP/MSEK": 2.985655,
    "out:ROI% old": 26.197661,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 154.7,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 21,
    "out:El bought/kWh/m2": 3288.407645,
    "out:Return %": 23,
    "out:Return/kSEK/y": 838,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 36.310482,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 151.496362,
    "out:PV (% sold (El))": 0.60264,
    "out:PV (kWh self-consumed)": 24987.3012,
    "out:PV (ratio sold/self-consumed)": 0.006063,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 378278.0063,
    "out:EL kWh savings": -81753.12835,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205445.83,
    "out:DH kr savings": 344232.9858,
    "out:El kr savings": -138980.3182,
    "out:El kr sold": 193.1578613,
    "out:El kr saved": 42478.41205,
    "out:El kr return": 42671.56991,
    "out:% solar/total": 52,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 61414.28439,
    "out:% savings (space heating)": 80.208715,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 15.04,
    "out:Etvv": 0,
    "out:Ef": 43.5,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3580625,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.350401,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.421677,
    "out:Electricity (inc PV)": 43.012871,
    "out:Total Energy Use Pre PV": 71.421677,
    "out:Total Energy Use Post PV": 60.012871,
    "out:Primary Energy": 85.991364,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.028725,
    "out:CO2 Operational/m2": 11.99695,
    "out:Total CO2/m2": 26.025675,
    "out:Total CO2 (tons)": 77946.02287,
    "out:Klimatpaverkan":0.001* -86945.38184,
    "out:Initial Cost/MSEK": 3.6555,
    "out:Running cost/(Apt SEK y)": 10830.41177,
    "out:Running Cost over RSP/MSEK": 2.86499,
    "out:LCP/MSEK": 3.17241,
    "out:ROI% old": 26.68351,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 171.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3261.413476,
    "out:Return %": 24,
    "out:Return/kSEK/y": 871,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 35.922177,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 169.783875,
    "out:PV (% sold (El))": 0.675386,
    "out:PV (kWh self-consumed)": 24969.01369,
    "out:PV (ratio sold/self-consumed)": 0.0068,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 387728.4222,
    "out:EL kWh savings": -80303.64846,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 216533.14,
    "out:DH kr savings": 352832.8642,
    "out:El kr savings": -136516.2024,
    "out:El kr sold": 216.4744406,
    "out:El kr saved": 42447.32327,
    "out:El kr return": 42663.79772,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 51963.86849,
    "out:% savings (space heating)": 83.254194,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 12.24,
    "out:Etvv": 0,
    "out:Ef": 43.01,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3655500,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.027661,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.009428,
    "out:Electricity (inc PV)": 39.673422,
    "out:Total Energy Use Pre PV": 79.009428,
    "out:Total Energy Use Post PV": 62.673422,
    "out:Primary Energy": 82.816331,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.929002,
    "out:CO2 Operational/m2": -3.246256,
    "out:Total CO2/m2": 19.682746,
    "out:Total CO2 (tons)": 58949.16346,
    "out:Klimatpaverkan":0.001* -105942.2412,
    "out:Initial Cost/MSEK": 3.91175,
    "out:Running cost/(Apt SEK y)": 11328.52941,
    "out:Running Cost over RSP/MSEK": 3.00446,
    "out:LCP/MSEK": 2.77669,
    "out:ROI% old": 24.426188,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 158.7,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 3020.037585,
    "out:Return %": 22,
    "out:Return/kSEK/y": 854,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 37.509928,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 10426.35222,
    "out:PV (% sold (El))": 20.737571,
    "out:PV (kWh self-consumed)": 39851.24309,
    "out:PV (ratio sold/self-consumed)": 0.261632,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 370725.2191,
    "out:EL kWh savings": -70302.11082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 231139.96,
    "out:DH kr savings": 337359.9494,
    "out:El kr savings": -119513.5884,
    "out:El kr sold": 13293.59908,
    "out:El kr saved": 67747.11325,
    "out:El kr return": 81040.71234,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 68967.07159,
    "out:% savings (space heating)": 77.774764,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 16.29,
    "out:Etvv": 0,
    "out:Ef": 39.67,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3911750,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.743026,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.660276,
    "out:Electricity (inc PV)": 39.269196,
    "out:Total Energy Use Pre PV": 75.660276,
    "out:Total Energy Use Post PV": 59.269196,
    "out:Primary Energy": 80.05985,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.929002,
    "out:CO2 Operational/m2": -4.587721,
    "out:Total CO2/m2": 18.341281,
    "out:Total CO2 (tons)": 54931.52082,
    "out:Klimatpaverkan":0.001* -109959.8839,
    "out:Initial Cost/MSEK": 3.986625,
    "out:Running cost/(Apt SEK y)": 10583.32353,
    "out:Running Cost over RSP/MSEK": 2.80634,
    "out:LCP/MSEK": 2.899935,
    "out:ROI% old": 24.677372,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 176.3,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 2998.376759,
    "out:Return %": 22,
    "out:Return/kSEK/y": 880,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 37.160776,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 10556.20057,
    "out:PV (% sold (El))": 20.995834,
    "out:PV (kWh self-consumed)": 39721.39474,
    "out:PV (ratio sold/self-consumed)": 0.265756,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 380562.5907,
    "out:EL kWh savings": -69091.46752,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 242315.62,
    "out:DH kr savings": 346311.9575,
    "out:El kr savings": -117455.4948,
    "out:El kr sold": 13459.15573,
    "out:El kr saved": 67526.37105,
    "out:El kr return": 80985.52679,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 59129.70004,
    "out:% savings (space heating)": 80.944942,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 13.39,
    "out:Etvv": 0,
    "out:Ef": 39.27,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3986625,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.505834,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.809982,
    "out:Electricity (inc PV)": 39.017763,
    "out:Total Energy Use Pre PV": 75.809982,
    "out:Total Energy Use Post PV": 60.017763,
    "out:Primary Energy": 80.758688,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.305634,
    "out:CO2 Operational/m2": -4.870513,
    "out:Total CO2/m2": 18.435121,
    "out:Total CO2 (tons)": 55212.56847,
    "out:Klimatpaverkan":0.001* -109678.8362,
    "out:Initial Cost/MSEK": 4.048375,
    "out:Running cost/(Apt SEK y)": 10691.5,
    "out:Running Cost over RSP/MSEK": 2.8355,
    "out:LCP/MSEK": 2.809025,
    "out:ROI% old": 24.198069,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 171.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 21,
    "out:El bought/kWh/m2": 2945.727215,
    "out:Return %": 22,
    "out:Return/kSEK/y": 876,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 36.310482,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 10875.71709,
    "out:PV (% sold (El))": 21.631339,
    "out:PV (kWh self-consumed)": 39401.87822,
    "out:PV (ratio sold/self-consumed)": 0.27602,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 378278.0063,
    "out:EL kWh savings": -68338.43412,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 241924.19,
    "out:DH kr savings": 344232.9858,
    "out:El kr savings": -116175.338,
    "out:El kr sold": 13866.53929,
    "out:El kr saved": 66983.19298,
    "out:El kr return": 80849.73227,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 61414.28439,
    "out:% savings (space heating)": 80.208715,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 15.04,
    "out:Etvv": 0,
    "out:Ef": 39.02,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4048375,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.350401,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.421677,
    "out:Electricity (inc PV)": 38.588296,
    "out:Total Energy Use Pre PV": 71.421677,
    "out:Total Energy Use Post PV": 55.588296,
    "out:Primary Energy": 78.027129,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.305634,
    "out:CO2 Operational/m2": -6.606632,
    "out:Total CO2/m2": 16.699002,
    "out:Total CO2 (tons)": 50012.95035,
    "out:Klimatpaverkan":0.001* -114878.4544,
    "out:Initial Cost/MSEK": 4.12325,
    "out:Running cost/(Apt SEK y)": 9709.147059,
    "out:Running Cost over RSP/MSEK": 2.57429,
    "out:LCP/MSEK": 2.99536,
    "out:ROI% old": 24.663658,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 191.1,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 2921.731596,
    "out:Return %": 22,
    "out:Return/kSEK/y": 909,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 35.922177,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 11023.22975,
    "out:PV (% sold (El))": 21.924735,
    "out:PV (kWh self-consumed)": 39254.36556,
    "out:PV (ratio sold/self-consumed)": 0.280815,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 387728.4222,
    "out:EL kWh savings": -67052.19488,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 252898.75,
    "out:DH kr savings": 352832.8642,
    "out:El kr savings": -113988.7313,
    "out:El kr sold": 14054.61793,
    "out:El kr saved": 66732.42145,
    "out:El kr return": 80787.03939,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 51963.86849,
    "out:% savings (space heating)": 83.254194,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 12.24,
    "out:Etvv": 0,
    "out:Ef": 38.59,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4123250,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.695347,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.401039,
    "out:Electricity (inc PV)": 51.523491,
    "out:Total Energy Use Pre PV": 74.401039,
    "out:Total Energy Use Post PV": 71.523491,
    "out:Primary Energy": 102.984162,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.375184,
    "out:CO2 Operational/m2": 14.381052,
    "out:Total CO2/m2": 18.756236,
    "out:Total CO2 (tons)": 56174.29712,
    "out:Klimatpaverkan":0.001* -108717.1076,
    "out:Initial Cost/MSEK": 3.065875,
    "out:Running cost/(Apt SEK y)": 12776.67647,
    "out:Running Cost over RSP/MSEK": 3.37329,
    "out:LCP/MSEK": 3.253735,
    "out:ROI% old": 29.446779,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 126.4,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3808.07273,
    "out:Return %": 26,
    "out:Return/kSEK/y": 805,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 35.901539,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 380705.3877,
    "out:EL kWh savings": -105792.6696,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166594.36,
    "out:DH kr savings": 346441.9028,
    "out:El kr savings": -179847.5384,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 58986.90303,
    "out:% savings (space heating)": 80.99096,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 14.63,
    "out:Etvv": 0,
    "out:Ef": 51.52,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3065875,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.585469,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.99893,
    "out:Electricity (inc PV)": 51.015756,
    "out:Total Energy Use Pre PV": 70.99893,
    "out:Total Energy Use Post PV": 68.015756,
    "out:Primary Energy": 100.13732,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.375184,
    "out:CO2 Operational/m2": 13.248019,
    "out:Total CO2/m2": 17.623203,
    "out:Total CO2 (tons)": 52780.90132,
    "out:Klimatpaverkan":0.001* -112110.5034,
    "out:Initial Cost/MSEK": 3.14075,
    "out:Running cost/(Apt SEK y)": 12021.94118,
    "out:Running Cost over RSP/MSEK": 3.17266,
    "out:LCP/MSEK": 3.37949,
    "out:ROI% old": 29.657338,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3779.9251,
    "out:Return %": 26,
    "out:Return/kSEK/y": 831,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 35.49943,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 390019.3679,
    "out:EL kWh savings": -104272.0204,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177655.19,
    "out:DH kr savings": 354917.6248,
    "out:El kr savings": -177262.4346,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 49672.92283,
    "out:% savings (space heating)": 83.992471,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 11.87,
    "out:Etvv": 0,
    "out:Ef": 51.02,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3140750,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.812438,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.400708,
    "out:Electricity (inc PV)": 50.90246,
    "out:Total Energy Use Pre PV": 71.400708,
    "out:Total Energy Use Post PV": 68.90246,
    "out:Primary Energy": 101.200887,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.751816,
    "out:CO2 Operational/m2": 13.529033,
    "out:Total CO2/m2": 18.280849,
    "out:Total CO2 (tons)": 54750.52901,
    "out:Klimatpaverkan":0.001* -110140.8757,
    "out:Initial Cost/MSEK": 3.2025,
    "out:Running cost/(Apt SEK y)": 12163.85294,
    "out:Running Cost over RSP/MSEK": 3.21071,
    "out:LCP/MSEK": 3.27969,
    "out:ROI% old": 28.915758,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 136.2,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 3738.04956,
    "out:Return %": 26,
    "out:Return/kSEK/y": 826,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 34.901208,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 386344.6369,
    "out:EL kWh savings": -103932.7026,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 174888.03,
    "out:DH kr savings": 351573.6196,
    "out:El kr savings": -176685.5945,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 53347.65379,
    "out:% savings (space heating)": 82.808257,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 13.68,
    "out:Etvv": 0,
    "out:Ef": 50.9,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3202500,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.812058,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.965418,
    "out:Electricity (inc PV)": 50.368113,
    "out:Total Energy Use Pre PV": 67.965418,
    "out:Total Energy Use Post PV": 65.368113,
    "out:Primary Energy": 98.367617,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.751816,
    "out:CO2 Operational/m2": 12.391619,
    "out:Total CO2/m2": 17.143435,
    "out:Total CO2 (tons)": 51344.01227,
    "out:Klimatpaverkan":0.001* -113547.3924,
    "out:Initial Cost/MSEK": 3.277375,
    "out:Running cost/(Apt SEK y)": 11404.14706,
    "out:Running Cost over RSP/MSEK": 3.00878,
    "out:LCP/MSEK": 3.406745,
    "out:ROI% old": 29.135338,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 150.8,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3707.57926,
    "out:Return %": 26,
    "out:Return/kSEK/y": 852,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 34.465918,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 395330.6743,
    "out:EL kWh savings": -102332.3513,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185785.92,
    "out:DH kr savings": 359750.9136,
    "out:El kr savings": -173964.9972,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 44361.61643,
    "out:% savings (space heating)": 85.704085,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 11.01,
    "out:Etvv": 0,
    "out:Ef": 50.37,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3277375,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.695347,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.401039,
    "out:Electricity (inc PV)": 43.247783,
    "out:Total Energy Use Pre PV": 74.401039,
    "out:Total Energy Use Post PV": 63.247783,
    "out:Primary Energy": 88.087887,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.652093,
    "out:CO2 Operational/m2": 13.072724,
    "out:Total CO2/m2": 26.724817,
    "out:Total CO2 (tons)": 80039.92968,
    "out:Klimatpaverkan":0.001* -84851.47503,
    "out:Initial Cost/MSEK": 3.53375,
    "out:Running cost/(Apt SEK y)": 11521.88235,
    "out:Running Cost over RSP/MSEK": 3.04898,
    "out:LCP/MSEK": 3.11017,
    "out:ROI% old": 26.859043,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 158.7,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3259.979337,
    "out:Return %": 24,
    "out:Return/kSEK/y": 848,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 35.901539,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 170.80231,
    "out:PV (% sold (El))": 0.679437,
    "out:PV (kWh self-consumed)": 24967.99526,
    "out:PV (ratio sold/self-consumed)": 0.006841,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 380705.3877,
    "out:EL kWh savings": -81007.20201,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 208947.43,
    "out:DH kr savings": 346441.9028,
    "out:El kr savings": -137712.2434,
    "out:El kr sold": 217.7729455,
    "out:El kr saved": 42445.59194,
    "out:El kr return": 42663.36488,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 58986.90303,
    "out:% savings (space heating)": 80.99096,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 14.63,
    "out:Etvv": 0,
    "out:Ef": 43.25,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3533750,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.585469,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.99893,
    "out:Electricity (inc PV)": 42.753312,
    "out:Total Energy Use Pre PV": 70.99893,
    "out:Total Energy Use Post PV": 59.753312,
    "out:Primary Energy": 85.264921,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.652093,
    "out:CO2 Operational/m2": 11.910122,
    "out:Total CO2/m2": 25.562215,
    "out:Total CO2 (tons)": 76557.97573,
    "out:Klimatpaverkan":0.001* -88333.42898,
    "out:Initial Cost/MSEK": 3.608625,
    "out:Running cost/(Apt SEK y)": 10767.38235,
    "out:Running Cost over RSP/MSEK": 2.8484,
    "out:LCP/MSEK": 3.235875,
    "out:ROI% old": 27.095797,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 171.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3232.049964,
    "out:Return %": 24,
    "out:Return/kSEK/y": 873,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 35.49943,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 191.533531,
    "out:PV (% sold (El))": 0.761904,
    "out:PV (kWh self-consumed)": 24947.26404,
    "out:PV (ratio sold/self-consumed)": 0.007678,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 390019.3679,
    "out:EL kWh savings": -79526.27797,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 219967.16,
    "out:DH kr savings": 354917.6248,
    "out:El kr savings": -135194.6725,
    "out:El kr sold": 244.2052522,
    "out:El kr saved": 42410.34886,
    "out:El kr return": 42654.55411,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 49672.92283,
    "out:% savings (space heating)": 83.992471,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 11.87,
    "out:Etvv": 0,
    "out:Ef": 42.75,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3608625,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.812438,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.400708,
    "out:Electricity (inc PV)": 42.643016,
    "out:Total Energy Use Pre PV": 71.400708,
    "out:Total Energy Use Post PV": 60.643016,
    "out:Primary Energy": 86.333888,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.028725,
    "out:CO2 Operational/m2": 12.142501,
    "out:Total CO2/m2": 26.171226,
    "out:Total CO2 (tons)": 78381.94322,
    "out:Klimatpaverkan":0.001* -86509.46149,
    "out:Initial Cost/MSEK": 3.67025,
    "out:Running cost/(Apt SEK y)": 10909.73529,
    "out:Running Cost over RSP/MSEK": 2.88655,
    "out:LCP/MSEK": 3.1361,
    "out:ROI% old": 26.492356,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 167.2,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 3190.546774,
    "out:Return %": 24,
    "out:Return/kSEK/y": 869,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 34.901208,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 225.443337,
    "out:PV (% sold (El))": 0.896794,
    "out:PV (kWh self-consumed)": 24913.35423,
    "out:PV (ratio sold/self-consumed)": 0.009049,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 386344.6369,
    "out:EL kWh savings": -79195.94515,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217227.95,
    "out:DH kr savings": 351573.6196,
    "out:El kr savings": -134633.1068,
    "out:El kr sold": 287.4402544,
    "out:El kr saved": 42352.70219,
    "out:El kr return": 42640.14244,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 53347.65379,
    "out:% savings (space heating)": 82.808257,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 13.68,
    "out:Etvv": 0,
    "out:Ef": 42.64,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3670250,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.812058,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.965418,
    "out:Electricity (inc PV)": 42.123407,
    "out:Total Energy Use Pre PV": 67.965418,
    "out:Total Energy Use Post PV": 57.123407,
    "out:Primary Energy": 83.527147,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.028725,
    "out:CO2 Operational/m2": 10.966217,
    "out:Total CO2/m2": 24.994942,
    "out:Total CO2 (tons)": 74859.01214,
    "out:Klimatpaverkan":0.001* -90032.39257,
    "out:Initial Cost/MSEK": 3.745125,
    "out:Running cost/(Apt SEK y)": 10150.35294,
    "out:Running Cost over RSP/MSEK": 2.68469,
    "out:LCP/MSEK": 3.263085,
    "out:ROI% old": 26.732695,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 186,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3160.384276,
    "out:Return %": 24,
    "out:Return/kSEK/y": 894,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 34.465918,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 252.033214,
    "out:PV (% sold (El))": 1.002567,
    "out:PV (kWh self-consumed)": 24886.76435,
    "out:PV (ratio sold/self-consumed)": 0.010127,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 395330.6743,
    "out:EL kWh savings": -77639.73364,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 228084.71,
    "out:DH kr savings": 359750.9136,
    "out:El kr savings": -131987.5472,
    "out:El kr sold": 321.3423474,
    "out:El kr saved": 42307.4994,
    "out:El kr return": 42628.84175,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 44361.61643,
    "out:% savings (space heating)": 85.704085,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 11.01,
    "out:Etvv": 0,
    "out:Ef": 42.12,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3745125,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.695347,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.401039,
    "out:Electricity (inc PV)": 38.796745,
    "out:Total Energy Use Pre PV": 74.401039,
    "out:Total Energy Use Post PV": 58.796745,
    "out:Primary Energy": 80.076019,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.929002,
    "out:CO2 Operational/m2": -5.54247,
    "out:Total CO2/m2": 17.386532,
    "out:Total CO2 (tons)": 52072.07962,
    "out:Klimatpaverkan":0.001* -112819.3251,
    "out:Initial Cost/MSEK": 4.0015,
    "out:Running cost/(Apt SEK y)": 10400.70588,
    "out:Running Cost over RSP/MSEK": 2.75831,
    "out:LCP/MSEK": 2.93309,
    "out:ROI% old": 24.757109,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 176.3,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 2920.457108,
    "out:Return %": 22,
    "out:Return/kSEK/y": 886,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 35.901539,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 11031.10396,
    "out:PV (% sold (El))": 21.940397,
    "out:PV (kWh self-consumed)": 39246.49135,
    "out:PV (ratio sold/self-consumed)": 0.281072,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 380705.3877,
    "out:EL kWh savings": -67676.49263,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245456.52,
    "out:DH kr savings": 346441.9028,
    "out:El kr savings": -115050.0375,
    "out:El kr sold": 14064.65755,
    "out:El kr saved": 66719.0353,
    "out:El kr return": 80783.69285,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 58986.90303,
    "out:% savings (space heating)": 80.99096,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 14.63,
    "out:Etvv": 0,
    "out:Ef": 38.8,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4001500,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.585469,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.99893,
    "out:Electricity (inc PV)": 38.35806,
    "out:Total Energy Use Pre PV": 70.99893,
    "out:Total Energy Use Post PV": 55.35806,
    "out:Primary Energy": 77.353467,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.929002,
    "out:CO2 Operational/m2": -6.931058,
    "out:Total CO2/m2": 15.997944,
    "out:Total CO2 (tons)": 47913.30518,
    "out:Klimatpaverkan":0.001* -116978.0995,
    "out:Initial Cost/MSEK": 4.076375,
    "out:Running cost/(Apt SEK y)": 9647.882353,
    "out:Running Cost over RSP/MSEK": 2.55818,
    "out:LCP/MSEK": 3.058345,
    "out:ROI% old": 25.003728,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 196.4,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 2895.641345,
    "out:Return %": 22,
    "out:Return/kSEK/y": 912,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 35.49943,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 11185.17594,
    "out:PV (% sold (El))": 22.24684,
    "out:PV (kWh self-consumed)": 39092.41938,
    "out:PV (ratio sold/self-consumed)": 0.286121,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 390019.3679,
    "out:EL kWh savings": -66362.64579,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 256362.23,
    "out:DH kr savings": 354917.6248,
    "out:El kr savings": -112816.4978,
    "out:El kr sold": 14261.09932,
    "out:El kr saved": 66457.11294,
    "out:El kr return": 80718.21226,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 49672.92283,
    "out:% savings (space heating)": 83.992471,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 11.87,
    "out:Etvv": 0,
    "out:Ef": 38.36,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4076375,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.812438,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.400708,
    "out:Electricity (inc PV)": 38.26026,
    "out:Total Energy Use Pre PV": 71.400708,
    "out:Total Energy Use Post PV": 56.26026,
    "out:Primary Energy": 78.444927,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.305634,
    "out:CO2 Operational/m2": -7.03364,
    "out:Total CO2/m2": 16.271994,
    "out:Total CO2 (tons)": 48734.07573,
    "out:Klimatpaverkan":0.001* -116157.329,
    "out:Initial Cost/MSEK": 4.138125,
    "out:Running cost/(Apt SEK y)": 9792.676471,
    "out:Running Cost over RSP/MSEK": 2.59697,
    "out:LCP/MSEK": 2.957805,
    "out:ROI% old": 24.496705,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 191.1,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 2858.781238,
    "out:Return %": 22,
    "out:Return/kSEK/y": 907,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 34.901208,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 11415.98322,
    "out:PV (% sold (El))": 22.705905,
    "out:PV (kWh self-consumed)": 38861.61209,
    "out:PV (ratio sold/self-consumed)": 0.29376,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 386344.6369,
    "out:EL kWh savings": -66069.73807,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 253810.44,
    "out:DH kr savings": 351573.6196,
    "out:El kr savings": -112318.5547,
    "out:El kr sold": 14555.37861,
    "out:El kr saved": 66064.74055,
    "out:El kr return": 80620.11916,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 53347.65379,
    "out:% savings (space heating)": 82.808257,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 13.68,
    "out:Etvv": 0,
    "out:Ef": 38.26,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4138125,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.812058,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.965418,
    "out:Electricity (inc PV)": 37.799632,
    "out:Total Energy Use Pre PV": 67.965418,
    "out:Total Energy Use Post PV": 52.799632,
    "out:Primary Energy": 75.744352,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.305634,
    "out:CO2 Operational/m2": -8.452907,
    "out:Total CO2/m2": 14.852727,
    "out:Total CO2 (tons)": 44483.41871,
    "out:Klimatpaverkan":0.001* -120407.986,
    "out:Initial Cost/MSEK": 4.213,
    "out:Running cost/(Apt SEK y)": 9035.088235,
    "out:Running Cost over RSP/MSEK": 2.39559,
    "out:LCP/MSEK": 3.08431,
    "out:ROI% old": 24.744193,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 207.5,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 2832.004417,
    "out:Return %": 22,
    "out:Return/kSEK/y": 932,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 34.465918,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 11586.2489,
    "out:PV (% sold (El))": 23.044557,
    "out:PV (kWh self-consumed)": 38691.34641,
    "out:PV (ratio sold/self-consumed)": 0.299453,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 395330.6743,
    "out:EL kWh savings": -64690.17268,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 264550.09,
    "out:DH kr savings": 359750.9136,
    "out:El kr savings": -109973.2935,
    "out:El kr sold": 14772.46734,
    "out:El kr saved": 65775.2889,
    "out:El kr return": 80547.75625,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 44361.61643,
    "out:% savings (space heating)": 85.704085,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 11.01,
    "out:Etvv": 0,
    "out:Ef": 37.8,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4213000,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z01000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.083384,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 146.2,
    "out:Total Energy Use Post PV": 146.2,
    "out:Primary Energy": 107.618369,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.182573,
    "out:CO2 Operational/m2": 48.93645,
    "out:Total CO2/m2": 61.119023,
    "out:Total CO2 (tons)": 183049.4219,
    "out:Klimatpaverkan":0.001* 18158.01723,
    "out:Initial Cost/MSEK": 2.647875,
    "out:Running cost/(Apt SEK y)": 32522.35294,
    "out:Running Cost over RSP/MSEK": 8.6457,
    "out:LCP/MSEK": -1.600675,
    "out:ROI% old": 5.649814,
    "out:Payback discounted": 22,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 11.6,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 5,
    "out:Return/kSEK/y": 134,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 49497.93054,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 45043.12,
    "out:DH kr savings": 45043.11679,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 260811.8105,
    "out:% savings (space heating)": 15.951137,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.08,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2647875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.397775,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 143.2,
    "out:Primary Energy": 105.038442,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.182573,
    "out:CO2 Operational/m2": 47.856498,
    "out:Total CO2/m2": 60.039071,
    "out:Total CO2 (tons)": 179815.002,
    "out:Klimatpaverkan":0.001* 14923.59724,
    "out:Initial Cost/MSEK": 2.72275,
    "out:Running cost/(Apt SEK y)": 31827.79412,
    "out:Running Cost over RSP/MSEK": 8.4609,
    "out:LCP/MSEK": -1.49075,
    "out:ROI% old": 6.464053,
    "out:Payback discounted": 19,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 6,
    "out:Return/kSEK/y": 157,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 60536.20381,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 55087.95,
    "out:DH kr savings": 55087.94547,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 249773.5373,
    "out:% savings (space heating)": 19.508316,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.4,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2722750,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.083384,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 140.2,
    "out:Primary Energy": 104.993369,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.559205,
    "out:CO2 Operational/m2": 46.776546,
    "out:Total CO2/m2": 59.335751,
    "out:Total CO2 (tons)": 177708.5822,
    "out:Klimatpaverkan":0.001* 12817.17746,
    "out:Initial Cost/MSEK": 2.7845,
    "out:Running cost/(Apt SEK y)": 31133.26471,
    "out:Running Cost over RSP/MSEK": 8.2761,
    "out:LCP/MSEK": -1.3677,
    "out:ROI% old": 7.268809,
    "out:Payback discounted": 17,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 16.4,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 7,
    "out:Return/kSEK/y": 181,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 68905.31298,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62703.83,
    "out:DH kr savings": 62703.83482,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 260811.8105,
    "out:% savings (space heating)": 15.951137,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.08,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2784500,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.397775,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 136.2,
    "out:Primary Energy": 102.413442,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.559205,
    "out:CO2 Operational/m2": 45.33661,
    "out:Total CO2/m2": 57.895815,
    "out:Total CO2 (tons)": 173396.0222,
    "out:Klimatpaverkan":0.001* 8504.61748,
    "out:Initial Cost/MSEK": 2.859375,
    "out:Running cost/(Apt SEK y)": 30207.23529,
    "out:Running Cost over RSP/MSEK": 8.02969,
    "out:LCP/MSEK": -1.196165,
    "out:ROI% old": 8.309558,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 19.9,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 7,
    "out:Return/kSEK/y": 213,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 79943.58626,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 72748.66,
    "out:DH kr savings": 72748.66349,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 249773.5373,
    "out:% savings (space heating)": 19.508316,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.4,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2859375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.083384,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 146.2,
    "out:Total Energy Use Post PV": 141.409285,
    "out:Primary Energy": 98.995082,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.459482,
    "out:CO2 Operational/m2": 30.196185,
    "out:Total CO2/m2": 51.655667,
    "out:Total CO2 (tons)": 154706.9884,
    "out:Klimatpaverkan":0.001* -10184.41628,
    "out:Initial Cost/MSEK": 3.11575,
    "out:Running cost/(Apt SEK y)": 31400.29412,
    "out:Running Cost over RSP/MSEK": 8.35529,
    "out:LCP/MSEK": -1.77814,
    "out:ROI% old": 6.132942,
    "out:Payback discounted": 20,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 15.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 6,
    "out:Return/kSEK/y": 172,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 49497.93054,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83193,
    "out:DH kr savings": 45043.11679,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 260811.8105,
    "out:% savings (space heating)": 15.951137,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.08,
    "out:Etvv": 25,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3115750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.397775,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 138.409285,
    "out:Primary Energy": 96.415155,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.459482,
    "out:CO2 Operational/m2": 29.116233,
    "out:Total CO2/m2": 50.575715,
    "out:Total CO2 (tons)": 151472.5684,
    "out:Klimatpaverkan":0.001* -13418.83626,
    "out:Initial Cost/MSEK": 3.190625,
    "out:Running cost/(Apt SEK y)": 30705.76471,
    "out:Running Cost over RSP/MSEK": 8.17049,
    "out:LCP/MSEK": -1.668215,
    "out:ROI% old": 6.816443,
    "out:Payback discounted": 18,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 18.1,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 6,
    "out:Return/kSEK/y": 196,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 60536.20381,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93237.82,
    "out:DH kr savings": 55087.94547,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 249773.5373,
    "out:% savings (space heating)": 19.508316,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.4,
    "out:Etvv": 25,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3190625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.083384,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 135.409285,
    "out:Primary Energy": 96.370082,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.836114,
    "out:CO2 Operational/m2": 28.036281,
    "out:Total CO2/m2": 49.872395,
    "out:Total CO2 (tons)": 149366.1487,
    "out:Klimatpaverkan":0.001* -15525.25605,
    "out:Initial Cost/MSEK": 3.252375,
    "out:Running cost/(Apt SEK y)": 30011.20588,
    "out:Running Cost over RSP/MSEK": 7.98569,
    "out:LCP/MSEK": -1.545165,
    "out:ROI% old": 7.49874,
    "out:Payback discounted": 16,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 20.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 7,
    "out:Return/kSEK/y": 219,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 68905.31298,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 100853.71,
    "out:DH kr savings": 62703.83482,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 260811.8105,
    "out:% savings (space heating)": 15.951137,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.08,
    "out:Etvv": 21.25,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3252375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.397775,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 131.409285,
    "out:Primary Energy": 93.790155,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.836114,
    "out:CO2 Operational/m2": 26.596345,
    "out:Total CO2/m2": 48.432459,
    "out:Total CO2 (tons)": 145053.5887,
    "out:Klimatpaverkan":0.001* -19837.81603,
    "out:Initial Cost/MSEK": 3.32725,
    "out:Running cost/(Apt SEK y)": 29085.17647,
    "out:Running Cost over RSP/MSEK": 7.73929,
    "out:LCP/MSEK": -1.37364,
    "out:ROI% old": 8.387922,
    "out:Payback discounted": 14,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 8,
    "out:Return/kSEK/y": 251,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 79943.58626,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 110898.54,
    "out:DH kr savings": 72748.66349,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 249773.5373,
    "out:% savings (space heating)": 19.508316,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.4,
    "out:Etvv": 21.25,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3327250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.083384,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 146.2,
    "out:Total Energy Use Post PV": 140.404548,
    "out:Primary Energy": 97.186555,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.736391,
    "out:CO2 Operational/m2": -7.50043,
    "out:Total CO2/m2": 23.235961,
    "out:Total CO2 (tons)": 69590.92309,
    "out:Klimatpaverkan":0.001* -95300.48162,
    "out:Initial Cost/MSEK": 3.5835,
    "out:Running cost/(Apt SEK y)": 30419.97059,
    "out:Running Cost over RSP/MSEK": 8.10179,
    "out:LCP/MSEK": -1.99239,
    "out:ROI% old": 6.343001,
    "out:Payback discounted": 19,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 16.4,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 6,
    "out:Return/kSEK/y": 205,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 49497.93054,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116523.85,
    "out:DH kr savings": 45043.11679,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 260811.8105,
    "out:% savings (space heating)": 15.951137,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.08,
    "out:Etvv": 25,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3583500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.397775,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 137.404548,
    "out:Primary Energy": 94.606628,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.736391,
    "out:CO2 Operational/m2": -8.580382,
    "out:Total CO2/m2": 22.156009,
    "out:Total CO2 (tons)": 66356.50311,
    "out:Klimatpaverkan":0.001* -98534.9016,
    "out:Initial Cost/MSEK": 3.658375,
    "out:Running cost/(Apt SEK y)": 29725.44118,
    "out:Running Cost over RSP/MSEK": 7.91699,
    "out:LCP/MSEK": -1.882465,
    "out:ROI% old": 6.934812,
    "out:Payback discounted": 18,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 6,
    "out:Return/kSEK/y": 229,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 60536.20381,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126568.68,
    "out:DH kr savings": 55087.94547,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 249773.5373,
    "out:% savings (space heating)": 19.508316,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.4,
    "out:Etvv": 25,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3658375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.083384,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 134.404548,
    "out:Primary Energy": 94.561555,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.113023,
    "out:CO2 Operational/m2": -9.660334,
    "out:Total CO2/m2": 21.452689,
    "out:Total CO2 (tons)": 64250.08332,
    "out:Klimatpaverkan":0.001* -100641.3214,
    "out:Initial Cost/MSEK": 3.720125,
    "out:Running cost/(Apt SEK y)": 29030.91177,
    "out:Running Cost over RSP/MSEK": 7.73219,
    "out:LCP/MSEK": -1.759415,
    "out:ROI% old": 7.529355,
    "out:Payback discounted": 16,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 21.6,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 7,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 68905.31298,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134184.57,
    "out:DH kr savings": 62703.83482,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 260811.8105,
    "out:% savings (space heating)": 15.951137,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.08,
    "out:Etvv": 21.25,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3720125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.397775,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 130.404548,
    "out:Primary Energy": 91.981628,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.113023,
    "out:CO2 Operational/m2": -11.10027,
    "out:Total CO2/m2": 20.012753,
    "out:Total CO2 (tons)": 59937.52335,
    "out:Klimatpaverkan":0.001* -104953.8814,
    "out:Initial Cost/MSEK": 3.795,
    "out:Running cost/(Apt SEK y)": 28104.85294,
    "out:Running Cost over RSP/MSEK": 7.48579,
    "out:LCP/MSEK": -1.58789,
    "out:ROI% old": 8.308338,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 25.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 7,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 79943.58626,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 144229.4,
    "out:DH kr savings": 72748.66349,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 249773.5373,
    "out:% savings (space heating)": 19.508316,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.4,
    "out:Etvv": 21.25,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3795000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.083384,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 138.2,
    "out:Primary Energy": 104.118369,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.182573,
    "out:CO2 Operational/m2": 46.056578,
    "out:Total CO2/m2": 58.239151,
    "out:Total CO2 (tons)": 174424.302,
    "out:Klimatpaverkan":0.001* 9532.897273,
    "out:Initial Cost/MSEK": 2.737625,
    "out:Running cost/(Apt SEK y)": 30670.26471,
    "out:Running Cost over RSP/MSEK": 8.15289,
    "out:LCP/MSEK": -1.197615,
    "out:ROI% old": 8.036215,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 18.1,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 7,
    "out:Return/kSEK/y": 197,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 75374.44047,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 68590.74,
    "out:DH kr savings": 68590.74082,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 260811.8105,
    "out:% savings (space heating)": 15.951137,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.08,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2737625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.397775,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 134.2,
    "out:Primary Energy": 101.538442,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.182573,
    "out:CO2 Operational/m2": 44.616642,
    "out:Total CO2/m2": 56.799215,
    "out:Total CO2 (tons)": 170111.742,
    "out:Klimatpaverkan":0.001* 5220.337296,
    "out:Initial Cost/MSEK": 2.8125,
    "out:Running cost/(Apt SEK y)": 29744.20588,
    "out:Running Cost over RSP/MSEK": 7.90649,
    "out:LCP/MSEK": -1.02609,
    "out:ROI% old": 9.073829,
    "out:Payback discounted": 13,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 21.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 8,
    "out:Return/kSEK/y": 228,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 86412.71374,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 78635.57,
    "out:DH kr savings": 78635.5695,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 249773.5373,
    "out:% savings (space heating)": 19.508316,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.4,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2812500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.083384,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 132.2,
    "out:Primary Energy": 102.018369,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.559205,
    "out:CO2 Operational/m2": 43.896674,
    "out:Total CO2/m2": 56.455879,
    "out:Total CO2 (tons)": 169083.4622,
    "out:Klimatpaverkan":0.001* 4192.057503,
    "out:Initial Cost/MSEK": 2.87425,
    "out:Running cost/(Apt SEK y)": 29281.17647,
    "out:Running Cost over RSP/MSEK": 7.78329,
    "out:LCP/MSEK": -0.96464,
    "out:ROI% old": 9.491221,
    "out:Payback discounted": 13,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 23.5,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 8,
    "out:Return/kSEK/y": 244,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 90900.34642,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82719.32,
    "out:DH kr savings": 82719.31525,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 260811.8105,
    "out:% savings (space heating)": 15.951137,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.08,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2874250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.397775,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 129.2,
    "out:Primary Energy": 99.438442,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.559205,
    "out:CO2 Operational/m2": 42.816722,
    "out:Total CO2/m2": 55.375927,
    "out:Total CO2 (tons)": 165849.0422,
    "out:Klimatpaverkan":0.001* 957.63752,
    "out:Initial Cost/MSEK": 2.949125,
    "out:Running cost/(Apt SEK y)": 28586.64706,
    "out:Running Cost over RSP/MSEK": 7.59849,
    "out:LCP/MSEK": -0.854715,
    "out:ROI% old": 10.145431,
    "out:Payback discounted": 12,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 9,
    "out:Return/kSEK/y": 268,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 101938.6197,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 92764.14,
    "out:DH kr savings": 92764.14392,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 249773.5373,
    "out:% savings (space heating)": 19.508316,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.4,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2949125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.083384,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 133.409285,
    "out:Primary Energy": 95.495082,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.459482,
    "out:CO2 Operational/m2": 27.316313,
    "out:Total CO2/m2": 48.775795,
    "out:Total CO2 (tons)": 146081.8685,
    "out:Klimatpaverkan":0.001* -18809.53623,
    "out:Initial Cost/MSEK": 3.2055,
    "out:Running cost/(Apt SEK y)": 29548.20588,
    "out:Running Cost over RSP/MSEK": 7.86249,
    "out:LCP/MSEK": -1.37509,
    "out:ROI% old": 8.157453,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 7,
    "out:Return/kSEK/y": 235,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 75374.44047,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 106740.62,
    "out:DH kr savings": 68590.74082,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 260811.8105,
    "out:% savings (space heating)": 15.951137,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.08,
    "out:Etvv": 20,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3205500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.397775,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 129.409285,
    "out:Primary Energy": 92.915155,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.459482,
    "out:CO2 Operational/m2": 25.876377,
    "out:Total CO2/m2": 47.335859,
    "out:Total CO2 (tons)": 141769.3085,
    "out:Klimatpaverkan":0.001* -23122.09621,
    "out:Initial Cost/MSEK": 3.280375,
    "out:Running cost/(Apt SEK y)": 28622.14706,
    "out:Running Cost over RSP/MSEK": 7.61609,
    "out:LCP/MSEK": -1.203565,
    "out:ROI% old": 9.044306,
    "out:Payback discounted": 13,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 8,
    "out:Return/kSEK/y": 266,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 86412.71374,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116785.45,
    "out:DH kr savings": 78635.5695,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 249773.5373,
    "out:% savings (space heating)": 19.508316,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.4,
    "out:Etvv": 20,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3280375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.083384,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 127.409285,
    "out:Primary Energy": 93.395082,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.836114,
    "out:CO2 Operational/m2": 25.156409,
    "out:Total CO2/m2": 46.992523,
    "out:Total CO2 (tons)": 140741.0287,
    "out:Klimatpaverkan":0.001* -24150.376,
    "out:Initial Cost/MSEK": 3.342,
    "out:Running cost/(Apt SEK y)": 28159.14706,
    "out:Running Cost over RSP/MSEK": 7.49289,
    "out:LCP/MSEK": -1.14199,
    "out:ROI% old": 9.404163,
    "out:Payback discounted": 13,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 28.3,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 8,
    "out:Return/kSEK/y": 282,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 90900.34642,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120869.19,
    "out:DH kr savings": 82719.31525,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 260811.8105,
    "out:% savings (space heating)": 15.951137,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.08,
    "out:Etvv": 17,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3342000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.397775,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 124.409285,
    "out:Primary Energy": 90.815155,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.836114,
    "out:CO2 Operational/m2": 24.076457,
    "out:Total CO2/m2": 45.912571,
    "out:Total CO2 (tons)": 137506.6087,
    "out:Klimatpaverkan":0.001* -27384.79599,
    "out:Initial Cost/MSEK": 3.416875,
    "out:Running cost/(Apt SEK y)": 27464.58824,
    "out:Running Cost over RSP/MSEK": 7.30809,
    "out:LCP/MSEK": -1.032065,
    "out:ROI% old": 9.970723,
    "out:Payback discounted": 12,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 9,
    "out:Return/kSEK/y": 306,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 101938.6197,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130914.02,
    "out:DH kr savings": 92764.14392,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 249773.5373,
    "out:% savings (space heating)": 19.508316,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.4,
    "out:Etvv": 17,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3416875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.083384,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 132.404548,
    "out:Primary Energy": 93.686555,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.736391,
    "out:CO2 Operational/m2": -10.380302,
    "out:Total CO2/m2": 20.356089,
    "out:Total CO2 (tons)": 60965.80314,
    "out:Klimatpaverkan":0.001* -103925.6016,
    "out:Initial Cost/MSEK": 3.67325,
    "out:Running cost/(Apt SEK y)": 28567.88235,
    "out:Running Cost over RSP/MSEK": 7.60899,
    "out:LCP/MSEK": -1.58934,
    "out:ROI% old": 8.104578,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 23.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 7,
    "out:Return/kSEK/y": 268,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 75374.44047,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140071.48,
    "out:DH kr savings": 68590.74082,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 260811.8105,
    "out:% savings (space heating)": 15.951137,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.08,
    "out:Etvv": 20,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3673250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.397775,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 128.404548,
    "out:Primary Energy": 91.106628,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.736391,
    "out:CO2 Operational/m2": -11.820238,
    "out:Total CO2/m2": 18.916153,
    "out:Total CO2 (tons)": 56653.24316,
    "out:Klimatpaverkan":0.001* -108238.1615,
    "out:Initial Cost/MSEK": 3.748125,
    "out:Running cost/(Apt SEK y)": 27641.82353,
    "out:Running Cost over RSP/MSEK": 7.36259,
    "out:LCP/MSEK": -1.417815,
    "out:ROI% old": 8.881812,
    "out:Payback discounted": 14,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 27.3,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 8,
    "out:Return/kSEK/y": 300,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 86412.71374,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 150116.31,
    "out:DH kr savings": 78635.5695,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 249773.5373,
    "out:% savings (space heating)": 19.508316,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.4,
    "out:Etvv": 20,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3748125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.083384,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 126.404548,
    "out:Primary Energy": 91.586555,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.113023,
    "out:CO2 Operational/m2": -12.540206,
    "out:Total CO2/m2": 18.572817,
    "out:Total CO2 (tons)": 55624.96337,
    "out:Klimatpaverkan":0.001* -109266.4413,
    "out:Initial Cost/MSEK": 3.809875,
    "out:Running cost/(Apt SEK y)": 27178.82353,
    "out:Running Cost over RSP/MSEK": 7.23939,
    "out:LCP/MSEK": -1.356365,
    "out:ROI% old": 9.199814,
    "out:Payback discounted": 13,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 29.4,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 8,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 90900.34642,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154200.05,
    "out:DH kr savings": 82719.31525,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 260811.8105,
    "out:% savings (space heating)": 15.951137,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.08,
    "out:Etvv": 17,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3809875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.397775,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 123.404548,
    "out:Primary Energy": 89.006628,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.113023,
    "out:CO2 Operational/m2": -13.620158,
    "out:Total CO2/m2": 17.492865,
    "out:Total CO2 (tons)": 52390.54339,
    "out:Klimatpaverkan":0.001* -112500.8613,
    "out:Initial Cost/MSEK": 3.88475,
    "out:Running cost/(Apt SEK y)": 26484.29412,
    "out:Running Cost over RSP/MSEK": 7.05459,
    "out:LCP/MSEK": -1.24644,
    "out:ROI% old": 9.702077,
    "out:Payback discounted": 12,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 32.5,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 9,
    "out:Return/kSEK/y": 339,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 101938.6197,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164244.88,
    "out:DH kr savings": 92764.14392,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 249773.5373,
    "out:% savings (space heating)": 19.508316,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.4,
    "out:Etvv": 17,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3884750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.962137,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.62715,
    "out:Electricity (inc PV)": 39.399668,
    "out:Total Energy Use Pre PV": 95.62715,
    "out:Total Energy Use Post PV": 93.399668,
    "out:Primary Energy": 101.098382,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.076048,
    "out:CO2 Operational/m2": 24.934253,
    "out:Total CO2/m2": 41.010301,
    "out:Total CO2 (tons)": 122824.4747,
    "out:Klimatpaverkan":0.001* -42066.93005,
    "out:Initial Cost/MSEK": 4.298125,
    "out:Running cost/(Apt SEK y)": 18735.17647,
    "out:Running Cost over RSP/MSEK": 4.96491,
    "out:LCP/MSEK": 0.429865,
    "out:ROI% old": 15.71446,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 75.3,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 2913.9005,
    "out:Return %": 14,
    "out:Return/kSEK/y": 603,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.12765,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 278077.5021,
    "out:EL kWh savings": -69482.22678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134930.74,
    "out:DH kr savings": 253050.5269,
    "out:El kr savings": -118119.7855,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 161614.7886,
    "out:% savings (space heating)": 47.918235,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 43.11,
    "out:Etvv": 0,
    "out:Ef": 39.4,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4298125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.421641,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.583177,
    "out:Electricity (inc PV)": 39.319792,
    "out:Total Energy Use Pre PV": 91.583177,
    "out:Total Energy Use Post PV": 89.319792,
    "out:Primary Energy": 98.559195,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.076048,
    "out:CO2 Operational/m2": 23.488512,
    "out:Total CO2/m2": 39.56456,
    "out:Total CO2 (tons)": 118494.5289,
    "out:Klimatpaverkan":0.001* -46396.87581,
    "out:Initial Cost/MSEK": 4.373,
    "out:Running cost/(Apt SEK y)": 17802.55882,
    "out:Running Cost over RSP/MSEK": 4.71677,
    "out:LCP/MSEK": 0.60313,
    "out:ROI% old": 16.256019,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 83.1,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2910.82239,
    "out:Return %": 15,
    "out:Return/kSEK/y": 634,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.083677,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 288681.1687,
    "out:EL kWh savings": -69243.00084,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 144986.76,
    "out:DH kr savings": 262699.8635,
    "out:El kr savings": -117713.1014,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 151011.122,
    "out:% savings (space heating)": 51.335359,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 39.69,
    "out:Etvv": 0,
    "out:Ef": 39.32,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4373000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.986066,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.868384,
    "out:Electricity (inc PV)": 38.905904,
    "out:Total Energy Use Pre PV": 90.868384,
    "out:Total Energy Use Post PV": 88.905904,
    "out:Primary Energy": 98.725203,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.45268,
    "out:CO2 Operational/m2": 23.394154,
    "out:Total CO2/m2": 39.846834,
    "out:Total CO2 (tons)": 119339.9301,
    "out:Klimatpaverkan":0.001* -45551.47466,
    "out:Initial Cost/MSEK": 4.43475,
    "out:Running cost/(Apt SEK y)": 17695.52941,
    "out:Running Cost over RSP/MSEK": 4.68864,
    "out:LCP/MSEK": 0.56951,
    "out:ROI% old": 16.120284,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 83.1,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2860.78688,
    "out:Return %": 14,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.368884,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 289985.7012,
    "out:EL kWh savings": -68003.42018,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148281.17,
    "out:DH kr savings": 263886.9881,
    "out:El kr savings": -115605.8143,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 149706.5895,
    "out:% savings (space heating)": 51.755756,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 40.99,
    "out:Etvv": 0,
    "out:Ef": 38.91,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4434750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.48559,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.812283,
    "out:Electricity (inc PV)": 38.816455,
    "out:Total Energy Use Pre PV": 86.812283,
    "out:Total Energy Use Post PV": 84.816455,
    "out:Primary Energy": 96.190896,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.45268,
    "out:CO2 Operational/m2": 21.946812,
    "out:Total CO2/m2": 38.399492,
    "out:Total CO2 (tons)": 115005.1894,
    "out:Klimatpaverkan":0.001* -49886.21536,
    "out:Initial Cost/MSEK": 4.509625,
    "out:Running cost/(Apt SEK y)": 16761.05882,
    "out:Running Cost over RSP/MSEK": 4.44003,
    "out:LCP/MSEK": 0.743245,
    "out:ROI% old": 16.640186,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 91.8,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2856.85981,
    "out:Return %": 15,
    "out:Return/kSEK/y": 670,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.312783,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 300469.5093,
    "out:EL kWh savings": -67735.52342,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158276.86,
    "out:DH kr savings": 273427.2535,
    "out:El kr savings": -115150.3898,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 139222.7814,
    "out:% savings (space heating)": 55.134254,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 37.6,
    "out:Etvv": 0,
    "out:Ef": 38.82,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4509625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.962137,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.62715,
    "out:Electricity (inc PV)": 31.669119,
    "out:Total Energy Use Pre PV": 95.62715,
    "out:Total Energy Use Post PV": 85.669119,
    "out:Primary Energy": 87.183394,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.352957,
    "out:CO2 Operational/m2": 21.441099,
    "out:Total CO2/m2": 46.794056,
    "out:Total CO2 (tons)": 140146.6267,
    "out:Klimatpaverkan":0.001* -24744.77801,
    "out:Initial Cost/MSEK": 4.766,
    "out:Running cost/(Apt SEK y)": 17497.79412,
    "out:Running Cost over RSP/MSEK": 4.64483,
    "out:LCP/MSEK": 0.28207,
    "out:ROI% old": 15.131197,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 89.5,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 2388.997264,
    "out:Return %": 14,
    "out:Return/kSEK/y": 645,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.12765,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1564.869784,
    "out:PV (% sold (El))": 6.224919,
    "out:PV (kWh self-consumed)": 23573.92778,
    "out:PV (ratio sold/self-consumed)": 0.066381,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 278077.5021,
    "out:EL kWh savings": -46329.49206,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176285.6,
    "out:DH kr savings": 253050.5269,
    "out:El kr savings": -78760.1365,
    "out:El kr sold": 1995.208975,
    "out:El kr saved": 40075.67723,
    "out:El kr return": 42070.8862,
    "out:% solar/total": 49,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 161614.7886,
    "out:% savings (space heating)": 47.918235,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 43.11,
    "out:Etvv": 0,
    "out:Ef": 31.67,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4766000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.421641,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.583177,
    "out:Electricity (inc PV)": 31.594696,
    "out:Total Energy Use Pre PV": 91.583177,
    "out:Total Energy Use Post PV": 81.594696,
    "out:Primary Energy": 84.654022,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.352957,
    "out:CO2 Operational/m2": 19.982905,
    "out:Total CO2/m2": 45.335862,
    "out:Total CO2 (tons)": 135779.3846,
    "out:Klimatpaverkan":0.001* -29112.02008,
    "out:Initial Cost/MSEK": 4.840875,
    "out:Running cost/(Apt SEK y)": 16565.26471,
    "out:Running Cost over RSP/MSEK": 4.39673,
    "out:LCP/MSEK": 0.455295,
    "out:ROI% old": 15.629317,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 98.8,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2386.066569,
    "out:Return %": 14,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.083677,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1572.590654,
    "out:PV (% sold (El))": 6.255632,
    "out:PV (kWh self-consumed)": 23566.20691,
    "out:PV (ratio sold/self-consumed)": 0.066731,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 288681.1687,
    "out:EL kWh savings": -46106.59767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186323.7,
    "out:DH kr savings": 262699.8635,
    "out:El kr savings": -78381.21605,
    "out:El kr sold": 2005.053084,
    "out:El kr saved": 40062.55175,
    "out:El kr return": 42067.60483,
    "out:% solar/total": 49,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 151011.122,
    "out:% savings (space heating)": 51.335359,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 39.69,
    "out:Etvv": 0,
    "out:Ef": 31.59,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4840875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.986066,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.868384,
    "out:Electricity (inc PV)": 31.209454,
    "out:Total Energy Use Pre PV": 90.868384,
    "out:Total Energy Use Post PV": 81.209454,
    "out:Primary Energy": 84.871593,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.729589,
    "out:CO2 Operational/m2": 19.681037,
    "out:Total CO2/m2": 45.410626,
    "out:Total CO2 (tons)": 136003.3003,
    "out:Klimatpaverkan":0.001* -28888.10441,
    "out:Initial Cost/MSEK": 4.902625,
    "out:Running cost/(Apt SEK y)": 16459.85294,
    "out:Running Cost over RSP/MSEK": 4.369,
    "out:LCP/MSEK": 0.421275,
    "out:ROI% old": 15.513264,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 101.2,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2338.502669,
    "out:Return %": 14,
    "out:Return/kSEK/y": 680,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.368884,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1701.022384,
    "out:PV (% sold (El))": 6.766522,
    "out:PV (kWh self-consumed)": 23437.77518,
    "out:PV (ratio sold/self-consumed)": 0.072576,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 289985.7012,
    "out:EL kWh savings": -44952.81082,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189636.01,
    "out:DH kr savings": 263886.9881,
    "out:El kr savings": -76419.77839,
    "out:El kr sold": 2168.80354,
    "out:El kr saved": 39844.21781,
    "out:El kr return": 42013.02135,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 149706.5895,
    "out:% savings (space heating)": 51.755756,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 40.99,
    "out:Etvv": 0,
    "out:Ef": 31.21,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4902625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.48559,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.812283,
    "out:Electricity (inc PV)": 31.12628,
    "out:Total Energy Use Pre PV": 86.812283,
    "out:Total Energy Use Post PV": 77.12628,
    "out:Primary Energy": 82.348581,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.729589,
    "out:CO2 Operational/m2": 18.216981,
    "out:Total CO2/m2": 43.94657,
    "out:Total CO2 (tons)": 131618.5017,
    "out:Klimatpaverkan":0.001* -33272.90298,
    "out:Initial Cost/MSEK": 4.9775,
    "out:Running cost/(Apt SEK y)": 15525.52941,
    "out:Running Cost over RSP/MSEK": 4.12042,
    "out:LCP/MSEK": 0.59498,
    "out:ROI% old": 15.993341,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 111.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2334.775577,
    "out:Return %": 14,
    "out:Return/kSEK/y": 712,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.312783,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1711.413532,
    "out:PV (% sold (El))": 6.807858,
    "out:PV (kWh self-consumed)": 23427.38403,
    "out:PV (ratio sold/self-consumed)": 0.073052,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 300469.5093,
    "out:EL kWh savings": -44703.70748,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199613,
    "out:DH kr savings": 273427.2535,
    "out:El kr savings": -75996.30272,
    "out:El kr sold": 2182.052254,
    "out:El kr saved": 39826.55286,
    "out:El kr return": 42008.60511,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 139222.7814,
    "out:% savings (space heating)": 55.134254,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 37.6,
    "out:Etvv": 0,
    "out:Ef": 31.13,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4977500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.962137,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.62715,
    "out:Electricity (inc PV)": 28.540451,
    "out:Total Energy Use Pre PV": 95.62715,
    "out:Total Energy Use Post PV": 82.540451,
    "out:Primary Energy": 81.551792,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.629866,
    "out:CO2 Operational/m2": -4.295358,
    "out:Total CO2/m2": 30.334508,
    "out:Total CO2 (tons)": 90850.83304,
    "out:Klimatpaverkan":0.001* -74040.57167,
    "out:Initial Cost/MSEK": 5.23375,
    "out:Running cost/(Apt SEK y)": 16429.26471,
    "out:Running Cost over RSP/MSEK": 4.36804,
    "out:LCP/MSEK": 0.09111,
    "out:ROI% old": 14.534402,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 96.4,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 2148.542862,
    "out:Return %": 13,
    "out:Return/kSEK/y": 681,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.12765,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 16637.20247,
    "out:PV (% sold (El))": 33.090689,
    "out:PV (kWh self-consumed)": 33640.39285,
    "out:PV (ratio sold/self-consumed)": 0.49456,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 278077.5021,
    "out:EL kWh savings": -36959.23644,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211432.26,
    "out:DH kr savings": 253050.5269,
    "out:El kr savings": -62830.70195,
    "out:El kr sold": 21212.43315,
    "out:El kr saved": 57188.66784,
    "out:El kr return": 78401.10098,
    "out:% solar/total": 69,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 161614.7886,
    "out:% savings (space heating)": 47.918235,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 43.11,
    "out:Etvv": 0,
    "out:Ef": 28.54,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5233750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.421641,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.583177,
    "out:Electricity (inc PV)": 28.47421,
    "out:Total Energy Use Pre PV": 91.583177,
    "out:Total Energy Use Post PV": 78.47421,
    "out:Primary Energy": 79.037147,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.629866,
    "out:CO2 Operational/m2": -5.777366,
    "out:Total CO2/m2": 28.8525,
    "out:Total CO2 (tons)": 86412.26884,
    "out:Klimatpaverkan":0.001* -78479.13587,
    "out:Initial Cost/MSEK": 5.308625,
    "out:Running cost/(Apt SEK y)": 15496.91177,
    "out:Running Cost over RSP/MSEK": 4.11997,
    "out:LCP/MSEK": 0.264305,
    "out:ROI% old": 14.996969,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 109,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2145.94904,
    "out:Return %": 13,
    "out:Return/kSEK/y": 713,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.083677,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 16658.9229,
    "out:PV (% sold (El))": 33.13389,
    "out:PV (kWh self-consumed)": 33618.67242,
    "out:PV (ratio sold/self-consumed)": 0.495526,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 288681.1687,
    "out:EL kWh savings": -36760.84687,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221446.55,
    "out:DH kr savings": 262699.8635,
    "out:El kr savings": -62493.43968,
    "out:El kr sold": 21240.12669,
    "out:El kr saved": 57151.74311,
    "out:El kr return": 78391.8698,
    "out:% solar/total": 69,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 151011.122,
    "out:% savings (space heating)": 51.335359,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 39.69,
    "out:Etvv": 0,
    "out:Ef": 28.47,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5308625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.986066,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.868384,
    "out:Electricity (inc PV)": 28.131304,
    "out:Total Energy Use Pre PV": 90.868384,
    "out:Total Energy Use Post PV": 78.131304,
    "out:Primary Energy": 79.330923,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.006498,
    "out:CO2 Operational/m2": -6.464531,
    "out:Total CO2/m2": 28.541967,
    "out:Total CO2 (tons)": 85482.23293,
    "out:Klimatpaverkan":0.001* -79409.17178,
    "out:Initial Cost/MSEK": 5.370375,
    "out:Running cost/(Apt SEK y)": 15394.29412,
    "out:Running Cost over RSP/MSEK": 4.09299,
    "out:LCP/MSEK": 0.229535,
    "out:ROI% old": 14.896299,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 109,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2103.83958,
    "out:Return %": 13,
    "out:Return/kSEK/y": 716,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.368884,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 17013.55589,
    "out:PV (% sold (El))": 33.83924,
    "out:PV (kWh self-consumed)": 33264.03942,
    "out:PV (ratio sold/self-consumed)": 0.51147,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 289985.7012,
    "out:EL kWh savings": -35733.85491,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 224831.72,
    "out:DH kr savings": 263886.9881,
    "out:El kr savings": -60747.55335,
    "out:El kr sold": 21692.28376,
    "out:El kr saved": 56548.86701,
    "out:El kr return": 78241.15078,
    "out:% solar/total": 69,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 149706.5895,
    "out:% savings (space heating)": 51.755756,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 40.99,
    "out:Etvv": 0,
    "out:Ef": 28.13,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5370375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.48559,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.812283,
    "out:Electricity (inc PV)": 28.057268,
    "out:Total Energy Use Pre PV": 86.812283,
    "out:Total Energy Use Post PV": 74.057268,
    "out:Primary Energy": 76.824359,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.006498,
    "out:CO2 Operational/m2": -7.95864,
    "out:Total CO2/m2": 27.047858,
    "out:Total CO2 (tons)": 81007.42663,
    "out:Klimatpaverkan":0.001* -83883.97808,
    "out:Initial Cost/MSEK": 5.44525,
    "out:Running cost/(Apt SEK y)": 14460.20588,
    "out:Running Cost over RSP/MSEK": 3.84448,
    "out:LCP/MSEK": 0.40317,
    "out:ROI% old": 15.343438,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 120.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2100.538738,
    "out:Return %": 14,
    "out:Return/kSEK/y": 748,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.312783,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 17041.46343,
    "out:PV (% sold (El))": 33.894746,
    "out:PV (kWh self-consumed)": 33236.13189,
    "out:PV (ratio sold/self-consumed)": 0.512739,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 300469.5093,
    "out:EL kWh savings": -35512.11958,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234784.52,
    "out:DH kr savings": 273427.2535,
    "out:El kr savings": -60370.60328,
    "out:El kr sold": 21727.86587,
    "out:El kr saved": 56501.42421,
    "out:El kr return": 78229.29007,
    "out:% solar/total": 69,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 139222.7814,
    "out:% savings (space heating)": 55.134254,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 37.6,
    "out:Etvv": 0,
    "out:Ef": 28.06,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5445250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.689726,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.606669,
    "out:Electricity (inc PV)": 38.73838,
    "out:Total Energy Use Pre PV": 89.606669,
    "out:Total Energy Use Post PV": 87.73838,
    "out:Primary Energy": 97.93564,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.076048,
    "out:CO2 Operational/m2": 22.999621,
    "out:Total CO2/m2": 39.075669,
    "out:Total CO2 (tons)": 117030.3168,
    "out:Klimatpaverkan":0.001* -47861.08794,
    "out:Initial Cost/MSEK": 4.387875,
    "out:Running cost/(Apt SEK y)": 17424.82353,
    "out:Running Cost over RSP/MSEK": 4.61674,
    "out:LCP/MSEK": 0.688285,
    "out:ROI% old": 16.526581,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 85.2,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2842.46683,
    "out:Return %": 15,
    "out:Return/kSEK/y": 647,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.107169,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 293868.196,
    "out:EL kWh savings": -67501.69142,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152667.18,
    "out:DH kr savings": 267420.0584,
    "out:El kr savings": -114752.8754,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 145824.0947,
    "out:% savings (space heating)": 53.006923,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 40.3,
    "out:Etvv": 0,
    "out:Ef": 38.74,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4387875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.202824,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.546455,
    "out:Electricity (inc PV)": 38.645812,
    "out:Total Energy Use Pre PV": 85.546455,
    "out:Total Energy Use Post PV": 83.645812,
    "out:Primary Energy": 95.402924,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.076048,
    "out:CO2 Operational/m2": 21.551736,
    "out:Total CO2/m2": 37.627784,
    "out:Total CO2 (tons)": 112693.9498,
    "out:Klimatpaverkan":0.001* -52197.45491,
    "out:Initial Cost/MSEK": 4.46275,
    "out:Running cost/(Apt SEK y)": 16489.76471,
    "out:Running Cost over RSP/MSEK": 4.36797,
    "out:LCP/MSEK": 0.86218,
    "out:ROI% old": 17.04564,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 94,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2838.25185,
    "out:Return %": 15,
    "out:Return/kSEK/y": 679,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.046955,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 304311.3504,
    "out:EL kWh savings": -67224.45337,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162641.76,
    "out:DH kr savings": 276923.3289,
    "out:El kr savings": -114281.5707,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 135380.9403,
    "out:% savings (space heating)": 56.37232,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 36.91,
    "out:Etvv": 0,
    "out:Ef": 38.65,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4462750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.631859,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.962386,
    "out:Electricity (inc PV)": 38.330793,
    "out:Total Energy Use Pre PV": 85.962386,
    "out:Total Energy Use Post PV": 84.330793,
    "out:Primary Energy": 96.04351,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.45268,
    "out:CO2 Operational/m2": 21.834619,
    "out:Total CO2/m2": 38.287299,
    "out:Total CO2 (tons)": 114669.1751,
    "out:Klimatpaverkan":0.001* -50222.22962,
    "out:Initial Cost/MSEK": 4.5245,
    "out:Running cost/(Apt SEK y)": 16633.79412,
    "out:Running Cost over RSP/MSEK": 4.40657,
    "out:LCP/MSEK": 0.76183,
    "out:ROI% old": 16.691126,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 94,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2797.36702,
    "out:Return %": 15,
    "out:Return/kSEK/y": 674,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.462886,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 303026.405,
    "out:EL kWh savings": -66280.98204,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163076.36,
    "out:DH kr savings": 275754.0286,
    "out:El kr savings": -112677.6695,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 136665.8857,
    "out:% savings (space heating)": 55.958235,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 38.64,
    "out:Etvv": 0,
    "out:Ef": 38.33,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4524500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.18117,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.891198,
    "out:Electricity (inc PV)": 38.231035,
    "out:Total Energy Use Pre PV": 81.891198,
    "out:Total Energy Use Post PV": 80.231035,
    "out:Primary Energy": 93.514461,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.45268,
    "out:CO2 Operational/m2": 20.385285,
    "out:Total CO2/m2": 36.837965,
    "out:Total CO2 (tons)": 110328.4684,
    "out:Klimatpaverkan":0.001* -54562.9363,
    "out:Initial Cost/MSEK": 4.599375,
    "out:Running cost/(Apt SEK y)": 15697.08824,
    "out:Running Cost over RSP/MSEK": 4.15737,
    "out:LCP/MSEK": 0.936155,
    "out:ROI% old": 17.193423,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 103.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2792.38386,
    "out:Return %": 15,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.391698,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 313361.1027,
    "out:EL kWh savings": -65982.21018,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172988.85,
    "out:DH kr savings": 285158.6035,
    "out:El kr savings": -112169.7573,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 126331.188,
    "out:% savings (space heating)": 59.288681,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 35.28,
    "out:Etvv": 0,
    "out:Ef": 38.23,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4599375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.689726,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.606669,
    "out:Electricity (inc PV)": 31.053686,
    "out:Total Energy Use Pre PV": 89.606669,
    "out:Total Energy Use Post PV": 80.053686,
    "out:Primary Energy": 84.103191,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.352957,
    "out:CO2 Operational/m2": 19.207943,
    "out:Total CO2/m2": 44.5609,
    "out:Total CO2 (tons)": 133458.3995,
    "out:Klimatpaverkan":0.001* -31433.00525,
    "out:Initial Cost/MSEK": 4.85575,
    "out:Running cost/(Apt SEK y)": 16189.73529,
    "out:Running Cost over RSP/MSEK": 4.29725,
    "out:LCP/MSEK": 0.5399,
    "out:ROI% old": 15.874111,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 103.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2321.123044,
    "out:Return %": 14,
    "out:Return/kSEK/y": 689,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.107169,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1749.94404,
    "out:PV (% sold (El))": 6.961129,
    "out:PV (kWh self-consumed)": 23388.85353,
    "out:PV (ratio sold/self-consumed)": 0.07482,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293868.196,
    "out:EL kWh savings": -44486.29089,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194024.54,
    "out:DH kr savings": 267420.0584,
    "out:El kr savings": -75626.69451,
    "out:El kr sold": 2231.178651,
    "out:El kr saved": 39761.051,
    "out:El kr return": 41992.22965,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 145824.0947,
    "out:% savings (space heating)": 53.006923,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 40.3,
    "out:Etvv": 0,
    "out:Ef": 31.05,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4855750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.202824,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.546455,
    "out:Electricity (inc PV)": 30.967646,
    "out:Total Energy Use Pre PV": 85.546455,
    "out:Total Energy Use Post PV": 75.967646,
    "out:Primary Energy": 81.582225,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.352957,
    "out:CO2 Operational/m2": 17.741774,
    "out:Total CO2/m2": 43.094731,
    "out:Total CO2 (tons)": 129067.2725,
    "out:Klimatpaverkan":0.001* -35824.13218,
    "out:Initial Cost/MSEK": 4.930625,
    "out:Running cost/(Apt SEK y)": 15254.82353,
    "out:Running Cost over RSP/MSEK": 4.04851,
    "out:LCP/MSEK": 0.713765,
    "out:ROI% old": 16.353737,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 114.5,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2317.127142,
    "out:Return %": 15,
    "out:Return/kSEK/y": 721,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.046955,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1761.349035,
    "out:PV (% sold (El))": 7.006497,
    "out:PV (kWh self-consumed)": 23377.44853,
    "out:PV (ratio sold/self-consumed)": 0.075344,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 304311.3504,
    "out:EL kWh savings": -44228.60398,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 203980.42,
    "out:DH kr savings": 276923.3289,
    "out:El kr savings": -75188.62676,
    "out:El kr sold": 2245.720019,
    "out:El kr saved": 39741.6625,
    "out:El kr return": 41987.38252,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 135380.9403,
    "out:% savings (space heating)": 56.37232,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 36.91,
    "out:Etvv": 0,
    "out:Ef": 30.97,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4930625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.631859,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.962386,
    "out:Electricity (inc PV)": 30.675109,
    "out:Total Energy Use Pre PV": 85.962386,
    "out:Total Energy Use Post PV": 76.675109,
    "out:Primary Energy": 82.263279,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.729589,
    "out:CO2 Operational/m2": 17.843354,
    "out:Total CO2/m2": 43.572943,
    "out:Total CO2 (tons)": 130499.5014,
    "out:Klimatpaverkan":0.001* -34391.9033,
    "out:Initial Cost/MSEK": 4.99225,
    "out:Running cost/(Apt SEK y)": 15400.29412,
    "out:Running Cost over RSP/MSEK": 4.08748,
    "out:LCP/MSEK": 0.61317,
    "out:ROI% old": 16.040348,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 111.7,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2278.420242,
    "out:Return %": 14,
    "out:Return/kSEK/y": 716,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.462886,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1873.784668,
    "out:PV (% sold (El))": 7.453756,
    "out:PV (kWh self-consumed)": 23265.0129,
    "out:PV (ratio sold/self-consumed)": 0.080541,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 303026.405,
    "out:EL kWh savings": -43352.46548,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 204443.91,
    "out:DH kr savings": 275754.0286,
    "out:El kr savings": -73699.19132,
    "out:El kr sold": 2389.075451,
    "out:El kr saved": 39550.52193,
    "out:El kr return": 41939.59738,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 136665.8857,
    "out:% savings (space heating)": 55.958235,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 38.64,
    "out:Etvv": 0,
    "out:Ef": 30.68,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4992250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.18117,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.891198,
    "out:Electricity (inc PV)": 30.58257,
    "out:Total Energy Use Pre PV": 81.891198,
    "out:Total Energy Use Post PV": 72.58257,
    "out:Primary Energy": 79.747224,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.729589,
    "out:CO2 Operational/m2": 16.371434,
    "out:Total CO2/m2": 42.101023,
    "out:Total CO2 (tons)": 126091.1504,
    "out:Klimatpaverkan":0.001* -38800.25428,
    "out:Initial Cost/MSEK": 5.067125,
    "out:Running cost/(Apt SEK y)": 14463.76471,
    "out:Running Cost over RSP/MSEK": 3.83832,
    "out:LCP/MSEK": 0.787455,
    "out:ROI% old": 16.505781,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 123.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2273.709056,
    "out:Return %": 15,
    "out:Return/kSEK/y": 748,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.391698,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1887.791696,
    "out:PV (% sold (El))": 7.509475,
    "out:PV (kWh self-consumed)": 23251.00587,
    "out:PV (ratio sold/self-consumed)": 0.081192,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 313361.1027,
    "out:EL kWh savings": -43075.31428,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214337.5,
    "out:DH kr savings": 285158.6035,
    "out:El kr savings": -73228.03428,
    "out:El kr sold": 2406.934413,
    "out:El kr saved": 39526.70998,
    "out:El kr return": 41933.64439,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 126331.188,
    "out:% savings (space heating)": 59.288681,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 35.28,
    "out:Etvv": 0,
    "out:Ef": 30.58,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5067125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.689726,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.606669,
    "out:Electricity (inc PV)": 27.99266,
    "out:Total Energy Use Pre PV": 89.606669,
    "out:Total Energy Use Post PV": 76.99266,
    "out:Primary Energy": 78.593344,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.629866,
    "out:CO2 Operational/m2": -7.077498,
    "out:Total CO2/m2": 27.552368,
    "out:Total CO2 (tons)": 82518.41714,
    "out:Klimatpaverkan":0.001* -82372.98757,
    "out:Initial Cost/MSEK": 5.3235,
    "out:Running cost/(Apt SEK y)": 15125.23529,
    "out:Running Cost over RSP/MSEK": 4.02152,
    "out:LCP/MSEK": 0.34788,
    "out:ROI% old": 15.219257,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 111.7,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2088.445948,
    "out:Return %": 14,
    "out:Return/kSEK/y": 725,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.107169,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 17143.91633,
    "out:PV (% sold (El))": 34.098521,
    "out:PV (kWh self-consumed)": 33133.67898,
    "out:PV (ratio sold/self-consumed)": 0.517417,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293868.196,
    "out:EL kWh savings": -35318.62079,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 229236.9,
    "out:DH kr savings": 267420.0584,
    "out:El kr savings": -60041.65533,
    "out:El kr sold": 21858.49332,
    "out:El kr saved": 56327.25427,
    "out:El kr return": 78185.74759,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 145824.0947,
    "out:% savings (space heating)": 53.006923,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 40.3,
    "out:Etvv": 0,
    "out:Ef": 27.99,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5323500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.202824,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.546455,
    "out:Electricity (inc PV)": 27.916065,
    "out:Total Energy Use Pre PV": 85.546455,
    "out:Total Energy Use Post PV": 72.916065,
    "out:Primary Energy": 76.089379,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.629866,
    "out:CO2 Operational/m2": -8.575732,
    "out:Total CO2/m2": 26.054134,
    "out:Total CO2 (tons)": 78031.25662,
    "out:Klimatpaverkan":0.001* -86860.14809,
    "out:Initial Cost/MSEK": 5.398375,
    "out:Running cost/(Apt SEK y)": 14190.55882,
    "out:Running Cost over RSP/MSEK": 3.77284,
    "out:LCP/MSEK": 0.521685,
    "out:ROI% old": 15.666249,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 123.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2084.906117,
    "out:Return %": 14,
    "out:Return/kSEK/y": 757,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.046955,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 17173.99521,
    "out:PV (% sold (El))": 34.158346,
    "out:PV (kWh self-consumed)": 33103.60011,
    "out:PV (ratio sold/self-consumed)": 0.518795,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 304311.3504,
    "out:EL kWh savings": -35089.22133,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 239168.5,
    "out:DH kr savings": 276923.3289,
    "out:El kr savings": -59651.67626,
    "out:El kr sold": 21896.84389,
    "out:El kr saved": 56276.12018,
    "out:El kr return": 78172.96407,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 135380.9403,
    "out:% savings (space heating)": 56.37232,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 36.91,
    "out:Etvv": 0,
    "out:Ef": 27.92,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5398375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.631859,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.962386,
    "out:Electricity (inc PV)": 27.655745,
    "out:Total Energy Use Pre PV": 85.962386,
    "out:Total Energy Use Post PV": 73.655745,
    "out:Primary Energy": 76.828424,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.006498,
    "out:CO2 Operational/m2": -8.783804,
    "out:Total CO2/m2": 26.222694,
    "out:Total CO2 (tons)": 78536.08816,
    "out:Klimatpaverkan":0.001* -86355.31655,
    "out:Initial Cost/MSEK": 5.460125,
    "out:Running cost/(Apt SEK y)": 14338.26471,
    "out:Running Cost over RSP/MSEK": 3.81241,
    "out:LCP/MSEK": 0.420365,
    "out:ROI% old": 15.385545,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 120.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2050.607588,
    "out:Return %": 14,
    "out:Return/kSEK/y": 752,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.462886,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 17468.49012,
    "out:PV (% sold (El))": 34.744084,
    "out:PV (kWh self-consumed)": 32809.10519,
    "out:PV (ratio sold/self-consumed)": 0.532428,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 303026.405,
    "out:EL kWh savings": -34309.57167,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 239700.08,
    "out:DH kr savings": 275754.0286,
    "out:El kr savings": -58326.27184,
    "out:El kr sold": 22272.3249,
    "out:El kr saved": 55775.47882,
    "out:El kr return": 78047.80373,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 136665.8857,
    "out:% savings (space heating)": 55.958235,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 38.64,
    "out:Etvv": 0,
    "out:Ef": 27.66,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5460125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.18117,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.891198,
    "out:Electricity (inc PV)": 27.573411,
    "out:Total Energy Use Pre PV": 81.891198,
    "out:Total Energy Use Post PV": 69.573411,
    "out:Primary Energy": 74.330738,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.006498,
    "out:CO2 Operational/m2": -10.293287,
    "out:Total CO2/m2": 24.713211,
    "out:Total CO2 (tons)": 74015.23725,
    "out:Klimatpaverkan":0.001* -90876.16746,
    "out:Initial Cost/MSEK": 5.535,
    "out:Running cost/(Apt SEK y)": 13402.02941,
    "out:Running Cost over RSP/MSEK": 3.56333,
    "out:LCP/MSEK": 0.59457,
    "out:ROI% old": 15.820286,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 132.9,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2046.431768,
    "out:Return %": 14,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.391698,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 17504.58419,
    "out:PV (% sold (El))": 34.815874,
    "out:PV (kWh self-consumed)": 32773.01113,
    "out:PV (ratio sold/self-consumed)": 0.534116,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 313361.1027,
    "out:EL kWh savings": -34062.98411,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 249569.88,
    "out:DH kr savings": 285158.6035,
    "out:El kr savings": -57907.07298,
    "out:El kr sold": 22318.34484,
    "out:El kr saved": 55714.11891,
    "out:El kr return": 78032.46375,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 126331.188,
    "out:% savings (space heating)": 59.288681,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 35.28,
    "out:Etvv": 0,
    "out:Ef": 27.57,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5535000,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z01000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.913198,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.369253,
    "out:Electricity (inc PV)": 50.343946,
    "out:Total Energy Use Pre PV": 66.369253,
    "out:Total Energy Use Post PV": 62.343946,
    "out:Primary Energy": 95.5168,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.557757,
    "out:CO2 Operational/m2": 11.496984,
    "out:Total CO2/m2": 28.054741,
    "out:Total CO2 (tons)": 84023.00741,
    "out:Klimatpaverkan":0.001* -80868.3973,
    "out:Initial Cost/MSEK": 5.624,
    "out:Running cost/(Apt SEK y)": 10919.82353,
    "out:Running Cost over RSP/MSEK": 2.87923,
    "out:LCP/MSEK": 1.18967,
    "out:ROI% old": 17.307636,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 162.9,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3805.84771,
    "out:Return %": 15,
    "out:Return/kSEK/y": 868,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.869753,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 404012.6627,
    "out:EL kWh savings": -102259.972,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 193809.57,
    "out:DH kr savings": 367651.523,
    "out:El kr savings": -173841.9523,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 35679.62805,
    "out:% savings (space heating)": 88.501931,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 7,
    "out:Etvv": 0,
    "out:Ef": 50.34,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5624000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.654845,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.936752,
    "out:Electricity (inc PV)": 49.730084,
    "out:Total Energy Use Pre PV": 63.936752,
    "out:Total Energy Use Post PV": 59.730084,
    "out:Primary Energy": 93.249945,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.557757,
    "out:CO2 Operational/m2": 10.719923,
    "out:Total CO2/m2": 27.27768,
    "out:Total CO2 (tons)": 81695.73581,
    "out:Klimatpaverkan":0.001* -83195.6689,
    "out:Initial Cost/MSEK": 5.698875,
    "out:Running cost/(Apt SEK y)": 10392.05882,
    "out:Running Cost over RSP/MSEK": 2.73901,
    "out:LCP/MSEK": 1.255015,
    "out:ROI% old": 17.431736,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 171.7,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3775.57264,
    "out:Return %": 16,
    "out:Return/kSEK/y": 886,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.437252,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 410776.3541,
    "out:EL kWh savings": -100421.4759,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 203089.97,
    "out:DH kr savings": 373806.4822,
    "out:El kr savings": -170716.509,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 28915.93663,
    "out:% savings (space heating)": 90.681589,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 5.34,
    "out:Etvv": 0,
    "out:Ef": 49.73,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5698875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.982326,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.990729,
    "out:Electricity (inc PV)": 49.450447,
    "out:Total Energy Use Pre PV": 62.990729,
    "out:Total Energy Use Post PV": 59.450447,
    "out:Primary Energy": 93.347486,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.934389,
    "out:CO2 Operational/m2": 10.59504,
    "out:Total CO2/m2": 27.529429,
    "out:Total CO2 (tons)": 82449.71561,
    "out:Klimatpaverkan":0.001* -82441.6891,
    "out:Initial Cost/MSEK": 5.760625,
    "out:Running cost/(Apt SEK y)": 10250.38235,
    "out:Running Cost over RSP/MSEK": 2.70177,
    "out:LCP/MSEK": 1.230505,
    "out:ROI% old": 17.337231,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 176.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3709.35103,
    "out:Return %": 15,
    "out:Return/kSEK/y": 891,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.491229,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 409795.5595,
    "out:EL kWh savings": -99583.97245,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 203621.21,
    "out:DH kr savings": 372913.9591,
    "out:El kr savings": -169292.7532,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 29896.73123,
    "out:% savings (space heating)": 90.36552,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 6.2,
    "out:Etvv": 0,
    "out:Ef": 49.45,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5760625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.912287,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.501163,
    "out:Electricity (inc PV)": 48.79016,
    "out:Total Energy Use Pre PV": 60.501163,
    "out:Total Energy Use Post PV": 56.79016,
    "out:Primary Energy": 91.104307,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.934389,
    "out:CO2 Operational/m2": 9.810446,
    "out:Total CO2/m2": 26.744835,
    "out:Total CO2 (tons)": 80099.88292,
    "out:Klimatpaverkan":0.001* -84791.52179,
    "out:Initial Cost/MSEK": 5.8355,
    "out:Running cost/(Apt SEK y)": 9714.058824,
    "out:Running Cost over RSP/MSEK": 2.5593,
    "out:LCP/MSEK": 1.2981,
    "out:ROI% old": 17.463554,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 186,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3675.08141,
    "out:Return %": 16,
    "out:Return/kSEK/y": 909,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.001663,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415995.2568,
    "out:EL kWh savings": -97606.43505,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212624.74,
    "out:DH kr savings": 378555.6837,
    "out:El kr savings": -165930.9396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 23697.03393,
    "out:% savings (space heating)": 92.363426,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.69,
    "out:Etvv": 0,
    "out:Ef": 48.79,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5835500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.913198,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.369253,
    "out:Electricity (inc PV)": 42.099921,
    "out:Total Energy Use Pre PV": 66.369253,
    "out:Total Energy Use Post PV": 54.099921,
    "out:Primary Energy": 80.677555,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.834666,
    "out:CO2 Operational/m2": 10.18641,
    "out:Total CO2/m2": 36.021076,
    "out:Total CO2 (tons)": 107881.9133,
    "out:Klimatpaverkan":0.001* -57009.49143,
    "out:Initial Cost/MSEK": 6.091875,
    "out:Running cost/(Apt SEK y)": 9665.058824,
    "out:Running Cost over RSP/MSEK": 2.55493,
    "out:LCP/MSEK": 1.046095,
    "out:ROI% old": 16.738853,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 201.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3257.770628,
    "out:Return %": 15,
    "out:Return/kSEK/y": 911,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.869753,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 172.378076,
    "out:PV (% sold (El))": 0.685705,
    "out:PV (kWh self-consumed)": 24966.41949,
    "out:PV (ratio sold/self-consumed)": 0.006904,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 404012.6627,
    "out:EL kWh savings": -77569.39386,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236003.34,
    "out:DH kr savings": 367651.523,
    "out:El kr savings": -131867.9696,
    "out:El kr sold": 219.7820472,
    "out:El kr saved": 42442.91313,
    "out:El kr return": 42662.69518,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 35679.62805,
    "out:% savings (space heating)": 88.501931,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 7,
    "out:Etvv": 0,
    "out:Ef": 42.1,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6091875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.654845,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.936752,
    "out:Electricity (inc PV)": 41.503755,
    "out:Total Energy Use Pre PV": 63.936752,
    "out:Total Energy Use Post PV": 51.503755,
    "out:Primary Energy": 78.442553,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.834666,
    "out:CO2 Operational/m2": 9.377223,
    "out:Total CO2/m2": 35.211889,
    "out:Total CO2 (tons)": 105458.4254,
    "out:Klimatpaverkan":0.001* -59432.97932,
    "out:Initial Cost/MSEK": 6.16675,
    "out:Running cost/(Apt SEK y)": 9137.529412,
    "out:Running Cost over RSP/MSEK": 2.41476,
    "out:LCP/MSEK": 1.11139,
    "out:ROI% old": 16.860328,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 213.5,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3227.733614,
    "out:Return %": 15,
    "out:Return/kSEK/y": 929,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.437252,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 194.901272,
    "out:PV (% sold (El))": 0.775301,
    "out:PV (kWh self-consumed)": 24943.89629,
    "out:PV (ratio sold/self-consumed)": 0.007814,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 410776.3541,
    "out:EL kWh savings": -75783.8967,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 245222.36,
    "out:DH kr savings": 373806.4822,
    "out:El kr savings": -128832.6244,
    "out:El kr sold": 248.4991222,
    "out:El kr saved": 42404.6237,
    "out:El kr return": 42653.12282,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 28915.93663,
    "out:% savings (space heating)": 90.681589,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 5.34,
    "out:Etvv": 0,
    "out:Ef": 41.5,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6166750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.982326,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.990729,
    "out:Electricity (inc PV)": 41.232538,
    "out:Total Energy Use Pre PV": 62.990729,
    "out:Total Energy Use Post PV": 51.232538,
    "out:Primary Energy": 78.555249,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.211298,
    "out:CO2 Operational/m2": 9.171982,
    "out:Total CO2/m2": 35.38328,
    "out:Total CO2 (tons)": 105971.7357,
    "out:Klimatpaverkan":0.001* -58919.66903,
    "out:Initial Cost/MSEK": 6.2285,
    "out:Running cost/(Apt SEK y)": 8996.558824,
    "out:Running Cost over RSP/MSEK": 2.37768,
    "out:LCP/MSEK": 1.08672,
    "out:ROI% old": 16.77822,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 219.6,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3162.137252,
    "out:Return %": 15,
    "out:Return/kSEK/y": 934,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.491229,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 250.44095,
    "out:PV (% sold (El))": 0.996233,
    "out:PV (kWh self-consumed)": 24888.35662,
    "out:PV (ratio sold/self-consumed)": 0.010063,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 409795.5595,
    "out:EL kWh savings": -74971.61089,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245781.53,
    "out:DH kr savings": 372913.9591,
    "out:El kr savings": -127451.7385,
    "out:El kr sold": 319.3122113,
    "out:El kr saved": 42310.20625,
    "out:El kr return": 42629.51846,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 29896.73123,
    "out:% savings (space heating)": 90.36552,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 6.2,
    "out:Etvv": 0,
    "out:Ef": 41.23,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6228500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.912287,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.501163,
    "out:Electricity (inc PV)": 40.592895,
    "out:Total Energy Use Pre PV": 60.501163,
    "out:Total Energy Use Post PV": 48.592895,
    "out:Primary Energy": 76.34923,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.211298,
    "out:CO2 Operational/m2": 8.340355,
    "out:Total CO2/m2": 34.551653,
    "out:Total CO2 (tons)": 103481.0407,
    "out:Klimatpaverkan":0.001* -61410.36398,
    "out:Initial Cost/MSEK": 6.303375,
    "out:Running cost/(Apt SEK y)": 8460.647059,
    "out:Running Cost over RSP/MSEK": 2.2353,
    "out:LCP/MSEK": 1.154225,
    "out:ROI% old": 16.901603,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 232.7,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3128.251035,
    "out:Return %": 15,
    "out:Return/kSEK/y": 952,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.001663,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 282.271294,
    "out:PV (% sold (El))": 1.122851,
    "out:PV (kWh self-consumed)": 24856.52627,
    "out:PV (ratio sold/self-consumed)": 0.011356,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415995.2568,
    "out:EL kWh savings": -73055.90158,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 254720.55,
    "out:DH kr savings": 378555.6837,
    "out:El kr savings": -124195.0327,
    "out:El kr sold": 359.8959002,
    "out:El kr saved": 42256.09466,
    "out:El kr return": 42615.99056,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 23697.03393,
    "out:% savings (space heating)": 92.363426,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.69,
    "out:Etvv": 0,
    "out:Ef": 40.59,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6303375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.913198,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.369253,
    "out:Electricity (inc PV)": 37.778815,
    "out:Total Energy Use Pre PV": 66.369253,
    "out:Total Energy Use Post PV": 49.778815,
    "out:Primary Energy": 72.899564,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.111575,
    "out:CO2 Operational/m2": -8.446664,
    "out:Total CO2/m2": 26.664911,
    "out:Total CO2 (tons)": 79860.51322,
    "out:Klimatpaverkan":0.001* -85030.89149,
    "out:Initial Cost/MSEK": 6.559625,
    "out:Running cost/(Apt SEK y)": 8544,
    "out:Running Cost over RSP/MSEK": 2.26429,
    "out:LCP/MSEK": 0.868985,
    "out:ROI% old": 16.178211,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 226,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 2918.494307,
    "out:Return %": 14,
    "out:Return/kSEK/y": 949,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.869753,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 11043.23193,
    "out:PV (% sold (El))": 21.964519,
    "out:PV (kWh self-consumed)": 39234.36339,
    "out:PV (ratio sold/self-consumed)": 0.281468,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 404012.6627,
    "out:EL kWh savings": -64627.82646,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 271864.34,
    "out:DH kr savings": 367651.523,
    "out:El kr savings": -109867.305,
    "out:El kr sold": 14080.12071,
    "out:El kr saved": 66698.41775,
    "out:El kr return": 80778.53846,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 35679.62805,
    "out:% savings (space heating)": 88.501931,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 7,
    "out:Etvv": 0,
    "out:Ef": 37.78,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6559625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.654845,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.936752,
    "out:Electricity (inc PV)": 37.250762,
    "out:Total Energy Use Pre PV": 63.936752,
    "out:Total Energy Use Post PV": 47.250762,
    "out:Primary Energy": 70.787166,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.111575,
    "out:CO2 Operational/m2": -9.498857,
    "out:Total CO2/m2": 25.612718,
    "out:Total CO2 (tons)": 76709.23051,
    "out:Klimatpaverkan":0.001* -88182.1742,
    "out:Initial Cost/MSEK": 6.6345,
    "out:Running cost/(Apt SEK y)": 8018.294118,
    "out:Running Cost over RSP/MSEK": 2.1246,
    "out:LCP/MSEK": 0.9338,
    "out:ROI% old": 16.296416,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 246.8,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 2891.806987,
    "out:Return %": 15,
    "out:Return/kSEK/y": 967,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.437252,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 11209.07005,
    "out:PV (% sold (El))": 22.294364,
    "out:PV (kWh self-consumed)": 39068.52526,
    "out:PV (ratio sold/self-consumed)": 0.286908,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 410776.3541,
    "out:EL kWh savings": -63046.32545,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280919.29,
    "out:DH kr savings": 373806.4822,
    "out:El kr savings": -107178.7533,
    "out:El kr sold": 14291.56431,
    "out:El kr saved": 66416.49295,
    "out:El kr return": 80708.05726,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 28915.93663,
    "out:% savings (space heating)": 90.681589,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 5.34,
    "out:Etvv": 0,
    "out:Ef": 37.25,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6634500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.982326,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.990729,
    "out:Electricity (inc PV)": 37.01059,
    "out:Total Energy Use Pre PV": 62.990729,
    "out:Total Energy Use Post PV": 47.01059,
    "out:Primary Energy": 70.955743,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.488207,
    "out:CO2 Operational/m2": -10.233026,
    "out:Total CO2/m2": 25.255181,
    "out:Total CO2 (tons)": 75638.4192,
    "out:Klimatpaverkan":0.001* -89252.98551,
    "out:Initial Cost/MSEK": 6.69625,
    "out:Running cost/(Apt SEK y)": 7881.205882,
    "out:Running Cost over RSP/MSEK": 2.08855,
    "out:LCP/MSEK": 0.9081,
    "out:ROI% old": 16.223046,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 246.8,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 2833.560424,
    "out:Return %": 15,
    "out:Return/kSEK/y": 972,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.491229,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 11576.28106,
    "out:PV (% sold (El))": 23.024731,
    "out:PV (kWh self-consumed)": 38701.31425,
    "out:PV (ratio sold/self-consumed)": 0.299119,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 409795.5595,
    "out:EL kWh savings": -62327.01838,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 281717.79,
    "out:DH kr savings": 372913.9591,
    "out:El kr savings": -105955.9312,
    "out:El kr sold": 14759.75836,
    "out:El kr saved": 65792.23422,
    "out:El kr return": 80551.99258,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 29896.73123,
    "out:% savings (space heating)": 90.36552,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 6.2,
    "out:Etvv": 0,
    "out:Ef": 37.01,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6696250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.912287,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.501163,
    "out:Electricity (inc PV)": 36.444641,
    "out:Total Energy Use Pre PV": 60.501163,
    "out:Total Energy Use Post PV": 44.444641,
    "out:Primary Energy": 68.882373,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.488207,
    "out:CO2 Operational/m2": -11.337609,
    "out:Total CO2/m2": 24.150598,
    "out:Total CO2 (tons)": 72330.2302,
    "out:Klimatpaverkan":0.001* -92561.17451,
    "out:Initial Cost/MSEK": 6.771125,
    "out:Running cost/(Apt SEK y)": 7347.294118,
    "out:Running Cost over RSP/MSEK": 1.9467,
    "out:LCP/MSEK": 0.975075,
    "out:ROI% old": 16.342927,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 270.5,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2803.487608,
    "out:Return %": 15,
    "out:Return/kSEK/y": 990,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.001663,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 11769.75996,
    "out:PV (% sold (El))": 23.409552,
    "out:PV (kWh self-consumed)": 38507.83535,
    "out:PV (ratio sold/self-consumed)": 0.305646,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415995.2568,
    "out:EL kWh savings": -60632.02012,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 290487.69,
    "out:DH kr savings": 378555.6837,
    "out:El kr savings": -103074.4342,
    "out:El kr sold": 15006.44395,
    "out:El kr saved": 65463.3201,
    "out:El kr return": 80469.76405,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 23697.03393,
    "out:% savings (space heating)": 92.363426,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.69,
    "out:Etvv": 0,
    "out:Ef": 36.44,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6771125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.382416,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.517975,
    "out:Electricity (inc PV)": 49.14684,
    "out:Total Energy Use Pre PV": 61.517975,
    "out:Total Energy Use Post PV": 58.14684,
    "out:Primary Energy": 92.627325,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.557757,
    "out:CO2 Operational/m2": 10.172649,
    "out:Total CO2/m2": 26.730406,
    "out:Total CO2 (tons)": 80056.66855,
    "out:Klimatpaverkan":0.001* -84834.73616,
    "out:Initial Cost/MSEK": 5.71375,
    "out:Running cost/(Apt SEK y)": 9948.088235,
    "out:Running Cost over RSP/MSEK": 2.62156,
    "out:LCP/MSEK": 1.35759,
    "out:ROI% old": 17.680008,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 181,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3676.25825,
    "out:Return %": 16,
    "out:Return/kSEK/y": 901,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 34.018475,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 411592.2698,
    "out:EL kWh savings": -98674.67968,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206802.01,
    "out:DH kr savings": 374548.9655,
    "out:El kr savings": -167746.9554,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 28100.02092,
    "out:% savings (space heating)": 90.944525,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 5.95,
    "out:Etvv": 0,
    "out:Ef": 49.15,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5713750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.378683,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.008316,
    "out:Electricity (inc PV)": 48.471299,
    "out:Total Energy Use Pre PV": 59.008316,
    "out:Total Energy Use Post PV": 55.471299,
    "out:Primary Energy": 90.391925,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.557757,
    "out:CO2 Operational/m2": 9.385402,
    "out:Total CO2/m2": 25.943159,
    "out:Total CO2 (tons)": 77698.89022,
    "out:Klimatpaverkan":0.001* -87192.51449,
    "out:Initial Cost/MSEK": 5.788625,
    "out:Running cost/(Apt SEK y)": 9408.735294,
    "out:Running Cost over RSP/MSEK": 2.4783,
    "out:LCP/MSEK": 1.425975,
    "out:ROI% old": 17.80487,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 196.4,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3640.58212,
    "out:Return %": 16,
    "out:Return/kSEK/y": 920,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 33.508816,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 417593.3829,
    "out:EL kWh savings": -96651.45706,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215702.5,
    "out:DH kr savings": 380009.9784,
    "out:El kr savings": -164307.477,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 22098.90786,
    "out:% savings (space heating)": 92.878436,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.49,
    "out:Etvv": 0,
    "out:Ef": 48.47,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5788625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.033958,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.35569,
    "out:Electricity (inc PV)": 48.407137,
    "out:Total Energy Use Pre PV": 59.35569,
    "out:Total Energy Use Post PV": 56.407137,
    "out:Primary Energy": 90.904574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.934389,
    "out:CO2 Operational/m2": 9.659234,
    "out:Total CO2/m2": 26.593623,
    "out:Total CO2 (tons)": 79647.00806,
    "out:Klimatpaverkan":0.001* -85244.39665,
    "out:Initial Cost/MSEK": 5.850375,
    "out:Running cost/(Apt SEK y)": 9542.529412,
    "out:Running Cost over RSP/MSEK": 2.51421,
    "out:LCP/MSEK": 1.328315,
    "out:ROI% old": 17.529255,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 191.1,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3594.8983,
    "out:Return %": 16,
    "out:Return/kSEK/y": 915,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.85619,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415630.8562,
    "out:EL kWh savings": -96459.29403,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214243.28,
    "out:DH kr savings": 378224.0792,
    "out:El kr savings": -163980.7998,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 24061.43449,
    "out:% savings (space heating)": 92.245994,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 5.39,
    "out:Etvv": 0,
    "out:Ef": 48.41,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5850375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.201831,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.794029,
    "out:Electricity (inc PV)": 47.696004,
    "out:Total Energy Use Pre PV": 56.794029,
    "out:Total Energy Use Post PV": 53.696004,
    "out:Primary Energy": 88.687325,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.934389,
    "out:CO2 Operational/m2": 8.865122,
    "out:Total CO2/m2": 25.799511,
    "out:Total CO2 (tons)": 77268.66928,
    "out:Klimatpaverkan":0.001* -87622.73543,
    "out:Initial Cost/MSEK": 5.92525,
    "out:Running cost/(Apt SEK y)": 8995.382353,
    "out:Running Cost over RSP/MSEK": 2.3689,
    "out:LCP/MSEK": 1.39875,
    "out:ROI% old": 17.658086,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 201.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3555.58203,
    "out:Return %": 16,
    "out:Return/kSEK/y": 934,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.294529,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 421118.0151,
    "out:EL kWh savings": -94329.47457,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222857.29,
    "out:DH kr savings": 383217.3937,
    "out:El kr savings": -160360.1068,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 18574.27563,
    "out:% savings (space heating)": 94.014279,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.05,
    "out:Etvv": 0,
    "out:Ef": 47.7,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5925250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.382416,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.517975,
    "out:Electricity (inc PV)": 40.938214,
    "out:Total Energy Use Pre PV": 61.517975,
    "out:Total Energy Use Post PV": 49.938214,
    "out:Primary Energy": 77.851798,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.834666,
    "out:CO2 Operational/m2": 8.70424,
    "out:Total CO2/m2": 34.538906,
    "out:Total CO2 (tons)": 103442.8639,
    "out:Klimatpaverkan":0.001* -61448.54081,
    "out:Initial Cost/MSEK": 6.181625,
    "out:Running cost/(Apt SEK y)": 8694.647059,
    "out:Running Cost over RSP/MSEK": 2.29756,
    "out:LCP/MSEK": 1.213715,
    "out:ROI% old": 17.090606,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 226,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3129.414019,
    "out:Return %": 15,
    "out:Return/kSEK/y": 944,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 34.018475,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 281.127467,
    "out:PV (% sold (El))": 1.118301,
    "out:PV (kWh self-consumed)": 24857.6701,
    "out:PV (ratio sold/self-consumed)": 0.011309,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 411592.2698,
    "out:EL kWh savings": -74090.12039,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248954.2,
    "out:DH kr savings": 374548.9655,
    "out:El kr savings": -125953.2047,
    "out:El kr sold": 358.4375209,
    "out:El kr saved": 42258.03917,
    "out:El kr return": 42616.47669,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 28100.02092,
    "out:% savings (space heating)": 90.944525,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 5.95,
    "out:Etvv": 0,
    "out:Ef": 40.94,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6181625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.378683,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.008316,
    "out:Electricity (inc PV)": 40.284495,
    "out:Total Energy Use Pre PV": 59.008316,
    "out:Total Energy Use Post PV": 47.284495,
    "out:Primary Energy": 75.655678,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.834666,
    "out:CO2 Operational/m2": 7.863836,
    "out:Total CO2/m2": 33.698502,
    "out:Total CO2 (tons)": 100925.8821,
    "out:Klimatpaverkan":0.001* -63965.52258,
    "out:Initial Cost/MSEK": 6.2565,
    "out:Running cost/(Apt SEK y)": 8155.764706,
    "out:Running Cost over RSP/MSEK": 2.15441,
    "out:LCP/MSEK": 1.28199,
    "out:ROI% old": 17.212933,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 246.8,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3094.183101,
    "out:Return %": 15,
    "out:Return/kSEK/y": 962,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 33.508816,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 317.172325,
    "out:PV (% sold (El))": 1.261685,
    "out:PV (kWh self-consumed)": 24821.62524,
    "out:PV (ratio sold/self-consumed)": 0.012778,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 417593.3829,
    "out:EL kWh savings": -72132.25394,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257789.54,
    "out:DH kr savings": 380009.9784,
    "out:El kr savings": -122624.8317,
    "out:El kr sold": 404.3947138,
    "out:El kr saved": 42196.76291,
    "out:El kr return": 42601.15763,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 22098.90786,
    "out:% savings (space heating)": 92.878436,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.49,
    "out:Etvv": 0,
    "out:Ef": 40.28,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6256500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.033958,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.35569,
    "out:Electricity (inc PV)": 40.222495,
    "out:Total Energy Use Pre PV": 59.35569,
    "out:Total Energy Use Post PV": 48.222495,
    "out:Primary Energy": 76.172218,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.211298,
    "out:CO2 Operational/m2": 8.06344,
    "out:Total CO2/m2": 34.274738,
    "out:Total CO2 (tons)": 102651.6896,
    "out:Klimatpaverkan":0.001* -62239.71511,
    "out:Initial Cost/MSEK": 6.318125,
    "out:Running cost/(Apt SEK y)": 8290.147059,
    "out:Running Cost over RSP/MSEK": 2.19046,
    "out:LCP/MSEK": 1.184315,
    "out:ROI% old": 16.963532,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 239.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3049.14491,
    "out:Return %": 15,
    "out:Return/kSEK/y": 958,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.85619,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 366.355747,
    "out:PV (% sold (El))": 1.457332,
    "out:PV (kWh self-consumed)": 24772.44182,
    "out:PV (ratio sold/self-consumed)": 0.014789,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415630.8562,
    "out:EL kWh savings": -71946.56602,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 256382.02,
    "out:DH kr savings": 378224.0792,
    "out:El kr savings": -122309.1622,
    "out:El kr sold": 467.1035772,
    "out:El kr saved": 42113.15109,
    "out:El kr return": 42580.25467,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 24061.43449,
    "out:% savings (space heating)": 92.245994,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 5.39,
    "out:Etvv": 0,
    "out:Ef": 40.22,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6318125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.201831,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.794029,
    "out:Electricity (inc PV)": 39.536368,
    "out:Total Energy Use Pre PV": 56.794029,
    "out:Total Energy Use Post PV": 45.536368,
    "out:Primary Energy": 73.99998,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 26.211298,
    "out:CO2 Operational/m2": 7.200383,
    "out:Total CO2/m2": 33.411681,
    "out:Total CO2 (tons)": 100066.8629,
    "out:Klimatpaverkan":0.001* -64824.54184,
    "out:Initial Cost/MSEK": 6.393,
    "out:Running cost/(Apt SEK y)": 7743.588235,
    "out:Running Cost over RSP/MSEK": 2.04529,
    "out:LCP/MSEK": 1.25461,
    "out:ROI% old": 17.089249,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 254.3,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3010.453912,
    "out:Return %": 15,
    "out:Return/kSEK/y": 976,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.294529,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 410.782062,
    "out:PV (% sold (El))": 1.634056,
    "out:PV (kWh self-consumed)": 24728.01551,
    "out:PV (ratio sold/self-consumed)": 0.016612,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 421118.0151,
    "out:EL kWh savings": -69891.63869,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 264925.36,
    "out:DH kr savings": 383217.3937,
    "out:El kr savings": -118815.7858,
    "out:El kr sold": 523.7471285,
    "out:El kr saved": 42037.62636,
    "out:El kr return": 42561.37349,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 18574.27563,
    "out:% savings (space heating)": 94.014279,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.05,
    "out:Etvv": 0,
    "out:Ef": 39.54,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6393000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.382416,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.517975,
    "out:Electricity (inc PV)": 36.750128,
    "out:Total Energy Use Pre PV": 61.517975,
    "out:Total Energy Use Post PV": 45.750128,
    "out:Primary Energy": 70.313243,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.111575,
    "out:CO2 Operational/m2": -10.964361,
    "out:Total CO2/m2": 24.147214,
    "out:Total CO2 (tons)": 72320.09524,
    "out:Klimatpaverkan":0.001* -92571.30947,
    "out:Initial Cost/MSEK": 6.649375,
    "out:Running cost/(Apt SEK y)": 7581.235294,
    "out:Running Cost over RSP/MSEK": 2.00894,
    "out:LCP/MSEK": 1.034585,
    "out:ROI% old": 16.508447,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 254.3,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2804.51954,
    "out:Return %": 15,
    "out:Return/kSEK/y": 982,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 34.018475,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 11763.09243,
    "out:PV (% sold (El))": 23.396291,
    "out:PV (kWh self-consumed)": 38514.50288,
    "out:PV (ratio sold/self-consumed)": 0.30542,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 411592.2698,
    "out:EL kWh savings": -61546.94343,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 284917.1,
    "out:DH kr savings": 374548.9655,
    "out:El kr savings": -104629.8038,
    "out:El kr sold": 14997.94285,
    "out:El kr saved": 65474.6549,
    "out:El kr return": 80472.59775,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 28100.02092,
    "out:% savings (space heating)": 90.944525,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 5.95,
    "out:Etvv": 0,
    "out:Ef": 36.75,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6649375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.378683,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.008316,
    "out:Electricity (inc PV)": 36.171975,
    "out:Total Energy Use Pre PV": 59.008316,
    "out:Total Energy Use Post PV": 43.171975,
    "out:Primary Energy": 68.253142,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.111575,
    "out:CO2 Operational/m2": -12.088066,
    "out:Total CO2/m2": 23.023509,
    "out:Total CO2 (tons)": 68954.63649,
    "out:Klimatpaverkan":0.001* -95936.76822,
    "out:Initial Cost/MSEK": 6.72425,
    "out:Running cost/(Apt SEK y)": 7044.441176,
    "out:Running Cost over RSP/MSEK": 1.86634,
    "out:LCP/MSEK": 1.10231,
    "out:ROI% old": 16.627579,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 279.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2773.260471,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1000,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 33.508816,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 11966.11932,
    "out:PV (% sold (El))": 23.800103,
    "out:PV (kWh self-consumed)": 38311.47599,
    "out:PV (ratio sold/self-consumed)": 0.312338,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 417593.3829,
    "out:EL kWh savings": -59815.39461,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 293580.61,
    "out:DH kr savings": 380009.9784,
    "out:El kr savings": -101686.1708,
    "out:El kr sold": 15256.80213,
    "out:El kr saved": 65129.50919,
    "out:El kr return": 80386.31132,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 22098.90786,
    "out:% savings (space heating)": 92.878436,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.49,
    "out:Etvv": 0,
    "out:Ef": 36.17,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6724250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.033958,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.35569,
    "out:Electricity (inc PV)": 36.117156,
    "out:Total Energy Use Pre PV": 59.35569,
    "out:Total Energy Use Post PV": 44.117156,
    "out:Primary Energy": 68.782608,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.488207,
    "out:CO2 Operational/m2": -12.250523,
    "out:Total CO2/m2": 23.237684,
    "out:Total CO2 (tons)": 69596.08342,
    "out:Klimatpaverkan":0.001* -95295.32129,
    "out:Initial Cost/MSEK": 6.786,
    "out:Running cost/(Apt SEK y)": 7181.5,
    "out:Running Cost over RSP/MSEK": 1.9031,
    "out:LCP/MSEK": 1.0038,
    "out:ROI% old": 16.398888,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 270.5,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2733.305752,
    "out:Return %": 15,
    "out:Return/kSEK/y": 995,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.85619,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 12229.5465,
    "out:PV (% sold (El))": 24.324048,
    "out:PV (kWh self-consumed)": 38048.04881,
    "out:PV (ratio sold/self-consumed)": 0.321424,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415630.8562,
    "out:EL kWh savings": -59651.21354,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 292409.69,
    "out:DH kr savings": 378224.0792,
    "out:El kr savings": -101407.063,
    "out:El kr sold": 15592.67179,
    "out:El kr saved": 64681.68298,
    "out:El kr return": 80274.35477,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 24061.43449,
    "out:% savings (space heating)": 92.245994,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 5.39,
    "out:Etvv": 0,
    "out:Ef": 36.12,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6786000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.201831,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.794029,
    "out:Electricity (inc PV)": 35.51051,
    "out:Total Energy Use Pre PV": 56.794029,
    "out:Total Energy Use Post PV": 41.51051,
    "out:Primary Energy": 66.753436,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.488207,
    "out:CO2 Operational/m2": -13.425191,
    "out:Total CO2/m2": 22.063016,
    "out:Total CO2 (tons)": 66077.9922,
    "out:Klimatpaverkan":0.001* -98813.41251,
    "out:Initial Cost/MSEK": 6.860875,
    "out:Running cost/(Apt SEK y)": 6637.235294,
    "out:Running Cost over RSP/MSEK": 1.75853,
    "out:LCP/MSEK": 1.073495,
    "out:ROI% old": 16.520946,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 288.1,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2698.984556,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1014,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.294529,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 12459.23544,
    "out:PV (% sold (El))": 24.78089,
    "out:PV (kWh self-consumed)": 37818.35987,
    "out:PV (ratio sold/self-consumed)": 0.329449,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 421118.0151,
    "out:EL kWh savings": -57834.32914,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 300784.56,
    "out:DH kr savings": 383217.3937,
    "out:El kr savings": -98318.35954,
    "out:El kr sold": 15885.52518,
    "out:El kr saved": 64291.21178,
    "out:El kr return": 80176.73697,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 18574.27563,
    "out:% savings (space heating)": 94.014279,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.05,
    "out:Etvv": 0,
    "out:Ef": 35.51,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6860875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.80426,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 139.2,
    "out:Total Energy Use Post PV": 139.2,
    "out:Primary Energy": 102.522982,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.968078,
    "out:CO2 Operational/m2": 46.416562,
    "out:Total CO2/m2": 61.38464,
    "out:Total CO2 (tons)": 183844.9359,
    "out:Klimatpaverkan":0.001* 18953.53122,
    "out:Initial Cost/MSEK": 3.252375,
    "out:Running cost/(Apt SEK y)": 30901.76471,
    "out:Running Cost over RSP/MSEK": 8.2145,
    "out:LCP/MSEK": -1.773975,
    "out:ROI% old": 6.493716,
    "out:Payback discounted": 19,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 17.3,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 6,
    "out:Return/kSEK/y": 189,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 71298.66104,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 64881.78,
    "out:DH kr savings": 64881.78154,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 239011.08,
    "out:% savings (space heating)": 22.976611,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.8,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3252375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.449521,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 136.2,
    "out:Primary Energy": 100.174665,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.968078,
    "out:CO2 Operational/m2": 45.33661,
    "out:Total CO2/m2": 60.304688,
    "out:Total CO2 (tons)": 180610.516,
    "out:Klimatpaverkan":0.001* 15719.11124,
    "out:Initial Cost/MSEK": 3.32725,
    "out:Running cost/(Apt SEK y)": 30207.23529,
    "out:Running Cost over RSP/MSEK": 8.02969,
    "out:LCP/MSEK": -1.66404,
    "out:ROI% old": 7.141075,
    "out:Payback discounted": 17,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 19.9,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 6,
    "out:Return/kSEK/y": 213,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 81345.99201,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 74024.85,
    "out:DH kr savings": 74024.85273,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 228963.7491,
    "out:% savings (space heating)": 26.21445,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.45,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3327250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.80426,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 133.2,
    "out:Primary Energy": 99.897982,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.34471,
    "out:CO2 Operational/m2": 44.256658,
    "out:Total CO2/m2": 59.601368,
    "out:Total CO2 (tons)": 178504.0962,
    "out:Klimatpaverkan":0.001* 13612.69145,
    "out:Initial Cost/MSEK": 3.389,
    "out:Running cost/(Apt SEK y)": 29512.70588,
    "out:Running Cost over RSP/MSEK": 7.84489,
    "out:LCP/MSEK": -1.54099,
    "out:ROI% old": 7.789951,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 7,
    "out:Return/kSEK/y": 236,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 90706.04348,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82542.5,
    "out:DH kr savings": 82542.49957,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 239011.08,
    "out:% savings (space heating)": 22.976611,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.8,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3389000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.449521,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 129.2,
    "out:Primary Energy": 97.549665,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.34471,
    "out:CO2 Operational/m2": 42.816722,
    "out:Total CO2/m2": 58.161432,
    "out:Total CO2 (tons)": 174191.5362,
    "out:Klimatpaverkan":0.001* 9300.131477,
    "out:Initial Cost/MSEK": 3.463875,
    "out:Running cost/(Apt SEK y)": 28586.64706,
    "out:Running Cost over RSP/MSEK": 7.59849,
    "out:LCP/MSEK": -1.369465,
    "out:ROI% old": 8.637766,
    "out:Payback discounted": 14,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 8,
    "out:Return/kSEK/y": 268,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 100753.3745,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 91685.57,
    "out:DH kr savings": 91685.57076,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 228963.7491,
    "out:% savings (space heating)": 26.21445,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.45,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3463875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.80426,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 139.2,
    "out:Total Energy Use Post PV": 134.409285,
    "out:Primary Energy": 93.899695,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.244987,
    "out:CO2 Operational/m2": 27.676297,
    "out:Total CO2/m2": 51.921284,
    "out:Total CO2 (tons)": 155502.5024,
    "out:Klimatpaverkan":0.001* -9388.902282,
    "out:Initial Cost/MSEK": 3.72025,
    "out:Running cost/(Apt SEK y)": 29779.70588,
    "out:Running Cost over RSP/MSEK": 7.92409,
    "out:LCP/MSEK": -1.95144,
    "out:ROI% old": 6.792209,
    "out:Payback discounted": 18,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 21.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 6,
    "out:Return/kSEK/y": 227,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 71298.66104,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 103031.66,
    "out:DH kr savings": 64881.78154,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 239011.08,
    "out:% savings (space heating)": 22.976611,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.8,
    "out:Etvv": 25,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3720250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.449521,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 131.409285,
    "out:Primary Energy": 91.551378,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.244987,
    "out:CO2 Operational/m2": 26.596345,
    "out:Total CO2/m2": 50.841332,
    "out:Total CO2 (tons)": 152268.0824,
    "out:Klimatpaverkan":0.001* -12623.32227,
    "out:Initial Cost/MSEK": 3.795125,
    "out:Running cost/(Apt SEK y)": 29085.17647,
    "out:Running Cost over RSP/MSEK": 7.73929,
    "out:LCP/MSEK": -1.841515,
    "out:ROI% old": 7.353833,
    "out:Payback discounted": 16,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 7,
    "out:Return/kSEK/y": 251,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 81345.99201,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112174.73,
    "out:DH kr savings": 74024.85273,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 228963.7491,
    "out:% savings (space heating)": 26.21445,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.45,
    "out:Etvv": 25,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3795125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.80426,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 128.409285,
    "out:Primary Energy": 91.274695,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.621619,
    "out:CO2 Operational/m2": 25.516393,
    "out:Total CO2/m2": 50.138012,
    "out:Total CO2 (tons)": 150161.6627,
    "out:Klimatpaverkan":0.001* -14729.74205,
    "out:Initial Cost/MSEK": 3.856875,
    "out:Running cost/(Apt SEK y)": 28390.64706,
    "out:Running Cost over RSP/MSEK": 7.55449,
    "out:LCP/MSEK": -1.718465,
    "out:ROI% old": 7.920587,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 27.3,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 7,
    "out:Return/kSEK/y": 274,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 90706.04348,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120692.38,
    "out:DH kr savings": 82542.49957,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 239011.08,
    "out:% savings (space heating)": 22.976611,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.8,
    "out:Etvv": 21.25,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3856875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.449521,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 124.409285,
    "out:Primary Energy": 88.926378,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.621619,
    "out:CO2 Operational/m2": 24.076457,
    "out:Total CO2/m2": 48.698076,
    "out:Total CO2 (tons)": 145849.1027,
    "out:Klimatpaverkan":0.001* -19042.30203,
    "out:Initial Cost/MSEK": 3.93175,
    "out:Running cost/(Apt SEK y)": 27464.58824,
    "out:Running Cost over RSP/MSEK": 7.30809,
    "out:LCP/MSEK": -1.54694,
    "out:ROI% old": 8.665026,
    "out:Payback discounted": 14,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 8,
    "out:Return/kSEK/y": 306,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 100753.3745,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 129835.45,
    "out:DH kr savings": 91685.57076,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 228963.7491,
    "out:% savings (space heating)": 26.21445,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.45,
    "out:Etvv": 21.25,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3931750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.80426,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 139.2,
    "out:Total Energy Use Post PV": 133.404548,
    "out:Primary Energy": 92.091168,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.521896,
    "out:CO2 Operational/m2": -10.020318,
    "out:Total CO2/m2": 23.501578,
    "out:Total CO2 (tons)": 70386.43709,
    "out:Klimatpaverkan":0.001* -94504.96762,
    "out:Initial Cost/MSEK": 4.188,
    "out:Running cost/(Apt SEK y)": 28799.38235,
    "out:Running Cost over RSP/MSEK": 7.67059,
    "out:LCP/MSEK": -2.16569,
    "out:ROI% old": 6.898315,
    "out:Payback discounted": 18,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 6,
    "out:Return/kSEK/y": 260,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 71298.66104,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136362.52,
    "out:DH kr savings": 64881.78154,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 239011.08,
    "out:% savings (space heating)": 22.976611,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.8,
    "out:Etvv": 25,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4188000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.449521,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 130.404548,
    "out:Primary Energy": 89.742851,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.521896,
    "out:CO2 Operational/m2": -11.10027,
    "out:Total CO2/m2": 22.421626,
    "out:Total CO2 (tons)": 67152.01711,
    "out:Klimatpaverkan":0.001* -97739.3876,
    "out:Initial Cost/MSEK": 4.262875,
    "out:Running cost/(Apt SEK y)": 28104.85294,
    "out:Running Cost over RSP/MSEK": 7.48579,
    "out:LCP/MSEK": -2.055765,
    "out:ROI% old": 7.39645,
    "out:Payback discounted": 16,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 25.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 7,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 81345.99201,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145505.59,
    "out:DH kr savings": 74024.85273,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 228963.7491,
    "out:% savings (space heating)": 26.21445,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.45,
    "out:Etvv": 25,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4262875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.80426,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 127.404548,
    "out:Primary Energy": 89.466168,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.898528,
    "out:CO2 Operational/m2": -12.180222,
    "out:Total CO2/m2": 21.718306,
    "out:Total CO2 (tons)": 65045.59732,
    "out:Klimatpaverkan":0.001* -99845.80739,
    "out:Initial Cost/MSEK": 4.324625,
    "out:Running cost/(Apt SEK y)": 27410.32353,
    "out:Running Cost over RSP/MSEK": 7.30099,
    "out:LCP/MSEK": -1.932715,
    "out:ROI% old": 7.901296,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 28.3,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 7,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 90706.04348,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154023.24,
    "out:DH kr savings": 82542.49957,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 239011.08,
    "out:% savings (space heating)": 22.976611,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.8,
    "out:Etvv": 21.25,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4324625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.449521,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 123.404548,
    "out:Primary Energy": 87.117851,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.898528,
    "out:CO2 Operational/m2": -13.620158,
    "out:Total CO2/m2": 20.27837,
    "out:Total CO2 (tons)": 60733.03734,
    "out:Klimatpaverkan":0.001* -104158.3674,
    "out:Initial Cost/MSEK": 4.3995,
    "out:Running cost/(Apt SEK y)": 26484.29412,
    "out:Running Cost over RSP/MSEK": 7.05459,
    "out:LCP/MSEK": -1.76119,
    "out:ROI% old": 8.566915,
    "out:Payback discounted": 14,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 32.5,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 8,
    "out:Return/kSEK/y": 339,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 100753.3745,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163166.31,
    "out:DH kr savings": 91685.57076,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 228963.7491,
    "out:% savings (space heating)": 26.21445,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.45,
    "out:Etvv": 21.25,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4399500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.80426,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 130.2,
    "out:Primary Energy": 99.022982,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.968078,
    "out:CO2 Operational/m2": 43.176706,
    "out:Total CO2/m2": 58.144784,
    "out:Total CO2 (tons)": 174141.676,
    "out:Klimatpaverkan":0.001* 9250.271276,
    "out:Initial Cost/MSEK": 3.342125,
    "out:Running cost/(Apt SEK y)": 28818.17647,
    "out:Running Cost over RSP/MSEK": 7.66009,
    "out:LCP/MSEK": -1.309315,
    "out:ROI% old": 8.689125,
    "out:Payback discounted": 14,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 25.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 8,
    "out:Return/kSEK/y": 260,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 97175.17097,
    "out:EL kWh savings": 3.19e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88429.41,
    "out:DH kr savings": 88429.40558,
    "out:El kr savings": 5.43e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 239011.08,
    "out:% savings (space heating)": 22.976611,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.8,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3342125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.449521,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 127.2,
    "out:Total Energy Use Post PV": 127.2,
    "out:Primary Energy": 96.674665,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.968078,
    "out:CO2 Operational/m2": 42.096754,
    "out:Total CO2/m2": 57.064832,
    "out:Total CO2 (tons)": 170907.256,
    "out:Klimatpaverkan":0.001* 6015.851293,
    "out:Initial Cost/MSEK": 3.417,
    "out:Running cost/(Apt SEK y)": 28123.64706,
    "out:Running Cost over RSP/MSEK": 7.47529,
    "out:LCP/MSEK": -1.19939,
    "out:ROI% old": 9.271332,
    "out:Payback discounted": 13,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 28.3,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 8,
    "out:Return/kSEK/y": 283,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 107222.5019,
    "out:EL kWh savings": -1.06e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 97572.48,
    "out:DH kr savings": 97572.47677,
    "out:El kr savings": -1.81e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 228963.7491,
    "out:% savings (space heating)": 26.21445,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.45,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3417000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.80426,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 125.2,
    "out:Total Energy Use Post PV": 125.2,
    "out:Primary Energy": 96.922982,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.34471,
    "out:CO2 Operational/m2": 41.376786,
    "out:Total CO2/m2": 56.721496,
    "out:Total CO2 (tons)": 169878.9762,
    "out:Klimatpaverkan":0.001* 4987.5715,
    "out:Initial Cost/MSEK": 3.47875,
    "out:Running cost/(Apt SEK y)": 27660.61765,
    "out:Running Cost over RSP/MSEK": 7.35209,
    "out:LCP/MSEK": -1.13794,
    "out:ROI% old": 9.612689,
    "out:Payback discounted": 13,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 30.4,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 9,
    "out:Return/kSEK/y": 299,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 112701.0769,
    "out:EL kWh savings": -1.06e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102557.98,
    "out:DH kr savings": 102557.98,
    "out:El kr savings": -1.81e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 239011.08,
    "out:% savings (space heating)": 22.976611,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.8,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3478750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.449521,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 122.2,
    "out:Total Energy Use Post PV": 122.2,
    "out:Primary Energy": 94.574665,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.34471,
    "out:CO2 Operational/m2": 40.296834,
    "out:Total CO2/m2": 55.641544,
    "out:Total CO2 (tons)": 166644.5562,
    "out:Klimatpaverkan":0.001* 1753.151517,
    "out:Initial Cost/MSEK": 3.553625,
    "out:Running cost/(Apt SEK y)": 26966.08824,
    "out:Running Cost over RSP/MSEK": 7.16729,
    "out:LCP/MSEK": -1.028015,
    "out:ROI% old": 10.153053,
    "out:Payback discounted": 12,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 9,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 122748.4079,
    "out:EL kWh savings": -1.06e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111701.05,
    "out:DH kr savings": 111701.0512,
    "out:El kr savings": -1.81e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 228963.7491,
    "out:% savings (space heating)": 26.21445,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.45,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3553625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.80426,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 125.409285,
    "out:Primary Energy": 90.399695,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.244987,
    "out:CO2 Operational/m2": 24.436441,
    "out:Total CO2/m2": 48.681428,
    "out:Total CO2 (tons)": 145799.2425,
    "out:Klimatpaverkan":0.001* -19092.16223,
    "out:Initial Cost/MSEK": 3.81,
    "out:Running cost/(Apt SEK y)": 27696.11765,
    "out:Running Cost over RSP/MSEK": 7.36969,
    "out:LCP/MSEK": -1.48679,
    "out:ROI% old": 8.710949,
    "out:Payback discounted": 14,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 30.4,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 8,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 97175.17097,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126579.28,
    "out:DH kr savings": 88429.40558,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 239011.08,
    "out:% savings (space heating)": 22.976611,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.8,
    "out:Etvv": 20,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3810000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.449521,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 127.2,
    "out:Total Energy Use Post PV": 122.409285,
    "out:Primary Energy": 88.051378,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.244987,
    "out:CO2 Operational/m2": 23.356489,
    "out:Total CO2/m2": 47.601476,
    "out:Total CO2 (tons)": 142564.8225,
    "out:Klimatpaverkan":0.001* -22326.58221,
    "out:Initial Cost/MSEK": 3.884875,
    "out:Running cost/(Apt SEK y)": 27001.58824,
    "out:Running Cost over RSP/MSEK": 7.18489,
    "out:LCP/MSEK": -1.376865,
    "out:ROI% old": 9.222617,
    "out:Payback discounted": 13,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 8,
    "out:Return/kSEK/y": 322,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 107222.5019,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135722.36,
    "out:DH kr savings": 97572.47677,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 228963.7491,
    "out:% savings (space heating)": 26.21445,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.45,
    "out:Etvv": 20,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3884875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.80426,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 125.2,
    "out:Total Energy Use Post PV": 120.409285,
    "out:Primary Energy": 88.299695,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.621619,
    "out:CO2 Operational/m2": 22.636521,
    "out:Total CO2/m2": 47.25814,
    "out:Total CO2 (tons)": 141536.5427,
    "out:Klimatpaverkan":0.001* -23354.86201,
    "out:Initial Cost/MSEK": 3.9465,
    "out:Running cost/(Apt SEK y)": 26538.55882,
    "out:Running Cost over RSP/MSEK": 7.06169,
    "out:LCP/MSEK": -1.31529,
    "out:ROI% old": 9.52457,
    "out:Payback discounted": 13,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 35.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 9,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 112701.0769,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140707.86,
    "out:DH kr savings": 102557.98,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 239011.08,
    "out:% savings (space heating)": 22.976611,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.8,
    "out:Etvv": 17,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3946500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.449521,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.409285,
    "out:Total Energy Use Pre PV": 122.2,
    "out:Total Energy Use Post PV": 117.409285,
    "out:Primary Energy": 85.951378,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.621619,
    "out:CO2 Operational/m2": 21.556569,
    "out:Total CO2/m2": 46.178188,
    "out:Total CO2 (tons)": 138302.1227,
    "out:Klimatpaverkan":0.001* -26589.28199,
    "out:Initial Cost/MSEK": 4.021375,
    "out:Running cost/(Apt SEK y)": 25844.02941,
    "out:Running Cost over RSP/MSEK": 6.87689,
    "out:LCP/MSEK": -1.205365,
    "out:ROI% old": 10.003721,
    "out:Payback discounted": 12,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 806.542549,
    "out:Return %": 9,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 10790.76641,
    "out:PV (% sold (El))": 42.924752,
    "out:PV (kWh self-consumed)": 14348.03116,
    "out:PV (ratio sold/self-consumed)": 0.752073,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122748.4079,
    "out:EL kWh savings": 14348.03059,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149850.93,
    "out:DH kr savings": 111701.0512,
    "out:El kr savings": 24391.652,
    "out:El kr sold": 13758.22717,
    "out:El kr saved": 24391.65298,
    "out:El kr return": 38149.88014,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 228963.7491,
    "out:% savings (space heating)": 26.21445,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.45,
    "out:Etvv": 17,
    "out:Ef": 11.41,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4021375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.80426,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 124.404548,
    "out:Primary Energy": 88.591168,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.521896,
    "out:CO2 Operational/m2": -13.260174,
    "out:Total CO2/m2": 20.261722,
    "out:Total CO2 (tons)": 60683.17714,
    "out:Klimatpaverkan":0.001* -104208.2276,
    "out:Initial Cost/MSEK": 4.27775,
    "out:Running cost/(Apt SEK y)": 26715.79412,
    "out:Running Cost over RSP/MSEK": 7.11619,
    "out:LCP/MSEK": -1.70104,
    "out:ROI% old": 8.605024,
    "out:Payback discounted": 14,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 8,
    "out:Return/kSEK/y": 331,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 97175.17097,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159910.14,
    "out:DH kr savings": 88429.40558,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 239011.08,
    "out:% savings (space heating)": 22.976611,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.8,
    "out:Etvv": 20,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4277750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.449521,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 127.2,
    "out:Total Energy Use Post PV": 121.404548,
    "out:Primary Energy": 86.242851,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.521896,
    "out:CO2 Operational/m2": -14.340126,
    "out:Total CO2/m2": 19.18177,
    "out:Total CO2 (tons)": 57448.75716,
    "out:Klimatpaverkan":0.001* -107442.6475,
    "out:Initial Cost/MSEK": 4.352625,
    "out:Running cost/(Apt SEK y)": 26021.26471,
    "out:Running Cost over RSP/MSEK": 6.93139,
    "out:LCP/MSEK": -1.591115,
    "out:ROI% old": 9.063529,
    "out:Payback discounted": 13,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 8,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 107222.5019,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169053.21,
    "out:DH kr savings": 97572.47677,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 228963.7491,
    "out:% savings (space heating)": 26.21445,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.45,
    "out:Etvv": 20,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4352625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.80426,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 125.2,
    "out:Total Energy Use Post PV": 119.404548,
    "out:Primary Energy": 86.491168,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.898528,
    "out:CO2 Operational/m2": -15.060094,
    "out:Total CO2/m2": 18.838434,
    "out:Total CO2 (tons)": 56420.47737,
    "out:Klimatpaverkan":0.001* -108470.9273,
    "out:Initial Cost/MSEK": 4.414375,
    "out:Running cost/(Apt SEK y)": 25558.23529,
    "out:Running Cost over RSP/MSEK": 6.80818,
    "out:LCP/MSEK": -1.529655,
    "out:ROI% old": 9.335475,
    "out:Payback discounted": 13,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 37,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 8,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 112701.0769,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 174038.72,
    "out:DH kr savings": 102557.98,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 239011.08,
    "out:% savings (space heating)": 22.976611,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.8,
    "out:Etvv": 17,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4414375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.449521,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.404548,
    "out:Total Energy Use Pre PV": 122.2,
    "out:Total Energy Use Post PV": 116.404548,
    "out:Primary Energy": 84.142851,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.898528,
    "out:CO2 Operational/m2": -16.140046,
    "out:Total CO2/m2": 17.758482,
    "out:Total CO2 (tons)": 53186.05738,
    "out:Klimatpaverkan":0.001* -111705.3473,
    "out:Initial Cost/MSEK": 4.48925,
    "out:Running cost/(Apt SEK y)": 24863.70588,
    "out:Running Cost over RSP/MSEK": 6.62338,
    "out:LCP/MSEK": -1.41973,
    "out:ROI% old": 9.767842,
    "out:Payback discounted": 12,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 40.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 734.444679,
    "out:Return %": 9,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 32920.41109,
    "out:PV (% sold (El))": 65.477298,
    "out:PV (kWh self-consumed)": 17357.18422,
    "out:PV (ratio sold/self-consumed)": 1.896645,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122748.4079,
    "out:EL kWh savings": 17357.18417,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 183181.79,
    "out:DH kr savings": 111701.0512,
    "out:El kr savings": 29507.21309,
    "out:El kr sold": 41973.52414,
    "out:El kr saved": 29507.21318,
    "out:El kr return": 71480.73732,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 228963.7491,
    "out:% savings (space heating)": 26.21445,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.45,
    "out:Etvv": 17,
    "out:Ef": 10.4,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4489250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.985858,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.535379,
    "out:Electricity (inc PV)": 39.228689,
    "out:Total Energy Use Pre PV": 88.535379,
    "out:Total Energy Use Post PV": 86.228689,
    "out:Primary Energy": 96.090196,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.861553,
    "out:CO2 Operational/m2": 22.40225,
    "out:Total CO2/m2": 41.263803,
    "out:Total CO2 (tons)": 123583.7046,
    "out:Klimatpaverkan":0.001* -41307.70007,
    "out:Initial Cost/MSEK": 4.902625,
    "out:Running cost/(Apt SEK y)": 17100.85294,
    "out:Running Cost over RSP/MSEK": 4.53009,
    "out:LCP/MSEK": 0.260185,
    "out:ROI% old": 15.043865,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 89.5,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2907.47653,
    "out:Return %": 13,
    "out:Return/kSEK/y": 658,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.035879,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 298971.2235,
    "out:EL kWh savings": -68970.15041,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154814.56,
    "out:DH kr savings": 272063.8133,
    "out:El kr savings": -117249.2557,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 140721.0673,
    "out:% savings (space heating)": 54.651418,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 36.4,
    "out:Etvv": 0,
    "out:Ef": 39.23,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4902625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.787359,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.488034,
    "out:Electricity (inc PV)": 39.14323,
    "out:Total Energy Use Pre PV": 85.488034,
    "out:Total Energy Use Post PV": 83.14323,
    "out:Primary Energy": 93.785463,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.861553,
    "out:CO2 Operational/m2": 21.316048,
    "out:Total CO2/m2": 40.177601,
    "out:Total CO2 (tons)": 120330.5661,
    "out:Klimatpaverkan":0.001* -44560.8386,
    "out:Initial Cost/MSEK": 4.9775,
    "out:Running cost/(Apt SEK y)": 16399.23529,
    "out:Running Cost over RSP/MSEK": 4.34343,
    "out:LCP/MSEK": 0.37197,
    "out:ROI% old": 15.35329,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 96.4,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2904.16238,
    "out:Return %": 14,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 22.988534,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 308550.6206,
    "out:EL kWh savings": -68714.20358,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163966.92,
    "out:DH kr savings": 280781.0647,
    "out:El kr savings": -116814.1461,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 131141.6701,
    "out:% savings (space heating)": 57.738462,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 33.33,
    "out:Etvv": 0,
    "out:Ef": 39.14,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4977500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.101758,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.748743,
    "out:Electricity (inc PV)": 38.715518,
    "out:Total Energy Use Pre PV": 83.748743,
    "out:Total Energy Use Post PV": 81.715518,
    "out:Primary Energy": 93.726912,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.238185,
    "out:CO2 Operational/m2": 20.858472,
    "out:Total CO2/m2": 40.096657,
    "out:Total CO2 (tons)": 120088.1416,
    "out:Klimatpaverkan":0.001* -44803.26316,
    "out:Initial Cost/MSEK": 5.03925,
    "out:Running cost/(Apt SEK y)": 16057.02941,
    "out:Running Cost over RSP/MSEK": 4.25273,
    "out:LCP/MSEK": 0.40092,
    "out:ROI% old": 15.422278,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 98.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2852.41201,
    "out:Return %": 14,
    "out:Return/kSEK/y": 694,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.249243,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 310603.9726,
    "out:EL kWh savings": -67433.2205,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168013.14,
    "out:DH kr savings": 282649.615,
    "out:El kr savings": -114636.4748,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 129088.3182,
    "out:% savings (space heating)": 58.400172,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 34.34,
    "out:Etvv": 0,
    "out:Ef": 38.72,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5039250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.941835,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.689708,
    "out:Electricity (inc PV)": 38.620076,
    "out:Total Energy Use Pre PV": 80.689708,
    "out:Total Energy Use Post PV": 78.620076,
    "out:Primary Energy": 91.42727,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.238185,
    "out:CO2 Operational/m2": 19.770727,
    "out:Total CO2/m2": 39.008912,
    "out:Total CO2 (tons)": 116830.3818,
    "out:Klimatpaverkan":0.001* -48061.02292,
    "out:Initial Cost/MSEK": 5.114125,
    "out:Running cost/(Apt SEK y)": 15353.64706,
    "out:Running Cost over RSP/MSEK": 4.0656,
    "out:LCP/MSEK": 0.513175,
    "out:ROI% old": 15.719209,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2848.27956,
    "out:Return %": 14,
    "out:Return/kSEK/y": 718,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.190208,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 320067.8359,
    "out:EL kWh savings": -67147.37491,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177111.19,
    "out:DH kr savings": 291261.7306,
    "out:El kr savings": -114150.5373,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 119624.4549,
    "out:% savings (space heating)": 61.449984,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 31.3,
    "out:Etvv": 0,
    "out:Ef": 38.62,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5114125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.985858,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.535379,
    "out:Electricity (inc PV)": 31.509839,
    "out:Total Energy Use Pre PV": 88.535379,
    "out:Total Energy Use Post PV": 78.509839,
    "out:Primary Energy": 82.196266,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.138462,
    "out:CO2 Operational/m2": 18.883076,
    "out:Total CO2/m2": 47.021538,
    "out:Total CO2 (tons)": 140827.9277,
    "out:Klimatpaverkan":0.001* -24063.47705,
    "out:Initial Cost/MSEK": 5.3705,
    "out:Running cost/(Apt SEK y)": 15863.67647,
    "out:Running Cost over RSP/MSEK": 4.21007,
    "out:LCP/MSEK": 0.11233,
    "out:ROI% old": 14.584516,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 106.3,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2382.88162,
    "out:Return %": 13,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.035879,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1581.011307,
    "out:PV (% sold (El))": 6.289129,
    "out:PV (kWh self-consumed)": 23557.78626,
    "out:PV (ratio sold/self-consumed)": 0.067112,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298971.2235,
    "out:EL kWh savings": -45852.45381,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196130.43,
    "out:DH kr savings": 272063.8133,
    "out:El kr savings": -77949.17147,
    "out:El kr sold": 2015.789417,
    "out:El kr saved": 40048.23664,
    "out:El kr return": 42064.02606,
    "out:% solar/total": 49,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 140721.0673,
    "out:% savings (space heating)": 54.651418,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 36.4,
    "out:Etvv": 0,
    "out:Ef": 31.51,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5370500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.787359,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.488034,
    "out:Electricity (inc PV)": 31.430263,
    "out:Total Energy Use Pre PV": 85.488034,
    "out:Total Energy Use Post PV": 75.430263,
    "out:Primary Energy": 79.902122,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.138462,
    "out:CO2 Operational/m2": 17.783386,
    "out:Total CO2/m2": 45.921848,
    "out:Total CO2 (tons)": 137534.393,
    "out:Klimatpaverkan":0.001* -27357.01168,
    "out:Initial Cost/MSEK": 5.445375,
    "out:Running cost/(Apt SEK y)": 15162.17647,
    "out:Running Cost over RSP/MSEK": 4.02343,
    "out:LCP/MSEK": 0.224095,
    "out:ROI% old": 14.873618,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 117.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2379.727487,
    "out:Return %": 13,
    "out:Return/kSEK/y": 724,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 22.988534,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1589.379951,
    "out:PV (% sold (El))": 6.322418,
    "out:PV (kWh self-consumed)": 23549.41762,
    "out:PV (ratio sold/self-consumed)": 0.067491,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308550.6206,
    "out:EL kWh savings": -45614.12636,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205263.51,
    "out:DH kr savings": 280781.0647,
    "out:El kr savings": -77544.01481,
    "out:El kr sold": 2026.459437,
    "out:El kr saved": 40034.00995,
    "out:El kr return": 42060.46938,
    "out:% solar/total": 49,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 131141.6701,
    "out:% savings (space heating)": 57.738462,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 33.33,
    "out:Etvv": 0,
    "out:Ef": 31.43,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5445375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.101758,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.748743,
    "out:Electricity (inc PV)": 31.032433,
    "out:Total Energy Use Pre PV": 83.748743,
    "out:Total Energy Use Post PV": 74.032433,
    "out:Primary Energy": 79.897359,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.515094,
    "out:CO2 Operational/m2": 17.109633,
    "out:Total CO2/m2": 45.624727,
    "out:Total CO2 (tons)": 136644.5256,
    "out:Klimatpaverkan":0.001* -28246.8791,
    "out:Initial Cost/MSEK": 5.507125,
    "out:Running cost/(Apt SEK y)": 14821.61765,
    "out:Running Cost over RSP/MSEK": 3.93315,
    "out:LCP/MSEK": 0.252625,
    "out:ROI% old": 14.941034,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 120.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2330.555268,
    "out:Return %": 13,
    "out:Return/kSEK/y": 736,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.249243,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1723.220824,
    "out:PV (% sold (El))": 6.854826,
    "out:PV (kWh self-consumed)": 23415.57674,
    "out:PV (ratio sold/self-consumed)": 0.073593,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 310603.9726,
    "out:EL kWh savings": -44422.63887,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 209328.24,
    "out:DH kr savings": 282649.615,
    "out:El kr savings": -75518.48607,
    "out:El kr sold": 2197.106551,
    "out:El kr saved": 39806.48046,
    "out:El kr return": 42003.58701,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 129088.3182,
    "out:% savings (space heating)": 58.400172,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 34.34,
    "out:Etvv": 0,
    "out:Ef": 31.03,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5507125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.941835,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.689708,
    "out:Electricity (inc PV)": 30.943732,
    "out:Total Energy Use Pre PV": 80.689708,
    "out:Total Energy Use Post PV": 70.943732,
    "out:Primary Energy": 77.609851,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.515094,
    "out:CO2 Operational/m2": 16.004146,
    "out:Total CO2/m2": 44.51924,
    "out:Total CO2 (tons)": 133333.6292,
    "out:Klimatpaverkan":0.001* -31557.77555,
    "out:Initial Cost/MSEK": 5.582,
    "out:Running cost/(Apt SEK y)": 14118.38235,
    "out:Running Cost over RSP/MSEK": 3.74606,
    "out:LCP/MSEK": 0.36484,
    "out:ROI% old": 15.21943,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 129.6,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2326.635235,
    "out:Return %": 14,
    "out:Return/kSEK/y": 760,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.190208,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1734.26527,
    "out:PV (% sold (El))": 6.89876,
    "out:PV (kWh self-consumed)": 23404.5323,
    "out:PV (ratio sold/self-consumed)": 0.0741,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 320067.8359,
    "out:EL kWh savings": -44156.98235,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 218406.05,
    "out:DH kr savings": 291261.7306,
    "out:El kr savings": -75066.86999,
    "out:El kr sold": 2211.18822,
    "out:El kr saved": 39787.7049,
    "out:El kr return": 41998.89312,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 119624.4549,
    "out:% savings (space heating)": 61.449984,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 31.3,
    "out:Etvv": 0,
    "out:Ef": 30.94,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5582000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.985858,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.535379,
    "out:Electricity (inc PV)": 28.398694,
    "out:Total Energy Use Pre PV": 88.535379,
    "out:Total Energy Use Post PV": 75.398694,
    "out:Primary Energy": 76.596205,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.415371,
    "out:CO2 Operational/m2": -6.903077,
    "out:Total CO2/m2": 30.512294,
    "out:Total CO2 (tons)": 91383.29614,
    "out:Klimatpaverkan":0.001* -73508.10857,
    "out:Initial Cost/MSEK": 5.83825,
    "out:Running cost/(Apt SEK y)": 14795.52941,
    "out:Running Cost over RSP/MSEK": 3.93337,
    "out:LCP/MSEK": -0.07872,
    "out:ROI% old": 14.093093,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 117.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2143.129999,
    "out:Return %": 13,
    "out:Return/kSEK/y": 737,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.035879,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 16682.54897,
    "out:PV (% sold (El))": 33.180881,
    "out:PV (kWh self-consumed)": 33595.04634,
    "out:PV (ratio sold/self-consumed)": 0.496578,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298971.2235,
    "out:EL kWh savings": -36534.67898,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 231225.11,
    "out:DH kr savings": 272063.8133,
    "out:El kr savings": -62108.95427,
    "out:El kr sold": 21270.24994,
    "out:El kr saved": 57111.57878,
    "out:El kr return": 78381.82872,
    "out:% solar/total": 69,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 140721.0673,
    "out:% savings (space heating)": 54.651418,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 36.4,
    "out:Etvv": 0,
    "out:Ef": 28.4,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5838250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.787359,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.488034,
    "out:Electricity (inc PV)": 28.327881,
    "out:Total Energy Use Pre PV": 85.488034,
    "out:Total Energy Use Post PV": 72.327881,
    "out:Primary Energy": 74.317835,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.415371,
    "out:CO2 Operational/m2": -8.028385,
    "out:Total CO2/m2": 29.386986,
    "out:Total CO2 (tons)": 88013.03646,
    "out:Klimatpaverkan":0.001* -76878.36825,
    "out:Initial Cost/MSEK": 5.913125,
    "out:Running cost/(Apt SEK y)": 14094.17647,
    "out:Running Cost over RSP/MSEK": 3.74678,
    "out:LCP/MSEK": 0.032995,
    "out:ROI% old": 14.365428,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 126.4,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2140.338166,
    "out:Return %": 13,
    "out:Return/kSEK/y": 760,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 22.988534,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 16705.96733,
    "out:PV (% sold (El))": 33.227459,
    "out:PV (kWh self-consumed)": 33571.62798,
    "out:PV (ratio sold/self-consumed)": 0.497622,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308550.6206,
    "out:EL kWh savings": -36322.59643,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 240332.76,
    "out:DH kr savings": 280781.0647,
    "out:El kr savings": -61748.41392,
    "out:El kr sold": 21300.10834,
    "out:El kr saved": 57071.76757,
    "out:El kr return": 78371.87591,
    "out:% solar/total": 69,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 131141.6701,
    "out:% savings (space heating)": 57.738462,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 33.33,
    "out:Etvv": 0,
    "out:Ef": 28.33,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5913125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.101758,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.748743,
    "out:Electricity (inc PV)": 27.973741,
    "out:Total Energy Use Pre PV": 83.748743,
    "out:Total Energy Use Post PV": 70.973741,
    "out:Primary Energy": 74.391714,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.792003,
    "out:CO2 Operational/m2": -9.099979,
    "out:Total CO2/m2": 28.692024,
    "out:Total CO2 (tons)": 85931.6486,
    "out:Klimatpaverkan":0.001* -78959.75611,
    "out:Initial Cost/MSEK": 5.974875,
    "out:Running cost/(Apt SEK y)": 13756.55882,
    "out:Running Cost over RSP/MSEK": 3.65728,
    "out:LCP/MSEK": 0.060745,
    "out:ROI% old": 14.430953,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 129.6,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2096.80095,
    "out:Return %": 13,
    "out:Return/kSEK/y": 772,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.249243,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 17073.0715,
    "out:PV (% sold (El))": 33.957614,
    "out:PV (kWh self-consumed)": 33204.52381,
    "out:PV (ratio sold/self-consumed)": 0.514179,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 310603.9726,
    "out:EL kWh savings": -35261.95902,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 244472.45,
    "out:DH kr savings": 282649.615,
    "out:El kr savings": -59945.33033,
    "out:El kr sold": 21768.16616,
    "out:El kr saved": 56447.69048,
    "out:El kr return": 78215.85664,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 129088.3182,
    "out:% savings (space heating)": 58.400172,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 34.34,
    "out:Etvv": 0,
    "out:Ef": 27.97,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5974875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.941835,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.689708,
    "out:Electricity (inc PV)": 27.894777,
    "out:Total Energy Use Pre PV": 80.689708,
    "out:Total Energy Use Post PV": 67.894777,
    "out:Primary Energy": 72.121732,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.792003,
    "out:CO2 Operational/m2": -10.237006,
    "out:Total CO2/m2": 27.554997,
    "out:Total CO2 (tons)": 82526.29091,
    "out:Klimatpaverkan":0.001* -82365.1138,
    "out:Initial Cost/MSEK": 6.04975,
    "out:Running cost/(Apt SEK y)": 13053.55882,
    "out:Running Cost over RSP/MSEK": 3.47025,
    "out:LCP/MSEK": 0.1729,
    "out:ROI% old": 14.693996,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2093.328792,
    "out:Return %": 13,
    "out:Return/kSEK/y": 796,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.190208,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 17102.4805,
    "out:PV (% sold (El))": 34.016107,
    "out:PV (kWh self-consumed)": 33175.11481,
    "out:PV (ratio sold/self-consumed)": 0.515521,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 320067.8359,
    "out:EL kWh savings": -35025.46449,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 253524.1,
    "out:DH kr savings": 291261.7306,
    "out:El kr savings": -59543.28963,
    "out:El kr sold": 21805.66264,
    "out:El kr saved": 56397.69518,
    "out:El kr return": 78203.35782,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 119624.4549,
    "out:% savings (space heating)": 61.449984,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 31.3,
    "out:Etvv": 0,
    "out:Ef": 27.89,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6049750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.834591,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.478188,
    "out:Electricity (inc PV)": 38.541583,
    "out:Total Energy Use Pre PV": 82.478188,
    "out:Total Energy Use Post PV": 80.541583,
    "out:Primary Energy": 92.940619,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.861553,
    "out:CO2 Operational/m2": 20.462773,
    "out:Total CO2/m2": 39.324326,
    "out:Total CO2 (tons)": 117775.0361,
    "out:Klimatpaverkan":0.001* -47116.36858,
    "out:Initial Cost/MSEK": 4.992375,
    "out:Running cost/(Apt SEK y)": 15785,
    "out:Running Cost over RSP/MSEK": 4.18048,
    "out:LCP/MSEK": 0.520045,
    "out:ROI% old": 15.773827,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 101.2,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2833.47316,
    "out:Return %": 14,
    "out:Return/kSEK/y": 703,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 21.978688,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 314399.0952,
    "out:EL kWh savings": -66912.29101,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 172352.28,
    "out:DH kr savings": 286103.1766,
    "out:El kr savings": -113750.8947,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 125293.1955,
    "out:% savings (space heating)": 59.623183,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 33.67,
    "out:Etvv": 0,
    "out:Ef": 38.54,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4992375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.691445,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.414069,
    "out:Electricity (inc PV)": 38.441981,
    "out:Total Energy Use Pre PV": 79.414069,
    "out:Total Energy Use Post PV": 77.441981,
    "out:Primary Energy": 90.6431,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.861553,
    "out:CO2 Operational/m2": 19.374356,
    "out:Total CO2/m2": 38.235909,
    "out:Total CO2 (tons)": 114515.2638,
    "out:Klimatpaverkan":0.001* -50376.14095,
    "out:Initial Cost/MSEK": 5.06725,
    "out:Running cost/(Apt SEK y)": 15080.85294,
    "out:Running Cost over RSP/MSEK": 3.99315,
    "out:LCP/MSEK": 0.6325,
    "out:ROI% old": 16.068874,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 111.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2828.98483,
    "out:Return %": 14,
    "out:Return/kSEK/y": 727,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 21.914569,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 323812.7119,
    "out:EL kWh savings": -66613.98637,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181425.79,
    "out:DH kr savings": 294669.5679,
    "out:El kr savings": -113243.7768,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 115879.5788,
    "out:% savings (space heating)": 62.656803,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 30.64,
    "out:Etvv": 0,
    "out:Ef": 38.44,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5067250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.853664,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.81059,
    "out:Electricity (inc PV)": 38.116885,
    "out:Total Energy Use Pre PV": 78.81059,
    "out:Total Energy Use Post PV": 77.116885,
    "out:Primary Energy": 91.057215,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.238185,
    "out:CO2 Operational/m2": 19.294692,
    "out:Total CO2/m2": 38.532877,
    "out:Total CO2 (tons)": 115404.673,
    "out:Klimatpaverkan":0.001* -49486.73176,
    "out:Initial Cost/MSEK": 5.129,
    "out:Running cost/(Apt SEK y)": 14990.5,
    "out:Running Cost over RSP/MSEK": 3.9694,
    "out:LCP/MSEK": 0.5945,
    "out:ROI% old": 15.941565,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 111.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2786.7413,
    "out:Return %": 14,
    "out:Return/kSEK/y": 730,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.31109,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 323326.8715,
    "out:EL kWh savings": -65640.33476,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182638.88,
    "out:DH kr savings": 294227.453,
    "out:El kr savings": -111588.5691,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 116365.4192,
    "out:% savings (space heating)": 62.500236,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 32.07,
    "out:Etvv": 0,
    "out:Ef": 38.12,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5129000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.743721,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.736409,
    "out:Electricity (inc PV)": 38.006752,
    "out:Total Energy Use Pre PV": 75.736409,
    "out:Total Energy Use Post PV": 74.006752,
    "out:Primary Energy": 88.765066,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.238185,
    "out:CO2 Operational/m2": 18.204948,
    "out:Total CO2/m2": 37.443133,
    "out:Total CO2 (tons)": 112140.9263,
    "out:Klimatpaverkan":0.001* -52750.47845,
    "out:Initial Cost/MSEK": 5.203875,
    "out:Running cost/(Apt SEK y)": 14284.85294,
    "out:Running Cost over RSP/MSEK": 3.78168,
    "out:LCP/MSEK": 0.707345,
    "out:ROI% old": 16.227523,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 120.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 2781.54863,
    "out:Return %": 14,
    "out:Return/kSEK/y": 754,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.236909,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 332641.0463,
    "out:EL kWh savings": -65310.49012,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191675.52,
    "out:DH kr savings": 302703.3522,
    "out:El kr savings": -111027.8332,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 107051.2444,
    "out:% savings (space heating)": 65.50181,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 29.08,
    "out:Etvv": 0,
    "out:Ef": 38.01,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5203875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.834591,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.478188,
    "out:Electricity (inc PV)": 30.870807,
    "out:Total Energy Use Pre PV": 82.478188,
    "out:Total Energy Use Post PV": 72.870807,
    "out:Primary Energy": 79.133223,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.138462,
    "out:CO2 Operational/m2": 16.631986,
    "out:Total CO2/m2": 44.770448,
    "out:Total CO2 (tons)": 134085.9887,
    "out:Klimatpaverkan":0.001* -30805.41603,
    "out:Initial Cost/MSEK": 5.46025,
    "out:Running cost/(Apt SEK y)": 14550.23529,
    "out:Running Cost over RSP/MSEK": 3.86106,
    "out:LCP/MSEK": 0.37159,
    "out:ROI% old": 15.257909,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 123.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2312.598088,
    "out:Return %": 14,
    "out:Return/kSEK/y": 745,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 21.978688,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1774.297142,
    "out:PV (% sold (El))": 7.058003,
    "out:PV (kWh self-consumed)": 23364.50042,
    "out:PV (ratio sold/self-consumed)": 0.07594,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 314399.0952,
    "out:EL kWh savings": -43938.57442,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 213669.83,
    "out:DH kr savings": 286103.1766,
    "out:El kr savings": -74695.57652,
    "out:El kr sold": 2262.228857,
    "out:El kr saved": 39719.65072,
    "out:El kr return": 41981.87958,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 125293.1955,
    "out:% savings (space heating)": 59.623183,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 33.67,
    "out:Etvv": 0,
    "out:Ef": 30.87,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5460250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.691445,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.414069,
    "out:Electricity (inc PV)": 30.7783,
    "out:Total Energy Use Pre PV": 79.414069,
    "out:Total Energy Use Post PV": 69.7783,
    "out:Primary Energy": 76.848474,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.138462,
    "out:CO2 Operational/m2": 15.52391,
    "out:Total CO2/m2": 43.662372,
    "out:Total CO2 (tons)": 130767.3383,
    "out:Klimatpaverkan":0.001* -34124.06645,
    "out:Initial Cost/MSEK": 5.535125,
    "out:Running cost/(Apt SEK y)": 13846.26471,
    "out:Running Cost over RSP/MSEK": 3.67378,
    "out:LCP/MSEK": 0.483995,
    "out:ROI% old": 15.534867,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 132.9,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2308.34545,
    "out:Return %": 14,
    "out:Return/kSEK/y": 769,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 21.914569,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1786.502532,
    "out:PV (% sold (El))": 7.106555,
    "out:PV (kWh self-consumed)": 23352.29503,
    "out:PV (ratio sold/self-consumed)": 0.076502,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 323812.7119,
    "out:EL kWh savings": -43661.51906,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222722.78,
    "out:DH kr savings": 294669.5679,
    "out:El kr savings": -74224.58241,
    "out:El kr sold": 2277.790729,
    "out:El kr saved": 39698.90156,
    "out:El kr return": 41976.69229,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 115879.5788,
    "out:% savings (space heating)": 62.656803,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 30.64,
    "out:Etvv": 0,
    "out:Ef": 30.78,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5535125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.853664,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.81059,
    "out:Electricity (inc PV)": 30.47671,
    "out:Total Energy Use Pre PV": 78.81059,
    "out:Total Energy Use Post PV": 69.47671,
    "out:Primary Energy": 77.3049,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.515094,
    "out:CO2 Operational/m2": 15.255134,
    "out:Total CO2/m2": 43.770228,
    "out:Total CO2 (tons)": 131090.3634,
    "out:Klimatpaverkan":0.001* -33801.04135,
    "out:Initial Cost/MSEK": 5.59675,
    "out:Running cost/(Apt SEK y)": 13757.35294,
    "out:Running Cost over RSP/MSEK": 3.65039,
    "out:LCP/MSEK": 0.44576,
    "out:ROI% old": 15.423517,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 136.2,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2268.37625,
    "out:Return %": 14,
    "out:Return/kSEK/y": 772,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.31109,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1903.679376,
    "out:PV (% sold (El))": 7.572675,
    "out:PV (kWh self-consumed)": 23235.11819,
    "out:PV (ratio sold/self-consumed)": 0.081931,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 323326.8715,
    "out:EL kWh savings": -42758.26714,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 223965.59,
    "out:DH kr savings": 294227.453,
    "out:El kr savings": -72689.05413,
    "out:El kr sold": 2427.191204,
    "out:El kr saved": 39499.70092,
    "out:El kr return": 41926.89213,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 116365.4192,
    "out:% savings (space heating)": 62.500236,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 32.07,
    "out:Etvv": 0,
    "out:Ef": 30.48,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5596750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.743721,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.736409,
    "out:Electricity (inc PV)": 30.374624,
    "out:Total Energy Use Pre PV": 75.736409,
    "out:Total Energy Use Post PV": 66.374624,
    "out:Primary Energy": 75.027235,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.515094,
    "out:CO2 Operational/m2": 14.141614,
    "out:Total CO2/m2": 42.656708,
    "out:Total CO2 (tons)": 127755.4083,
    "out:Klimatpaverkan":0.001* -37135.99636,
    "out:Initial Cost/MSEK": 5.671625,
    "out:Running cost/(Apt SEK y)": 13051.88235,
    "out:Running Cost over RSP/MSEK": 3.46271,
    "out:LCP/MSEK": 0.558565,
    "out:ROI% old": 15.69263,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 147,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 2263.470149,
    "out:Return %": 14,
    "out:Return/kSEK/y": 796,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.236909,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 1918.332125,
    "out:PV (% sold (El))": 7.630962,
    "out:PV (kWh self-consumed)": 23220.46544,
    "out:PV (ratio sold/self-consumed)": 0.082614,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 332641.0463,
    "out:EL kWh savings": -42452.523,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 232979.94,
    "out:DH kr savings": 302703.3522,
    "out:El kr savings": -72169.28909,
    "out:El kr sold": 2445.873459,
    "out:El kr saved": 39474.79125,
    "out:El kr return": 41920.66471,
    "out:% solar/total": 48,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 107051.2444,
    "out:% savings (space heating)": 65.50181,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 29.08,
    "out:Etvv": 0,
    "out:Ef": 30.37,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5671625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.834591,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.478188,
    "out:Electricity (inc PV)": 27.829892,
    "out:Total Energy Use Pre PV": 82.478188,
    "out:Total Energy Use Post PV": 69.829892,
    "out:Primary Energy": 73.659576,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.415371,
    "out:CO2 Operational/m2": -9.721839,
    "out:Total CO2/m2": 27.693532,
    "out:Total CO2 (tons)": 82941.19859,
    "out:Klimatpaverkan":0.001* -81950.20612,
    "out:Initial Cost/MSEK": 5.928,
    "out:Running cost/(Apt SEK y)": 13486.23529,
    "out:Running Cost over RSP/MSEK": 3.58547,
    "out:LCP/MSEK": 0.17943,
    "out:ROI% old": 14.718117,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 132.9,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2080.893654,
    "out:Return %": 13,
    "out:Return/kSEK/y": 781,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 21.978688,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 17208.1632,
    "out:PV (% sold (El))": 34.226305,
    "out:PV (kWh self-consumed)": 33069.43211,
    "out:PV (ratio sold/self-consumed)": 0.520365,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 314399.0952,
    "out:EL kWh savings": -34831.13609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248830.65,
    "out:DH kr savings": 286103.1766,
    "out:El kr savings": -59212.93135,
    "out:El kr sold": 21940.40808,
    "out:El kr saved": 56218.03459,
    "out:El kr return": 78158.44267,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 125293.1955,
    "out:% savings (space heating)": 59.623183,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 33.67,
    "out:Etvv": 0,
    "out:Ef": 27.83,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5928000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.691445,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.414069,
    "out:Electricity (inc PV)": 27.747593,
    "out:Total Energy Use Pre PV": 79.414069,
    "out:Total Energy Use Post PV": 66.747593,
    "out:Primary Energy": 71.393201,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.415371,
    "out:CO2 Operational/m2": -10.863989,
    "out:Total CO2/m2": 26.551382,
    "out:Total CO2 (tons)": 79520.49768,
    "out:Klimatpaverkan":0.001* -85370.90703,
    "out:Initial Cost/MSEK": 6.002875,
    "out:Running cost/(Apt SEK y)": 12782.5,
    "out:Running Cost over RSP/MSEK": 3.39825,
    "out:LCP/MSEK": 0.291775,
    "out:ROI% old": 14.980084,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 143.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2077.125872,
    "out:Return %": 13,
    "out:Return/kSEK/y": 805,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 21.914569,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 17240.37001,
    "out:PV (% sold (El))": 34.290363,
    "out:PV (kWh self-consumed)": 33037.2253,
    "out:PV (ratio sold/self-consumed)": 0.521847,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 323812.7119,
    "out:EL kWh savings": -34584.65335,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257857.13,
    "out:DH kr savings": 294669.5679,
    "out:El kr savings": -58793.91069,
    "out:El kr sold": 21981.47176,
    "out:El kr saved": 56163.28302,
    "out:El kr return": 78144.75478,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 115879.5788,
    "out:% savings (space heating)": 62.656803,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 30.64,
    "out:Etvv": 0,
    "out:Ef": 27.75,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6002875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.853664,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.81059,
    "out:Electricity (inc PV)": 27.479225,
    "out:Total Energy Use Pre PV": 78.81059,
    "out:Total Energy Use Post PV": 66.479225,
    "out:Primary Energy": 71.909427,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.792003,
    "out:CO2 Operational/m2": -11.452078,
    "out:Total CO2/m2": 26.339925,
    "out:Total CO2 (tons)": 78887.19107,
    "out:Klimatpaverkan":0.001* -86004.21364,
    "out:Initial Cost/MSEK": 6.064625,
    "out:Running cost/(Apt SEK y)": 12695.94118,
    "out:Running Cost over RSP/MSEK": 3.37548,
    "out:LCP/MSEK": 0.252795,
    "out:ROI% old": 14.881193,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 147,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2041.704617,
    "out:Return %": 13,
    "out:Return/kSEK/y": 808,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.31109,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 17545.45442,
    "out:PV (% sold (El))": 34.897163,
    "out:PV (kWh self-consumed)": 32732.14089,
    "out:PV (ratio sold/self-consumed)": 0.536031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 323326.8715,
    "out:EL kWh savings": -33780.9002,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 259170.38,
    "out:DH kr savings": 294227.453,
    "out:El kr savings": -57427.53034,
    "out:El kr sold": 22370.45439,
    "out:El kr saved": 55644.63951,
    "out:El kr return": 78015.0939,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 116365.4192,
    "out:% savings (space heating)": 62.500236,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 32.07,
    "out:Etvv": 0,
    "out:Ef": 27.48,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6064625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.743721,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 39.736409,
    "out:Electricity (inc PV)": 27.388379,
    "out:Total Energy Use Pre PV": 75.736409,
    "out:Total Energy Use Post PV": 63.388379,
    "out:Primary Energy": 69.651994,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.792003,
    "out:CO2 Operational/m2": -12.604651,
    "out:Total CO2/m2": 25.187352,
    "out:Total CO2 (tons)": 75435.27363,
    "out:Klimatpaverkan":0.001* -89456.13108,
    "out:Initial Cost/MSEK": 6.1395,
    "out:Running cost/(Apt SEK y)": 11990.76471,
    "out:Running Cost over RSP/MSEK": 3.18788,
    "out:LCP/MSEK": 0.36552,
    "out:ROI% old": 15.136226,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 158.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 36,
    "out:El bought/kWh/m2": 2037.355461,
    "out:Return %": 14,
    "out:Return/kSEK/y": 832,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.236909,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 17583.06865,
    "out:PV (% sold (El))": 34.971976,
    "out:PV (kWh self-consumed)": 32694.52666,
    "out:PV (ratio sold/self-consumed)": 0.537799,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 332641.0463,
    "out:EL kWh savings": -33508.81948,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 268156.77,
    "out:DH kr savings": 302703.3522,
    "out:El kr savings": -56964.99311,
    "out:El kr sold": 22418.41253,
    "out:El kr saved": 55580.69533,
    "out:El kr return": 77999.10785,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 107051.2444,
    "out:% savings (space heating)": 65.50181,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 29.08,
    "out:Etvv": 0,
    "out:Ef": 27.39,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6139500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.633247,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.460413,
    "out:Electricity (inc PV)": 49.034505,
    "out:Total Energy Use Pre PV": 61.460413,
    "out:Total Energy Use Post PV": 57.034505,
    "out:Primary Energy": 91.089228,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.343262,
    "out:CO2 Operational/m2": 9.937074,
    "out:Total CO2/m2": 29.280336,
    "out:Total CO2 (tons)": 87693.62329,
    "out:Klimatpaverkan":0.001* -77197.78142,
    "out:Initial Cost/MSEK": 6.2285,
    "out:Running cost/(Apt SEK y)": 9857.705882,
    "out:Running Cost over RSP/MSEK": 2.59706,
    "out:LCP/MSEK": 0.86734,
    "out:ROI% old": 16.275049,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 186,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3742.22891,
    "out:Return %": 15,
    "out:Return/kSEK/y": 904,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 34.960913,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 416830.9722,
    "out:EL kWh savings": -98338.24012,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212141.18,
    "out:DH kr savings": 379316.1847,
    "out:El kr savings": -167175.0082,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 22861.31849,
    "out:% savings (space heating)": 92.632742,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.04,
    "out:Etvv": 0,
    "out:Ef": 49.03,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6228500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.930483,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.959815,
    "out:Electricity (inc PV)": 48.327249,
    "out:Total Energy Use Pre PV": 58.959815,
    "out:Total Energy Use Post PV": 54.327249,
    "out:Primary Energy": 89.101612,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.343262,
    "out:CO2 Operational/m2": 9.151023,
    "out:Total CO2/m2": 28.494285,
    "out:Total CO2 (tons)": 85339.42694,
    "out:Klimatpaverkan":0.001* -79551.97777,
    "out:Initial Cost/MSEK": 6.303375,
    "out:Running cost/(Apt SEK y)": 9319.705882,
    "out:Running Cost over RSP/MSEK": 2.45415,
    "out:LCP/MSEK": 0.935375,
    "out:ROI% old": 16.40561,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 201.9,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3707.18705,
    "out:Return %": 15,
    "out:Return/kSEK/y": 923,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 34.460315,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 421930.6932,
    "out:EL kWh savings": -96220.03215,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220382.88,
    "out:DH kr savings": 383956.9308,
    "out:El kr savings": -163574.0546,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 17761.59748,
    "out:% savings (space heating)": 94.276171,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.02,
    "out:Etvv": 0,
    "out:Ef": 48.33,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6303375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.11233,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.957661,
    "out:Electricity (inc PV)": 48.05049,
    "out:Total Energy Use Pre PV": 57.957661,
    "out:Total Energy Use Post PV": 54.05049,
    "out:Primary Energy": 88.966078,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.719894,
    "out:CO2 Operational/m2": 9.018731,
    "out:Total CO2/m2": 28.738625,
    "out:Total CO2 (tons)": 86071.21703,
    "out:Klimatpaverkan":0.001* -78820.18768,
    "out:Initial Cost/MSEK": 6.365125,
    "out:Running cost/(Apt SEK y)": 9169.647059,
    "out:Running Cost over RSP/MSEK": 2.41471,
    "out:LCP/MSEK": 0.913065,
    "out:ROI% old": 16.334973,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 201.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3637.03627,
    "out:Return %": 15,
    "out:Return/kSEK/y": 928,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 33.458161,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 421386.0676,
    "out:EL kWh savings": -95391.14823,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 221296.37,
    "out:DH kr savings": 383461.3215,
    "out:El kr savings": -162164.952,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 18306.22314,
    "out:% savings (space heating)": 94.100661,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.54,
    "out:Etvv": 0,
    "out:Ef": 48.05,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6365125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.668137,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.378707,
    "out:Electricity (inc PV)": 47.312701,
    "out:Total Energy Use Pre PV": 56.378707,
    "out:Total Energy Use Post PV": 52.312701,
    "out:Primary Energy": 86.994033,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.719894,
    "out:CO2 Operational/m2": 8.58232,
    "out:Total CO2/m2": 28.302214,
    "out:Total CO2 (tons)": 84764.18074,
    "out:Klimatpaverkan":0.001* -80127.22397,
    "out:Initial Cost/MSEK": 6.44,
    "out:Running cost/(Apt SEK y)": 8851.441176,
    "out:Running Cost over RSP/MSEK": 2.33032,
    "out:LCP/MSEK": 0.92258,
    "out:ROI% old": 16.332254,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 213.5,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3596.50949,
    "out:Return %": 15,
    "out:Return/kSEK/y": 939,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 32.879207,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 425711.3771,
    "out:EL kWh savings": -93181.49495,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 228988.81,
    "out:DH kr savings": 387397.3532,
    "out:El kr savings": -158408.5414,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 13980.91359,
    "out:% savings (space heating)": 95.49453,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.62,
    "out:Etvv": 0,
    "out:Ef": 47.31,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6440000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.633247,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.460413,
    "out:Electricity (inc PV)": 40.829407,
    "out:Total Energy Use Pre PV": 61.460413,
    "out:Total Energy Use Post PV": 48.829407,
    "out:Primary Energy": 76.320052,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.620171,
    "out:CO2 Operational/m2": 8.555655,
    "out:Total CO2/m2": 37.175826,
    "out:Total CO2 (tons)": 111340.3508,
    "out:Klimatpaverkan":0.001* -53551.05394,
    "out:Initial Cost/MSEK": 6.696375,
    "out:Running cost/(Apt SEK y)": 8603.529412,
    "out:Running Cost over RSP/MSEK": 2.27289,
    "out:LCP/MSEK": 0.723635,
    "out:ROI% old": 15.829481,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 232.7,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3194.686264,
    "out:Return %": 14,
    "out:Return/kSEK/y": 947,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 34.960913,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 221.893638,
    "out:PV (% sold (El))": 0.882674,
    "out:PV (kWh self-consumed)": 24916.90393,
    "out:PV (ratio sold/self-consumed)": 0.008905,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 416830.9722,
    "out:EL kWh savings": -73764.24708,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 254199.88,
    "out:DH kr savings": 379316.1847,
    "out:El kr savings": -125399.22,
    "out:El kr sold": 282.9143888,
    "out:El kr saved": 42358.73668,
    "out:El kr return": 42641.65107,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 22861.31849,
    "out:% savings (space heating)": 92.632742,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.04,
    "out:Etvv": 0,
    "out:Ef": 40.83,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6696375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.930483,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.959815,
    "out:Electricity (inc PV)": 40.145331,
    "out:Total Energy Use Pre PV": 58.959815,
    "out:Total Energy Use Post PV": 46.145331,
    "out:Primary Energy": 74.37416,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.620171,
    "out:CO2 Operational/m2": 7.7251,
    "out:Total CO2/m2": 36.345271,
    "out:Total CO2 (tons)": 108852.8664,
    "out:Klimatpaverkan":0.001* -56038.53828,
    "out:Initial Cost/MSEK": 6.77125,
    "out:Running cost/(Apt SEK y)": 8065.911765,
    "out:Running Cost over RSP/MSEK": 2.13007,
    "out:LCP/MSEK": 0.79158,
    "out:ROI% old": 15.955758,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 254.3,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3159.996238,
    "out:Return %": 14,
    "out:Return/kSEK/y": 965,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 34.460315,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 252.385687,
    "out:PV (% sold (El))": 1.003969,
    "out:PV (kWh self-consumed)": 24886.41188,
    "out:PV (ratio sold/self-consumed)": 0.010142,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 421930.6932,
    "out:EL kWh savings": -71715.46243,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 262362.44,
    "out:DH kr savings": 383956.9308,
    "out:El kr savings": -121916.2861,
    "out:El kr sold": 321.791751,
    "out:El kr saved": 42306.90019,
    "out:El kr return": 42628.69195,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 17761.59748,
    "out:% savings (space heating)": 94.276171,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.02,
    "out:Etvv": 0,
    "out:Ef": 40.15,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6771250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.11233,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.957661,
    "out:Electricity (inc PV)": 39.878177,
    "out:Total Energy Use Pre PV": 57.957661,
    "out:Total Energy Use Post PV": 45.878177,
    "out:Primary Energy": 74.255915,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.996803,
    "out:CO2 Operational/m2": 7.491643,
    "out:Total CO2/m2": 36.488446,
    "out:Total CO2 (tons)": 109281.6707,
    "out:Klimatpaverkan":0.001* -55609.73397,
    "out:Initial Cost/MSEK": 6.833,
    "out:Running cost/(Apt SEK y)": 7916.705882,
    "out:Running Cost over RSP/MSEK": 2.09082,
    "out:LCP/MSEK": 0.76908,
    "out:ROI% old": 15.893625,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 254.3,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3090.684229,
    "out:Return %": 14,
    "out:Return/kSEK/y": 970,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 33.458161,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 320.900383,
    "out:PV (% sold (El))": 1.276514,
    "out:PV (kWh self-consumed)": 24817.89718,
    "out:PV (ratio sold/self-consumed)": 0.01293,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 421386.0676,
    "out:EL kWh savings": -70915.34517,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263314.38,
    "out:DH kr savings": 383461.3215,
    "out:El kr savings": -120556.0868,
    "out:El kr sold": 409.1479883,
    "out:El kr saved": 42190.42521,
    "out:El kr return": 42599.5732,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 18306.22314,
    "out:% savings (space heating)": 94.100661,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.54,
    "out:Etvv": 0,
    "out:Ef": 39.88,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6833000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.668137,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.378707,
    "out:Electricity (inc PV)": 39.167237,
    "out:Total Energy Use Pre PV": 56.378707,
    "out:Total Energy Use Post PV": 44.167237,
    "out:Primary Energy": 72.332198,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.996803,
    "out:CO2 Operational/m2": 6.989254,
    "out:Total CO2/m2": 35.986057,
    "out:Total CO2 (tons)": 107777.0326,
    "out:Klimatpaverkan":0.001* -57114.37215,
    "out:Initial Cost/MSEK": 6.907875,
    "out:Running cost/(Apt SEK y)": 7599.058824,
    "out:Running Cost over RSP/MSEK": 2.00656,
    "out:LCP/MSEK": 0.778465,
    "out:ROI% old": 15.895606,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 270.5,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3050.731872,
    "out:Return %": 14,
    "out:Return/kSEK/y": 981,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 32.879207,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 364.561544,
    "out:PV (% sold (El))": 1.450195,
    "out:PV (kWh self-consumed)": 24774.23602,
    "out:PV (ratio sold/self-consumed)": 0.014715,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 425711.3771,
    "out:EL kWh savings": -68786.10374,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 270925.79,
    "out:DH kr savings": 387397.3532,
    "out:El kr savings": -116936.3764,
    "out:El kr sold": 464.8159692,
    "out:El kr saved": 42116.20124,
    "out:El kr return": 42581.01721,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 13980.91359,
    "out:% savings (space heating)": 95.49453,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.62,
    "out:Etvv": 0,
    "out:Ef": 39.17,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6907875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.633247,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.460413,
    "out:Electricity (inc PV)": 36.653861,
    "out:Total Energy Use Pre PV": 61.460413,
    "out:Total Energy Use Post PV": 44.653861,
    "out:Primary Energy": 68.804069,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.89708,
    "out:CO2 Operational/m2": -10.587122,
    "out:Total CO2/m2": 27.309958,
    "out:Total CO2 (tons)": 81792.40733,
    "out:Klimatpaverkan":0.001* -83098.99738,
    "out:Initial Cost/MSEK": 7.164125,
    "out:Running cost/(Apt SEK y)": 7486.235294,
    "out:Running Cost over RSP/MSEK": 1.98324,
    "out:LCP/MSEK": 0.545535,
    "out:ROI% old": 15.373545,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 262.2,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2862.45689,
    "out:Return %": 14,
    "out:Return/kSEK/y": 985,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 34.960913,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 11392.83768,
    "out:PV (% sold (El))": 22.65987,
    "out:PV (kWh self-consumed)": 38884.75764,
    "out:PV (ratio sold/self-consumed)": 0.29299,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 416830.9722,
    "out:EL kWh savings": -61258.627,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 289702.39,
    "out:DH kr savings": 379316.1847,
    "out:El kr savings": -104139.6659,
    "out:El kr sold": 14525.86804,
    "out:El kr saved": 66104.08798,
    "out:El kr return": 80629.95602,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 22861.31849,
    "out:% savings (space heating)": 92.632742,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 4.04,
    "out:Etvv": 0,
    "out:Ef": 36.65,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7164125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.930483,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.959815,
    "out:Electricity (inc PV)": 36.048918,
    "out:Total Energy Use Pre PV": 58.959815,
    "out:Total Energy Use Post PV": 42.048918,
    "out:Primary Energy": 67.000616,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.89708,
    "out:CO2 Operational/m2": -11.697146,
    "out:Total CO2/m2": 26.199934,
    "out:Total CO2 (tons)": 78467.92272,
    "out:Klimatpaverkan":0.001* -86423.48199,
    "out:Initial Cost/MSEK": 7.239,
    "out:Running cost/(Apt SEK y)": 6950.676471,
    "out:Running Cost over RSP/MSEK": 1.84097,
    "out:LCP/MSEK": 0.61293,
    "out:ROI% old": 15.495293,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 288.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2831.659991,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1003,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 34.460315,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 11588.45569,
    "out:PV (% sold (El))": 23.048946,
    "out:PV (kWh self-consumed)": 38689.13962,
    "out:PV (ratio sold/self-consumed)": 0.299527,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 421930.6932,
    "out:EL kWh savings": -59446.84302,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 297672.58,
    "out:DH kr savings": 383956.9308,
    "out:El kr savings": -101059.6331,
    "out:El kr sold": 14775.281,
    "out:El kr saved": 65771.53736,
    "out:El kr return": 80546.81836,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 17761.59748,
    "out:% savings (space heating)": 94.276171,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.02,
    "out:Etvv": 0,
    "out:Ef": 36.05,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7239000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.11233,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.957661,
    "out:Electricity (inc PV)": 35.812708,
    "out:Total Energy Use Pre PV": 57.957661,
    "out:Total Energy Use Post PV": 41.812708,
    "out:Primary Energy": 66.93807,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.273712,
    "out:CO2 Operational/m2": -12.488378,
    "out:Total CO2/m2": 25.785334,
    "out:Total CO2 (tons)": 77226.20964,
    "out:Klimatpaverkan":0.001* -87665.19507,
    "out:Initial Cost/MSEK": 7.30075,
    "out:Running cost/(Apt SEK y)": 6805.588235,
    "out:Running Cost over RSP/MSEK": 1.80281,
    "out:LCP/MSEK": 0.58934,
    "out:ROI% old": 15.438903,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 288.1,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2770.156423,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1008,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 33.458161,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 11986.39928,
    "out:PV (% sold (El))": 23.840439,
    "out:PV (kWh self-consumed)": 38291.19603,
    "out:PV (ratio sold/self-consumed)": 0.313033,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 421386.0676,
    "out:EL kWh savings": -58739.402,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 298887,
    "out:DH kr savings": 383461.3215,
    "out:El kr savings": -99856.9834,
    "out:El kr sold": 15282.65908,
    "out:El kr saved": 65095.03326,
    "out:El kr return": 80377.69234,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 18306.22314,
    "out:% savings (space heating)": 94.100661,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.54,
    "out:Etvv": 0,
    "out:Ef": 35.81,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7300750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.668137,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.378707,
    "out:Electricity (inc PV)": 35.184315,
    "out:Total Energy Use Pre PV": 56.378707,
    "out:Total Energy Use Post PV": 40.184315,
    "out:Primary Energy": 65.162938,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.273712,
    "out:CO2 Operational/m2": -13.311941,
    "out:Total CO2/m2": 24.961771,
    "out:Total CO2 (tons)": 74759.6661,
    "out:Klimatpaverkan":0.001* -90131.73861,
    "out:Initial Cost/MSEK": 7.375625,
    "out:Running cost/(Apt SEK y)": 6490.294118,
    "out:Running Cost over RSP/MSEK": 1.71917,
    "out:LCP/MSEK": 0.598105,
    "out:ROI% old": 15.444173,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 307.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2734.713507,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1019,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 32.879207,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 12220.1877,
    "out:PV (% sold (El))": 24.305434,
    "out:PV (kWh self-consumed)": 38057.40761,
    "out:PV (ratio sold/self-consumed)": 0.321099,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 425711.3771,
    "out:EL kWh savings": -56857.38607,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 306320.54,
    "out:DH kr savings": 387397.3532,
    "out:El kr savings": -96657.55631,
    "out:El kr sold": 15580.73932,
    "out:El kr saved": 64697.59293,
    "out:El kr return": 80278.33226,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 13980.91359,
    "out:% savings (space heating)": 95.49453,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.62,
    "out:Etvv": 0,
    "out:Ef": 35.18,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7375625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.665711,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.438454,
    "out:Electricity (inc PV)": 47.718145,
    "out:Total Energy Use Pre PV": 57.438454,
    "out:Total Energy Use Post PV": 53.718145,
    "out:Primary Energy": 88.260574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.343262,
    "out:CO2 Operational/m2": 8.950191,
    "out:Total CO2/m2": 28.293453,
    "out:Total CO2 (tons)": 84737.94184,
    "out:Klimatpaverkan":0.001* -80153.46287,
    "out:Initial Cost/MSEK": 6.31825,
    "out:Running cost/(Apt SEK y)": 9091.882353,
    "out:Running Cost over RSP/MSEK": 2.39427,
    "out:LCP/MSEK": 0.98038,
    "out:ROI% old": 16.502377,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 201.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3600.69178,
    "out:Return %": 15,
    "out:Return/kSEK/y": 930,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 32.938954,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 422723.6765,
    "out:EL kWh savings": -94395.78612,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 224205.71,
    "out:DH kr savings": 384678.5456,
    "out:El kr savings": -160472.8364,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 16968.61423,
    "out:% savings (space heating)": 94.531717,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.38,
    "out:Etvv": 0,
    "out:Ef": 47.72,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6318250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.308938,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.83301,
    "out:Electricity (inc PV)": 46.971433,
    "out:Total Energy Use Pre PV": 54.83301,
    "out:Total Energy Use Post PV": 50.971433,
    "out:Primary Energy": 86.293079,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.343262,
    "out:CO2 Operational/m2": 8.1503,
    "out:Total CO2/m2": 27.493562,
    "out:Total CO2 (tons)": 82342.29515,
    "out:Klimatpaverkan":0.001* -82549.10956,
    "out:Initial Cost/MSEK": 6.393125,
    "out:Running cost/(Apt SEK y)": 8538.205882,
    "out:Running Cost over RSP/MSEK": 2.24724,
    "out:LCP/MSEK": 1.052535,
    "out:ROI% old": 16.637649,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 219.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3558.3107,
    "out:Return %": 15,
    "out:Return/kSEK/y": 949,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 32.33351,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 426787.1661,
    "out:EL kWh savings": -92159.40875,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231705.33,
    "out:DH kr savings": 388376.3211,
    "out:El kr savings": -156670.9949,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 12905.12465,
    "out:% savings (space heating)": 95.841212,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.49,
    "out:Etvv": 0,
    "out:Ef": 46.97,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6393125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.717027,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.155025,
    "out:Electricity (inc PV)": 46.914089,
    "out:Total Energy Use Pre PV": 55.155025,
    "out:Total Energy Use Post PV": 51.914089,
    "out:Primary Energy": 86.570641,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.719894,
    "out:CO2 Operational/m2": 8.420785,
    "out:Total CO2/m2": 28.140679,
    "out:Total CO2 (tons)": 84280.38884,
    "out:Klimatpaverkan":0.001* -80611.01587,
    "out:Initial Cost/MSEK": 6.454875,
    "out:Running cost/(Apt SEK y)": 8668.176471,
    "out:Running Cost over RSP/MSEK": 2.28215,
    "out:LCP/MSEK": 0.955875,
    "out:ROI% old": 16.401225,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 213.5,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3510.85175,
    "out:Return %": 15,
    "out:Return/kSEK/y": 945,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 31.655525,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 425564.9532,
    "out:EL kWh savings": -91987.66539,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 230885.08,
    "out:DH kr savings": 387264.1074,
    "out:El kr savings": -156379.0312,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 14127.3375,
    "out:% savings (space heating)": 95.447343,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.04,
    "out:Etvv": 0,
    "out:Ef": 46.91,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6454875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.554272,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 49.490787,
    "out:Electricity (inc PV)": 46.147758,
    "out:Total Energy Use Pre PV": 53.490787,
    "out:Total Energy Use Post PV": 50.147758,
    "out:Primary Energy": 84.613154,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.719894,
    "out:CO2 Operational/m2": 7.973116,
    "out:Total CO2/m2": 27.69301,
    "out:Total CO2 (tons)": 82939.63521,
    "out:Klimatpaverkan":0.001* -81951.7695,
    "out:Initial Cost/MSEK": 6.52975,
    "out:Running cost/(Apt SEK y)": 8337.205882,
    "out:Running Cost over RSP/MSEK": 2.19441,
    "out:LCP/MSEK": 0.96874,
    "out:ROI% old": 16.405113,
    "out:Payback discounted": 7,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 226,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3464.35509,
    "out:Return %": 15,
    "out:Return/kSEK/y": 956,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 30.991287,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 429047.3654,
    "out:EL kWh savings": -89692.52977,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237955.8,
    "out:DH kr savings": 390433.1025,
    "out:El kr savings": -152477.3006,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 10644.92531,
    "out:% savings (space heating)": 96.569581,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.21,
    "out:Etvv": 0,
    "out:Ef": 46.15,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6529750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.665711,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.438454,
    "out:Electricity (inc PV)": 39.557705,
    "out:Total Energy Use Pre PV": 57.438454,
    "out:Total Energy Use Post PV": 45.557705,
    "out:Primary Energy": 73.571782,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.620171,
    "out:CO2 Operational/m2": 7.364186,
    "out:Total CO2/m2": 35.984357,
    "out:Total CO2 (tons)": 107771.9411,
    "out:Klimatpaverkan":0.001* -57119.4636,
    "out:Initial Cost/MSEK": 6.786125,
    "out:Running cost/(Apt SEK y)": 7839.441176,
    "out:Running Cost over RSP/MSEK": 2.0705,
    "out:LCP/MSEK": 0.836275,
    "out:ROI% old": 16.046187,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 254.3,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3054.851685,
    "out:Return %": 14,
    "out:Return/kSEK/y": 973,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 32.938954,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 359.904195,
    "out:PV (% sold (El))": 1.431668,
    "out:PV (kWh self-consumed)": 24778.89337,
    "out:PV (ratio sold/self-consumed)": 0.014525,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 422723.6765,
    "out:EL kWh savings": -69955.54229,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 266213,
    "out:DH kr savings": 384678.5456,
    "out:El kr savings": -118924.4219,
    "out:El kr sold": 458.877849,
    "out:El kr saved": 42124.11873,
    "out:El kr return": 42582.99658,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 16968.61423,
    "out:% savings (space heating)": 94.531717,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.38,
    "out:Etvv": 0,
    "out:Ef": 39.56,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6786125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.308938,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.83301,
    "out:Electricity (inc PV)": 38.839046,
    "out:Total Energy Use Pre PV": 54.83301,
    "out:Total Energy Use Post PV": 42.839046,
    "out:Primary Energy": 71.654783,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.620171,
    "out:CO2 Operational/m2": 6.490482,
    "out:Total CO2/m2": 35.110653,
    "out:Total CO2 (tons)": 105155.227,
    "out:Klimatpaverkan":0.001* -59736.17774,
    "out:Initial Cost/MSEK": 6.861,
    "out:Running cost/(Apt SEK y)": 7286.352941,
    "out:Running Cost over RSP/MSEK": 1.92361,
    "out:LCP/MSEK": 0.90829,
    "out:ROI% old": 16.176921,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 279.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3013.137056,
    "out:Return %": 14,
    "out:Return/kSEK/y": 992,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 32.33351,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 407.636829,
    "out:PV (% sold (El))": 1.621545,
    "out:PV (kWh self-consumed)": 24731.16074,
    "out:PV (ratio sold/self-consumed)": 0.016483,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 426787.1661,
    "out:EL kWh savings": -67803.18271,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 273630.65,
    "out:DH kr savings": 388376.3211,
    "out:El kr savings": -115265.4106,
    "out:El kr sold": 519.7369564,
    "out:El kr saved": 42042.97325,
    "out:El kr return": 42562.71021,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 12905.12465,
    "out:% savings (space heating)": 95.841212,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.49,
    "out:Etvv": 0,
    "out:Ef": 38.84,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6861000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.717027,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.155025,
    "out:Electricity (inc PV)": 38.783954,
    "out:Total Energy Use Pre PV": 55.155025,
    "out:Total Energy Use Post PV": 43.783954,
    "out:Primary Energy": 71.936398,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.996803,
    "out:CO2 Operational/m2": 6.672332,
    "out:Total CO2/m2": 35.669135,
    "out:Total CO2 (tons)": 106827.8618,
    "out:Klimatpaverkan":0.001* -58063.5429,
    "out:Initial Cost/MSEK": 6.922625,
    "out:Running cost/(Apt SEK y)": 7417.058824,
    "out:Running Cost over RSP/MSEK": 1.9587,
    "out:LCP/MSEK": 0.811575,
    "out:ROI% old": 15.960502,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 270.5,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2966.516291,
    "out:Return %": 14,
    "out:Return/kSEK/y": 987,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 31.655525,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 464.327877,
    "out:PV (% sold (El))": 1.847057,
    "out:PV (kWh self-consumed)": 24674.46969,
    "out:PV (ratio sold/self-consumed)": 0.018818,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 425564.9532,
    "out:EL kWh savings": -67638.18402,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 272871.21,
    "out:DH kr savings": 387264.1074,
    "out:El kr savings": -114984.9128,
    "out:El kr sold": 592.0180426,
    "out:El kr saved": 41946.59847,
    "out:El kr return": 42538.61652,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 14127.3375,
    "out:% savings (space heating)": 95.447343,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.04,
    "out:Etvv": 0,
    "out:Ef": 38.78,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6922625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.554272,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 49.490787,
    "out:Electricity (inc PV)": 38.048878,
    "out:Total Energy Use Pre PV": 53.490787,
    "out:Total Energy Use Post PV": 42.048878,
    "out:Primary Energy": 70.03517,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 28.996803,
    "out:CO2 Operational/m2": 6.132437,
    "out:Total CO2/m2": 35.12924,
    "out:Total CO2 (tons)": 105210.8944,
    "out:Klimatpaverkan":0.001* -59680.5103,
    "out:Initial Cost/MSEK": 6.9975,
    "out:Running cost/(Apt SEK y)": 7086.794118,
    "out:Running Cost over RSP/MSEK": 1.87113,
    "out:LCP/MSEK": 0.82427,
    "out:ROI% old": 15.968499,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 288.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2920.937777,
    "out:Return %": 14,
    "out:Return/kSEK/y": 999,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 30.991287,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 522.779673,
    "out:PV (% sold (El))": 2.079573,
    "out:PV (kWh self-consumed)": 24616.01789,
    "out:PV (ratio sold/self-consumed)": 0.021237,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 429047.3654,
    "out:EL kWh savings": -65436.65608,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279857.33,
    "out:DH kr savings": 390433.1025,
    "out:El kr savings": -111242.3153,
    "out:El kr sold": 666.5440831,
    "out:El kr saved": 41847.23042,
    "out:El kr return": 42513.7745,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 10644.92531,
    "out:% savings (space heating)": 96.569581,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.21,
    "out:Etvv": 0,
    "out:Ef": 38.05,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6997500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.665711,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.438454,
    "out:Electricity (inc PV)": 35.529375,
    "out:Total Energy Use Pre PV": 57.438454,
    "out:Total Energy Use Post PV": 41.529375,
    "out:Primary Energy": 66.320788,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.89708,
    "out:CO2 Operational/m2": -12.903888,
    "out:Total CO2/m2": 24.993192,
    "out:Total CO2 (tons)": 74853.77094,
    "out:Klimatpaverkan":0.001* -90037.63377,
    "out:Initial Cost/MSEK": 7.253875,
    "out:Running cost/(Apt SEK y)": 6730.470588,
    "out:Running Cost over RSP/MSEK": 1.78305,
    "out:LCP/MSEK": 0.655975,
    "out:ROI% old": 15.577585,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 288.1,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2738.368329,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1011,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 32.938954,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 12195.9194,
    "out:PV (% sold (El))": 24.257165,
    "out:PV (kWh self-consumed)": 38081.67591,
    "out:PV (ratio sold/self-consumed)": 0.320257,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 422723.6765,
    "out:EL kWh savings": -57890.82918,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 301813.93,
    "out:DH kr savings": 384678.5456,
    "out:El kr savings": -98414.40961,
    "out:El kr sold": 15549.79724,
    "out:El kr saved": 64738.84904,
    "out:El kr return": 80288.64628,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 16968.61423,
    "out:% savings (space heating)": 94.531717,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.38,
    "out:Etvv": 0,
    "out:Ef": 35.53,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7253875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.308938,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.83301,
    "out:Electricity (inc PV)": 34.894357,
    "out:Total Energy Use Pre PV": 54.83301,
    "out:Total Energy Use Post PV": 38.894357,
    "out:Primary Energy": 64.554343,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.89708,
    "out:CO2 Operational/m2": -14.113451,
    "out:Total CO2/m2": 23.783629,
    "out:Total CO2 (tons)": 71231.17037,
    "out:Klimatpaverkan":0.001* -93660.23434,
    "out:Initial Cost/MSEK": 7.32875,
    "out:Running cost/(Apt SEK y)": 6179.852941,
    "out:Running Cost over RSP/MSEK": 1.63681,
    "out:LCP/MSEK": 0.72734,
    "out:ROI% old": 15.703497,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 317.9,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2701.364589,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1029,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 32.33351,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 12443.18166,
    "out:PV (% sold (El))": 24.748959,
    "out:PV (kWh self-consumed)": 37834.41365,
    "out:PV (ratio sold/self-consumed)": 0.328885,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 426787.1661,
    "out:EL kWh savings": -55988.97159,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 309060.13,
    "out:DH kr savings": 388376.3211,
    "out:El kr savings": -95181.2517,
    "out:El kr sold": 15865.05662,
    "out:El kr saved": 64318.5032,
    "out:El kr return": 80183.55982,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 12905.12465,
    "out:% savings (space heating)": 95.841212,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.49,
    "out:Etvv": 0,
    "out:Ef": 34.89,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7328750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.717027,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.155025,
    "out:Electricity (inc PV)": 34.845654,
    "out:Total Energy Use Pre PV": 55.155025,
    "out:Total Energy Use Post PV": 39.845654,
    "out:Primary Energy": 64.847458,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.273712,
    "out:CO2 Operational/m2": -14.30816,
    "out:Total CO2/m2": 23.965552,
    "out:Total CO2 (tons)": 71776.02365,
    "out:Klimatpaverkan":0.001* -93115.38106,
    "out:Initial Cost/MSEK": 7.3905,
    "out:Running cost/(Apt SEK y)": 6313.352941,
    "out:Running Cost over RSP/MSEK": 1.67263,
    "out:LCP/MSEK": 0.62977,
    "out:ROI% old": 15.503049,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 307.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2660.010223,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1025,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 31.655525,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 12724.12967,
    "out:PV (% sold (El))": 25.307753,
    "out:PV (kWh self-consumed)": 37553.46564,
    "out:PV (ratio sold/self-consumed)": 0.338827,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 425564.9532,
    "out:EL kWh savings": -55843.10774,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 308554.09,
    "out:DH kr savings": 387264.1074,
    "out:El kr savings": -94933.28316,
    "out:El kr sold": 16223.26533,
    "out:El kr saved": 63840.89159,
    "out:El kr return": 80064.15692,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 14127.3375,
    "out:% savings (space heating)": 95.447343,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 3.04,
    "out:Etvv": 0,
    "out:Ef": 34.85,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7390500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.554272,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 49.490787,
    "out:Electricity (inc PV)": 34.195607,
    "out:Total Energy Use Pre PV": 53.490787,
    "out:Total Energy Use Post PV": 38.195607,
    "out:Primary Energy": 63.099283,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.273712,
    "out:CO2 Operational/m2": -15.218549,
    "out:Total CO2/m2": 23.055163,
    "out:Total CO2 (tons)": 69049.43915,
    "out:Klimatpaverkan":0.001* -95841.96556,
    "out:Initial Cost/MSEK": 7.465375,
    "out:Running cost/(Apt SEK y)": 5985.882353,
    "out:Running Cost over RSP/MSEK": 1.58579,
    "out:LCP/MSEK": 0.641735,
    "out:ROI% old": 15.513736,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 328.9,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2619.580979,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1036,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 30.991287,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 13003.53996,
    "out:PV (% sold (El))": 25.863488,
    "out:PV (kWh self-consumed)": 37274.05536,
    "out:PV (ratio sold/self-consumed)": 0.348863,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 429047.3654,
    "out:EL kWh savings": -53896.2388,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 315389.01,
    "out:DH kr savings": 390433.1025,
    "out:El kr savings": -91623.60596,
    "out:El kr sold": 16579.51344,
    "out:El kr saved": 63365.89411,
    "out:El kr return": 79945.40755,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 10644.92531,
    "out:% savings (space heating)": 96.569581,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 2.21,
    "out:Etvv": 0,
    "out:Ef": 34.2,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7465375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.309182,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 139.799,
    "out:Total Energy Use Post PV": 139.799,
    "out:Primary Energy": 107.654627,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.063972,
    "out:CO2 Operational/m2": 45.58373,
    "out:Total CO2/m2": 46.647702,
    "out:Total CO2 (tons)": 139708.3014,
    "out:Klimatpaverkan":0.001* -25183.10332,
    "out:Initial Cost/MSEK": 0.46336632,
    "out:Running cost/(Apt SEK y)": 30664.41177,
    "out:Running Cost over RSP/MSEK": 8.14912,
    "out:LCP/MSEK": 1.080414,
    "out:ROI% old": 47.595173,
    "out:Payback discounted": 3,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 16.4,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 43,
    "out:Return/kSEK/y": 197,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 84761.27019,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 53717.21,
    "out:DH kr savings": 77132.75587,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 225548.4714,
    "out:% savings (space heating)": 27.315053,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.31,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 463366.3197,
    "out:Seasonal Variation ROI (%)": -5,
    "out:Seasonal Variation Payback": -999
  },
  {
    "ID": "Z00000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.872344,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 134.799,
    "out:Total Energy Use Post PV": 134.799,
    "out:Primary Energy": 104.548841,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.063972,
    "out:CO2 Operational/m2": 43.78381,
    "out:Total CO2/m2": 44.847782,
    "out:Total CO2 (tons)": 134317.6014,
    "out:Klimatpaverkan":0.001* -30573.80329,
    "out:Initial Cost/MSEK": 0.538240482,
    "out:Running cost/(Apt SEK y)": 29506.85294,
    "out:Running Cost over RSP/MSEK": 7.84111,
    "out:LCP/MSEK": 1.31355,
    "out:ROI% old": 49.1493,
    "out:Payback discounted": 3,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 20.7,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 44,
    "out:Return/kSEK/y": 236,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 98049.44939,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 65809.45,
    "out:DH kr savings": 89224.99894,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 212260.2922,
    "out:% savings (space heating)": 31.597284,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 538240.4822,
    "out:Seasonal Variation ROI (%)": -2,
    "out:Seasonal Variation Payback": -999
  },
  {
    "ID": "Z00000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.309182,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 132.799,
    "out:Total Energy Use Post PV": 132.799,
    "out:Primary Energy": 105.029627,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.440604,
    "out:CO2 Operational/m2": 43.063842,
    "out:Total CO2/m2": 44.504446,
    "out:Total CO2 (tons)": 133289.3216,
    "out:Klimatpaverkan":0.001* -31602.08309,
    "out:Initial Cost/MSEK": 0.59997432,
    "out:Running cost/(Apt SEK y)": 29043.82353,
    "out:Running Cost over RSP/MSEK": 7.71791,
    "out:LCP/MSEK": 1.375016,
    "out:ROI% old": 47.025584,
    "out:Payback discounted": 3,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 42,
    "out:Return/kSEK/y": 252,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 104168.6526,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 71377.93,
    "out:DH kr savings": 94793.47389,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 225548.4714,
    "out:% savings (space heating)": 27.315053,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.31,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 599974.3197,
    "out:Seasonal Variation ROI (%)": -1,
    "out:Seasonal Variation Payback": -999
  },
  {
    "ID": "Z00000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.872344,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 128.799,
    "out:Total Energy Use Post PV": 128.799,
    "out:Primary Energy": 101.923841,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.440604,
    "out:CO2 Operational/m2": 41.623906,
    "out:Total CO2/m2": 43.06451,
    "out:Total CO2 (tons)": 128976.7616,
    "out:Klimatpaverkan":0.001* -35914.64306,
    "out:Initial Cost/MSEK": 0.674848482,
    "out:Running cost/(Apt SEK y)": 28117.79412,
    "out:Running Cost over RSP/MSEK": 7.47151,
    "out:LCP/MSEK": 1.546542,
    "out:ROI% old": 47.024101,
    "out:Payback discounted": 3,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 42,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 117456.8318,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83470.17,
    "out:DH kr savings": 106885.717,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 212260.2922,
    "out:% savings (space heating)": 31.597284,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 674848.4822,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.309182,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 139.799,
    "out:Total Energy Use Post PV": 134.185248,
    "out:Primary Energy": 97.549873,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 10.340881,
    "out:CO2 Operational/m2": 30.956905,
    "out:Total CO2/m2": 41.297786,
    "out:Total CO2 (tons)": 123685.4826,
    "out:Klimatpaverkan":0.001* -41205.92213,
    "out:Initial Cost/MSEK": 0.931208744,
    "out:Running cost/(Apt SEK y)": 29511.55882,
    "out:Running Cost over RSP/MSEK": 7.8507,
    "out:LCP/MSEK": 0.910991,
    "out:ROI% old": 28.261271,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 21.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 25,
    "out:Return/kSEK/y": 236,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 84761.27019,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 92914.7,
    "out:DH kr savings": 77132.75587,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 225548.4714,
    "out:% savings (space heating)": 27.315053,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.31,
    "out:Etvv": 25,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 931208.7442,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.872344,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 134.799,
    "out:Total Energy Use Post PV": 129.185248,
    "out:Primary Energy": 94.444087,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 10.340881,
    "out:CO2 Operational/m2": 29.156985,
    "out:Total CO2/m2": 39.497866,
    "out:Total CO2 (tons)": 118294.7826,
    "out:Klimatpaverkan":0.001* -46596.6221,
    "out:Initial Cost/MSEK": 1.006082907,
    "out:Running cost/(Apt SEK y)": 28354,
    "out:Running Cost over RSP/MSEK": 7.5427,
    "out:LCP/MSEK": 1.144117,
    "out:ROI% old": 30.531423,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 27,
    "out:Return/kSEK/y": 276,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 98049.44939,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105006.95,
    "out:DH kr savings": 89224.99894,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 212260.2922,
    "out:% savings (space heating)": 31.597284,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 25,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1006082.907,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z00000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.309182,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 132.799,
    "out:Total Energy Use Post PV": 127.185248,
    "out:Primary Energy": 94.924873,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 10.717513,
    "out:CO2 Operational/m2": 28.437017,
    "out:Total CO2/m2": 39.15453,
    "out:Total CO2 (tons)": 117266.5028,
    "out:Klimatpaverkan":0.001* -47624.90189,
    "out:Initial Cost/MSEK": 1.067816744,
    "out:Running cost/(Apt SEK y)": 27890.97059,
    "out:Running Cost over RSP/MSEK": 7.4195,
    "out:LCP/MSEK": 1.205583,
    "out:ROI% old": 30.414529,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 28.3,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 27,
    "out:Return/kSEK/y": 291,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 104168.6526,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110575.42,
    "out:DH kr savings": 94793.47389,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 225548.4714,
    "out:% savings (space heating)": 27.315053,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.31,
    "out:Etvv": 21.25,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1067816.744,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z00000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.872344,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 128.799,
    "out:Total Energy Use Post PV": 123.185248,
    "out:Primary Energy": 91.819087,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 10.717513,
    "out:CO2 Operational/m2": 26.997081,
    "out:Total CO2/m2": 37.714594,
    "out:Total CO2 (tons)": 112953.9428,
    "out:Klimatpaverkan":0.001* -51937.46187,
    "out:Initial Cost/MSEK": 1.142690907,
    "out:Running cost/(Apt SEK y)": 26964.91177,
    "out:Running Cost over RSP/MSEK": 7.1731,
    "out:LCP/MSEK": 1.377109,
    "out:ROI% old": 31.502082,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 32.5,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 28,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 117456.8318,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 122667.66,
    "out:DH kr savings": 106885.717,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 212260.2922,
    "out:% savings (space heating)": 31.597284,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 21.25,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1142690.907,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.309182,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 139.799,
    "out:Total Energy Use Post PV": 132.781176,
    "out:Primary Energy": 95.022544,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.61779,
    "out:CO2 Operational/m2": -4.725413,
    "out:Total CO2/m2": 14.892377,
    "out:Total CO2 (tons)": 44602.16913,
    "out:Klimatpaverkan":0.001* -120289.2356,
    "out:Initial Cost/MSEK": 1.399,
    "out:Running cost/(Apt SEK y)": 28516.26471,
    "out:Running Cost over RSP/MSEK": 7.59328,
    "out:LCP/MSEK": 0.70062,
    "out:ROI% old": 21.440008,
    "out:Payback discounted": 6,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 19,
    "out:Return/kSEK/y": 270,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 84761.27019,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126753.86,
    "out:DH kr savings": 77132.75587,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 225548.4714,
    "out:% savings (space heating)": 27.315053,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.31,
    "out:Etvv": 25,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1399000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.872344,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 134.799,
    "out:Total Energy Use Post PV": 127.781176,
    "out:Primary Energy": 91.916758,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.61779,
    "out:CO2 Operational/m2": -6.525333,
    "out:Total CO2/m2": 13.092457,
    "out:Total CO2 (tons)": 39211.46916,
    "out:Klimatpaverkan":0.001* -125679.9355,
    "out:Initial Cost/MSEK": 1.473875,
    "out:Running cost/(Apt SEK y)": 27358.73529,
    "out:Running Cost over RSP/MSEK": 7.28528,
    "out:LCP/MSEK": 0.933745,
    "out:ROI% old": 23.336152,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 27.3,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 21,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 98049.44939,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138846.1,
    "out:DH kr savings": 89224.99894,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 212260.2922,
    "out:% savings (space heating)": 31.597284,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 25,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1473875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.309182,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 132.799,
    "out:Total Energy Use Post PV": 125.781176,
    "out:Primary Energy": 92.397544,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.994422,
    "out:CO2 Operational/m2": -7.245301,
    "out:Total CO2/m2": 12.749121,
    "out:Total CO2 (tons)": 38183.18937,
    "out:Klimatpaverkan":0.001* -126708.2153,
    "out:Initial Cost/MSEK": 1.535625,
    "out:Running cost/(Apt SEK y)": 26895.70588,
    "out:Running Cost over RSP/MSEK": 7.16208,
    "out:LCP/MSEK": 0.995195,
    "out:ROI% old": 23.54388,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 29.4,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 21,
    "out:Return/kSEK/y": 325,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 104168.6526,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144414.58,
    "out:DH kr savings": 94793.47389,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 225548.4714,
    "out:% savings (space heating)": 27.315053,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.31,
    "out:Etvv": 21.25,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1535625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.872344,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 128.799,
    "out:Total Energy Use Post PV": 121.781176,
    "out:Primary Energy": 89.291758,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.994422,
    "out:CO2 Operational/m2": -8.685237,
    "out:Total CO2/m2": 11.309185,
    "out:Total CO2 (tons)": 33870.62939,
    "out:Klimatpaverkan":0.001* -131020.7753,
    "out:Initial Cost/MSEK": 1.6105,
    "out:Running cost/(Apt SEK y)": 25969.64706,
    "out:Running Cost over RSP/MSEK": 6.91568,
    "out:LCP/MSEK": 1.16672,
    "out:ROI% old": 24.63494,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 22,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 117456.8318,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156506.82,
    "out:DH kr savings": 106885.717,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 212260.2922,
    "out:% savings (space heating)": 31.597284,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 21.25,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1610500,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.309182,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 130.799,
    "out:Total Energy Use Post PV": 130.799,
    "out:Primary Energy": 104.154627,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.063972,
    "out:CO2 Operational/m2": 42.343874,
    "out:Total CO2/m2": 43.407846,
    "out:Total CO2 (tons)": 130005.0414,
    "out:Klimatpaverkan":0.001* -34886.36327,
    "out:Initial Cost/MSEK": 0.553102945,
    "out:Running cost/(Apt SEK y)": 28580.82353,
    "out:Running Cost over RSP/MSEK": 7.59471,
    "out:LCP/MSEK": 1.545087,
    "out:ROI% old": 54.192702,
    "out:Payback discounted": 3,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 48,
    "out:Return/kSEK/y": 268,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 110637.7801,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77264.83,
    "out:DH kr savings": 100680.3799,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 225548.4714,
    "out:% savings (space heating)": 27.315053,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.31,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 553102.9447,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999
  },
  {
    "ID": "Z00000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.872344,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 125.799,
    "out:Total Energy Use Post PV": 125.799,
    "out:Primary Energy": 101.048841,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 1.063972,
    "out:CO2 Operational/m2": 40.543954,
    "out:Total CO2/m2": 41.607926,
    "out:Total CO2 (tons)": 124614.3415,
    "out:Klimatpaverkan":0.001* -40277.06324,
    "out:Initial Cost/MSEK": 0.627977107,
    "out:Running cost/(Apt SEK y)": 27423.26471,
    "out:Running Cost over RSP/MSEK": 7.28671,
    "out:LCP/MSEK": 1.778213,
    "out:ROI% old": 54.737892,
    "out:Payback discounted": 3,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 29.4,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 49,
    "out:Return/kSEK/y": 307,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 123925.9593,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 89357.08,
    "out:DH kr savings": 112772.623,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 212260.2922,
    "out:% savings (space heating)": 31.597284,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 627977.1072,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.309182,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 125.799,
    "out:Total Energy Use Post PV": 125.799,
    "out:Primary Energy": 102.054627,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.440604,
    "out:CO2 Operational/m2": 40.543954,
    "out:Total CO2/m2": 41.984558,
    "out:Total CO2 (tons)": 125742.3417,
    "out:Klimatpaverkan":0.001* -39149.06305,
    "out:Initial Cost/MSEK": 0.689710945,
    "out:Running cost/(Apt SEK y)": 27423.26471,
    "out:Running Cost over RSP/MSEK": 7.28671,
    "out:LCP/MSEK": 1.716479,
    "out:ROI% old": 49.838477,
    "out:Payback discounted": 3,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 29.4,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 45,
    "out:Return/kSEK/y": 307,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 126163.6861,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91393.41,
    "out:DH kr savings": 114808.9543,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 225548.4714,
    "out:% savings (space heating)": 27.315053,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.31,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 689710.9447,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.872344,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 120.799,
    "out:Total Energy Use Post PV": 120.799,
    "out:Primary Energy": 98.948841,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 1.440604,
    "out:CO2 Operational/m2": 38.744034,
    "out:Total CO2/m2": 40.184638,
    "out:Total CO2 (tons)": 120351.6417,
    "out:Klimatpaverkan":0.001* -44539.76302,
    "out:Initial Cost/MSEK": 0.764585107,
    "out:Running cost/(Apt SEK y)": 26265.70588,
    "out:Running Cost over RSP/MSEK": 6.97871,
    "out:LCP/MSEK": 1.949605,
    "out:ROI% old": 50.712658,
    "out:Payback discounted": 3,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 45,
    "out:Return/kSEK/y": 347,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 139451.8653,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 103485.65,
    "out:DH kr savings": 126901.1974,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 212260.2922,
    "out:% savings (space heating)": 31.597284,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 764585.1072,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z00000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.309182,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 130.799,
    "out:Total Energy Use Post PV": 125.185248,
    "out:Primary Energy": 94.049873,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 10.340881,
    "out:CO2 Operational/m2": 27.717049,
    "out:Total CO2/m2": 38.05793,
    "out:Total CO2 (tons)": 113982.2226,
    "out:Klimatpaverkan":0.001* -50909.18208,
    "out:Initial Cost/MSEK": 1.020945369,
    "out:Running cost/(Apt SEK y)": 27427.94118,
    "out:Running Cost over RSP/MSEK": 7.2963,
    "out:LCP/MSEK": 1.375655,
    "out:ROI% old": 33.534745,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 30.4,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 30,
    "out:Return/kSEK/y": 307,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 110637.7801,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116462.33,
    "out:DH kr savings": 100680.3799,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 225548.4714,
    "out:% savings (space heating)": 27.315053,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.31,
    "out:Etvv": 20,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1020945.369,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.872344,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 125.799,
    "out:Total Energy Use Post PV": 120.185248,
    "out:Primary Energy": 90.944087,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 10.340881,
    "out:CO2 Operational/m2": 25.917129,
    "out:Total CO2/m2": 36.25801,
    "out:Total CO2 (tons)": 108591.5227,
    "out:Klimatpaverkan":0.001* -56299.88205,
    "out:Initial Cost/MSEK": 1.095819532,
    "out:Running cost/(Apt SEK y)": 26270.38235,
    "out:Running Cost over RSP/MSEK": 6.9883,
    "out:LCP/MSEK": 1.60878,
    "out:ROI% old": 35.258673,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 35.8,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 32,
    "out:Return/kSEK/y": 346,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 123925.9593,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128554.57,
    "out:DH kr savings": 112772.623,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 212260.2922,
    "out:% savings (space heating)": 31.597284,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 20,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1095819.532,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.309182,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 125.799,
    "out:Total Energy Use Post PV": 120.185248,
    "out:Primary Energy": 91.949873,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 10.717513,
    "out:CO2 Operational/m2": 25.917129,
    "out:Total CO2/m2": 36.634642,
    "out:Total CO2 (tons)": 109719.5229,
    "out:Klimatpaverkan":0.001* -55171.88185,
    "out:Initial Cost/MSEK": 1.157553369,
    "out:Running cost/(Apt SEK y)": 26270.38235,
    "out:Running Cost over RSP/MSEK": 6.9883,
    "out:LCP/MSEK": 1.547047,
    "out:ROI% old": 33.378282,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 35.8,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 30,
    "out:Return/kSEK/y": 346,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 126163.6861,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130590.9,
    "out:DH kr savings": 114808.9543,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 225548.4714,
    "out:% savings (space heating)": 27.315053,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.31,
    "out:Etvv": 17,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1157553.369,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.872344,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 120.799,
    "out:Total Energy Use Post PV": 115.185248,
    "out:Primary Energy": 88.844087,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 10.717513,
    "out:CO2 Operational/m2": 24.117209,
    "out:Total CO2/m2": 34.834722,
    "out:Total CO2 (tons)": 104328.8229,
    "out:Klimatpaverkan":0.001* -60562.58183,
    "out:Initial Cost/MSEK": 1.232427532,
    "out:Running cost/(Apt SEK y)": 25112.85294,
    "out:Running Cost over RSP/MSEK": 6.6803,
    "out:LCP/MSEK": 1.780172,
    "out:ROI% old": 34.920628,
    "out:Payback discounted": 4,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 31,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 139451.8653,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142683.14,
    "out:DH kr savings": 126901.1974,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 212260.2922,
    "out:% savings (space heating)": 31.597284,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 17,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1232427.532,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.309182,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 130.799,
    "out:Total Energy Use Post PV": 123.781176,
    "out:Primary Energy": 91.522544,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.61779,
    "out:CO2 Operational/m2": -7.965269,
    "out:Total CO2/m2": 11.652521,
    "out:Total CO2 (tons)": 34898.90919,
    "out:Klimatpaverkan":0.001* -129992.4955,
    "out:Initial Cost/MSEK": 1.48875,
    "out:Running cost/(Apt SEK y)": 26432.67647,
    "out:Running Cost over RSP/MSEK": 7.03888,
    "out:LCP/MSEK": 1.16527,
    "out:ROI% old": 25.467386,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 23,
    "out:Return/kSEK/y": 341,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 110637.7801,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150301.48,
    "out:DH kr savings": 100680.3799,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 225548.4714,
    "out:% savings (space heating)": 27.315053,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.31,
    "out:Etvv": 20,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1488750,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.872344,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 125.799,
    "out:Total Energy Use Post PV": 118.781176,
    "out:Primary Energy": 88.416758,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.61779,
    "out:CO2 Operational/m2": -9.765189,
    "out:Total CO2/m2": 9.852601,
    "out:Total CO2 (tons)": 29508.20921,
    "out:Klimatpaverkan":0.001* -135383.1955,
    "out:Initial Cost/MSEK": 1.563625,
    "out:Running cost/(Apt SEK y)": 25275.11765,
    "out:Running Cost over RSP/MSEK": 6.73087,
    "out:LCP/MSEK": 1.398405,
    "out:ROI% old": 27.061933,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 37,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 24,
    "out:Return/kSEK/y": 380,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 123925.9593,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162393.72,
    "out:DH kr savings": 112772.623,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 212260.2922,
    "out:% savings (space heating)": 31.597284,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 20,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1563625,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.309182,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 125.799,
    "out:Total Energy Use Post PV": 118.781176,
    "out:Primary Energy": 89.422544,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.994422,
    "out:CO2 Operational/m2": -9.765189,
    "out:Total CO2/m2": 10.229233,
    "out:Total CO2 (tons)": 30636.20941,
    "out:Klimatpaverkan":0.001* -134255.1953,
    "out:Initial Cost/MSEK": 1.625375,
    "out:Running cost/(Apt SEK y)": 25275.11765,
    "out:Running Cost over RSP/MSEK": 6.73087,
    "out:LCP/MSEK": 1.336655,
    "out:ROI% old": 26.033816,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 37,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 23,
    "out:Return/kSEK/y": 380,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 126163.6861,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164430.06,
    "out:DH kr savings": 114808.9543,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 225548.4714,
    "out:% savings (space heating)": 27.315053,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.31,
    "out:Etvv": 17,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1625375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.872344,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 120.799,
    "out:Total Energy Use Post PV": 113.781176,
    "out:Primary Energy": 86.316758,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.994422,
    "out:CO2 Operational/m2": -11.565109,
    "out:Total CO2/m2": 8.429313,
    "out:Total CO2 (tons)": 25245.50944,
    "out:Klimatpaverkan":0.001* -139645.8953,
    "out:Initial Cost/MSEK": 1.70025,
    "out:Running cost/(Apt SEK y)": 24117.55882,
    "out:Running Cost over RSP/MSEK": 6.42287,
    "out:LCP/MSEK": 1.56978,
    "out:ROI% old": 27.475203,
    "out:Payback discounted": 5,
    "out:Atemp": 2995,
    "out:Umean": 0.6,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 43,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 25,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 139451.8653,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176522.3,
    "out:DH kr savings": 126901.1974,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 212260.2922,
    "out:% savings (space heating)": 31.597284,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 17,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1700250,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z01000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.782142,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 122.799,
    "out:Total Energy Use Post PV": 122.799,
    "out:Primary Energy": 96.085699,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.246545,
    "out:CO2 Operational/m2": 39.464002,
    "out:Total CO2/m2": 52.710547,
    "out:Total CO2 (tons)": 157866.3186,
    "out:Klimatpaverkan":0.001* -7025.086095,
    "out:Initial Cost/MSEK": 3.11125,
    "out:Running cost/(Apt SEK y)": 26728.73529,
    "out:Running Cost over RSP/MSEK": 7.10191,
    "out:LCP/MSEK": -0.52026,
    "out:ROI% old": 11.896872,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 32.5,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 11,
    "out:Return/kSEK/y": 331,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 134259.2007,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98760.33,
    "out:DH kr savings": 122175.8727,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 176050.5409,
    "out:% savings (space heating)": 43.266189,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 58.78,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3111250,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.096533,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 118.799,
    "out:Total Energy Use Post PV": 118.799,
    "out:Primary Energy": 93.505773,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.246545,
    "out:CO2 Operational/m2": 38.024066,
    "out:Total CO2/m2": 51.270611,
    "out:Total CO2 (tons)": 153553.7586,
    "out:Klimatpaverkan":0.001* -11337.64607,
    "out:Initial Cost/MSEK": 3.186125,
    "out:Running cost/(Apt SEK y)": 25802.67647,
    "out:Running Cost over RSP/MSEK": 6.85551,
    "out:LCP/MSEK": -0.348735,
    "out:ROI% old": 12.722082,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 37,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 11,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 145297.474,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 108805.16,
    "out:DH kr savings": 132220.7013,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 165012.2676,
    "out:% savings (space heating)": 46.823368,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.1,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3186125,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.782142,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 116.799,
    "out:Total Energy Use Post PV": 116.799,
    "out:Primary Energy": 93.460699,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.623177,
    "out:CO2 Operational/m2": 37.304098,
    "out:Total CO2/m2": 50.927275,
    "out:Total CO2 (tons)": 152525.4788,
    "out:Klimatpaverkan":0.001* -12365.92587,
    "out:Initial Cost/MSEK": 3.247875,
    "out:Running cost/(Apt SEK y)": 25339.67647,
    "out:Running Cost over RSP/MSEK": 6.73231,
    "out:LCP/MSEK": -0.287285,
    "out:ROI% old": 13.022097,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 378,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 153666.5832,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116421.04,
    "out:DH kr savings": 139836.5907,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 176050.5409,
    "out:% savings (space heating)": 43.266189,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 58.78,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3247875,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.096533,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 112.799,
    "out:Total Energy Use Post PV": 112.799,
    "out:Primary Energy": 90.880773,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.623177,
    "out:CO2 Operational/m2": 35.864162,
    "out:Total CO2/m2": 49.487339,
    "out:Total CO2 (tons)": 148212.9189,
    "out:Klimatpaverkan":0.001* -16678.48584,
    "out:Initial Cost/MSEK": 3.32275,
    "out:Running cost/(Apt SEK y)": 24413.61765,
    "out:Running Cost over RSP/MSEK": 6.4859,
    "out:LCP/MSEK": -0.11575,
    "out:ROI% old": 13.788063,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 44.2,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 410,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 164704.8564,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126465.87,
    "out:DH kr savings": 149881.4194,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 165012.2676,
    "out:% savings (space heating)": 46.823368,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.1,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3322750,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.782142,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 122.799,
    "out:Total Energy Use Post PV": 117.185248,
    "out:Primary Energy": 85.980945,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.523454,
    "out:CO2 Operational/m2": 24.837177,
    "out:Total CO2/m2": 47.360631,
    "out:Total CO2 (tons)": 141843.4998,
    "out:Klimatpaverkan":0.001* -23047.9049,
    "out:Initial Cost/MSEK": 3.579125,
    "out:Running cost/(Apt SEK y)": 25575.85294,
    "out:Running Cost over RSP/MSEK": 6.8035,
    "out:LCP/MSEK": -0.689725,
    "out:ROI% old": 11.532747,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 10,
    "out:Return/kSEK/y": 370,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 134259.2007,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137957.82,
    "out:DH kr savings": 122175.8727,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 176050.5409,
    "out:% savings (space heating)": 43.266189,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 58.78,
    "out:Etvv": 25,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3579125,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.096533,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 118.799,
    "out:Total Energy Use Post PV": 113.185248,
    "out:Primary Energy": 83.401019,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.523454,
    "out:CO2 Operational/m2": 23.397241,
    "out:Total CO2/m2": 45.920695,
    "out:Total CO2 (tons)": 137530.9398,
    "out:Klimatpaverkan":0.001* -27360.46488,
    "out:Initial Cost/MSEK": 3.654,
    "out:Running cost/(Apt SEK y)": 24649.82353,
    "out:Running Cost over RSP/MSEK": 6.55709,
    "out:LCP/MSEK": -0.51819,
    "out:ROI% old": 12.259794,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 44.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 11,
    "out:Return/kSEK/y": 401,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 145297.474,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148002.65,
    "out:DH kr savings": 132220.7013,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 165012.2676,
    "out:% savings (space heating)": 46.823368,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.1,
    "out:Etvv": 25,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3654000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.782142,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 116.799,
    "out:Total Energy Use Post PV": 111.185248,
    "out:Primary Energy": 83.355945,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.900086,
    "out:CO2 Operational/m2": 22.677273,
    "out:Total CO2/m2": 45.577359,
    "out:Total CO2 (tons)": 136502.66,
    "out:Klimatpaverkan":0.001* -28388.74467,
    "out:Initial Cost/MSEK": 3.71575,
    "out:Running cost/(Apt SEK y)": 24186.79412,
    "out:Running Cost over RSP/MSEK": 6.43389,
    "out:LCP/MSEK": -0.45674,
    "out:ROI% old": 12.529714,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 46.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 11,
    "out:Return/kSEK/y": 417,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 153666.5832,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155618.54,
    "out:DH kr savings": 139836.5907,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 176050.5409,
    "out:% savings (space heating)": 43.266189,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 58.78,
    "out:Etvv": 21.25,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3715750,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.096533,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 112.799,
    "out:Total Energy Use Post PV": 107.185248,
    "out:Primary Energy": 80.776019,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.900086,
    "out:CO2 Operational/m2": 21.237337,
    "out:Total CO2/m2": 44.137423,
    "out:Total CO2 (tons)": 132190.1001,
    "out:Klimatpaverkan":0.001* -32701.30465,
    "out:Initial Cost/MSEK": 3.790625,
    "out:Running cost/(Apt SEK y)": 23260.76471,
    "out:Running Cost over RSP/MSEK": 6.18749,
    "out:LCP/MSEK": -0.285215,
    "out:ROI% old": 13.210826,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 52.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 12,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 164704.8564,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165663.37,
    "out:DH kr savings": 149881.4194,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 165012.2676,
    "out:% savings (space heating)": 46.823368,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.1,
    "out:Etvv": 21.25,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3790625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.782142,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 122.799,
    "out:Total Energy Use Post PV": 115.781176,
    "out:Primary Energy": 83.453616,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.800363,
    "out:CO2 Operational/m2": -10.845141,
    "out:Total CO2/m2": 20.955222,
    "out:Total CO2 (tons)": 62760.18636,
    "out:Klimatpaverkan":0.001* -102131.2183,
    "out:Initial Cost/MSEK": 4.046875,
    "out:Running cost/(Apt SEK y)": 24580.58824,
    "out:Running Cost over RSP/MSEK": 6.54607,
    "out:LCP/MSEK": -0.900045,
    "out:ROI% old": 11.108501,
    "out:Payback discounted": 11,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 40.5,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 10,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 134259.2007,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171796.97,
    "out:DH kr savings": 122175.8727,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 176050.5409,
    "out:% savings (space heating)": 43.266189,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 58.78,
    "out:Etvv": 25,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4046875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.096533,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 118.799,
    "out:Total Energy Use Post PV": 111.781176,
    "out:Primary Energy": 80.87369,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.800363,
    "out:CO2 Operational/m2": -12.285077,
    "out:Total CO2/m2": 19.515286,
    "out:Total CO2 (tons)": 58447.62638,
    "out:Klimatpaverkan":0.001* -106443.7783,
    "out:Initial Cost/MSEK": 4.12175,
    "out:Running cost/(Apt SEK y)": 23654.55882,
    "out:Running Cost over RSP/MSEK": 6.29967,
    "out:LCP/MSEK": -0.72852,
    "out:ROI% old": 11.760712,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 11,
    "out:Return/kSEK/y": 435,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 145297.474,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181841.8,
    "out:DH kr savings": 132220.7013,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 165012.2676,
    "out:% savings (space heating)": 46.823368,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.1,
    "out:Etvv": 25,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4121750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.782142,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 116.799,
    "out:Total Energy Use Post PV": 109.781176,
    "out:Primary Energy": 80.828616,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.176995,
    "out:CO2 Operational/m2": -13.005045,
    "out:Total CO2/m2": 19.17195,
    "out:Total CO2 (tons)": 57419.34659,
    "out:Klimatpaverkan":0.001* -107472.0581,
    "out:Initial Cost/MSEK": 4.1835,
    "out:Running cost/(Apt SEK y)": 23191.52941,
    "out:Running Cost over RSP/MSEK": 6.17647,
    "out:LCP/MSEK": -0.66707,
    "out:ROI% old": 12.00782,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 48.2,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 11,
    "out:Return/kSEK/y": 451,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 153666.5832,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189457.69,
    "out:DH kr savings": 139836.5907,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 176050.5409,
    "out:% savings (space heating)": 43.266189,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 58.78,
    "out:Etvv": 21.25,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4183500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.096533,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 112.799,
    "out:Total Energy Use Post PV": 105.781176,
    "out:Primary Energy": 78.24869,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.176995,
    "out:CO2 Operational/m2": -14.444981,
    "out:Total CO2/m2": 17.732014,
    "out:Total CO2 (tons)": 53106.78661,
    "out:Klimatpaverkan":0.001* -111784.6181,
    "out:Initial Cost/MSEK": 4.258375,
    "out:Running cost/(Apt SEK y)": 22265.47059,
    "out:Running Cost over RSP/MSEK": 5.93007,
    "out:LCP/MSEK": -0.495545,
    "out:ROI% old": 12.623293,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 11,
    "out:Return/kSEK/y": 483,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 164704.8564,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199502.52,
    "out:DH kr savings": 149881.4194,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 165012.2676,
    "out:% savings (space heating)": 46.823368,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.1,
    "out:Etvv": 21.25,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4258375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.782142,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 113.799,
    "out:Total Energy Use Post PV": 113.799,
    "out:Primary Energy": 92.585699,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.246545,
    "out:CO2 Operational/m2": 36.224146,
    "out:Total CO2/m2": 49.470691,
    "out:Total CO2 (tons)": 148163.0587,
    "out:Klimatpaverkan":0.001* -16728.34604,
    "out:Initial Cost/MSEK": 3.201,
    "out:Running cost/(Apt SEK y)": 24645.11765,
    "out:Running Cost over RSP/MSEK": 6.5475,
    "out:LCP/MSEK": -0.0556,
    "out:ROI% old": 14.037578,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 43,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 402,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 160135.7107,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 122307.95,
    "out:DH kr savings": 145723.4967,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 176050.5409,
    "out:% savings (space heating)": 43.266189,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 58.78,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3201000,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.096533,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 110.799,
    "out:Total Energy Use Post PV": 110.799,
    "out:Primary Energy": 90.005773,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.246545,
    "out:CO2 Operational/m2": 35.144194,
    "out:Total CO2/m2": 48.390739,
    "out:Total CO2 (tons)": 144928.6387,
    "out:Klimatpaverkan":0.001* -19962.76603,
    "out:Initial Cost/MSEK": 3.275875,
    "out:Running cost/(Apt SEK y)": 23950.58824,
    "out:Running Cost over RSP/MSEK": 6.3627,
    "out:LCP/MSEK": 0.054325,
    "out:ROI% old": 14.522619,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 46.8,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 425,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 171173.9839,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132352.78,
    "out:DH kr savings": 155768.3254,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 165012.2676,
    "out:% savings (space heating)": 46.823368,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.1,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3275875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.782142,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 108.799,
    "out:Total Energy Use Post PV": 108.799,
    "out:Primary Energy": 90.485699,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.623177,
    "out:CO2 Operational/m2": 34.424226,
    "out:Total CO2/m2": 48.047403,
    "out:Total CO2 (tons)": 143900.3589,
    "out:Klimatpaverkan":0.001* -20991.04582,
    "out:Initial Cost/MSEK": 3.337625,
    "out:Running cost/(Apt SEK y)": 23487.58824,
    "out:Running Cost over RSP/MSEK": 6.2395,
    "out:LCP/MSEK": 0.115775,
    "out:ROI% old": 14.781255,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 49.5,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 441,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175661.6166,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136436.53,
    "out:DH kr savings": 159852.0711,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 176050.5409,
    "out:% savings (space heating)": 43.266189,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 58.78,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3337625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.096533,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 104.799,
    "out:Total Energy Use Post PV": 104.799,
    "out:Primary Energy": 87.905773,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.623177,
    "out:CO2 Operational/m2": 32.98429,
    "out:Total CO2/m2": 46.607467,
    "out:Total CO2 (tons)": 139587.7989,
    "out:Klimatpaverkan":0.001* -25303.6058,
    "out:Initial Cost/MSEK": 3.4125,
    "out:Running cost/(Apt SEK y)": 22561.52941,
    "out:Running Cost over RSP/MSEK": 5.9931,
    "out:LCP/MSEK": 0.2873,
    "out:ROI% old": 15.488435,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 55.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 14,
    "out:Return/kSEK/y": 472,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 186699.8899,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146481.35,
    "out:DH kr savings": 169896.8998,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 165012.2676,
    "out:% savings (space heating)": 46.823368,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.1,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3412500,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.782142,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 113.799,
    "out:Total Energy Use Post PV": 108.185248,
    "out:Primary Energy": 82.480945,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.523454,
    "out:CO2 Operational/m2": 21.597321,
    "out:Total CO2/m2": 44.120775,
    "out:Total CO2 (tons)": 132140.2399,
    "out:Klimatpaverkan":0.001* -32751.16485,
    "out:Initial Cost/MSEK": 3.668875,
    "out:Running cost/(Apt SEK y)": 23492.26471,
    "out:Running Cost over RSP/MSEK": 6.24909,
    "out:LCP/MSEK": -0.225065,
    "out:ROI% old": 13.409365,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 50.9,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 12,
    "out:Return/kSEK/y": 441,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 160135.7107,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 161505.44,
    "out:DH kr savings": 145723.4967,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 176050.5409,
    "out:% savings (space heating)": 43.266189,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 58.78,
    "out:Etvv": 20,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3668875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.096533,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 110.799,
    "out:Total Energy Use Post PV": 105.185248,
    "out:Primary Energy": 79.901019,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.523454,
    "out:CO2 Operational/m2": 20.517369,
    "out:Total CO2/m2": 43.040823,
    "out:Total CO2 (tons)": 128905.8199,
    "out:Klimatpaverkan":0.001* -35985.58483,
    "out:Initial Cost/MSEK": 3.74375,
    "out:Running cost/(Apt SEK y)": 22797.73529,
    "out:Running Cost over RSP/MSEK": 6.06429,
    "out:LCP/MSEK": -0.11514,
    "out:ROI% old": 13.846353,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 55.2,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 12,
    "out:Return/kSEK/y": 464,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 171173.9839,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171550.27,
    "out:DH kr savings": 155768.3254,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 165012.2676,
    "out:% savings (space heating)": 46.823368,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.1,
    "out:Etvv": 20,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3743750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.782142,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 108.799,
    "out:Total Energy Use Post PV": 103.185248,
    "out:Primary Energy": 80.380945,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.900086,
    "out:CO2 Operational/m2": 19.797401,
    "out:Total CO2/m2": 42.697487,
    "out:Total CO2 (tons)": 127877.5401,
    "out:Klimatpaverkan":0.001* -37013.86463,
    "out:Initial Cost/MSEK": 3.805375,
    "out:Running cost/(Apt SEK y)": 22334.70588,
    "out:Running Cost over RSP/MSEK": 5.94109,
    "out:LCP/MSEK": -0.053565,
    "out:ROI% old": 14.084627,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 58.3,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 13,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 175661.6166,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175634.02,
    "out:DH kr savings": 159852.0711,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 176050.5409,
    "out:% savings (space heating)": 43.266189,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 58.78,
    "out:Etvv": 17,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3805375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.096533,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 104.799,
    "out:Total Energy Use Post PV": 99.185248,
    "out:Primary Energy": 77.801019,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.900086,
    "out:CO2 Operational/m2": 18.357465,
    "out:Total CO2/m2": 41.257551,
    "out:Total CO2 (tons)": 123564.9801,
    "out:Klimatpaverkan":0.001* -41326.4246,
    "out:Initial Cost/MSEK": 3.88025,
    "out:Running cost/(Apt SEK y)": 21408.67647,
    "out:Running Cost over RSP/MSEK": 5.69469,
    "out:LCP/MSEK": 0.11796,
    "out:ROI% old": 14.720001,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 64.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 13,
    "out:Return/kSEK/y": 512,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 186699.8899,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185678.85,
    "out:DH kr savings": 169896.8998,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 165012.2676,
    "out:% savings (space heating)": 46.823368,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.1,
    "out:Etvv": 17,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3880250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.782142,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 113.799,
    "out:Total Energy Use Post PV": 106.781176,
    "out:Primary Energy": 79.953616,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.800363,
    "out:CO2 Operational/m2": -14.084997,
    "out:Total CO2/m2": 17.715366,
    "out:Total CO2 (tons)": 53056.92641,
    "out:Klimatpaverkan":0.001* -111834.4783,
    "out:Initial Cost/MSEK": 4.136625,
    "out:Running cost/(Apt SEK y)": 22497,
    "out:Running Cost over RSP/MSEK": 5.99167,
    "out:LCP/MSEK": -0.435395,
    "out:ROI% old": 12.78209,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 52.3,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 11,
    "out:Return/kSEK/y": 475,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 160135.7107,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195344.6,
    "out:DH kr savings": 145723.4967,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 176050.5409,
    "out:% savings (space heating)": 43.266189,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 58.78,
    "out:Etvv": 20,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4136625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.096533,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 110.799,
    "out:Total Energy Use Post PV": 103.781176,
    "out:Primary Energy": 77.37369,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.800363,
    "out:CO2 Operational/m2": -15.164949,
    "out:Total CO2/m2": 16.635414,
    "out:Total CO2 (tons)": 49822.50643,
    "out:Klimatpaverkan":0.001* -115068.8983,
    "out:Initial Cost/MSEK": 4.2115,
    "out:Running cost/(Apt SEK y)": 21802.47059,
    "out:Running Cost over RSP/MSEK": 5.80687,
    "out:LCP/MSEK": -0.32547,
    "out:ROI% old": 13.181696,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 12,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 171173.9839,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205389.43,
    "out:DH kr savings": 155768.3254,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 165012.2676,
    "out:% savings (space heating)": 46.823368,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.1,
    "out:Etvv": 20,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4211500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.782142,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 108.799,
    "out:Total Energy Use Post PV": 101.781176,
    "out:Primary Energy": 77.853616,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.176995,
    "out:CO2 Operational/m2": -15.884917,
    "out:Total CO2/m2": 16.292078,
    "out:Total CO2 (tons)": 48794.22664,
    "out:Klimatpaverkan":0.001* -116097.1781,
    "out:Initial Cost/MSEK": 4.27325,
    "out:Running cost/(Apt SEK y)": 21339.44118,
    "out:Running Cost over RSP/MSEK": 5.68367,
    "out:LCP/MSEK": -0.26402,
    "out:ROI% old": 13.403081,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 12,
    "out:Return/kSEK/y": 514,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 175661.6166,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 209473.17,
    "out:DH kr savings": 159852.0711,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 176050.5409,
    "out:% savings (space heating)": 43.266189,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 58.78,
    "out:Etvv": 17,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4273250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.096533,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 104.799,
    "out:Total Energy Use Post PV": 97.781176,
    "out:Primary Energy": 75.27369,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.176995,
    "out:CO2 Operational/m2": -17.324853,
    "out:Total CO2/m2": 14.852142,
    "out:Total CO2 (tons)": 44481.66666,
    "out:Klimatpaverkan":0.001* -120409.7381,
    "out:Initial Cost/MSEK": 4.348125,
    "out:Running cost/(Apt SEK y)": 20413.38235,
    "out:Running Cost over RSP/MSEK": 5.43727,
    "out:LCP/MSEK": -0.092495,
    "out:ROI% old": 13.981823,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 13,
    "out:Return/kSEK/y": 546,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 186699.8899,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 219518,
    "out:DH kr savings": 169896.8998,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 165012.2676,
    "out:% savings (space heating)": 46.823368,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 55.1,
    "out:Etvv": 17,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4348125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.503018,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 115.799,
    "out:Total Energy Use Post PV": 115.799,
    "out:Primary Energy": 90.990313,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.03205,
    "out:CO2 Operational/m2": 36.944114,
    "out:Total CO2/m2": 52.976164,
    "out:Total CO2 (tons)": 158661.8326,
    "out:Klimatpaverkan":0.001* -6229.572098,
    "out:Initial Cost/MSEK": 3.71575,
    "out:Running cost/(Apt SEK y)": 25108.14706,
    "out:Running Cost over RSP/MSEK": 6.67071,
    "out:LCP/MSEK": -0.69356,
    "out:ROI% old": 11.619227,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 40.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 10,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 156059.9312,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118598.99,
    "out:DH kr savings": 142014.5374,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 154249.8104,
    "out:% savings (space heating)": 50.291663,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.5,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3715750,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z02000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.148279,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 111.799,
    "out:Total Energy Use Post PV": 111.799,
    "out:Primary Energy": 88.641995,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.03205,
    "out:CO2 Operational/m2": 35.504178,
    "out:Total CO2/m2": 51.536228,
    "out:Total CO2 (tons)": 154349.2726,
    "out:Klimatpaverkan":0.001* -10542.13208,
    "out:Initial Cost/MSEK": 3.790625,
    "out:Running cost/(Apt SEK y)": 24182.11765,
    "out:Running Cost over RSP/MSEK": 6.4243,
    "out:LCP/MSEK": -0.522025,
    "out:ROI% old": 12.318361,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 11,
    "out:Return/kSEK/y": 417,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166107.2622,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 127742.06,
    "out:DH kr savings": 151157.6086,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144202.4794,
    "out:% savings (space heating)": 53.529503,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.15,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3790625,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z02000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.503018,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 108.799,
    "out:Total Energy Use Post PV": 108.799,
    "out:Primary Energy": 88.365313,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.408682,
    "out:CO2 Operational/m2": 34.424226,
    "out:Total CO2/m2": 50.832908,
    "out:Total CO2 (tons)": 152242.8528,
    "out:Klimatpaverkan":0.001* -12648.55186,
    "out:Initial Cost/MSEK": 3.852375,
    "out:Running cost/(Apt SEK y)": 23487.58824,
    "out:Running Cost over RSP/MSEK": 6.2395,
    "out:LCP/MSEK": -0.398975,
    "out:ROI% old": 12.8062,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 49.5,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 11,
    "out:Return/kSEK/y": 441,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175467.3137,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136259.71,
    "out:DH kr savings": 159675.2554,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 154249.8104,
    "out:% savings (space heating)": 50.291663,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.5,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3852375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.148279,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 105.799,
    "out:Primary Energy": 86.016995,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.408682,
    "out:CO2 Operational/m2": 33.344274,
    "out:Total CO2/m2": 49.752956,
    "out:Total CO2 (tons)": 149008.4329,
    "out:Klimatpaverkan":0.001* -15882.97185,
    "out:Initial Cost/MSEK": 3.92725,
    "out:Running cost/(Apt SEK y)": 22793.02941,
    "out:Running Cost over RSP/MSEK": 6.0547,
    "out:LCP/MSEK": -0.28905,
    "out:ROI% old": 13.23427,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185514.6446,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145402.78,
    "out:DH kr savings": 168818.3266,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144202.4794,
    "out:% savings (space heating)": 53.529503,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.15,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3927250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.503018,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 115.799,
    "out:Total Energy Use Post PV": 110.185248,
    "out:Primary Energy": 80.885559,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.308959,
    "out:CO2 Operational/m2": 22.317289,
    "out:Total CO2/m2": 47.626248,
    "out:Total CO2 (tons)": 142639.0138,
    "out:Klimatpaverkan":0.001* -22252.39091,
    "out:Initial Cost/MSEK": 4.183625,
    "out:Running cost/(Apt SEK y)": 23955.29412,
    "out:Running Cost over RSP/MSEK": 6.3723,
    "out:LCP/MSEK": -0.863025,
    "out:ROI% old": 11.338766,
    "out:Payback discounted": 11,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 48.2,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 10,
    "out:Return/kSEK/y": 425,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 156059.9312,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157796.48,
    "out:DH kr savings": 142014.5374,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 154249.8104,
    "out:% savings (space heating)": 50.291663,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.5,
    "out:Etvv": 25,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4183625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.148279,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 111.799,
    "out:Total Energy Use Post PV": 106.185248,
    "out:Primary Energy": 78.537241,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.308959,
    "out:CO2 Operational/m2": 20.877353,
    "out:Total CO2/m2": 46.186312,
    "out:Total CO2 (tons)": 138326.4538,
    "out:Klimatpaverkan":0.001* -26564.95088,
    "out:Initial Cost/MSEK": 4.2585,
    "out:Running cost/(Apt SEK y)": 23029.23529,
    "out:Running Cost over RSP/MSEK": 6.12589,
    "out:LCP/MSEK": -0.69149,
    "out:ROI% old": 11.966018,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 11,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166107.2622,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 166939.56,
    "out:DH kr savings": 151157.6086,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144202.4794,
    "out:% savings (space heating)": 53.529503,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.15,
    "out:Etvv": 25,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4258500,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.503018,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 108.799,
    "out:Total Energy Use Post PV": 103.185248,
    "out:Primary Energy": 78.260559,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.685591,
    "out:CO2 Operational/m2": 19.797401,
    "out:Total CO2/m2": 45.482992,
    "out:Total CO2 (tons)": 136220.034,
    "out:Klimatpaverkan":0.001* -28671.37067,
    "out:Initial Cost/MSEK": 4.32025,
    "out:Running cost/(Apt SEK y)": 22334.70588,
    "out:Running Cost over RSP/MSEK": 5.94109,
    "out:LCP/MSEK": -0.56844,
    "out:ROI% old": 12.406061,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 58.3,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 11,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175467.3137,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175457.2,
    "out:DH kr savings": 159675.2554,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 154249.8104,
    "out:% savings (space heating)": 50.291663,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.5,
    "out:Etvv": 21.25,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4320250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.148279,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 100.185248,
    "out:Primary Energy": 75.912241,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.685591,
    "out:CO2 Operational/m2": 18.717449,
    "out:Total CO2/m2": 44.40304,
    "out:Total CO2 (tons)": 132985.6141,
    "out:Klimatpaverkan":0.001* -31905.79065,
    "out:Initial Cost/MSEK": 4.395125,
    "out:Running cost/(Apt SEK y)": 21640.17647,
    "out:Running Cost over RSP/MSEK": 5.75629,
    "out:LCP/MSEK": -0.458515,
    "out:ROI% old": 12.795378,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 11,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185514.6446,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184600.27,
    "out:DH kr savings": 168818.3266,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144202.4794,
    "out:% savings (space heating)": 53.529503,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.15,
    "out:Etvv": 21.25,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4395125,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.503018,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 115.799,
    "out:Total Energy Use Post PV": 108.781176,
    "out:Primary Energy": 78.35823,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.585868,
    "out:CO2 Operational/m2": -13.365029,
    "out:Total CO2/m2": 21.220839,
    "out:Total CO2 (tons)": 63555.70036,
    "out:Klimatpaverkan":0.001* -101335.7044,
    "out:Initial Cost/MSEK": 4.651375,
    "out:Running cost/(Apt SEK y)": 22960,
    "out:Running Cost over RSP/MSEK": 6.11487,
    "out:LCP/MSEK": -1.073345,
    "out:ROI% old": 10.989162,
    "out:Payback discounted": 11,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 49.5,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 10,
    "out:Return/kSEK/y": 459,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 156059.9312,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191635.64,
    "out:DH kr savings": 142014.5374,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 154249.8104,
    "out:% savings (space heating)": 50.291663,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.5,
    "out:Etvv": 25,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4651375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.148279,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 111.799,
    "out:Total Energy Use Post PV": 104.781176,
    "out:Primary Energy": 76.009912,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.585868,
    "out:CO2 Operational/m2": -14.804965,
    "out:Total CO2/m2": 19.780903,
    "out:Total CO2 (tons)": 59243.14038,
    "out:Klimatpaverkan":0.001* -105648.2643,
    "out:Initial Cost/MSEK": 4.72625,
    "out:Running cost/(Apt SEK y)": 22033.97059,
    "out:Running Cost over RSP/MSEK": 5.86847,
    "out:LCP/MSEK": -0.90182,
    "out:ROI% old": 11.559844,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 55.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 10,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166107.2622,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200778.71,
    "out:DH kr savings": 151157.6086,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144202.4794,
    "out:% savings (space heating)": 53.529503,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.15,
    "out:Etvv": 25,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4726250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.503018,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 108.799,
    "out:Total Energy Use Post PV": 101.781176,
    "out:Primary Energy": 75.73323,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.9625,
    "out:CO2 Operational/m2": -15.884917,
    "out:Total CO2/m2": 19.077583,
    "out:Total CO2 (tons)": 57136.72059,
    "out:Klimatpaverkan":0.001* -107754.6841,
    "out:Initial Cost/MSEK": 4.788,
    "out:Running cost/(Apt SEK y)": 21339.44118,
    "out:Running Cost over RSP/MSEK": 5.68367,
    "out:LCP/MSEK": -0.77877,
    "out:ROI% old": 11.962137,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 11,
    "out:Return/kSEK/y": 514,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175467.3137,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 209296.36,
    "out:DH kr savings": 159675.2554,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 154249.8104,
    "out:% savings (space heating)": 50.291663,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.5,
    "out:Etvv": 21.25,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4788000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.148279,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 98.781176,
    "out:Primary Energy": 73.384912,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.9625,
    "out:CO2 Operational/m2": -16.964869,
    "out:Total CO2/m2": 17.997631,
    "out:Total CO2 (tons)": 53902.30061,
    "out:Klimatpaverkan":0.001* -110989.1041,
    "out:Initial Cost/MSEK": 4.862875,
    "out:Running cost/(Apt SEK y)": 20644.91177,
    "out:Running Cost over RSP/MSEK": 5.49887,
    "out:LCP/MSEK": -0.668845,
    "out:ROI% old": 12.320842,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 64.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 11,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185514.6446,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 218439.43,
    "out:DH kr savings": 168818.3266,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144202.4794,
    "out:% savings (space heating)": 53.529503,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.15,
    "out:Etvv": 21.25,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4862875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.503018,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 106.799,
    "out:Total Energy Use Post PV": 106.799,
    "out:Primary Energy": 87.490313,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.03205,
    "out:CO2 Operational/m2": 33.704258,
    "out:Total CO2/m2": 49.736308,
    "out:Total CO2 (tons)": 148958.5727,
    "out:Klimatpaverkan":0.001* -15932.83205,
    "out:Initial Cost/MSEK": 3.8055,
    "out:Running cost/(Apt SEK y)": 23024.55882,
    "out:Running Cost over RSP/MSEK": 6.1163,
    "out:LCP/MSEK": -0.2289,
    "out:ROI% old": 13.426432,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 52.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 181936.4412,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142146.62,
    "out:DH kr savings": 165562.1614,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 154249.8104,
    "out:% savings (space heating)": 50.291663,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.5,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3805500,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.148279,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 103.799,
    "out:Total Energy Use Post PV": 103.799,
    "out:Primary Energy": 85.141995,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.03205,
    "out:CO2 Operational/m2": 32.624306,
    "out:Total CO2/m2": 48.656356,
    "out:Total CO2 (tons)": 145724.1527,
    "out:Klimatpaverkan":0.001* -19167.25203,
    "out:Initial Cost/MSEK": 3.880375,
    "out:Running cost/(Apt SEK y)": 22330.02941,
    "out:Running Cost over RSP/MSEK": 5.9315,
    "out:LCP/MSEK": -0.118975,
    "out:ROI% old": 13.847704,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 191983.7721,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151289.69,
    "out:DH kr savings": 174705.2326,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144202.4794,
    "out:% savings (space heating)": 53.529503,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.15,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3880375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.503018,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 101.799,
    "out:Total Energy Use Post PV": 101.799,
    "out:Primary Energy": 85.390313,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.408682,
    "out:CO2 Operational/m2": 31.904338,
    "out:Total CO2/m2": 48.31302,
    "out:Total CO2 (tons)": 144695.8729,
    "out:Klimatpaverkan":0.001* -20195.53182,
    "out:Initial Cost/MSEK": 3.942125,
    "out:Running cost/(Apt SEK y)": 21867,
    "out:Running Cost over RSP/MSEK": 5.8083,
    "out:LCP/MSEK": -0.057525,
    "out:ROI% old": 14.077252,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 496,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 197462.3471,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156275.19,
    "out:DH kr savings": 179690.7359,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 154249.8104,
    "out:% savings (space heating)": 50.291663,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.5,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3942125,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.148279,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 98.799,
    "out:Total Energy Use Post PV": 98.799,
    "out:Primary Energy": 83.041995,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.408682,
    "out:CO2 Operational/m2": 30.824386,
    "out:Total CO2/m2": 47.233068,
    "out:Total CO2 (tons)": 141461.4529,
    "out:Klimatpaverkan":0.001* -23429.95181,
    "out:Initial Cost/MSEK": 4.017,
    "out:Running cost/(Apt SEK y)": 21172.47059,
    "out:Running Cost over RSP/MSEK": 5.6235,
    "out:LCP/MSEK": 0.0524,
    "out:ROI% old": 14.472065,
    "out:Payback discounted": 8,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 64.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 207509.6781,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165418.26,
    "out:DH kr savings": 188833.8071,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144202.4794,
    "out:% savings (space heating)": 53.529503,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.15,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4017000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.503018,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 106.799,
    "out:Total Energy Use Post PV": 101.185248,
    "out:Primary Energy": 77.385559,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.308959,
    "out:CO2 Operational/m2": 19.077433,
    "out:Total CO2/m2": 44.386392,
    "out:Total CO2 (tons)": 132935.7539,
    "out:Klimatpaverkan":0.001* -31955.65085,
    "out:Initial Cost/MSEK": 4.273375,
    "out:Running cost/(Apt SEK y)": 21871.67647,
    "out:Running Cost over RSP/MSEK": 5.81789,
    "out:LCP/MSEK": -0.398365,
    "out:ROI% old": 12.953997,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 61.4,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 12,
    "out:Return/kSEK/y": 496,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 181936.4412,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181344.11,
    "out:DH kr savings": 165562.1614,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 154249.8104,
    "out:% savings (space heating)": 50.291663,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.5,
    "out:Etvv": 20,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4273375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.148279,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 103.799,
    "out:Total Energy Use Post PV": 98.185248,
    "out:Primary Energy": 75.037241,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.308959,
    "out:CO2 Operational/m2": 17.997481,
    "out:Total CO2/m2": 43.30644,
    "out:Total CO2 (tons)": 129701.3339,
    "out:Klimatpaverkan":0.001* -35190.07084,
    "out:Initial Cost/MSEK": 4.34825,
    "out:Running cost/(Apt SEK y)": 21177.14706,
    "out:Running Cost over RSP/MSEK": 5.63309,
    "out:LCP/MSEK": -0.28844,
    "out:ROI% old": 13.338075,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 12,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 191983.7721,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190487.18,
    "out:DH kr savings": 174705.2326,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144202.4794,
    "out:% savings (space heating)": 53.529503,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.15,
    "out:Etvv": 20,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4348250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.503018,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 101.799,
    "out:Total Energy Use Post PV": 96.185248,
    "out:Primary Energy": 75.285559,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.685591,
    "out:CO2 Operational/m2": 17.277513,
    "out:Total CO2/m2": 42.963104,
    "out:Total CO2 (tons)": 128673.0541,
    "out:Klimatpaverkan":0.001* -36218.35063,
    "out:Initial Cost/MSEK": 4.409875,
    "out:Running cost/(Apt SEK y)": 20714.11765,
    "out:Running Cost over RSP/MSEK": 5.50989,
    "out:LCP/MSEK": -0.226865,
    "out:ROI% old": 13.550789,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 69.8,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 12,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 197462.3471,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195472.68,
    "out:DH kr savings": 179690.7359,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 154249.8104,
    "out:% savings (space heating)": 50.291663,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.5,
    "out:Etvv": 17,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4409875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.148279,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 98.799,
    "out:Total Energy Use Post PV": 93.185248,
    "out:Primary Energy": 72.937241,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 25.685591,
    "out:CO2 Operational/m2": 16.197561,
    "out:Total CO2/m2": 41.883152,
    "out:Total CO2 (tons)": 125438.6341,
    "out:Klimatpaverkan":0.001* -39452.77061,
    "out:Initial Cost/MSEK": 4.48475,
    "out:Running cost/(Apt SEK y)": 20019.58824,
    "out:Running Cost over RSP/MSEK": 5.32509,
    "out:LCP/MSEK": -0.11694,
    "out:ROI% old": 13.913214,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 75.3,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 12,
    "out:Return/kSEK/y": 559,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 207509.6781,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204615.75,
    "out:DH kr savings": 188833.8071,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144202.4794,
    "out:% savings (space heating)": 53.529503,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.15,
    "out:Etvv": 17,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4484750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.503018,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 106.799,
    "out:Total Energy Use Post PV": 99.781176,
    "out:Primary Energy": 74.85823,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.585868,
    "out:CO2 Operational/m2": -16.604885,
    "out:Total CO2/m2": 17.980983,
    "out:Total CO2 (tons)": 53852.44041,
    "out:Klimatpaverkan":0.001* -111038.9643,
    "out:Initial Cost/MSEK": 4.741125,
    "out:Running cost/(Apt SEK y)": 20876.41177,
    "out:Running Cost over RSP/MSEK": 5.56047,
    "out:LCP/MSEK": -0.608695,
    "out:ROI% old": 12.451626,
    "out:Payback discounted": 10,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 11,
    "out:Return/kSEK/y": 530,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 181936.4412,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215183.26,
    "out:DH kr savings": 165562.1614,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 154249.8104,
    "out:% savings (space heating)": 50.291663,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.5,
    "out:Etvv": 20,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4741125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.148279,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 103.799,
    "out:Total Energy Use Post PV": 96.781176,
    "out:Primary Energy": 72.509912,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.585868,
    "out:CO2 Operational/m2": -17.684837,
    "out:Total CO2/m2": 16.901031,
    "out:Total CO2 (tons)": 50618.02043,
    "out:Klimatpaverkan":0.001* -114273.3843,
    "out:Initial Cost/MSEK": 4.816,
    "out:Running cost/(Apt SEK y)": 20181.88235,
    "out:Running Cost over RSP/MSEK": 5.37567,
    "out:LCP/MSEK": -0.49877,
    "out:ROI% old": 12.806211,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 68,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 11,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 191983.7721,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 224326.33,
    "out:DH kr savings": 174705.2326,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144202.4794,
    "out:% savings (space heating)": 53.529503,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.15,
    "out:Etvv": 20,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4816000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.503018,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 101.799,
    "out:Total Energy Use Post PV": 94.781176,
    "out:Primary Energy": 72.75823,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.9625,
    "out:CO2 Operational/m2": -18.404805,
    "out:Total CO2/m2": 16.557695,
    "out:Total CO2 (tons)": 49589.74063,
    "out:Klimatpaverkan":0.001* -115301.6641,
    "out:Initial Cost/MSEK": 4.87775,
    "out:Running cost/(Apt SEK y)": 19718.85294,
    "out:Running Cost over RSP/MSEK": 5.25247,
    "out:LCP/MSEK": -0.43732,
    "out:ROI% old": 13.004913,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 12,
    "out:Return/kSEK/y": 569,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 197462.3471,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 229311.84,
    "out:DH kr savings": 179690.7359,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 154249.8104,
    "out:% savings (space heating)": 50.291663,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 51.5,
    "out:Etvv": 17,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4877750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.148279,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 98.799,
    "out:Total Energy Use Post PV": 91.781176,
    "out:Primary Energy": 70.409912,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.9625,
    "out:CO2 Operational/m2": -19.484757,
    "out:Total CO2/m2": 15.477743,
    "out:Total CO2 (tons)": 46355.32065,
    "out:Klimatpaverkan":0.001* -118536.0841,
    "out:Initial Cost/MSEK": 4.952625,
    "out:Running cost/(Apt SEK y)": 19024.32353,
    "out:Running Cost over RSP/MSEK": 5.06766,
    "out:LCP/MSEK": -0.327385,
    "out:ROI% old": 13.341381,
    "out:Payback discounted": 9,
    "out:Atemp": 2995,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 77.2,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 12,
    "out:Return/kSEK/y": 593,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 207509.6781,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 238454.91,
    "out:DH kr savings": 188833.8071,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144202.4794,
    "out:% savings (space heating)": 53.529503,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.15,
    "out:Etvv": 17,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4952625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z00000000000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.155554,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 111.799,
    "out:Total Energy Use Post PV": 111.799,
    "out:Primary Energy": 88.647088,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.16569,
    "out:CO2 Operational/m2": 35.504178,
    "out:Total CO2/m2": 74.669868,
    "out:Total CO2 (tons)": 223633.7478,
    "out:Klimatpaverkan":0.001* 58742.34306,
    "out:Initial Cost/MSEK": 6.42825,
    "out:Running cost/(Apt SEK y)": 24182.11765,
    "out:Running Cost over RSP/MSEK": 6.4243,
    "out:LCP/MSEK": -3.15965,
    "out:ROI% old": 7.263919,
    "out:Payback discounted": 17,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 6,
    "out:Return/kSEK/y": 417,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 166085.4732,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127722.23,
    "out:DH kr savings": 151137.7806,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144224.2684,
    "out:% savings (space heating)": 53.522481,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.16,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6428250,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.952973,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 108.799,
    "out:Total Energy Use Post PV": 108.799,
    "out:Primary Energy": 86.405281,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.16569,
    "out:CO2 Operational/m2": 34.424226,
    "out:Total CO2/m2": 73.589916,
    "out:Total CO2 (tons)": 220399.3278,
    "out:Klimatpaverkan":0.001* 55507.92308,
    "out:Initial Cost/MSEK": 6.503125,
    "out:Running cost/(Apt SEK y)": 23487.58824,
    "out:Running Cost over RSP/MSEK": 6.2395,
    "out:LCP/MSEK": -3.049725,
    "out:ROI% old": 7.586243,
    "out:Payback discounted": 16,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 49.5,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 441,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 175677.0977,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 136450.61,
    "out:DH kr savings": 159866.1589,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 134632.6439,
    "out:% savings (space heating)": 56.613465,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 44.95,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6503125,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.155554,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 105.799,
    "out:Primary Energy": 86.022088,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.542322,
    "out:CO2 Operational/m2": 33.344274,
    "out:Total CO2/m2": 72.886596,
    "out:Total CO2 (tons)": 218292.908,
    "out:Klimatpaverkan":0.001* 53401.50329,
    "out:Initial Cost/MSEK": 6.564875,
    "out:Running cost/(Apt SEK y)": 22793.02941,
    "out:Running Cost over RSP/MSEK": 6.0547,
    "out:LCP/MSEK": -2.926675,
    "out:ROI% old": 7.917026,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 185492.8557,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145382.95,
    "out:DH kr savings": 168798.4987,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144224.2684,
    "out:% savings (space heating)": 53.522481,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.16,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6564875,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.952973,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 102.799,
    "out:Total Energy Use Post PV": 102.799,
    "out:Primary Energy": 83.780281,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.542322,
    "out:CO2 Operational/m2": 32.264322,
    "out:Total CO2/m2": 71.806644,
    "out:Total CO2 (tons)": 215058.488,
    "out:Klimatpaverkan":0.001* 50167.08331,
    "out:Initial Cost/MSEK": 6.63975,
    "out:Running cost/(Apt SEK y)": 22098.5,
    "out:Running Cost over RSP/MSEK": 5.8699,
    "out:LCP/MSEK": -2.81675,
    "out:ROI% old": 8.225353,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 58.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 195084.4802,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154111.33,
    "out:DH kr savings": 177526.877,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 134632.6439,
    "out:% savings (space heating)": 56.613465,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 44.95,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6639750,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.155554,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 111.799,
    "out:Total Energy Use Post PV": 106.185248,
    "out:Primary Energy": 78.542334,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.442599,
    "out:CO2 Operational/m2": 20.877353,
    "out:Total CO2/m2": 69.319952,
    "out:Total CO2 (tons)": 207610.929,
    "out:Klimatpaverkan":0.001* 42719.52425,
    "out:Initial Cost/MSEK": 6.896125,
    "out:Running cost/(Apt SEK y)": 23029.23529,
    "out:Running Cost over RSP/MSEK": 6.12589,
    "out:LCP/MSEK": -3.329115,
    "out:ROI% old": 7.389264,
    "out:Payback discounted": 16,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 53.8,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 7,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 166085.4732,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166919.73,
    "out:DH kr savings": 151137.7806,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144224.2684,
    "out:% savings (space heating)": 53.522481,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.16,
    "out:Etvv": 25,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6896125,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.952973,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 108.799,
    "out:Total Energy Use Post PV": 103.185248,
    "out:Primary Energy": 76.300527,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.442599,
    "out:CO2 Operational/m2": 19.797401,
    "out:Total CO2/m2": 68.24,
    "out:Total CO2 (tons)": 204376.509,
    "out:Klimatpaverkan":0.001* 39485.10427,
    "out:Initial Cost/MSEK": 6.971,
    "out:Running cost/(Apt SEK y)": 22334.70588,
    "out:Running Cost over RSP/MSEK": 5.94109,
    "out:LCP/MSEK": -3.21919,
    "out:ROI% old": 7.688608,
    "out:Payback discounted": 16,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 58.3,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 7,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175677.0977,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175648.11,
    "out:DH kr savings": 159866.1589,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 134632.6439,
    "out:% savings (space heating)": 56.613465,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 44.95,
    "out:Etvv": 25,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6971000,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.155554,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 100.185248,
    "out:Primary Energy": 75.917334,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.819231,
    "out:CO2 Operational/m2": 18.717449,
    "out:Total CO2/m2": 67.53668,
    "out:Total CO2 (tons)": 202270.0892,
    "out:Klimatpaverkan":0.001* 37378.68448,
    "out:Initial Cost/MSEK": 7.03275,
    "out:Running cost/(Apt SEK y)": 21640.17647,
    "out:Running Cost over RSP/MSEK": 5.75629,
    "out:LCP/MSEK": -3.09614,
    "out:ROI% old": 7.996486,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 7,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185492.8557,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184580.45,
    "out:DH kr savings": 168798.4987,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144224.2684,
    "out:% savings (space heating)": 53.522481,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.16,
    "out:Etvv": 21.25,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7032750,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.952973,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 102.799,
    "out:Total Energy Use Post PV": 97.185248,
    "out:Primary Energy": 73.675527,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.819231,
    "out:CO2 Operational/m2": 17.637497,
    "out:Total CO2/m2": 66.456728,
    "out:Total CO2 (tons)": 199035.6692,
    "out:Klimatpaverkan":0.001* 34144.2645,
    "out:Initial Cost/MSEK": 7.107625,
    "out:Running cost/(Apt SEK y)": 20945.64706,
    "out:Running Cost over RSP/MSEK": 5.57149,
    "out:LCP/MSEK": -2.986215,
    "out:ROI% old": 8.283679,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 68,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 7,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 195084.4802,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 193308.82,
    "out:DH kr savings": 177526.877,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 134632.6439,
    "out:% savings (space heating)": 56.613465,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 44.95,
    "out:Etvv": 21.25,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7107625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.155554,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 111.799,
    "out:Total Energy Use Post PV": 104.781176,
    "out:Primary Energy": 76.015005,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 57.719508,
    "out:CO2 Operational/m2": -14.804965,
    "out:Total CO2/m2": 42.914543,
    "out:Total CO2 (tons)": 128527.6155,
    "out:Klimatpaverkan":0.001* -36363.78919,
    "out:Initial Cost/MSEK": 7.363875,
    "out:Running cost/(Apt SEK y)": 22033.97059,
    "out:Running Cost over RSP/MSEK": 5.86847,
    "out:LCP/MSEK": -3.539445,
    "out:ROI% old": 7.419289,
    "out:Payback discounted": 16,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 55.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 7,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166085.4732,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 200758.88,
    "out:DH kr savings": 151137.7806,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144224.2684,
    "out:% savings (space heating)": 53.522481,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.16,
    "out:Etvv": 25,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7363875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.952973,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 108.799,
    "out:Total Energy Use Post PV": 101.781176,
    "out:Primary Energy": 73.773198,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 57.719508,
    "out:CO2 Operational/m2": -15.884917,
    "out:Total CO2/m2": 41.834591,
    "out:Total CO2 (tons)": 125293.1955,
    "out:Klimatpaverkan":0.001* -39598.20918,
    "out:Initial Cost/MSEK": 7.43875,
    "out:Running cost/(Apt SEK y)": 21339.44118,
    "out:Running Cost over RSP/MSEK": 5.68367,
    "out:LCP/MSEK": -3.42952,
    "out:ROI% old": 7.699508,
    "out:Payback discounted": 16,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 7,
    "out:Return/kSEK/y": 514,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175677.0977,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209487.26,
    "out:DH kr savings": 159866.1589,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 134632.6439,
    "out:% savings (space heating)": 56.613465,
    "out:Total_Water Heating (DH)": 129382.5496,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 44.95,
    "out:Etvv": 25,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7438750,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.155554,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 98.781176,
    "out:Primary Energy": 73.390005,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 58.09614,
    "out:CO2 Operational/m2": -16.964869,
    "out:Total CO2/m2": 41.131271,
    "out:Total CO2 (tons)": 123186.7757,
    "out:Klimatpaverkan":0.001* -41704.62896,
    "out:Initial Cost/MSEK": 7.5005,
    "out:Running cost/(Apt SEK y)": 20644.91177,
    "out:Running Cost over RSP/MSEK": 5.49887,
    "out:LCP/MSEK": -3.30647,
    "out:ROI% old": 7.988096,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 64.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 7,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185492.8557,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 218419.6,
    "out:DH kr savings": 168798.4987,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144224.2684,
    "out:% savings (space heating)": 53.522481,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.16,
    "out:Etvv": 21.25,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7500500,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.952973,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 102.799,
    "out:Total Energy Use Post PV": 95.781176,
    "out:Primary Energy": 71.148198,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 58.09614,
    "out:CO2 Operational/m2": -18.044821,
    "out:Total CO2/m2": 40.051319,
    "out:Total CO2 (tons)": 119952.3558,
    "out:Klimatpaverkan":0.001* -44939.04895,
    "out:Initial Cost/MSEK": 7.575375,
    "out:Running cost/(Apt SEK y)": 19950.38235,
    "out:Running Cost over RSP/MSEK": 5.31407,
    "out:LCP/MSEK": -3.196545,
    "out:ROI% old": 8.257639,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 69.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 7,
    "out:Return/kSEK/y": 561,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 195084.4802,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227147.98,
    "out:DH kr savings": 177526.877,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 134632.6439,
    "out:% savings (space heating)": 56.613465,
    "out:Total_Water Heating (DH)": 109975.1672,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 44.95,
    "out:Etvv": 21.25,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7575375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.155554,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 103.799,
    "out:Total Energy Use Post PV": 103.799,
    "out:Primary Energy": 85.147088,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.16569,
    "out:CO2 Operational/m2": 32.624306,
    "out:Total CO2/m2": 71.789996,
    "out:Total CO2 (tons)": 215008.6278,
    "out:Klimatpaverkan":0.001* 50117.22311,
    "out:Initial Cost/MSEK": 6.518,
    "out:Running cost/(Apt SEK y)": 22330.02941,
    "out:Running Cost over RSP/MSEK": 5.9315,
    "out:LCP/MSEK": -2.7566,
    "out:ROI% old": 8.243984,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 191961.9831,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151269.86,
    "out:DH kr savings": 174685.4047,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144224.2684,
    "out:% savings (space heating)": 53.522481,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.16,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6518000,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.952973,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 100.799,
    "out:Total Energy Use Post PV": 100.799,
    "out:Primary Energy": 82.905281,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.16569,
    "out:CO2 Operational/m2": 31.544354,
    "out:Total CO2/m2": 70.710044,
    "out:Total CO2 (tons)": 211774.2078,
    "out:Klimatpaverkan":0.001* 46882.80312,
    "out:Initial Cost/MSEK": 6.592875,
    "out:Running cost/(Apt SEK y)": 21635.5,
    "out:Running Cost over RSP/MSEK": 5.7467,
    "out:LCP/MSEK": -2.646675,
    "out:ROI% old": 8.550789,
    "out:Payback discounted": 14,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 61.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 201553.6077,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159998.24,
    "out:DH kr savings": 183413.783,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 134632.6439,
    "out:% savings (space heating)": 56.613465,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 44.95,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6592875,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.155554,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 98.799,
    "out:Total Energy Use Post PV": 98.799,
    "out:Primary Energy": 83.047088,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.542322,
    "out:CO2 Operational/m2": 30.824386,
    "out:Total CO2/m2": 70.366708,
    "out:Total CO2 (tons)": 210745.928,
    "out:Klimatpaverkan":0.001* 45854.52333,
    "out:Initial Cost/MSEK": 6.654625,
    "out:Running cost/(Apt SEK y)": 21172.47059,
    "out:Running Cost over RSP/MSEK": 5.6235,
    "out:LCP/MSEK": -2.585225,
    "out:ROI% old": 8.735922,
    "out:Payback discounted": 14,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 64.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 207487.8891,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165398.43,
    "out:DH kr savings": 188813.9791,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144224.2684,
    "out:% savings (space heating)": 53.522481,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.16,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6654625,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.952973,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 94.799,
    "out:Total Energy Use Post PV": 94.799,
    "out:Primary Energy": 80.805281,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.542322,
    "out:CO2 Operational/m2": 29.38445,
    "out:Total CO2/m2": 68.926772,
    "out:Total CO2 (tons)": 206433.3681,
    "out:Klimatpaverkan":0.001* 41541.96335,
    "out:Initial Cost/MSEK": 6.7295,
    "out:Running cost/(Apt SEK y)": 20246.41177,
    "out:Running Cost over RSP/MSEK": 5.3771,
    "out:LCP/MSEK": -2.4137,
    "out:ROI% old": 9.161793,
    "out:Payback discounted": 13,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 217079.5136,
    "out:EL kWh savings": -13773.8506,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174126.81,
    "out:DH kr savings": 197542.3574,
    "out:El kr savings": -23415.54602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 134632.6439,
    "out:% savings (space heating)": 56.613465,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 44.95,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6729500,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.155554,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 103.799,
    "out:Total Energy Use Post PV": 98.185248,
    "out:Primary Energy": 75.042334,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.442599,
    "out:CO2 Operational/m2": 17.997481,
    "out:Total CO2/m2": 66.44008,
    "out:Total CO2 (tons)": 198985.809,
    "out:Klimatpaverkan":0.001* 34094.4043,
    "out:Initial Cost/MSEK": 6.985875,
    "out:Running cost/(Apt SEK y)": 21177.14706,
    "out:Running Cost over RSP/MSEK": 5.63309,
    "out:LCP/MSEK": -2.926065,
    "out:ROI% old": 8.302079,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 66.3,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 7,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 191961.9831,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 190467.35,
    "out:DH kr savings": 174685.4047,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144224.2684,
    "out:% savings (space heating)": 53.522481,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.16,
    "out:Etvv": 20,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6985875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.952973,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 100.799,
    "out:Total Energy Use Post PV": 95.185248,
    "out:Primary Energy": 72.800527,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.442599,
    "out:CO2 Operational/m2": 16.917529,
    "out:Total CO2/m2": 65.360128,
    "out:Total CO2 (tons)": 195751.389,
    "out:Klimatpaverkan":0.001* 30859.98432,
    "out:Initial Cost/MSEK": 7.06075,
    "out:Running cost/(Apt SEK y)": 20482.61765,
    "out:Running Cost over RSP/MSEK": 5.44829,
    "out:LCP/MSEK": -2.81614,
    "out:ROI% old": 8.587938,
    "out:Payback discounted": 14,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 8,
    "out:Return/kSEK/y": 543,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 201553.6077,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199195.73,
    "out:DH kr savings": 183413.783,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 134632.6439,
    "out:% savings (space heating)": 56.613465,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 44.95,
    "out:Etvv": 20,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7060750,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.155554,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 98.799,
    "out:Total Energy Use Post PV": 93.185248,
    "out:Primary Energy": 72.942334,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.819231,
    "out:CO2 Operational/m2": 16.197561,
    "out:Total CO2/m2": 65.016792,
    "out:Total CO2 (tons)": 194723.1092,
    "out:Klimatpaverkan":0.001* 29831.70452,
    "out:Initial Cost/MSEK": 7.122375,
    "out:Running cost/(Apt SEK y)": 20019.58824,
    "out:Running Cost over RSP/MSEK": 5.32509,
    "out:LCP/MSEK": -2.754565,
    "out:ROI% old": 8.760741,
    "out:Payback discounted": 14,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 75.3,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 8,
    "out:Return/kSEK/y": 559,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 207487.8891,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 204595.93,
    "out:DH kr savings": 188813.9791,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144224.2684,
    "out:% savings (space heating)": 53.522481,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.16,
    "out:Etvv": 17,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7122375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.952973,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.185248,
    "out:Total Energy Use Pre PV": 94.799,
    "out:Total Energy Use Post PV": 89.185248,
    "out:Primary Energy": 70.700527,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.819231,
    "out:CO2 Operational/m2": 14.757625,
    "out:Total CO2/m2": 63.576856,
    "out:Total CO2 (tons)": 190410.5493,
    "out:Klimatpaverkan":0.001* 25519.14455,
    "out:Initial Cost/MSEK": 7.19725,
    "out:Running cost/(Apt SEK y)": 19093.55882,
    "out:Running Cost over RSP/MSEK": 5.07869,
    "out:LCP/MSEK": -2.58304,
    "out:ROI% old": 9.158677,
    "out:Payback discounted": 13,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 83.1,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 1073.411686,
    "out:Return %": 8,
    "out:Return/kSEK/y": 590,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 26.792701,
    "out:PV (m2 panels)": 140.570312,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25138.79757,
    "out:PV (kWh sold)": 8325.799858,
    "out:PV (% sold (El))": 33.119324,
    "out:PV (kWh self-consumed)": 16812.99771,
    "out:PV (ratio sold/self-consumed)": 0.4952,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 217079.5136,
    "out:EL kWh savings": 3039.148172,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 213324.3,
    "out:DH kr savings": 197542.3574,
    "out:El kr savings": 5166.551892,
    "out:El kr sold": 10615.39482,
    "out:El kr saved": 28582.0961,
    "out:El kr return": 39197.49092,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 134632.6439,
    "out:% savings (space heating)": 56.613465,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 44.95,
    "out:Etvv": 17,
    "out:Ef": 15.19,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7197250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.155554,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 103.799,
    "out:Total Energy Use Post PV": 96.781176,
    "out:Primary Energy": 72.515005,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 57.719508,
    "out:CO2 Operational/m2": -17.684837,
    "out:Total CO2/m2": 40.034671,
    "out:Total CO2 (tons)": 119902.4956,
    "out:Klimatpaverkan":0.001* -44988.90915,
    "out:Initial Cost/MSEK": 7.453625,
    "out:Running cost/(Apt SEK y)": 20181.88235,
    "out:Running Cost over RSP/MSEK": 5.37567,
    "out:LCP/MSEK": -3.136395,
    "out:ROI% old": 8.274459,
    "out:Payback discounted": 15,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 68,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 7,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 191961.9831,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 224306.51,
    "out:DH kr savings": 174685.4047,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144224.2684,
    "out:% savings (space heating)": 53.522481,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.16,
    "out:Etvv": 20,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7453625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.952973,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 100.799,
    "out:Total Energy Use Post PV": 93.781176,
    "out:Primary Energy": 70.273198,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 57.719508,
    "out:CO2 Operational/m2": -18.764789,
    "out:Total CO2/m2": 38.954719,
    "out:Total CO2 (tons)": 116668.0756,
    "out:Klimatpaverkan":0.001* -48223.32913,
    "out:Initial Cost/MSEK": 7.5285,
    "out:Running cost/(Apt SEK y)": 19487.35294,
    "out:Running Cost over RSP/MSEK": 5.19086,
    "out:LCP/MSEK": -3.02646,
    "out:ROI% old": 8.542851,
    "out:Payback discounted": 14,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 73.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 8,
    "out:Return/kSEK/y": 577,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 201553.6077,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233034.88,
    "out:DH kr savings": 183413.783,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 134632.6439,
    "out:% savings (space heating)": 56.613465,
    "out:Total_Water Heating (DH)": 103506.0397,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 44.95,
    "out:Etvv": 20,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7528500,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.155554,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 98.799,
    "out:Total Energy Use Post PV": 91.781176,
    "out:Primary Energy": 70.415005,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 58.09614,
    "out:CO2 Operational/m2": -19.484757,
    "out:Total CO2/m2": 38.611383,
    "out:Total CO2 (tons)": 115639.7958,
    "out:Klimatpaverkan":0.001* -49251.60892,
    "out:Initial Cost/MSEK": 7.59025,
    "out:Running cost/(Apt SEK y)": 19024.32353,
    "out:Running Cost over RSP/MSEK": 5.06766,
    "out:LCP/MSEK": -2.96501,
    "out:ROI% old": 8.705228,
    "out:Payback discounted": 14,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 77.2,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 8,
    "out:Return/kSEK/y": 593,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 207487.8891,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 238435.08,
    "out:DH kr savings": 188813.9791,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 144224.2684,
    "out:% savings (space heating)": 53.522481,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 48.16,
    "out:Etvv": 17,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7590250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.952973,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.781176,
    "out:Total Energy Use Pre PV": 94.799,
    "out:Total Energy Use Post PV": 87.781176,
    "out:Primary Energy": 68.173198,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 58.09614,
    "out:CO2 Operational/m2": -20.924693,
    "out:Total CO2/m2": 37.171447,
    "out:Total CO2 (tons)": 111327.2358,
    "out:Klimatpaverkan":0.001* -53564.1689,
    "out:Initial Cost/MSEK": 7.665125,
    "out:Running cost/(Apt SEK y)": 18098.29412,
    "out:Running Cost over RSP/MSEK": 4.82126,
    "out:LCP/MSEK": -2.793485,
    "out:ROI% old": 9.079416,
    "out:Payback discounted": 13,
    "out:Atemp": 2995,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 562.2812489,
    "out:Facade surface/m2": 1481.26657,
    "out:Window surface/m2": 254.6995135,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 85.2,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 973.446762,
    "out:Return %": 8,
    "out:Return/kSEK/y": 624,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 78070.3589,
    "out:EAHP (pipework)": 77831.69002,
    "out:EAHP (natural ventilation grills)": 161861.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 53.585403,
    "out:PV (m2 panels)": 281.140625,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 50277.59531,
    "out:PV (kWh sold)": 29259.44864,
    "out:PV (% sold (El))": 58.1958,
    "out:PV (kWh self-consumed)": 21018.14667,
    "out:PV (ratio sold/self-consumed)": 1.392104,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1164111.849,
    "out:FTX (Diffusers)": 212265.625,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153158.8197,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 34,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 419900,
    "out:DH (pipework)": 467500,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 474720.0015,
    "out:FTX (Facade)": 4048523.726,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 217079.5136,
    "out:EL kWh savings": 7244.296673,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 74874.16,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247163.46,
    "out:DH kr savings": 197542.3574,
    "out:El kr savings": 12315.30434,
    "out:El kr sold": 37305.79702,
    "out:El kr saved": 35730.84933,
    "out:El kr return": 73036.64636,
    "out:% solar/total": 43,
    "out:Total_El consumption (without PV)": 48518.45612,
    "out:Total_Space Heating (DH)": 134632.6439,
    "out:% savings (space heating)": 56.613465,
    "out:Total_Water Heating (DH)": 87980.13376,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 44.95,
    "out:Etvv": 17,
    "out:Ef": 13.78,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 7665125,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  }
]