import {
  filterExtractor,
  type Dataset,
  type Datum,
  type InputMap,
  type Value,
} from "packages/dataAccess";
import type { InstallationItem } from "packages/installations";

interface LowCostDatum {
  targetEnergyClass: string | undefined;
  currentEnergyClass?: string;
  dataset: Dataset;
  getFieldValue: (datum: Datum, fieldName: string) => Value;
  calculateLowCostByDefaultFilters?: boolean;
  installationFilters: Record<InstallationItem, InputMap>;
}

export const lowCostDatum = ({
  targetEnergyClass,
  currentEnergyClass,
  dataset,
  getFieldValue,
  calculateLowCostByDefaultFilters,
  installationFilters,
}: LowCostDatum) => {
  if (targetEnergyClass) {
    const datasetForTargetClass = dataset
      .filter(
        (item) => getFieldValue(item, "out:Energy Class") <= targetEnergyClass,
      )
      .sort((a, b) =>
        getFieldValue(a, "out:Initial Cost/MSEK") <
        getFieldValue(b, "out:Initial Cost/MSEK")
          ? -1
          : 1,
      );

    let currentDatum: Datum | undefined = undefined;

    if (!currentEnergyClass || targetEnergyClass < currentEnergyClass) {
      if (calculateLowCostByDefaultFilters) {
        // biome-ignore lint/complexity/noForEach: <explanation>
        datasetForTargetClass.forEach((datum: Datum) => {
          if (!currentDatum) {
            const condition = Array.from(filterExtractor([datum]))
              .map((x) => {
                return [x[0], Array.from(x[1])[0]];
              })
              .filter((x) => x[1] !== 0)
              .every(([fieldName, value]) => {
                return Object.keys(installationFilters)
                  .flatMap((key) =>
                    Array.from(installationFilters[key as InstallationItem]),
                  )
                  .filter((x) => x[1] !== 0)
                  .some((x) => x[0] === fieldName && x[1] === value);
              });

            if (condition) {
              currentDatum = datum;
            }
          }
        });
      } else {
        currentDatum = datasetForTargetClass[0];
      }
    }

    return currentDatum;
  }
};
