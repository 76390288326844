export default [
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 179.2,
    "out:Total Energy Use Post PV": 179.2,
    "out:Primary Energy": 153.09,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 60.36,
    "out:Total CO2/m2": 60.36,
    "out:Total CO2 (tons)": 167.02,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 30127,
    "out:Running Cost over RSP/MSEK": 11.302,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 0,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 179,
    "out:Energy use kWh/m2": 179,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 20,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 174.2,
    "out:Total Energy Use Post PV": 174.2,
    "out:Primary Energy": 148.66,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 58.56,
    "out:Total CO2/m2": 58.56,
    "out:Total CO2 (tons)": 162.04,
    "out:Klimatpaverkan": -4.98,
    "out:Initial Cost/MSEK": 0.069,
    "out:Running cost/(Apt SEK y)": 29256,
    "out:Running Cost over RSP/MSEK": 10.975,
    "out:LCP/MSEK": 0.258,
    "out:ROI% old": 67.49,
    "out:Payback discounted": 2,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 2.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 60,
    "out:Return/kSEK/y": 42,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 20,
    "out:Payback Time": 5,
    "out:DH kWh savings": 13835,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 13835,
    "out:DH kr savings": 13835,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 172.2,
    "out:Total Energy Use Post PV": 172.2,
    "out:Primary Energy": 150.47,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.65,
    "out:CO2 Operational/m2": 57.84,
    "out:Total CO2/m2": 58.49,
    "out:Total CO2 (tons)": 161.85,
    "out:Klimatpaverkan": -5.17,
    "out:Initial Cost/MSEK": 0.219,
    "out:Running cost/(Apt SEK y)": 28908,
    "out:Running Cost over RSP/MSEK": 10.844,
    "out:LCP/MSEK": 0.239,
    "out:ROI% old": 29.9,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 4.1,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 27,
    "out:Return/kSEK/y": 58,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 19369,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 19369,
    "out:DH kr savings": 19369,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 167.2,
    "out:Total Energy Use Post PV": 167.2,
    "out:Primary Energy": 146.03,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.65,
    "out:CO2 Operational/m2": 56.04,
    "out:Total CO2/m2": 56.69,
    "out:Total CO2 (tons)": 156.87,
    "out:Klimatpaverkan": -10.15,
    "out:Initial Cost/MSEK": 0.288,
    "out:Running cost/(Apt SEK y)": 28038,
    "out:Running Cost over RSP/MSEK": 10.517,
    "out:LCP/MSEK": 0.497,
    "out:ROI% old": 38.94,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 35,
    "out:Return/kSEK/y": 100,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 33204,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 33204,
    "out:DH kr savings": 33204,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 179.2,
    "out:Total Energy Use Post PV": 173.92,
    "out:Primary Energy": 143.58,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.88,
    "out:CO2 Operational/m2": 41.3,
    "out:Total CO2/m2": 51.18,
    "out:Total CO2 (tons)": 141.62,
    "out:Klimatpaverkan": -25.4,
    "out:Initial Cost/MSEK": 0.46,
    "out:Running cost/(Apt SEK y)": 29201,
    "out:Running Cost over RSP/MSEK": 10.964,
    "out:LCP/MSEK": -0.122,
    "out:ROI% old": 10.49,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 2.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 44,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 44423,
    "out:DH kr savings": 0,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 174.2,
    "out:Total Energy Use Post PV": 168.92,
    "out:Primary Energy": 139.15,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.88,
    "out:CO2 Operational/m2": 39.5,
    "out:Total CO2/m2": 49.38,
    "out:Total CO2 (tons)": 136.64,
    "out:Klimatpaverkan": -30.38,
    "out:Initial Cost/MSEK": 0.53,
    "out:Running cost/(Apt SEK y)": 28331,
    "out:Running Cost over RSP/MSEK": 10.637,
    "out:LCP/MSEK": 0.135,
    "out:ROI% old": 17.94,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 5.9,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 86,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 13835,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 58258,
    "out:DH kr savings": 13835,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 172.2,
    "out:Total Energy Use Post PV": 166.92,
    "out:Primary Energy": 140.96,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.53,
    "out:CO2 Operational/m2": 38.78,
    "out:Total CO2/m2": 49.31,
    "out:Total CO2 (tons)": 136.45,
    "out:Klimatpaverkan": -30.57,
    "out:Initial Cost/MSEK": 0.679,
    "out:Running cost/(Apt SEK y)": 27983,
    "out:Running Cost over RSP/MSEK": 10.506,
    "out:LCP/MSEK": 0.117,
    "out:ROI% old": 16.74,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 103,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 19369,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 63792,
    "out:DH kr savings": 19369,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 167.2,
    "out:Total Energy Use Post PV": 161.92,
    "out:Primary Energy": 136.52,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.53,
    "out:CO2 Operational/m2": 36.98,
    "out:Total CO2/m2": 47.51,
    "out:Total CO2 (tons)": 131.47,
    "out:Klimatpaverkan": -35.55,
    "out:Initial Cost/MSEK": 0.748,
    "out:Running cost/(Apt SEK y)": 27112,
    "out:Running Cost over RSP/MSEK": 10.179,
    "out:LCP/MSEK": 0.374,
    "out:ROI% old": 21.43,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 10.5,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 145,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 33204,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77627,
    "out:DH kr savings": 33204,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 170.2,
    "out:Total Energy Use Post PV": 170.2,
    "out:Primary Energy": 149.59,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 57.12,
    "out:Total CO2/m2": 57.12,
    "out:Total CO2 (tons)": 158.05,
    "out:Klimatpaverkan": -8.97,
    "out:Initial Cost/MSEK": 0.127,
    "out:Running cost/(Apt SEK y)": 28560,
    "out:Running Cost over RSP/MSEK": 10.714,
    "out:LCP/MSEK": 0.462,
    "out:ROI% old": 66.33,
    "out:Payback discounted": 2,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 5.3,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 59,
    "out:Return/kSEK/y": 75,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 20,
    "out:Payback Time": 5,
    "out:DH kWh savings": 24903,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 24903,
    "out:DH kr savings": 24903,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 165.2,
    "out:Total Energy Use Post PV": 165.2,
    "out:Primary Energy": 145.16,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 55.32,
    "out:Total CO2/m2": 55.32,
    "out:Total CO2 (tons)": 153.07,
    "out:Klimatpaverkan": -13.95,
    "out:Initial Cost/MSEK": 0.196,
    "out:Running cost/(Apt SEK y)": 27690,
    "out:Running Cost over RSP/MSEK": 10.387,
    "out:LCP/MSEK": 0.719,
    "out:ROI% old": 66.74,
    "out:Payback discounted": 2,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 8.5,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 60,
    "out:Return/kSEK/y": 117,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 20,
    "out:Payback Time": 5,
    "out:DH kWh savings": 38738,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 38738,
    "out:DH kr savings": 38738,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 165.2,
    "out:Total Energy Use Post PV": 165.2,
    "out:Primary Energy": 147.49,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.65,
    "out:CO2 Operational/m2": 55.32,
    "out:Total CO2/m2": 55.97,
    "out:Total CO2 (tons)": 154.88,
    "out:Klimatpaverkan": -12.14,
    "out:Initial Cost/MSEK": 0.345,
    "out:Running cost/(Apt SEK y)": 27690,
    "out:Running Cost over RSP/MSEK": 10.387,
    "out:LCP/MSEK": 0.57,
    "out:ROI% old": 37.86,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 8.5,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 34,
    "out:Return/kSEK/y": 117,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 38738,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 38738,
    "out:DH kr savings": 38738,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 160.2,
    "out:Total Energy Use Post PV": 160.2,
    "out:Primary Energy": 143.06,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.65,
    "out:CO2 Operational/m2": 53.52,
    "out:Total CO2/m2": 54.17,
    "out:Total CO2 (tons)": 149.9,
    "out:Klimatpaverkan": -17.12,
    "out:Initial Cost/MSEK": 0.414,
    "out:Running cost/(Apt SEK y)": 26819,
    "out:Running Cost over RSP/MSEK": 10.06,
    "out:LCP/MSEK": 0.827,
    "out:ROI% old": 42.81,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 38,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 52573,
    "out:DH kr savings": 52573,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 170.2,
    "out:Total Energy Use Post PV": 164.92,
    "out:Primary Energy": 140.08,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.88,
    "out:CO2 Operational/m2": 38.06,
    "out:Total CO2/m2": 47.94,
    "out:Total CO2 (tons)": 132.66,
    "out:Klimatpaverkan": -34.36,
    "out:Initial Cost/MSEK": 0.587,
    "out:Running cost/(Apt SEK y)": 27635,
    "out:Running Cost over RSP/MSEK": 10.375,
    "out:LCP/MSEK": 0.339,
    "out:ROI% old": 22.54,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 8.5,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 120,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 24903,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69326,
    "out:DH kr savings": 24903,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 165.2,
    "out:Total Energy Use Post PV": 159.92,
    "out:Primary Energy": 135.65,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.88,
    "out:CO2 Operational/m2": 36.26,
    "out:Total CO2/m2": 46.14,
    "out:Total CO2 (tons)": 127.68,
    "out:Klimatpaverkan": -39.34,
    "out:Initial Cost/MSEK": 0.656,
    "out:Running cost/(Apt SEK y)": 26764,
    "out:Running Cost over RSP/MSEK": 10.049,
    "out:LCP/MSEK": 0.597,
    "out:ROI% old": 27.28,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 25,
    "out:Return/kSEK/y": 161,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 38738,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83161,
    "out:DH kr savings": 38738,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 165.2,
    "out:Total Energy Use Post PV": 159.92,
    "out:Primary Energy": 137.98,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.53,
    "out:CO2 Operational/m2": 36.26,
    "out:Total CO2/m2": 46.79,
    "out:Total CO2 (tons)": 129.48,
    "out:Klimatpaverkan": -37.54,
    "out:Initial Cost/MSEK": 0.806,
    "out:Running cost/(Apt SEK y)": 26764,
    "out:Running Cost over RSP/MSEK": 10.049,
    "out:LCP/MSEK": 0.448,
    "out:ROI% old": 22.22,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 161,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 38738,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83161,
    "out:DH kr savings": 38738,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 160.2,
    "out:Total Energy Use Post PV": 154.92,
    "out:Primary Energy": 133.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.53,
    "out:CO2 Operational/m2": 34.46,
    "out:Total CO2/m2": 44.99,
    "out:Total CO2 (tons)": 124.5,
    "out:Klimatpaverkan": -42.52,
    "out:Initial Cost/MSEK": 0.875,
    "out:Running cost/(Apt SEK y)": 25894,
    "out:Running Cost over RSP/MSEK": 9.722,
    "out:LCP/MSEK": 0.705,
    "out:ROI% old": 25.8,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 23,
    "out:Return/kSEK/y": 203,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 96996,
    "out:DH kr savings": 52573,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 168.26,
    "out:Total Energy Use Post PV": 168.26,
    "out:Primary Energy": 154.3,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.8,
    "out:CO2 Operational/m2": 54.58,
    "out:Total CO2/m2": 58.38,
    "out:Total CO2 (tons)": 161.54,
    "out:Klimatpaverkan": -5.48,
    "out:Initial Cost/MSEK": 0.644,
    "out:Running cost/(Apt SEK y)": 27748,
    "out:Running Cost over RSP/MSEK": 10.405,
    "out:LCP/MSEK": 0.253,
    "out:ROI% old": 19.9,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 114,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 1,
    "out:Payback Time": 100,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 7993,
    "out:DH kr savings": 52573,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 163.26,
    "out:Total Energy Use Post PV": 163.26,
    "out:Primary Energy": 149.86,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.8,
    "out:CO2 Operational/m2": 52.78,
    "out:Total CO2/m2": 56.58,
    "out:Total CO2 (tons)": 156.56,
    "out:Klimatpaverkan": -10.46,
    "out:Initial Cost/MSEK": 0.713,
    "out:Running cost/(Apt SEK y)": 26877,
    "out:Running Cost over RSP/MSEK": 10.078,
    "out:LCP/MSEK": 0.511,
    "out:ROI% old": 24.51,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 9.8,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 156,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 66408,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 21828,
    "out:DH kr savings": 66408,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 161.26,
    "out:Total Energy Use Post PV": 161.26,
    "out:Primary Energy": 151.67,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.45,
    "out:CO2 Operational/m2": 52.06,
    "out:Total CO2/m2": 56.51,
    "out:Total CO2 (tons)": 156.37,
    "out:Klimatpaverkan": -10.65,
    "out:Initial Cost/MSEK": 0.863,
    "out:Running cost/(Apt SEK y)": 26529,
    "out:Running Cost over RSP/MSEK": 9.947,
    "out:LCP/MSEK": 0.492,
    "out:ROI% old": 22.43,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 11.2,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 173,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 71942,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 27362,
    "out:DH kr savings": 71942,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 156.26,
    "out:Total Energy Use Post PV": 156.26,
    "out:Primary Energy": 147.24,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.45,
    "out:CO2 Operational/m2": 50.26,
    "out:Total CO2/m2": 54.71,
    "out:Total CO2 (tons)": 151.39,
    "out:Klimatpaverkan": -15.63,
    "out:Initial Cost/MSEK": 0.932,
    "out:Running cost/(Apt SEK y)": 25659,
    "out:Running Cost over RSP/MSEK": 9.62,
    "out:LCP/MSEK": 0.75,
    "out:ROI% old": 25.78,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 214,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 41197,
    "out:DH kr savings": 85777,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 168.26,
    "out:Total Energy Use Post PV": 161.64,
    "out:Primary Energy": 142.39,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.68,
    "out:CO2 Operational/m2": 42.16,
    "out:Total CO2/m2": 55.84,
    "out:Total CO2 (tons)": 154.5,
    "out:Klimatpaverkan": -12.52,
    "out:Initial Cost/MSEK": 1.105,
    "out:Running cost/(Apt SEK y)": 26784,
    "out:Running Cost over RSP/MSEK": 10.052,
    "out:LCP/MSEK": 0.145,
    "out:ROI% old": 16.16,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 10.5,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 160,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 54256,
    "out:DH kr savings": 52573,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 163.26,
    "out:Total Energy Use Post PV": 156.64,
    "out:Primary Energy": 137.95,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.68,
    "out:CO2 Operational/m2": 40.36,
    "out:Total CO2/m2": 54.04,
    "out:Total CO2 (tons)": 149.52,
    "out:Klimatpaverkan": -17.5,
    "out:Initial Cost/MSEK": 1.174,
    "out:Running cost/(Apt SEK y)": 25914,
    "out:Running Cost over RSP/MSEK": 9.726,
    "out:LCP/MSEK": 0.402,
    "out:ROI% old": 19.18,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 202,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 66408,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 68091,
    "out:DH kr savings": 66408,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 161.26,
    "out:Total Energy Use Post PV": 154.64,
    "out:Primary Energy": 139.77,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.33,
    "out:CO2 Operational/m2": 39.64,
    "out:Total CO2/m2": 53.97,
    "out:Total CO2 (tons)": 149.33,
    "out:Klimatpaverkan": -17.69,
    "out:Initial Cost/MSEK": 1.323,
    "out:Running cost/(Apt SEK y)": 25565,
    "out:Running Cost over RSP/MSEK": 9.595,
    "out:LCP/MSEK": 0.384,
    "out:ROI% old": 18.43,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 219,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 71942,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 73625,
    "out:DH kr savings": 71942,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 156.26,
    "out:Total Energy Use Post PV": 149.64,
    "out:Primary Energy": 135.33,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.33,
    "out:CO2 Operational/m2": 37.84,
    "out:Total CO2/m2": 52.17,
    "out:Total CO2 (tons)": 144.35,
    "out:Klimatpaverkan": -22.67,
    "out:Initial Cost/MSEK": 1.392,
    "out:Running cost/(Apt SEK y)": 24695,
    "out:Running Cost over RSP/MSEK": 9.268,
    "out:LCP/MSEK": 0.641,
    "out:ROI% old": 20.87,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 261,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 87460,
    "out:DH kr savings": 85777,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 159.26,
    "out:Total Energy Use Post PV": 159.26,
    "out:Primary Energy": 150.8,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.8,
    "out:CO2 Operational/m2": 51.34,
    "out:Total CO2/m2": 55.14,
    "out:Total CO2 (tons)": 152.58,
    "out:Klimatpaverkan": -14.44,
    "out:Initial Cost/MSEK": 0.771,
    "out:Running cost/(Apt SEK y)": 26181,
    "out:Running Cost over RSP/MSEK": 9.816,
    "out:LCP/MSEK": 0.715,
    "out:ROI% old": 27.53,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 189,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 32896,
    "out:DH kr savings": 77476,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 154.26,
    "out:Total Energy Use Post PV": 154.26,
    "out:Primary Energy": 146.36,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.8,
    "out:CO2 Operational/m2": 49.54,
    "out:Total CO2/m2": 53.34,
    "out:Total CO2 (tons)": 147.59,
    "out:Klimatpaverkan": -19.43,
    "out:Initial Cost/MSEK": 0.84,
    "out:Running cost/(Apt SEK y)": 25311,
    "out:Running Cost over RSP/MSEK": 9.489,
    "out:LCP/MSEK": 0.972,
    "out:ROI% old": 30.82,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 28,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 46731,
    "out:DH kr savings": 91311,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 154.26,
    "out:Total Energy Use Post PV": 154.26,
    "out:Primary Energy": 148.35,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.45,
    "out:CO2 Operational/m2": 49.54,
    "out:Total CO2/m2": 53.99,
    "out:Total CO2 (tons)": 149.4,
    "out:Klimatpaverkan": -17.62,
    "out:Initial Cost/MSEK": 0.989,
    "out:Running cost/(Apt SEK y)": 25311,
    "out:Running Cost over RSP/MSEK": 9.489,
    "out:LCP/MSEK": 0.823,
    "out:ROI% old": 26.16,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 46731,
    "out:DH kr savings": 91311,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 115.53,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 149.26,
    "out:Total Energy Use Post PV": 149.26,
    "out:Primary Energy": 143.91,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.45,
    "out:CO2 Operational/m2": 47.74,
    "out:Total CO2/m2": 52.19,
    "out:Total CO2 (tons)": 144.42,
    "out:Klimatpaverkan": -22.6,
    "out:Initial Cost/MSEK": 1.059,
    "out:Running cost/(Apt SEK y)": 24440,
    "out:Running Cost over RSP/MSEK": 9.163,
    "out:LCP/MSEK": 1.08,
    "out:ROI% old": 28.86,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 26,
    "out:Return/kSEK/y": 273,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 60566,
    "out:DH kr savings": 105146,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 110.46,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 159.26,
    "out:Total Energy Use Post PV": 152.64,
    "out:Primary Energy": 138.89,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.68,
    "out:CO2 Operational/m2": 38.92,
    "out:Total CO2/m2": 52.6,
    "out:Total CO2 (tons)": 145.53,
    "out:Klimatpaverkan": -21.49,
    "out:Initial Cost/MSEK": 1.231,
    "out:Running cost/(Apt SEK y)": 25217,
    "out:Running Cost over RSP/MSEK": 9.464,
    "out:LCP/MSEK": 0.606,
    "out:ROI% old": 21.32,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 236,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 79159,
    "out:DH kr savings": 77476,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 154.26,
    "out:Total Energy Use Post PV": 147.64,
    "out:Primary Energy": 134.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.68,
    "out:CO2 Operational/m2": 37.12,
    "out:Total CO2/m2": 50.8,
    "out:Total CO2 (tons)": 140.55,
    "out:Klimatpaverkan": -26.47,
    "out:Initial Cost/MSEK": 1.301,
    "out:Running cost/(Apt SEK y)": 24347,
    "out:Running Cost over RSP/MSEK": 9.137,
    "out:LCP/MSEK": 0.864,
    "out:ROI% old": 23.78,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 277,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 92994,
    "out:DH kr savings": 91311,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 154.26,
    "out:Total Energy Use Post PV": 147.64,
    "out:Primary Energy": 136.44,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.33,
    "out:CO2 Operational/m2": 37.12,
    "out:Total CO2/m2": 51.45,
    "out:Total CO2 (tons)": 142.36,
    "out:Klimatpaverkan": -24.66,
    "out:Initial Cost/MSEK": 1.45,
    "out:Running cost/(Apt SEK y)": 24347,
    "out:Running Cost over RSP/MSEK": 9.137,
    "out:LCP/MSEK": 0.714,
    "out:ROI% old": 21.32,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 277,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 92994,
    "out:DH kr savings": 91311,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 115.53,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 149.26,
    "out:Total Energy Use Post PV": 142.64,
    "out:Primary Energy": 132.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.33,
    "out:CO2 Operational/m2": 35.32,
    "out:Total CO2/m2": 49.65,
    "out:Total CO2 (tons)": 137.38,
    "out:Klimatpaverkan": -29.64,
    "out:Initial Cost/MSEK": 1.519,
    "out:Running cost/(Apt SEK y)": 23476,
    "out:Running Cost over RSP/MSEK": 8.81,
    "out:LCP/MSEK": 0.972,
    "out:ROI% old": 23.43,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 319,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 106829,
    "out:DH kr savings": 105146,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 110.46,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 170.2,
    "out:Total Energy Use Post PV": 170.2,
    "out:Primary Energy": 145.67,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.31,
    "out:CO2 Operational/m2": 57.12,
    "out:Total CO2/m2": 59.43,
    "out:Total CO2 (tons)": 164.46,
    "out:Klimatpaverkan": -2.56,
    "out:Initial Cost/MSEK": 0.447,
    "out:Running cost/(Apt SEK y)": 28560,
    "out:Running Cost over RSP/MSEK": 10.714,
    "out:LCP/MSEK": 0.142,
    "out:ROI% old": 18.81,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 5.3,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 17,
    "out:Return/kSEK/y": 75,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 24903,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 24903,
    "out:DH kr savings": 24903,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 166.2,
    "out:Total Energy Use Post PV": 166.2,
    "out:Primary Energy": 141.57,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.31,
    "out:CO2 Operational/m2": 55.68,
    "out:Total CO2/m2": 57.99,
    "out:Total CO2 (tons)": 160.47,
    "out:Klimatpaverkan": -6.55,
    "out:Initial Cost/MSEK": 0.516,
    "out:Running cost/(Apt SEK y)": 27864,
    "out:Running Cost over RSP/MSEK": 10.452,
    "out:LCP/MSEK": 0.334,
    "out:ROI% old": 23.53,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 7.8,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 21,
    "out:Return/kSEK/y": 109,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 35971,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 35971,
    "out:DH kr savings": 35971,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 164.2,
    "out:Total Energy Use Post PV": 164.2,
    "out:Primary Energy": 143.04,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.97,
    "out:CO2 Operational/m2": 54.96,
    "out:Total CO2/m2": 57.93,
    "out:Total CO2 (tons)": 160.28,
    "out:Klimatpaverkan": -6.74,
    "out:Initial Cost/MSEK": 0.665,
    "out:Running cost/(Apt SEK y)": 27516,
    "out:Running Cost over RSP/MSEK": 10.321,
    "out:LCP/MSEK": 0.315,
    "out:ROI% old": 21.05,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 9.1,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 19,
    "out:Return/kSEK/y": 125,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 41505,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 41505,
    "out:DH kr savings": 41505,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 159.2,
    "out:Total Energy Use Post PV": 159.2,
    "out:Primary Energy": 138.94,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.97,
    "out:CO2 Operational/m2": 53.16,
    "out:Total CO2/m2": 56.13,
    "out:Total CO2 (tons)": 155.3,
    "out:Klimatpaverkan": -11.72,
    "out:Initial Cost/MSEK": 0.734,
    "out:Running cost/(Apt SEK y)": 26645,
    "out:Running Cost over RSP/MSEK": 9.995,
    "out:LCP/MSEK": 0.573,
    "out:ROI% old": 25.43,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 23,
    "out:Return/kSEK/y": 167,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 55340,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 55340,
    "out:DH kr savings": 55340,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 170.2,
    "out:Total Energy Use Post PV": 164.92,
    "out:Primary Energy": 136.16,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.2,
    "out:CO2 Operational/m2": 38.06,
    "out:Total CO2/m2": 50.26,
    "out:Total CO2 (tons)": 139.06,
    "out:Klimatpaverkan": -27.96,
    "out:Initial Cost/MSEK": 0.907,
    "out:Running cost/(Apt SEK y)": 27635,
    "out:Running Cost over RSP/MSEK": 10.375,
    "out:LCP/MSEK": 0.019,
    "out:ROI% old": 14.59,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 8.5,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 120,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 24903,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69326,
    "out:DH kr savings": 24903,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 166.2,
    "out:Total Energy Use Post PV": 160.92,
    "out:Primary Energy": 132.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.2,
    "out:CO2 Operational/m2": 36.62,
    "out:Total CO2/m2": 48.82,
    "out:Total CO2 (tons)": 135.08,
    "out:Klimatpaverkan": -31.94,
    "out:Initial Cost/MSEK": 0.976,
    "out:Running cost/(Apt SEK y)": 26938,
    "out:Running Cost over RSP/MSEK": 10.114,
    "out:LCP/MSEK": 0.212,
    "out:ROI% old": 17.38,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 11.2,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 153,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 35971,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 80394,
    "out:DH kr savings": 35971,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 164.2,
    "out:Total Energy Use Post PV": 158.92,
    "out:Primary Energy": 133.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.85,
    "out:CO2 Operational/m2": 35.9,
    "out:Total CO2/m2": 48.75,
    "out:Total CO2 (tons)": 134.89,
    "out:Klimatpaverkan": -32.13,
    "out:Initial Cost/MSEK": 1.126,
    "out:Running cost/(Apt SEK y)": 26590,
    "out:Running Cost over RSP/MSEK": 9.983,
    "out:LCP/MSEK": 0.193,
    "out:ROI% old": 16.73,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 170,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 41505,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85928,
    "out:DH kr savings": 41505,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 159.2,
    "out:Total Energy Use Post PV": 153.92,
    "out:Primary Energy": 129.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.85,
    "out:CO2 Operational/m2": 34.1,
    "out:Total CO2/m2": 46.95,
    "out:Total CO2 (tons)": 129.91,
    "out:Klimatpaverkan": -37.11,
    "out:Initial Cost/MSEK": 1.195,
    "out:Running cost/(Apt SEK y)": 25720,
    "out:Running Cost over RSP/MSEK": 9.656,
    "out:LCP/MSEK": 0.451,
    "out:ROI% old": 19.67,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 212,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 55340,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99763,
    "out:DH kr savings": 55340,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 162.2,
    "out:Total Energy Use Post PV": 162.2,
    "out:Primary Energy": 142.17,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.31,
    "out:CO2 Operational/m2": 54.24,
    "out:Total CO2/m2": 56.55,
    "out:Total CO2 (tons)": 156.49,
    "out:Klimatpaverkan": -10.53,
    "out:Initial Cost/MSEK": 0.573,
    "out:Running cost/(Apt SEK y)": 27167,
    "out:Running Cost over RSP/MSEK": 10.191,
    "out:LCP/MSEK": 0.538,
    "out:ROI% old": 27.69,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 10.5,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 25,
    "out:Return/kSEK/y": 142,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 47039,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 47039,
    "out:DH kr savings": 47039,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 157.2,
    "out:Primary Energy": 138.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.31,
    "out:CO2 Operational/m2": 52.44,
    "out:Total CO2/m2": 54.75,
    "out:Total CO2 (tons)": 151.51,
    "out:Klimatpaverkan": -15.51,
    "out:Initial Cost/MSEK": 0.643,
    "out:Running cost/(Apt SEK y)": 26297,
    "out:Running Cost over RSP/MSEK": 9.864,
    "out:LCP/MSEK": 0.795,
    "out:ROI% old": 31.97,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 29,
    "out:Return/kSEK/y": 184,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 60874,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 60874,
    "out:DH kr savings": 60874,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 156.2,
    "out:Primary Energy": 140.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.97,
    "out:CO2 Operational/m2": 52.08,
    "out:Total CO2/m2": 55.05,
    "out:Total CO2 (tons)": 152.32,
    "out:Klimatpaverkan": -14.7,
    "out:Initial Cost/MSEK": 0.792,
    "out:Running cost/(Apt SEK y)": 26123,
    "out:Running Cost over RSP/MSEK": 9.798,
    "out:LCP/MSEK": 0.711,
    "out:ROI% old": 27.12,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 24,
    "out:Return/kSEK/y": 192,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 63641,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 63641,
    "out:DH kr savings": 63641,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 152.2,
    "out:Primary Energy": 135.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.97,
    "out:CO2 Operational/m2": 50.64,
    "out:Total CO2/m2": 53.61,
    "out:Total CO2 (tons)": 148.33,
    "out:Klimatpaverkan": -18.69,
    "out:Initial Cost/MSEK": 0.861,
    "out:Running cost/(Apt SEK y)": 25426,
    "out:Running Cost over RSP/MSEK": 9.537,
    "out:LCP/MSEK": 0.904,
    "out:ROI% old": 29.28,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 17.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 26,
    "out:Return/kSEK/y": 226,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 74709,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 74709,
    "out:DH kr savings": 74709,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 162.2,
    "out:Total Energy Use Post PV": 156.92,
    "out:Primary Energy": 132.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.2,
    "out:CO2 Operational/m2": 35.18,
    "out:Total CO2/m2": 47.38,
    "out:Total CO2 (tons)": 131.09,
    "out:Klimatpaverkan": -35.93,
    "out:Initial Cost/MSEK": 1.034,
    "out:Running cost/(Apt SEK y)": 26242,
    "out:Running Cost over RSP/MSEK": 9.852,
    "out:LCP/MSEK": 0.416,
    "out:ROI% old": 20.03,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 186,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 47039,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91462,
    "out:DH kr savings": 47039,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 151.92,
    "out:Primary Energy": 128.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.2,
    "out:CO2 Operational/m2": 33.38,
    "out:Total CO2/m2": 45.58,
    "out:Total CO2 (tons)": 126.11,
    "out:Klimatpaverkan": -40.91,
    "out:Initial Cost/MSEK": 1.103,
    "out:Running cost/(Apt SEK y)": 25371,
    "out:Running Cost over RSP/MSEK": 9.526,
    "out:LCP/MSEK": 0.673,
    "out:ROI% old": 23,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 17.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 228,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 60874,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105297,
    "out:DH kr savings": 60874,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 150.92,
    "out:Primary Energy": 130.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.85,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 45.87,
    "out:Total CO2 (tons)": 126.92,
    "out:Klimatpaverkan": -40.1,
    "out:Initial Cost/MSEK": 1.252,
    "out:Running cost/(Apt SEK y)": 25197,
    "out:Running Cost over RSP/MSEK": 9.46,
    "out:LCP/MSEK": 0.589,
    "out:ROI% old": 21.01,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 237,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 63641,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108064,
    "out:DH kr savings": 63641,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 146.92,
    "out:Primary Energy": 126.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.85,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 44.43,
    "out:Total CO2 (tons)": 122.94,
    "out:Klimatpaverkan": -44.08,
    "out:Initial Cost/MSEK": 1.322,
    "out:Running cost/(Apt SEK y)": 24501,
    "out:Running Cost over RSP/MSEK": 9.199,
    "out:LCP/MSEK": 0.781,
    "out:ROI% old": 22.73,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 270,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 74709,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119132,
    "out:DH kr savings": 74709,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 159.26,
    "out:Total Energy Use Post PV": 159.26,
    "out:Primary Energy": 146.87,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.11,
    "out:CO2 Operational/m2": 51.34,
    "out:Total CO2/m2": 57.46,
    "out:Total CO2 (tons)": 158.98,
    "out:Klimatpaverkan": -8.04,
    "out:Initial Cost/MSEK": 1.091,
    "out:Running cost/(Apt SEK y)": 26181,
    "out:Running Cost over RSP/MSEK": 9.816,
    "out:LCP/MSEK": 0.395,
    "out:ROI% old": 19.45,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 189,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 32896,
    "out:DH kr savings": 77476,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 155.26,
    "out:Total Energy Use Post PV": 155.26,
    "out:Primary Energy": 142.77,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.11,
    "out:CO2 Operational/m2": 49.9,
    "out:Total CO2/m2": 56.02,
    "out:Total CO2 (tons)": 154.99,
    "out:Klimatpaverkan": -12.03,
    "out:Initial Cost/MSEK": 1.16,
    "out:Running cost/(Apt SEK y)": 25485,
    "out:Running Cost over RSP/MSEK": 9.555,
    "out:LCP/MSEK": 0.587,
    "out:ROI% old": 21.51,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 223,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 43964,
    "out:DH kr savings": 88544,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 153.26,
    "out:Total Energy Use Post PV": 153.26,
    "out:Primary Energy": 144.24,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.76,
    "out:CO2 Operational/m2": 49.18,
    "out:Total CO2/m2": 55.95,
    "out:Total CO2 (tons)": 154.81,
    "out:Klimatpaverkan": -12.21,
    "out:Initial Cost/MSEK": 1.31,
    "out:Running cost/(Apt SEK y)": 25137,
    "out:Running Cost over RSP/MSEK": 9.424,
    "out:LCP/MSEK": 0.568,
    "out:ROI% old": 20.48,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 240,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 49498,
    "out:DH kr savings": 94078,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 148.26,
    "out:Total Energy Use Post PV": 148.26,
    "out:Primary Energy": 140.15,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.76,
    "out:CO2 Operational/m2": 47.38,
    "out:Total CO2/m2": 54.15,
    "out:Total CO2 (tons)": 149.83,
    "out:Klimatpaverkan": -17.19,
    "out:Initial Cost/MSEK": 1.379,
    "out:Running cost/(Apt SEK y)": 24266,
    "out:Running Cost over RSP/MSEK": 9.097,
    "out:LCP/MSEK": 0.826,
    "out:ROI% old": 22.84,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 281,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 63333,
    "out:DH kr savings": 107913,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 159.26,
    "out:Total Energy Use Post PV": 152.64,
    "out:Primary Energy": 134.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.99,
    "out:CO2 Operational/m2": 38.92,
    "out:Total CO2/m2": 54.91,
    "out:Total CO2 (tons)": 151.94,
    "out:Klimatpaverkan": -15.08,
    "out:Initial Cost/MSEK": 1.551,
    "out:Running cost/(Apt SEK y)": 25217,
    "out:Running Cost over RSP/MSEK": 9.464,
    "out:LCP/MSEK": 0.286,
    "out:ROI% old": 16.92,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 236,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 79159,
    "out:DH kr savings": 77476,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 155.26,
    "out:Total Energy Use Post PV": 148.64,
    "out:Primary Energy": 130.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.99,
    "out:CO2 Operational/m2": 37.48,
    "out:Total CO2/m2": 53.47,
    "out:Total CO2 (tons)": 147.95,
    "out:Klimatpaverkan": -19.07,
    "out:Initial Cost/MSEK": 1.621,
    "out:Running cost/(Apt SEK y)": 24521,
    "out:Running Cost over RSP/MSEK": 9.203,
    "out:LCP/MSEK": 0.479,
    "out:ROI% old": 18.51,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 269,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 90227,
    "out:DH kr savings": 88544,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 153.26,
    "out:Total Energy Use Post PV": 146.64,
    "out:Primary Energy": 132.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.65,
    "out:CO2 Operational/m2": 36.76,
    "out:Total CO2/m2": 53.4,
    "out:Total CO2 (tons)": 147.77,
    "out:Klimatpaverkan": -19.25,
    "out:Initial Cost/MSEK": 1.77,
    "out:Running cost/(Apt SEK y)": 24173,
    "out:Running Cost over RSP/MSEK": 9.072,
    "out:LCP/MSEK": 0.46,
    "out:ROI% old": 18,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 286,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95761,
    "out:DH kr savings": 94078,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 148.26,
    "out:Total Energy Use Post PV": 141.64,
    "out:Primary Energy": 128.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.65,
    "out:CO2 Operational/m2": 34.96,
    "out:Total CO2/m2": 51.6,
    "out:Total CO2 (tons)": 142.79,
    "out:Klimatpaverkan": -24.23,
    "out:Initial Cost/MSEK": 1.839,
    "out:Running cost/(Apt SEK y)": 23302,
    "out:Running Cost over RSP/MSEK": 8.745,
    "out:LCP/MSEK": 0.718,
    "out:ROI% old": 19.86,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 328,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109596,
    "out:DH kr savings": 107913,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 151.26,
    "out:Total Energy Use Post PV": 151.26,
    "out:Primary Energy": 143.37,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.11,
    "out:CO2 Operational/m2": 48.46,
    "out:Total CO2/m2": 54.58,
    "out:Total CO2 (tons)": 151.01,
    "out:Klimatpaverkan": -16.01,
    "out:Initial Cost/MSEK": 1.218,
    "out:Running cost/(Apt SEK y)": 24788,
    "out:Running Cost over RSP/MSEK": 9.293,
    "out:LCP/MSEK": 0.791,
    "out:ROI% old": 23.56,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 256,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 55032,
    "out:DH kr savings": 99612,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 146.26,
    "out:Total Energy Use Post PV": 146.26,
    "out:Primary Energy": 139.27,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.11,
    "out:CO2 Operational/m2": 46.66,
    "out:Total CO2/m2": 52.78,
    "out:Total CO2 (tons)": 146.03,
    "out:Klimatpaverkan": -20.99,
    "out:Initial Cost/MSEK": 1.287,
    "out:Running cost/(Apt SEK y)": 23918,
    "out:Running Cost over RSP/MSEK": 8.967,
    "out:LCP/MSEK": 1.048,
    "out:ROI% old": 25.93,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 68867,
    "out:DH kr savings": 113447,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 145.26,
    "out:Total Energy Use Post PV": 145.26,
    "out:Primary Energy": 140.92,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.76,
    "out:CO2 Operational/m2": 46.3,
    "out:Total CO2/m2": 53.07,
    "out:Total CO2 (tons)": 146.84,
    "out:Klimatpaverkan": -20.18,
    "out:Initial Cost/MSEK": 1.436,
    "out:Running cost/(Apt SEK y)": 23744,
    "out:Running Cost over RSP/MSEK": 8.901,
    "out:LCP/MSEK": 0.964,
    "out:ROI% old": 23.88,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 306,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 71634,
    "out:DH kr savings": 116214,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 107.04,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 141.26,
    "out:Total Energy Use Post PV": 141.26,
    "out:Primary Energy": 136.82,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.76,
    "out:CO2 Operational/m2": 44.86,
    "out:Total CO2/m2": 51.63,
    "out:Total CO2 (tons)": 142.85,
    "out:Klimatpaverkan": -24.17,
    "out:Initial Cost/MSEK": 1.505,
    "out:Running cost/(Apt SEK y)": 23047,
    "out:Running Cost over RSP/MSEK": 8.64,
    "out:LCP/MSEK": 1.157,
    "out:ROI% old": 25.26,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 82702,
    "out:DH kr savings": 127282,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 102.36,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 151.26,
    "out:Total Energy Use Post PV": 144.64,
    "out:Primary Energy": 131.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.99,
    "out:CO2 Operational/m2": 36.04,
    "out:Total CO2/m2": 52.03,
    "out:Total CO2 (tons)": 143.97,
    "out:Klimatpaverkan": -23.05,
    "out:Initial Cost/MSEK": 1.678,
    "out:Running cost/(Apt SEK y)": 23825,
    "out:Running Cost over RSP/MSEK": 8.941,
    "out:LCP/MSEK": 0.683,
    "out:ROI% old": 20.1,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 101295,
    "out:DH kr savings": 99612,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 146.26,
    "out:Total Energy Use Post PV": 139.64,
    "out:Primary Energy": 127.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.99,
    "out:CO2 Operational/m2": 34.24,
    "out:Total CO2/m2": 50.23,
    "out:Total CO2 (tons)": 138.99,
    "out:Klimatpaverkan": -28.03,
    "out:Initial Cost/MSEK": 1.747,
    "out:Running cost/(Apt SEK y)": 22954,
    "out:Running Cost over RSP/MSEK": 8.614,
    "out:LCP/MSEK": 0.94,
    "out:ROI% old": 21.97,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 344,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115130,
    "out:DH kr savings": 113447,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 145.26,
    "out:Total Energy Use Post PV": 138.64,
    "out:Primary Energy": 129.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.65,
    "out:CO2 Operational/m2": 33.88,
    "out:Total CO2/m2": 50.52,
    "out:Total CO2 (tons)": 139.8,
    "out:Klimatpaverkan": -27.22,
    "out:Initial Cost/MSEK": 1.897,
    "out:Running cost/(Apt SEK y)": 22780,
    "out:Running Cost over RSP/MSEK": 8.549,
    "out:LCP/MSEK": 0.856,
    "out:ROI% old": 20.73,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 353,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117897,
    "out:DH kr savings": 116214,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 107.04,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 141.26,
    "out:Total Energy Use Post PV": 134.64,
    "out:Primary Energy": 124.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.65,
    "out:CO2 Operational/m2": 32.44,
    "out:Total CO2/m2": 49.08,
    "out:Total CO2 (tons)": 135.81,
    "out:Klimatpaverkan": -31.21,
    "out:Initial Cost/MSEK": 1.966,
    "out:Running cost/(Apt SEK y)": 22084,
    "out:Running Cost over RSP/MSEK": 8.288,
    "out:LCP/MSEK": 1.048,
    "out:ROI% old": 21.9,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128965,
    "out:DH kr savings": 127282,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 102.36,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 168.2,
    "out:Total Energy Use Post PV": 168.2,
    "out:Primary Energy": 143.23,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.07,
    "out:CO2 Operational/m2": 56.4,
    "out:Total CO2/m2": 64.47,
    "out:Total CO2 (tons)": 178.38,
    "out:Klimatpaverkan": 11.36,
    "out:Initial Cost/MSEK": 1.54,
    "out:Running cost/(Apt SEK y)": 28212,
    "out:Running Cost over RSP/MSEK": 10.583,
    "out:LCP/MSEK": -0.821,
    "out:ROI% old": 6.67,
    "out:Payback discounted": 18,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 6,
    "out:Return/kSEK/y": 92,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 30437,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 30437,
    "out:DH kr savings": 30437,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 163.2,
    "out:Total Energy Use Post PV": 163.2,
    "out:Primary Energy": 139.24,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.07,
    "out:CO2 Operational/m2": 54.6,
    "out:Total CO2/m2": 62.67,
    "out:Total CO2 (tons)": 173.4,
    "out:Klimatpaverkan": 6.38,
    "out:Initial Cost/MSEK": 1.61,
    "out:Running cost/(Apt SEK y)": 27341,
    "out:Running Cost over RSP/MSEK": 10.256,
    "out:LCP/MSEK": -0.564,
    "out:ROI% old": 9.28,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 9.8,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 134,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 44272,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 44272,
    "out:DH kr savings": 44272,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 161.2,
    "out:Total Energy Use Post PV": 161.2,
    "out:Primary Energy": 140.6,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.72,
    "out:CO2 Operational/m2": 53.88,
    "out:Total CO2/m2": 62.6,
    "out:Total CO2 (tons)": 173.21,
    "out:Klimatpaverkan": 6.19,
    "out:Initial Cost/MSEK": 1.759,
    "out:Running cost/(Apt SEK y)": 26993,
    "out:Running Cost over RSP/MSEK": 10.125,
    "out:LCP/MSEK": -0.582,
    "out:ROI% old": 9.56,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 11.2,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 150,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 49806,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 49806,
    "out:DH kr savings": 49806,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 156.2,
    "out:Primary Energy": 136.61,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.72,
    "out:CO2 Operational/m2": 52.08,
    "out:Total CO2/m2": 60.8,
    "out:Total CO2 (tons)": 168.23,
    "out:Klimatpaverkan": 1.21,
    "out:Initial Cost/MSEK": 1.828,
    "out:Running cost/(Apt SEK y)": 26123,
    "out:Running Cost over RSP/MSEK": 9.798,
    "out:LCP/MSEK": -0.325,
    "out:ROI% old": 11.75,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 192,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 63641,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 63641,
    "out:DH kr savings": 63641,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 168.2,
    "out:Total Energy Use Post PV": 162.92,
    "out:Primary Energy": 133.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.95,
    "out:CO2 Operational/m2": 37.34,
    "out:Total CO2/m2": 55.29,
    "out:Total CO2 (tons)": 152.99,
    "out:Klimatpaverkan": -14.03,
    "out:Initial Cost/MSEK": 2.001,
    "out:Running cost/(Apt SEK y)": 27286,
    "out:Running Cost over RSP/MSEK": 10.245,
    "out:LCP/MSEK": -0.944,
    "out:ROI% old": 7.55,
    "out:Payback discounted": 16,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 9.8,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 136,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 30437,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74860,
    "out:DH kr savings": 30437,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 163.2,
    "out:Total Energy Use Post PV": 157.92,
    "out:Primary Energy": 129.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.95,
    "out:CO2 Operational/m2": 35.54,
    "out:Total CO2/m2": 53.49,
    "out:Total CO2 (tons)": 148.01,
    "out:Klimatpaverkan": -19.01,
    "out:Initial Cost/MSEK": 2.07,
    "out:Running cost/(Apt SEK y)": 26416,
    "out:Running Cost over RSP/MSEK": 9.918,
    "out:LCP/MSEK": -0.686,
    "out:ROI% old": 9.55,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 178,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 44272,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88695,
    "out:DH kr savings": 44272,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 161.2,
    "out:Total Energy Use Post PV": 155.92,
    "out:Primary Energy": 131.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.6,
    "out:CO2 Operational/m2": 34.82,
    "out:Total CO2/m2": 53.42,
    "out:Total CO2 (tons)": 147.82,
    "out:Klimatpaverkan": -19.2,
    "out:Initial Cost/MSEK": 2.219,
    "out:Running cost/(Apt SEK y)": 26068,
    "out:Running Cost over RSP/MSEK": 9.787,
    "out:LCP/MSEK": -0.705,
    "out:ROI% old": 9.75,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 195,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 49806,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 94229,
    "out:DH kr savings": 49806,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 150.92,
    "out:Primary Energy": 127.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.6,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 51.62,
    "out:Total CO2 (tons)": 142.84,
    "out:Klimatpaverkan": -24.18,
    "out:Initial Cost/MSEK": 2.289,
    "out:Running cost/(Apt SEK y)": 25197,
    "out:Running Cost over RSP/MSEK": 9.46,
    "out:LCP/MSEK": -0.447,
    "out:ROI% old": 11.5,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 237,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 63641,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 108064,
    "out:DH kr savings": 63641,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 159.2,
    "out:Total Energy Use Post PV": 159.2,
    "out:Primary Energy": 139.73,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.07,
    "out:CO2 Operational/m2": 53.16,
    "out:Total CO2/m2": 61.23,
    "out:Total CO2 (tons)": 169.42,
    "out:Klimatpaverkan": 2.4,
    "out:Initial Cost/MSEK": 1.667,
    "out:Running cost/(Apt SEK y)": 26645,
    "out:Running Cost over RSP/MSEK": 9.995,
    "out:LCP/MSEK": -0.36,
    "out:ROI% old": 11.2,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 167,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 55340,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 55340,
    "out:DH kr savings": 55340,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 154.2,
    "out:Primary Energy": 135.74,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.07,
    "out:CO2 Operational/m2": 51.36,
    "out:Total CO2/m2": 59.43,
    "out:Total CO2 (tons)": 164.44,
    "out:Klimatpaverkan": -2.58,
    "out:Initial Cost/MSEK": 1.736,
    "out:Running cost/(Apt SEK y)": 25775,
    "out:Running Cost over RSP/MSEK": 9.668,
    "out:LCP/MSEK": -0.102,
    "out:ROI% old": 13.45,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 209,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 69175,
    "out:DH kr savings": 69175,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 154.2,
    "out:Primary Energy": 137.63,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.72,
    "out:CO2 Operational/m2": 51.36,
    "out:Total CO2/m2": 60.08,
    "out:Total CO2 (tons)": 166.24,
    "out:Klimatpaverkan": -0.78,
    "out:Initial Cost/MSEK": 1.886,
    "out:Running cost/(Apt SEK y)": 25775,
    "out:Running Cost over RSP/MSEK": 9.668,
    "out:LCP/MSEK": -0.252,
    "out:ROI% old": 12.38,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 209,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69175,
    "out:DH kr savings": 69175,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 149.2,
    "out:Primary Energy": 133.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.72,
    "out:CO2 Operational/m2": 49.56,
    "out:Total CO2/m2": 58.28,
    "out:Total CO2 (tons)": 161.26,
    "out:Klimatpaverkan": -5.76,
    "out:Initial Cost/MSEK": 1.955,
    "out:Running cost/(Apt SEK y)": 24904,
    "out:Running Cost over RSP/MSEK": 9.341,
    "out:LCP/MSEK": 0.006,
    "out:ROI% old": 14.33,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 251,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83010,
    "out:DH kr savings": 83010,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 159.2,
    "out:Total Energy Use Post PV": 153.92,
    "out:Primary Energy": 130.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.95,
    "out:CO2 Operational/m2": 34.1,
    "out:Total CO2/m2": 52.05,
    "out:Total CO2 (tons)": 144.02,
    "out:Klimatpaverkan": -23,
    "out:Initial Cost/MSEK": 2.128,
    "out:Running cost/(Apt SEK y)": 25720,
    "out:Running Cost over RSP/MSEK": 9.656,
    "out:LCP/MSEK": -0.482,
    "out:ROI% old": 11.05,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 212,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 55340,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99763,
    "out:DH kr savings": 55340,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 148.92,
    "out:Primary Energy": 126.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.95,
    "out:CO2 Operational/m2": 32.3,
    "out:Total CO2/m2": 50.25,
    "out:Total CO2 (tons)": 139.04,
    "out:Klimatpaverkan": -27.98,
    "out:Initial Cost/MSEK": 2.197,
    "out:Running cost/(Apt SEK y)": 24849,
    "out:Running Cost over RSP/MSEK": 9.33,
    "out:LCP/MSEK": -0.224,
    "out:ROI% old": 12.83,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113598,
    "out:DH kr savings": 69175,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 148.92,
    "out:Primary Energy": 128.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.6,
    "out:CO2 Operational/m2": 32.3,
    "out:Total CO2/m2": 50.9,
    "out:Total CO2 (tons)": 140.85,
    "out:Klimatpaverkan": -26.17,
    "out:Initial Cost/MSEK": 2.346,
    "out:Running cost/(Apt SEK y)": 24849,
    "out:Running Cost over RSP/MSEK": 9.33,
    "out:LCP/MSEK": -0.374,
    "out:ROI% old": 12.01,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113598,
    "out:DH kr savings": 69175,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 143.92,
    "out:Primary Energy": 124.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.6,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 49.1,
    "out:Total CO2 (tons)": 135.87,
    "out:Klimatpaverkan": -31.15,
    "out:Initial Cost/MSEK": 2.415,
    "out:Running cost/(Apt SEK y)": 23979,
    "out:Running Cost over RSP/MSEK": 9.003,
    "out:LCP/MSEK": -0.116,
    "out:ROI% old": 13.6,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 127433,
    "out:DH kr savings": 83010,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 157.26,
    "out:Total Energy Use Post PV": 157.26,
    "out:Primary Energy": 144.43,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.86,
    "out:CO2 Operational/m2": 50.62,
    "out:Total CO2/m2": 62.49,
    "out:Total CO2 (tons)": 172.9,
    "out:Klimatpaverkan": 5.88,
    "out:Initial Cost/MSEK": 2.185,
    "out:Running cost/(Apt SEK y)": 25833,
    "out:Running Cost over RSP/MSEK": 9.686,
    "out:LCP/MSEK": -0.568,
    "out:ROI% old": 10.57,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 38430,
    "out:DH kr savings": 83010,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 152.26,
    "out:Total Energy Use Post PV": 152.26,
    "out:Primary Energy": 140.44,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.86,
    "out:CO2 Operational/m2": 48.82,
    "out:Total CO2/m2": 60.69,
    "out:Total CO2 (tons)": 167.92,
    "out:Klimatpaverkan": 0.9,
    "out:Initial Cost/MSEK": 2.254,
    "out:Running cost/(Apt SEK y)": 24962,
    "out:Running Cost over RSP/MSEK": 9.359,
    "out:LCP/MSEK": -0.311,
    "out:ROI% old": 12.32,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 17.8,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 248,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 52265,
    "out:DH kr savings": 96845,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 150.26,
    "out:Total Energy Use Post PV": 150.26,
    "out:Primary Energy": 141.81,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.52,
    "out:CO2 Operational/m2": 48.1,
    "out:Total CO2/m2": 60.62,
    "out:Total CO2 (tons)": 167.74,
    "out:Klimatpaverkan": 0.72,
    "out:Initial Cost/MSEK": 2.403,
    "out:Running cost/(Apt SEK y)": 24614,
    "out:Running Cost over RSP/MSEK": 9.228,
    "out:LCP/MSEK": -0.329,
    "out:ROI% old": 12.33,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 265,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 57799,
    "out:DH kr savings": 102379,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 146.26,
    "out:Total Energy Use Post PV": 146.26,
    "out:Primary Energy": 137.82,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.52,
    "out:CO2 Operational/m2": 46.66,
    "out:Total CO2/m2": 59.18,
    "out:Total CO2 (tons)": 163.75,
    "out:Klimatpaverkan": -3.27,
    "out:Initial Cost/MSEK": 2.472,
    "out:Running cost/(Apt SEK y)": 23918,
    "out:Running Cost over RSP/MSEK": 8.967,
    "out:LCP/MSEK": -0.137,
    "out:ROI% old": 13.49,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 68867,
    "out:DH kr savings": 113447,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 157.26,
    "out:Total Energy Use Post PV": 150.64,
    "out:Primary Energy": 132.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.75,
    "out:CO2 Operational/m2": 38.2,
    "out:Total CO2/m2": 59.94,
    "out:Total CO2 (tons)": 165.86,
    "out:Klimatpaverkan": -1.16,
    "out:Initial Cost/MSEK": 2.645,
    "out:Running cost/(Apt SEK y)": 24869,
    "out:Running Cost over RSP/MSEK": 9.333,
    "out:LCP/MSEK": -0.677,
    "out:ROI% old": 10.63,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 252,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 84693,
    "out:DH kr savings": 83010,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 152.26,
    "out:Total Energy Use Post PV": 145.64,
    "out:Primary Energy": 128.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.75,
    "out:CO2 Operational/m2": 36.4,
    "out:Total CO2/m2": 58.14,
    "out:Total CO2 (tons)": 160.88,
    "out:Klimatpaverkan": -6.14,
    "out:Initial Cost/MSEK": 2.714,
    "out:Running cost/(Apt SEK y)": 23999,
    "out:Running Cost over RSP/MSEK": 9.007,
    "out:LCP/MSEK": -0.419,
    "out:ROI% old": 12.08,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 294,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 98528,
    "out:DH kr savings": 96845,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 150.26,
    "out:Total Energy Use Post PV": 143.64,
    "out:Primary Energy": 129.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.4,
    "out:CO2 Operational/m2": 35.68,
    "out:Total CO2/m2": 58.08,
    "out:Total CO2 (tons)": 160.7,
    "out:Klimatpaverkan": -6.32,
    "out:Initial Cost/MSEK": 2.864,
    "out:Running cost/(Apt SEK y)": 23650,
    "out:Running Cost over RSP/MSEK": 8.876,
    "out:LCP/MSEK": -0.438,
    "out:ROI% old": 12.1,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 104062,
    "out:DH kr savings": 102379,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 146.26,
    "out:Total Energy Use Post PV": 139.64,
    "out:Primary Energy": 125.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.4,
    "out:CO2 Operational/m2": 34.24,
    "out:Total CO2/m2": 56.64,
    "out:Total CO2 (tons)": 156.71,
    "out:Klimatpaverkan": -10.31,
    "out:Initial Cost/MSEK": 2.933,
    "out:Running cost/(Apt SEK y)": 22954,
    "out:Running Cost over RSP/MSEK": 8.614,
    "out:LCP/MSEK": -0.245,
    "out:ROI% old": 13.09,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 344,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115130,
    "out:DH kr savings": 113447,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 148.26,
    "out:Total Energy Use Post PV": 148.26,
    "out:Primary Energy": 140.93,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.86,
    "out:CO2 Operational/m2": 47.38,
    "out:Total CO2/m2": 59.25,
    "out:Total CO2 (tons)": 163.94,
    "out:Klimatpaverkan": -3.08,
    "out:Initial Cost/MSEK": 2.311,
    "out:Running cost/(Apt SEK y)": 24266,
    "out:Running Cost over RSP/MSEK": 9.097,
    "out:LCP/MSEK": -0.107,
    "out:ROI% old": 13.63,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 281,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 63333,
    "out:DH kr savings": 107913,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 143.26,
    "out:Primary Energy": 136.94,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.86,
    "out:CO2 Operational/m2": 45.58,
    "out:Total CO2/m2": 57.45,
    "out:Total CO2 (tons)": 158.96,
    "out:Klimatpaverkan": -8.06,
    "out:Initial Cost/MSEK": 2.38,
    "out:Running cost/(Apt SEK y)": 23396,
    "out:Running Cost over RSP/MSEK": 8.771,
    "out:LCP/MSEK": 0.151,
    "out:ROI% old": 15.19,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77168,
    "out:DH kr savings": 121748,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 143.26,
    "out:Primary Energy": 138.48,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.52,
    "out:CO2 Operational/m2": 45.58,
    "out:Total CO2/m2": 58.1,
    "out:Total CO2 (tons)": 160.76,
    "out:Klimatpaverkan": -6.26,
    "out:Initial Cost/MSEK": 2.53,
    "out:Running cost/(Apt SEK y)": 23396,
    "out:Running Cost over RSP/MSEK": 8.771,
    "out:LCP/MSEK": 0.001,
    "out:ROI% old": 14.29,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77168,
    "out:DH kr savings": 121748,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.25,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 138.26,
    "out:Total Energy Use Post PV": 138.26,
    "out:Primary Energy": 134.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.52,
    "out:CO2 Operational/m2": 43.78,
    "out:Total CO2/m2": 56.3,
    "out:Total CO2 (tons)": 155.78,
    "out:Klimatpaverkan": -11.24,
    "out:Initial Cost/MSEK": 2.599,
    "out:Running cost/(Apt SEK y)": 22525,
    "out:Running Cost over RSP/MSEK": 8.444,
    "out:LCP/MSEK": 0.259,
    "out:ROI% old": 15.71,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 91003,
    "out:DH kr savings": 135583,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.7,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 148.26,
    "out:Total Energy Use Post PV": 141.64,
    "out:Primary Energy": 129.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.75,
    "out:CO2 Operational/m2": 34.96,
    "out:Total CO2/m2": 56.7,
    "out:Total CO2 (tons)": 156.9,
    "out:Klimatpaverkan": -10.12,
    "out:Initial Cost/MSEK": 2.772,
    "out:Running cost/(Apt SEK y)": 23302,
    "out:Running Cost over RSP/MSEK": 8.745,
    "out:LCP/MSEK": -0.215,
    "out:ROI% old": 13.18,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 328,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 109596,
    "out:DH kr savings": 107913,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 136.64,
    "out:Primary Energy": 125.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.75,
    "out:CO2 Operational/m2": 33.16,
    "out:Total CO2/m2": 54.9,
    "out:Total CO2 (tons)": 151.92,
    "out:Klimatpaverkan": -15.1,
    "out:Initial Cost/MSEK": 2.841,
    "out:Running cost/(Apt SEK y)": 22432,
    "out:Running Cost over RSP/MSEK": 8.418,
    "out:LCP/MSEK": 0.043,
    "out:ROI% old": 14.5,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123431,
    "out:DH kr savings": 121748,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 136.64,
    "out:Primary Energy": 126.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.4,
    "out:CO2 Operational/m2": 33.16,
    "out:Total CO2/m2": 55.56,
    "out:Total CO2 (tons)": 153.72,
    "out:Klimatpaverkan": -13.3,
    "out:Initial Cost/MSEK": 2.99,
    "out:Running cost/(Apt SEK y)": 22432,
    "out:Running Cost over RSP/MSEK": 8.418,
    "out:LCP/MSEK": -0.107,
    "out:ROI% old": 13.78,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123431,
    "out:DH kr savings": 121748,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.25,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 138.26,
    "out:Total Energy Use Post PV": 131.64,
    "out:Primary Energy": 122.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.4,
    "out:CO2 Operational/m2": 31.36,
    "out:Total CO2/m2": 53.76,
    "out:Total CO2 (tons)": 148.74,
    "out:Klimatpaverkan": -18.28,
    "out:Initial Cost/MSEK": 3.06,
    "out:Running cost/(Apt SEK y)": 21561,
    "out:Running Cost over RSP/MSEK": 8.092,
    "out:LCP/MSEK": 0.151,
    "out:ROI% old": 14.99,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 137266,
    "out:DH kr savings": 135583,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.7,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.52,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 167.2,
    "out:Total Energy Use Post PV": 167.2,
    "out:Primary Energy": 142.59,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.69,
    "out:CO2 Operational/m2": 56.04,
    "out:Total CO2/m2": 57.73,
    "out:Total CO2 (tons)": 159.74,
    "out:Klimatpaverkan": -7.28,
    "out:Initial Cost/MSEK": 0.15,
    "out:Running cost/(Apt SEK y)": 28038,
    "out:Running Cost over RSP/MSEK": 10.517,
    "out:LCP/MSEK": 0.634,
    "out:ROI% old": 74.76,
    "out:Payback discounted": 2,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 67,
    "out:Return/kSEK/y": 100,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 33204,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 33204,
    "out:DH kr savings": 33204,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 291975,
    "out:% savings (space heating)": 10.21,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.52,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 162.2,
    "out:Total Energy Use Post PV": 162.2,
    "out:Primary Energy": 138.63,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.69,
    "out:CO2 Operational/m2": 54.24,
    "out:Total CO2/m2": 55.93,
    "out:Total CO2 (tons)": 154.75,
    "out:Klimatpaverkan": -12.27,
    "out:Initial Cost/MSEK": 0.219,
    "out:Running cost/(Apt SEK y)": 27167,
    "out:Running Cost over RSP/MSEK": 10.191,
    "out:LCP/MSEK": 0.892,
    "out:ROI% old": 72.46,
    "out:Payback discounted": 2,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 10.5,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 65,
    "out:Return/kSEK/y": 142,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 21,
    "out:Payback Time": 4.761905,
    "out:DH kWh savings": 47039,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 47039,
    "out:DH kr savings": 47039,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 279458,
    "out:% savings (space heating)": 14.06,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.52,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 160.2,
    "out:Total Energy Use Post PV": 160.2,
    "out:Primary Energy": 139.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.34,
    "out:CO2 Operational/m2": 53.52,
    "out:Total CO2/m2": 55.86,
    "out:Total CO2 (tons)": 154.57,
    "out:Klimatpaverkan": -12.45,
    "out:Initial Cost/MSEK": 0.368,
    "out:Running cost/(Apt SEK y)": 26819,
    "out:Running Cost over RSP/MSEK": 10.06,
    "out:LCP/MSEK": 0.873,
    "out:ROI% old": 48.15,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 43,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 52573,
    "out:DH kr savings": 52573,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 291975,
    "out:% savings (space heating)": 10.21,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.52,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 156.2,
    "out:Primary Energy": 136.01,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.34,
    "out:CO2 Operational/m2": 52.08,
    "out:Total CO2/m2": 54.42,
    "out:Total CO2 (tons)": 150.58,
    "out:Klimatpaverkan": -16.44,
    "out:Initial Cost/MSEK": 0.438,
    "out:Running cost/(Apt SEK y)": 26123,
    "out:Running Cost over RSP/MSEK": 9.798,
    "out:LCP/MSEK": 1.066,
    "out:ROI% old": 49.07,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 44,
    "out:Return/kSEK/y": 192,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 63641,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 63641,
    "out:DH kr savings": 63641,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 279458,
    "out:% savings (space heating)": 14.06,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.52,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 167.2,
    "out:Total Energy Use Post PV": 161.92,
    "out:Primary Energy": 133.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.57,
    "out:CO2 Operational/m2": 36.98,
    "out:Total CO2/m2": 48.55,
    "out:Total CO2 (tons)": 134.34,
    "out:Klimatpaverkan": -32.68,
    "out:Initial Cost/MSEK": 0.61,
    "out:Running cost/(Apt SEK y)": 27112,
    "out:Running Cost over RSP/MSEK": 10.179,
    "out:LCP/MSEK": 0.512,
    "out:ROI% old": 26.27,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 10.5,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 24,
    "out:Return/kSEK/y": 145,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 33204,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77627,
    "out:DH kr savings": 33204,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 291975,
    "out:% savings (space heating)": 10.21,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.52,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 162.2,
    "out:Total Energy Use Post PV": 156.92,
    "out:Primary Energy": 129.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.57,
    "out:CO2 Operational/m2": 35.18,
    "out:Total CO2/m2": 46.75,
    "out:Total CO2 (tons)": 129.36,
    "out:Klimatpaverkan": -37.66,
    "out:Initial Cost/MSEK": 0.68,
    "out:Running cost/(Apt SEK y)": 26242,
    "out:Running Cost over RSP/MSEK": 9.852,
    "out:LCP/MSEK": 0.77,
    "out:ROI% old": 30.47,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 27,
    "out:Return/kSEK/y": 186,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 47039,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 91462,
    "out:DH kr savings": 47039,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 279458,
    "out:% savings (space heating)": 14.06,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.52,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 160.2,
    "out:Total Energy Use Post PV": 154.92,
    "out:Primary Energy": 130.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.22,
    "out:CO2 Operational/m2": 34.46,
    "out:Total CO2/m2": 46.68,
    "out:Total CO2 (tons)": 129.17,
    "out:Klimatpaverkan": -37.85,
    "out:Initial Cost/MSEK": 0.829,
    "out:Running cost/(Apt SEK y)": 25894,
    "out:Running Cost over RSP/MSEK": 9.722,
    "out:LCP/MSEK": 0.751,
    "out:ROI% old": 27.23,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 25,
    "out:Return/kSEK/y": 203,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96996,
    "out:DH kr savings": 52573,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 291975,
    "out:% savings (space heating)": 10.21,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.52,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 150.92,
    "out:Primary Energy": 126.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.22,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 45.24,
    "out:Total CO2 (tons)": 125.19,
    "out:Klimatpaverkan": -41.83,
    "out:Initial Cost/MSEK": 0.898,
    "out:Running cost/(Apt SEK y)": 25197,
    "out:Running Cost over RSP/MSEK": 9.46,
    "out:LCP/MSEK": 0.943,
    "out:ROI% old": 29.29,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 26,
    "out:Return/kSEK/y": 237,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 63641,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 108064,
    "out:DH kr savings": 63641,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 279458,
    "out:% savings (space heating)": 14.06,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.52,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 158.2,
    "out:Total Energy Use Post PV": 158.2,
    "out:Primary Energy": 139.09,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.69,
    "out:CO2 Operational/m2": 52.8,
    "out:Total CO2/m2": 54.49,
    "out:Total CO2 (tons)": 150.77,
    "out:Klimatpaverkan": -16.25,
    "out:Initial Cost/MSEK": 0.277,
    "out:Running cost/(Apt SEK y)": 26471,
    "out:Running Cost over RSP/MSEK": 9.929,
    "out:LCP/MSEK": 1.096,
    "out:ROI% old": 70.9,
    "out:Payback discounted": 2,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 63,
    "out:Return/kSEK/y": 175,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 21,
    "out:Payback Time": 4.761905,
    "out:DH kWh savings": 58107,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 58107,
    "out:DH kr savings": 58107,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 291975,
    "out:% savings (space heating)": 10.21,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.52,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 154.2,
    "out:Primary Energy": 135.13,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.69,
    "out:CO2 Operational/m2": 51.36,
    "out:Total CO2/m2": 53.05,
    "out:Total CO2 (tons)": 146.79,
    "out:Klimatpaverkan": -20.23,
    "out:Initial Cost/MSEK": 0.346,
    "out:Running cost/(Apt SEK y)": 25775,
    "out:Running Cost over RSP/MSEK": 9.668,
    "out:LCP/MSEK": 1.288,
    "out:ROI% old": 67.52,
    "out:Payback discounted": 2,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 60,
    "out:Return/kSEK/y": 209,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 20,
    "out:Payback Time": 5,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 69175,
    "out:DH kr savings": 69175,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 279458,
    "out:% savings (space heating)": 14.06,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.52,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 153.2,
    "out:Total Energy Use Post PV": 153.2,
    "out:Primary Energy": 136.99,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.34,
    "out:CO2 Operational/m2": 51,
    "out:Total CO2/m2": 53.34,
    "out:Total CO2 (tons)": 147.59,
    "out:Klimatpaverkan": -19.43,
    "out:Initial Cost/MSEK": 0.495,
    "out:Running cost/(Apt SEK y)": 25600,
    "out:Running Cost over RSP/MSEK": 9.602,
    "out:LCP/MSEK": 1.204,
    "out:ROI% old": 49.03,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 44,
    "out:Return/kSEK/y": 217,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 71942,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 71942,
    "out:DH kr savings": 71942,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 291975,
    "out:% savings (space heating)": 10.21,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.52,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 148.2,
    "out:Primary Energy": 133.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.34,
    "out:CO2 Operational/m2": 49.2,
    "out:Total CO2/m2": 51.54,
    "out:Total CO2 (tons)": 142.61,
    "out:Klimatpaverkan": -24.41,
    "out:Initial Cost/MSEK": 0.564,
    "out:Running cost/(Apt SEK y)": 24730,
    "out:Running Cost over RSP/MSEK": 9.276,
    "out:LCP/MSEK": 1.462,
    "out:ROI% old": 51.29,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 46,
    "out:Return/kSEK/y": 259,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85777,
    "out:DH kr savings": 85777,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 279458,
    "out:% savings (space heating)": 14.06,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.52,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 158.2,
    "out:Total Energy Use Post PV": 152.92,
    "out:Primary Energy": 129.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.57,
    "out:CO2 Operational/m2": 33.74,
    "out:Total CO2/m2": 45.31,
    "out:Total CO2 (tons)": 125.38,
    "out:Klimatpaverkan": -41.64,
    "out:Initial Cost/MSEK": 0.737,
    "out:Running cost/(Apt SEK y)": 25545,
    "out:Running Cost over RSP/MSEK": 9.591,
    "out:LCP/MSEK": 0.974,
    "out:ROI% old": 33.16,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 30,
    "out:Return/kSEK/y": 220,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 58107,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102530,
    "out:DH kr savings": 58107,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 291975,
    "out:% savings (space heating)": 10.21,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.52,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 148.92,
    "out:Primary Energy": 125.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.57,
    "out:CO2 Operational/m2": 32.3,
    "out:Total CO2/m2": 43.87,
    "out:Total CO2 (tons)": 121.39,
    "out:Klimatpaverkan": -45.63,
    "out:Initial Cost/MSEK": 0.806,
    "out:Running cost/(Apt SEK y)": 24849,
    "out:Running Cost over RSP/MSEK": 9.33,
    "out:LCP/MSEK": 1.166,
    "out:ROI% old": 34.95,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 31,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113598,
    "out:DH kr savings": 69175,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 279458,
    "out:% savings (space heating)": 14.06,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.52,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 153.2,
    "out:Total Energy Use Post PV": 147.92,
    "out:Primary Energy": 127.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.22,
    "out:CO2 Operational/m2": 31.94,
    "out:Total CO2/m2": 44.16,
    "out:Total CO2 (tons)": 122.2,
    "out:Klimatpaverkan": -44.82,
    "out:Initial Cost/MSEK": 0.956,
    "out:Running cost/(Apt SEK y)": 24675,
    "out:Running Cost over RSP/MSEK": 9.264,
    "out:LCP/MSEK": 1.082,
    "out:ROI% old": 30.46,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 27,
    "out:Return/kSEK/y": 262,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 71942,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116365,
    "out:DH kr savings": 71942,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 291975,
    "out:% savings (space heating)": 10.21,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.52,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 142.92,
    "out:Primary Energy": 123.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.22,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 42.36,
    "out:Total CO2 (tons)": 117.22,
    "out:Klimatpaverkan": -49.8,
    "out:Initial Cost/MSEK": 1.025,
    "out:Running cost/(Apt SEK y)": 23805,
    "out:Running Cost over RSP/MSEK": 8.937,
    "out:LCP/MSEK": 1.34,
    "out:ROI% old": 32.96,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 30,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130200,
    "out:DH kr savings": 85777,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 279458,
    "out:% savings (space heating)": 14.06,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.52,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 156.26,
    "out:Total Energy Use Post PV": 156.26,
    "out:Primary Energy": 143.79,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.49,
    "out:CO2 Operational/m2": 50.26,
    "out:Total CO2/m2": 55.75,
    "out:Total CO2 (tons)": 154.26,
    "out:Klimatpaverkan": -12.76,
    "out:Initial Cost/MSEK": 0.794,
    "out:Running cost/(Apt SEK y)": 25659,
    "out:Running Cost over RSP/MSEK": 9.62,
    "out:LCP/MSEK": 0.887,
    "out:ROI% old": 30.25,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 27,
    "out:Return/kSEK/y": 214,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 41197,
    "out:DH kr savings": 85777,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 291975,
    "out:% savings (space heating)": 10.21,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.52,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 151.26,
    "out:Total Energy Use Post PV": 151.26,
    "out:Primary Energy": 139.84,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.49,
    "out:CO2 Operational/m2": 48.46,
    "out:Total CO2/m2": 53.95,
    "out:Total CO2 (tons)": 149.28,
    "out:Klimatpaverkan": -17.74,
    "out:Initial Cost/MSEK": 0.863,
    "out:Running cost/(Apt SEK y)": 24788,
    "out:Running Cost over RSP/MSEK": 9.293,
    "out:LCP/MSEK": 1.145,
    "out:ROI% old": 33.23,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 30,
    "out:Return/kSEK/y": 256,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 55032,
    "out:DH kr savings": 99612,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 279458,
    "out:% savings (space heating)": 14.06,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.52,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 149.26,
    "out:Total Energy Use Post PV": 149.26,
    "out:Primary Energy": 141.17,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.14,
    "out:CO2 Operational/m2": 47.74,
    "out:Total CO2/m2": 53.88,
    "out:Total CO2 (tons)": 149.09,
    "out:Klimatpaverkan": -17.93,
    "out:Initial Cost/MSEK": 1.013,
    "out:Running cost/(Apt SEK y)": 24440,
    "out:Running Cost over RSP/MSEK": 9.163,
    "out:LCP/MSEK": 1.126,
    "out:ROI% old": 30.18,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 27,
    "out:Return/kSEK/y": 273,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 60566,
    "out:DH kr savings": 105146,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 291975,
    "out:% savings (space heating)": 10.21,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.52,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 145.26,
    "out:Total Energy Use Post PV": 145.26,
    "out:Primary Energy": 137.21,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.14,
    "out:CO2 Operational/m2": 46.3,
    "out:Total CO2/m2": 52.44,
    "out:Total CO2 (tons)": 145.11,
    "out:Klimatpaverkan": -21.91,
    "out:Initial Cost/MSEK": 1.082,
    "out:Running cost/(Apt SEK y)": 23744,
    "out:Running Cost over RSP/MSEK": 8.901,
    "out:LCP/MSEK": 1.319,
    "out:ROI% old": 31.7,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 28,
    "out:Return/kSEK/y": 306,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71634,
    "out:DH kr savings": 116214,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 279458,
    "out:% savings (space heating)": 14.06,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.52,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 156.26,
    "out:Total Energy Use Post PV": 149.64,
    "out:Primary Energy": 131.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.37,
    "out:CO2 Operational/m2": 37.84,
    "out:Total CO2/m2": 53.21,
    "out:Total CO2 (tons)": 147.22,
    "out:Klimatpaverkan": -19.8,
    "out:Initial Cost/MSEK": 1.255,
    "out:Running cost/(Apt SEK y)": 24695,
    "out:Running Cost over RSP/MSEK": 9.268,
    "out:LCP/MSEK": 0.779,
    "out:ROI% old": 23.16,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 261,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 87460,
    "out:DH kr savings": 85777,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 291975,
    "out:% savings (space heating)": 10.21,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.52,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 151.26,
    "out:Total Energy Use Post PV": 144.64,
    "out:Primary Energy": 127.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.37,
    "out:CO2 Operational/m2": 36.04,
    "out:Total CO2/m2": 51.41,
    "out:Total CO2 (tons)": 142.24,
    "out:Klimatpaverkan": -24.78,
    "out:Initial Cost/MSEK": 1.324,
    "out:Running cost/(Apt SEK y)": 23825,
    "out:Running Cost over RSP/MSEK": 8.941,
    "out:LCP/MSEK": 1.037,
    "out:ROI% old": 25.47,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 101295,
    "out:DH kr savings": 99612,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 279458,
    "out:% savings (space heating)": 14.06,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.52,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 149.26,
    "out:Total Energy Use Post PV": 142.64,
    "out:Primary Energy": 129.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.02,
    "out:CO2 Operational/m2": 35.32,
    "out:Total CO2/m2": 51.34,
    "out:Total CO2 (tons)": 142.05,
    "out:Klimatpaverkan": -24.97,
    "out:Initial Cost/MSEK": 1.473,
    "out:Running cost/(Apt SEK y)": 23476,
    "out:Running Cost over RSP/MSEK": 8.81,
    "out:LCP/MSEK": 1.018,
    "out:ROI% old": 24.16,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 319,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 106829,
    "out:DH kr savings": 105146,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 291975,
    "out:% savings (space heating)": 10.21,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.52,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 145.26,
    "out:Total Energy Use Post PV": 138.64,
    "out:Primary Energy": 125.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.02,
    "out:CO2 Operational/m2": 33.88,
    "out:Total CO2/m2": 49.9,
    "out:Total CO2 (tons)": 138.07,
    "out:Klimatpaverkan": -28.95,
    "out:Initial Cost/MSEK": 1.542,
    "out:Running cost/(Apt SEK y)": 22780,
    "out:Running Cost over RSP/MSEK": 8.549,
    "out:LCP/MSEK": 1.21,
    "out:ROI% old": 25.5,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 353,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117897,
    "out:DH kr savings": 116214,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 279458,
    "out:% savings (space heating)": 14.06,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.52,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 147.26,
    "out:Total Energy Use Post PV": 147.26,
    "out:Primary Energy": 140.29,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.49,
    "out:CO2 Operational/m2": 47.02,
    "out:Total CO2/m2": 52.51,
    "out:Total CO2 (tons)": 145.29,
    "out:Klimatpaverkan": -21.73,
    "out:Initial Cost/MSEK": 0.921,
    "out:Running cost/(Apt SEK y)": 24092,
    "out:Running Cost over RSP/MSEK": 9.032,
    "out:LCP/MSEK": 1.349,
    "out:ROI% old": 35.21,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 31,
    "out:Return/kSEK/y": 290,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 66100,
    "out:DH kr savings": 110680,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 291975,
    "out:% savings (space heating)": 10.21,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.52,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 143.26,
    "out:Primary Energy": 136.34,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.49,
    "out:CO2 Operational/m2": 45.58,
    "out:Total CO2/m2": 51.07,
    "out:Total CO2 (tons)": 141.31,
    "out:Klimatpaverkan": -25.71,
    "out:Initial Cost/MSEK": 0.99,
    "out:Running cost/(Apt SEK y)": 23396,
    "out:Running Cost over RSP/MSEK": 8.771,
    "out:LCP/MSEK": 1.541,
    "out:ROI% old": 36.53,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 33,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77168,
    "out:DH kr savings": 121748,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 279458,
    "out:% savings (space heating)": 14.06,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.52,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 142.26,
    "out:Total Energy Use Post PV": 142.26,
    "out:Primary Energy": 137.84,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.14,
    "out:CO2 Operational/m2": 45.22,
    "out:Total CO2/m2": 51.36,
    "out:Total CO2 (tons)": 142.12,
    "out:Klimatpaverkan": -24.9,
    "out:Initial Cost/MSEK": 1.139,
    "out:Running cost/(Apt SEK y)": 23222,
    "out:Running Cost over RSP/MSEK": 8.705,
    "out:LCP/MSEK": 1.457,
    "out:ROI% old": 32.56,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 29,
    "out:Return/kSEK/y": 331,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 79935,
    "out:DH kr savings": 124515,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 291975,
    "out:% savings (space heating)": 10.21,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 103.53,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 137.26,
    "out:Primary Energy": 133.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.14,
    "out:CO2 Operational/m2": 43.42,
    "out:Total CO2/m2": 49.56,
    "out:Total CO2 (tons)": 137.14,
    "out:Klimatpaverkan": -29.88,
    "out:Initial Cost/MSEK": 1.209,
    "out:Running cost/(Apt SEK y)": 22351,
    "out:Running Cost over RSP/MSEK": 8.378,
    "out:LCP/MSEK": 1.715,
    "out:ROI% old": 34.56,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 31,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93770,
    "out:DH kr savings": 138350,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 279458,
    "out:% savings (space heating)": 14.06,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.52,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 147.26,
    "out:Total Energy Use Post PV": 140.64,
    "out:Primary Energy": 128.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.37,
    "out:CO2 Operational/m2": 34.6,
    "out:Total CO2/m2": 49.97,
    "out:Total CO2 (tons)": 138.25,
    "out:Klimatpaverkan": -28.77,
    "out:Initial Cost/MSEK": 1.381,
    "out:Running cost/(Apt SEK y)": 23128,
    "out:Running Cost over RSP/MSEK": 8.68,
    "out:LCP/MSEK": 1.241,
    "out:ROI% old": 27.12,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 24,
    "out:Return/kSEK/y": 336,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 112363,
    "out:DH kr savings": 110680,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 291975,
    "out:% savings (space heating)": 10.21,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.52,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 136.64,
    "out:Primary Energy": 124.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.37,
    "out:CO2 Operational/m2": 33.16,
    "out:Total CO2/m2": 48.53,
    "out:Total CO2 (tons)": 134.27,
    "out:Klimatpaverkan": -32.75,
    "out:Initial Cost/MSEK": 1.45,
    "out:Running cost/(Apt SEK y)": 22432,
    "out:Running Cost over RSP/MSEK": 8.418,
    "out:LCP/MSEK": 1.433,
    "out:ROI% old": 28.4,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123431,
    "out:DH kr savings": 121748,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 279458,
    "out:% savings (space heating)": 14.06,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.52,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 142.26,
    "out:Total Energy Use Post PV": 135.64,
    "out:Primary Energy": 125.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.02,
    "out:CO2 Operational/m2": 32.8,
    "out:Total CO2/m2": 48.82,
    "out:Total CO2 (tons)": 135.08,
    "out:Klimatpaverkan": -31.94,
    "out:Initial Cost/MSEK": 1.6,
    "out:Running cost/(Apt SEK y)": 22258,
    "out:Running Cost over RSP/MSEK": 8.353,
    "out:LCP/MSEK": 1.349,
    "out:ROI% old": 26.33,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 24,
    "out:Return/kSEK/y": 378,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126198,
    "out:DH kr savings": 124515,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 291975,
    "out:% savings (space heating)": 10.21,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 103.53,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 130.64,
    "out:Primary Energy": 121.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.02,
    "out:CO2 Operational/m2": 31,
    "out:Total CO2/m2": 47.02,
    "out:Total CO2 (tons)": 130.1,
    "out:Klimatpaverkan": -36.92,
    "out:Initial Cost/MSEK": 1.669,
    "out:Running cost/(Apt SEK y)": 21387,
    "out:Running Cost over RSP/MSEK": 8.026,
    "out:LCP/MSEK": 1.607,
    "out:ROI% old": 28.04,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140033,
    "out:DH kr savings": 138350,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 279458,
    "out:% savings (space heating)": 14.06,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.8,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 158.2,
    "out:Total Energy Use Post PV": 158.2,
    "out:Primary Energy": 134.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4,
    "out:CO2 Operational/m2": 52.8,
    "out:Total CO2/m2": 56.8,
    "out:Total CO2 (tons)": 157.17,
    "out:Klimatpaverkan": -9.85,
    "out:Initial Cost/MSEK": 0.597,
    "out:Running cost/(Apt SEK y)": 26471,
    "out:Running Cost over RSP/MSEK": 9.929,
    "out:LCP/MSEK": 0.776,
    "out:ROI% old": 32.87,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 29,
    "out:Return/kSEK/y": 175,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 58107,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 58107,
    "out:DH kr savings": 58107,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 267839,
    "out:% savings (space heating)": 17.63,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.8,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.67,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 154.2,
    "out:Primary Energy": 131.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4,
    "out:CO2 Operational/m2": 51.36,
    "out:Total CO2/m2": 55.36,
    "out:Total CO2 (tons)": 153.19,
    "out:Klimatpaverkan": -13.83,
    "out:Initial Cost/MSEK": 0.666,
    "out:Running cost/(Apt SEK y)": 25775,
    "out:Running Cost over RSP/MSEK": 9.668,
    "out:LCP/MSEK": 0.968,
    "out:ROI% old": 35.07,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 31,
    "out:Return/kSEK/y": 209,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 69175,
    "out:DH kr savings": 69175,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 256420,
    "out:% savings (space heating)": 21.15,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.67,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.8,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 152.2,
    "out:Primary Energy": 132.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.66,
    "out:CO2 Operational/m2": 50.64,
    "out:Total CO2/m2": 55.3,
    "out:Total CO2 (tons)": 153,
    "out:Klimatpaverkan": -14.02,
    "out:Initial Cost/MSEK": 0.815,
    "out:Running cost/(Apt SEK y)": 25426,
    "out:Running Cost over RSP/MSEK": 9.537,
    "out:LCP/MSEK": 0.95,
    "out:ROI% old": 30.93,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 17.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 28,
    "out:Return/kSEK/y": 226,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 74709,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74709,
    "out:DH kr savings": 74709,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 267839,
    "out:% savings (space heating)": 17.63,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.8,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.67,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 147.2,
    "out:Primary Energy": 128.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.66,
    "out:CO2 Operational/m2": 48.84,
    "out:Total CO2/m2": 53.5,
    "out:Total CO2 (tons)": 148.02,
    "out:Klimatpaverkan": -19,
    "out:Initial Cost/MSEK": 0.884,
    "out:Running cost/(Apt SEK y)": 24556,
    "out:Running Cost over RSP/MSEK": 9.21,
    "out:LCP/MSEK": 1.207,
    "out:ROI% old": 33.79,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 30,
    "out:Return/kSEK/y": 267,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88544,
    "out:DH kr savings": 88544,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 256420,
    "out:% savings (space heating)": 21.15,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.67,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.8,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 158.2,
    "out:Total Energy Use Post PV": 152.92,
    "out:Primary Energy": 125.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.89,
    "out:CO2 Operational/m2": 33.74,
    "out:Total CO2/m2": 47.63,
    "out:Total CO2 (tons)": 131.78,
    "out:Klimatpaverkan": -35.24,
    "out:Initial Cost/MSEK": 1.057,
    "out:Running cost/(Apt SEK y)": 25545,
    "out:Running Cost over RSP/MSEK": 9.591,
    "out:LCP/MSEK": 0.654,
    "out:ROI% old": 23.12,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 220,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 58107,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102530,
    "out:DH kr savings": 58107,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 267839,
    "out:% savings (space heating)": 17.63,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.8,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.67,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 148.92,
    "out:Primary Energy": 121.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.89,
    "out:CO2 Operational/m2": 32.3,
    "out:Total CO2/m2": 46.19,
    "out:Total CO2 (tons)": 127.8,
    "out:Klimatpaverkan": -39.22,
    "out:Initial Cost/MSEK": 1.126,
    "out:Running cost/(Apt SEK y)": 24849,
    "out:Running Cost over RSP/MSEK": 9.33,
    "out:LCP/MSEK": 0.846,
    "out:ROI% old": 25.02,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113598,
    "out:DH kr savings": 69175,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 256420,
    "out:% savings (space heating)": 21.15,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.67,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.8,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 146.92,
    "out:Primary Energy": 122.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.54,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 46.12,
    "out:Total CO2 (tons)": 127.61,
    "out:Klimatpaverkan": -39.41,
    "out:Initial Cost/MSEK": 1.276,
    "out:Running cost/(Apt SEK y)": 24501,
    "out:Running Cost over RSP/MSEK": 9.199,
    "out:LCP/MSEK": 0.827,
    "out:ROI% old": 23.55,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 270,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 74709,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119132,
    "out:DH kr savings": 74709,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 267839,
    "out:% savings (space heating)": 17.63,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.8,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.67,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 141.92,
    "out:Primary Energy": 119.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.54,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 44.32,
    "out:Total CO2 (tons)": 122.63,
    "out:Klimatpaverkan": -44.39,
    "out:Initial Cost/MSEK": 1.345,
    "out:Running cost/(Apt SEK y)": 23630,
    "out:Running Cost over RSP/MSEK": 8.872,
    "out:LCP/MSEK": 1.085,
    "out:ROI% old": 25.81,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 23,
    "out:Return/kSEK/y": 312,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132967,
    "out:DH kr savings": 88544,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 256420,
    "out:% savings (space heating)": 21.15,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.67,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.8,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 149.2,
    "out:Primary Energy": 131.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4,
    "out:CO2 Operational/m2": 49.56,
    "out:Total CO2/m2": 53.56,
    "out:Total CO2 (tons)": 148.21,
    "out:Klimatpaverkan": -18.81,
    "out:Initial Cost/MSEK": 0.723,
    "out:Running cost/(Apt SEK y)": 24904,
    "out:Running Cost over RSP/MSEK": 9.341,
    "out:LCP/MSEK": 1.238,
    "out:ROI% old": 38.73,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 35,
    "out:Return/kSEK/y": 251,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83010,
    "out:DH kr savings": 83010,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 267839,
    "out:% savings (space heating)": 17.63,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.8,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.67,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 145.2,
    "out:Primary Energy": 127.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4,
    "out:CO2 Operational/m2": 48.12,
    "out:Total CO2/m2": 52.12,
    "out:Total CO2 (tons)": 144.23,
    "out:Klimatpaverkan": -22.79,
    "out:Initial Cost/MSEK": 0.792,
    "out:Running cost/(Apt SEK y)": 24208,
    "out:Running Cost over RSP/MSEK": 9.079,
    "out:LCP/MSEK": 1.43,
    "out:ROI% old": 40.06,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 36,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 94078,
    "out:DH kr savings": 94078,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 256420,
    "out:% savings (space heating)": 21.15,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.67,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.8,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 144.2,
    "out:Total Energy Use Post PV": 144.2,
    "out:Primary Energy": 129.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.66,
    "out:CO2 Operational/m2": 47.76,
    "out:Total CO2/m2": 52.42,
    "out:Total CO2 (tons)": 145.03,
    "out:Klimatpaverkan": -21.99,
    "out:Initial Cost/MSEK": 0.942,
    "out:Running cost/(Apt SEK y)": 24034,
    "out:Running Cost over RSP/MSEK": 9.014,
    "out:LCP/MSEK": 1.346,
    "out:ROI% old": 34.7,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 31,
    "out:Return/kSEK/y": 292,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96845,
    "out:DH kr savings": 96845,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 267839,
    "out:% savings (space heating)": 17.63,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.8,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.67,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 140.2,
    "out:Primary Energy": 125.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.66,
    "out:CO2 Operational/m2": 46.32,
    "out:Total CO2/m2": 50.98,
    "out:Total CO2 (tons)": 141.05,
    "out:Klimatpaverkan": -25.97,
    "out:Initial Cost/MSEK": 1.011,
    "out:Running cost/(Apt SEK y)": 23337,
    "out:Running Cost over RSP/MSEK": 8.753,
    "out:LCP/MSEK": 1.538,
    "out:ROI% old": 36.02,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 32,
    "out:Return/kSEK/y": 326,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107913,
    "out:DH kr savings": 107913,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 256420,
    "out:% savings (space heating)": 21.15,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.67,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.8,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 143.92,
    "out:Primary Energy": 121.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.89,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 44.39,
    "out:Total CO2 (tons)": 122.82,
    "out:Klimatpaverkan": -44.2,
    "out:Initial Cost/MSEK": 1.184,
    "out:Running cost/(Apt SEK y)": 23979,
    "out:Running Cost over RSP/MSEK": 9.003,
    "out:LCP/MSEK": 1.115,
    "out:ROI% old": 27.75,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 25,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127433,
    "out:DH kr savings": 83010,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 267839,
    "out:% savings (space heating)": 17.63,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.8,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.67,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 139.92,
    "out:Primary Energy": 118.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.89,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 42.95,
    "out:Total CO2 (tons)": 118.83,
    "out:Klimatpaverkan": -48.19,
    "out:Initial Cost/MSEK": 1.253,
    "out:Running cost/(Apt SEK y)": 23282,
    "out:Running Cost over RSP/MSEK": 8.741,
    "out:LCP/MSEK": 1.308,
    "out:ROI% old": 29.2,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 26,
    "out:Return/kSEK/y": 329,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138501,
    "out:DH kr savings": 94078,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 256420,
    "out:% savings (space heating)": 21.15,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.67,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.8,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 144.2,
    "out:Total Energy Use Post PV": 138.92,
    "out:Primary Energy": 119.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.54,
    "out:CO2 Operational/m2": 28.7,
    "out:Total CO2/m2": 43.24,
    "out:Total CO2 (tons)": 119.64,
    "out:Klimatpaverkan": -47.38,
    "out:Initial Cost/MSEK": 1.402,
    "out:Running cost/(Apt SEK y)": 23108,
    "out:Running Cost over RSP/MSEK": 8.676,
    "out:LCP/MSEK": 1.224,
    "out:ROI% old": 26.75,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 24,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141268,
    "out:DH kr savings": 96845,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 267839,
    "out:% savings (space heating)": 17.63,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.8,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.67,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 134.92,
    "out:Primary Energy": 116.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.54,
    "out:CO2 Operational/m2": 27.26,
    "out:Total CO2/m2": 41.8,
    "out:Total CO2 (tons)": 115.66,
    "out:Klimatpaverkan": -51.36,
    "out:Initial Cost/MSEK": 1.471,
    "out:Running cost/(Apt SEK y)": 22412,
    "out:Running Cost over RSP/MSEK": 8.415,
    "out:LCP/MSEK": 1.416,
    "out:ROI% old": 28.03,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 25,
    "out:Return/kSEK/y": 370,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152336,
    "out:DH kr savings": 107913,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 256420,
    "out:% savings (space heating)": 21.15,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.67,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.8,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 147.26,
    "out:Total Energy Use Post PV": 147.26,
    "out:Primary Energy": 136.16,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.8,
    "out:CO2 Operational/m2": 47.02,
    "out:Total CO2/m2": 54.82,
    "out:Total CO2 (tons)": 151.7,
    "out:Klimatpaverkan": -15.32,
    "out:Initial Cost/MSEK": 1.241,
    "out:Running cost/(Apt SEK y)": 24092,
    "out:Running Cost over RSP/MSEK": 9.032,
    "out:LCP/MSEK": 1.029,
    "out:ROI% old": 26.13,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 290,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 66100,
    "out:DH kr savings": 110680,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 267839,
    "out:% savings (space heating)": 17.63,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.8,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.67,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 143.26,
    "out:Primary Energy": 132.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.8,
    "out:CO2 Operational/m2": 45.58,
    "out:Total CO2/m2": 53.38,
    "out:Total CO2 (tons)": 147.71,
    "out:Klimatpaverkan": -19.31,
    "out:Initial Cost/MSEK": 1.31,
    "out:Running cost/(Apt SEK y)": 23396,
    "out:Running Cost over RSP/MSEK": 8.771,
    "out:LCP/MSEK": 1.221,
    "out:ROI% old": 27.6,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77168,
    "out:DH kr savings": 121748,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 256420,
    "out:% savings (space heating)": 21.15,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.67,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.8,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 141.26,
    "out:Total Energy Use Post PV": 141.26,
    "out:Primary Energy": 133.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.45,
    "out:CO2 Operational/m2": 44.86,
    "out:Total CO2/m2": 53.32,
    "out:Total CO2 (tons)": 147.53,
    "out:Klimatpaverkan": -19.49,
    "out:Initial Cost/MSEK": 1.459,
    "out:Running cost/(Apt SEK y)": 23047,
    "out:Running Cost over RSP/MSEK": 8.64,
    "out:LCP/MSEK": 1.203,
    "out:ROI% old": 26.06,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82702,
    "out:DH kr savings": 127282,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 267839,
    "out:% savings (space heating)": 17.63,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.8,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.67,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 136.26,
    "out:Primary Energy": 129.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.45,
    "out:CO2 Operational/m2": 43.06,
    "out:Total CO2/m2": 51.52,
    "out:Total CO2 (tons)": 142.55,
    "out:Klimatpaverkan": -24.47,
    "out:Initial Cost/MSEK": 1.529,
    "out:Running cost/(Apt SEK y)": 22177,
    "out:Running Cost over RSP/MSEK": 8.313,
    "out:LCP/MSEK": 1.46,
    "out:ROI% old": 27.93,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 96537,
    "out:DH kr savings": 141117,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 256420,
    "out:% savings (space heating)": 21.15,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.67,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.8,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 147.26,
    "out:Total Energy Use Post PV": 140.64,
    "out:Primary Energy": 124.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.68,
    "out:CO2 Operational/m2": 34.6,
    "out:Total CO2/m2": 52.28,
    "out:Total CO2 (tons)": 144.66,
    "out:Klimatpaverkan": -22.36,
    "out:Initial Cost/MSEK": 1.701,
    "out:Running cost/(Apt SEK y)": 23128,
    "out:Running Cost over RSP/MSEK": 8.68,
    "out:LCP/MSEK": 0.921,
    "out:ROI% old": 22.02,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 336,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 112363,
    "out:DH kr savings": 110680,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 267839,
    "out:% savings (space heating)": 17.63,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.8,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.67,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 136.64,
    "out:Primary Energy": 120.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.68,
    "out:CO2 Operational/m2": 33.16,
    "out:Total CO2/m2": 50.84,
    "out:Total CO2 (tons)": 140.67,
    "out:Klimatpaverkan": -26.35,
    "out:Initial Cost/MSEK": 1.77,
    "out:Running cost/(Apt SEK y)": 22432,
    "out:Running Cost over RSP/MSEK": 8.418,
    "out:LCP/MSEK": 1.113,
    "out:ROI% old": 23.27,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123431,
    "out:DH kr savings": 121748,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 256420,
    "out:% savings (space heating)": 21.15,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.67,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.8,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 141.26,
    "out:Total Energy Use Post PV": 134.64,
    "out:Primary Energy": 121.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.33,
    "out:CO2 Operational/m2": 32.44,
    "out:Total CO2/m2": 50.77,
    "out:Total CO2 (tons)": 140.49,
    "out:Klimatpaverkan": -26.53,
    "out:Initial Cost/MSEK": 1.92,
    "out:Running cost/(Apt SEK y)": 22084,
    "out:Running Cost over RSP/MSEK": 8.288,
    "out:LCP/MSEK": 1.094,
    "out:ROI% old": 22.43,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128965,
    "out:DH kr savings": 127282,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 267839,
    "out:% savings (space heating)": 17.63,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.8,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.67,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 129.64,
    "out:Primary Energy": 118.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.33,
    "out:CO2 Operational/m2": 30.64,
    "out:Total CO2/m2": 48.97,
    "out:Total CO2 (tons)": 135.51,
    "out:Klimatpaverkan": -31.51,
    "out:Initial Cost/MSEK": 1.989,
    "out:Running cost/(Apt SEK y)": 21213,
    "out:Running Cost over RSP/MSEK": 7.961,
    "out:LCP/MSEK": 1.352,
    "out:ROI% old": 24,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142800,
    "out:DH kr savings": 141117,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 256420,
    "out:% savings (space heating)": 21.15,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.67,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.8,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 138.26,
    "out:Total Energy Use Post PV": 138.26,
    "out:Primary Energy": 132.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.8,
    "out:CO2 Operational/m2": 43.78,
    "out:Total CO2/m2": 51.58,
    "out:Total CO2 (tons)": 142.73,
    "out:Klimatpaverkan": -24.29,
    "out:Initial Cost/MSEK": 1.368,
    "out:Running cost/(Apt SEK y)": 22525,
    "out:Running Cost over RSP/MSEK": 8.444,
    "out:LCP/MSEK": 1.491,
    "out:ROI% old": 29.86,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 27,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91003,
    "out:DH kr savings": 135583,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 267839,
    "out:% savings (space heating)": 17.63,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.8,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.67,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 134.26,
    "out:Primary Energy": 129.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.8,
    "out:CO2 Operational/m2": 42.34,
    "out:Total CO2/m2": 50.14,
    "out:Total CO2 (tons)": 138.75,
    "out:Klimatpaverkan": -28.27,
    "out:Initial Cost/MSEK": 1.437,
    "out:Running cost/(Apt SEK y)": 21829,
    "out:Running Cost over RSP/MSEK": 8.182,
    "out:LCP/MSEK": 1.683,
    "out:ROI% old": 31.02,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 28,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102071,
    "out:DH kr savings": 146651,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 256420,
    "out:% savings (space heating)": 21.15,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.67,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.8,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 133.26,
    "out:Total Energy Use Post PV": 133.26,
    "out:Primary Energy": 130.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.45,
    "out:CO2 Operational/m2": 41.98,
    "out:Total CO2/m2": 50.44,
    "out:Total CO2 (tons)": 139.56,
    "out:Klimatpaverkan": -27.46,
    "out:Initial Cost/MSEK": 1.586,
    "out:Running cost/(Apt SEK y)": 21655,
    "out:Running Cost over RSP/MSEK": 8.117,
    "out:LCP/MSEK": 1.599,
    "out:ROI% old": 28.69,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 26,
    "out:Return/kSEK/y": 407,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 104838,
    "out:DH kr savings": 149418,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 267839,
    "out:% savings (space heating)": 17.63,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.8,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.67,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 129.26,
    "out:Total Energy Use Post PV": 129.26,
    "out:Primary Energy": 126.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.45,
    "out:CO2 Operational/m2": 40.54,
    "out:Total CO2/m2": 49,
    "out:Total CO2 (tons)": 135.57,
    "out:Klimatpaverkan": -31.45,
    "out:Initial Cost/MSEK": 1.655,
    "out:Running cost/(Apt SEK y)": 20958,
    "out:Running Cost over RSP/MSEK": 7.855,
    "out:LCP/MSEK": 1.791,
    "out:ROI% old": 29.74,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 27,
    "out:Return/kSEK/y": 440,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115906,
    "out:DH kr savings": 160486,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 256420,
    "out:% savings (space heating)": 21.15,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.68,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.8,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 138.26,
    "out:Total Energy Use Post PV": 131.64,
    "out:Primary Energy": 120.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.68,
    "out:CO2 Operational/m2": 31.36,
    "out:Total CO2/m2": 49.04,
    "out:Total CO2 (tons)": 135.69,
    "out:Klimatpaverkan": -31.33,
    "out:Initial Cost/MSEK": 1.828,
    "out:Running cost/(Apt SEK y)": 21561,
    "out:Running Cost over RSP/MSEK": 8.092,
    "out:LCP/MSEK": 1.382,
    "out:ROI% old": 25.09,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137266,
    "out:DH kr savings": 135583,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 267839,
    "out:% savings (space heating)": 17.63,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.8,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.67,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 127.64,
    "out:Primary Energy": 117.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.68,
    "out:CO2 Operational/m2": 29.92,
    "out:Total CO2/m2": 47.6,
    "out:Total CO2 (tons)": 131.71,
    "out:Klimatpaverkan": -35.31,
    "out:Initial Cost/MSEK": 1.897,
    "out:Running cost/(Apt SEK y)": 20865,
    "out:Running Cost over RSP/MSEK": 7.83,
    "out:LCP/MSEK": 1.575,
    "out:ROI% old": 26.14,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148334,
    "out:DH kr savings": 146651,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 256420,
    "out:% savings (space heating)": 21.15,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.67,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.8,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 133.26,
    "out:Total Energy Use Post PV": 126.64,
    "out:Primary Energy": 118.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.33,
    "out:CO2 Operational/m2": 29.56,
    "out:Total CO2/m2": 47.89,
    "out:Total CO2 (tons)": 132.52,
    "out:Klimatpaverkan": -34.5,
    "out:Initial Cost/MSEK": 2.047,
    "out:Running cost/(Apt SEK y)": 20691,
    "out:Running Cost over RSP/MSEK": 7.765,
    "out:LCP/MSEK": 1.491,
    "out:ROI% old": 24.69,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 453,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151101,
    "out:DH kr savings": 149418,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 267839,
    "out:% savings (space heating)": 17.63,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.8,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.67,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 129.26,
    "out:Total Energy Use Post PV": 122.64,
    "out:Primary Energy": 114.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.33,
    "out:CO2 Operational/m2": 28.12,
    "out:Total CO2/m2": 46.45,
    "out:Total CO2 (tons)": 128.53,
    "out:Klimatpaverkan": -38.49,
    "out:Initial Cost/MSEK": 2.116,
    "out:Running cost/(Apt SEK y)": 19995,
    "out:Running Cost over RSP/MSEK": 7.503,
    "out:LCP/MSEK": 1.683,
    "out:ROI% old": 25.65,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162169,
    "out:DH kr savings": 160486,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 256420,
    "out:% savings (space heating)": 21.15,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.68,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.92,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 155.2,
    "out:Primary Energy": 132.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.76,
    "out:CO2 Operational/m2": 51.72,
    "out:Total CO2/m2": 61.48,
    "out:Total CO2 (tons)": 170.1,
    "out:Klimatpaverkan": 3.08,
    "out:Initial Cost/MSEK": 1.69,
    "out:Running cost/(Apt SEK y)": 25949,
    "out:Running Cost over RSP/MSEK": 9.733,
    "out:LCP/MSEK": -0.122,
    "out:ROI% old": 13.26,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 201,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 66408,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 66408,
    "out:DH kr savings": 66408,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 259879,
    "out:% savings (space heating)": 20.08,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.92,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.92,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 151.2,
    "out:Total Energy Use Post PV": 151.2,
    "out:Primary Energy": 128.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.76,
    "out:CO2 Operational/m2": 50.28,
    "out:Total CO2/m2": 60.04,
    "out:Total CO2 (tons)": 166.12,
    "out:Klimatpaverkan": -0.9,
    "out:Initial Cost/MSEK": 1.759,
    "out:Running cost/(Apt SEK y)": 25252,
    "out:Running Cost over RSP/MSEK": 9.472,
    "out:LCP/MSEK": 0.071,
    "out:ROI% old": 14.86,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 234,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77476,
    "out:DH kr savings": 77476,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 248821,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.92,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.92,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 149.2,
    "out:Primary Energy": 129.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.41,
    "out:CO2 Operational/m2": 49.56,
    "out:Total CO2/m2": 59.97,
    "out:Total CO2 (tons)": 165.93,
    "out:Klimatpaverkan": -1.09,
    "out:Initial Cost/MSEK": 1.909,
    "out:Running cost/(Apt SEK y)": 24904,
    "out:Running Cost over RSP/MSEK": 9.341,
    "out:LCP/MSEK": 0.052,
    "out:ROI% old": 14.68,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 251,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83010,
    "out:DH kr savings": 83010,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 259879,
    "out:% savings (space heating)": 20.08,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.92,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.92,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 145.2,
    "out:Primary Energy": 126.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.41,
    "out:CO2 Operational/m2": 48.12,
    "out:Total CO2/m2": 58.53,
    "out:Total CO2 (tons)": 161.95,
    "out:Klimatpaverkan": -5.07,
    "out:Initial Cost/MSEK": 1.978,
    "out:Running cost/(Apt SEK y)": 24208,
    "out:Running Cost over RSP/MSEK": 9.079,
    "out:LCP/MSEK": 0.244,
    "out:ROI% old": 16.05,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 14,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 94078,
    "out:DH kr savings": 94078,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 248821,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.92,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.92,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 149.92,
    "out:Primary Energy": 122.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.64,
    "out:CO2 Operational/m2": 32.66,
    "out:Total CO2/m2": 52.3,
    "out:Total CO2 (tons)": 144.71,
    "out:Klimatpaverkan": -22.31,
    "out:Initial Cost/MSEK": 2.151,
    "out:Running cost/(Apt SEK y)": 25023,
    "out:Running Cost over RSP/MSEK": 9.395,
    "out:LCP/MSEK": -0.244,
    "out:ROI% old": 12.67,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 245,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 66408,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110831,
    "out:DH kr savings": 66408,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 259879,
    "out:% savings (space heating)": 20.08,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.92,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.92,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 151.2,
    "out:Total Energy Use Post PV": 145.92,
    "out:Primary Energy": 119.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.64,
    "out:CO2 Operational/m2": 31.22,
    "out:Total CO2/m2": 50.86,
    "out:Total CO2 (tons)": 140.72,
    "out:Klimatpaverkan": -26.3,
    "out:Initial Cost/MSEK": 2.22,
    "out:Running cost/(Apt SEK y)": 24327,
    "out:Running Cost over RSP/MSEK": 9.133,
    "out:LCP/MSEK": -0.052,
    "out:ROI% old": 13.95,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 278,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121899,
    "out:DH kr savings": 77476,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 248821,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.92,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.92,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 143.92,
    "out:Primary Energy": 120.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.29,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 50.79,
    "out:Total CO2 (tons)": 140.54,
    "out:Klimatpaverkan": -26.48,
    "out:Initial Cost/MSEK": 2.369,
    "out:Running cost/(Apt SEK y)": 23979,
    "out:Running Cost over RSP/MSEK": 9.003,
    "out:LCP/MSEK": -0.07,
    "out:ROI% old": 13.86,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127433,
    "out:DH kr savings": 83010,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 259879,
    "out:% savings (space heating)": 20.08,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.92,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.92,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 139.92,
    "out:Primary Energy": 116.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.29,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 49.35,
    "out:Total CO2 (tons)": 136.55,
    "out:Klimatpaverkan": -30.47,
    "out:Initial Cost/MSEK": 2.438,
    "out:Running cost/(Apt SEK y)": 23282,
    "out:Running Cost over RSP/MSEK": 8.741,
    "out:LCP/MSEK": 0.122,
    "out:ROI% old": 15,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 329,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138501,
    "out:DH kr savings": 94078,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 248821,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.92,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.92,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 147.2,
    "out:Primary Energy": 128.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.76,
    "out:CO2 Operational/m2": 48.84,
    "out:Total CO2/m2": 58.6,
    "out:Total CO2 (tons)": 162.14,
    "out:Klimatpaverkan": -4.88,
    "out:Initial Cost/MSEK": 1.817,
    "out:Running cost/(Apt SEK y)": 24556,
    "out:Running Cost over RSP/MSEK": 9.21,
    "out:LCP/MSEK": 0.275,
    "out:ROI% old": 16.45,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 15,
    "out:Return/kSEK/y": 267,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88544,
    "out:DH kr savings": 88544,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 259879,
    "out:% savings (space heating)": 20.08,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.92,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.92,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 143.2,
    "out:Primary Energy": 125.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.76,
    "out:CO2 Operational/m2": 47.4,
    "out:Total CO2/m2": 57.16,
    "out:Total CO2 (tons)": 158.15,
    "out:Klimatpaverkan": -8.87,
    "out:Initial Cost/MSEK": 1.886,
    "out:Running cost/(Apt SEK y)": 23860,
    "out:Running Cost over RSP/MSEK": 8.949,
    "out:LCP/MSEK": 0.467,
    "out:ROI% old": 17.82,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 16,
    "out:Return/kSEK/y": 301,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99612,
    "out:DH kr savings": 99612,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 248821,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.92,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.92,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 141.2,
    "out:Total Energy Use Post PV": 141.2,
    "out:Primary Energy": 126.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.41,
    "out:CO2 Operational/m2": 46.68,
    "out:Total CO2/m2": 57.09,
    "out:Total CO2 (tons)": 157.96,
    "out:Klimatpaverkan": -9.06,
    "out:Initial Cost/MSEK": 2.036,
    "out:Running cost/(Apt SEK y)": 23511,
    "out:Running Cost over RSP/MSEK": 8.818,
    "out:LCP/MSEK": 0.448,
    "out:ROI% old": 17.43,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 16,
    "out:Return/kSEK/y": 318,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105146,
    "out:DH kr savings": 105146,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 259879,
    "out:% savings (space heating)": 20.08,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.92,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.92,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 137.2,
    "out:Total Energy Use Post PV": 137.2,
    "out:Primary Energy": 123.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.41,
    "out:CO2 Operational/m2": 45.24,
    "out:Total CO2/m2": 55.65,
    "out:Total CO2 (tons)": 153.98,
    "out:Klimatpaverkan": -13.04,
    "out:Initial Cost/MSEK": 2.105,
    "out:Running cost/(Apt SEK y)": 22815,
    "out:Running Cost over RSP/MSEK": 8.557,
    "out:LCP/MSEK": 0.641,
    "out:ROI% old": 18.63,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 17,
    "out:Return/kSEK/y": 351,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116214,
    "out:DH kr savings": 116214,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 248821,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.92,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00001200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.92,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 141.92,
    "out:Primary Energy": 119.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.64,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 49.42,
    "out:Total CO2 (tons)": 136.74,
    "out:Klimatpaverkan": -30.28,
    "out:Initial Cost/MSEK": 2.277,
    "out:Running cost/(Apt SEK y)": 23630,
    "out:Running Cost over RSP/MSEK": 8.872,
    "out:LCP/MSEK": 0.152,
    "out:ROI% old": 15.24,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 312,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132967,
    "out:DH kr savings": 88544,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 259879,
    "out:% savings (space heating)": 20.08,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.92,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.92,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 137.92,
    "out:Primary Energy": 115.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.64,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 47.98,
    "out:Total CO2 (tons)": 132.76,
    "out:Klimatpaverkan": -34.26,
    "out:Initial Cost/MSEK": 2.347,
    "out:Running cost/(Apt SEK y)": 22934,
    "out:Running Cost over RSP/MSEK": 8.611,
    "out:LCP/MSEK": 0.345,
    "out:ROI% old": 16.38,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 345,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 144035,
    "out:DH kr savings": 99612,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 248821,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.92,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.92,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 141.2,
    "out:Total Energy Use Post PV": 135.92,
    "out:Primary Energy": 117.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.29,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 47.91,
    "out:Total CO2 (tons)": 132.57,
    "out:Klimatpaverkan": -34.45,
    "out:Initial Cost/MSEK": 2.496,
    "out:Running cost/(Apt SEK y)": 22586,
    "out:Running Cost over RSP/MSEK": 8.48,
    "out:LCP/MSEK": 0.326,
    "out:ROI% old": 16.15,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149569,
    "out:DH kr savings": 105146,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 259879,
    "out:% savings (space heating)": 20.08,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.92,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.92,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 137.2,
    "out:Total Energy Use Post PV": 131.92,
    "out:Primary Energy": 113.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.29,
    "out:CO2 Operational/m2": 26.18,
    "out:Total CO2/m2": 46.47,
    "out:Total CO2 (tons)": 128.58,
    "out:Klimatpaverkan": -38.44,
    "out:Initial Cost/MSEK": 2.565,
    "out:Running cost/(Apt SEK y)": 21890,
    "out:Running Cost over RSP/MSEK": 8.218,
    "out:LCP/MSEK": 0.518,
    "out:ROI% old": 17.17,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160637,
    "out:DH kr savings": 116214,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 248821,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.92,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00001201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.92,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 144.26,
    "out:Total Energy Use Post PV": 144.26,
    "out:Primary Energy": 133.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.55,
    "out:CO2 Operational/m2": 45.94,
    "out:Total CO2/m2": 59.5,
    "out:Total CO2 (tons)": 164.63,
    "out:Klimatpaverkan": -2.39,
    "out:Initial Cost/MSEK": 2.334,
    "out:Running cost/(Apt SEK y)": 23570,
    "out:Running Cost over RSP/MSEK": 8.836,
    "out:LCP/MSEK": 0.132,
    "out:ROI% old": 15.09,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74401,
    "out:DH kr savings": 118981,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 259879,
    "out:% savings (space heating)": 20.08,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.92,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.92,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 140.26,
    "out:Total Energy Use Post PV": 140.26,
    "out:Primary Energy": 130.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.55,
    "out:CO2 Operational/m2": 44.5,
    "out:Total CO2/m2": 58.06,
    "out:Total CO2 (tons)": 160.64,
    "out:Klimatpaverkan": -6.38,
    "out:Initial Cost/MSEK": 2.404,
    "out:Running cost/(Apt SEK y)": 22873,
    "out:Running Cost over RSP/MSEK": 8.574,
    "out:LCP/MSEK": 0.324,
    "out:ROI% old": 16.21,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 348,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85469,
    "out:DH kr savings": 130049,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 248821,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.92,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.92,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 138.26,
    "out:Total Energy Use Post PV": 138.26,
    "out:Primary Energy": 131.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.2,
    "out:CO2 Operational/m2": 43.78,
    "out:Total CO2/m2": 57.99,
    "out:Total CO2 (tons)": 160.46,
    "out:Klimatpaverkan": -6.56,
    "out:Initial Cost/MSEK": 2.553,
    "out:Running cost/(Apt SEK y)": 22525,
    "out:Running Cost over RSP/MSEK": 8.444,
    "out:LCP/MSEK": 0.305,
    "out:ROI% old": 15.99,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91003,
    "out:DH kr savings": 135583,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 259879,
    "out:% savings (space heating)": 20.08,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.92,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.92,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 134.26,
    "out:Primary Energy": 127.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.2,
    "out:CO2 Operational/m2": 42.34,
    "out:Total CO2/m2": 56.55,
    "out:Total CO2 (tons)": 156.47,
    "out:Klimatpaverkan": -10.55,
    "out:Initial Cost/MSEK": 2.622,
    "out:Running cost/(Apt SEK y)": 21829,
    "out:Running Cost over RSP/MSEK": 8.182,
    "out:LCP/MSEK": 0.497,
    "out:ROI% old": 17,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102071,
    "out:DH kr savings": 146651,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 248821,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.92,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.92,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 144.26,
    "out:Total Energy Use Post PV": 137.64,
    "out:Primary Energy": 121.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.44,
    "out:CO2 Operational/m2": 33.52,
    "out:Total CO2/m2": 56.95,
    "out:Total CO2 (tons)": 157.59,
    "out:Klimatpaverkan": -9.43,
    "out:Initial Cost/MSEK": 2.795,
    "out:Running cost/(Apt SEK y)": 22606,
    "out:Running Cost over RSP/MSEK": 8.484,
    "out:LCP/MSEK": 0.023,
    "out:ROI% old": 14.4,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120664,
    "out:DH kr savings": 118981,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 259879,
    "out:% savings (space heating)": 20.08,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.92,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.92,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 140.26,
    "out:Total Energy Use Post PV": 133.64,
    "out:Primary Energy": 118.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.44,
    "out:CO2 Operational/m2": 32.08,
    "out:Total CO2/m2": 55.51,
    "out:Total CO2 (tons)": 153.6,
    "out:Klimatpaverkan": -13.42,
    "out:Initial Cost/MSEK": 2.864,
    "out:Running cost/(Apt SEK y)": 21910,
    "out:Running Cost over RSP/MSEK": 8.222,
    "out:LCP/MSEK": 0.216,
    "out:ROI% old": 15.36,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131732,
    "out:DH kr savings": 130049,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 248821,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.92,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.92,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 138.26,
    "out:Total Energy Use Post PV": 131.64,
    "out:Primary Energy": 119.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.09,
    "out:CO2 Operational/m2": 31.36,
    "out:Total CO2/m2": 55.44,
    "out:Total CO2 (tons)": 153.42,
    "out:Klimatpaverkan": -13.6,
    "out:Initial Cost/MSEK": 3.014,
    "out:Running cost/(Apt SEK y)": 21561,
    "out:Running Cost over RSP/MSEK": 8.092,
    "out:LCP/MSEK": 0.197,
    "out:ROI% old": 15.22,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137266,
    "out:DH kr savings": 135583,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 259879,
    "out:% savings (space heating)": 20.08,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.92,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.92,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 127.64,
    "out:Primary Energy": 115.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.09,
    "out:CO2 Operational/m2": 29.92,
    "out:Total CO2/m2": 54,
    "out:Total CO2 (tons)": 149.43,
    "out:Klimatpaverkan": -17.59,
    "out:Initial Cost/MSEK": 3.083,
    "out:Running cost/(Apt SEK y)": 20865,
    "out:Running Cost over RSP/MSEK": 7.83,
    "out:LCP/MSEK": 0.389,
    "out:ROI% old": 16.09,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148334,
    "out:DH kr savings": 146651,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 248821,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.92,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.92,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 136.26,
    "out:Primary Energy": 130.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.55,
    "out:CO2 Operational/m2": 43.06,
    "out:Total CO2/m2": 56.62,
    "out:Total CO2 (tons)": 156.66,
    "out:Klimatpaverkan": -10.36,
    "out:Initial Cost/MSEK": 2.461,
    "out:Running cost/(Apt SEK y)": 22177,
    "out:Running Cost over RSP/MSEK": 8.313,
    "out:LCP/MSEK": 0.528,
    "out:ROI% old": 17.35,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96537,
    "out:DH kr savings": 141117,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 259879,
    "out:% savings (space heating)": 20.08,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.92,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.92,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 132.26,
    "out:Total Energy Use Post PV": 132.26,
    "out:Primary Energy": 126.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.55,
    "out:CO2 Operational/m2": 41.62,
    "out:Total CO2/m2": 55.18,
    "out:Total CO2 (tons)": 152.67,
    "out:Klimatpaverkan": -14.35,
    "out:Initial Cost/MSEK": 2.53,
    "out:Running cost/(Apt SEK y)": 21481,
    "out:Running Cost over RSP/MSEK": 8.052,
    "out:LCP/MSEK": 0.72,
    "out:ROI% old": 18.35,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 415,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107605,
    "out:DH kr savings": 152185,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 248821,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.92,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.92,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 130.26,
    "out:Total Energy Use Post PV": 130.26,
    "out:Primary Energy": 127.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.2,
    "out:CO2 Operational/m2": 40.9,
    "out:Total CO2/m2": 55.11,
    "out:Total CO2 (tons)": 152.49,
    "out:Klimatpaverkan": -14.53,
    "out:Initial Cost/MSEK": 2.68,
    "out:Running cost/(Apt SEK y)": 21132,
    "out:Running Cost over RSP/MSEK": 7.921,
    "out:LCP/MSEK": 0.701,
    "out:ROI% old": 18.02,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 432,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113139,
    "out:DH kr savings": 157719,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 259879,
    "out:% savings (space heating)": 20.08,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.93,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.92,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 126.26,
    "out:Total Energy Use Post PV": 126.26,
    "out:Primary Energy": 124.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.2,
    "out:CO2 Operational/m2": 39.46,
    "out:Total CO2/m2": 53.67,
    "out:Total CO2 (tons)": 148.5,
    "out:Klimatpaverkan": -18.52,
    "out:Initial Cost/MSEK": 2.749,
    "out:Running cost/(Apt SEK y)": 20436,
    "out:Running Cost over RSP/MSEK": 7.659,
    "out:LCP/MSEK": 0.894,
    "out:ROI% old": 18.93,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 168787,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124207,
    "out:DH kr savings": 168787,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 248821,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.93,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00001201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.92,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 129.64,
    "out:Primary Energy": 118.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.44,
    "out:CO2 Operational/m2": 30.64,
    "out:Total CO2/m2": 54.07,
    "out:Total CO2 (tons)": 149.62,
    "out:Klimatpaverkan": -17.4,
    "out:Initial Cost/MSEK": 2.922,
    "out:Running cost/(Apt SEK y)": 21213,
    "out:Running Cost over RSP/MSEK": 7.961,
    "out:LCP/MSEK": 0.419,
    "out:ROI% old": 16.34,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142800,
    "out:DH kr savings": 141117,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 259879,
    "out:% savings (space heating)": 20.08,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.92,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.92,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 132.26,
    "out:Total Energy Use Post PV": 125.64,
    "out:Primary Energy": 114.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.44,
    "out:CO2 Operational/m2": 29.2,
    "out:Total CO2/m2": 52.63,
    "out:Total CO2 (tons)": 145.63,
    "out:Klimatpaverkan": -21.39,
    "out:Initial Cost/MSEK": 2.991,
    "out:Running cost/(Apt SEK y)": 20517,
    "out:Running Cost over RSP/MSEK": 7.699,
    "out:LCP/MSEK": 0.612,
    "out:ROI% old": 17.21,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 461,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 153868,
    "out:DH kr savings": 152185,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 248821,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.92,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.92,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 130.26,
    "out:Total Energy Use Post PV": 123.64,
    "out:Primary Energy": 115.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.09,
    "out:CO2 Operational/m2": 28.48,
    "out:Total CO2/m2": 52.56,
    "out:Total CO2 (tons)": 145.45,
    "out:Klimatpaverkan": -21.57,
    "out:Initial Cost/MSEK": 3.14,
    "out:Running cost/(Apt SEK y)": 20169,
    "out:Running Cost over RSP/MSEK": 7.569,
    "out:LCP/MSEK": 0.593,
    "out:ROI% old": 16.98,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159402,
    "out:DH kr savings": 157719,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 259879,
    "out:% savings (space heating)": 20.08,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.93,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00001201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.92,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 126.26,
    "out:Total Energy Use Post PV": 119.64,
    "out:Primary Energy": 112.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.09,
    "out:CO2 Operational/m2": 27.04,
    "out:Total CO2/m2": 51.12,
    "out:Total CO2 (tons)": 141.46,
    "out:Klimatpaverkan": -25.56,
    "out:Initial Cost/MSEK": 3.209,
    "out:Running cost/(Apt SEK y)": 19472,
    "out:Running Cost over RSP/MSEK": 7.307,
    "out:LCP/MSEK": 0.785,
    "out:ROI% old": 17.78,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 168787,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170470,
    "out:DH kr savings": 168787,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 248821,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.93,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.73,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 166.2,
    "out:Total Energy Use Post PV": 166.2,
    "out:Primary Energy": 141.9,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.15,
    "out:CO2 Operational/m2": 55.68,
    "out:Total CO2/m2": 57.83,
    "out:Total CO2 (tons)": 160.01,
    "out:Klimatpaverkan": -7.01,
    "out:Initial Cost/MSEK": 0.191,
    "out:Running cost/(Apt SEK y)": 27864,
    "out:Running Cost over RSP/MSEK": 10.452,
    "out:LCP/MSEK": 0.658,
    "out:ROI% old": 63.43,
    "out:Payback discounted": 2,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 7.8,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 57,
    "out:Return/kSEK/y": 109,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 35971,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 35971,
    "out:DH kr savings": 35971,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 289782,
    "out:% savings (space heating)": 10.89,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.73,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.24,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 162.2,
    "out:Total Energy Use Post PV": 162.2,
    "out:Primary Energy": 137.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.15,
    "out:CO2 Operational/m2": 54.24,
    "out:Total CO2/m2": 56.39,
    "out:Total CO2 (tons)": 156.02,
    "out:Klimatpaverkan": -11,
    "out:Initial Cost/MSEK": 0.261,
    "out:Running cost/(Apt SEK y)": 27167,
    "out:Running Cost over RSP/MSEK": 10.191,
    "out:LCP/MSEK": 0.851,
    "out:ROI% old": 60.92,
    "out:Payback discounted": 2,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 10.5,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 55,
    "out:Return/kSEK/y": 142,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 47039,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 47039,
    "out:DH kr savings": 47039,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277365,
    "out:% savings (space heating)": 14.71,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.24,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.73,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 160.2,
    "out:Total Energy Use Post PV": 160.2,
    "out:Primary Energy": 139.27,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.8,
    "out:CO2 Operational/m2": 53.52,
    "out:Total CO2/m2": 56.32,
    "out:Total CO2 (tons)": 155.83,
    "out:Klimatpaverkan": -11.19,
    "out:Initial Cost/MSEK": 0.41,
    "out:Running cost/(Apt SEK y)": 26819,
    "out:Running Cost over RSP/MSEK": 10.06,
    "out:LCP/MSEK": 0.832,
    "out:ROI% old": 43.28,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 39,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 52573,
    "out:DH kr savings": 52573,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 289782,
    "out:% savings (space heating)": 10.89,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.73,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.24,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 155.2,
    "out:Primary Energy": 135.35,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.8,
    "out:CO2 Operational/m2": 51.72,
    "out:Total CO2/m2": 54.52,
    "out:Total CO2 (tons)": 150.85,
    "out:Klimatpaverkan": -16.17,
    "out:Initial Cost/MSEK": 0.479,
    "out:Running cost/(Apt SEK y)": 25949,
    "out:Running Cost over RSP/MSEK": 9.733,
    "out:LCP/MSEK": 1.09,
    "out:ROI% old": 46.77,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 42,
    "out:Return/kSEK/y": 201,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 66408,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 66408,
    "out:DH kr savings": 66408,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277365,
    "out:% savings (space heating)": 14.71,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.24,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.73,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 166.2,
    "out:Total Energy Use Post PV": 160.92,
    "out:Primary Energy": 132.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.03,
    "out:CO2 Operational/m2": 36.62,
    "out:Total CO2/m2": 48.65,
    "out:Total CO2 (tons)": 134.61,
    "out:Klimatpaverkan": -32.41,
    "out:Initial Cost/MSEK": 0.652,
    "out:Running cost/(Apt SEK y)": 26938,
    "out:Running Cost over RSP/MSEK": 10.114,
    "out:LCP/MSEK": 0.536,
    "out:ROI% old": 26.03,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 11.2,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 23,
    "out:Return/kSEK/y": 153,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 35971,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 80394,
    "out:DH kr savings": 35971,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 289782,
    "out:% savings (space heating)": 10.89,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.73,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.24,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 162.2,
    "out:Total Energy Use Post PV": 156.92,
    "out:Primary Energy": 128.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.03,
    "out:CO2 Operational/m2": 35.18,
    "out:Total CO2/m2": 47.21,
    "out:Total CO2 (tons)": 130.63,
    "out:Klimatpaverkan": -36.39,
    "out:Initial Cost/MSEK": 0.721,
    "out:Running cost/(Apt SEK y)": 26242,
    "out:Running Cost over RSP/MSEK": 9.852,
    "out:LCP/MSEK": 0.728,
    "out:ROI% old": 28.72,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 26,
    "out:Return/kSEK/y": 186,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 47039,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 91462,
    "out:DH kr savings": 47039,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277365,
    "out:% savings (space heating)": 14.71,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.24,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.73,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 160.2,
    "out:Total Energy Use Post PV": 154.92,
    "out:Primary Energy": 129.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.68,
    "out:CO2 Operational/m2": 34.46,
    "out:Total CO2/m2": 47.14,
    "out:Total CO2 (tons)": 130.44,
    "out:Klimatpaverkan": -36.58,
    "out:Initial Cost/MSEK": 0.87,
    "out:Running cost/(Apt SEK y)": 25894,
    "out:Running Cost over RSP/MSEK": 9.722,
    "out:LCP/MSEK": 0.71,
    "out:ROI% old": 25.93,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 23,
    "out:Return/kSEK/y": 203,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96996,
    "out:DH kr savings": 52573,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 289782,
    "out:% savings (space heating)": 10.89,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.73,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.24,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 149.92,
    "out:Primary Energy": 125.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.68,
    "out:CO2 Operational/m2": 32.66,
    "out:Total CO2/m2": 45.34,
    "out:Total CO2 (tons)": 125.46,
    "out:Klimatpaverkan": -41.56,
    "out:Initial Cost/MSEK": 0.94,
    "out:Running cost/(Apt SEK y)": 25023,
    "out:Running Cost over RSP/MSEK": 9.395,
    "out:LCP/MSEK": 0.967,
    "out:ROI% old": 28.99,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 26,
    "out:Return/kSEK/y": 245,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 66408,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 110831,
    "out:DH kr savings": 66408,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277365,
    "out:% savings (space heating)": 14.71,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.24,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.73,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 157.2,
    "out:Primary Energy": 138.4,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.15,
    "out:CO2 Operational/m2": 52.44,
    "out:Total CO2/m2": 54.59,
    "out:Total CO2 (tons)": 151.04,
    "out:Klimatpaverkan": -15.98,
    "out:Initial Cost/MSEK": 0.318,
    "out:Running cost/(Apt SEK y)": 26297,
    "out:Running Cost over RSP/MSEK": 9.864,
    "out:LCP/MSEK": 1.12,
    "out:ROI% old": 64.58,
    "out:Payback discounted": 2,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 58,
    "out:Return/kSEK/y": 184,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 60874,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 60874,
    "out:DH kr savings": 60874,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 289782,
    "out:% savings (space heating)": 10.89,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.73,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.24,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 153.2,
    "out:Total Energy Use Post PV": 153.2,
    "out:Primary Energy": 134.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.15,
    "out:CO2 Operational/m2": 51,
    "out:Total CO2/m2": 53.15,
    "out:Total CO2 (tons)": 147.06,
    "out:Klimatpaverkan": -19.96,
    "out:Initial Cost/MSEK": 0.387,
    "out:Running cost/(Apt SEK y)": 25600,
    "out:Running Cost over RSP/MSEK": 9.602,
    "out:LCP/MSEK": 1.312,
    "out:ROI% old": 62.69,
    "out:Payback discounted": 2,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 56,
    "out:Return/kSEK/y": 217,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 71942,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71942,
    "out:DH kr savings": 71942,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277365,
    "out:% savings (space heating)": 14.71,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.24,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.73,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 152.2,
    "out:Primary Energy": 136.3,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.8,
    "out:CO2 Operational/m2": 50.64,
    "out:Total CO2/m2": 53.44,
    "out:Total CO2 (tons)": 147.87,
    "out:Klimatpaverkan": -19.15,
    "out:Initial Cost/MSEK": 0.537,
    "out:Running cost/(Apt SEK y)": 25426,
    "out:Running Cost over RSP/MSEK": 9.537,
    "out:LCP/MSEK": 1.228,
    "out:ROI% old": 46.98,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 17.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 42,
    "out:Return/kSEK/y": 226,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 74709,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74709,
    "out:DH kr savings": 74709,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 289782,
    "out:% savings (space heating)": 10.89,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.73,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.24,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 148.2,
    "out:Primary Energy": 132.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.8,
    "out:CO2 Operational/m2": 49.2,
    "out:Total CO2/m2": 52,
    "out:Total CO2 (tons)": 143.88,
    "out:Klimatpaverkan": -23.14,
    "out:Initial Cost/MSEK": 0.606,
    "out:Running cost/(Apt SEK y)": 24730,
    "out:Running Cost over RSP/MSEK": 9.276,
    "out:LCP/MSEK": 1.42,
    "out:ROI% old": 47.78,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 43,
    "out:Return/kSEK/y": 259,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85777,
    "out:DH kr savings": 85777,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277365,
    "out:% savings (space heating)": 14.71,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.24,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.73,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 151.92,
    "out:Primary Energy": 128.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.03,
    "out:CO2 Operational/m2": 33.38,
    "out:Total CO2/m2": 45.41,
    "out:Total CO2 (tons)": 125.65,
    "out:Klimatpaverkan": -41.37,
    "out:Initial Cost/MSEK": 0.779,
    "out:Running cost/(Apt SEK y)": 25371,
    "out:Running Cost over RSP/MSEK": 9.526,
    "out:LCP/MSEK": 0.998,
    "out:ROI% old": 32.59,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 17.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 29,
    "out:Return/kSEK/y": 228,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 60874,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105297,
    "out:DH kr savings": 60874,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 289782,
    "out:% savings (space heating)": 10.89,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.73,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.24,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 153.2,
    "out:Total Energy Use Post PV": 147.92,
    "out:Primary Energy": 124.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.03,
    "out:CO2 Operational/m2": 31.94,
    "out:Total CO2/m2": 43.97,
    "out:Total CO2 (tons)": 121.66,
    "out:Klimatpaverkan": -45.36,
    "out:Initial Cost/MSEK": 0.848,
    "out:Running cost/(Apt SEK y)": 24675,
    "out:Running Cost over RSP/MSEK": 9.264,
    "out:LCP/MSEK": 1.19,
    "out:ROI% old": 34.34,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 31,
    "out:Return/kSEK/y": 262,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 71942,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116365,
    "out:DH kr savings": 71942,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277365,
    "out:% savings (space heating)": 14.71,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.24,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.73,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 146.92,
    "out:Primary Energy": 126.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.68,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 44.26,
    "out:Total CO2 (tons)": 122.47,
    "out:Klimatpaverkan": -44.55,
    "out:Initial Cost/MSEK": 0.997,
    "out:Running cost/(Apt SEK y)": 24501,
    "out:Running Cost over RSP/MSEK": 9.199,
    "out:LCP/MSEK": 1.106,
    "out:ROI% old": 30.13,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 27,
    "out:Return/kSEK/y": 270,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 74709,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119132,
    "out:DH kr savings": 74709,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 289782,
    "out:% savings (space heating)": 10.89,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.73,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.24,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 142.92,
    "out:Primary Energy": 122.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.68,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 42.82,
    "out:Total CO2 (tons)": 118.49,
    "out:Klimatpaverkan": -48.53,
    "out:Initial Cost/MSEK": 1.066,
    "out:Running cost/(Apt SEK y)": 23805,
    "out:Running Cost over RSP/MSEK": 8.937,
    "out:LCP/MSEK": 1.298,
    "out:ROI% old": 31.68,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 28,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130200,
    "out:DH kr savings": 85777,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277365,
    "out:% savings (space heating)": 14.71,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.24,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.73,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 155.26,
    "out:Total Energy Use Post PV": 155.26,
    "out:Primary Energy": 143.1,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.94,
    "out:CO2 Operational/m2": 49.9,
    "out:Total CO2/m2": 55.85,
    "out:Total CO2 (tons)": 154.53,
    "out:Klimatpaverkan": -12.49,
    "out:Initial Cost/MSEK": 0.836,
    "out:Running cost/(Apt SEK y)": 25485,
    "out:Running Cost over RSP/MSEK": 9.555,
    "out:LCP/MSEK": 0.911,
    "out:ROI% old": 29.87,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 27,
    "out:Return/kSEK/y": 223,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 43964,
    "out:DH kr savings": 88544,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 289782,
    "out:% savings (space heating)": 10.89,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.73,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.24,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 151.26,
    "out:Total Energy Use Post PV": 151.26,
    "out:Primary Energy": 139.17,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.94,
    "out:CO2 Operational/m2": 48.46,
    "out:Total CO2/m2": 54.41,
    "out:Total CO2 (tons)": 150.54,
    "out:Klimatpaverkan": -16.48,
    "out:Initial Cost/MSEK": 0.905,
    "out:Running cost/(Apt SEK y)": 24788,
    "out:Running Cost over RSP/MSEK": 9.293,
    "out:LCP/MSEK": 1.104,
    "out:ROI% old": 31.71,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 28,
    "out:Return/kSEK/y": 256,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 55032,
    "out:DH kr savings": 99612,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277365,
    "out:% savings (space heating)": 14.71,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.24,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.73,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 149.26,
    "out:Total Energy Use Post PV": 149.26,
    "out:Primary Energy": 140.48,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.6,
    "out:CO2 Operational/m2": 47.74,
    "out:Total CO2/m2": 54.34,
    "out:Total CO2 (tons)": 150.36,
    "out:Klimatpaverkan": -16.66,
    "out:Initial Cost/MSEK": 1.054,
    "out:Running cost/(Apt SEK y)": 24440,
    "out:Running Cost over RSP/MSEK": 9.163,
    "out:LCP/MSEK": 1.085,
    "out:ROI% old": 28.99,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 26,
    "out:Return/kSEK/y": 273,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 60566,
    "out:DH kr savings": 105146,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 289782,
    "out:% savings (space heating)": 10.89,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.73,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.24,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 144.26,
    "out:Total Energy Use Post PV": 144.26,
    "out:Primary Energy": 136.55,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.6,
    "out:CO2 Operational/m2": 45.94,
    "out:Total CO2/m2": 52.54,
    "out:Total CO2 (tons)": 145.38,
    "out:Klimatpaverkan": -21.64,
    "out:Initial Cost/MSEK": 1.123,
    "out:Running cost/(Apt SEK y)": 23570,
    "out:Running Cost over RSP/MSEK": 8.836,
    "out:LCP/MSEK": 1.343,
    "out:ROI% old": 31.36,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 28,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 74401,
    "out:DH kr savings": 118981,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277365,
    "out:% savings (space heating)": 14.71,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.24,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.73,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 155.26,
    "out:Total Energy Use Post PV": 148.64,
    "out:Primary Energy": 131.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.83,
    "out:CO2 Operational/m2": 37.48,
    "out:Total CO2/m2": 53.3,
    "out:Total CO2 (tons)": 147.49,
    "out:Klimatpaverkan": -19.53,
    "out:Initial Cost/MSEK": 1.296,
    "out:Running cost/(Apt SEK y)": 24521,
    "out:Running Cost over RSP/MSEK": 9.203,
    "out:LCP/MSEK": 0.803,
    "out:ROI% old": 23.14,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 269,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 90227,
    "out:DH kr savings": 88544,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 289782,
    "out:% savings (space heating)": 10.89,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.73,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.24,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 151.26,
    "out:Total Energy Use Post PV": 144.64,
    "out:Primary Energy": 127.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.83,
    "out:CO2 Operational/m2": 36.04,
    "out:Total CO2/m2": 51.86,
    "out:Total CO2 (tons)": 143.5,
    "out:Klimatpaverkan": -23.52,
    "out:Initial Cost/MSEK": 1.365,
    "out:Running cost/(Apt SEK y)": 23825,
    "out:Running Cost over RSP/MSEK": 8.941,
    "out:LCP/MSEK": 0.995,
    "out:ROI% old": 24.7,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 101295,
    "out:DH kr savings": 99612,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277365,
    "out:% savings (space heating)": 14.71,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.24,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.73,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 149.26,
    "out:Total Energy Use Post PV": 142.64,
    "out:Primary Energy": 128.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.48,
    "out:CO2 Operational/m2": 35.32,
    "out:Total CO2/m2": 51.8,
    "out:Total CO2 (tons)": 143.32,
    "out:Klimatpaverkan": -23.7,
    "out:Initial Cost/MSEK": 1.515,
    "out:Running cost/(Apt SEK y)": 23476,
    "out:Running Cost over RSP/MSEK": 8.81,
    "out:LCP/MSEK": 0.977,
    "out:ROI% old": 23.5,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 319,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 106829,
    "out:DH kr savings": 105146,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 289782,
    "out:% savings (space heating)": 10.89,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.73,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.24,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 144.26,
    "out:Total Energy Use Post PV": 137.64,
    "out:Primary Energy": 124.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.48,
    "out:CO2 Operational/m2": 33.52,
    "out:Total CO2/m2": 50,
    "out:Total CO2 (tons)": 138.34,
    "out:Klimatpaverkan": -28.68,
    "out:Initial Cost/MSEK": 1.584,
    "out:Running cost/(Apt SEK y)": 22606,
    "out:Running Cost over RSP/MSEK": 8.484,
    "out:LCP/MSEK": 1.234,
    "out:ROI% old": 25.42,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 120664,
    "out:DH kr savings": 118981,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277365,
    "out:% savings (space heating)": 14.71,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.24,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.73,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 146.26,
    "out:Total Energy Use Post PV": 146.26,
    "out:Primary Energy": 139.6,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.94,
    "out:CO2 Operational/m2": 46.66,
    "out:Total CO2/m2": 52.61,
    "out:Total CO2 (tons)": 145.56,
    "out:Klimatpaverkan": -21.46,
    "out:Initial Cost/MSEK": 0.962,
    "out:Running cost/(Apt SEK y)": 23918,
    "out:Running Cost over RSP/MSEK": 8.967,
    "out:LCP/MSEK": 1.373,
    "out:ROI% old": 34.67,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 31,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 68867,
    "out:DH kr savings": 113447,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 289782,
    "out:% savings (space heating)": 10.89,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.73,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.24,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 142.26,
    "out:Total Energy Use Post PV": 142.26,
    "out:Primary Energy": 135.67,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.94,
    "out:CO2 Operational/m2": 45.22,
    "out:Total CO2/m2": 51.17,
    "out:Total CO2 (tons)": 141.58,
    "out:Klimatpaverkan": -25.44,
    "out:Initial Cost/MSEK": 1.031,
    "out:Running cost/(Apt SEK y)": 23222,
    "out:Running Cost over RSP/MSEK": 8.705,
    "out:LCP/MSEK": 1.565,
    "out:ROI% old": 35.96,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 32,
    "out:Return/kSEK/y": 331,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 79935,
    "out:DH kr savings": 124515,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277365,
    "out:% savings (space heating)": 14.71,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.24,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.73,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 141.26,
    "out:Total Energy Use Post PV": 141.26,
    "out:Primary Energy": 137.15,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.6,
    "out:CO2 Operational/m2": 44.86,
    "out:Total CO2/m2": 51.46,
    "out:Total CO2 (tons)": 142.39,
    "out:Klimatpaverkan": -24.63,
    "out:Initial Cost/MSEK": 1.181,
    "out:Running cost/(Apt SEK y)": 23047,
    "out:Running Cost over RSP/MSEK": 8.64,
    "out:LCP/MSEK": 1.481,
    "out:ROI% old": 32.2,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 29,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82702,
    "out:DH kr savings": 127282,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 289782,
    "out:% savings (space heating)": 10.89,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 102.73,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.24,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 137.26,
    "out:Primary Energy": 133.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.6,
    "out:CO2 Operational/m2": 43.42,
    "out:Total CO2/m2": 50.02,
    "out:Total CO2 (tons)": 138.4,
    "out:Klimatpaverkan": -28.62,
    "out:Initial Cost/MSEK": 1.25,
    "out:Running cost/(Apt SEK y)": 22351,
    "out:Running Cost over RSP/MSEK": 8.378,
    "out:LCP/MSEK": 1.673,
    "out:ROI% old": 33.41,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 30,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93770,
    "out:DH kr savings": 138350,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277365,
    "out:% savings (space heating)": 14.71,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 98.24,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.73,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 146.26,
    "out:Total Energy Use Post PV": 139.64,
    "out:Primary Energy": 127.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.83,
    "out:CO2 Operational/m2": 34.24,
    "out:Total CO2/m2": 50.06,
    "out:Total CO2 (tons)": 138.52,
    "out:Klimatpaverkan": -28.5,
    "out:Initial Cost/MSEK": 1.423,
    "out:Running cost/(Apt SEK y)": 22954,
    "out:Running Cost over RSP/MSEK": 8.614,
    "out:LCP/MSEK": 1.265,
    "out:ROI% old": 26.98,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 24,
    "out:Return/kSEK/y": 344,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115130,
    "out:DH kr savings": 113447,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 289782,
    "out:% savings (space heating)": 10.89,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.73,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.24,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 142.26,
    "out:Total Energy Use Post PV": 135.64,
    "out:Primary Energy": 123.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.83,
    "out:CO2 Operational/m2": 32.8,
    "out:Total CO2/m2": 48.62,
    "out:Total CO2 (tons)": 134.54,
    "out:Klimatpaverkan": -32.48,
    "out:Initial Cost/MSEK": 1.492,
    "out:Running cost/(Apt SEK y)": 22258,
    "out:Running Cost over RSP/MSEK": 8.353,
    "out:LCP/MSEK": 1.457,
    "out:ROI% old": 28.24,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 378,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126198,
    "out:DH kr savings": 124515,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277365,
    "out:% savings (space heating)": 14.71,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.24,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.73,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 141.26,
    "out:Total Energy Use Post PV": 134.64,
    "out:Primary Energy": 125.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.48,
    "out:CO2 Operational/m2": 32.44,
    "out:Total CO2/m2": 48.92,
    "out:Total CO2 (tons)": 135.35,
    "out:Klimatpaverkan": -31.67,
    "out:Initial Cost/MSEK": 1.641,
    "out:Running cost/(Apt SEK y)": 22084,
    "out:Running Cost over RSP/MSEK": 8.288,
    "out:LCP/MSEK": 1.373,
    "out:ROI% old": 26.23,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 24,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128965,
    "out:DH kr savings": 127282,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 289782,
    "out:% savings (space heating)": 10.89,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 102.73,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.24,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 130.64,
    "out:Primary Energy": 121.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.48,
    "out:CO2 Operational/m2": 31,
    "out:Total CO2/m2": 47.48,
    "out:Total CO2 (tons)": 131.36,
    "out:Klimatpaverkan": -35.66,
    "out:Initial Cost/MSEK": 1.711,
    "out:Running cost/(Apt SEK y)": 21387,
    "out:Running Cost over RSP/MSEK": 8.026,
    "out:LCP/MSEK": 1.565,
    "out:ROI% old": 27.36,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140033,
    "out:DH kr savings": 138350,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277365,
    "out:% savings (space heating)": 14.71,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 98.24,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.99,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 157.2,
    "out:Primary Energy": 134.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.46,
    "out:CO2 Operational/m2": 52.44,
    "out:Total CO2/m2": 56.9,
    "out:Total CO2 (tons)": 157.44,
    "out:Klimatpaverkan": -9.58,
    "out:Initial Cost/MSEK": 0.638,
    "out:Running cost/(Apt SEK y)": 26297,
    "out:Running Cost over RSP/MSEK": 9.864,
    "out:LCP/MSEK": 0.8,
    "out:ROI% old": 32.2,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 29,
    "out:Return/kSEK/y": 184,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 60874,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 60874,
    "out:DH kr savings": 60874,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 265599,
    "out:% savings (space heating)": 18.32,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 95.99,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.9,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 153.2,
    "out:Total Energy Use Post PV": 153.2,
    "out:Primary Energy": 130.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.46,
    "out:CO2 Operational/m2": 51,
    "out:Total CO2/m2": 55.46,
    "out:Total CO2 (tons)": 153.46,
    "out:Klimatpaverkan": -13.56,
    "out:Initial Cost/MSEK": 0.707,
    "out:Running cost/(Apt SEK y)": 25600,
    "out:Running Cost over RSP/MSEK": 9.602,
    "out:LCP/MSEK": 0.992,
    "out:ROI% old": 34.33,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 31,
    "out:Return/kSEK/y": 217,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 71942,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71942,
    "out:DH kr savings": 71942,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254281,
    "out:% savings (space heating)": 21.8,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.9,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.99,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 151.2,
    "out:Total Energy Use Post PV": 151.2,
    "out:Primary Energy": 131.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.11,
    "out:CO2 Operational/m2": 50.28,
    "out:Total CO2/m2": 55.39,
    "out:Total CO2 (tons)": 153.27,
    "out:Klimatpaverkan": -13.75,
    "out:Initial Cost/MSEK": 0.857,
    "out:Running cost/(Apt SEK y)": 25252,
    "out:Running Cost over RSP/MSEK": 9.472,
    "out:LCP/MSEK": 0.973,
    "out:ROI% old": 30.52,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 27,
    "out:Return/kSEK/y": 234,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77476,
    "out:DH kr savings": 77476,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 265599,
    "out:% savings (space heating)": 18.32,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 95.99,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.9,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 147.2,
    "out:Primary Energy": 128.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.11,
    "out:CO2 Operational/m2": 48.84,
    "out:Total CO2/m2": 53.95,
    "out:Total CO2 (tons)": 149.29,
    "out:Klimatpaverkan": -17.73,
    "out:Initial Cost/MSEK": 0.926,
    "out:Running cost/(Apt SEK y)": 24556,
    "out:Running Cost over RSP/MSEK": 9.21,
    "out:LCP/MSEK": 1.166,
    "out:ROI% old": 32.27,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 29,
    "out:Return/kSEK/y": 267,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88544,
    "out:DH kr savings": 88544,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254281,
    "out:% savings (space heating)": 21.8,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.9,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.99,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 151.92,
    "out:Primary Energy": 124.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.34,
    "out:CO2 Operational/m2": 33.38,
    "out:Total CO2/m2": 47.72,
    "out:Total CO2 (tons)": 132.05,
    "out:Klimatpaverkan": -34.97,
    "out:Initial Cost/MSEK": 1.098,
    "out:Running cost/(Apt SEK y)": 25371,
    "out:Running Cost over RSP/MSEK": 9.526,
    "out:LCP/MSEK": 0.678,
    "out:ROI% old": 23.1,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 17.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 228,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 60874,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105297,
    "out:DH kr savings": 60874,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 265599,
    "out:% savings (space heating)": 18.32,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 95.99,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.9,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 153.2,
    "out:Total Energy Use Post PV": 147.92,
    "out:Primary Energy": 121.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.34,
    "out:CO2 Operational/m2": 31.94,
    "out:Total CO2/m2": 46.28,
    "out:Total CO2 (tons)": 128.07,
    "out:Klimatpaverkan": -38.95,
    "out:Initial Cost/MSEK": 1.168,
    "out:Running cost/(Apt SEK y)": 24675,
    "out:Running Cost over RSP/MSEK": 9.264,
    "out:LCP/MSEK": 0.87,
    "out:ROI% old": 24.93,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 262,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 71942,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116365,
    "out:DH kr savings": 71942,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254281,
    "out:% savings (space heating)": 21.8,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.9,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.99,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 151.2,
    "out:Total Energy Use Post PV": 145.92,
    "out:Primary Energy": 122.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15,
    "out:CO2 Operational/m2": 31.22,
    "out:Total CO2/m2": 46.22,
    "out:Total CO2 (tons)": 127.88,
    "out:Klimatpaverkan": -39.14,
    "out:Initial Cost/MSEK": 1.317,
    "out:Running cost/(Apt SEK y)": 24327,
    "out:Running Cost over RSP/MSEK": 9.133,
    "out:LCP/MSEK": 0.851,
    "out:ROI% old": 23.52,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 278,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121899,
    "out:DH kr savings": 77476,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 265599,
    "out:% savings (space heating)": 18.32,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 95.99,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.9,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 141.92,
    "out:Primary Energy": 118.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 44.78,
    "out:Total CO2 (tons)": 123.89,
    "out:Klimatpaverkan": -43.13,
    "out:Initial Cost/MSEK": 1.386,
    "out:Running cost/(Apt SEK y)": 23630,
    "out:Running Cost over RSP/MSEK": 8.872,
    "out:LCP/MSEK": 1.043,
    "out:ROI% old": 25.04,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 312,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132967,
    "out:DH kr savings": 88544,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254281,
    "out:% savings (space heating)": 21.8,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.9,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.99,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 149.2,
    "out:Primary Energy": 130.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.46,
    "out:CO2 Operational/m2": 49.56,
    "out:Total CO2/m2": 54.02,
    "out:Total CO2 (tons)": 149.48,
    "out:Klimatpaverkan": -17.54,
    "out:Initial Cost/MSEK": 0.765,
    "out:Running cost/(Apt SEK y)": 24904,
    "out:Running Cost over RSP/MSEK": 9.341,
    "out:LCP/MSEK": 1.196,
    "out:ROI% old": 36.63,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 33,
    "out:Return/kSEK/y": 251,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83010,
    "out:DH kr savings": 83010,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 265599,
    "out:% savings (space heating)": 18.32,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 95.99,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.9,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 145.2,
    "out:Primary Energy": 127.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.46,
    "out:CO2 Operational/m2": 48.12,
    "out:Total CO2/m2": 52.58,
    "out:Total CO2 (tons)": 145.49,
    "out:Klimatpaverkan": -21.53,
    "out:Initial Cost/MSEK": 0.834,
    "out:Running cost/(Apt SEK y)": 24208,
    "out:Running Cost over RSP/MSEK": 9.079,
    "out:LCP/MSEK": 1.388,
    "out:ROI% old": 38.07,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 34,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 94078,
    "out:DH kr savings": 94078,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254281,
    "out:% savings (space heating)": 21.8,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.9,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.99,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 143.2,
    "out:Primary Energy": 128.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.11,
    "out:CO2 Operational/m2": 47.4,
    "out:Total CO2/m2": 52.51,
    "out:Total CO2 (tons)": 145.3,
    "out:Klimatpaverkan": -21.72,
    "out:Initial Cost/MSEK": 0.983,
    "out:Running cost/(Apt SEK y)": 23860,
    "out:Running Cost over RSP/MSEK": 8.949,
    "out:LCP/MSEK": 1.37,
    "out:ROI% old": 34.19,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 31,
    "out:Return/kSEK/y": 301,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99612,
    "out:DH kr savings": 99612,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 265599,
    "out:% savings (space heating)": 18.32,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 95.99,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.9,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 139.2,
    "out:Total Energy Use Post PV": 139.2,
    "out:Primary Energy": 125.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.11,
    "out:CO2 Operational/m2": 45.96,
    "out:Total CO2/m2": 51.07,
    "out:Total CO2 (tons)": 141.32,
    "out:Klimatpaverkan": -25.7,
    "out:Initial Cost/MSEK": 1.052,
    "out:Running cost/(Apt SEK y)": 23163,
    "out:Running Cost over RSP/MSEK": 8.687,
    "out:LCP/MSEK": 1.562,
    "out:ROI% old": 35.49,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 32,
    "out:Return/kSEK/y": 334,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 110680,
    "out:DH kr savings": 110680,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254281,
    "out:% savings (space heating)": 21.8,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.9,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.99,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 143.92,
    "out:Primary Energy": 121.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.34,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 44.84,
    "out:Total CO2 (tons)": 124.08,
    "out:Klimatpaverkan": -42.94,
    "out:Initial Cost/MSEK": 1.225,
    "out:Running cost/(Apt SEK y)": 23979,
    "out:Running Cost over RSP/MSEK": 9.003,
    "out:LCP/MSEK": 1.074,
    "out:ROI% old": 26.81,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 24,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127433,
    "out:DH kr savings": 83010,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 265599,
    "out:% savings (space heating)": 18.32,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 95.99,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.9,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 139.92,
    "out:Primary Energy": 117.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.34,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 43.4,
    "out:Total CO2 (tons)": 120.1,
    "out:Klimatpaverkan": -46.92,
    "out:Initial Cost/MSEK": 1.294,
    "out:Running cost/(Apt SEK y)": 23282,
    "out:Running Cost over RSP/MSEK": 8.741,
    "out:LCP/MSEK": 1.266,
    "out:ROI% old": 28.26,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 25,
    "out:Return/kSEK/y": 329,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138501,
    "out:DH kr savings": 94078,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254281,
    "out:% savings (space heating)": 21.8,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.9,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.99,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 137.92,
    "out:Primary Energy": 119.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 43.34,
    "out:Total CO2 (tons)": 119.91,
    "out:Klimatpaverkan": -47.11,
    "out:Initial Cost/MSEK": 1.444,
    "out:Running cost/(Apt SEK y)": 22934,
    "out:Running Cost over RSP/MSEK": 8.611,
    "out:LCP/MSEK": 1.247,
    "out:ROI% old": 26.63,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 24,
    "out:Return/kSEK/y": 345,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144035,
    "out:DH kr savings": 99612,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 265599,
    "out:% savings (space heating)": 18.32,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 95.99,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.9,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 139.2,
    "out:Total Energy Use Post PV": 133.92,
    "out:Primary Energy": 115.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15,
    "out:CO2 Operational/m2": 26.9,
    "out:Total CO2/m2": 41.9,
    "out:Total CO2 (tons)": 115.93,
    "out:Klimatpaverkan": -51.09,
    "out:Initial Cost/MSEK": 1.513,
    "out:Running cost/(Apt SEK y)": 22238,
    "out:Running Cost over RSP/MSEK": 8.349,
    "out:LCP/MSEK": 1.44,
    "out:ROI% old": 27.88,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 25,
    "out:Return/kSEK/y": 379,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155103,
    "out:DH kr savings": 110680,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254281,
    "out:% savings (space heating)": 21.8,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.9,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.99,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 146.26,
    "out:Total Energy Use Post PV": 146.26,
    "out:Primary Energy": 135.45,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.26,
    "out:CO2 Operational/m2": 46.66,
    "out:Total CO2/m2": 54.92,
    "out:Total CO2 (tons)": 151.97,
    "out:Klimatpaverkan": -15.05,
    "out:Initial Cost/MSEK": 1.282,
    "out:Running cost/(Apt SEK y)": 23918,
    "out:Running Cost over RSP/MSEK": 8.967,
    "out:LCP/MSEK": 1.053,
    "out:ROI% old": 26.02,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 68867,
    "out:DH kr savings": 113447,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 265599,
    "out:% savings (space heating)": 18.32,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 95.99,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.9,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 142.26,
    "out:Total Energy Use Post PV": 142.26,
    "out:Primary Energy": 131.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.26,
    "out:CO2 Operational/m2": 45.22,
    "out:Total CO2/m2": 53.48,
    "out:Total CO2 (tons)": 147.98,
    "out:Klimatpaverkan": -19.04,
    "out:Initial Cost/MSEK": 1.352,
    "out:Running cost/(Apt SEK y)": 23222,
    "out:Running Cost over RSP/MSEK": 8.705,
    "out:LCP/MSEK": 1.245,
    "out:ROI% old": 27.45,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 331,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 79935,
    "out:DH kr savings": 124515,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254281,
    "out:% savings (space heating)": 21.8,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.9,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.99,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 140.26,
    "out:Total Energy Use Post PV": 140.26,
    "out:Primary Energy": 132.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.91,
    "out:CO2 Operational/m2": 44.5,
    "out:Total CO2/m2": 53.41,
    "out:Total CO2 (tons)": 147.8,
    "out:Klimatpaverkan": -19.22,
    "out:Initial Cost/MSEK": 1.501,
    "out:Running cost/(Apt SEK y)": 22873,
    "out:Running Cost over RSP/MSEK": 8.574,
    "out:LCP/MSEK": 1.226,
    "out:ROI% old": 25.96,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 348,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85469,
    "out:DH kr savings": 130049,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 265599,
    "out:% savings (space heating)": 18.32,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 95.99,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.9,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 136.26,
    "out:Primary Energy": 129.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.91,
    "out:CO2 Operational/m2": 43.06,
    "out:Total CO2/m2": 51.97,
    "out:Total CO2 (tons)": 143.81,
    "out:Klimatpaverkan": -23.21,
    "out:Initial Cost/MSEK": 1.57,
    "out:Running cost/(Apt SEK y)": 22177,
    "out:Running Cost over RSP/MSEK": 8.313,
    "out:LCP/MSEK": 1.419,
    "out:ROI% old": 27.2,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 24,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 96537,
    "out:DH kr savings": 141117,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254281,
    "out:% savings (space heating)": 21.8,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.9,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.99,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 146.26,
    "out:Total Energy Use Post PV": 139.64,
    "out:Primary Energy": 123.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.14,
    "out:CO2 Operational/m2": 34.24,
    "out:Total CO2/m2": 52.38,
    "out:Total CO2 (tons)": 144.93,
    "out:Klimatpaverkan": -22.09,
    "out:Initial Cost/MSEK": 1.743,
    "out:Running cost/(Apt SEK y)": 22954,
    "out:Running Cost over RSP/MSEK": 8.614,
    "out:LCP/MSEK": 0.945,
    "out:ROI% old": 22.03,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 344,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115130,
    "out:DH kr savings": 113447,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 265599,
    "out:% savings (space heating)": 18.32,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 95.99,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.9,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 142.26,
    "out:Total Energy Use Post PV": 135.64,
    "out:Primary Energy": 119.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.14,
    "out:CO2 Operational/m2": 32.8,
    "out:Total CO2/m2": 50.94,
    "out:Total CO2 (tons)": 140.94,
    "out:Klimatpaverkan": -26.08,
    "out:Initial Cost/MSEK": 1.812,
    "out:Running cost/(Apt SEK y)": 22258,
    "out:Running Cost over RSP/MSEK": 8.353,
    "out:LCP/MSEK": 1.137,
    "out:ROI% old": 23.25,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 378,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126198,
    "out:DH kr savings": 124515,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254281,
    "out:% savings (space heating)": 21.8,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.9,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.99,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 140.26,
    "out:Total Energy Use Post PV": 133.64,
    "out:Primary Energy": 120.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.79,
    "out:CO2 Operational/m2": 32.08,
    "out:Total CO2/m2": 50.87,
    "out:Total CO2 (tons)": 140.76,
    "out:Klimatpaverkan": -26.26,
    "out:Initial Cost/MSEK": 1.961,
    "out:Running cost/(Apt SEK y)": 21910,
    "out:Running Cost over RSP/MSEK": 8.222,
    "out:LCP/MSEK": 1.118,
    "out:ROI% old": 22.43,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131732,
    "out:DH kr savings": 130049,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 265599,
    "out:% savings (space heating)": 18.32,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 95.99,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.9,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 129.64,
    "out:Primary Energy": 117.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.79,
    "out:CO2 Operational/m2": 30.64,
    "out:Total CO2/m2": 49.43,
    "out:Total CO2 (tons)": 136.77,
    "out:Klimatpaverkan": -30.25,
    "out:Initial Cost/MSEK": 2.031,
    "out:Running cost/(Apt SEK y)": 21213,
    "out:Running Cost over RSP/MSEK": 7.961,
    "out:LCP/MSEK": 1.31,
    "out:ROI% old": 23.51,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142800,
    "out:DH kr savings": 141117,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254281,
    "out:% savings (space heating)": 21.8,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.9,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.99,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 138.26,
    "out:Total Energy Use Post PV": 138.26,
    "out:Primary Energy": 131.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.26,
    "out:CO2 Operational/m2": 43.78,
    "out:Total CO2/m2": 52.04,
    "out:Total CO2 (tons)": 144,
    "out:Klimatpaverkan": -23.02,
    "out:Initial Cost/MSEK": 1.409,
    "out:Running cost/(Apt SEK y)": 22525,
    "out:Running Cost over RSP/MSEK": 8.444,
    "out:LCP/MSEK": 1.449,
    "out:ROI% old": 28.98,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 26,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91003,
    "out:DH kr savings": 135583,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 265599,
    "out:% savings (space heating)": 18.32,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 95.99,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.9,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 134.26,
    "out:Primary Energy": 128.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.26,
    "out:CO2 Operational/m2": 42.34,
    "out:Total CO2/m2": 50.6,
    "out:Total CO2 (tons)": 140.02,
    "out:Klimatpaverkan": -27,
    "out:Initial Cost/MSEK": 1.478,
    "out:Running cost/(Apt SEK y)": 21829,
    "out:Running Cost over RSP/MSEK": 8.182,
    "out:LCP/MSEK": 1.641,
    "out:ROI% old": 30.15,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 27,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102071,
    "out:DH kr savings": 146651,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254281,
    "out:% savings (space heating)": 21.8,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.9,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.99,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 132.26,
    "out:Total Energy Use Post PV": 132.26,
    "out:Primary Energy": 129.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.91,
    "out:CO2 Operational/m2": 41.62,
    "out:Total CO2/m2": 50.53,
    "out:Total CO2 (tons)": 139.83,
    "out:Klimatpaverkan": -27.19,
    "out:Initial Cost/MSEK": 1.628,
    "out:Running cost/(Apt SEK y)": 21481,
    "out:Running Cost over RSP/MSEK": 8.052,
    "out:LCP/MSEK": 1.623,
    "out:ROI% old": 28.53,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 26,
    "out:Return/kSEK/y": 415,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 107605,
    "out:DH kr savings": 152185,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 265599,
    "out:% savings (space heating)": 18.32,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.9,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 128.26,
    "out:Total Energy Use Post PV": 128.26,
    "out:Primary Energy": 125.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.91,
    "out:CO2 Operational/m2": 40.18,
    "out:Total CO2/m2": 49.09,
    "out:Total CO2 (tons)": 135.84,
    "out:Klimatpaverkan": -31.18,
    "out:Initial Cost/MSEK": 1.697,
    "out:Running cost/(Apt SEK y)": 20784,
    "out:Running Cost over RSP/MSEK": 7.79,
    "out:LCP/MSEK": 1.815,
    "out:ROI% old": 29.57,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 26,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 163253,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118673,
    "out:DH kr savings": 163253,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254281,
    "out:% savings (space heating)": 21.8,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.9,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.99,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 138.26,
    "out:Total Energy Use Post PV": 131.64,
    "out:Primary Energy": 120.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.14,
    "out:CO2 Operational/m2": 31.36,
    "out:Total CO2/m2": 49.5,
    "out:Total CO2 (tons)": 136.96,
    "out:Klimatpaverkan": -30.06,
    "out:Initial Cost/MSEK": 1.869,
    "out:Running cost/(Apt SEK y)": 21561,
    "out:Running Cost over RSP/MSEK": 8.092,
    "out:LCP/MSEK": 1.341,
    "out:ROI% old": 24.53,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137266,
    "out:DH kr savings": 135583,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 265599,
    "out:% savings (space heating)": 18.32,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 95.99,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.9,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 127.64,
    "out:Primary Energy": 116.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.14,
    "out:CO2 Operational/m2": 29.92,
    "out:Total CO2/m2": 48.06,
    "out:Total CO2 (tons)": 132.98,
    "out:Klimatpaverkan": -34.04,
    "out:Initial Cost/MSEK": 1.939,
    "out:Running cost/(Apt SEK y)": 20865,
    "out:Running Cost over RSP/MSEK": 7.83,
    "out:LCP/MSEK": 1.533,
    "out:ROI% old": 25.58,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148334,
    "out:DH kr savings": 146651,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254281,
    "out:% savings (space heating)": 21.8,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.9,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.99,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 132.26,
    "out:Total Energy Use Post PV": 125.64,
    "out:Primary Energy": 117.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.79,
    "out:CO2 Operational/m2": 29.2,
    "out:Total CO2/m2": 47.99,
    "out:Total CO2 (tons)": 132.79,
    "out:Klimatpaverkan": -34.23,
    "out:Initial Cost/MSEK": 2.088,
    "out:Running cost/(Apt SEK y)": 20517,
    "out:Running Cost over RSP/MSEK": 7.699,
    "out:LCP/MSEK": 1.514,
    "out:ROI% old": 24.65,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 461,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 153868,
    "out:DH kr savings": 152185,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 265599,
    "out:% savings (space heating)": 18.32,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.9,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 128.26,
    "out:Total Energy Use Post PV": 121.64,
    "out:Primary Energy": 114.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.79,
    "out:CO2 Operational/m2": 27.76,
    "out:Total CO2/m2": 46.55,
    "out:Total CO2 (tons)": 128.8,
    "out:Klimatpaverkan": -38.22,
    "out:Initial Cost/MSEK": 2.157,
    "out:Running cost/(Apt SEK y)": 19820,
    "out:Running Cost over RSP/MSEK": 7.438,
    "out:LCP/MSEK": 1.707,
    "out:ROI% old": 25.59,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 46.7,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 495,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 163253,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164936,
    "out:DH kr savings": 163253,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254281,
    "out:% savings (space heating)": 21.8,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.9,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.11,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 154.2,
    "out:Primary Energy": 131.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.21,
    "out:CO2 Operational/m2": 51.36,
    "out:Total CO2/m2": 61.57,
    "out:Total CO2 (tons)": 170.37,
    "out:Klimatpaverkan": 3.35,
    "out:Initial Cost/MSEK": 1.732,
    "out:Running cost/(Apt SEK y)": 25775,
    "out:Running Cost over RSP/MSEK": 9.668,
    "out:LCP/MSEK": -0.098,
    "out:ROI% old": 13.48,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 209,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69175,
    "out:DH kr savings": 69175,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257628,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.11,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.15,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 150.2,
    "out:Primary Energy": 128.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.21,
    "out:CO2 Operational/m2": 49.92,
    "out:Total CO2/m2": 60.13,
    "out:Total CO2 (tons)": 166.39,
    "out:Klimatpaverkan": -0.63,
    "out:Initial Cost/MSEK": 1.801,
    "out:Running cost/(Apt SEK y)": 25078,
    "out:Running Cost over RSP/MSEK": 9.406,
    "out:LCP/MSEK": 0.095,
    "out:ROI% old": 15.04,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 242,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80243,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 80243,
    "out:DH kr savings": 80243,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 246672,
    "out:% savings (space heating)": 24.14,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.15,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.11,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 148.2,
    "out:Primary Energy": 129.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.87,
    "out:CO2 Operational/m2": 49.2,
    "out:Total CO2/m2": 60.07,
    "out:Total CO2 (tons)": 166.2,
    "out:Klimatpaverkan": -0.82,
    "out:Initial Cost/MSEK": 1.95,
    "out:Running cost/(Apt SEK y)": 24730,
    "out:Running Cost over RSP/MSEK": 9.276,
    "out:LCP/MSEK": 0.076,
    "out:ROI% old": 14.84,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 259,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85777,
    "out:DH kr savings": 85777,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257628,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.11,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.15,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 144.2,
    "out:Total Energy Use Post PV": 144.2,
    "out:Primary Energy": 125.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.87,
    "out:CO2 Operational/m2": 47.76,
    "out:Total CO2/m2": 58.63,
    "out:Total CO2 (tons)": 162.22,
    "out:Klimatpaverkan": -4.8,
    "out:Initial Cost/MSEK": 2.02,
    "out:Running cost/(Apt SEK y)": 24034,
    "out:Running Cost over RSP/MSEK": 9.014,
    "out:LCP/MSEK": 0.268,
    "out:ROI% old": 16.18,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 14,
    "out:Return/kSEK/y": 292,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 96845,
    "out:DH kr savings": 96845,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 246672,
    "out:% savings (space heating)": 24.14,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.15,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.11,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 148.92,
    "out:Primary Energy": 122.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.1,
    "out:CO2 Operational/m2": 32.3,
    "out:Total CO2/m2": 52.4,
    "out:Total CO2 (tons)": 144.98,
    "out:Klimatpaverkan": -22.04,
    "out:Initial Cost/MSEK": 2.192,
    "out:Running cost/(Apt SEK y)": 24849,
    "out:Running Cost over RSP/MSEK": 9.33,
    "out:LCP/MSEK": -0.22,
    "out:ROI% old": 12.85,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113598,
    "out:DH kr savings": 69175,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257628,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.11,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.15,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 144.92,
    "out:Primary Energy": 118.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.1,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 50.96,
    "out:Total CO2 (tons)": 141,
    "out:Klimatpaverkan": -26.02,
    "out:Initial Cost/MSEK": 2.261,
    "out:Running cost/(Apt SEK y)": 24153,
    "out:Running Cost over RSP/MSEK": 9.068,
    "out:LCP/MSEK": -0.028,
    "out:ROI% old": 14.11,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 80243,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124666,
    "out:DH kr savings": 80243,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 246672,
    "out:% savings (space heating)": 24.14,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.15,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.11,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 142.92,
    "out:Primary Energy": 119.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.75,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 50.89,
    "out:Total CO2 (tons)": 140.81,
    "out:Klimatpaverkan": -26.21,
    "out:Initial Cost/MSEK": 2.411,
    "out:Running cost/(Apt SEK y)": 23805,
    "out:Running Cost over RSP/MSEK": 8.937,
    "out:LCP/MSEK": -0.046,
    "out:ROI% old": 14.01,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130200,
    "out:DH kr savings": 85777,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257628,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.11,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.15,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 144.2,
    "out:Total Energy Use Post PV": 138.92,
    "out:Primary Energy": 116.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.75,
    "out:CO2 Operational/m2": 28.7,
    "out:Total CO2/m2": 49.45,
    "out:Total CO2 (tons)": 136.82,
    "out:Klimatpaverkan": -30.2,
    "out:Initial Cost/MSEK": 2.48,
    "out:Running cost/(Apt SEK y)": 23108,
    "out:Running Cost over RSP/MSEK": 8.676,
    "out:LCP/MSEK": 0.146,
    "out:ROI% old": 15.13,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141268,
    "out:DH kr savings": 96845,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 246672,
    "out:% savings (space heating)": 24.14,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.15,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.11,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 146.2,
    "out:Total Energy Use Post PV": 146.2,
    "out:Primary Energy": 128.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.21,
    "out:CO2 Operational/m2": 48.48,
    "out:Total CO2/m2": 58.69,
    "out:Total CO2 (tons)": 162.41,
    "out:Klimatpaverkan": -4.61,
    "out:Initial Cost/MSEK": 1.858,
    "out:Running cost/(Apt SEK y)": 24382,
    "out:Running Cost over RSP/MSEK": 9.145,
    "out:LCP/MSEK": 0.299,
    "out:ROI% old": 16.58,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 15,
    "out:Return/kSEK/y": 276,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91311,
    "out:DH kr savings": 91311,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257628,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.11,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.15,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 142.2,
    "out:Primary Energy": 124.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.21,
    "out:CO2 Operational/m2": 47.04,
    "out:Total CO2/m2": 57.25,
    "out:Total CO2 (tons)": 158.42,
    "out:Klimatpaverkan": -8.6,
    "out:Initial Cost/MSEK": 1.928,
    "out:Running cost/(Apt SEK y)": 23686,
    "out:Running Cost over RSP/MSEK": 8.883,
    "out:LCP/MSEK": 0.491,
    "out:ROI% old": 17.92,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 16,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102379,
    "out:DH kr savings": 102379,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 246672,
    "out:% savings (space heating)": 24.14,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.15,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.11,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 141.2,
    "out:Total Energy Use Post PV": 141.2,
    "out:Primary Energy": 126.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.87,
    "out:CO2 Operational/m2": 46.68,
    "out:Total CO2/m2": 57.55,
    "out:Total CO2 (tons)": 159.23,
    "out:Klimatpaverkan": -7.79,
    "out:Initial Cost/MSEK": 2.077,
    "out:Running cost/(Apt SEK y)": 23511,
    "out:Running Cost over RSP/MSEK": 8.818,
    "out:LCP/MSEK": 0.407,
    "out:ROI% old": 17.08,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 15,
    "out:Return/kSEK/y": 318,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105146,
    "out:DH kr savings": 105146,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257628,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.11,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.15,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 137.2,
    "out:Total Energy Use Post PV": 137.2,
    "out:Primary Energy": 122.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.87,
    "out:CO2 Operational/m2": 45.24,
    "out:Total CO2/m2": 56.11,
    "out:Total CO2 (tons)": 155.25,
    "out:Klimatpaverkan": -11.77,
    "out:Initial Cost/MSEK": 2.146,
    "out:Running cost/(Apt SEK y)": 22815,
    "out:Running Cost over RSP/MSEK": 8.557,
    "out:LCP/MSEK": 0.599,
    "out:ROI% old": 18.27,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 16,
    "out:Return/kSEK/y": 351,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116214,
    "out:DH kr savings": 116214,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 246672,
    "out:% savings (space heating)": 24.14,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.15,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00002200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.11,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 146.2,
    "out:Total Energy Use Post PV": 140.92,
    "out:Primary Energy": 118.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.1,
    "out:CO2 Operational/m2": 29.42,
    "out:Total CO2/m2": 49.52,
    "out:Total CO2 (tons)": 137.01,
    "out:Klimatpaverkan": -30.01,
    "out:Initial Cost/MSEK": 2.319,
    "out:Running cost/(Apt SEK y)": 23456,
    "out:Running Cost over RSP/MSEK": 8.807,
    "out:LCP/MSEK": 0.176,
    "out:ROI% old": 15.37,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 320,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135734,
    "out:DH kr savings": 91311,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257628,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.11,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.15,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 136.92,
    "out:Primary Energy": 115.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.1,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 48.08,
    "out:Total CO2 (tons)": 133.03,
    "out:Klimatpaverkan": -33.99,
    "out:Initial Cost/MSEK": 2.388,
    "out:Running cost/(Apt SEK y)": 22760,
    "out:Running Cost over RSP/MSEK": 8.545,
    "out:LCP/MSEK": 0.369,
    "out:ROI% old": 16.49,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 354,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146802,
    "out:DH kr savings": 102379,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 246672,
    "out:% savings (space heating)": 24.14,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.15,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.11,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 141.2,
    "out:Total Energy Use Post PV": 135.92,
    "out:Primary Energy": 116.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.75,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 48.37,
    "out:Total CO2 (tons)": 133.84,
    "out:Klimatpaverkan": -33.18,
    "out:Initial Cost/MSEK": 2.537,
    "out:Running cost/(Apt SEK y)": 22586,
    "out:Running Cost over RSP/MSEK": 8.48,
    "out:LCP/MSEK": 0.285,
    "out:ROI% old": 15.89,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149569,
    "out:DH kr savings": 105146,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257628,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.11,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.15,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 137.2,
    "out:Total Energy Use Post PV": 131.92,
    "out:Primary Energy": 113.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.75,
    "out:CO2 Operational/m2": 26.18,
    "out:Total CO2/m2": 46.93,
    "out:Total CO2 (tons)": 129.85,
    "out:Klimatpaverkan": -37.17,
    "out:Initial Cost/MSEK": 2.607,
    "out:Running cost/(Apt SEK y)": 21890,
    "out:Running Cost over RSP/MSEK": 8.218,
    "out:LCP/MSEK": 0.477,
    "out:ROI% old": 16.9,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160637,
    "out:DH kr savings": 116214,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 246672,
    "out:% savings (space heating)": 24.14,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.15,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00002201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.11,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 143.26,
    "out:Primary Energy": 132.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.01,
    "out:CO2 Operational/m2": 45.58,
    "out:Total CO2/m2": 59.59,
    "out:Total CO2 (tons)": 164.9,
    "out:Klimatpaverkan": -2.12,
    "out:Initial Cost/MSEK": 2.376,
    "out:Running cost/(Apt SEK y)": 23396,
    "out:Running Cost over RSP/MSEK": 8.771,
    "out:LCP/MSEK": 0.155,
    "out:ROI% old": 15.22,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77168,
    "out:DH kr savings": 121748,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257628,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.11,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.15,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 139.26,
    "out:Total Energy Use Post PV": 139.26,
    "out:Primary Energy": 129.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.01,
    "out:CO2 Operational/m2": 44.14,
    "out:Total CO2/m2": 58.15,
    "out:Total CO2 (tons)": 160.91,
    "out:Klimatpaverkan": -6.11,
    "out:Initial Cost/MSEK": 2.445,
    "out:Running cost/(Apt SEK y)": 22699,
    "out:Running Cost over RSP/MSEK": 8.509,
    "out:LCP/MSEK": 0.348,
    "out:ROI% old": 16.32,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88236,
    "out:DH kr savings": 132816,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 246672,
    "out:% savings (space heating)": 24.14,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.15,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.11,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 137.26,
    "out:Primary Energy": 130.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.66,
    "out:CO2 Operational/m2": 43.42,
    "out:Total CO2/m2": 58.09,
    "out:Total CO2 (tons)": 160.73,
    "out:Klimatpaverkan": -6.29,
    "out:Initial Cost/MSEK": 2.595,
    "out:Running cost/(Apt SEK y)": 22351,
    "out:Running Cost over RSP/MSEK": 8.378,
    "out:LCP/MSEK": 0.329,
    "out:ROI% old": 16.1,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93770,
    "out:DH kr savings": 138350,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257628,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.11,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.15,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 133.26,
    "out:Total Energy Use Post PV": 133.26,
    "out:Primary Energy": 126.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.66,
    "out:CO2 Operational/m2": 41.98,
    "out:Total CO2/m2": 56.65,
    "out:Total CO2 (tons)": 156.74,
    "out:Klimatpaverkan": -10.28,
    "out:Initial Cost/MSEK": 2.664,
    "out:Running cost/(Apt SEK y)": 21655,
    "out:Running Cost over RSP/MSEK": 8.117,
    "out:LCP/MSEK": 0.521,
    "out:ROI% old": 17.08,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 407,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104838,
    "out:DH kr savings": 149418,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 246672,
    "out:% savings (space heating)": 24.14,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.15,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.11,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 136.64,
    "out:Primary Energy": 121.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.89,
    "out:CO2 Operational/m2": 33.16,
    "out:Total CO2/m2": 57.05,
    "out:Total CO2 (tons)": 157.86,
    "out:Klimatpaverkan": -9.16,
    "out:Initial Cost/MSEK": 2.836,
    "out:Running cost/(Apt SEK y)": 22432,
    "out:Running Cost over RSP/MSEK": 8.418,
    "out:LCP/MSEK": 0.047,
    "out:ROI% old": 14.52,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123431,
    "out:DH kr savings": 121748,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257628,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.11,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.15,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 139.26,
    "out:Total Energy Use Post PV": 132.64,
    "out:Primary Energy": 117.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.89,
    "out:CO2 Operational/m2": 31.72,
    "out:Total CO2/m2": 55.61,
    "out:Total CO2 (tons)": 153.87,
    "out:Klimatpaverkan": -13.15,
    "out:Initial Cost/MSEK": 2.906,
    "out:Running cost/(Apt SEK y)": 21735,
    "out:Running Cost over RSP/MSEK": 8.157,
    "out:LCP/MSEK": 0.239,
    "out:ROI% old": 15.46,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 403,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134499,
    "out:DH kr savings": 132816,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 246672,
    "out:% savings (space heating)": 24.14,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.15,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.11,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 130.64,
    "out:Primary Energy": 118.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.55,
    "out:CO2 Operational/m2": 31,
    "out:Total CO2/m2": 55.54,
    "out:Total CO2 (tons)": 153.69,
    "out:Klimatpaverkan": -13.33,
    "out:Initial Cost/MSEK": 3.055,
    "out:Running cost/(Apt SEK y)": 21387,
    "out:Running Cost over RSP/MSEK": 8.026,
    "out:LCP/MSEK": 0.221,
    "out:ROI% old": 15.32,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140033,
    "out:DH kr savings": 138350,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257628,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.11,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.15,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 133.26,
    "out:Total Energy Use Post PV": 126.64,
    "out:Primary Energy": 114.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.55,
    "out:CO2 Operational/m2": 29.56,
    "out:Total CO2/m2": 54.1,
    "out:Total CO2 (tons)": 149.7,
    "out:Klimatpaverkan": -17.32,
    "out:Initial Cost/MSEK": 3.124,
    "out:Running cost/(Apt SEK y)": 20691,
    "out:Running Cost over RSP/MSEK": 7.765,
    "out:LCP/MSEK": 0.413,
    "out:ROI% old": 16.17,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 453,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151101,
    "out:DH kr savings": 149418,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 246672,
    "out:% savings (space heating)": 24.14,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.15,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.11,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 135.26,
    "out:Total Energy Use Post PV": 135.26,
    "out:Primary Energy": 129.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.01,
    "out:CO2 Operational/m2": 42.7,
    "out:Total CO2/m2": 56.71,
    "out:Total CO2 (tons)": 156.93,
    "out:Klimatpaverkan": -10.09,
    "out:Initial Cost/MSEK": 2.503,
    "out:Running cost/(Apt SEK y)": 22003,
    "out:Running Cost over RSP/MSEK": 8.248,
    "out:LCP/MSEK": 0.552,
    "out:ROI% old": 17.43,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 390,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 143884,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99304,
    "out:DH kr savings": 143884,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257628,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.11,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.15,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 131.26,
    "out:Total Energy Use Post PV": 131.26,
    "out:Primary Energy": 125.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.01,
    "out:CO2 Operational/m2": 41.26,
    "out:Total CO2/m2": 55.27,
    "out:Total CO2 (tons)": 152.94,
    "out:Klimatpaverkan": -14.08,
    "out:Initial Cost/MSEK": 2.572,
    "out:Running cost/(Apt SEK y)": 21307,
    "out:Running Cost over RSP/MSEK": 7.986,
    "out:LCP/MSEK": 0.744,
    "out:ROI% old": 18.42,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 110372,
    "out:DH kr savings": 154952,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 246672,
    "out:% savings (space heating)": 24.14,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.15,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.11,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 130.26,
    "out:Total Energy Use Post PV": 130.26,
    "out:Primary Energy": 126.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.66,
    "out:CO2 Operational/m2": 40.9,
    "out:Total CO2/m2": 55.57,
    "out:Total CO2 (tons)": 153.75,
    "out:Klimatpaverkan": -13.27,
    "out:Initial Cost/MSEK": 2.721,
    "out:Running cost/(Apt SEK y)": 21132,
    "out:Running Cost over RSP/MSEK": 7.921,
    "out:LCP/MSEK": 0.66,
    "out:ROI% old": 17.75,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 432,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113139,
    "out:DH kr savings": 157719,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257628,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.11,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.15,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 126.26,
    "out:Total Energy Use Post PV": 126.26,
    "out:Primary Energy": 123.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.66,
    "out:CO2 Operational/m2": 39.46,
    "out:Total CO2/m2": 54.13,
    "out:Total CO2 (tons)": 149.77,
    "out:Klimatpaverkan": -17.25,
    "out:Initial Cost/MSEK": 2.79,
    "out:Running cost/(Apt SEK y)": 20436,
    "out:Running Cost over RSP/MSEK": 7.659,
    "out:LCP/MSEK": 0.852,
    "out:ROI% old": 18.65,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 168787,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124207,
    "out:DH kr savings": 168787,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 246672,
    "out:% savings (space heating)": 24.14,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.15,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00002201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.11,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 135.26,
    "out:Total Energy Use Post PV": 128.64,
    "out:Primary Energy": 117.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.89,
    "out:CO2 Operational/m2": 30.28,
    "out:Total CO2/m2": 54.17,
    "out:Total CO2 (tons)": 149.89,
    "out:Klimatpaverkan": -17.13,
    "out:Initial Cost/MSEK": 2.963,
    "out:Running cost/(Apt SEK y)": 21039,
    "out:Running Cost over RSP/MSEK": 7.895,
    "out:LCP/MSEK": 0.443,
    "out:ROI% old": 16.42,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 436,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 143884,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145567,
    "out:DH kr savings": 143884,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257628,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.11,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.15,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 131.26,
    "out:Total Energy Use Post PV": 124.64,
    "out:Primary Energy": 114.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.89,
    "out:CO2 Operational/m2": 28.84,
    "out:Total CO2/m2": 52.73,
    "out:Total CO2 (tons)": 145.9,
    "out:Klimatpaverkan": -21.12,
    "out:Initial Cost/MSEK": 3.032,
    "out:Running cost/(Apt SEK y)": 20343,
    "out:Running Cost over RSP/MSEK": 7.634,
    "out:LCP/MSEK": 0.636,
    "out:ROI% old": 17.28,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156635,
    "out:DH kr savings": 154952,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 246672,
    "out:% savings (space heating)": 24.14,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.15,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.11,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 130.26,
    "out:Total Energy Use Post PV": 123.64,
    "out:Primary Energy": 115.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.55,
    "out:CO2 Operational/m2": 28.48,
    "out:Total CO2/m2": 53.02,
    "out:Total CO2 (tons)": 146.71,
    "out:Klimatpaverkan": -20.31,
    "out:Initial Cost/MSEK": 3.182,
    "out:Running cost/(Apt SEK y)": 20169,
    "out:Running Cost over RSP/MSEK": 7.569,
    "out:LCP/MSEK": 0.552,
    "out:ROI% old": 16.76,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159402,
    "out:DH kr savings": 157719,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257628,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.11,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00002201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.15,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 126.26,
    "out:Total Energy Use Post PV": 119.64,
    "out:Primary Energy": 111.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.55,
    "out:CO2 Operational/m2": 27.04,
    "out:Total CO2/m2": 51.58,
    "out:Total CO2 (tons)": 142.73,
    "out:Klimatpaverkan": -24.29,
    "out:Initial Cost/MSEK": 3.251,
    "out:Running cost/(Apt SEK y)": 19472,
    "out:Running Cost over RSP/MSEK": 7.307,
    "out:LCP/MSEK": 0.744,
    "out:ROI% old": 17.55,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 168787,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170470,
    "out:DH kr savings": 168787,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 246672,
    "out:% savings (space heating)": 24.14,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.15,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 179.2,
    "out:Total Energy Use Post PV": 179.2,
    "out:Primary Energy": 153.09,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 60.36,
    "out:Total CO2/m2": 60.36,
    "out:Total CO2 (tons)": 167.02,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 30127,
    "out:Running Cost over RSP/MSEK": 11.302,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 0,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 179,
    "out:Energy use kWh/m2": 179,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 20,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 174.2,
    "out:Total Energy Use Post PV": 174.2,
    "out:Primary Energy": 148.66,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 58.56,
    "out:Total CO2/m2": 58.56,
    "out:Total CO2 (tons)": 162.04,
    "out:Klimatpaverkan": -4.98,
    "out:Initial Cost/MSEK": 0.069,
    "out:Running cost/(Apt SEK y)": 29256,
    "out:Running Cost over RSP/MSEK": 10.975,
    "out:LCP/MSEK": 0.258,
    "out:ROI% old": 67.49,
    "out:Payback discounted": 2,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 2.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 60,
    "out:Return/kSEK/y": 42,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 20,
    "out:Payback Time": 5,
    "out:DH kWh savings": 13835,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 13835,
    "out:DH kr savings": 13835,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 172.2,
    "out:Total Energy Use Post PV": 172.2,
    "out:Primary Energy": 150.47,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.65,
    "out:CO2 Operational/m2": 57.84,
    "out:Total CO2/m2": 58.49,
    "out:Total CO2 (tons)": 161.85,
    "out:Klimatpaverkan": -5.17,
    "out:Initial Cost/MSEK": 0.219,
    "out:Running cost/(Apt SEK y)": 28908,
    "out:Running Cost over RSP/MSEK": 10.844,
    "out:LCP/MSEK": 0.239,
    "out:ROI% old": 29.9,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 4.1,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 27,
    "out:Return/kSEK/y": 58,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 19369,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 19369,
    "out:DH kr savings": 19369,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 167.2,
    "out:Total Energy Use Post PV": 167.2,
    "out:Primary Energy": 146.03,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.65,
    "out:CO2 Operational/m2": 56.04,
    "out:Total CO2/m2": 56.69,
    "out:Total CO2 (tons)": 156.87,
    "out:Klimatpaverkan": -10.15,
    "out:Initial Cost/MSEK": 0.288,
    "out:Running cost/(Apt SEK y)": 28038,
    "out:Running Cost over RSP/MSEK": 10.517,
    "out:LCP/MSEK": 0.497,
    "out:ROI% old": 38.94,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 35,
    "out:Return/kSEK/y": 100,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 33204,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 33204,
    "out:DH kr savings": 33204,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 179.2,
    "out:Total Energy Use Post PV": 173.92,
    "out:Primary Energy": 143.58,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.88,
    "out:CO2 Operational/m2": 41.3,
    "out:Total CO2/m2": 51.18,
    "out:Total CO2 (tons)": 141.62,
    "out:Klimatpaverkan": -25.4,
    "out:Initial Cost/MSEK": 0.46,
    "out:Running cost/(Apt SEK y)": 29201,
    "out:Running Cost over RSP/MSEK": 10.964,
    "out:LCP/MSEK": -0.122,
    "out:ROI% old": 10.49,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 2.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 44,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 44423,
    "out:DH kr savings": 0,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 174.2,
    "out:Total Energy Use Post PV": 168.92,
    "out:Primary Energy": 139.15,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.88,
    "out:CO2 Operational/m2": 39.5,
    "out:Total CO2/m2": 49.38,
    "out:Total CO2 (tons)": 136.64,
    "out:Klimatpaverkan": -30.38,
    "out:Initial Cost/MSEK": 0.53,
    "out:Running cost/(Apt SEK y)": 28331,
    "out:Running Cost over RSP/MSEK": 10.637,
    "out:LCP/MSEK": 0.135,
    "out:ROI% old": 17.94,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 5.9,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 86,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 13835,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 58258,
    "out:DH kr savings": 13835,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 172.2,
    "out:Total Energy Use Post PV": 166.92,
    "out:Primary Energy": 140.96,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.53,
    "out:CO2 Operational/m2": 38.78,
    "out:Total CO2/m2": 49.31,
    "out:Total CO2 (tons)": 136.45,
    "out:Klimatpaverkan": -30.57,
    "out:Initial Cost/MSEK": 0.679,
    "out:Running cost/(Apt SEK y)": 27983,
    "out:Running Cost over RSP/MSEK": 10.506,
    "out:LCP/MSEK": 0.117,
    "out:ROI% old": 16.74,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 103,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 19369,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 63792,
    "out:DH kr savings": 19369,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 167.2,
    "out:Total Energy Use Post PV": 161.92,
    "out:Primary Energy": 136.52,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.53,
    "out:CO2 Operational/m2": 36.98,
    "out:Total CO2/m2": 47.51,
    "out:Total CO2 (tons)": 131.47,
    "out:Klimatpaverkan": -35.55,
    "out:Initial Cost/MSEK": 0.748,
    "out:Running cost/(Apt SEK y)": 27112,
    "out:Running Cost over RSP/MSEK": 10.179,
    "out:LCP/MSEK": 0.374,
    "out:ROI% old": 21.43,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 10.5,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 145,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 33204,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77627,
    "out:DH kr savings": 33204,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 170.2,
    "out:Total Energy Use Post PV": 170.2,
    "out:Primary Energy": 149.59,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 57.12,
    "out:Total CO2/m2": 57.12,
    "out:Total CO2 (tons)": 158.05,
    "out:Klimatpaverkan": -8.97,
    "out:Initial Cost/MSEK": 0.127,
    "out:Running cost/(Apt SEK y)": 28560,
    "out:Running Cost over RSP/MSEK": 10.714,
    "out:LCP/MSEK": 0.462,
    "out:ROI% old": 66.33,
    "out:Payback discounted": 2,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 5.3,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 59,
    "out:Return/kSEK/y": 75,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 20,
    "out:Payback Time": 5,
    "out:DH kWh savings": 24903,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 24903,
    "out:DH kr savings": 24903,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 165.2,
    "out:Total Energy Use Post PV": 165.2,
    "out:Primary Energy": 145.16,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 55.32,
    "out:Total CO2/m2": 55.32,
    "out:Total CO2 (tons)": 153.07,
    "out:Klimatpaverkan": -13.95,
    "out:Initial Cost/MSEK": 0.196,
    "out:Running cost/(Apt SEK y)": 27690,
    "out:Running Cost over RSP/MSEK": 10.387,
    "out:LCP/MSEK": 0.719,
    "out:ROI% old": 66.74,
    "out:Payback discounted": 2,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 8.5,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 60,
    "out:Return/kSEK/y": 117,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 20,
    "out:Payback Time": 5,
    "out:DH kWh savings": 38738,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 38738,
    "out:DH kr savings": 38738,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 165.2,
    "out:Total Energy Use Post PV": 165.2,
    "out:Primary Energy": 147.49,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.65,
    "out:CO2 Operational/m2": 55.32,
    "out:Total CO2/m2": 55.97,
    "out:Total CO2 (tons)": 154.88,
    "out:Klimatpaverkan": -12.14,
    "out:Initial Cost/MSEK": 0.345,
    "out:Running cost/(Apt SEK y)": 27690,
    "out:Running Cost over RSP/MSEK": 10.387,
    "out:LCP/MSEK": 0.57,
    "out:ROI% old": 37.86,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 8.5,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 34,
    "out:Return/kSEK/y": 117,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 38738,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 38738,
    "out:DH kr savings": 38738,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 160.2,
    "out:Total Energy Use Post PV": 160.2,
    "out:Primary Energy": 143.06,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.65,
    "out:CO2 Operational/m2": 53.52,
    "out:Total CO2/m2": 54.17,
    "out:Total CO2 (tons)": 149.9,
    "out:Klimatpaverkan": -17.12,
    "out:Initial Cost/MSEK": 0.414,
    "out:Running cost/(Apt SEK y)": 26819,
    "out:Running Cost over RSP/MSEK": 10.06,
    "out:LCP/MSEK": 0.827,
    "out:ROI% old": 42.81,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 38,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 52573,
    "out:DH kr savings": 52573,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 170.2,
    "out:Total Energy Use Post PV": 164.92,
    "out:Primary Energy": 140.08,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.88,
    "out:CO2 Operational/m2": 38.06,
    "out:Total CO2/m2": 47.94,
    "out:Total CO2 (tons)": 132.66,
    "out:Klimatpaverkan": -34.36,
    "out:Initial Cost/MSEK": 0.587,
    "out:Running cost/(Apt SEK y)": 27635,
    "out:Running Cost over RSP/MSEK": 10.375,
    "out:LCP/MSEK": 0.339,
    "out:ROI% old": 22.54,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 8.5,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 120,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 24903,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69326,
    "out:DH kr savings": 24903,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 165.2,
    "out:Total Energy Use Post PV": 159.92,
    "out:Primary Energy": 135.65,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.88,
    "out:CO2 Operational/m2": 36.26,
    "out:Total CO2/m2": 46.14,
    "out:Total CO2 (tons)": 127.68,
    "out:Klimatpaverkan": -39.34,
    "out:Initial Cost/MSEK": 0.656,
    "out:Running cost/(Apt SEK y)": 26764,
    "out:Running Cost over RSP/MSEK": 10.049,
    "out:LCP/MSEK": 0.597,
    "out:ROI% old": 27.28,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 25,
    "out:Return/kSEK/y": 161,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 38738,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83161,
    "out:DH kr savings": 38738,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 165.2,
    "out:Total Energy Use Post PV": 159.92,
    "out:Primary Energy": 137.98,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.53,
    "out:CO2 Operational/m2": 36.26,
    "out:Total CO2/m2": 46.79,
    "out:Total CO2 (tons)": 129.48,
    "out:Klimatpaverkan": -37.54,
    "out:Initial Cost/MSEK": 0.806,
    "out:Running cost/(Apt SEK y)": 26764,
    "out:Running Cost over RSP/MSEK": 10.049,
    "out:LCP/MSEK": 0.448,
    "out:ROI% old": 22.22,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 161,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 38738,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83161,
    "out:DH kr savings": 38738,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 160.2,
    "out:Total Energy Use Post PV": 154.92,
    "out:Primary Energy": 133.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.53,
    "out:CO2 Operational/m2": 34.46,
    "out:Total CO2/m2": 44.99,
    "out:Total CO2 (tons)": 124.5,
    "out:Klimatpaverkan": -42.52,
    "out:Initial Cost/MSEK": 0.875,
    "out:Running cost/(Apt SEK y)": 25894,
    "out:Running Cost over RSP/MSEK": 9.722,
    "out:LCP/MSEK": 0.705,
    "out:ROI% old": 25.8,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 23,
    "out:Return/kSEK/y": 203,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 96996,
    "out:DH kr savings": 52573,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 168.26,
    "out:Total Energy Use Post PV": 168.26,
    "out:Primary Energy": 154.3,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.8,
    "out:CO2 Operational/m2": 54.58,
    "out:Total CO2/m2": 58.38,
    "out:Total CO2 (tons)": 161.54,
    "out:Klimatpaverkan": -5.48,
    "out:Initial Cost/MSEK": 0.644,
    "out:Running cost/(Apt SEK y)": 27748,
    "out:Running Cost over RSP/MSEK": 10.405,
    "out:LCP/MSEK": 0.253,
    "out:ROI% old": 19.9,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 114,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 1,
    "out:Payback Time": 100,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 7993,
    "out:DH kr savings": 52573,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 163.26,
    "out:Total Energy Use Post PV": 163.26,
    "out:Primary Energy": 149.86,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.8,
    "out:CO2 Operational/m2": 52.78,
    "out:Total CO2/m2": 56.58,
    "out:Total CO2 (tons)": 156.56,
    "out:Klimatpaverkan": -10.46,
    "out:Initial Cost/MSEK": 0.713,
    "out:Running cost/(Apt SEK y)": 26877,
    "out:Running Cost over RSP/MSEK": 10.078,
    "out:LCP/MSEK": 0.511,
    "out:ROI% old": 24.51,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 9.8,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 156,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 66408,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 21828,
    "out:DH kr savings": 66408,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 161.26,
    "out:Total Energy Use Post PV": 161.26,
    "out:Primary Energy": 151.67,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.45,
    "out:CO2 Operational/m2": 52.06,
    "out:Total CO2/m2": 56.51,
    "out:Total CO2 (tons)": 156.37,
    "out:Klimatpaverkan": -10.65,
    "out:Initial Cost/MSEK": 0.863,
    "out:Running cost/(Apt SEK y)": 26529,
    "out:Running Cost over RSP/MSEK": 9.947,
    "out:LCP/MSEK": 0.492,
    "out:ROI% old": 22.43,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 11.2,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 173,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 71942,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 27362,
    "out:DH kr savings": 71942,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 156.26,
    "out:Total Energy Use Post PV": 156.26,
    "out:Primary Energy": 147.24,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.45,
    "out:CO2 Operational/m2": 50.26,
    "out:Total CO2/m2": 54.71,
    "out:Total CO2 (tons)": 151.39,
    "out:Klimatpaverkan": -15.63,
    "out:Initial Cost/MSEK": 0.932,
    "out:Running cost/(Apt SEK y)": 25659,
    "out:Running Cost over RSP/MSEK": 9.62,
    "out:LCP/MSEK": 0.75,
    "out:ROI% old": 25.78,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 214,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 41197,
    "out:DH kr savings": 85777,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 168.26,
    "out:Total Energy Use Post PV": 161.64,
    "out:Primary Energy": 142.39,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.68,
    "out:CO2 Operational/m2": 42.16,
    "out:Total CO2/m2": 55.84,
    "out:Total CO2 (tons)": 154.5,
    "out:Klimatpaverkan": -12.52,
    "out:Initial Cost/MSEK": 1.105,
    "out:Running cost/(Apt SEK y)": 26784,
    "out:Running Cost over RSP/MSEK": 10.052,
    "out:LCP/MSEK": 0.145,
    "out:ROI% old": 16.16,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 10.5,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 160,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 54256,
    "out:DH kr savings": 52573,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 163.26,
    "out:Total Energy Use Post PV": 156.64,
    "out:Primary Energy": 137.95,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.68,
    "out:CO2 Operational/m2": 40.36,
    "out:Total CO2/m2": 54.04,
    "out:Total CO2 (tons)": 149.52,
    "out:Klimatpaverkan": -17.5,
    "out:Initial Cost/MSEK": 1.174,
    "out:Running cost/(Apt SEK y)": 25914,
    "out:Running Cost over RSP/MSEK": 9.726,
    "out:LCP/MSEK": 0.402,
    "out:ROI% old": 19.18,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 202,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 66408,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 68091,
    "out:DH kr savings": 66408,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 161.26,
    "out:Total Energy Use Post PV": 154.64,
    "out:Primary Energy": 139.77,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.33,
    "out:CO2 Operational/m2": 39.64,
    "out:Total CO2/m2": 53.97,
    "out:Total CO2 (tons)": 149.33,
    "out:Klimatpaverkan": -17.69,
    "out:Initial Cost/MSEK": 1.323,
    "out:Running cost/(Apt SEK y)": 25565,
    "out:Running Cost over RSP/MSEK": 9.595,
    "out:LCP/MSEK": 0.384,
    "out:ROI% old": 18.43,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 219,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 71942,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 73625,
    "out:DH kr savings": 71942,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 156.26,
    "out:Total Energy Use Post PV": 149.64,
    "out:Primary Energy": 135.33,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.33,
    "out:CO2 Operational/m2": 37.84,
    "out:Total CO2/m2": 52.17,
    "out:Total CO2 (tons)": 144.35,
    "out:Klimatpaverkan": -22.67,
    "out:Initial Cost/MSEK": 1.392,
    "out:Running cost/(Apt SEK y)": 24695,
    "out:Running Cost over RSP/MSEK": 9.268,
    "out:LCP/MSEK": 0.641,
    "out:ROI% old": 20.87,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 261,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 87460,
    "out:DH kr savings": 85777,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 159.26,
    "out:Total Energy Use Post PV": 159.26,
    "out:Primary Energy": 150.8,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.8,
    "out:CO2 Operational/m2": 51.34,
    "out:Total CO2/m2": 55.14,
    "out:Total CO2 (tons)": 152.58,
    "out:Klimatpaverkan": -14.44,
    "out:Initial Cost/MSEK": 0.771,
    "out:Running cost/(Apt SEK y)": 26181,
    "out:Running Cost over RSP/MSEK": 9.816,
    "out:LCP/MSEK": 0.715,
    "out:ROI% old": 27.53,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 189,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 32896,
    "out:DH kr savings": 77476,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 154.26,
    "out:Total Energy Use Post PV": 154.26,
    "out:Primary Energy": 146.36,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.8,
    "out:CO2 Operational/m2": 49.54,
    "out:Total CO2/m2": 53.34,
    "out:Total CO2 (tons)": 147.59,
    "out:Klimatpaverkan": -19.43,
    "out:Initial Cost/MSEK": 0.84,
    "out:Running cost/(Apt SEK y)": 25311,
    "out:Running Cost over RSP/MSEK": 9.489,
    "out:LCP/MSEK": 0.972,
    "out:ROI% old": 30.82,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 28,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 46731,
    "out:DH kr savings": 91311,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 154.26,
    "out:Total Energy Use Post PV": 154.26,
    "out:Primary Energy": 148.35,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.45,
    "out:CO2 Operational/m2": 49.54,
    "out:Total CO2/m2": 53.99,
    "out:Total CO2 (tons)": 149.4,
    "out:Klimatpaverkan": -17.62,
    "out:Initial Cost/MSEK": 0.989,
    "out:Running cost/(Apt SEK y)": 25311,
    "out:Running Cost over RSP/MSEK": 9.489,
    "out:LCP/MSEK": 0.823,
    "out:ROI% old": 26.16,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 46731,
    "out:DH kr savings": 91311,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 115.53,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 149.26,
    "out:Total Energy Use Post PV": 149.26,
    "out:Primary Energy": 143.91,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.45,
    "out:CO2 Operational/m2": 47.74,
    "out:Total CO2/m2": 52.19,
    "out:Total CO2 (tons)": 144.42,
    "out:Klimatpaverkan": -22.6,
    "out:Initial Cost/MSEK": 1.059,
    "out:Running cost/(Apt SEK y)": 24440,
    "out:Running Cost over RSP/MSEK": 9.163,
    "out:LCP/MSEK": 1.08,
    "out:ROI% old": 28.86,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 26,
    "out:Return/kSEK/y": 273,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 60566,
    "out:DH kr savings": 105146,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 110.46,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 159.26,
    "out:Total Energy Use Post PV": 152.64,
    "out:Primary Energy": 138.89,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.68,
    "out:CO2 Operational/m2": 38.92,
    "out:Total CO2/m2": 52.6,
    "out:Total CO2 (tons)": 145.53,
    "out:Klimatpaverkan": -21.49,
    "out:Initial Cost/MSEK": 1.231,
    "out:Running cost/(Apt SEK y)": 25217,
    "out:Running Cost over RSP/MSEK": 9.464,
    "out:LCP/MSEK": 0.606,
    "out:ROI% old": 21.32,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 236,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 79159,
    "out:DH kr savings": 77476,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 117.52,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 154.26,
    "out:Total Energy Use Post PV": 147.64,
    "out:Primary Energy": 134.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.68,
    "out:CO2 Operational/m2": 37.12,
    "out:Total CO2/m2": 50.8,
    "out:Total CO2 (tons)": 140.55,
    "out:Klimatpaverkan": -26.47,
    "out:Initial Cost/MSEK": 1.301,
    "out:Running cost/(Apt SEK y)": 24347,
    "out:Running Cost over RSP/MSEK": 9.137,
    "out:LCP/MSEK": 0.864,
    "out:ROI% old": 23.78,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 277,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 92994,
    "out:DH kr savings": 91311,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 112.45,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.52,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 154.26,
    "out:Total Energy Use Post PV": 147.64,
    "out:Primary Energy": 136.44,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.33,
    "out:CO2 Operational/m2": 37.12,
    "out:Total CO2/m2": 51.45,
    "out:Total CO2 (tons)": 142.36,
    "out:Klimatpaverkan": -24.66,
    "out:Initial Cost/MSEK": 1.45,
    "out:Running cost/(Apt SEK y)": 24347,
    "out:Running Cost over RSP/MSEK": 9.137,
    "out:LCP/MSEK": 0.714,
    "out:ROI% old": 21.32,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 277,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 92994,
    "out:DH kr savings": 91311,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 325185,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 115.53,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.45,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 149.26,
    "out:Total Energy Use Post PV": 142.64,
    "out:Primary Energy": 132.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.33,
    "out:CO2 Operational/m2": 35.32,
    "out:Total CO2/m2": 49.65,
    "out:Total CO2 (tons)": 137.38,
    "out:Klimatpaverkan": -29.64,
    "out:Initial Cost/MSEK": 1.519,
    "out:Running cost/(Apt SEK y)": 23476,
    "out:Running Cost over RSP/MSEK": 8.81,
    "out:LCP/MSEK": 0.972,
    "out:ROI% old": 23.43,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.96,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 319,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 106829,
    "out:DH kr savings": 105146,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 311159,
    "out:% savings (space heating)": 4.31,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 110.46,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 170.2,
    "out:Total Energy Use Post PV": 170.2,
    "out:Primary Energy": 145.67,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.31,
    "out:CO2 Operational/m2": 57.12,
    "out:Total CO2/m2": 59.43,
    "out:Total CO2 (tons)": 164.46,
    "out:Klimatpaverkan": -2.56,
    "out:Initial Cost/MSEK": 0.447,
    "out:Running cost/(Apt SEK y)": 28560,
    "out:Running Cost over RSP/MSEK": 10.714,
    "out:LCP/MSEK": 0.142,
    "out:ROI% old": 18.81,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 5.3,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 17,
    "out:Return/kSEK/y": 75,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 24903,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 24903,
    "out:DH kr savings": 24903,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 166.2,
    "out:Total Energy Use Post PV": 166.2,
    "out:Primary Energy": 141.57,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.31,
    "out:CO2 Operational/m2": 55.68,
    "out:Total CO2/m2": 57.99,
    "out:Total CO2 (tons)": 160.47,
    "out:Klimatpaverkan": -6.55,
    "out:Initial Cost/MSEK": 0.516,
    "out:Running cost/(Apt SEK y)": 27864,
    "out:Running Cost over RSP/MSEK": 10.452,
    "out:LCP/MSEK": 0.334,
    "out:ROI% old": 23.53,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 7.8,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 21,
    "out:Return/kSEK/y": 109,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 35971,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 35971,
    "out:DH kr savings": 35971,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 164.2,
    "out:Total Energy Use Post PV": 164.2,
    "out:Primary Energy": 143.04,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.97,
    "out:CO2 Operational/m2": 54.96,
    "out:Total CO2/m2": 57.93,
    "out:Total CO2 (tons)": 160.28,
    "out:Klimatpaverkan": -6.74,
    "out:Initial Cost/MSEK": 0.665,
    "out:Running cost/(Apt SEK y)": 27516,
    "out:Running Cost over RSP/MSEK": 10.321,
    "out:LCP/MSEK": 0.315,
    "out:ROI% old": 21.05,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 9.1,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 19,
    "out:Return/kSEK/y": 125,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 41505,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 41505,
    "out:DH kr savings": 41505,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 159.2,
    "out:Total Energy Use Post PV": 159.2,
    "out:Primary Energy": 138.94,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.97,
    "out:CO2 Operational/m2": 53.16,
    "out:Total CO2/m2": 56.13,
    "out:Total CO2 (tons)": 155.3,
    "out:Klimatpaverkan": -11.72,
    "out:Initial Cost/MSEK": 0.734,
    "out:Running cost/(Apt SEK y)": 26645,
    "out:Running Cost over RSP/MSEK": 9.995,
    "out:LCP/MSEK": 0.573,
    "out:ROI% old": 25.43,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 23,
    "out:Return/kSEK/y": 167,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 55340,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 55340,
    "out:DH kr savings": 55340,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 170.2,
    "out:Total Energy Use Post PV": 164.92,
    "out:Primary Energy": 136.16,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.2,
    "out:CO2 Operational/m2": 38.06,
    "out:Total CO2/m2": 50.26,
    "out:Total CO2 (tons)": 139.06,
    "out:Klimatpaverkan": -27.96,
    "out:Initial Cost/MSEK": 0.907,
    "out:Running cost/(Apt SEK y)": 27635,
    "out:Running Cost over RSP/MSEK": 10.375,
    "out:LCP/MSEK": 0.019,
    "out:ROI% old": 14.59,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 8.5,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 120,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 24903,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69326,
    "out:DH kr savings": 24903,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 166.2,
    "out:Total Energy Use Post PV": 160.92,
    "out:Primary Energy": 132.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.2,
    "out:CO2 Operational/m2": 36.62,
    "out:Total CO2/m2": 48.82,
    "out:Total CO2 (tons)": 135.08,
    "out:Klimatpaverkan": -31.94,
    "out:Initial Cost/MSEK": 0.976,
    "out:Running cost/(Apt SEK y)": 26938,
    "out:Running Cost over RSP/MSEK": 10.114,
    "out:LCP/MSEK": 0.212,
    "out:ROI% old": 17.38,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 11.2,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 153,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 35971,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 80394,
    "out:DH kr savings": 35971,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 164.2,
    "out:Total Energy Use Post PV": 158.92,
    "out:Primary Energy": 133.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.85,
    "out:CO2 Operational/m2": 35.9,
    "out:Total CO2/m2": 48.75,
    "out:Total CO2 (tons)": 134.89,
    "out:Klimatpaverkan": -32.13,
    "out:Initial Cost/MSEK": 1.126,
    "out:Running cost/(Apt SEK y)": 26590,
    "out:Running Cost over RSP/MSEK": 9.983,
    "out:LCP/MSEK": 0.193,
    "out:ROI% old": 16.73,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 170,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 41505,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85928,
    "out:DH kr savings": 41505,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 159.2,
    "out:Total Energy Use Post PV": 153.92,
    "out:Primary Energy": 129.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.85,
    "out:CO2 Operational/m2": 34.1,
    "out:Total CO2/m2": 46.95,
    "out:Total CO2 (tons)": 129.91,
    "out:Klimatpaverkan": -37.11,
    "out:Initial Cost/MSEK": 1.195,
    "out:Running cost/(Apt SEK y)": 25720,
    "out:Running Cost over RSP/MSEK": 9.656,
    "out:LCP/MSEK": 0.451,
    "out:ROI% old": 19.67,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 212,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 55340,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99763,
    "out:DH kr savings": 55340,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 162.2,
    "out:Total Energy Use Post PV": 162.2,
    "out:Primary Energy": 142.17,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.31,
    "out:CO2 Operational/m2": 54.24,
    "out:Total CO2/m2": 56.55,
    "out:Total CO2 (tons)": 156.49,
    "out:Klimatpaverkan": -10.53,
    "out:Initial Cost/MSEK": 0.573,
    "out:Running cost/(Apt SEK y)": 27167,
    "out:Running Cost over RSP/MSEK": 10.191,
    "out:LCP/MSEK": 0.538,
    "out:ROI% old": 27.69,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 10.5,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 25,
    "out:Return/kSEK/y": 142,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 47039,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 47039,
    "out:DH kr savings": 47039,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 157.2,
    "out:Primary Energy": 138.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.31,
    "out:CO2 Operational/m2": 52.44,
    "out:Total CO2/m2": 54.75,
    "out:Total CO2 (tons)": 151.51,
    "out:Klimatpaverkan": -15.51,
    "out:Initial Cost/MSEK": 0.643,
    "out:Running cost/(Apt SEK y)": 26297,
    "out:Running Cost over RSP/MSEK": 9.864,
    "out:LCP/MSEK": 0.795,
    "out:ROI% old": 31.97,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 29,
    "out:Return/kSEK/y": 184,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 60874,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 60874,
    "out:DH kr savings": 60874,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 156.2,
    "out:Primary Energy": 140.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.97,
    "out:CO2 Operational/m2": 52.08,
    "out:Total CO2/m2": 55.05,
    "out:Total CO2 (tons)": 152.32,
    "out:Klimatpaverkan": -14.7,
    "out:Initial Cost/MSEK": 0.792,
    "out:Running cost/(Apt SEK y)": 26123,
    "out:Running Cost over RSP/MSEK": 9.798,
    "out:LCP/MSEK": 0.711,
    "out:ROI% old": 27.12,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 24,
    "out:Return/kSEK/y": 192,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 63641,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 63641,
    "out:DH kr savings": 63641,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 152.2,
    "out:Primary Energy": 135.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.97,
    "out:CO2 Operational/m2": 50.64,
    "out:Total CO2/m2": 53.61,
    "out:Total CO2 (tons)": 148.33,
    "out:Klimatpaverkan": -18.69,
    "out:Initial Cost/MSEK": 0.861,
    "out:Running cost/(Apt SEK y)": 25426,
    "out:Running Cost over RSP/MSEK": 9.537,
    "out:LCP/MSEK": 0.904,
    "out:ROI% old": 29.28,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 17.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 26,
    "out:Return/kSEK/y": 226,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 74709,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 74709,
    "out:DH kr savings": 74709,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 162.2,
    "out:Total Energy Use Post PV": 156.92,
    "out:Primary Energy": 132.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.2,
    "out:CO2 Operational/m2": 35.18,
    "out:Total CO2/m2": 47.38,
    "out:Total CO2 (tons)": 131.09,
    "out:Klimatpaverkan": -35.93,
    "out:Initial Cost/MSEK": 1.034,
    "out:Running cost/(Apt SEK y)": 26242,
    "out:Running Cost over RSP/MSEK": 9.852,
    "out:LCP/MSEK": 0.416,
    "out:ROI% old": 20.03,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 186,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 47039,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91462,
    "out:DH kr savings": 47039,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 151.92,
    "out:Primary Energy": 128.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.2,
    "out:CO2 Operational/m2": 33.38,
    "out:Total CO2/m2": 45.58,
    "out:Total CO2 (tons)": 126.11,
    "out:Klimatpaverkan": -40.91,
    "out:Initial Cost/MSEK": 1.103,
    "out:Running cost/(Apt SEK y)": 25371,
    "out:Running Cost over RSP/MSEK": 9.526,
    "out:LCP/MSEK": 0.673,
    "out:ROI% old": 23,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 17.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 228,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 60874,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105297,
    "out:DH kr savings": 60874,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 150.92,
    "out:Primary Energy": 130.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.85,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 45.87,
    "out:Total CO2 (tons)": 126.92,
    "out:Klimatpaverkan": -40.1,
    "out:Initial Cost/MSEK": 1.252,
    "out:Running cost/(Apt SEK y)": 25197,
    "out:Running Cost over RSP/MSEK": 9.46,
    "out:LCP/MSEK": 0.589,
    "out:ROI% old": 21.01,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 237,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 63641,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108064,
    "out:DH kr savings": 63641,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 146.92,
    "out:Primary Energy": 126.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.85,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 44.43,
    "out:Total CO2 (tons)": 122.94,
    "out:Klimatpaverkan": -44.08,
    "out:Initial Cost/MSEK": 1.322,
    "out:Running cost/(Apt SEK y)": 24501,
    "out:Running Cost over RSP/MSEK": 9.199,
    "out:LCP/MSEK": 0.781,
    "out:ROI% old": 22.73,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 270,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 74709,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119132,
    "out:DH kr savings": 74709,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 159.26,
    "out:Total Energy Use Post PV": 159.26,
    "out:Primary Energy": 146.87,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.11,
    "out:CO2 Operational/m2": 51.34,
    "out:Total CO2/m2": 57.46,
    "out:Total CO2 (tons)": 158.98,
    "out:Klimatpaverkan": -8.04,
    "out:Initial Cost/MSEK": 1.091,
    "out:Running cost/(Apt SEK y)": 26181,
    "out:Running Cost over RSP/MSEK": 9.816,
    "out:LCP/MSEK": 0.395,
    "out:ROI% old": 19.45,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 189,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 32896,
    "out:DH kr savings": 77476,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 155.26,
    "out:Total Energy Use Post PV": 155.26,
    "out:Primary Energy": 142.77,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.11,
    "out:CO2 Operational/m2": 49.9,
    "out:Total CO2/m2": 56.02,
    "out:Total CO2 (tons)": 154.99,
    "out:Klimatpaverkan": -12.03,
    "out:Initial Cost/MSEK": 1.16,
    "out:Running cost/(Apt SEK y)": 25485,
    "out:Running Cost over RSP/MSEK": 9.555,
    "out:LCP/MSEK": 0.587,
    "out:ROI% old": 21.51,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 223,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 43964,
    "out:DH kr savings": 88544,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 153.26,
    "out:Total Energy Use Post PV": 153.26,
    "out:Primary Energy": 144.24,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.76,
    "out:CO2 Operational/m2": 49.18,
    "out:Total CO2/m2": 55.95,
    "out:Total CO2 (tons)": 154.81,
    "out:Klimatpaverkan": -12.21,
    "out:Initial Cost/MSEK": 1.31,
    "out:Running cost/(Apt SEK y)": 25137,
    "out:Running Cost over RSP/MSEK": 9.424,
    "out:LCP/MSEK": 0.568,
    "out:ROI% old": 20.48,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 240,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 49498,
    "out:DH kr savings": 94078,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 148.26,
    "out:Total Energy Use Post PV": 148.26,
    "out:Primary Energy": 140.15,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.76,
    "out:CO2 Operational/m2": 47.38,
    "out:Total CO2/m2": 54.15,
    "out:Total CO2 (tons)": 149.83,
    "out:Klimatpaverkan": -17.19,
    "out:Initial Cost/MSEK": 1.379,
    "out:Running cost/(Apt SEK y)": 24266,
    "out:Running Cost over RSP/MSEK": 9.097,
    "out:LCP/MSEK": 0.826,
    "out:ROI% old": 22.84,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 281,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 63333,
    "out:DH kr savings": 107913,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 159.26,
    "out:Total Energy Use Post PV": 152.64,
    "out:Primary Energy": 134.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.99,
    "out:CO2 Operational/m2": 38.92,
    "out:Total CO2/m2": 54.91,
    "out:Total CO2 (tons)": 151.94,
    "out:Klimatpaverkan": -15.08,
    "out:Initial Cost/MSEK": 1.551,
    "out:Running cost/(Apt SEK y)": 25217,
    "out:Running Cost over RSP/MSEK": 9.464,
    "out:LCP/MSEK": 0.286,
    "out:ROI% old": 16.92,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 236,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 79159,
    "out:DH kr savings": 77476,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 155.26,
    "out:Total Energy Use Post PV": 148.64,
    "out:Primary Energy": 130.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.99,
    "out:CO2 Operational/m2": 37.48,
    "out:Total CO2/m2": 53.47,
    "out:Total CO2 (tons)": 147.95,
    "out:Klimatpaverkan": -19.07,
    "out:Initial Cost/MSEK": 1.621,
    "out:Running cost/(Apt SEK y)": 24521,
    "out:Running Cost over RSP/MSEK": 9.203,
    "out:LCP/MSEK": 0.479,
    "out:ROI% old": 18.51,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 269,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 90227,
    "out:DH kr savings": 88544,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 153.26,
    "out:Total Energy Use Post PV": 146.64,
    "out:Primary Energy": 132.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.65,
    "out:CO2 Operational/m2": 36.76,
    "out:Total CO2/m2": 53.4,
    "out:Total CO2 (tons)": 147.77,
    "out:Klimatpaverkan": -19.25,
    "out:Initial Cost/MSEK": 1.77,
    "out:Running cost/(Apt SEK y)": 24173,
    "out:Running Cost over RSP/MSEK": 9.072,
    "out:LCP/MSEK": 0.46,
    "out:ROI% old": 18,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 286,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95761,
    "out:DH kr savings": 94078,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 148.26,
    "out:Total Energy Use Post PV": 141.64,
    "out:Primary Energy": 128.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.65,
    "out:CO2 Operational/m2": 34.96,
    "out:Total CO2/m2": 51.6,
    "out:Total CO2 (tons)": 142.79,
    "out:Klimatpaverkan": -24.23,
    "out:Initial Cost/MSEK": 1.839,
    "out:Running cost/(Apt SEK y)": 23302,
    "out:Running Cost over RSP/MSEK": 8.745,
    "out:LCP/MSEK": 0.718,
    "out:ROI% old": 19.86,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 328,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109596,
    "out:DH kr savings": 107913,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 151.26,
    "out:Total Energy Use Post PV": 151.26,
    "out:Primary Energy": 143.37,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.11,
    "out:CO2 Operational/m2": 48.46,
    "out:Total CO2/m2": 54.58,
    "out:Total CO2 (tons)": 151.01,
    "out:Klimatpaverkan": -16.01,
    "out:Initial Cost/MSEK": 1.218,
    "out:Running cost/(Apt SEK y)": 24788,
    "out:Running Cost over RSP/MSEK": 9.293,
    "out:LCP/MSEK": 0.791,
    "out:ROI% old": 23.56,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 256,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 55032,
    "out:DH kr savings": 99612,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 146.26,
    "out:Total Energy Use Post PV": 146.26,
    "out:Primary Energy": 139.27,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.11,
    "out:CO2 Operational/m2": 46.66,
    "out:Total CO2/m2": 52.78,
    "out:Total CO2 (tons)": 146.03,
    "out:Klimatpaverkan": -20.99,
    "out:Initial Cost/MSEK": 1.287,
    "out:Running cost/(Apt SEK y)": 23918,
    "out:Running Cost over RSP/MSEK": 8.967,
    "out:LCP/MSEK": 1.048,
    "out:ROI% old": 25.93,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 68867,
    "out:DH kr savings": 113447,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 145.26,
    "out:Total Energy Use Post PV": 145.26,
    "out:Primary Energy": 140.92,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.76,
    "out:CO2 Operational/m2": 46.3,
    "out:Total CO2/m2": 53.07,
    "out:Total CO2 (tons)": 146.84,
    "out:Klimatpaverkan": -20.18,
    "out:Initial Cost/MSEK": 1.436,
    "out:Running cost/(Apt SEK y)": 23744,
    "out:Running Cost over RSP/MSEK": 8.901,
    "out:LCP/MSEK": 0.964,
    "out:ROI% old": 23.88,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 306,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 71634,
    "out:DH kr savings": 116214,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 107.04,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 141.26,
    "out:Total Energy Use Post PV": 141.26,
    "out:Primary Energy": 136.82,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.76,
    "out:CO2 Operational/m2": 44.86,
    "out:Total CO2/m2": 51.63,
    "out:Total CO2 (tons)": 142.85,
    "out:Klimatpaverkan": -24.17,
    "out:Initial Cost/MSEK": 1.505,
    "out:Running cost/(Apt SEK y)": 23047,
    "out:Running Cost over RSP/MSEK": 8.64,
    "out:LCP/MSEK": 1.157,
    "out:ROI% old": 25.26,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 82702,
    "out:DH kr savings": 127282,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 102.36,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 151.26,
    "out:Total Energy Use Post PV": 144.64,
    "out:Primary Energy": 131.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.99,
    "out:CO2 Operational/m2": 36.04,
    "out:Total CO2/m2": 52.03,
    "out:Total CO2 (tons)": 143.97,
    "out:Klimatpaverkan": -23.05,
    "out:Initial Cost/MSEK": 1.678,
    "out:Running cost/(Apt SEK y)": 23825,
    "out:Running Cost over RSP/MSEK": 8.941,
    "out:LCP/MSEK": 0.683,
    "out:ROI% old": 20.1,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 101295,
    "out:DH kr savings": 99612,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 109.04,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 146.26,
    "out:Total Energy Use Post PV": 139.64,
    "out:Primary Energy": 127.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.99,
    "out:CO2 Operational/m2": 34.24,
    "out:Total CO2/m2": 50.23,
    "out:Total CO2 (tons)": 138.99,
    "out:Klimatpaverkan": -28.03,
    "out:Initial Cost/MSEK": 1.747,
    "out:Running cost/(Apt SEK y)": 22954,
    "out:Running Cost over RSP/MSEK": 8.614,
    "out:LCP/MSEK": 0.94,
    "out:ROI% old": 21.97,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 344,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115130,
    "out:DH kr savings": 113447,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.35,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.04,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 145.26,
    "out:Total Energy Use Post PV": 138.64,
    "out:Primary Energy": 129.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.65,
    "out:CO2 Operational/m2": 33.88,
    "out:Total CO2/m2": 50.52,
    "out:Total CO2 (tons)": 139.8,
    "out:Klimatpaverkan": -27.22,
    "out:Initial Cost/MSEK": 1.897,
    "out:Running cost/(Apt SEK y)": 22780,
    "out:Running Cost over RSP/MSEK": 8.549,
    "out:LCP/MSEK": 0.856,
    "out:ROI% old": 20.73,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 353,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117897,
    "out:DH kr savings": 116214,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 301702,
    "out:% savings (space heating)": 7.22,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 107.04,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.35,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 141.26,
    "out:Total Energy Use Post PV": 134.64,
    "out:Primary Energy": 124.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.65,
    "out:CO2 Operational/m2": 32.44,
    "out:Total CO2/m2": 49.08,
    "out:Total CO2 (tons)": 135.81,
    "out:Klimatpaverkan": -31.21,
    "out:Initial Cost/MSEK": 1.966,
    "out:Running cost/(Apt SEK y)": 22084,
    "out:Running Cost over RSP/MSEK": 8.288,
    "out:LCP/MSEK": 1.048,
    "out:ROI% old": 21.9,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128965,
    "out:DH kr savings": 127282,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 288743,
    "out:% savings (space heating)": 11.21,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 102.36,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 168.2,
    "out:Total Energy Use Post PV": 168.2,
    "out:Primary Energy": 143.23,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.07,
    "out:CO2 Operational/m2": 56.4,
    "out:Total CO2/m2": 64.47,
    "out:Total CO2 (tons)": 178.38,
    "out:Klimatpaverkan": 11.36,
    "out:Initial Cost/MSEK": 1.54,
    "out:Running cost/(Apt SEK y)": 28212,
    "out:Running Cost over RSP/MSEK": 10.583,
    "out:LCP/MSEK": -0.821,
    "out:ROI% old": 6.67,
    "out:Payback discounted": 18,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 6,
    "out:Return/kSEK/y": 92,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 30437,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 30437,
    "out:DH kr savings": 30437,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 163.2,
    "out:Total Energy Use Post PV": 163.2,
    "out:Primary Energy": 139.24,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.07,
    "out:CO2 Operational/m2": 54.6,
    "out:Total CO2/m2": 62.67,
    "out:Total CO2 (tons)": 173.4,
    "out:Klimatpaverkan": 6.38,
    "out:Initial Cost/MSEK": 1.61,
    "out:Running cost/(Apt SEK y)": 27341,
    "out:Running Cost over RSP/MSEK": 10.256,
    "out:LCP/MSEK": -0.564,
    "out:ROI% old": 9.28,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 9.8,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 134,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 44272,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 44272,
    "out:DH kr savings": 44272,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 161.2,
    "out:Total Energy Use Post PV": 161.2,
    "out:Primary Energy": 140.6,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.72,
    "out:CO2 Operational/m2": 53.88,
    "out:Total CO2/m2": 62.6,
    "out:Total CO2 (tons)": 173.21,
    "out:Klimatpaverkan": 6.19,
    "out:Initial Cost/MSEK": 1.759,
    "out:Running cost/(Apt SEK y)": 26993,
    "out:Running Cost over RSP/MSEK": 10.125,
    "out:LCP/MSEK": -0.582,
    "out:ROI% old": 9.56,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 11.2,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 150,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 49806,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 49806,
    "out:DH kr savings": 49806,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 156.2,
    "out:Primary Energy": 136.61,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.72,
    "out:CO2 Operational/m2": 52.08,
    "out:Total CO2/m2": 60.8,
    "out:Total CO2 (tons)": 168.23,
    "out:Klimatpaverkan": 1.21,
    "out:Initial Cost/MSEK": 1.828,
    "out:Running cost/(Apt SEK y)": 26123,
    "out:Running Cost over RSP/MSEK": 9.798,
    "out:LCP/MSEK": -0.325,
    "out:ROI% old": 11.75,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 192,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 63641,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 63641,
    "out:DH kr savings": 63641,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 168.2,
    "out:Total Energy Use Post PV": 162.92,
    "out:Primary Energy": 133.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.95,
    "out:CO2 Operational/m2": 37.34,
    "out:Total CO2/m2": 55.29,
    "out:Total CO2 (tons)": 152.99,
    "out:Klimatpaverkan": -14.03,
    "out:Initial Cost/MSEK": 2.001,
    "out:Running cost/(Apt SEK y)": 27286,
    "out:Running Cost over RSP/MSEK": 10.245,
    "out:LCP/MSEK": -0.944,
    "out:ROI% old": 7.55,
    "out:Payback discounted": 16,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 9.8,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 136,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 30437,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74860,
    "out:DH kr savings": 30437,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 163.2,
    "out:Total Energy Use Post PV": 157.92,
    "out:Primary Energy": 129.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.95,
    "out:CO2 Operational/m2": 35.54,
    "out:Total CO2/m2": 53.49,
    "out:Total CO2 (tons)": 148.01,
    "out:Klimatpaverkan": -19.01,
    "out:Initial Cost/MSEK": 2.07,
    "out:Running cost/(Apt SEK y)": 26416,
    "out:Running Cost over RSP/MSEK": 9.918,
    "out:LCP/MSEK": -0.686,
    "out:ROI% old": 9.55,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 178,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 44272,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88695,
    "out:DH kr savings": 44272,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 161.2,
    "out:Total Energy Use Post PV": 155.92,
    "out:Primary Energy": 131.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.6,
    "out:CO2 Operational/m2": 34.82,
    "out:Total CO2/m2": 53.42,
    "out:Total CO2 (tons)": 147.82,
    "out:Klimatpaverkan": -19.2,
    "out:Initial Cost/MSEK": 2.219,
    "out:Running cost/(Apt SEK y)": 26068,
    "out:Running Cost over RSP/MSEK": 9.787,
    "out:LCP/MSEK": -0.705,
    "out:ROI% old": 9.75,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 195,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 49806,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 94229,
    "out:DH kr savings": 49806,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 150.92,
    "out:Primary Energy": 127.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.6,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 51.62,
    "out:Total CO2 (tons)": 142.84,
    "out:Klimatpaverkan": -24.18,
    "out:Initial Cost/MSEK": 2.289,
    "out:Running cost/(Apt SEK y)": 25197,
    "out:Running Cost over RSP/MSEK": 9.46,
    "out:LCP/MSEK": -0.447,
    "out:ROI% old": 11.5,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 237,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 63641,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 108064,
    "out:DH kr savings": 63641,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 159.2,
    "out:Total Energy Use Post PV": 159.2,
    "out:Primary Energy": 139.73,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.07,
    "out:CO2 Operational/m2": 53.16,
    "out:Total CO2/m2": 61.23,
    "out:Total CO2 (tons)": 169.42,
    "out:Klimatpaverkan": 2.4,
    "out:Initial Cost/MSEK": 1.667,
    "out:Running cost/(Apt SEK y)": 26645,
    "out:Running Cost over RSP/MSEK": 9.995,
    "out:LCP/MSEK": -0.36,
    "out:ROI% old": 11.2,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 167,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 55340,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 55340,
    "out:DH kr savings": 55340,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 154.2,
    "out:Primary Energy": 135.74,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.07,
    "out:CO2 Operational/m2": 51.36,
    "out:Total CO2/m2": 59.43,
    "out:Total CO2 (tons)": 164.44,
    "out:Klimatpaverkan": -2.58,
    "out:Initial Cost/MSEK": 1.736,
    "out:Running cost/(Apt SEK y)": 25775,
    "out:Running Cost over RSP/MSEK": 9.668,
    "out:LCP/MSEK": -0.102,
    "out:ROI% old": 13.45,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 209,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 69175,
    "out:DH kr savings": 69175,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 154.2,
    "out:Primary Energy": 137.63,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.72,
    "out:CO2 Operational/m2": 51.36,
    "out:Total CO2/m2": 60.08,
    "out:Total CO2 (tons)": 166.24,
    "out:Klimatpaverkan": -0.78,
    "out:Initial Cost/MSEK": 1.886,
    "out:Running cost/(Apt SEK y)": 25775,
    "out:Running Cost over RSP/MSEK": 9.668,
    "out:LCP/MSEK": -0.252,
    "out:ROI% old": 12.38,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 209,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69175,
    "out:DH kr savings": 69175,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 149.2,
    "out:Primary Energy": 133.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.72,
    "out:CO2 Operational/m2": 49.56,
    "out:Total CO2/m2": 58.28,
    "out:Total CO2 (tons)": 161.26,
    "out:Klimatpaverkan": -5.76,
    "out:Initial Cost/MSEK": 1.955,
    "out:Running cost/(Apt SEK y)": 24904,
    "out:Running Cost over RSP/MSEK": 9.341,
    "out:LCP/MSEK": 0.006,
    "out:ROI% old": 14.33,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 251,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83010,
    "out:DH kr savings": 83010,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00010200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 159.2,
    "out:Total Energy Use Post PV": 153.92,
    "out:Primary Energy": 130.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.95,
    "out:CO2 Operational/m2": 34.1,
    "out:Total CO2/m2": 52.05,
    "out:Total CO2 (tons)": 144.02,
    "out:Klimatpaverkan": -23,
    "out:Initial Cost/MSEK": 2.128,
    "out:Running cost/(Apt SEK y)": 25720,
    "out:Running Cost over RSP/MSEK": 9.656,
    "out:LCP/MSEK": -0.482,
    "out:ROI% old": 11.05,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 212,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 55340,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99763,
    "out:DH kr savings": 55340,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 148.92,
    "out:Primary Energy": 126.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.95,
    "out:CO2 Operational/m2": 32.3,
    "out:Total CO2/m2": 50.25,
    "out:Total CO2 (tons)": 139.04,
    "out:Klimatpaverkan": -27.98,
    "out:Initial Cost/MSEK": 2.197,
    "out:Running cost/(Apt SEK y)": 24849,
    "out:Running Cost over RSP/MSEK": 9.33,
    "out:LCP/MSEK": -0.224,
    "out:ROI% old": 12.83,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113598,
    "out:DH kr savings": 69175,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 148.92,
    "out:Primary Energy": 128.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.6,
    "out:CO2 Operational/m2": 32.3,
    "out:Total CO2/m2": 50.9,
    "out:Total CO2 (tons)": 140.85,
    "out:Klimatpaverkan": -26.17,
    "out:Initial Cost/MSEK": 2.346,
    "out:Running cost/(Apt SEK y)": 24849,
    "out:Running Cost over RSP/MSEK": 9.33,
    "out:LCP/MSEK": -0.374,
    "out:ROI% old": 12.01,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113598,
    "out:DH kr savings": 69175,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 143.92,
    "out:Primary Energy": 124.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.6,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 49.1,
    "out:Total CO2 (tons)": 135.87,
    "out:Klimatpaverkan": -31.15,
    "out:Initial Cost/MSEK": 2.415,
    "out:Running cost/(Apt SEK y)": 23979,
    "out:Running Cost over RSP/MSEK": 9.003,
    "out:LCP/MSEK": -0.116,
    "out:ROI% old": 13.6,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 127433,
    "out:DH kr savings": 83010,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00010201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 157.26,
    "out:Total Energy Use Post PV": 157.26,
    "out:Primary Energy": 144.43,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.86,
    "out:CO2 Operational/m2": 50.62,
    "out:Total CO2/m2": 62.49,
    "out:Total CO2 (tons)": 172.9,
    "out:Klimatpaverkan": 5.88,
    "out:Initial Cost/MSEK": 2.185,
    "out:Running cost/(Apt SEK y)": 25833,
    "out:Running Cost over RSP/MSEK": 9.686,
    "out:LCP/MSEK": -0.568,
    "out:ROI% old": 10.57,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 38430,
    "out:DH kr savings": 83010,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 152.26,
    "out:Total Energy Use Post PV": 152.26,
    "out:Primary Energy": 140.44,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.86,
    "out:CO2 Operational/m2": 48.82,
    "out:Total CO2/m2": 60.69,
    "out:Total CO2 (tons)": 167.92,
    "out:Klimatpaverkan": 0.9,
    "out:Initial Cost/MSEK": 2.254,
    "out:Running cost/(Apt SEK y)": 24962,
    "out:Running Cost over RSP/MSEK": 9.359,
    "out:LCP/MSEK": -0.311,
    "out:ROI% old": 12.32,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 17.8,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 248,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 52265,
    "out:DH kr savings": 96845,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 150.26,
    "out:Total Energy Use Post PV": 150.26,
    "out:Primary Energy": 141.81,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.52,
    "out:CO2 Operational/m2": 48.1,
    "out:Total CO2/m2": 60.62,
    "out:Total CO2 (tons)": 167.74,
    "out:Klimatpaverkan": 0.72,
    "out:Initial Cost/MSEK": 2.403,
    "out:Running cost/(Apt SEK y)": 24614,
    "out:Running Cost over RSP/MSEK": 9.228,
    "out:LCP/MSEK": -0.329,
    "out:ROI% old": 12.33,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 265,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 57799,
    "out:DH kr savings": 102379,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 146.26,
    "out:Total Energy Use Post PV": 146.26,
    "out:Primary Energy": 137.82,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.52,
    "out:CO2 Operational/m2": 46.66,
    "out:Total CO2/m2": 59.18,
    "out:Total CO2 (tons)": 163.75,
    "out:Klimatpaverkan": -3.27,
    "out:Initial Cost/MSEK": 2.472,
    "out:Running cost/(Apt SEK y)": 23918,
    "out:Running Cost over RSP/MSEK": 8.967,
    "out:LCP/MSEK": -0.137,
    "out:ROI% old": 13.49,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 68867,
    "out:DH kr savings": 113447,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 157.26,
    "out:Total Energy Use Post PV": 150.64,
    "out:Primary Energy": 132.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.75,
    "out:CO2 Operational/m2": 38.2,
    "out:Total CO2/m2": 59.94,
    "out:Total CO2 (tons)": 165.86,
    "out:Klimatpaverkan": -1.16,
    "out:Initial Cost/MSEK": 2.645,
    "out:Running cost/(Apt SEK y)": 24869,
    "out:Running Cost over RSP/MSEK": 9.333,
    "out:LCP/MSEK": -0.677,
    "out:ROI% old": 10.63,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 252,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 84693,
    "out:DH kr savings": 83010,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 152.26,
    "out:Total Energy Use Post PV": 145.64,
    "out:Primary Energy": 128.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.75,
    "out:CO2 Operational/m2": 36.4,
    "out:Total CO2/m2": 58.14,
    "out:Total CO2 (tons)": 160.88,
    "out:Klimatpaverkan": -6.14,
    "out:Initial Cost/MSEK": 2.714,
    "out:Running cost/(Apt SEK y)": 23999,
    "out:Running Cost over RSP/MSEK": 9.007,
    "out:LCP/MSEK": -0.419,
    "out:ROI% old": 12.08,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 294,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 98528,
    "out:DH kr savings": 96845,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 150.26,
    "out:Total Energy Use Post PV": 143.64,
    "out:Primary Energy": 129.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.4,
    "out:CO2 Operational/m2": 35.68,
    "out:Total CO2/m2": 58.08,
    "out:Total CO2 (tons)": 160.7,
    "out:Klimatpaverkan": -6.32,
    "out:Initial Cost/MSEK": 2.864,
    "out:Running cost/(Apt SEK y)": 23650,
    "out:Running Cost over RSP/MSEK": 8.876,
    "out:LCP/MSEK": -0.438,
    "out:ROI% old": 12.1,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 104062,
    "out:DH kr savings": 102379,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 146.26,
    "out:Total Energy Use Post PV": 139.64,
    "out:Primary Energy": 125.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.4,
    "out:CO2 Operational/m2": 34.24,
    "out:Total CO2/m2": 56.64,
    "out:Total CO2 (tons)": 156.71,
    "out:Klimatpaverkan": -10.31,
    "out:Initial Cost/MSEK": 2.933,
    "out:Running cost/(Apt SEK y)": 22954,
    "out:Running Cost over RSP/MSEK": 8.614,
    "out:LCP/MSEK": -0.245,
    "out:ROI% old": 13.09,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 344,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115130,
    "out:DH kr savings": 113447,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 148.26,
    "out:Total Energy Use Post PV": 148.26,
    "out:Primary Energy": 140.93,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.86,
    "out:CO2 Operational/m2": 47.38,
    "out:Total CO2/m2": 59.25,
    "out:Total CO2 (tons)": 163.94,
    "out:Klimatpaverkan": -3.08,
    "out:Initial Cost/MSEK": 2.311,
    "out:Running cost/(Apt SEK y)": 24266,
    "out:Running Cost over RSP/MSEK": 9.097,
    "out:LCP/MSEK": -0.107,
    "out:ROI% old": 13.63,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 281,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 63333,
    "out:DH kr savings": 107913,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 143.26,
    "out:Primary Energy": 136.94,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.86,
    "out:CO2 Operational/m2": 45.58,
    "out:Total CO2/m2": 57.45,
    "out:Total CO2 (tons)": 158.96,
    "out:Klimatpaverkan": -8.06,
    "out:Initial Cost/MSEK": 2.38,
    "out:Running cost/(Apt SEK y)": 23396,
    "out:Running Cost over RSP/MSEK": 8.771,
    "out:LCP/MSEK": 0.151,
    "out:ROI% old": 15.19,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77168,
    "out:DH kr savings": 121748,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 143.26,
    "out:Primary Energy": 138.48,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.52,
    "out:CO2 Operational/m2": 45.58,
    "out:Total CO2/m2": 58.1,
    "out:Total CO2 (tons)": 160.76,
    "out:Klimatpaverkan": -6.26,
    "out:Initial Cost/MSEK": 2.53,
    "out:Running cost/(Apt SEK y)": 23396,
    "out:Running Cost over RSP/MSEK": 8.771,
    "out:LCP/MSEK": 0.001,
    "out:ROI% old": 14.29,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77168,
    "out:DH kr savings": 121748,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.25,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 138.26,
    "out:Total Energy Use Post PV": 138.26,
    "out:Primary Energy": 134.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.52,
    "out:CO2 Operational/m2": 43.78,
    "out:Total CO2/m2": 56.3,
    "out:Total CO2 (tons)": 155.78,
    "out:Klimatpaverkan": -11.24,
    "out:Initial Cost/MSEK": 2.599,
    "out:Running cost/(Apt SEK y)": 22525,
    "out:Running Cost over RSP/MSEK": 8.444,
    "out:LCP/MSEK": 0.259,
    "out:ROI% old": 15.71,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 91003,
    "out:DH kr savings": 135583,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.7,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00010201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 148.26,
    "out:Total Energy Use Post PV": 141.64,
    "out:Primary Energy": 129.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.75,
    "out:CO2 Operational/m2": 34.96,
    "out:Total CO2/m2": 56.7,
    "out:Total CO2 (tons)": 156.9,
    "out:Klimatpaverkan": -10.12,
    "out:Initial Cost/MSEK": 2.772,
    "out:Running cost/(Apt SEK y)": 23302,
    "out:Running Cost over RSP/MSEK": 8.745,
    "out:LCP/MSEK": -0.215,
    "out:ROI% old": 13.18,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 328,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 109596,
    "out:DH kr savings": 107913,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 106.25,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 136.64,
    "out:Primary Energy": 125.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.75,
    "out:CO2 Operational/m2": 33.16,
    "out:Total CO2/m2": 54.9,
    "out:Total CO2 (tons)": 151.92,
    "out:Klimatpaverkan": -15.1,
    "out:Initial Cost/MSEK": 2.841,
    "out:Running cost/(Apt SEK y)": 22432,
    "out:Running Cost over RSP/MSEK": 8.418,
    "out:LCP/MSEK": 0.043,
    "out:ROI% old": 14.5,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123431,
    "out:DH kr savings": 121748,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.69,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.25,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 136.64,
    "out:Primary Energy": 126.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.4,
    "out:CO2 Operational/m2": 33.16,
    "out:Total CO2/m2": 55.56,
    "out:Total CO2 (tons)": 153.72,
    "out:Klimatpaverkan": -13.3,
    "out:Initial Cost/MSEK": 2.99,
    "out:Running cost/(Apt SEK y)": 22432,
    "out:Running Cost over RSP/MSEK": 8.418,
    "out:LCP/MSEK": -0.107,
    "out:ROI% old": 13.78,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123431,
    "out:DH kr savings": 121748,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 293987,
    "out:% savings (space heating)": 9.59,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.25,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00010201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.69,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 138.26,
    "out:Total Energy Use Post PV": 131.64,
    "out:Primary Energy": 122.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.4,
    "out:CO2 Operational/m2": 31.36,
    "out:Total CO2/m2": 53.76,
    "out:Total CO2 (tons)": 148.74,
    "out:Klimatpaverkan": -18.28,
    "out:Initial Cost/MSEK": 3.06,
    "out:Running cost/(Apt SEK y)": 21561,
    "out:Running Cost over RSP/MSEK": 8.092,
    "out:LCP/MSEK": 0.151,
    "out:ROI% old": 14.99,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.78,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 137266,
    "out:DH kr savings": 135583,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 281379,
    "out:% savings (space heating)": 13.47,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.7,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.76,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 167.2,
    "out:Total Energy Use Post PV": 167.2,
    "out:Primary Energy": 142.8,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.1,
    "out:CO2 Operational/m2": 56.04,
    "out:Total CO2/m2": 57.14,
    "out:Total CO2 (tons)": 158.12,
    "out:Klimatpaverkan": -8.9,
    "out:Initial Cost/MSEK": 0.299,
    "out:Running cost/(Apt SEK y)": 28038,
    "out:Running Cost over RSP/MSEK": 10.517,
    "out:LCP/MSEK": 0.485,
    "out:ROI% old": 37.42,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 33,
    "out:Return/kSEK/y": 100,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 33204,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 33204,
    "out:DH kr savings": 33204,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 292642,
    "out:% savings (space heating)": 10.01,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.76,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 163.2,
    "out:Total Energy Use Post PV": 163.2,
    "out:Primary Energy": 138.83,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.1,
    "out:CO2 Operational/m2": 54.6,
    "out:Total CO2/m2": 55.7,
    "out:Total CO2 (tons)": 154.13,
    "out:Klimatpaverkan": -12.89,
    "out:Initial Cost/MSEK": 0.369,
    "out:Running cost/(Apt SEK y)": 27341,
    "out:Running Cost over RSP/MSEK": 10.256,
    "out:LCP/MSEK": 0.677,
    "out:ROI% old": 40.53,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 9.8,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 36,
    "out:Return/kSEK/y": 134,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 44272,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 44272,
    "out:DH kr savings": 44272,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 280095,
    "out:% savings (space heating)": 13.87,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.76,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 161.2,
    "out:Total Energy Use Post PV": 161.2,
    "out:Primary Energy": 140.18,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.76,
    "out:CO2 Operational/m2": 53.88,
    "out:Total CO2/m2": 55.64,
    "out:Total CO2 (tons)": 153.95,
    "out:Klimatpaverkan": -13.07,
    "out:Initial Cost/MSEK": 0.518,
    "out:Running cost/(Apt SEK y)": 26993,
    "out:Running Cost over RSP/MSEK": 10.125,
    "out:LCP/MSEK": 0.658,
    "out:ROI% old": 32.45,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 11.2,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 29,
    "out:Return/kSEK/y": 150,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 49806,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 49806,
    "out:DH kr savings": 49806,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 292642,
    "out:% savings (space heating)": 10.01,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.76,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 156.2,
    "out:Primary Energy": 136.21,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.76,
    "out:CO2 Operational/m2": 52.08,
    "out:Total CO2/m2": 53.84,
    "out:Total CO2 (tons)": 148.97,
    "out:Klimatpaverkan": -18.05,
    "out:Initial Cost/MSEK": 0.587,
    "out:Running cost/(Apt SEK y)": 26123,
    "out:Running Cost over RSP/MSEK": 9.798,
    "out:LCP/MSEK": 0.916,
    "out:ROI% old": 36.57,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 33,
    "out:Return/kSEK/y": 192,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 63641,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 63641,
    "out:DH kr savings": 63641,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 280095,
    "out:% savings (space heating)": 13.87,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.76,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 167.2,
    "out:Total Energy Use Post PV": 161.92,
    "out:Primary Energy": 133.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.99,
    "out:CO2 Operational/m2": 36.98,
    "out:Total CO2/m2": 47.97,
    "out:Total CO2 (tons)": 132.72,
    "out:Klimatpaverkan": -34.3,
    "out:Initial Cost/MSEK": 0.76,
    "out:Running cost/(Apt SEK y)": 27112,
    "out:Running Cost over RSP/MSEK": 10.179,
    "out:LCP/MSEK": 0.363,
    "out:ROI% old": 21.1,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 10.5,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 145,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 33204,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77627,
    "out:DH kr savings": 33204,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 292642,
    "out:% savings (space heating)": 10.01,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.76,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 163.2,
    "out:Total Energy Use Post PV": 157.92,
    "out:Primary Energy": 129.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.99,
    "out:CO2 Operational/m2": 35.54,
    "out:Total CO2/m2": 46.53,
    "out:Total CO2 (tons)": 128.74,
    "out:Klimatpaverkan": -38.28,
    "out:Initial Cost/MSEK": 0.829,
    "out:Running cost/(Apt SEK y)": 26416,
    "out:Running Cost over RSP/MSEK": 9.918,
    "out:LCP/MSEK": 0.555,
    "out:ROI% old": 23.85,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 178,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 44272,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88695,
    "out:DH kr savings": 44272,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 280095,
    "out:% savings (space heating)": 13.87,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.76,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 161.2,
    "out:Total Energy Use Post PV": 155.92,
    "out:Primary Energy": 130.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.64,
    "out:CO2 Operational/m2": 34.82,
    "out:Total CO2/m2": 46.46,
    "out:Total CO2 (tons)": 128.55,
    "out:Klimatpaverkan": -38.47,
    "out:Initial Cost/MSEK": 0.978,
    "out:Running cost/(Apt SEK y)": 26068,
    "out:Running Cost over RSP/MSEK": 9.787,
    "out:LCP/MSEK": 0.536,
    "out:ROI% old": 22.11,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 195,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 49806,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 94229,
    "out:DH kr savings": 49806,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 292642,
    "out:% savings (space heating)": 10.01,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.76,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 150.92,
    "out:Primary Energy": 126.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.64,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 44.66,
    "out:Total CO2 (tons)": 123.57,
    "out:Klimatpaverkan": -43.45,
    "out:Initial Cost/MSEK": 1.048,
    "out:Running cost/(Apt SEK y)": 25197,
    "out:Running Cost over RSP/MSEK": 9.46,
    "out:LCP/MSEK": 0.794,
    "out:ROI% old": 25.11,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 23,
    "out:Return/kSEK/y": 237,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 63641,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 108064,
    "out:DH kr savings": 63641,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 280095,
    "out:% savings (space heating)": 13.87,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.76,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 158.2,
    "out:Total Energy Use Post PV": 158.2,
    "out:Primary Energy": 139.3,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.1,
    "out:CO2 Operational/m2": 52.8,
    "out:Total CO2/m2": 53.9,
    "out:Total CO2 (tons)": 149.15,
    "out:Klimatpaverkan": -17.87,
    "out:Initial Cost/MSEK": 0.426,
    "out:Running cost/(Apt SEK y)": 26471,
    "out:Running Cost over RSP/MSEK": 9.929,
    "out:LCP/MSEK": 0.946,
    "out:ROI% old": 46.02,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 41,
    "out:Return/kSEK/y": 175,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 58107,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 58107,
    "out:DH kr savings": 58107,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 292642,
    "out:% savings (space heating)": 10.01,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.76,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 154.2,
    "out:Primary Energy": 135.33,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.1,
    "out:CO2 Operational/m2": 51.36,
    "out:Total CO2/m2": 52.46,
    "out:Total CO2 (tons)": 145.17,
    "out:Klimatpaverkan": -21.85,
    "out:Initial Cost/MSEK": 0.495,
    "out:Running cost/(Apt SEK y)": 25775,
    "out:Running Cost over RSP/MSEK": 9.668,
    "out:LCP/MSEK": 1.139,
    "out:ROI% old": 47.13,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 42,
    "out:Return/kSEK/y": 209,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 69175,
    "out:DH kr savings": 69175,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 280095,
    "out:% savings (space heating)": 13.87,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.76,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 153.2,
    "out:Total Energy Use Post PV": 153.2,
    "out:Primary Energy": 137.2,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.76,
    "out:CO2 Operational/m2": 51,
    "out:Total CO2/m2": 52.76,
    "out:Total CO2 (tons)": 145.98,
    "out:Klimatpaverkan": -21.04,
    "out:Initial Cost/MSEK": 0.645,
    "out:Running cost/(Apt SEK y)": 25600,
    "out:Running Cost over RSP/MSEK": 9.602,
    "out:LCP/MSEK": 1.055,
    "out:ROI% old": 37.66,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 34,
    "out:Return/kSEK/y": 217,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 71942,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 71942,
    "out:DH kr savings": 71942,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 292642,
    "out:% savings (space heating)": 10.01,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.76,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 149.2,
    "out:Primary Energy": 133.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.76,
    "out:CO2 Operational/m2": 49.56,
    "out:Total CO2/m2": 51.32,
    "out:Total CO2 (tons)": 141.99,
    "out:Klimatpaverkan": -25.03,
    "out:Initial Cost/MSEK": 0.714,
    "out:Running cost/(Apt SEK y)": 24904,
    "out:Running Cost over RSP/MSEK": 9.341,
    "out:LCP/MSEK": 1.247,
    "out:ROI% old": 39.24,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 35,
    "out:Return/kSEK/y": 251,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83010,
    "out:DH kr savings": 83010,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 280095,
    "out:% savings (space heating)": 13.87,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.76,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 158.2,
    "out:Total Energy Use Post PV": 152.92,
    "out:Primary Energy": 129.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.99,
    "out:CO2 Operational/m2": 33.74,
    "out:Total CO2/m2": 44.73,
    "out:Total CO2 (tons)": 123.76,
    "out:Klimatpaverkan": -43.26,
    "out:Initial Cost/MSEK": 0.887,
    "out:Running cost/(Apt SEK y)": 25545,
    "out:Running Cost over RSP/MSEK": 9.591,
    "out:LCP/MSEK": 0.824,
    "out:ROI% old": 27.57,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 25,
    "out:Return/kSEK/y": 220,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 58107,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102530,
    "out:DH kr savings": 58107,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 292642,
    "out:% savings (space heating)": 10.01,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.76,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 148.92,
    "out:Primary Energy": 125.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.99,
    "out:CO2 Operational/m2": 32.3,
    "out:Total CO2/m2": 43.29,
    "out:Total CO2 (tons)": 119.77,
    "out:Klimatpaverkan": -47.25,
    "out:Initial Cost/MSEK": 0.956,
    "out:Running cost/(Apt SEK y)": 24849,
    "out:Running Cost over RSP/MSEK": 9.33,
    "out:LCP/MSEK": 1.016,
    "out:ROI% old": 29.48,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 27,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113598,
    "out:DH kr savings": 69175,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 280095,
    "out:% savings (space heating)": 13.87,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.76,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 153.2,
    "out:Total Energy Use Post PV": 147.92,
    "out:Primary Energy": 127.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.64,
    "out:CO2 Operational/m2": 31.94,
    "out:Total CO2/m2": 43.58,
    "out:Total CO2 (tons)": 120.58,
    "out:Klimatpaverkan": -46.44,
    "out:Initial Cost/MSEK": 1.105,
    "out:Running cost/(Apt SEK y)": 24675,
    "out:Running Cost over RSP/MSEK": 9.264,
    "out:LCP/MSEK": 0.932,
    "out:ROI% old": 26.34,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 24,
    "out:Return/kSEK/y": 262,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 71942,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116365,
    "out:DH kr savings": 71942,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 292642,
    "out:% savings (space heating)": 10.01,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.76,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 143.92,
    "out:Primary Energy": 123.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.64,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 42.14,
    "out:Total CO2 (tons)": 116.6,
    "out:Klimatpaverkan": -50.42,
    "out:Initial Cost/MSEK": 1.174,
    "out:Running cost/(Apt SEK y)": 23979,
    "out:Running Cost over RSP/MSEK": 9.003,
    "out:LCP/MSEK": 1.125,
    "out:ROI% old": 27.97,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 25,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 127433,
    "out:DH kr savings": 83010,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 280095,
    "out:% savings (space heating)": 13.87,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.76,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 156.26,
    "out:Total Energy Use Post PV": 156.26,
    "out:Primary Energy": 144,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.9,
    "out:CO2 Operational/m2": 50.26,
    "out:Total CO2/m2": 55.17,
    "out:Total CO2 (tons)": 152.64,
    "out:Klimatpaverkan": -14.38,
    "out:Initial Cost/MSEK": 0.944,
    "out:Running cost/(Apt SEK y)": 25659,
    "out:Running Cost over RSP/MSEK": 9.62,
    "out:LCP/MSEK": 0.738,
    "out:ROI% old": 25.46,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 214,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 41197,
    "out:DH kr savings": 85777,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 292642,
    "out:% savings (space heating)": 10.01,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.76,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 152.26,
    "out:Total Energy Use Post PV": 152.26,
    "out:Primary Energy": 140.04,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.9,
    "out:CO2 Operational/m2": 48.82,
    "out:Total CO2/m2": 53.73,
    "out:Total CO2 (tons)": 148.66,
    "out:Klimatpaverkan": -18.36,
    "out:Initial Cost/MSEK": 1.013,
    "out:Running cost/(Apt SEK y)": 24962,
    "out:Running Cost over RSP/MSEK": 9.359,
    "out:LCP/MSEK": 0.93,
    "out:ROI% old": 27.41,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 17.8,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 24,
    "out:Return/kSEK/y": 248,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 52265,
    "out:DH kr savings": 96845,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 280095,
    "out:% savings (space heating)": 13.87,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.76,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 150.26,
    "out:Total Energy Use Post PV": 150.26,
    "out:Primary Energy": 141.38,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.55,
    "out:CO2 Operational/m2": 48.1,
    "out:Total CO2/m2": 53.66,
    "out:Total CO2 (tons)": 148.47,
    "out:Klimatpaverkan": -18.55,
    "out:Initial Cost/MSEK": 1.162,
    "out:Running cost/(Apt SEK y)": 24614,
    "out:Running Cost over RSP/MSEK": 9.228,
    "out:LCP/MSEK": 0.911,
    "out:ROI% old": 25.49,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 265,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 57799,
    "out:DH kr savings": 102379,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 292642,
    "out:% savings (space heating)": 10.01,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.76,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 145.26,
    "out:Total Energy Use Post PV": 145.26,
    "out:Primary Energy": 137.41,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.55,
    "out:CO2 Operational/m2": 46.3,
    "out:Total CO2/m2": 51.86,
    "out:Total CO2 (tons)": 143.49,
    "out:Klimatpaverkan": -23.53,
    "out:Initial Cost/MSEK": 1.231,
    "out:Running cost/(Apt SEK y)": 23744,
    "out:Running Cost over RSP/MSEK": 8.901,
    "out:LCP/MSEK": 1.169,
    "out:ROI% old": 27.85,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 306,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71634,
    "out:DH kr savings": 116214,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 280095,
    "out:% savings (space heating)": 13.87,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.76,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 156.26,
    "out:Total Energy Use Post PV": 149.64,
    "out:Primary Energy": 132.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.78,
    "out:CO2 Operational/m2": 37.84,
    "out:Total CO2/m2": 52.62,
    "out:Total CO2 (tons)": 145.6,
    "out:Klimatpaverkan": -21.42,
    "out:Initial Cost/MSEK": 1.404,
    "out:Running cost/(Apt SEK y)": 24695,
    "out:Running Cost over RSP/MSEK": 9.268,
    "out:LCP/MSEK": 0.63,
    "out:ROI% old": 20.69,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 261,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 87460,
    "out:DH kr savings": 85777,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 292642,
    "out:% savings (space heating)": 10.01,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.76,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 152.26,
    "out:Total Energy Use Post PV": 145.64,
    "out:Primary Energy": 128.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.78,
    "out:CO2 Operational/m2": 36.4,
    "out:Total CO2/m2": 51.18,
    "out:Total CO2 (tons)": 141.62,
    "out:Klimatpaverkan": -25.4,
    "out:Initial Cost/MSEK": 1.473,
    "out:Running cost/(Apt SEK y)": 23999,
    "out:Running Cost over RSP/MSEK": 9.007,
    "out:LCP/MSEK": 0.822,
    "out:ROI% old": 22.26,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 294,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 98528,
    "out:DH kr savings": 96845,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 280095,
    "out:% savings (space heating)": 13.87,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.76,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 150.26,
    "out:Total Energy Use Post PV": 143.64,
    "out:Primary Energy": 129.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.44,
    "out:CO2 Operational/m2": 35.68,
    "out:Total CO2/m2": 51.11,
    "out:Total CO2 (tons)": 141.43,
    "out:Klimatpaverkan": -25.59,
    "out:Initial Cost/MSEK": 1.623,
    "out:Running cost/(Apt SEK y)": 23650,
    "out:Running Cost over RSP/MSEK": 8.876,
    "out:LCP/MSEK": 0.803,
    "out:ROI% old": 21.36,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 104062,
    "out:DH kr savings": 102379,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 292642,
    "out:% savings (space heating)": 10.01,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.76,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 145.26,
    "out:Total Energy Use Post PV": 138.64,
    "out:Primary Energy": 125.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.44,
    "out:CO2 Operational/m2": 33.88,
    "out:Total CO2/m2": 49.31,
    "out:Total CO2 (tons)": 136.45,
    "out:Klimatpaverkan": -30.57,
    "out:Initial Cost/MSEK": 1.692,
    "out:Running cost/(Apt SEK y)": 22780,
    "out:Running Cost over RSP/MSEK": 8.549,
    "out:LCP/MSEK": 1.061,
    "out:ROI% old": 23.24,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 353,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117897,
    "out:DH kr savings": 116214,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 280095,
    "out:% savings (space heating)": 13.87,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.76,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 147.26,
    "out:Total Energy Use Post PV": 147.26,
    "out:Primary Energy": 140.5,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.9,
    "out:CO2 Operational/m2": 47.02,
    "out:Total CO2/m2": 51.93,
    "out:Total CO2 (tons)": 143.68,
    "out:Klimatpaverkan": -23.34,
    "out:Initial Cost/MSEK": 1.07,
    "out:Running cost/(Apt SEK y)": 24092,
    "out:Running Cost over RSP/MSEK": 9.032,
    "out:LCP/MSEK": 1.199,
    "out:ROI% old": 30.29,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 27,
    "out:Return/kSEK/y": 290,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 66100,
    "out:DH kr savings": 110680,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 292642,
    "out:% savings (space heating)": 10.01,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.76,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 143.26,
    "out:Primary Energy": 136.54,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.9,
    "out:CO2 Operational/m2": 45.58,
    "out:Total CO2/m2": 50.49,
    "out:Total CO2 (tons)": 139.69,
    "out:Klimatpaverkan": -27.33,
    "out:Initial Cost/MSEK": 1.14,
    "out:Running cost/(Apt SEK y)": 23396,
    "out:Running Cost over RSP/MSEK": 8.771,
    "out:LCP/MSEK": 1.392,
    "out:ROI% old": 31.73,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 28,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77168,
    "out:DH kr savings": 121748,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 280095,
    "out:% savings (space heating)": 13.87,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.76,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 142.26,
    "out:Total Energy Use Post PV": 142.26,
    "out:Primary Energy": 138.06,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.55,
    "out:CO2 Operational/m2": 45.22,
    "out:Total CO2/m2": 50.78,
    "out:Total CO2 (tons)": 140.5,
    "out:Klimatpaverkan": -26.52,
    "out:Initial Cost/MSEK": 1.289,
    "out:Running cost/(Apt SEK y)": 23222,
    "out:Running Cost over RSP/MSEK": 8.705,
    "out:LCP/MSEK": 1.308,
    "out:ROI% old": 28.78,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 26,
    "out:Return/kSEK/y": 331,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 79935,
    "out:DH kr savings": 124515,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 292642,
    "out:% savings (space heating)": 10.01,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 103.77,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 138.26,
    "out:Total Energy Use Post PV": 138.26,
    "out:Primary Energy": 134.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.55,
    "out:CO2 Operational/m2": 43.78,
    "out:Total CO2/m2": 49.34,
    "out:Total CO2 (tons)": 136.52,
    "out:Klimatpaverkan": -30.5,
    "out:Initial Cost/MSEK": 1.358,
    "out:Running cost/(Apt SEK y)": 22525,
    "out:Running Cost over RSP/MSEK": 8.444,
    "out:LCP/MSEK": 1.5,
    "out:ROI% old": 30.06,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 27,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 91003,
    "out:DH kr savings": 135583,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 280095,
    "out:% savings (space heating)": 13.87,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.23,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.76,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 147.26,
    "out:Total Energy Use Post PV": 140.64,
    "out:Primary Energy": 128.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.78,
    "out:CO2 Operational/m2": 34.6,
    "out:Total CO2/m2": 49.38,
    "out:Total CO2 (tons)": 136.64,
    "out:Klimatpaverkan": -30.38,
    "out:Initial Cost/MSEK": 1.531,
    "out:Running cost/(Apt SEK y)": 23128,
    "out:Running Cost over RSP/MSEK": 8.68,
    "out:LCP/MSEK": 1.091,
    "out:ROI% old": 24.47,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 336,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 112363,
    "out:DH kr savings": 110680,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 292642,
    "out:% savings (space heating)": 10.01,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 105.76,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 136.64,
    "out:Primary Energy": 124.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.78,
    "out:CO2 Operational/m2": 33.16,
    "out:Total CO2/m2": 47.94,
    "out:Total CO2 (tons)": 132.65,
    "out:Klimatpaverkan": -34.37,
    "out:Initial Cost/MSEK": 1.6,
    "out:Running cost/(Apt SEK y)": 22432,
    "out:Running Cost over RSP/MSEK": 8.418,
    "out:LCP/MSEK": 1.283,
    "out:ROI% old": 25.75,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123431,
    "out:DH kr savings": 121748,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 280095,
    "out:% savings (space heating)": 13.87,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 101.23,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.76,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 142.26,
    "out:Total Energy Use Post PV": 135.64,
    "out:Primary Energy": 126.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.44,
    "out:CO2 Operational/m2": 32.8,
    "out:Total CO2/m2": 48.23,
    "out:Total CO2 (tons)": 133.46,
    "out:Klimatpaverkan": -33.56,
    "out:Initial Cost/MSEK": 1.749,
    "out:Running cost/(Apt SEK y)": 22258,
    "out:Running Cost over RSP/MSEK": 8.353,
    "out:LCP/MSEK": 1.199,
    "out:ROI% old": 24.08,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 378,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126198,
    "out:DH kr savings": 124515,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 292642,
    "out:% savings (space heating)": 10.01,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 103.77,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.23,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 138.26,
    "out:Total Energy Use Post PV": 131.64,
    "out:Primary Energy": 122.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.44,
    "out:CO2 Operational/m2": 31.36,
    "out:Total CO2/m2": 46.79,
    "out:Total CO2 (tons)": 129.48,
    "out:Klimatpaverkan": -37.54,
    "out:Initial Cost/MSEK": 1.819,
    "out:Running cost/(Apt SEK y)": 21561,
    "out:Running Cost over RSP/MSEK": 8.092,
    "out:LCP/MSEK": 1.392,
    "out:ROI% old": 25.22,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.82,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 137266,
    "out:DH kr savings": 135583,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 280095,
    "out:% savings (space heating)": 13.87,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.23,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.04,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 158.2,
    "out:Total Energy Use Post PV": 158.2,
    "out:Primary Energy": 135.17,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.42,
    "out:CO2 Operational/m2": 52.8,
    "out:Total CO2/m2": 56.22,
    "out:Total CO2 (tons)": 155.56,
    "out:Klimatpaverkan": -11.46,
    "out:Initial Cost/MSEK": 0.746,
    "out:Running cost/(Apt SEK y)": 26471,
    "out:Running Cost over RSP/MSEK": 9.929,
    "out:LCP/MSEK": 0.626,
    "out:ROI% old": 26.28,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 24,
    "out:Return/kSEK/y": 175,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 58107,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 58107,
    "out:DH kr savings": 58107,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 268522,
    "out:% savings (space heating)": 17.42,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.04,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.91,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 154.2,
    "out:Primary Energy": 131.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.42,
    "out:CO2 Operational/m2": 51.36,
    "out:Total CO2/m2": 54.78,
    "out:Total CO2 (tons)": 151.57,
    "out:Klimatpaverkan": -15.45,
    "out:Initial Cost/MSEK": 0.815,
    "out:Running cost/(Apt SEK y)": 25775,
    "out:Running Cost over RSP/MSEK": 9.668,
    "out:LCP/MSEK": 0.819,
    "out:ROI% old": 28.63,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 26,
    "out:Return/kSEK/y": 209,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 69175,
    "out:DH kr savings": 69175,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257071,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.91,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.04,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 152.2,
    "out:Primary Energy": 132.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.07,
    "out:CO2 Operational/m2": 50.64,
    "out:Total CO2/m2": 54.71,
    "out:Total CO2 (tons)": 151.39,
    "out:Klimatpaverkan": -15.63,
    "out:Initial Cost/MSEK": 0.965,
    "out:Running cost/(Apt SEK y)": 25426,
    "out:Running Cost over RSP/MSEK": 9.537,
    "out:LCP/MSEK": 0.8,
    "out:ROI% old": 26.13,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 17.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 23,
    "out:Return/kSEK/y": 226,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 74709,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74709,
    "out:DH kr savings": 74709,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 268522,
    "out:% savings (space heating)": 17.42,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.04,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.91,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 148.2,
    "out:Primary Energy": 128.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.07,
    "out:CO2 Operational/m2": 49.2,
    "out:Total CO2/m2": 53.27,
    "out:Total CO2 (tons)": 147.4,
    "out:Klimatpaverkan": -19.62,
    "out:Initial Cost/MSEK": 1.034,
    "out:Running cost/(Apt SEK y)": 24730,
    "out:Running Cost over RSP/MSEK": 9.276,
    "out:LCP/MSEK": 0.992,
    "out:ROI% old": 28,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 25,
    "out:Return/kSEK/y": 259,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85777,
    "out:DH kr savings": 85777,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257071,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.91,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.04,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 158.2,
    "out:Total Energy Use Post PV": 152.92,
    "out:Primary Energy": 125.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.3,
    "out:CO2 Operational/m2": 33.74,
    "out:Total CO2/m2": 47.04,
    "out:Total CO2 (tons)": 130.16,
    "out:Klimatpaverkan": -36.86,
    "out:Initial Cost/MSEK": 1.207,
    "out:Running cost/(Apt SEK y)": 25545,
    "out:Running Cost over RSP/MSEK": 9.591,
    "out:LCP/MSEK": 0.504,
    "out:ROI% old": 20.26,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 220,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 58107,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102530,
    "out:DH kr savings": 58107,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 268522,
    "out:% savings (space heating)": 17.42,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.04,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.91,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 148.92,
    "out:Primary Energy": 122.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.3,
    "out:CO2 Operational/m2": 32.3,
    "out:Total CO2/m2": 45.6,
    "out:Total CO2 (tons)": 126.18,
    "out:Klimatpaverkan": -40.84,
    "out:Initial Cost/MSEK": 1.276,
    "out:Running cost/(Apt SEK y)": 24849,
    "out:Running Cost over RSP/MSEK": 9.33,
    "out:LCP/MSEK": 0.696,
    "out:ROI% old": 22.08,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113598,
    "out:DH kr savings": 69175,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257071,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.91,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.04,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 146.92,
    "out:Primary Energy": 123.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.95,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 45.53,
    "out:Total CO2 (tons)": 125.99,
    "out:Klimatpaverkan": -41.03,
    "out:Initial Cost/MSEK": 1.425,
    "out:Running cost/(Apt SEK y)": 24501,
    "out:Running Cost over RSP/MSEK": 9.199,
    "out:LCP/MSEK": 0.678,
    "out:ROI% old": 21.08,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 270,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 74709,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119132,
    "out:DH kr savings": 74709,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 268522,
    "out:% savings (space heating)": 17.42,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.04,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.91,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 142.92,
    "out:Primary Energy": 119.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.95,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 44.09,
    "out:Total CO2 (tons)": 122.01,
    "out:Klimatpaverkan": -45.01,
    "out:Initial Cost/MSEK": 1.494,
    "out:Running cost/(Apt SEK y)": 23805,
    "out:Running Cost over RSP/MSEK": 8.937,
    "out:LCP/MSEK": 0.87,
    "out:ROI% old": 22.6,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130200,
    "out:DH kr savings": 85777,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257071,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.91,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.04,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 150.2,
    "out:Primary Energy": 131.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.42,
    "out:CO2 Operational/m2": 49.92,
    "out:Total CO2/m2": 53.34,
    "out:Total CO2 (tons)": 147.59,
    "out:Klimatpaverkan": -19.43,
    "out:Initial Cost/MSEK": 0.873,
    "out:Running cost/(Apt SEK y)": 25078,
    "out:Running Cost over RSP/MSEK": 9.406,
    "out:LCP/MSEK": 1.023,
    "out:ROI% old": 31.03,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 28,
    "out:Return/kSEK/y": 242,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 80243,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 80243,
    "out:DH kr savings": 80243,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 268522,
    "out:% savings (space heating)": 17.42,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.04,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.91,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 146.2,
    "out:Total Energy Use Post PV": 146.2,
    "out:Primary Energy": 128.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.42,
    "out:CO2 Operational/m2": 48.48,
    "out:Total CO2/m2": 51.9,
    "out:Total CO2 (tons)": 143.6,
    "out:Klimatpaverkan": -23.42,
    "out:Initial Cost/MSEK": 0.942,
    "out:Running cost/(Apt SEK y)": 24382,
    "out:Running Cost over RSP/MSEK": 9.145,
    "out:LCP/MSEK": 1.215,
    "out:ROI% old": 32.71,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 29,
    "out:Return/kSEK/y": 276,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 91311,
    "out:DH kr savings": 91311,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257071,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.91,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.04,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 144.2,
    "out:Total Energy Use Post PV": 144.2,
    "out:Primary Energy": 129.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.07,
    "out:CO2 Operational/m2": 47.76,
    "out:Total CO2/m2": 51.83,
    "out:Total CO2 (tons)": 143.42,
    "out:Klimatpaverkan": -23.6,
    "out:Initial Cost/MSEK": 1.091,
    "out:Running cost/(Apt SEK y)": 24034,
    "out:Running Cost over RSP/MSEK": 9.014,
    "out:LCP/MSEK": 1.196,
    "out:ROI% old": 29.94,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 27,
    "out:Return/kSEK/y": 292,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96845,
    "out:DH kr savings": 96845,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 268522,
    "out:% savings (space heating)": 17.42,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.04,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.91,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 140.2,
    "out:Primary Energy": 125.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.07,
    "out:CO2 Operational/m2": 46.32,
    "out:Total CO2/m2": 50.39,
    "out:Total CO2 (tons)": 139.43,
    "out:Klimatpaverkan": -27.59,
    "out:Initial Cost/MSEK": 1.161,
    "out:Running cost/(Apt SEK y)": 23337,
    "out:Running Cost over RSP/MSEK": 8.753,
    "out:LCP/MSEK": 1.389,
    "out:ROI% old": 31.38,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 28,
    "out:Return/kSEK/y": 326,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107913,
    "out:DH kr savings": 107913,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257071,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.91,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.04,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 144.92,
    "out:Primary Energy": 122.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.3,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 44.16,
    "out:Total CO2 (tons)": 122.19,
    "out:Klimatpaverkan": -44.83,
    "out:Initial Cost/MSEK": 1.333,
    "out:Running cost/(Apt SEK y)": 24153,
    "out:Running Cost over RSP/MSEK": 9.068,
    "out:LCP/MSEK": 0.9,
    "out:ROI% old": 23.93,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 80243,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124666,
    "out:DH kr savings": 80243,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 268522,
    "out:% savings (space heating)": 17.42,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.04,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.91,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 146.2,
    "out:Total Energy Use Post PV": 140.92,
    "out:Primary Energy": 118.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.3,
    "out:CO2 Operational/m2": 29.42,
    "out:Total CO2/m2": 42.72,
    "out:Total CO2 (tons)": 118.21,
    "out:Klimatpaverkan": -48.81,
    "out:Initial Cost/MSEK": 1.402,
    "out:Running cost/(Apt SEK y)": 23456,
    "out:Running Cost over RSP/MSEK": 8.807,
    "out:LCP/MSEK": 1.093,
    "out:ROI% old": 25.42,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 23,
    "out:Return/kSEK/y": 320,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135734,
    "out:DH kr savings": 91311,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257071,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.91,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.04,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 144.2,
    "out:Total Energy Use Post PV": 138.92,
    "out:Primary Energy": 120.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.95,
    "out:CO2 Operational/m2": 28.7,
    "out:Total CO2/m2": 42.65,
    "out:Total CO2 (tons)": 118.02,
    "out:Klimatpaverkan": -49,
    "out:Initial Cost/MSEK": 1.552,
    "out:Running cost/(Apt SEK y)": 23108,
    "out:Running Cost over RSP/MSEK": 8.676,
    "out:LCP/MSEK": 1.074,
    "out:ROI% old": 24.17,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141268,
    "out:DH kr savings": 96845,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 268522,
    "out:% savings (space heating)": 17.42,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.04,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.91,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 134.92,
    "out:Primary Energy": 116.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.95,
    "out:CO2 Operational/m2": 27.26,
    "out:Total CO2/m2": 41.21,
    "out:Total CO2 (tons)": 114.04,
    "out:Klimatpaverkan": -52.98,
    "out:Initial Cost/MSEK": 1.621,
    "out:Running cost/(Apt SEK y)": 22412,
    "out:Running Cost over RSP/MSEK": 8.415,
    "out:LCP/MSEK": 1.266,
    "out:ROI% old": 25.45,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 23,
    "out:Return/kSEK/y": 370,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152336,
    "out:DH kr savings": 107913,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257071,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.91,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.04,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 147.26,
    "out:Total Energy Use Post PV": 147.26,
    "out:Primary Energy": 136.38,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.22,
    "out:CO2 Operational/m2": 47.02,
    "out:Total CO2/m2": 54.24,
    "out:Total CO2 (tons)": 150.08,
    "out:Klimatpaverkan": -16.94,
    "out:Initial Cost/MSEK": 1.39,
    "out:Running cost/(Apt SEK y)": 24092,
    "out:Running Cost over RSP/MSEK": 9.032,
    "out:LCP/MSEK": 0.879,
    "out:ROI% old": 23.32,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 290,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 66100,
    "out:DH kr savings": 110680,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 268522,
    "out:% savings (space heating)": 17.42,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.04,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.91,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 143.26,
    "out:Primary Energy": 132.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.22,
    "out:CO2 Operational/m2": 45.58,
    "out:Total CO2/m2": 52.8,
    "out:Total CO2 (tons)": 146.1,
    "out:Klimatpaverkan": -20.92,
    "out:Initial Cost/MSEK": 1.46,
    "out:Running cost/(Apt SEK y)": 23396,
    "out:Running Cost over RSP/MSEK": 8.771,
    "out:LCP/MSEK": 1.072,
    "out:ROI% old": 24.78,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77168,
    "out:DH kr savings": 121748,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257071,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.91,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.04,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 141.26,
    "out:Total Energy Use Post PV": 141.26,
    "out:Primary Energy": 133.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.87,
    "out:CO2 Operational/m2": 44.86,
    "out:Total CO2/m2": 52.73,
    "out:Total CO2 (tons)": 145.91,
    "out:Klimatpaverkan": -21.11,
    "out:Initial Cost/MSEK": 1.609,
    "out:Running cost/(Apt SEK y)": 23047,
    "out:Running Cost over RSP/MSEK": 8.64,
    "out:LCP/MSEK": 1.053,
    "out:ROI% old": 23.64,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82702,
    "out:DH kr savings": 127282,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 268522,
    "out:% savings (space heating)": 17.42,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.04,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.91,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 137.26,
    "out:Primary Energy": 130.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.87,
    "out:CO2 Operational/m2": 43.42,
    "out:Total CO2/m2": 51.29,
    "out:Total CO2 (tons)": 141.93,
    "out:Klimatpaverkan": -25.09,
    "out:Initial Cost/MSEK": 1.678,
    "out:Running cost/(Apt SEK y)": 22351,
    "out:Running Cost over RSP/MSEK": 8.378,
    "out:LCP/MSEK": 1.245,
    "out:ROI% old": 24.89,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93770,
    "out:DH kr savings": 138350,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257071,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.91,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.04,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 147.26,
    "out:Total Energy Use Post PV": 140.64,
    "out:Primary Energy": 124.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.1,
    "out:CO2 Operational/m2": 34.6,
    "out:Total CO2/m2": 51.7,
    "out:Total CO2 (tons)": 143.04,
    "out:Klimatpaverkan": -23.98,
    "out:Initial Cost/MSEK": 1.851,
    "out:Running cost/(Apt SEK y)": 23128,
    "out:Running Cost over RSP/MSEK": 8.68,
    "out:LCP/MSEK": 0.771,
    "out:ROI% old": 20.24,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 336,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 112363,
    "out:DH kr savings": 110680,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 268522,
    "out:% savings (space heating)": 17.42,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.04,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.91,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 136.64,
    "out:Primary Energy": 120.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.1,
    "out:CO2 Operational/m2": 33.16,
    "out:Total CO2/m2": 50.26,
    "out:Total CO2 (tons)": 139.06,
    "out:Klimatpaverkan": -27.96,
    "out:Initial Cost/MSEK": 1.92,
    "out:Running cost/(Apt SEK y)": 22432,
    "out:Running Cost over RSP/MSEK": 8.418,
    "out:LCP/MSEK": 0.963,
    "out:ROI% old": 21.45,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123431,
    "out:DH kr savings": 121748,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257071,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.91,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.04,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 141.26,
    "out:Total Energy Use Post PV": 134.64,
    "out:Primary Energy": 121.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.75,
    "out:CO2 Operational/m2": 32.44,
    "out:Total CO2/m2": 50.19,
    "out:Total CO2 (tons)": 138.87,
    "out:Klimatpaverkan": -28.15,
    "out:Initial Cost/MSEK": 2.069,
    "out:Running cost/(Apt SEK y)": 22084,
    "out:Running Cost over RSP/MSEK": 8.288,
    "out:LCP/MSEK": 0.945,
    "out:ROI% old": 20.81,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128965,
    "out:DH kr savings": 127282,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 268522,
    "out:% savings (space heating)": 17.42,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.04,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.91,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 130.64,
    "out:Primary Energy": 118.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.75,
    "out:CO2 Operational/m2": 31,
    "out:Total CO2/m2": 48.75,
    "out:Total CO2 (tons)": 134.88,
    "out:Klimatpaverkan": -32.14,
    "out:Initial Cost/MSEK": 2.139,
    "out:Running cost/(Apt SEK y)": 21387,
    "out:Running Cost over RSP/MSEK": 8.026,
    "out:LCP/MSEK": 1.137,
    "out:ROI% old": 21.88,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140033,
    "out:DH kr savings": 138350,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257071,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.91,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.04,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 139.26,
    "out:Total Energy Use Post PV": 139.26,
    "out:Primary Energy": 132.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.22,
    "out:CO2 Operational/m2": 44.14,
    "out:Total CO2/m2": 51.36,
    "out:Total CO2 (tons)": 142.11,
    "out:Klimatpaverkan": -24.91,
    "out:Initial Cost/MSEK": 1.517,
    "out:Running cost/(Apt SEK y)": 22699,
    "out:Running Cost over RSP/MSEK": 8.509,
    "out:LCP/MSEK": 1.276,
    "out:ROI% old": 26.3,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 24,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88236,
    "out:DH kr savings": 132816,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 268522,
    "out:% savings (space heating)": 17.42,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.04,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.91,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 135.26,
    "out:Total Energy Use Post PV": 135.26,
    "out:Primary Energy": 129.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.22,
    "out:CO2 Operational/m2": 42.7,
    "out:Total CO2/m2": 49.92,
    "out:Total CO2 (tons)": 138.13,
    "out:Klimatpaverkan": -28.89,
    "out:Initial Cost/MSEK": 1.586,
    "out:Running cost/(Apt SEK y)": 22003,
    "out:Running Cost over RSP/MSEK": 8.248,
    "out:LCP/MSEK": 1.468,
    "out:ROI% old": 27.51,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 390,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 143884,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99304,
    "out:DH kr savings": 143884,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257071,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.91,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.04,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 133.26,
    "out:Total Energy Use Post PV": 133.26,
    "out:Primary Energy": 130.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.87,
    "out:CO2 Operational/m2": 41.98,
    "out:Total CO2/m2": 49.85,
    "out:Total CO2 (tons)": 137.94,
    "out:Klimatpaverkan": -29.08,
    "out:Initial Cost/MSEK": 1.736,
    "out:Running cost/(Apt SEK y)": 21655,
    "out:Running Cost over RSP/MSEK": 8.117,
    "out:LCP/MSEK": 1.449,
    "out:ROI% old": 26.21,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 407,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 104838,
    "out:DH kr savings": 149418,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 268522,
    "out:% savings (space heating)": 17.42,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 95.05,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.91,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 129.26,
    "out:Total Energy Use Post PV": 129.26,
    "out:Primary Energy": 126.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.87,
    "out:CO2 Operational/m2": 40.54,
    "out:Total CO2/m2": 48.41,
    "out:Total CO2 (tons)": 133.96,
    "out:Klimatpaverkan": -33.06,
    "out:Initial Cost/MSEK": 1.805,
    "out:Running cost/(Apt SEK y)": 20958,
    "out:Running Cost over RSP/MSEK": 7.855,
    "out:LCP/MSEK": 1.642,
    "out:ROI% old": 27.28,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 24,
    "out:Return/kSEK/y": 440,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115906,
    "out:DH kr savings": 160486,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257071,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.91,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.04,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 139.26,
    "out:Total Energy Use Post PV": 132.64,
    "out:Primary Energy": 120.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.1,
    "out:CO2 Operational/m2": 31.72,
    "out:Total CO2/m2": 48.82,
    "out:Total CO2 (tons)": 135.07,
    "out:Klimatpaverkan": -31.95,
    "out:Initial Cost/MSEK": 1.978,
    "out:Running cost/(Apt SEK y)": 21735,
    "out:Running Cost over RSP/MSEK": 8.157,
    "out:LCP/MSEK": 1.167,
    "out:ROI% old": 22.72,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 403,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134499,
    "out:DH kr savings": 132816,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 268522,
    "out:% savings (space heating)": 17.42,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.04,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.91,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 135.26,
    "out:Total Energy Use Post PV": 128.64,
    "out:Primary Energy": 117.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.1,
    "out:CO2 Operational/m2": 30.28,
    "out:Total CO2/m2": 47.38,
    "out:Total CO2 (tons)": 131.09,
    "out:Klimatpaverkan": -35.93,
    "out:Initial Cost/MSEK": 2.047,
    "out:Running cost/(Apt SEK y)": 21039,
    "out:Running Cost over RSP/MSEK": 7.895,
    "out:LCP/MSEK": 1.36,
    "out:ROI% old": 23.78,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 436,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 143884,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145567,
    "out:DH kr savings": 143884,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257071,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.91,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.04,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 133.26,
    "out:Total Energy Use Post PV": 126.64,
    "out:Primary Energy": 118.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.75,
    "out:CO2 Operational/m2": 29.56,
    "out:Total CO2/m2": 47.31,
    "out:Total CO2 (tons)": 130.9,
    "out:Klimatpaverkan": -36.12,
    "out:Initial Cost/MSEK": 2.196,
    "out:Running cost/(Apt SEK y)": 20691,
    "out:Running Cost over RSP/MSEK": 7.765,
    "out:LCP/MSEK": 1.341,
    "out:ROI% old": 23.01,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 453,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151101,
    "out:DH kr savings": 149418,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 268522,
    "out:% savings (space heating)": 17.42,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 95.05,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.91,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 129.26,
    "out:Total Energy Use Post PV": 122.64,
    "out:Primary Energy": 114.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.75,
    "out:CO2 Operational/m2": 28.12,
    "out:Total CO2/m2": 45.87,
    "out:Total CO2 (tons)": 126.92,
    "out:Klimatpaverkan": -40.1,
    "out:Initial Cost/MSEK": 2.265,
    "out:Running cost/(Apt SEK y)": 19995,
    "out:Running Cost over RSP/MSEK": 7.503,
    "out:LCP/MSEK": 1.533,
    "out:ROI% old": 23.96,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.68,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162169,
    "out:DH kr savings": 160486,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 257071,
    "out:% savings (space heating)": 20.95,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.91,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.17,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 155.2,
    "out:Primary Energy": 132.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.17,
    "out:CO2 Operational/m2": 51.72,
    "out:Total CO2/m2": 60.89,
    "out:Total CO2 (tons)": 168.49,
    "out:Klimatpaverkan": 1.47,
    "out:Initial Cost/MSEK": 1.84,
    "out:Running cost/(Apt SEK y)": 25949,
    "out:Running Cost over RSP/MSEK": 9.733,
    "out:LCP/MSEK": -0.271,
    "out:ROI% old": 12.18,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 201,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 66408,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 66408,
    "out:DH kr savings": 66408,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 260569,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.17,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.16,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 151.2,
    "out:Total Energy Use Post PV": 151.2,
    "out:Primary Energy": 129.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.17,
    "out:CO2 Operational/m2": 50.28,
    "out:Total CO2/m2": 59.45,
    "out:Total CO2 (tons)": 164.5,
    "out:Klimatpaverkan": -2.52,
    "out:Initial Cost/MSEK": 1.909,
    "out:Running cost/(Apt SEK y)": 25252,
    "out:Running Cost over RSP/MSEK": 9.472,
    "out:LCP/MSEK": -0.079,
    "out:ROI% old": 13.7,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 234,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77476,
    "out:DH kr savings": 77476,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249479,
    "out:% savings (space heating)": 23.28,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.16,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.17,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 149.2,
    "out:Primary Energy": 130.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.82,
    "out:CO2 Operational/m2": 49.56,
    "out:Total CO2/m2": 59.38,
    "out:Total CO2 (tons)": 164.31,
    "out:Klimatpaverkan": -2.71,
    "out:Initial Cost/MSEK": 2.058,
    "out:Running cost/(Apt SEK y)": 24904,
    "out:Running Cost over RSP/MSEK": 9.341,
    "out:LCP/MSEK": -0.098,
    "out:ROI% old": 13.61,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 251,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83010,
    "out:DH kr savings": 83010,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 260569,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.17,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.16,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 145.2,
    "out:Primary Energy": 126.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.82,
    "out:CO2 Operational/m2": 48.12,
    "out:Total CO2/m2": 57.94,
    "out:Total CO2 (tons)": 160.33,
    "out:Klimatpaverkan": -6.69,
    "out:Initial Cost/MSEK": 2.128,
    "out:Running cost/(Apt SEK y)": 24208,
    "out:Running Cost over RSP/MSEK": 9.079,
    "out:LCP/MSEK": 0.095,
    "out:ROI% old": 14.92,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 94078,
    "out:DH kr savings": 94078,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249479,
    "out:% savings (space heating)": 23.28,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.16,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.17,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 149.92,
    "out:Primary Energy": 123.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.05,
    "out:CO2 Operational/m2": 32.66,
    "out:Total CO2/m2": 51.71,
    "out:Total CO2 (tons)": 143.09,
    "out:Klimatpaverkan": -23.93,
    "out:Initial Cost/MSEK": 2.3,
    "out:Running cost/(Apt SEK y)": 25023,
    "out:Running Cost over RSP/MSEK": 9.395,
    "out:LCP/MSEK": -0.393,
    "out:ROI% old": 11.84,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 245,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 66408,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110831,
    "out:DH kr savings": 66408,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 260569,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.17,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.16,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 151.2,
    "out:Total Energy Use Post PV": 145.92,
    "out:Primary Energy": 119.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.05,
    "out:CO2 Operational/m2": 31.22,
    "out:Total CO2/m2": 50.27,
    "out:Total CO2 (tons)": 139.11,
    "out:Klimatpaverkan": -27.91,
    "out:Initial Cost/MSEK": 2.369,
    "out:Running cost/(Apt SEK y)": 24327,
    "out:Running Cost over RSP/MSEK": 9.133,
    "out:LCP/MSEK": -0.201,
    "out:ROI% old": 13.07,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 278,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121899,
    "out:DH kr savings": 77476,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249479,
    "out:% savings (space heating)": 23.28,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.16,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.17,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 143.92,
    "out:Primary Energy": 120.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.71,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 50.21,
    "out:Total CO2 (tons)": 138.92,
    "out:Klimatpaverkan": -28.1,
    "out:Initial Cost/MSEK": 2.519,
    "out:Running cost/(Apt SEK y)": 23979,
    "out:Running Cost over RSP/MSEK": 9.003,
    "out:LCP/MSEK": -0.22,
    "out:ROI% old": 13.04,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127433,
    "out:DH kr savings": 83010,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 260569,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.17,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.16,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 139.92,
    "out:Primary Energy": 117.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.71,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 48.77,
    "out:Total CO2 (tons)": 134.94,
    "out:Klimatpaverkan": -32.08,
    "out:Initial Cost/MSEK": 2.588,
    "out:Running cost/(Apt SEK y)": 23282,
    "out:Running Cost over RSP/MSEK": 8.741,
    "out:LCP/MSEK": -0.028,
    "out:ROI% old": 14.13,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 329,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138501,
    "out:DH kr savings": 94078,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249479,
    "out:% savings (space heating)": 23.28,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.16,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.17,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 147.2,
    "out:Primary Energy": 129.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.17,
    "out:CO2 Operational/m2": 48.84,
    "out:Total CO2/m2": 58.01,
    "out:Total CO2 (tons)": 160.52,
    "out:Klimatpaverkan": -6.5,
    "out:Initial Cost/MSEK": 1.967,
    "out:Running cost/(Apt SEK y)": 24556,
    "out:Running Cost over RSP/MSEK": 9.21,
    "out:LCP/MSEK": 0.125,
    "out:ROI% old": 15.19,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 14,
    "out:Return/kSEK/y": 267,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88544,
    "out:DH kr savings": 88544,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 260569,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.17,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.16,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 143.2,
    "out:Primary Energy": 125.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.17,
    "out:CO2 Operational/m2": 47.4,
    "out:Total CO2/m2": 56.57,
    "out:Total CO2 (tons)": 156.53,
    "out:Klimatpaverkan": -10.49,
    "out:Initial Cost/MSEK": 2.036,
    "out:Running cost/(Apt SEK y)": 23860,
    "out:Running Cost over RSP/MSEK": 8.949,
    "out:LCP/MSEK": 0.317,
    "out:ROI% old": 16.51,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 15,
    "out:Return/kSEK/y": 301,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99612,
    "out:DH kr savings": 99612,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249479,
    "out:% savings (space heating)": 23.28,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.16,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.17,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 142.2,
    "out:Primary Energy": 127.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.82,
    "out:CO2 Operational/m2": 47.04,
    "out:Total CO2/m2": 56.86,
    "out:Total CO2 (tons)": 157.34,
    "out:Klimatpaverkan": -9.68,
    "out:Initial Cost/MSEK": 2.185,
    "out:Running cost/(Apt SEK y)": 23686,
    "out:Running Cost over RSP/MSEK": 8.883,
    "out:LCP/MSEK": 0.233,
    "out:ROI% old": 15.81,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 14,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102379,
    "out:DH kr savings": 102379,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 260569,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.17,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.16,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 138.2,
    "out:Primary Energy": 123.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.82,
    "out:CO2 Operational/m2": 45.6,
    "out:Total CO2/m2": 55.42,
    "out:Total CO2 (tons)": 153.36,
    "out:Klimatpaverkan": -13.66,
    "out:Initial Cost/MSEK": 2.254,
    "out:Running cost/(Apt SEK y)": 22989,
    "out:Running Cost over RSP/MSEK": 8.622,
    "out:LCP/MSEK": 0.426,
    "out:ROI% old": 16.98,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 15,
    "out:Return/kSEK/y": 343,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113447,
    "out:DH kr savings": 113447,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249479,
    "out:% savings (space heating)": 23.28,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.16,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00011200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.17,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 141.92,
    "out:Primary Energy": 119.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.05,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 48.83,
    "out:Total CO2 (tons)": 135.12,
    "out:Klimatpaverkan": -31.9,
    "out:Initial Cost/MSEK": 2.427,
    "out:Running cost/(Apt SEK y)": 23630,
    "out:Running Cost over RSP/MSEK": 8.872,
    "out:LCP/MSEK": 0.003,
    "out:ROI% old": 14.3,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 312,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132967,
    "out:DH kr savings": 88544,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 260569,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.17,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.16,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 137.92,
    "out:Primary Energy": 116.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.05,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 47.39,
    "out:Total CO2 (tons)": 131.14,
    "out:Klimatpaverkan": -35.88,
    "out:Initial Cost/MSEK": 2.496,
    "out:Running cost/(Apt SEK y)": 22934,
    "out:Running Cost over RSP/MSEK": 8.611,
    "out:LCP/MSEK": 0.195,
    "out:ROI% old": 15.4,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 345,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 144035,
    "out:DH kr savings": 99612,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249479,
    "out:% savings (space heating)": 23.28,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.16,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.17,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 136.92,
    "out:Primary Energy": 117.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.71,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 47.69,
    "out:Total CO2 (tons)": 131.95,
    "out:Klimatpaverkan": -35.07,
    "out:Initial Cost/MSEK": 2.646,
    "out:Running cost/(Apt SEK y)": 22760,
    "out:Running Cost over RSP/MSEK": 8.545,
    "out:LCP/MSEK": 0.111,
    "out:ROI% old": 14.89,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 354,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146802,
    "out:DH kr savings": 102379,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 260569,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.17,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.16,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 132.92,
    "out:Primary Energy": 114.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.71,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 46.25,
    "out:Total CO2 (tons)": 127.96,
    "out:Klimatpaverkan": -39.06,
    "out:Initial Cost/MSEK": 2.715,
    "out:Running cost/(Apt SEK y)": 22064,
    "out:Running Cost over RSP/MSEK": 8.284,
    "out:LCP/MSEK": 0.303,
    "out:ROI% old": 15.88,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 387,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157870,
    "out:DH kr savings": 113447,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249479,
    "out:% savings (space heating)": 23.28,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.16,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00011201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.17,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 144.26,
    "out:Total Energy Use Post PV": 144.26,
    "out:Primary Energy": 133.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.97,
    "out:CO2 Operational/m2": 45.94,
    "out:Total CO2/m2": 58.91,
    "out:Total CO2 (tons)": 163.01,
    "out:Klimatpaverkan": -4.01,
    "out:Initial Cost/MSEK": 2.484,
    "out:Running cost/(Apt SEK y)": 23570,
    "out:Running Cost over RSP/MSEK": 8.836,
    "out:LCP/MSEK": -0.018,
    "out:ROI% old": 14.18,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74401,
    "out:DH kr savings": 118981,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 260569,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.17,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.16,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 140.26,
    "out:Total Energy Use Post PV": 140.26,
    "out:Primary Energy": 130.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.97,
    "out:CO2 Operational/m2": 44.5,
    "out:Total CO2/m2": 57.47,
    "out:Total CO2 (tons)": 159.03,
    "out:Klimatpaverkan": -7.99,
    "out:Initial Cost/MSEK": 2.553,
    "out:Running cost/(Apt SEK y)": 22873,
    "out:Running Cost over RSP/MSEK": 8.574,
    "out:LCP/MSEK": 0.174,
    "out:ROI% old": 15.26,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 348,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85469,
    "out:DH kr savings": 130049,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249479,
    "out:% savings (space heating)": 23.28,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.16,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.17,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 138.26,
    "out:Total Energy Use Post PV": 138.26,
    "out:Primary Energy": 131.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.62,
    "out:CO2 Operational/m2": 43.78,
    "out:Total CO2/m2": 57.4,
    "out:Total CO2 (tons)": 158.84,
    "out:Klimatpaverkan": -8.18,
    "out:Initial Cost/MSEK": 2.703,
    "out:Running cost/(Apt SEK y)": 22525,
    "out:Running Cost over RSP/MSEK": 8.444,
    "out:LCP/MSEK": 0.155,
    "out:ROI% old": 15.11,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91003,
    "out:DH kr savings": 135583,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 260569,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.17,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.16,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 134.26,
    "out:Primary Energy": 127.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.62,
    "out:CO2 Operational/m2": 42.34,
    "out:Total CO2/m2": 55.96,
    "out:Total CO2 (tons)": 154.85,
    "out:Klimatpaverkan": -12.17,
    "out:Initial Cost/MSEK": 2.772,
    "out:Running cost/(Apt SEK y)": 21829,
    "out:Running Cost over RSP/MSEK": 8.182,
    "out:LCP/MSEK": 0.348,
    "out:ROI% old": 16.08,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102071,
    "out:DH kr savings": 146651,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249479,
    "out:% savings (space heating)": 23.28,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.16,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.17,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 144.26,
    "out:Total Energy Use Post PV": 137.64,
    "out:Primary Energy": 121.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.85,
    "out:CO2 Operational/m2": 33.52,
    "out:Total CO2/m2": 56.37,
    "out:Total CO2 (tons)": 155.97,
    "out:Klimatpaverkan": -11.05,
    "out:Initial Cost/MSEK": 2.945,
    "out:Running cost/(Apt SEK y)": 22606,
    "out:Running Cost over RSP/MSEK": 8.484,
    "out:LCP/MSEK": -0.126,
    "out:ROI% old": 13.67,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120664,
    "out:DH kr savings": 118981,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 260569,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.17,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.16,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 140.26,
    "out:Total Energy Use Post PV": 133.64,
    "out:Primary Energy": 118.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.85,
    "out:CO2 Operational/m2": 32.08,
    "out:Total CO2/m2": 54.93,
    "out:Total CO2 (tons)": 151.99,
    "out:Klimatpaverkan": -15.03,
    "out:Initial Cost/MSEK": 3.014,
    "out:Running cost/(Apt SEK y)": 21910,
    "out:Running Cost over RSP/MSEK": 8.222,
    "out:LCP/MSEK": 0.066,
    "out:ROI% old": 14.6,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131732,
    "out:DH kr savings": 130049,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249479,
    "out:% savings (space heating)": 23.28,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.16,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.17,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 138.26,
    "out:Total Energy Use Post PV": 131.64,
    "out:Primary Energy": 119.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.5,
    "out:CO2 Operational/m2": 31.36,
    "out:Total CO2/m2": 54.86,
    "out:Total CO2 (tons)": 151.8,
    "out:Klimatpaverkan": -15.22,
    "out:Initial Cost/MSEK": 3.163,
    "out:Running cost/(Apt SEK y)": 21561,
    "out:Running Cost over RSP/MSEK": 8.092,
    "out:LCP/MSEK": 0.047,
    "out:ROI% old": 14.5,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137266,
    "out:DH kr savings": 135583,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 260569,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.17,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.16,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 127.64,
    "out:Primary Energy": 115.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.5,
    "out:CO2 Operational/m2": 29.92,
    "out:Total CO2/m2": 53.42,
    "out:Total CO2 (tons)": 147.81,
    "out:Klimatpaverkan": -19.21,
    "out:Initial Cost/MSEK": 3.232,
    "out:Running cost/(Apt SEK y)": 20865,
    "out:Running Cost over RSP/MSEK": 7.83,
    "out:LCP/MSEK": 0.239,
    "out:ROI% old": 15.34,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148334,
    "out:DH kr savings": 146651,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249479,
    "out:% savings (space heating)": 23.28,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.16,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.17,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 136.26,
    "out:Primary Energy": 130.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.97,
    "out:CO2 Operational/m2": 43.06,
    "out:Total CO2/m2": 56.03,
    "out:Total CO2 (tons)": 155.04,
    "out:Klimatpaverkan": -11.98,
    "out:Initial Cost/MSEK": 2.611,
    "out:Running cost/(Apt SEK y)": 22177,
    "out:Running Cost over RSP/MSEK": 8.313,
    "out:LCP/MSEK": 0.378,
    "out:ROI% old": 16.35,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96537,
    "out:DH kr savings": 141117,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 260569,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.17,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.16,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 132.26,
    "out:Total Energy Use Post PV": 132.26,
    "out:Primary Energy": 126.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.97,
    "out:CO2 Operational/m2": 41.62,
    "out:Total CO2/m2": 54.59,
    "out:Total CO2 (tons)": 151.06,
    "out:Klimatpaverkan": -15.96,
    "out:Initial Cost/MSEK": 2.68,
    "out:Running cost/(Apt SEK y)": 21481,
    "out:Running Cost over RSP/MSEK": 8.052,
    "out:LCP/MSEK": 0.57,
    "out:ROI% old": 17.33,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 415,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107605,
    "out:DH kr savings": 152185,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249479,
    "out:% savings (space heating)": 23.28,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.16,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.17,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 131.26,
    "out:Total Energy Use Post PV": 131.26,
    "out:Primary Energy": 127.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.62,
    "out:CO2 Operational/m2": 41.26,
    "out:Total CO2/m2": 54.88,
    "out:Total CO2 (tons)": 151.87,
    "out:Klimatpaverkan": -15.15,
    "out:Initial Cost/MSEK": 2.829,
    "out:Running cost/(Apt SEK y)": 21307,
    "out:Running Cost over RSP/MSEK": 7.986,
    "out:LCP/MSEK": 0.486,
    "out:ROI% old": 16.74,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110372,
    "out:DH kr savings": 154952,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 260569,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.17,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.16,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 127.26,
    "out:Primary Energy": 124.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.62,
    "out:CO2 Operational/m2": 39.82,
    "out:Total CO2/m2": 53.44,
    "out:Total CO2 (tons)": 147.88,
    "out:Klimatpaverkan": -19.14,
    "out:Initial Cost/MSEK": 2.898,
    "out:Running cost/(Apt SEK y)": 20610,
    "out:Running Cost over RSP/MSEK": 7.725,
    "out:LCP/MSEK": 0.679,
    "out:ROI% old": 17.63,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121440,
    "out:DH kr savings": 166020,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249479,
    "out:% savings (space heating)": 23.28,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 88.17,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00011201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.17,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 129.64,
    "out:Primary Energy": 118.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.85,
    "out:CO2 Operational/m2": 30.64,
    "out:Total CO2/m2": 53.49,
    "out:Total CO2 (tons)": 148,
    "out:Klimatpaverkan": -19.02,
    "out:Initial Cost/MSEK": 3.071,
    "out:Running cost/(Apt SEK y)": 21213,
    "out:Running Cost over RSP/MSEK": 7.961,
    "out:LCP/MSEK": 0.27,
    "out:ROI% old": 15.54,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142800,
    "out:DH kr savings": 141117,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 260569,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.17,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.16,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 132.26,
    "out:Total Energy Use Post PV": 125.64,
    "out:Primary Energy": 114.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.85,
    "out:CO2 Operational/m2": 29.2,
    "out:Total CO2/m2": 52.05,
    "out:Total CO2 (tons)": 144.02,
    "out:Klimatpaverkan": -23,
    "out:Initial Cost/MSEK": 3.14,
    "out:Running cost/(Apt SEK y)": 20517,
    "out:Running Cost over RSP/MSEK": 7.699,
    "out:LCP/MSEK": 0.462,
    "out:ROI% old": 16.39,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 461,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 153868,
    "out:DH kr savings": 152185,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249479,
    "out:% savings (space heating)": 23.28,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.16,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.17,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 131.26,
    "out:Total Energy Use Post PV": 124.64,
    "out:Primary Energy": 116.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.5,
    "out:CO2 Operational/m2": 28.84,
    "out:Total CO2/m2": 52.34,
    "out:Total CO2 (tons)": 144.83,
    "out:Klimatpaverkan": -22.19,
    "out:Initial Cost/MSEK": 3.29,
    "out:Running cost/(Apt SEK y)": 20343,
    "out:Running Cost over RSP/MSEK": 7.634,
    "out:LCP/MSEK": 0.378,
    "out:ROI% old": 15.93,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156635,
    "out:DH kr savings": 154952,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 260569,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.17,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00011201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.16,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 120.64,
    "out:Primary Energy": 112.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.5,
    "out:CO2 Operational/m2": 27.4,
    "out:Total CO2/m2": 50.9,
    "out:Total CO2 (tons)": 140.84,
    "out:Klimatpaverkan": -26.18,
    "out:Initial Cost/MSEK": 3.359,
    "out:Running cost/(Apt SEK y)": 19646,
    "out:Running Cost over RSP/MSEK": 7.373,
    "out:LCP/MSEK": 0.57,
    "out:ROI% old": 16.71,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167703,
    "out:DH kr savings": 166020,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249479,
    "out:% savings (space heating)": 23.28,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 88.17,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.91,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 166.2,
    "out:Total Energy Use Post PV": 166.2,
    "out:Primary Energy": 142.06,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.27,
    "out:CO2 Operational/m2": 55.68,
    "out:Total CO2/m2": 56.95,
    "out:Total CO2 (tons)": 157.58,
    "out:Klimatpaverkan": -9.44,
    "out:Initial Cost/MSEK": 0.32,
    "out:Running cost/(Apt SEK y)": 27864,
    "out:Running Cost over RSP/MSEK": 10.452,
    "out:LCP/MSEK": 0.53,
    "out:ROI% old": 37.99,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 7.8,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 34,
    "out:Return/kSEK/y": 109,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 35971,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 35971,
    "out:DH kr savings": 35971,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 290291,
    "out:% savings (space heating)": 10.73,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.91,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.42,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 162.2,
    "out:Total Energy Use Post PV": 162.2,
    "out:Primary Energy": 138.12,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.27,
    "out:CO2 Operational/m2": 54.24,
    "out:Total CO2/m2": 55.51,
    "out:Total CO2 (tons)": 153.6,
    "out:Klimatpaverkan": -13.42,
    "out:Initial Cost/MSEK": 0.389,
    "out:Running cost/(Apt SEK y)": 27167,
    "out:Running Cost over RSP/MSEK": 10.191,
    "out:LCP/MSEK": 0.722,
    "out:ROI% old": 40.84,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 10.5,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 37,
    "out:Return/kSEK/y": 142,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 47039,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 47039,
    "out:DH kr savings": 47039,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277850,
    "out:% savings (space heating)": 14.56,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.42,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.91,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 160.2,
    "out:Total Energy Use Post PV": 160.2,
    "out:Primary Energy": 139.43,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.92,
    "out:CO2 Operational/m2": 53.52,
    "out:Total CO2/m2": 55.44,
    "out:Total CO2 (tons)": 153.41,
    "out:Klimatpaverkan": -13.61,
    "out:Initial Cost/MSEK": 0.538,
    "out:Running cost/(Apt SEK y)": 26819,
    "out:Running Cost over RSP/MSEK": 10.06,
    "out:LCP/MSEK": 0.704,
    "out:ROI% old": 32.97,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 30,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 52573,
    "out:DH kr savings": 52573,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 290291,
    "out:% savings (space heating)": 10.73,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.91,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.42,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 155.2,
    "out:Primary Energy": 135.5,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.92,
    "out:CO2 Operational/m2": 51.72,
    "out:Total CO2/m2": 53.64,
    "out:Total CO2 (tons)": 148.43,
    "out:Klimatpaverkan": -18.59,
    "out:Initial Cost/MSEK": 0.607,
    "out:Running cost/(Apt SEK y)": 25949,
    "out:Running Cost over RSP/MSEK": 9.733,
    "out:LCP/MSEK": 0.961,
    "out:ROI% old": 36.9,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 33,
    "out:Return/kSEK/y": 201,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 66408,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 66408,
    "out:DH kr savings": 66408,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277850,
    "out:% savings (space heating)": 14.56,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.42,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.91,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 166.2,
    "out:Total Energy Use Post PV": 160.92,
    "out:Primary Energy": 132.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.15,
    "out:CO2 Operational/m2": 36.62,
    "out:Total CO2/m2": 47.77,
    "out:Total CO2 (tons)": 132.19,
    "out:Klimatpaverkan": -34.83,
    "out:Initial Cost/MSEK": 0.78,
    "out:Running cost/(Apt SEK y)": 26938,
    "out:Running Cost over RSP/MSEK": 10.114,
    "out:LCP/MSEK": 0.408,
    "out:ROI% old": 21.76,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 11.2,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 153,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 35971,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 80394,
    "out:DH kr savings": 35971,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 290291,
    "out:% savings (space heating)": 10.73,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.91,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.42,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 162.2,
    "out:Total Energy Use Post PV": 156.92,
    "out:Primary Energy": 128.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.15,
    "out:CO2 Operational/m2": 35.18,
    "out:Total CO2/m2": 46.33,
    "out:Total CO2 (tons)": 128.2,
    "out:Klimatpaverkan": -38.82,
    "out:Initial Cost/MSEK": 0.849,
    "out:Running cost/(Apt SEK y)": 26242,
    "out:Running Cost over RSP/MSEK": 9.852,
    "out:LCP/MSEK": 0.6,
    "out:ROI% old": 24.38,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 186,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 47039,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 91462,
    "out:DH kr savings": 47039,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277850,
    "out:% savings (space heating)": 14.56,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.42,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.91,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 160.2,
    "out:Total Energy Use Post PV": 154.92,
    "out:Primary Energy": 129.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.8,
    "out:CO2 Operational/m2": 34.46,
    "out:Total CO2/m2": 46.26,
    "out:Total CO2 (tons)": 128.01,
    "out:Klimatpaverkan": -39.01,
    "out:Initial Cost/MSEK": 0.999,
    "out:Running cost/(Apt SEK y)": 25894,
    "out:Running Cost over RSP/MSEK": 9.722,
    "out:LCP/MSEK": 0.582,
    "out:ROI% old": 22.61,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 203,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96996,
    "out:DH kr savings": 52573,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 290291,
    "out:% savings (space heating)": 10.73,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.91,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.42,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 149.92,
    "out:Primary Energy": 125.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.8,
    "out:CO2 Operational/m2": 32.66,
    "out:Total CO2/m2": 44.46,
    "out:Total CO2 (tons)": 123.03,
    "out:Klimatpaverkan": -43.99,
    "out:Initial Cost/MSEK": 1.068,
    "out:Running cost/(Apt SEK y)": 25023,
    "out:Running Cost over RSP/MSEK": 9.395,
    "out:LCP/MSEK": 0.839,
    "out:ROI% old": 25.51,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 23,
    "out:Return/kSEK/y": 245,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 66408,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 110831,
    "out:DH kr savings": 66408,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277850,
    "out:% savings (space heating)": 14.56,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.42,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.91,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 158.2,
    "out:Total Energy Use Post PV": 158.2,
    "out:Primary Energy": 138.56,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.27,
    "out:CO2 Operational/m2": 52.8,
    "out:Total CO2/m2": 54.07,
    "out:Total CO2 (tons)": 149.61,
    "out:Klimatpaverkan": -17.41,
    "out:Initial Cost/MSEK": 0.446,
    "out:Running cost/(Apt SEK y)": 26471,
    "out:Running Cost over RSP/MSEK": 9.929,
    "out:LCP/MSEK": 0.926,
    "out:ROI% old": 43.95,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 39,
    "out:Return/kSEK/y": 175,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 58107,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 58107,
    "out:DH kr savings": 58107,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 290291,
    "out:% savings (space heating)": 10.73,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.91,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.42,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 153.2,
    "out:Total Energy Use Post PV": 153.2,
    "out:Primary Energy": 134.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.27,
    "out:CO2 Operational/m2": 51,
    "out:Total CO2/m2": 52.27,
    "out:Total CO2 (tons)": 144.63,
    "out:Klimatpaverkan": -22.39,
    "out:Initial Cost/MSEK": 0.515,
    "out:Running cost/(Apt SEK y)": 25600,
    "out:Running Cost over RSP/MSEK": 9.602,
    "out:LCP/MSEK": 1.184,
    "out:ROI% old": 47.11,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 42,
    "out:Return/kSEK/y": 217,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 71942,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71942,
    "out:DH kr savings": 71942,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277850,
    "out:% savings (space heating)": 14.56,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.42,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.91,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 152.2,
    "out:Primary Energy": 136.46,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.92,
    "out:CO2 Operational/m2": 50.64,
    "out:Total CO2/m2": 52.56,
    "out:Total CO2 (tons)": 145.44,
    "out:Klimatpaverkan": -21.58,
    "out:Initial Cost/MSEK": 0.665,
    "out:Running cost/(Apt SEK y)": 25426,
    "out:Running Cost over RSP/MSEK": 9.537,
    "out:LCP/MSEK": 1.1,
    "out:ROI% old": 37.92,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 17.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 34,
    "out:Return/kSEK/y": 226,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 74709,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74709,
    "out:DH kr savings": 74709,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 290291,
    "out:% savings (space heating)": 10.73,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.91,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.42,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 148.2,
    "out:Primary Energy": 132.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.92,
    "out:CO2 Operational/m2": 49.2,
    "out:Total CO2/m2": 51.12,
    "out:Total CO2 (tons)": 141.46,
    "out:Klimatpaverkan": -25.56,
    "out:Initial Cost/MSEK": 0.734,
    "out:Running cost/(Apt SEK y)": 24730,
    "out:Running Cost over RSP/MSEK": 9.276,
    "out:LCP/MSEK": 1.292,
    "out:ROI% old": 39.44,
    "out:Payback discounted": 3,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 35,
    "out:Return/kSEK/y": 259,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85777,
    "out:DH kr savings": 85777,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277850,
    "out:% savings (space heating)": 14.56,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.42,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.91,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 158.2,
    "out:Total Energy Use Post PV": 152.92,
    "out:Primary Energy": 129.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.15,
    "out:CO2 Operational/m2": 33.74,
    "out:Total CO2/m2": 44.89,
    "out:Total CO2 (tons)": 124.22,
    "out:Klimatpaverkan": -42.8,
    "out:Initial Cost/MSEK": 0.907,
    "out:Running cost/(Apt SEK y)": 25545,
    "out:Running Cost over RSP/MSEK": 9.591,
    "out:LCP/MSEK": 0.804,
    "out:ROI% old": 26.96,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 24,
    "out:Return/kSEK/y": 220,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 58107,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102530,
    "out:DH kr savings": 58107,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 290291,
    "out:% savings (space heating)": 10.73,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.91,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.42,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 153.2,
    "out:Total Energy Use Post PV": 147.92,
    "out:Primary Energy": 125.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.15,
    "out:CO2 Operational/m2": 31.94,
    "out:Total CO2/m2": 43.09,
    "out:Total CO2 (tons)": 119.24,
    "out:Klimatpaverkan": -47.78,
    "out:Initial Cost/MSEK": 0.976,
    "out:Running cost/(Apt SEK y)": 24675,
    "out:Running Cost over RSP/MSEK": 9.264,
    "out:LCP/MSEK": 1.062,
    "out:ROI% old": 29.83,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 27,
    "out:Return/kSEK/y": 262,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 71942,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116365,
    "out:DH kr savings": 71942,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277850,
    "out:% savings (space heating)": 14.56,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.42,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.91,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 146.92,
    "out:Primary Energy": 126.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.8,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 43.38,
    "out:Total CO2 (tons)": 120.04,
    "out:Klimatpaverkan": -46.98,
    "out:Initial Cost/MSEK": 1.125,
    "out:Running cost/(Apt SEK y)": 24501,
    "out:Running Cost over RSP/MSEK": 9.199,
    "out:LCP/MSEK": 0.978,
    "out:ROI% old": 26.7,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 24,
    "out:Return/kSEK/y": 270,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 74709,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119132,
    "out:DH kr savings": 74709,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 290291,
    "out:% savings (space heating)": 10.73,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.91,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.42,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 142.92,
    "out:Primary Energy": 123.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.8,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 41.94,
    "out:Total CO2 (tons)": 116.06,
    "out:Klimatpaverkan": -50.96,
    "out:Initial Cost/MSEK": 1.194,
    "out:Running cost/(Apt SEK y)": 23805,
    "out:Running Cost over RSP/MSEK": 8.937,
    "out:LCP/MSEK": 1.17,
    "out:ROI% old": 28.28,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 25,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130200,
    "out:DH kr savings": 85777,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277850,
    "out:% savings (space heating)": 14.56,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.42,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.91,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 155.26,
    "out:Total Energy Use Post PV": 155.26,
    "out:Primary Energy": 143.26,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.07,
    "out:CO2 Operational/m2": 49.9,
    "out:Total CO2/m2": 54.97,
    "out:Total CO2 (tons)": 152.1,
    "out:Klimatpaverkan": -14.92,
    "out:Initial Cost/MSEK": 0.964,
    "out:Running cost/(Apt SEK y)": 25485,
    "out:Running Cost over RSP/MSEK": 9.555,
    "out:LCP/MSEK": 0.783,
    "out:ROI% old": 25.89,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 223,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 43964,
    "out:DH kr savings": 88544,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 290291,
    "out:% savings (space heating)": 10.73,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.91,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.42,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 151.26,
    "out:Total Energy Use Post PV": 151.26,
    "out:Primary Energy": 139.33,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.07,
    "out:CO2 Operational/m2": 48.46,
    "out:Total CO2/m2": 53.53,
    "out:Total CO2 (tons)": 148.12,
    "out:Klimatpaverkan": -18.9,
    "out:Initial Cost/MSEK": 1.033,
    "out:Running cost/(Apt SEK y)": 24788,
    "out:Running Cost over RSP/MSEK": 9.293,
    "out:LCP/MSEK": 0.975,
    "out:ROI% old": 27.78,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 256,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 55032,
    "out:DH kr savings": 99612,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277850,
    "out:% savings (space heating)": 14.56,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.42,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.91,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 149.26,
    "out:Total Energy Use Post PV": 149.26,
    "out:Primary Energy": 140.64,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.72,
    "out:CO2 Operational/m2": 47.74,
    "out:Total CO2/m2": 53.46,
    "out:Total CO2 (tons)": 147.93,
    "out:Klimatpaverkan": -19.09,
    "out:Initial Cost/MSEK": 1.182,
    "out:Running cost/(Apt SEK y)": 24440,
    "out:Running Cost over RSP/MSEK": 9.163,
    "out:LCP/MSEK": 0.957,
    "out:ROI% old": 25.85,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 273,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 60566,
    "out:DH kr savings": 105146,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 290291,
    "out:% savings (space heating)": 10.73,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.91,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.42,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 144.26,
    "out:Total Energy Use Post PV": 144.26,
    "out:Primary Energy": 136.7,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.72,
    "out:CO2 Operational/m2": 45.94,
    "out:Total CO2/m2": 51.66,
    "out:Total CO2 (tons)": 142.95,
    "out:Klimatpaverkan": -24.07,
    "out:Initial Cost/MSEK": 1.252,
    "out:Running cost/(Apt SEK y)": 23570,
    "out:Running Cost over RSP/MSEK": 8.836,
    "out:LCP/MSEK": 1.214,
    "out:ROI% old": 28.15,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 74401,
    "out:DH kr savings": 118981,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277850,
    "out:% savings (space heating)": 14.56,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.42,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.91,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 155.26,
    "out:Total Energy Use Post PV": 148.64,
    "out:Primary Energy": 131.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.95,
    "out:CO2 Operational/m2": 37.48,
    "out:Total CO2/m2": 52.43,
    "out:Total CO2 (tons)": 145.06,
    "out:Klimatpaverkan": -21.96,
    "out:Initial Cost/MSEK": 1.424,
    "out:Running cost/(Apt SEK y)": 24521,
    "out:Running Cost over RSP/MSEK": 9.203,
    "out:LCP/MSEK": 0.675,
    "out:ROI% old": 21.06,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 269,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 90227,
    "out:DH kr savings": 88544,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 290291,
    "out:% savings (space heating)": 10.73,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.91,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.42,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 151.26,
    "out:Total Energy Use Post PV": 144.64,
    "out:Primary Energy": 127.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.95,
    "out:CO2 Operational/m2": 36.04,
    "out:Total CO2/m2": 50.99,
    "out:Total CO2 (tons)": 141.08,
    "out:Klimatpaverkan": -25.94,
    "out:Initial Cost/MSEK": 1.493,
    "out:Running cost/(Apt SEK y)": 23825,
    "out:Running Cost over RSP/MSEK": 8.941,
    "out:LCP/MSEK": 0.867,
    "out:ROI% old": 22.58,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 101295,
    "out:DH kr savings": 99612,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277850,
    "out:% savings (space heating)": 14.56,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.42,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.91,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 149.26,
    "out:Total Energy Use Post PV": 142.64,
    "out:Primary Energy": 128.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.6,
    "out:CO2 Operational/m2": 35.32,
    "out:Total CO2/m2": 50.92,
    "out:Total CO2 (tons)": 140.89,
    "out:Klimatpaverkan": -26.13,
    "out:Initial Cost/MSEK": 1.643,
    "out:Running cost/(Apt SEK y)": 23476,
    "out:Running Cost over RSP/MSEK": 8.81,
    "out:LCP/MSEK": 0.849,
    "out:ROI% old": 21.66,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 319,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 106829,
    "out:DH kr savings": 105146,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 290291,
    "out:% savings (space heating)": 10.73,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.91,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.42,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 144.26,
    "out:Total Energy Use Post PV": 137.64,
    "out:Primary Energy": 124.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.6,
    "out:CO2 Operational/m2": 33.52,
    "out:Total CO2/m2": 49.12,
    "out:Total CO2 (tons)": 135.91,
    "out:Klimatpaverkan": -31.11,
    "out:Initial Cost/MSEK": 1.712,
    "out:Running cost/(Apt SEK y)": 22606,
    "out:Running Cost over RSP/MSEK": 8.484,
    "out:LCP/MSEK": 1.106,
    "out:ROI% old": 23.52,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 120664,
    "out:DH kr savings": 118981,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277850,
    "out:% savings (space heating)": 14.56,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.42,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.91,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 147.26,
    "out:Total Energy Use Post PV": 147.26,
    "out:Primary Energy": 139.76,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.07,
    "out:CO2 Operational/m2": 47.02,
    "out:Total CO2/m2": 52.09,
    "out:Total CO2 (tons)": 144.13,
    "out:Klimatpaverkan": -22.89,
    "out:Initial Cost/MSEK": 1.09,
    "out:Running cost/(Apt SEK y)": 24092,
    "out:Running Cost over RSP/MSEK": 9.032,
    "out:LCP/MSEK": 1.179,
    "out:ROI% old": 29.74,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 27,
    "out:Return/kSEK/y": 290,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 66100,
    "out:DH kr savings": 110680,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 290291,
    "out:% savings (space heating)": 10.73,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.91,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.42,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 142.26,
    "out:Total Energy Use Post PV": 142.26,
    "out:Primary Energy": 135.83,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.07,
    "out:CO2 Operational/m2": 45.22,
    "out:Total CO2/m2": 50.29,
    "out:Total CO2 (tons)": 139.15,
    "out:Klimatpaverkan": -27.87,
    "out:Initial Cost/MSEK": 1.16,
    "out:Running cost/(Apt SEK y)": 23222,
    "out:Running Cost over RSP/MSEK": 8.705,
    "out:LCP/MSEK": 1.437,
    "out:ROI% old": 31.99,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 29,
    "out:Return/kSEK/y": 331,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 79935,
    "out:DH kr savings": 124515,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277850,
    "out:% savings (space heating)": 14.56,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.42,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.91,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 141.26,
    "out:Total Energy Use Post PV": 141.26,
    "out:Primary Energy": 137.31,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.72,
    "out:CO2 Operational/m2": 44.86,
    "out:Total CO2/m2": 50.58,
    "out:Total CO2 (tons)": 139.96,
    "out:Klimatpaverkan": -27.06,
    "out:Initial Cost/MSEK": 1.309,
    "out:Running cost/(Apt SEK y)": 23047,
    "out:Running Cost over RSP/MSEK": 8.64,
    "out:LCP/MSEK": 1.353,
    "out:ROI% old": 29.05,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 26,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82702,
    "out:DH kr savings": 127282,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 290291,
    "out:% savings (space heating)": 10.73,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.42,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 137.26,
    "out:Primary Energy": 133.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.72,
    "out:CO2 Operational/m2": 43.42,
    "out:Total CO2/m2": 49.14,
    "out:Total CO2 (tons)": 135.98,
    "out:Klimatpaverkan": -31.04,
    "out:Initial Cost/MSEK": 1.378,
    "out:Running cost/(Apt SEK y)": 22351,
    "out:Running Cost over RSP/MSEK": 8.378,
    "out:LCP/MSEK": 1.545,
    "out:ROI% old": 30.3,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 27,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93770,
    "out:DH kr savings": 138350,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277850,
    "out:% savings (space heating)": 14.56,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 98.42,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.91,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 147.26,
    "out:Total Energy Use Post PV": 140.64,
    "out:Primary Energy": 127.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.95,
    "out:CO2 Operational/m2": 34.6,
    "out:Total CO2/m2": 49.55,
    "out:Total CO2 (tons)": 137.09,
    "out:Klimatpaverkan": -29.93,
    "out:Initial Cost/MSEK": 1.551,
    "out:Running cost/(Apt SEK y)": 23128,
    "out:Running Cost over RSP/MSEK": 8.68,
    "out:LCP/MSEK": 1.071,
    "out:ROI% old": 24.15,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 336,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 112363,
    "out:DH kr savings": 110680,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 290291,
    "out:% savings (space heating)": 10.73,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 104.91,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.42,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 142.26,
    "out:Total Energy Use Post PV": 135.64,
    "out:Primary Energy": 123.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.95,
    "out:CO2 Operational/m2": 32.8,
    "out:Total CO2/m2": 47.75,
    "out:Total CO2 (tons)": 132.11,
    "out:Klimatpaverkan": -34.91,
    "out:Initial Cost/MSEK": 1.62,
    "out:Running cost/(Apt SEK y)": 22258,
    "out:Running Cost over RSP/MSEK": 8.353,
    "out:LCP/MSEK": 1.329,
    "out:ROI% old": 26,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 378,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126198,
    "out:DH kr savings": 124515,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277850,
    "out:% savings (space heating)": 14.56,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 100.42,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.91,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 141.26,
    "out:Total Energy Use Post PV": 134.64,
    "out:Primary Energy": 125.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.6,
    "out:CO2 Operational/m2": 32.44,
    "out:Total CO2/m2": 48.04,
    "out:Total CO2 (tons)": 132.92,
    "out:Klimatpaverkan": -34.1,
    "out:Initial Cost/MSEK": 1.77,
    "out:Running cost/(Apt SEK y)": 22084,
    "out:Running Cost over RSP/MSEK": 8.288,
    "out:LCP/MSEK": 1.245,
    "out:ROI% old": 24.33,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128965,
    "out:DH kr savings": 127282,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 290291,
    "out:% savings (space heating)": 10.73,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.42,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 130.64,
    "out:Primary Energy": 121.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.6,
    "out:CO2 Operational/m2": 31,
    "out:Total CO2/m2": 46.6,
    "out:Total CO2 (tons)": 128.94,
    "out:Klimatpaverkan": -38.08,
    "out:Initial Cost/MSEK": 1.839,
    "out:Running cost/(Apt SEK y)": 21387,
    "out:Running Cost over RSP/MSEK": 8.026,
    "out:LCP/MSEK": 1.437,
    "out:ROI% old": 25.45,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140033,
    "out:DH kr savings": 138350,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 277850,
    "out:% savings (space heating)": 14.56,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 98.42,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.18,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 157.2,
    "out:Primary Energy": 134.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.58,
    "out:CO2 Operational/m2": 52.44,
    "out:Total CO2/m2": 56.02,
    "out:Total CO2 (tons)": 155.02,
    "out:Klimatpaverkan": -12,
    "out:Initial Cost/MSEK": 0.766,
    "out:Running cost/(Apt SEK y)": 26297,
    "out:Running Cost over RSP/MSEK": 9.864,
    "out:LCP/MSEK": 0.672,
    "out:ROI% old": 26.81,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 24,
    "out:Return/kSEK/y": 184,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 60874,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 60874,
    "out:DH kr savings": 60874,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 266120,
    "out:% savings (space heating)": 18.16,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.18,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.08,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 153.2,
    "out:Total Energy Use Post PV": 153.2,
    "out:Primary Energy": 130.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.58,
    "out:CO2 Operational/m2": 51,
    "out:Total CO2/m2": 54.58,
    "out:Total CO2 (tons)": 151.03,
    "out:Klimatpaverkan": -15.99,
    "out:Initial Cost/MSEK": 0.835,
    "out:Running cost/(Apt SEK y)": 25600,
    "out:Running Cost over RSP/MSEK": 9.602,
    "out:LCP/MSEK": 0.864,
    "out:ROI% old": 29.06,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 26,
    "out:Return/kSEK/y": 217,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 71942,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71942,
    "out:DH kr savings": 71942,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254778,
    "out:% savings (space heating)": 21.65,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.08,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.18,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 151.2,
    "out:Total Energy Use Post PV": 151.2,
    "out:Primary Energy": 131.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.24,
    "out:CO2 Operational/m2": 50.28,
    "out:Total CO2/m2": 54.52,
    "out:Total CO2 (tons)": 150.85,
    "out:Klimatpaverkan": -16.17,
    "out:Initial Cost/MSEK": 0.985,
    "out:Running cost/(Apt SEK y)": 25252,
    "out:Running Cost over RSP/MSEK": 9.472,
    "out:LCP/MSEK": 0.845,
    "out:ROI% old": 26.55,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 24,
    "out:Return/kSEK/y": 234,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77476,
    "out:DH kr savings": 77476,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 266120,
    "out:% savings (space heating)": 18.16,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.18,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.08,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 147.2,
    "out:Primary Energy": 128.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.24,
    "out:CO2 Operational/m2": 48.84,
    "out:Total CO2/m2": 53.08,
    "out:Total CO2 (tons)": 146.86,
    "out:Klimatpaverkan": -20.16,
    "out:Initial Cost/MSEK": 1.054,
    "out:Running cost/(Apt SEK y)": 24556,
    "out:Running Cost over RSP/MSEK": 9.21,
    "out:LCP/MSEK": 1.038,
    "out:ROI% old": 28.35,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 25,
    "out:Return/kSEK/y": 267,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88544,
    "out:DH kr savings": 88544,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254778,
    "out:% savings (space heating)": 21.65,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.08,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.18,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 151.92,
    "out:Primary Energy": 124.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.47,
    "out:CO2 Operational/m2": 33.38,
    "out:Total CO2/m2": 46.85,
    "out:Total CO2 (tons)": 129.62,
    "out:Klimatpaverkan": -37.4,
    "out:Initial Cost/MSEK": 1.227,
    "out:Running cost/(Apt SEK y)": 25371,
    "out:Running Cost over RSP/MSEK": 9.526,
    "out:LCP/MSEK": 0.55,
    "out:ROI% old": 20.69,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 17.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 228,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 60874,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105297,
    "out:DH kr savings": 60874,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 266120,
    "out:% savings (space heating)": 18.16,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.18,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.08,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 153.2,
    "out:Total Energy Use Post PV": 147.92,
    "out:Primary Energy": 121.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.47,
    "out:CO2 Operational/m2": 31.94,
    "out:Total CO2/m2": 45.41,
    "out:Total CO2 (tons)": 125.64,
    "out:Klimatpaverkan": -41.38,
    "out:Initial Cost/MSEK": 1.296,
    "out:Running cost/(Apt SEK y)": 24675,
    "out:Running Cost over RSP/MSEK": 9.264,
    "out:LCP/MSEK": 0.742,
    "out:ROI% old": 22.46,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 262,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 71942,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116365,
    "out:DH kr savings": 71942,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254778,
    "out:% savings (space heating)": 21.65,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.08,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.18,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 151.2,
    "out:Total Energy Use Post PV": 145.92,
    "out:Primary Energy": 122.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.12,
    "out:CO2 Operational/m2": 31.22,
    "out:Total CO2/m2": 45.34,
    "out:Total CO2 (tons)": 125.45,
    "out:Klimatpaverkan": -41.57,
    "out:Initial Cost/MSEK": 1.445,
    "out:Running cost/(Apt SEK y)": 24327,
    "out:Running Cost over RSP/MSEK": 9.133,
    "out:LCP/MSEK": 0.723,
    "out:ROI% old": 21.43,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 278,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121899,
    "out:DH kr savings": 77476,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 266120,
    "out:% savings (space heating)": 18.16,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.18,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.08,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 141.92,
    "out:Primary Energy": 118.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.12,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 43.9,
    "out:Total CO2 (tons)": 121.47,
    "out:Klimatpaverkan": -45.55,
    "out:Initial Cost/MSEK": 1.514,
    "out:Running cost/(Apt SEK y)": 23630,
    "out:Running Cost over RSP/MSEK": 8.872,
    "out:LCP/MSEK": 0.915,
    "out:ROI% old": 22.92,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 312,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132967,
    "out:DH kr savings": 88544,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254778,
    "out:% savings (space heating)": 21.65,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.08,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.18,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 149.2,
    "out:Primary Energy": 130.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.58,
    "out:CO2 Operational/m2": 49.56,
    "out:Total CO2/m2": 53.14,
    "out:Total CO2 (tons)": 147.05,
    "out:Klimatpaverkan": -19.97,
    "out:Initial Cost/MSEK": 0.893,
    "out:Running cost/(Apt SEK y)": 24904,
    "out:Running Cost over RSP/MSEK": 9.341,
    "out:LCP/MSEK": 1.068,
    "out:ROI% old": 31.37,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 28,
    "out:Return/kSEK/y": 251,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83010,
    "out:DH kr savings": 83010,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 266120,
    "out:% savings (space heating)": 18.16,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.18,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.08,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 145.2,
    "out:Primary Energy": 127.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.58,
    "out:CO2 Operational/m2": 48.12,
    "out:Total CO2/m2": 51.7,
    "out:Total CO2 (tons)": 143.07,
    "out:Klimatpaverkan": -23.95,
    "out:Initial Cost/MSEK": 0.962,
    "out:Running cost/(Apt SEK y)": 24208,
    "out:Running Cost over RSP/MSEK": 9.079,
    "out:LCP/MSEK": 1.26,
    "out:ROI% old": 33,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 30,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 94078,
    "out:DH kr savings": 94078,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254778,
    "out:% savings (space heating)": 21.65,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.08,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.18,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 144.2,
    "out:Total Energy Use Post PV": 144.2,
    "out:Primary Energy": 128.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.24,
    "out:CO2 Operational/m2": 47.76,
    "out:Total CO2/m2": 52,
    "out:Total CO2 (tons)": 143.87,
    "out:Klimatpaverkan": -23.15,
    "out:Initial Cost/MSEK": 1.111,
    "out:Running cost/(Apt SEK y)": 24034,
    "out:Running Cost over RSP/MSEK": 9.014,
    "out:LCP/MSEK": 1.176,
    "out:ROI% old": 29.4,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 26,
    "out:Return/kSEK/y": 292,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96845,
    "out:DH kr savings": 96845,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 266120,
    "out:% savings (space heating)": 18.16,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.18,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.08,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 140.2,
    "out:Primary Energy": 125.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.24,
    "out:CO2 Operational/m2": 46.32,
    "out:Total CO2/m2": 50.56,
    "out:Total CO2 (tons)": 139.89,
    "out:Klimatpaverkan": -27.13,
    "out:Initial Cost/MSEK": 1.181,
    "out:Running cost/(Apt SEK y)": 23337,
    "out:Running Cost over RSP/MSEK": 8.753,
    "out:LCP/MSEK": 1.368,
    "out:ROI% old": 30.85,
    "out:Payback discounted": 4,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 28,
    "out:Return/kSEK/y": 326,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107913,
    "out:DH kr savings": 107913,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254778,
    "out:% savings (space heating)": 21.65,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.08,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.18,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 149.2,
    "out:Total Energy Use Post PV": 143.92,
    "out:Primary Energy": 121.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.47,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 43.97,
    "out:Total CO2 (tons)": 121.66,
    "out:Klimatpaverkan": -45.36,
    "out:Initial Cost/MSEK": 1.353,
    "out:Running cost/(Apt SEK y)": 23979,
    "out:Running Cost over RSP/MSEK": 9.003,
    "out:LCP/MSEK": 0.946,
    "out:ROI% old": 24.27,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 83010,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127433,
    "out:DH kr savings": 83010,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 266120,
    "out:% savings (space heating)": 18.16,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.18,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.08,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 139.92,
    "out:Primary Energy": 117.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.47,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 42.53,
    "out:Total CO2 (tons)": 117.67,
    "out:Klimatpaverkan": -49.35,
    "out:Initial Cost/MSEK": 1.423,
    "out:Running cost/(Apt SEK y)": 23282,
    "out:Running Cost over RSP/MSEK": 8.741,
    "out:LCP/MSEK": 1.138,
    "out:ROI% old": 25.71,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 23,
    "out:Return/kSEK/y": 329,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138501,
    "out:DH kr savings": 94078,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254778,
    "out:% savings (space heating)": 21.65,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.08,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.18,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 144.2,
    "out:Total Energy Use Post PV": 138.92,
    "out:Primary Energy": 119.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.12,
    "out:CO2 Operational/m2": 28.7,
    "out:Total CO2/m2": 42.82,
    "out:Total CO2 (tons)": 118.48,
    "out:Klimatpaverkan": -48.54,
    "out:Initial Cost/MSEK": 1.572,
    "out:Running cost/(Apt SEK y)": 23108,
    "out:Running Cost over RSP/MSEK": 8.676,
    "out:LCP/MSEK": 1.054,
    "out:ROI% old": 23.86,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141268,
    "out:DH kr savings": 96845,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 266120,
    "out:% savings (space heating)": 18.16,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.18,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.08,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 134.92,
    "out:Primary Energy": 115.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.12,
    "out:CO2 Operational/m2": 27.26,
    "out:Total CO2/m2": 41.38,
    "out:Total CO2 (tons)": 114.5,
    "out:Klimatpaverkan": -52.52,
    "out:Initial Cost/MSEK": 1.641,
    "out:Running cost/(Apt SEK y)": 22412,
    "out:Running Cost over RSP/MSEK": 8.415,
    "out:LCP/MSEK": 1.246,
    "out:ROI% old": 25.13,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 23,
    "out:Return/kSEK/y": 370,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152336,
    "out:DH kr savings": 107913,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254778,
    "out:% savings (space heating)": 21.65,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.08,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.18,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 146.26,
    "out:Total Energy Use Post PV": 146.26,
    "out:Primary Energy": 135.62,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.38,
    "out:CO2 Operational/m2": 46.66,
    "out:Total CO2/m2": 54.05,
    "out:Total CO2 (tons)": 149.54,
    "out:Klimatpaverkan": -17.48,
    "out:Initial Cost/MSEK": 1.41,
    "out:Running cost/(Apt SEK y)": 23918,
    "out:Running Cost over RSP/MSEK": 8.967,
    "out:LCP/MSEK": 0.925,
    "out:ROI% old": 23.65,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 68867,
    "out:DH kr savings": 113447,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 266120,
    "out:% savings (space heating)": 18.16,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.18,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.08,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 142.26,
    "out:Total Energy Use Post PV": 142.26,
    "out:Primary Energy": 132.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.38,
    "out:CO2 Operational/m2": 45.22,
    "out:Total CO2/m2": 52.61,
    "out:Total CO2 (tons)": 145.56,
    "out:Klimatpaverkan": -21.46,
    "out:Initial Cost/MSEK": 1.48,
    "out:Running cost/(Apt SEK y)": 23222,
    "out:Running Cost over RSP/MSEK": 8.705,
    "out:LCP/MSEK": 1.117,
    "out:ROI% old": 25.07,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 331,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 79935,
    "out:DH kr savings": 124515,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254778,
    "out:% savings (space heating)": 21.65,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.08,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.18,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 140.26,
    "out:Total Energy Use Post PV": 140.26,
    "out:Primary Energy": 132.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.03,
    "out:CO2 Operational/m2": 44.5,
    "out:Total CO2/m2": 52.54,
    "out:Total CO2 (tons)": 145.37,
    "out:Klimatpaverkan": -21.65,
    "out:Initial Cost/MSEK": 1.629,
    "out:Running cost/(Apt SEK y)": 22873,
    "out:Running Cost over RSP/MSEK": 8.574,
    "out:LCP/MSEK": 1.098,
    "out:ROI% old": 23.92,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 348,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85469,
    "out:DH kr savings": 130049,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 266120,
    "out:% savings (space heating)": 18.16,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.18,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.08,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 136.26,
    "out:Primary Energy": 129.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.03,
    "out:CO2 Operational/m2": 43.06,
    "out:Total CO2/m2": 51.1,
    "out:Total CO2 (tons)": 141.39,
    "out:Klimatpaverkan": -25.63,
    "out:Initial Cost/MSEK": 1.698,
    "out:Running cost/(Apt SEK y)": 22177,
    "out:Running Cost over RSP/MSEK": 8.313,
    "out:LCP/MSEK": 1.291,
    "out:ROI% old": 25.14,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 96537,
    "out:DH kr savings": 141117,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254778,
    "out:% savings (space heating)": 21.65,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.08,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.18,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 146.26,
    "out:Total Energy Use Post PV": 139.64,
    "out:Primary Energy": 123.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.26,
    "out:CO2 Operational/m2": 34.24,
    "out:Total CO2/m2": 51.5,
    "out:Total CO2 (tons)": 142.5,
    "out:Klimatpaverkan": -24.52,
    "out:Initial Cost/MSEK": 1.871,
    "out:Running cost/(Apt SEK y)": 22954,
    "out:Running Cost over RSP/MSEK": 8.614,
    "out:LCP/MSEK": 0.816,
    "out:ROI% old": 20.52,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 344,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115130,
    "out:DH kr savings": 113447,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 266120,
    "out:% savings (space heating)": 18.16,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.18,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.08,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 142.26,
    "out:Total Energy Use Post PV": 135.64,
    "out:Primary Energy": 120.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.26,
    "out:CO2 Operational/m2": 32.8,
    "out:Total CO2/m2": 50.06,
    "out:Total CO2 (tons)": 138.52,
    "out:Klimatpaverkan": -28.5,
    "out:Initial Cost/MSEK": 1.94,
    "out:Running cost/(Apt SEK y)": 22258,
    "out:Running Cost over RSP/MSEK": 8.353,
    "out:LCP/MSEK": 1.009,
    "out:ROI% old": 21.71,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 378,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126198,
    "out:DH kr savings": 124515,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254778,
    "out:% savings (space heating)": 21.65,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.08,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.18,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 140.26,
    "out:Total Energy Use Post PV": 133.64,
    "out:Primary Energy": 121.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.92,
    "out:CO2 Operational/m2": 32.08,
    "out:Total CO2/m2": 49.99,
    "out:Total CO2 (tons)": 138.33,
    "out:Klimatpaverkan": -28.69,
    "out:Initial Cost/MSEK": 2.089,
    "out:Running cost/(Apt SEK y)": 21910,
    "out:Running Cost over RSP/MSEK": 8.222,
    "out:LCP/MSEK": 0.99,
    "out:ROI% old": 21.06,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131732,
    "out:DH kr savings": 130049,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 266120,
    "out:% savings (space heating)": 18.16,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.18,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.08,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 129.64,
    "out:Primary Energy": 117.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.92,
    "out:CO2 Operational/m2": 30.64,
    "out:Total CO2/m2": 48.55,
    "out:Total CO2 (tons)": 134.35,
    "out:Klimatpaverkan": -32.67,
    "out:Initial Cost/MSEK": 2.159,
    "out:Running cost/(Apt SEK y)": 21213,
    "out:Running Cost over RSP/MSEK": 7.961,
    "out:LCP/MSEK": 1.182,
    "out:ROI% old": 22.11,
    "out:Payback discounted": 6,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142800,
    "out:DH kr savings": 141117,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254778,
    "out:% savings (space heating)": 21.65,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.08,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.18,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 138.26,
    "out:Total Energy Use Post PV": 138.26,
    "out:Primary Energy": 132.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.38,
    "out:CO2 Operational/m2": 43.78,
    "out:Total CO2/m2": 51.17,
    "out:Total CO2 (tons)": 141.57,
    "out:Klimatpaverkan": -25.45,
    "out:Initial Cost/MSEK": 1.537,
    "out:Running cost/(Apt SEK y)": 22525,
    "out:Running Cost over RSP/MSEK": 8.444,
    "out:LCP/MSEK": 1.321,
    "out:ROI% old": 26.56,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 24,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91003,
    "out:DH kr savings": 135583,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 266120,
    "out:% savings (space heating)": 18.16,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.18,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.08,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 134.26,
    "out:Primary Energy": 128.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.38,
    "out:CO2 Operational/m2": 42.34,
    "out:Total CO2/m2": 49.73,
    "out:Total CO2 (tons)": 137.59,
    "out:Klimatpaverkan": -29.43,
    "out:Initial Cost/MSEK": 1.606,
    "out:Running cost/(Apt SEK y)": 21829,
    "out:Running Cost over RSP/MSEK": 8.182,
    "out:LCP/MSEK": 1.513,
    "out:ROI% old": 27.74,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 25,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102071,
    "out:DH kr savings": 146651,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254778,
    "out:% savings (space heating)": 21.65,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.08,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.18,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 133.26,
    "out:Total Energy Use Post PV": 133.26,
    "out:Primary Energy": 129.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.03,
    "out:CO2 Operational/m2": 41.98,
    "out:Total CO2/m2": 50.02,
    "out:Total CO2 (tons)": 138.4,
    "out:Klimatpaverkan": -28.62,
    "out:Initial Cost/MSEK": 1.756,
    "out:Running cost/(Apt SEK y)": 21655,
    "out:Running Cost over RSP/MSEK": 8.117,
    "out:LCP/MSEK": 1.429,
    "out:ROI% old": 25.92,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 407,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 104838,
    "out:DH kr savings": 149418,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 266120,
    "out:% savings (space heating)": 18.16,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.08,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 129.26,
    "out:Total Energy Use Post PV": 129.26,
    "out:Primary Energy": 126.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.03,
    "out:CO2 Operational/m2": 40.54,
    "out:Total CO2/m2": 48.58,
    "out:Total CO2 (tons)": 134.41,
    "out:Klimatpaverkan": -32.61,
    "out:Initial Cost/MSEK": 1.825,
    "out:Running cost/(Apt SEK y)": 20958,
    "out:Running Cost over RSP/MSEK": 7.855,
    "out:LCP/MSEK": 1.621,
    "out:ROI% old": 26.98,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 24,
    "out:Return/kSEK/y": 440,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115906,
    "out:DH kr savings": 160486,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254778,
    "out:% savings (space heating)": 21.65,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.08,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.18,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 138.26,
    "out:Total Energy Use Post PV": 131.64,
    "out:Primary Energy": 120.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.26,
    "out:CO2 Operational/m2": 31.36,
    "out:Total CO2/m2": 48.62,
    "out:Total CO2 (tons)": 134.53,
    "out:Klimatpaverkan": -32.49,
    "out:Initial Cost/MSEK": 1.998,
    "out:Running cost/(Apt SEK y)": 21561,
    "out:Running Cost over RSP/MSEK": 8.092,
    "out:LCP/MSEK": 1.213,
    "out:ROI% old": 22.96,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137266,
    "out:DH kr savings": 135583,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 266120,
    "out:% savings (space heating)": 18.16,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 96.18,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.08,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 127.64,
    "out:Primary Energy": 116.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.26,
    "out:CO2 Operational/m2": 29.92,
    "out:Total CO2/m2": 47.18,
    "out:Total CO2 (tons)": 130.55,
    "out:Klimatpaverkan": -36.47,
    "out:Initial Cost/MSEK": 2.067,
    "out:Running cost/(Apt SEK y)": 20865,
    "out:Running Cost over RSP/MSEK": 7.83,
    "out:LCP/MSEK": 1.405,
    "out:ROI% old": 24,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148334,
    "out:DH kr savings": 146651,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254778,
    "out:% savings (space heating)": 21.65,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.08,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.18,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 133.26,
    "out:Total Energy Use Post PV": 126.64,
    "out:Primary Energy": 117.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.92,
    "out:CO2 Operational/m2": 29.56,
    "out:Total CO2/m2": 47.47,
    "out:Total CO2 (tons)": 131.36,
    "out:Klimatpaverkan": -35.66,
    "out:Initial Cost/MSEK": 2.216,
    "out:Running cost/(Apt SEK y)": 20691,
    "out:Running Cost over RSP/MSEK": 7.765,
    "out:LCP/MSEK": 1.321,
    "out:ROI% old": 22.8,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 453,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151101,
    "out:DH kr savings": 149418,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 266120,
    "out:% savings (space heating)": 18.16,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.08,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 129.26,
    "out:Total Energy Use Post PV": 122.64,
    "out:Primary Energy": 114.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.92,
    "out:CO2 Operational/m2": 28.12,
    "out:Total CO2/m2": 46.03,
    "out:Total CO2 (tons)": 127.37,
    "out:Klimatpaverkan": -39.65,
    "out:Initial Cost/MSEK": 2.285,
    "out:Running cost/(Apt SEK y)": 19995,
    "out:Running Cost over RSP/MSEK": 7.503,
    "out:LCP/MSEK": 1.513,
    "out:ROI% old": 23.75,
    "out:Payback discounted": 5,
    "out:Atemp": 2767,
    "out:Umean": 0.67,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162169,
    "out:DH kr savings": 160486,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 254778,
    "out:% savings (space heating)": 21.65,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.08,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.3,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 155.2,
    "out:Primary Energy": 131.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.34,
    "out:CO2 Operational/m2": 51.72,
    "out:Total CO2/m2": 61.06,
    "out:Total CO2 (tons)": 168.94,
    "out:Klimatpaverkan": 1.92,
    "out:Initial Cost/MSEK": 1.86,
    "out:Running cost/(Apt SEK y)": 25949,
    "out:Running Cost over RSP/MSEK": 9.733,
    "out:LCP/MSEK": -0.291,
    "out:ROI% old": 12.05,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 201,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 66408,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 66408,
    "out:DH kr savings": 66408,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 258154,
    "out:% savings (space heating)": 20.61,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.3,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.33,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 151.2,
    "out:Total Energy Use Post PV": 151.2,
    "out:Primary Energy": 128.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.34,
    "out:CO2 Operational/m2": 50.28,
    "out:Total CO2/m2": 59.62,
    "out:Total CO2 (tons)": 164.96,
    "out:Klimatpaverkan": -2.06,
    "out:Initial Cost/MSEK": 1.929,
    "out:Running cost/(Apt SEK y)": 25252,
    "out:Running Cost over RSP/MSEK": 9.472,
    "out:LCP/MSEK": -0.099,
    "out:ROI% old": 13.55,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 234,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77476,
    "out:DH kr savings": 77476,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 247174,
    "out:% savings (space heating)": 23.99,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.33,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.3,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 148.2,
    "out:Primary Energy": 129.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.99,
    "out:CO2 Operational/m2": 49.2,
    "out:Total CO2/m2": 59.19,
    "out:Total CO2 (tons)": 163.78,
    "out:Klimatpaverkan": -3.24,
    "out:Initial Cost/MSEK": 2.078,
    "out:Running cost/(Apt SEK y)": 24730,
    "out:Running Cost over RSP/MSEK": 9.276,
    "out:LCP/MSEK": -0.052,
    "out:ROI% old": 13.93,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 259,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85777,
    "out:DH kr savings": 85777,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 258154,
    "out:% savings (space heating)": 20.61,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.3,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.33,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 144.2,
    "out:Total Energy Use Post PV": 144.2,
    "out:Primary Energy": 125.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.99,
    "out:CO2 Operational/m2": 47.76,
    "out:Total CO2/m2": 57.75,
    "out:Total CO2 (tons)": 159.79,
    "out:Klimatpaverkan": -7.23,
    "out:Initial Cost/MSEK": 2.148,
    "out:Running cost/(Apt SEK y)": 24034,
    "out:Running Cost over RSP/MSEK": 9.014,
    "out:LCP/MSEK": 0.14,
    "out:ROI% old": 15.22,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 14,
    "out:Return/kSEK/y": 292,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 96845,
    "out:DH kr savings": 96845,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 247174,
    "out:% savings (space heating)": 23.99,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.33,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.3,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 149.92,
    "out:Primary Energy": 122.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.22,
    "out:CO2 Operational/m2": 32.66,
    "out:Total CO2/m2": 51.88,
    "out:Total CO2 (tons)": 143.55,
    "out:Klimatpaverkan": -23.47,
    "out:Initial Cost/MSEK": 2.32,
    "out:Running cost/(Apt SEK y)": 25023,
    "out:Running Cost over RSP/MSEK": 9.395,
    "out:LCP/MSEK": -0.413,
    "out:ROI% old": 11.74,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 245,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 66408,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110831,
    "out:DH kr savings": 66408,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 258154,
    "out:% savings (space heating)": 20.61,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.3,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.33,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 151.2,
    "out:Total Energy Use Post PV": 145.92,
    "out:Primary Energy": 118.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.22,
    "out:CO2 Operational/m2": 31.22,
    "out:Total CO2/m2": 50.44,
    "out:Total CO2 (tons)": 139.57,
    "out:Klimatpaverkan": -27.45,
    "out:Initial Cost/MSEK": 2.39,
    "out:Running cost/(Apt SEK y)": 24327,
    "out:Running Cost over RSP/MSEK": 9.133,
    "out:LCP/MSEK": -0.221,
    "out:ROI% old": 12.96,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 278,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121899,
    "out:DH kr savings": 77476,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 247174,
    "out:% savings (space heating)": 23.99,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.33,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.3,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 142.92,
    "out:Primary Energy": 119.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.87,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 50.01,
    "out:Total CO2 (tons)": 138.38,
    "out:Klimatpaverkan": -28.64,
    "out:Initial Cost/MSEK": 2.539,
    "out:Running cost/(Apt SEK y)": 23805,
    "out:Running Cost over RSP/MSEK": 8.937,
    "out:LCP/MSEK": -0.175,
    "out:ROI% old": 13.3,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130200,
    "out:DH kr savings": 85777,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 258154,
    "out:% savings (space heating)": 20.61,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.3,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.33,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 144.2,
    "out:Total Energy Use Post PV": 138.92,
    "out:Primary Energy": 116.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.87,
    "out:CO2 Operational/m2": 28.7,
    "out:Total CO2/m2": 48.57,
    "out:Total CO2 (tons)": 134.4,
    "out:Klimatpaverkan": -32.62,
    "out:Initial Cost/MSEK": 2.608,
    "out:Running cost/(Apt SEK y)": 23108,
    "out:Running Cost over RSP/MSEK": 8.676,
    "out:LCP/MSEK": 0.018,
    "out:ROI% old": 14.38,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141268,
    "out:DH kr savings": 96845,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 247174,
    "out:% savings (space heating)": 23.99,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.33,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.3,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 146.2,
    "out:Total Energy Use Post PV": 146.2,
    "out:Primary Energy": 128.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.34,
    "out:CO2 Operational/m2": 48.48,
    "out:Total CO2/m2": 57.82,
    "out:Total CO2 (tons)": 159.98,
    "out:Klimatpaverkan": -7.04,
    "out:Initial Cost/MSEK": 1.987,
    "out:Running cost/(Apt SEK y)": 24382,
    "out:Running Cost over RSP/MSEK": 9.145,
    "out:LCP/MSEK": 0.17,
    "out:ROI% old": 15.51,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 14,
    "out:Return/kSEK/y": 276,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91311,
    "out:DH kr savings": 91311,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 258154,
    "out:% savings (space heating)": 20.61,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.3,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.33,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 142.2,
    "out:Primary Energy": 124.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.34,
    "out:CO2 Operational/m2": 47.04,
    "out:Total CO2/m2": 56.38,
    "out:Total CO2 (tons)": 156,
    "out:Klimatpaverkan": -11.02,
    "out:Initial Cost/MSEK": 2.056,
    "out:Running cost/(Apt SEK y)": 23686,
    "out:Running Cost over RSP/MSEK": 8.883,
    "out:LCP/MSEK": 0.363,
    "out:ROI% old": 16.81,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 15,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102379,
    "out:DH kr savings": 102379,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 247174,
    "out:% savings (space heating)": 23.99,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.33,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.3,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 141.2,
    "out:Total Energy Use Post PV": 141.2,
    "out:Primary Energy": 126.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.99,
    "out:CO2 Operational/m2": 46.68,
    "out:Total CO2/m2": 56.67,
    "out:Total CO2 (tons)": 156.8,
    "out:Klimatpaverkan": -10.22,
    "out:Initial Cost/MSEK": 2.205,
    "out:Running cost/(Apt SEK y)": 23511,
    "out:Running Cost over RSP/MSEK": 8.818,
    "out:LCP/MSEK": 0.279,
    "out:ROI% old": 16.09,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 14,
    "out:Return/kSEK/y": 318,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105146,
    "out:DH kr savings": 105146,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 258154,
    "out:% savings (space heating)": 20.61,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.3,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.33,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 137.2,
    "out:Total Energy Use Post PV": 137.2,
    "out:Primary Energy": 122.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.99,
    "out:CO2 Operational/m2": 45.24,
    "out:Total CO2/m2": 55.23,
    "out:Total CO2 (tons)": 152.82,
    "out:Klimatpaverkan": -14.2,
    "out:Initial Cost/MSEK": 2.274,
    "out:Running cost/(Apt SEK y)": 22815,
    "out:Running Cost over RSP/MSEK": 8.557,
    "out:LCP/MSEK": 0.471,
    "out:ROI% old": 17.24,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 15,
    "out:Return/kSEK/y": 351,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116214,
    "out:DH kr savings": 116214,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 247174,
    "out:% savings (space heating)": 23.99,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.33,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z00012200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.3,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 146.2,
    "out:Total Energy Use Post PV": 140.92,
    "out:Primary Energy": 118.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.22,
    "out:CO2 Operational/m2": 29.42,
    "out:Total CO2/m2": 48.64,
    "out:Total CO2 (tons)": 134.58,
    "out:Klimatpaverkan": -32.44,
    "out:Initial Cost/MSEK": 2.447,
    "out:Running cost/(Apt SEK y)": 23456,
    "out:Running Cost over RSP/MSEK": 8.807,
    "out:LCP/MSEK": 0.048,
    "out:ROI% old": 14.57,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 320,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135734,
    "out:DH kr savings": 91311,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 258154,
    "out:% savings (space heating)": 20.61,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.3,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.33,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 136.92,
    "out:Primary Energy": 115.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.22,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 47.2,
    "out:Total CO2 (tons)": 130.6,
    "out:Klimatpaverkan": -36.42,
    "out:Initial Cost/MSEK": 2.516,
    "out:Running cost/(Apt SEK y)": 22760,
    "out:Running Cost over RSP/MSEK": 8.545,
    "out:LCP/MSEK": 0.24,
    "out:ROI% old": 15.65,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 354,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146802,
    "out:DH kr savings": 102379,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 247174,
    "out:% savings (space heating)": 23.99,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.33,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.3,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 141.2,
    "out:Total Energy Use Post PV": 135.92,
    "out:Primary Energy": 116.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.87,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 47.49,
    "out:Total CO2 (tons)": 131.41,
    "out:Klimatpaverkan": -35.61,
    "out:Initial Cost/MSEK": 2.666,
    "out:Running cost/(Apt SEK y)": 22586,
    "out:Running Cost over RSP/MSEK": 8.48,
    "out:LCP/MSEK": 0.156,
    "out:ROI% old": 15.12,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149569,
    "out:DH kr savings": 105146,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 258154,
    "out:% savings (space heating)": 20.61,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.3,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.33,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 137.2,
    "out:Total Energy Use Post PV": 131.92,
    "out:Primary Energy": 113.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.87,
    "out:CO2 Operational/m2": 26.18,
    "out:Total CO2/m2": 46.05,
    "out:Total CO2 (tons)": 127.43,
    "out:Klimatpaverkan": -39.59,
    "out:Initial Cost/MSEK": 2.735,
    "out:Running cost/(Apt SEK y)": 21890,
    "out:Running Cost over RSP/MSEK": 8.218,
    "out:LCP/MSEK": 0.349,
    "out:ROI% old": 16.11,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160637,
    "out:DH kr savings": 116214,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 247174,
    "out:% savings (space heating)": 23.99,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.33,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z00012201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.3,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 144.26,
    "out:Total Energy Use Post PV": 144.26,
    "out:Primary Energy": 133.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.13,
    "out:CO2 Operational/m2": 45.94,
    "out:Total CO2/m2": 59.08,
    "out:Total CO2 (tons)": 163.47,
    "out:Klimatpaverkan": -3.55,
    "out:Initial Cost/MSEK": 2.504,
    "out:Running cost/(Apt SEK y)": 23570,
    "out:Running Cost over RSP/MSEK": 8.836,
    "out:LCP/MSEK": -0.038,
    "out:ROI% old": 14.07,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74401,
    "out:DH kr savings": 118981,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 258154,
    "out:% savings (space heating)": 20.61,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.3,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.33,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 140.26,
    "out:Total Energy Use Post PV": 140.26,
    "out:Primary Energy": 129.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.13,
    "out:CO2 Operational/m2": 44.5,
    "out:Total CO2/m2": 57.64,
    "out:Total CO2 (tons)": 159.48,
    "out:Klimatpaverkan": -7.54,
    "out:Initial Cost/MSEK": 2.573,
    "out:Running cost/(Apt SEK y)": 22873,
    "out:Running Cost over RSP/MSEK": 8.574,
    "out:LCP/MSEK": 0.154,
    "out:ROI% old": 15.14,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 348,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85469,
    "out:DH kr savings": 130049,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 247174,
    "out:% savings (space heating)": 23.99,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.33,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.3,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 137.26,
    "out:Primary Energy": 130.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.79,
    "out:CO2 Operational/m2": 43.42,
    "out:Total CO2/m2": 57.21,
    "out:Total CO2 (tons)": 158.3,
    "out:Klimatpaverkan": -8.72,
    "out:Initial Cost/MSEK": 2.723,
    "out:Running cost/(Apt SEK y)": 22351,
    "out:Running Cost over RSP/MSEK": 8.378,
    "out:LCP/MSEK": 0.201,
    "out:ROI% old": 15.34,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93770,
    "out:DH kr savings": 138350,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 258154,
    "out:% savings (space heating)": 20.61,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.3,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.33,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 133.26,
    "out:Total Energy Use Post PV": 133.26,
    "out:Primary Energy": 127,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.79,
    "out:CO2 Operational/m2": 41.98,
    "out:Total CO2/m2": 55.77,
    "out:Total CO2 (tons)": 154.32,
    "out:Klimatpaverkan": -12.7,
    "out:Initial Cost/MSEK": 2.792,
    "out:Running cost/(Apt SEK y)": 21655,
    "out:Running Cost over RSP/MSEK": 8.117,
    "out:LCP/MSEK": 0.393,
    "out:ROI% old": 16.3,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 407,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104838,
    "out:DH kr savings": 149418,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 247174,
    "out:% savings (space heating)": 23.99,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.33,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.3,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 144.26,
    "out:Total Energy Use Post PV": 137.64,
    "out:Primary Energy": 121.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.02,
    "out:CO2 Operational/m2": 33.52,
    "out:Total CO2/m2": 56.53,
    "out:Total CO2 (tons)": 156.43,
    "out:Klimatpaverkan": -10.59,
    "out:Initial Cost/MSEK": 2.965,
    "out:Running cost/(Apt SEK y)": 22606,
    "out:Running Cost over RSP/MSEK": 8.484,
    "out:LCP/MSEK": -0.146,
    "out:ROI% old": 13.58,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120664,
    "out:DH kr savings": 118981,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 258154,
    "out:% savings (space heating)": 20.61,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.3,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.33,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 140.26,
    "out:Total Energy Use Post PV": 133.64,
    "out:Primary Energy": 117.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.02,
    "out:CO2 Operational/m2": 32.08,
    "out:Total CO2/m2": 55.09,
    "out:Total CO2 (tons)": 152.44,
    "out:Klimatpaverkan": -14.58,
    "out:Initial Cost/MSEK": 3.034,
    "out:Running cost/(Apt SEK y)": 21910,
    "out:Running Cost over RSP/MSEK": 8.222,
    "out:LCP/MSEK": 0.046,
    "out:ROI% old": 14.5,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131732,
    "out:DH kr savings": 130049,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 247174,
    "out:% savings (space heating)": 23.99,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.33,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.3,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 130.64,
    "out:Primary Energy": 118.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.67,
    "out:CO2 Operational/m2": 31,
    "out:Total CO2/m2": 54.67,
    "out:Total CO2 (tons)": 151.26,
    "out:Klimatpaverkan": -15.76,
    "out:Initial Cost/MSEK": 3.183,
    "out:Running cost/(Apt SEK y)": 21387,
    "out:Running Cost over RSP/MSEK": 8.026,
    "out:LCP/MSEK": 0.092,
    "out:ROI% old": 14.7,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140033,
    "out:DH kr savings": 138350,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 258154,
    "out:% savings (space heating)": 20.61,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.3,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.33,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 133.26,
    "out:Total Energy Use Post PV": 126.64,
    "out:Primary Energy": 115.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.67,
    "out:CO2 Operational/m2": 29.56,
    "out:Total CO2/m2": 53.23,
    "out:Total CO2 (tons)": 147.28,
    "out:Klimatpaverkan": -19.74,
    "out:Initial Cost/MSEK": 3.252,
    "out:Running cost/(Apt SEK y)": 20691,
    "out:Running Cost over RSP/MSEK": 7.765,
    "out:LCP/MSEK": 0.285,
    "out:ROI% old": 15.54,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 453,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151101,
    "out:DH kr savings": 149418,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 247174,
    "out:% savings (space heating)": 23.99,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.33,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.3,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 135.26,
    "out:Total Energy Use Post PV": 135.26,
    "out:Primary Energy": 129.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.13,
    "out:CO2 Operational/m2": 42.7,
    "out:Total CO2/m2": 55.84,
    "out:Total CO2 (tons)": 154.5,
    "out:Klimatpaverkan": -12.52,
    "out:Initial Cost/MSEK": 2.631,
    "out:Running cost/(Apt SEK y)": 22003,
    "out:Running Cost over RSP/MSEK": 8.248,
    "out:LCP/MSEK": 0.423,
    "out:ROI% old": 16.58,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 390,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 143884,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99304,
    "out:DH kr savings": 143884,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 258154,
    "out:% savings (space heating)": 20.61,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.3,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.33,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 131.26,
    "out:Total Energy Use Post PV": 131.26,
    "out:Primary Energy": 126.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.13,
    "out:CO2 Operational/m2": 41.26,
    "out:Total CO2/m2": 54.4,
    "out:Total CO2 (tons)": 150.52,
    "out:Klimatpaverkan": -16.5,
    "out:Initial Cost/MSEK": 2.7,
    "out:Running cost/(Apt SEK y)": 21307,
    "out:Running Cost over RSP/MSEK": 7.986,
    "out:LCP/MSEK": 0.616,
    "out:ROI% old": 17.54,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 110372,
    "out:DH kr savings": 154952,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 247174,
    "out:% savings (space heating)": 23.99,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.33,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.3,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 130.26,
    "out:Total Energy Use Post PV": 130.26,
    "out:Primary Energy": 127.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.79,
    "out:CO2 Operational/m2": 40.9,
    "out:Total CO2/m2": 54.69,
    "out:Total CO2 (tons)": 151.33,
    "out:Klimatpaverkan": -15.69,
    "out:Initial Cost/MSEK": 2.849,
    "out:Running cost/(Apt SEK y)": 21132,
    "out:Running Cost over RSP/MSEK": 7.921,
    "out:LCP/MSEK": 0.532,
    "out:ROI% old": 16.95,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 432,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113139,
    "out:DH kr savings": 157719,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 258154,
    "out:% savings (space heating)": 20.61,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.3,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.33,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 126.26,
    "out:Total Energy Use Post PV": 126.26,
    "out:Primary Energy": 123.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.79,
    "out:CO2 Operational/m2": 39.46,
    "out:Total CO2/m2": 53.25,
    "out:Total CO2 (tons)": 147.34,
    "out:Klimatpaverkan": -19.68,
    "out:Initial Cost/MSEK": 2.919,
    "out:Running cost/(Apt SEK y)": 20436,
    "out:Running Cost over RSP/MSEK": 7.659,
    "out:LCP/MSEK": 0.724,
    "out:ROI% old": 17.83,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 168787,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124207,
    "out:DH kr savings": 168787,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 247174,
    "out:% savings (space heating)": 23.99,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.33,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z00012201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.3,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 135.26,
    "out:Total Energy Use Post PV": 128.64,
    "out:Primary Energy": 117.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.02,
    "out:CO2 Operational/m2": 30.28,
    "out:Total CO2/m2": 53.29,
    "out:Total CO2 (tons)": 147.46,
    "out:Klimatpaverkan": -19.56,
    "out:Initial Cost/MSEK": 3.091,
    "out:Running cost/(Apt SEK y)": 21039,
    "out:Running Cost over RSP/MSEK": 7.895,
    "out:LCP/MSEK": 0.315,
    "out:ROI% old": 15.74,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 436,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 143884,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145567,
    "out:DH kr savings": 143884,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 258154,
    "out:% savings (space heating)": 20.61,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 93.3,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.33,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 131.26,
    "out:Total Energy Use Post PV": 124.64,
    "out:Primary Energy": 114.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.02,
    "out:CO2 Operational/m2": 28.84,
    "out:Total CO2/m2": 51.85,
    "out:Total CO2 (tons)": 143.48,
    "out:Klimatpaverkan": -23.54,
    "out:Initial Cost/MSEK": 3.16,
    "out:Running cost/(Apt SEK y)": 20343,
    "out:Running Cost over RSP/MSEK": 7.634,
    "out:LCP/MSEK": 0.507,
    "out:ROI% old": 16.58,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156635,
    "out:DH kr savings": 154952,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 247174,
    "out:% savings (space heating)": 23.99,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 89.33,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.3,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 130.26,
    "out:Total Energy Use Post PV": 123.64,
    "out:Primary Energy": 115.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.67,
    "out:CO2 Operational/m2": 28.48,
    "out:Total CO2/m2": 52.15,
    "out:Total CO2 (tons)": 144.29,
    "out:Klimatpaverkan": -22.73,
    "out:Initial Cost/MSEK": 3.31,
    "out:Running cost/(Apt SEK y)": 20169,
    "out:Running Cost over RSP/MSEK": 7.569,
    "out:LCP/MSEK": 0.423,
    "out:ROI% old": 16.11,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159402,
    "out:DH kr savings": 157719,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 258154,
    "out:% savings (space heating)": 20.61,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 91.3,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00012201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.33,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 126.26,
    "out:Total Energy Use Post PV": 119.64,
    "out:Primary Energy": 111.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.67,
    "out:CO2 Operational/m2": 27.04,
    "out:Total CO2/m2": 50.71,
    "out:Total CO2 (tons)": 140.3,
    "out:Klimatpaverkan": -26.72,
    "out:Initial Cost/MSEK": 3.379,
    "out:Running cost/(Apt SEK y)": 19472,
    "out:Running Cost over RSP/MSEK": 7.307,
    "out:LCP/MSEK": 0.616,
    "out:ROI% old": 16.89,
    "out:Payback discounted": 7,
    "out:Atemp": 2767,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.88,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 168787,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170470,
    "out:DH kr savings": 168787,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 247174,
    "out:% savings (space heating)": 23.99,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.33,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 160.2,
    "out:Total Energy Use Post PV": 160.2,
    "out:Primary Energy": 136.89,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.44,
    "out:CO2 Operational/m2": 53.52,
    "out:Total CO2/m2": 66.96,
    "out:Total CO2 (tons)": 185.27,
    "out:Klimatpaverkan": 18.25,
    "out:Initial Cost/MSEK": 2.681,
    "out:Running cost/(Apt SEK y)": 26819,
    "out:Running Cost over RSP/MSEK": 10.06,
    "out:LCP/MSEK": -1.439,
    "out:ROI% old": 6.62,
    "out:Payback discounted": 18,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 6,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 52573,
    "out:DH kr savings": 52573,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 156.2,
    "out:Primary Energy": 133.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.44,
    "out:CO2 Operational/m2": 52.08,
    "out:Total CO2/m2": 65.52,
    "out:Total CO2 (tons)": 181.28,
    "out:Klimatpaverkan": 14.26,
    "out:Initial Cost/MSEK": 2.75,
    "out:Running cost/(Apt SEK y)": 26123,
    "out:Running Cost over RSP/MSEK": 9.798,
    "out:LCP/MSEK": -1.247,
    "out:ROI% old": 7.81,
    "out:Payback discounted": 15,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 7,
    "out:Return/kSEK/y": 192,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 63641,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 63641,
    "out:DH kr savings": 63641,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 154.2,
    "out:Primary Energy": 134.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.09,
    "out:CO2 Operational/m2": 51.36,
    "out:Total CO2/m2": 65.45,
    "out:Total CO2 (tons)": 181.09,
    "out:Klimatpaverkan": 14.07,
    "out:Initial Cost/MSEK": 2.9,
    "out:Running cost/(Apt SEK y)": 25775,
    "out:Running Cost over RSP/MSEK": 9.668,
    "out:LCP/MSEK": -1.266,
    "out:ROI% old": 8.05,
    "out:Payback discounted": 15,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 7,
    "out:Return/kSEK/y": 209,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69175,
    "out:DH kr savings": 69175,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 150.2,
    "out:Primary Energy": 130.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.09,
    "out:CO2 Operational/m2": 49.92,
    "out:Total CO2/m2": 64.01,
    "out:Total CO2 (tons)": 177.11,
    "out:Klimatpaverkan": 10.09,
    "out:Initial Cost/MSEK": 2.969,
    "out:Running cost/(Apt SEK y)": 25078,
    "out:Running Cost over RSP/MSEK": 9.406,
    "out:LCP/MSEK": -1.074,
    "out:ROI% old": 9.12,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 242,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 80243,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 80243,
    "out:DH kr savings": 80243,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 160.2,
    "out:Total Energy Use Post PV": 154.92,
    "out:Primary Energy": 127.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.32,
    "out:CO2 Operational/m2": 34.46,
    "out:Total CO2/m2": 57.78,
    "out:Total CO2 (tons)": 159.87,
    "out:Klimatpaverkan": -7.15,
    "out:Initial Cost/MSEK": 3.142,
    "out:Running cost/(Apt SEK y)": 25894,
    "out:Running Cost over RSP/MSEK": 9.722,
    "out:LCP/MSEK": -1.562,
    "out:ROI% old": 7.18,
    "out:Payback discounted": 17,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 6,
    "out:Return/kSEK/y": 203,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96996,
    "out:DH kr savings": 52573,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 150.92,
    "out:Primary Energy": 123.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.32,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 56.34,
    "out:Total CO2 (tons)": 155.89,
    "out:Klimatpaverkan": -11.13,
    "out:Initial Cost/MSEK": 3.211,
    "out:Running cost/(Apt SEK y)": 25197,
    "out:Running Cost over RSP/MSEK": 9.46,
    "out:LCP/MSEK": -1.369,
    "out:ROI% old": 8.19,
    "out:Payback discounted": 15,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 237,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 63641,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 108064,
    "out:DH kr savings": 63641,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 148.92,
    "out:Primary Energy": 124.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.97,
    "out:CO2 Operational/m2": 32.3,
    "out:Total CO2/m2": 56.27,
    "out:Total CO2 (tons)": 155.7,
    "out:Klimatpaverkan": -11.32,
    "out:Initial Cost/MSEK": 3.36,
    "out:Running cost/(Apt SEK y)": 24849,
    "out:Running Cost over RSP/MSEK": 9.33,
    "out:LCP/MSEK": -1.388,
    "out:ROI% old": 8.38,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113598,
    "out:DH kr savings": 69175,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 144.92,
    "out:Primary Energy": 121.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.97,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 54.83,
    "out:Total CO2 (tons)": 151.72,
    "out:Klimatpaverkan": -15.3,
    "out:Initial Cost/MSEK": 3.43,
    "out:Running cost/(Apt SEK y)": 24153,
    "out:Running Cost over RSP/MSEK": 9.068,
    "out:LCP/MSEK": -1.196,
    "out:ROI% old": 9.3,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80243,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124666,
    "out:DH kr savings": 80243,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 152.2,
    "out:Primary Energy": 133.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.44,
    "out:CO2 Operational/m2": 50.64,
    "out:Total CO2/m2": 64.08,
    "out:Total CO2 (tons)": 177.3,
    "out:Klimatpaverkan": 10.28,
    "out:Initial Cost/MSEK": 2.808,
    "out:Running cost/(Apt SEK y)": 25426,
    "out:Running Cost over RSP/MSEK": 9.537,
    "out:LCP/MSEK": -1.043,
    "out:ROI% old": 8.98,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 17.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 226,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 74709,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74709,
    "out:DH kr savings": 74709,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 147.2,
    "out:Primary Energy": 129.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.44,
    "out:CO2 Operational/m2": 48.84,
    "out:Total CO2/m2": 62.28,
    "out:Total CO2 (tons)": 172.32,
    "out:Klimatpaverkan": 5.3,
    "out:Initial Cost/MSEK": 2.877,
    "out:Running cost/(Apt SEK y)": 24556,
    "out:Running Cost over RSP/MSEK": 9.21,
    "out:LCP/MSEK": -0.786,
    "out:ROI% old": 10.39,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 267,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88544,
    "out:DH kr savings": 88544,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 146.2,
    "out:Total Energy Use Post PV": 146.2,
    "out:Primary Energy": 131.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.09,
    "out:CO2 Operational/m2": 48.48,
    "out:Total CO2/m2": 62.57,
    "out:Total CO2 (tons)": 173.13,
    "out:Klimatpaverkan": 6.11,
    "out:Initial Cost/MSEK": 3.027,
    "out:Running cost/(Apt SEK y)": 24382,
    "out:Running Cost over RSP/MSEK": 9.145,
    "out:LCP/MSEK": -0.87,
    "out:ROI% old": 10.18,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 276,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91311,
    "out:DH kr savings": 91311,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 142.2,
    "out:Primary Energy": 127.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.09,
    "out:CO2 Operational/m2": 47.04,
    "out:Total CO2/m2": 61.13,
    "out:Total CO2 (tons)": 169.14,
    "out:Klimatpaverkan": 2.12,
    "out:Initial Cost/MSEK": 3.096,
    "out:Running cost/(Apt SEK y)": 23686,
    "out:Running Cost over RSP/MSEK": 8.883,
    "out:LCP/MSEK": -0.677,
    "out:ROI% old": 11.16,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102379,
    "out:DH kr savings": 102379,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 146.92,
    "out:Primary Energy": 123.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.32,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 54.9,
    "out:Total CO2 (tons)": 151.9,
    "out:Klimatpaverkan": -15.12,
    "out:Initial Cost/MSEK": 3.268,
    "out:Running cost/(Apt SEK y)": 24501,
    "out:Running Cost over RSP/MSEK": 9.199,
    "out:LCP/MSEK": -1.165,
    "out:ROI% old": 9.19,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 270,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 74709,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119132,
    "out:DH kr savings": 74709,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 141.92,
    "out:Primary Energy": 120.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.32,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 53.1,
    "out:Total CO2 (tons)": 146.92,
    "out:Klimatpaverkan": -20.1,
    "out:Initial Cost/MSEK": 3.338,
    "out:Running cost/(Apt SEK y)": 23630,
    "out:Running Cost over RSP/MSEK": 8.872,
    "out:LCP/MSEK": -0.908,
    "out:ROI% old": 10.4,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 312,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132967,
    "out:DH kr savings": 88544,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 146.2,
    "out:Total Energy Use Post PV": 140.92,
    "out:Primary Energy": 121.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.97,
    "out:CO2 Operational/m2": 29.42,
    "out:Total CO2/m2": 53.39,
    "out:Total CO2 (tons)": 147.73,
    "out:Klimatpaverkan": -19.29,
    "out:Initial Cost/MSEK": 3.487,
    "out:Running cost/(Apt SEK y)": 23456,
    "out:Running Cost over RSP/MSEK": 8.807,
    "out:LCP/MSEK": -0.992,
    "out:ROI% old": 10.22,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 320,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135734,
    "out:DH kr savings": 91311,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 136.92,
    "out:Primary Energy": 118.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.97,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 51.95,
    "out:Total CO2 (tons)": 143.75,
    "out:Klimatpaverkan": -23.27,
    "out:Initial Cost/MSEK": 3.556,
    "out:Running cost/(Apt SEK y)": 22760,
    "out:Running Cost over RSP/MSEK": 8.545,
    "out:LCP/MSEK": -0.8,
    "out:ROI% old": 11.07,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 354,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146802,
    "out:DH kr savings": 102379,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 149.26,
    "out:Total Energy Use Post PV": 149.26,
    "out:Primary Energy": 138.1,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.23,
    "out:CO2 Operational/m2": 47.74,
    "out:Total CO2/m2": 64.98,
    "out:Total CO2 (tons)": 179.79,
    "out:Klimatpaverkan": 12.77,
    "out:Initial Cost/MSEK": 3.326,
    "out:Running cost/(Apt SEK y)": 24440,
    "out:Running Cost over RSP/MSEK": 9.163,
    "out:LCP/MSEK": -1.186,
    "out:ROI% old": 9.19,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 273,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 60566,
    "out:DH kr savings": 105146,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 145.26,
    "out:Total Energy Use Post PV": 145.26,
    "out:Primary Energy": 134.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.23,
    "out:CO2 Operational/m2": 46.3,
    "out:Total CO2/m2": 63.54,
    "out:Total CO2 (tons)": 175.81,
    "out:Klimatpaverkan": 8.79,
    "out:Initial Cost/MSEK": 3.395,
    "out:Running cost/(Apt SEK y)": 23744,
    "out:Running Cost over RSP/MSEK": 8.901,
    "out:LCP/MSEK": -0.994,
    "out:ROI% old": 10.1,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 306,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71634,
    "out:DH kr savings": 116214,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 143.26,
    "out:Primary Energy": 135.47,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.88,
    "out:CO2 Operational/m2": 45.58,
    "out:Total CO2/m2": 63.47,
    "out:Total CO2 (tons)": 175.62,
    "out:Klimatpaverkan": 8.6,
    "out:Initial Cost/MSEK": 3.544,
    "out:Running cost/(Apt SEK y)": 23396,
    "out:Running Cost over RSP/MSEK": 8.771,
    "out:LCP/MSEK": -1.013,
    "out:ROI% old": 10.2,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77168,
    "out:DH kr savings": 121748,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 139.26,
    "out:Total Energy Use Post PV": 139.26,
    "out:Primary Energy": 131.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.88,
    "out:CO2 Operational/m2": 44.14,
    "out:Total CO2/m2": 62.03,
    "out:Total CO2 (tons)": 171.63,
    "out:Klimatpaverkan": 4.61,
    "out:Initial Cost/MSEK": 3.613,
    "out:Running cost/(Apt SEK y)": 22699,
    "out:Running Cost over RSP/MSEK": 8.509,
    "out:LCP/MSEK": -0.821,
    "out:ROI% old": 11.04,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88236,
    "out:DH kr savings": 132816,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 149.26,
    "out:Total Energy Use Post PV": 142.64,
    "out:Primary Energy": 126.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.12,
    "out:CO2 Operational/m2": 35.32,
    "out:Total CO2/m2": 62.43,
    "out:Total CO2 (tons)": 172.75,
    "out:Klimatpaverkan": 5.73,
    "out:Initial Cost/MSEK": 3.786,
    "out:Running cost/(Apt SEK y)": 23476,
    "out:Running Cost over RSP/MSEK": 8.81,
    "out:LCP/MSEK": -1.295,
    "out:ROI% old": 9.4,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 319,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 106829,
    "out:DH kr savings": 105146,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 145.26,
    "out:Total Energy Use Post PV": 138.64,
    "out:Primary Energy": 122.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.12,
    "out:CO2 Operational/m2": 33.88,
    "out:Total CO2/m2": 60.99,
    "out:Total CO2 (tons)": 168.77,
    "out:Klimatpaverkan": 1.75,
    "out:Initial Cost/MSEK": 3.855,
    "out:Running cost/(Apt SEK y)": 22780,
    "out:Running Cost over RSP/MSEK": 8.549,
    "out:LCP/MSEK": -1.102,
    "out:ROI% old": 10.2,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 353,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117897,
    "out:DH kr savings": 116214,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 136.64,
    "out:Primary Energy": 123.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.77,
    "out:CO2 Operational/m2": 33.16,
    "out:Total CO2/m2": 60.92,
    "out:Total CO2 (tons)": 168.58,
    "out:Klimatpaverkan": 1.56,
    "out:Initial Cost/MSEK": 4.005,
    "out:Running cost/(Apt SEK y)": 22432,
    "out:Running Cost over RSP/MSEK": 8.418,
    "out:LCP/MSEK": -1.121,
    "out:ROI% old": 10.29,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123431,
    "out:DH kr savings": 121748,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 139.26,
    "out:Total Energy Use Post PV": 132.64,
    "out:Primary Energy": 119.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.77,
    "out:CO2 Operational/m2": 31.72,
    "out:Total CO2/m2": 59.48,
    "out:Total CO2 (tons)": 164.59,
    "out:Klimatpaverkan": -2.43,
    "out:Initial Cost/MSEK": 4.074,
    "out:Running cost/(Apt SEK y)": 21735,
    "out:Running Cost over RSP/MSEK": 8.157,
    "out:LCP/MSEK": -0.929,
    "out:ROI% old": 11.03,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 403,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134499,
    "out:DH kr savings": 132816,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 141.26,
    "out:Total Energy Use Post PV": 141.26,
    "out:Primary Energy": 134.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.23,
    "out:CO2 Operational/m2": 44.86,
    "out:Total CO2/m2": 62.1,
    "out:Total CO2 (tons)": 171.82,
    "out:Klimatpaverkan": 4.8,
    "out:Initial Cost/MSEK": 3.452,
    "out:Running cost/(Apt SEK y)": 23047,
    "out:Running Cost over RSP/MSEK": 8.64,
    "out:LCP/MSEK": -0.79,
    "out:ROI% old": 11.02,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82702,
    "out:DH kr savings": 127282,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 136.26,
    "out:Primary Energy": 130.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.23,
    "out:CO2 Operational/m2": 43.06,
    "out:Total CO2/m2": 60.3,
    "out:Total CO2 (tons)": 166.84,
    "out:Klimatpaverkan": -0.18,
    "out:Initial Cost/MSEK": 3.521,
    "out:Running cost/(Apt SEK y)": 22177,
    "out:Running Cost over RSP/MSEK": 8.313,
    "out:LCP/MSEK": -0.533,
    "out:ROI% old": 12.13,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 96537,
    "out:DH kr savings": 141117,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 135.26,
    "out:Total Energy Use Post PV": 135.26,
    "out:Primary Energy": 132.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.88,
    "out:CO2 Operational/m2": 42.7,
    "out:Total CO2/m2": 60.59,
    "out:Total CO2 (tons)": 167.65,
    "out:Klimatpaverkan": 0.63,
    "out:Initial Cost/MSEK": 3.671,
    "out:Running cost/(Apt SEK y)": 22003,
    "out:Running Cost over RSP/MSEK": 8.248,
    "out:LCP/MSEK": -0.617,
    "out:ROI% old": 11.89,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 390,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 143884,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99304,
    "out:DH kr savings": 143884,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.01,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 131.26,
    "out:Total Energy Use Post PV": 131.26,
    "out:Primary Energy": 128.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.88,
    "out:CO2 Operational/m2": 41.26,
    "out:Total CO2/m2": 59.15,
    "out:Total CO2 (tons)": 163.67,
    "out:Klimatpaverkan": -3.35,
    "out:Initial Cost/MSEK": 3.74,
    "out:Running cost/(Apt SEK y)": 21307,
    "out:Running Cost over RSP/MSEK": 7.986,
    "out:LCP/MSEK": -0.424,
    "out:ROI% old": 12.66,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 110372,
    "out:DH kr savings": 154952,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.79,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 141.26,
    "out:Total Energy Use Post PV": 134.64,
    "out:Primary Energy": 122.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.12,
    "out:CO2 Operational/m2": 32.44,
    "out:Total CO2/m2": 59.55,
    "out:Total CO2 (tons)": 164.78,
    "out:Klimatpaverkan": -2.24,
    "out:Initial Cost/MSEK": 3.913,
    "out:Running cost/(Apt SEK y)": 22084,
    "out:Running Cost over RSP/MSEK": 8.288,
    "out:LCP/MSEK": -0.898,
    "out:ROI% old": 11.01,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128965,
    "out:DH kr savings": 127282,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 129.64,
    "out:Primary Energy": 118.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.12,
    "out:CO2 Operational/m2": 30.64,
    "out:Total CO2/m2": 57.75,
    "out:Total CO2 (tons)": 159.8,
    "out:Klimatpaverkan": -7.22,
    "out:Initial Cost/MSEK": 3.982,
    "out:Running cost/(Apt SEK y)": 21213,
    "out:Running Cost over RSP/MSEK": 7.961,
    "out:LCP/MSEK": -0.641,
    "out:ROI% old": 11.99,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142800,
    "out:DH kr savings": 141117,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 135.26,
    "out:Total Energy Use Post PV": 128.64,
    "out:Primary Energy": 120.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.77,
    "out:CO2 Operational/m2": 30.28,
    "out:Total CO2/m2": 58.04,
    "out:Total CO2 (tons)": 160.61,
    "out:Klimatpaverkan": -6.41,
    "out:Initial Cost/MSEK": 4.131,
    "out:Running cost/(Apt SEK y)": 21039,
    "out:Running Cost over RSP/MSEK": 7.895,
    "out:LCP/MSEK": -0.725,
    "out:ROI% old": 11.78,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 436,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 143884,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145567,
    "out:DH kr savings": 143884,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.01,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 131.26,
    "out:Total Energy Use Post PV": 124.64,
    "out:Primary Energy": 116.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.77,
    "out:CO2 Operational/m2": 28.84,
    "out:Total CO2/m2": 56.6,
    "out:Total CO2 (tons)": 156.63,
    "out:Klimatpaverkan": -10.39,
    "out:Initial Cost/MSEK": 4.2,
    "out:Running cost/(Apt SEK y)": 20343,
    "out:Running Cost over RSP/MSEK": 7.634,
    "out:LCP/MSEK": -0.533,
    "out:ROI% old": 12.47,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156635,
    "out:DH kr savings": 154952,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.79,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 151.2,
    "out:Total Energy Use Post PV": 151.2,
    "out:Primary Energy": 129.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.75,
    "out:CO2 Operational/m2": 50.28,
    "out:Total CO2/m2": 66.03,
    "out:Total CO2 (tons)": 182.71,
    "out:Klimatpaverkan": 15.69,
    "out:Initial Cost/MSEK": 3.128,
    "out:Running cost/(Apt SEK y)": 25252,
    "out:Running Cost over RSP/MSEK": 9.472,
    "out:LCP/MSEK": -1.298,
    "out:ROI% old": 8.36,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 7,
    "out:Return/kSEK/y": 234,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77476,
    "out:DH kr savings": 77476,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 148.2,
    "out:Primary Energy": 125.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.75,
    "out:CO2 Operational/m2": 49.2,
    "out:Total CO2/m2": 64.95,
    "out:Total CO2 (tons)": 179.72,
    "out:Klimatpaverkan": 12.7,
    "out:Initial Cost/MSEK": 3.197,
    "out:Running cost/(Apt SEK y)": 24730,
    "out:Running Cost over RSP/MSEK": 9.276,
    "out:LCP/MSEK": -1.171,
    "out:ROI% old": 9.05,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 259,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85777,
    "out:DH kr savings": 85777,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 145.2,
    "out:Primary Energy": 126.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.4,
    "out:CO2 Operational/m2": 48.12,
    "out:Total CO2/m2": 64.52,
    "out:Total CO2 (tons)": 178.53,
    "out:Klimatpaverkan": 11.51,
    "out:Initial Cost/MSEK": 3.347,
    "out:Running cost/(Apt SEK y)": 24208,
    "out:Running Cost over RSP/MSEK": 9.079,
    "out:LCP/MSEK": -1.124,
    "out:ROI% old": 9.49,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 94078,
    "out:DH kr savings": 94078,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 141.2,
    "out:Total Energy Use Post PV": 141.2,
    "out:Primary Energy": 123.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.4,
    "out:CO2 Operational/m2": 46.68,
    "out:Total CO2/m2": 63.08,
    "out:Total CO2 (tons)": 174.55,
    "out:Klimatpaverkan": 7.53,
    "out:Initial Cost/MSEK": 3.416,
    "out:Running cost/(Apt SEK y)": 23511,
    "out:Running Cost over RSP/MSEK": 8.818,
    "out:LCP/MSEK": -0.932,
    "out:ROI% old": 10.39,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 318,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105146,
    "out:DH kr savings": 105146,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 151.2,
    "out:Total Energy Use Post PV": 145.92,
    "out:Primary Energy": 119.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.63,
    "out:CO2 Operational/m2": 31.22,
    "out:Total CO2/m2": 56.85,
    "out:Total CO2 (tons)": 157.31,
    "out:Klimatpaverkan": -9.71,
    "out:Initial Cost/MSEK": 3.588,
    "out:Running cost/(Apt SEK y)": 24327,
    "out:Running Cost over RSP/MSEK": 9.133,
    "out:LCP/MSEK": -1.42,
    "out:ROI% old": 8.63,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 278,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121899,
    "out:DH kr savings": 77476,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 142.92,
    "out:Primary Energy": 116.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.63,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 55.77,
    "out:Total CO2 (tons)": 154.32,
    "out:Klimatpaverkan": -12.7,
    "out:Initial Cost/MSEK": 3.658,
    "out:Running cost/(Apt SEK y)": 23805,
    "out:Running Cost over RSP/MSEK": 8.937,
    "out:LCP/MSEK": -1.293,
    "out:ROI% old": 9.23,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130200,
    "out:DH kr savings": 85777,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 139.92,
    "out:Primary Energy": 117.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.28,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 55.35,
    "out:Total CO2 (tons)": 153.14,
    "out:Klimatpaverkan": -13.88,
    "out:Initial Cost/MSEK": 3.807,
    "out:Running cost/(Apt SEK y)": 23282,
    "out:Running Cost over RSP/MSEK": 8.741,
    "out:LCP/MSEK": -1.246,
    "out:ROI% old": 9.61,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 329,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138501,
    "out:DH kr savings": 94078,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 141.2,
    "out:Total Energy Use Post PV": 135.92,
    "out:Primary Energy": 113.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.28,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 53.91,
    "out:Total CO2 (tons)": 149.16,
    "out:Klimatpaverkan": -17.86,
    "out:Initial Cost/MSEK": 3.876,
    "out:Running cost/(Apt SEK y)": 22586,
    "out:Running Cost over RSP/MSEK": 8.48,
    "out:LCP/MSEK": -1.054,
    "out:ROI% old": 10.4,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149569,
    "out:DH kr savings": 105146,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 143.2,
    "out:Primary Energy": 125.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.75,
    "out:CO2 Operational/m2": 47.4,
    "out:Total CO2/m2": 63.15,
    "out:Total CO2 (tons)": 174.74,
    "out:Klimatpaverkan": 7.72,
    "out:Initial Cost/MSEK": 3.255,
    "out:Running cost/(Apt SEK y)": 23860,
    "out:Running Cost over RSP/MSEK": 8.949,
    "out:LCP/MSEK": -0.902,
    "out:ROI% old": 10.33,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 301,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99612,
    "out:DH kr savings": 99612,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 139.2,
    "out:Total Energy Use Post PV": 139.2,
    "out:Primary Energy": 122.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.75,
    "out:CO2 Operational/m2": 45.96,
    "out:Total CO2/m2": 61.71,
    "out:Total CO2 (tons)": 170.75,
    "out:Klimatpaverkan": 3.73,
    "out:Initial Cost/MSEK": 3.324,
    "out:Running cost/(Apt SEK y)": 23163,
    "out:Running Cost over RSP/MSEK": 8.687,
    "out:LCP/MSEK": -0.709,
    "out:ROI% old": 11.24,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 334,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 110680,
    "out:DH kr savings": 110680,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 138.2,
    "out:Primary Energy": 123.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.4,
    "out:CO2 Operational/m2": 45.6,
    "out:Total CO2/m2": 62,
    "out:Total CO2 (tons)": 171.56,
    "out:Klimatpaverkan": 4.54,
    "out:Initial Cost/MSEK": 3.473,
    "out:Running cost/(Apt SEK y)": 22989,
    "out:Running Cost over RSP/MSEK": 8.622,
    "out:LCP/MSEK": -0.793,
    "out:ROI% old": 11.02,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 343,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113447,
    "out:DH kr savings": 113447,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 134.2,
    "out:Primary Energy": 120.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.4,
    "out:CO2 Operational/m2": 44.16,
    "out:Total CO2/m2": 60.56,
    "out:Total CO2 (tons)": 167.58,
    "out:Klimatpaverkan": 0.56,
    "out:Initial Cost/MSEK": 3.542,
    "out:Running cost/(Apt SEK y)": 22293,
    "out:Running Cost over RSP/MSEK": 8.361,
    "out:LCP/MSEK": -0.601,
    "out:ROI% old": 11.86,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124515,
    "out:DH kr savings": 124515,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 137.92,
    "out:Primary Energy": 116.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.63,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 53.97,
    "out:Total CO2 (tons)": 149.34,
    "out:Klimatpaverkan": -17.68,
    "out:Initial Cost/MSEK": 3.715,
    "out:Running cost/(Apt SEK y)": 22934,
    "out:Running Cost over RSP/MSEK": 8.611,
    "out:LCP/MSEK": -1.024,
    "out:ROI% old": 10.35,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 345,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144035,
    "out:DH kr savings": 99612,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 139.2,
    "out:Total Energy Use Post PV": 133.92,
    "out:Primary Energy": 112.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.63,
    "out:CO2 Operational/m2": 26.9,
    "out:Total CO2/m2": 52.53,
    "out:Total CO2 (tons)": 145.36,
    "out:Klimatpaverkan": -21.66,
    "out:Initial Cost/MSEK": 3.784,
    "out:Running cost/(Apt SEK y)": 22238,
    "out:Running Cost over RSP/MSEK": 8.349,
    "out:LCP/MSEK": -0.832,
    "out:ROI% old": 11.15,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 379,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155103,
    "out:DH kr savings": 110680,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 132.92,
    "out:Primary Energy": 114.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.28,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 52.83,
    "out:Total CO2 (tons)": 146.17,
    "out:Klimatpaverkan": -20.85,
    "out:Initial Cost/MSEK": 3.934,
    "out:Running cost/(Apt SEK y)": 22064,
    "out:Running Cost over RSP/MSEK": 8.284,
    "out:LCP/MSEK": -0.916,
    "out:ROI% old": 10.96,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 387,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157870,
    "out:DH kr savings": 113447,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 128.92,
    "out:Primary Energy": 110.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.28,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 51.39,
    "out:Total CO2 (tons)": 142.18,
    "out:Klimatpaverkan": -24.84,
    "out:Initial Cost/MSEK": 4.003,
    "out:Running cost/(Apt SEK y)": 21367,
    "out:Running Cost over RSP/MSEK": 8.022,
    "out:LCP/MSEK": -0.723,
    "out:ROI% old": 11.7,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168938,
    "out:DH kr savings": 124515,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 140.26,
    "out:Total Energy Use Post PV": 140.26,
    "out:Primary Energy": 130.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.55,
    "out:CO2 Operational/m2": 44.5,
    "out:Total CO2/m2": 64.05,
    "out:Total CO2 (tons)": 177.23,
    "out:Klimatpaverkan": 10.21,
    "out:Initial Cost/MSEK": 3.772,
    "out:Running cost/(Apt SEK y)": 22873,
    "out:Running Cost over RSP/MSEK": 8.574,
    "out:LCP/MSEK": -1.045,
    "out:ROI% old": 10.33,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 348,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85469,
    "out:DH kr savings": 130049,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 137.26,
    "out:Primary Energy": 127.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.55,
    "out:CO2 Operational/m2": 43.42,
    "out:Total CO2/m2": 62.97,
    "out:Total CO2 (tons)": 174.24,
    "out:Klimatpaverkan": 7.22,
    "out:Initial Cost/MSEK": 3.841,
    "out:Running cost/(Apt SEK y)": 22351,
    "out:Running Cost over RSP/MSEK": 8.378,
    "out:LCP/MSEK": -0.918,
    "out:ROI% old": 10.87,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93770,
    "out:DH kr savings": 138350,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 134.26,
    "out:Primary Energy": 127.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.2,
    "out:CO2 Operational/m2": 42.34,
    "out:Total CO2/m2": 62.54,
    "out:Total CO2 (tons)": 173.06,
    "out:Klimatpaverkan": 6.04,
    "out:Initial Cost/MSEK": 3.991,
    "out:Running cost/(Apt SEK y)": 21829,
    "out:Running Cost over RSP/MSEK": 8.182,
    "out:LCP/MSEK": -0.871,
    "out:ROI% old": 11.17,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102071,
    "out:DH kr savings": 146651,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 130.26,
    "out:Total Energy Use Post PV": 130.26,
    "out:Primary Energy": 124.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.2,
    "out:CO2 Operational/m2": 40.9,
    "out:Total CO2/m2": 61.1,
    "out:Total CO2 (tons)": 169.07,
    "out:Klimatpaverkan": 2.05,
    "out:Initial Cost/MSEK": 4.06,
    "out:Running cost/(Apt SEK y)": 21132,
    "out:Running Cost over RSP/MSEK": 7.921,
    "out:LCP/MSEK": -0.679,
    "out:ROI% old": 11.9,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 432,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113139,
    "out:DH kr savings": 157719,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 140.26,
    "out:Total Energy Use Post PV": 133.64,
    "out:Primary Energy": 118.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.43,
    "out:CO2 Operational/m2": 32.08,
    "out:Total CO2/m2": 61.51,
    "out:Total CO2 (tons)": 170.19,
    "out:Klimatpaverkan": 3.17,
    "out:Initial Cost/MSEK": 4.233,
    "out:Running cost/(Apt SEK y)": 21910,
    "out:Running Cost over RSP/MSEK": 8.222,
    "out:LCP/MSEK": -1.153,
    "out:ROI% old": 10.39,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131732,
    "out:DH kr savings": 130049,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 130.64,
    "out:Primary Energy": 115.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.43,
    "out:CO2 Operational/m2": 31,
    "out:Total CO2/m2": 60.43,
    "out:Total CO2 (tons)": 167.2,
    "out:Klimatpaverkan": 0.18,
    "out:Initial Cost/MSEK": 4.302,
    "out:Running cost/(Apt SEK y)": 21387,
    "out:Running Cost over RSP/MSEK": 8.026,
    "out:LCP/MSEK": -1.026,
    "out:ROI% old": 10.88,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140033,
    "out:DH kr savings": 138350,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 127.64,
    "out:Primary Energy": 115.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.08,
    "out:CO2 Operational/m2": 29.92,
    "out:Total CO2/m2": 60,
    "out:Total CO2 (tons)": 166.02,
    "out:Klimatpaverkan": -1,
    "out:Initial Cost/MSEK": 4.451,
    "out:Running cost/(Apt SEK y)": 20865,
    "out:Running Cost over RSP/MSEK": 7.83,
    "out:LCP/MSEK": -0.979,
    "out:ROI% old": 11.14,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148334,
    "out:DH kr savings": 146651,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 130.26,
    "out:Total Energy Use Post PV": 123.64,
    "out:Primary Energy": 112.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.08,
    "out:CO2 Operational/m2": 28.48,
    "out:Total CO2/m2": 58.56,
    "out:Total CO2 (tons)": 162.03,
    "out:Klimatpaverkan": -4.99,
    "out:Initial Cost/MSEK": 4.52,
    "out:Running cost/(Apt SEK y)": 20169,
    "out:Running Cost over RSP/MSEK": 7.569,
    "out:LCP/MSEK": -0.787,
    "out:ROI% old": 11.8,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159402,
    "out:DH kr savings": 157719,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 132.26,
    "out:Total Energy Use Post PV": 132.26,
    "out:Primary Energy": 126.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.55,
    "out:CO2 Operational/m2": 41.62,
    "out:Total CO2/m2": 61.17,
    "out:Total CO2 (tons)": 169.26,
    "out:Klimatpaverkan": 2.24,
    "out:Initial Cost/MSEK": 3.899,
    "out:Running cost/(Apt SEK y)": 21481,
    "out:Running Cost over RSP/MSEK": 8.052,
    "out:LCP/MSEK": -0.649,
    "out:ROI% old": 11.91,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 415,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 107605,
    "out:DH kr savings": 152185,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 128.26,
    "out:Total Energy Use Post PV": 128.26,
    "out:Primary Energy": 123.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.55,
    "out:CO2 Operational/m2": 40.18,
    "out:Total CO2/m2": 59.73,
    "out:Total CO2 (tons)": 165.28,
    "out:Klimatpaverkan": -1.74,
    "out:Initial Cost/MSEK": 3.968,
    "out:Running cost/(Apt SEK y)": 20784,
    "out:Running Cost over RSP/MSEK": 7.79,
    "out:LCP/MSEK": -0.456,
    "out:ROI% old": 12.64,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 163253,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118673,
    "out:DH kr savings": 163253,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 127.26,
    "out:Primary Energy": 124.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.2,
    "out:CO2 Operational/m2": 39.82,
    "out:Total CO2/m2": 60.02,
    "out:Total CO2 (tons)": 166.09,
    "out:Klimatpaverkan": -0.93,
    "out:Initial Cost/MSEK": 4.117,
    "out:Running cost/(Apt SEK y)": 20610,
    "out:Running Cost over RSP/MSEK": 7.725,
    "out:LCP/MSEK": -0.54,
    "out:ROI% old": 12.41,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121440,
    "out:DH kr savings": 166020,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 88.18,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 123.26,
    "out:Total Energy Use Post PV": 123.26,
    "out:Primary Energy": 121.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.2,
    "out:CO2 Operational/m2": 38.38,
    "out:Total CO2/m2": 58.58,
    "out:Total CO2 (tons)": 162.1,
    "out:Klimatpaverkan": -4.92,
    "out:Initial Cost/MSEK": 4.187,
    "out:Running cost/(Apt SEK y)": 19914,
    "out:Running Cost over RSP/MSEK": 7.463,
    "out:LCP/MSEK": -0.348,
    "out:ROI% old": 13.1,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 177088,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132508,
    "out:DH kr savings": 177088,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 84.36,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 132.26,
    "out:Total Energy Use Post PV": 125.64,
    "out:Primary Energy": 114.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.43,
    "out:CO2 Operational/m2": 29.2,
    "out:Total CO2/m2": 58.63,
    "out:Total CO2 (tons)": 162.22,
    "out:Klimatpaverkan": -4.8,
    "out:Initial Cost/MSEK": 4.359,
    "out:Running cost/(Apt SEK y)": 20517,
    "out:Running Cost over RSP/MSEK": 7.699,
    "out:LCP/MSEK": -0.757,
    "out:ROI% old": 11.81,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 461,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 153868,
    "out:DH kr savings": 152185,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 128.26,
    "out:Total Energy Use Post PV": 121.64,
    "out:Primary Energy": 111.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.43,
    "out:CO2 Operational/m2": 27.76,
    "out:Total CO2/m2": 57.19,
    "out:Total CO2 (tons)": 158.24,
    "out:Klimatpaverkan": -8.78,
    "out:Initial Cost/MSEK": 4.429,
    "out:Running cost/(Apt SEK y)": 19820,
    "out:Running Cost over RSP/MSEK": 7.438,
    "out:LCP/MSEK": -0.565,
    "out:ROI% old": 12.46,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 46.7,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 495,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163253,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164936,
    "out:DH kr savings": 163253,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 120.64,
    "out:Primary Energy": 112.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.08,
    "out:CO2 Operational/m2": 27.4,
    "out:Total CO2/m2": 57.48,
    "out:Total CO2 (tons)": 159.05,
    "out:Klimatpaverkan": -7.97,
    "out:Initial Cost/MSEK": 4.578,
    "out:Running cost/(Apt SEK y)": 19646,
    "out:Running Cost over RSP/MSEK": 7.373,
    "out:LCP/MSEK": -0.649,
    "out:ROI% old": 12.26,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167703,
    "out:DH kr savings": 166020,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 88.18,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 123.26,
    "out:Total Energy Use Post PV": 116.64,
    "out:Primary Energy": 109.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.08,
    "out:CO2 Operational/m2": 25.96,
    "out:Total CO2/m2": 56.04,
    "out:Total CO2 (tons)": 155.06,
    "out:Klimatpaverkan": -11.96,
    "out:Initial Cost/MSEK": 4.647,
    "out:Running cost/(Apt SEK y)": 18950,
    "out:Running Cost over RSP/MSEK": 7.111,
    "out:LCP/MSEK": -0.456,
    "out:ROI% old": 12.88,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 53,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 177088,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178771,
    "out:DH kr savings": 177088,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 84.36,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 148.2,
    "out:Primary Energy": 126.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.5,
    "out:CO2 Operational/m2": 49.2,
    "out:Total CO2/m2": 70.7,
    "out:Total CO2 (tons)": 195.64,
    "out:Klimatpaverkan": 28.62,
    "out:Initial Cost/MSEK": 4.222,
    "out:Running cost/(Apt SEK y)": 24730,
    "out:Running Cost over RSP/MSEK": 9.276,
    "out:LCP/MSEK": -2.195,
    "out:ROI% old": 6.86,
    "out:Payback discounted": 18,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 6,
    "out:Return/kSEK/y": 259,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85777,
    "out:DH kr savings": 85777,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 145.2,
    "out:Primary Energy": 123.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.5,
    "out:CO2 Operational/m2": 48.12,
    "out:Total CO2/m2": 69.62,
    "out:Total CO2 (tons)": 192.65,
    "out:Klimatpaverkan": 25.63,
    "out:Initial Cost/MSEK": 4.291,
    "out:Running cost/(Apt SEK y)": 24208,
    "out:Running Cost over RSP/MSEK": 9.079,
    "out:LCP/MSEK": -2.068,
    "out:ROI% old": 7.4,
    "out:Payback discounted": 16,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 7,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 94078,
    "out:DH kr savings": 94078,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 142.2,
    "out:Primary Energy": 123.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.15,
    "out:CO2 Operational/m2": 47.04,
    "out:Total CO2/m2": 69.2,
    "out:Total CO2 (tons)": 191.46,
    "out:Klimatpaverkan": 24.44,
    "out:Initial Cost/MSEK": 4.44,
    "out:Running cost/(Apt SEK y)": 23686,
    "out:Running Cost over RSP/MSEK": 8.883,
    "out:LCP/MSEK": -2.022,
    "out:ROI% old": 7.78,
    "out:Payback discounted": 16,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 7,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102379,
    "out:DH kr savings": 102379,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 138.2,
    "out:Primary Energy": 120.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.15,
    "out:CO2 Operational/m2": 45.6,
    "out:Total CO2/m2": 67.76,
    "out:Total CO2 (tons)": 187.48,
    "out:Klimatpaverkan": 20.46,
    "out:Initial Cost/MSEK": 4.509,
    "out:Running cost/(Apt SEK y)": 22989,
    "out:Running Cost over RSP/MSEK": 8.622,
    "out:LCP/MSEK": -1.83,
    "out:ROI% old": 8.49,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 343,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113447,
    "out:DH kr savings": 113447,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 142.92,
    "out:Primary Energy": 117.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.39,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 61.53,
    "out:Total CO2 (tons)": 170.24,
    "out:Klimatpaverkan": 3.22,
    "out:Initial Cost/MSEK": 4.682,
    "out:Running cost/(Apt SEK y)": 23805,
    "out:Running Cost over RSP/MSEK": 8.937,
    "out:LCP/MSEK": -2.318,
    "out:ROI% old": 7.21,
    "out:Payback discounted": 17,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 6,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130200,
    "out:DH kr savings": 85777,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 139.92,
    "out:Primary Energy": 113.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.39,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 60.45,
    "out:Total CO2 (tons)": 167.25,
    "out:Klimatpaverkan": 0.23,
    "out:Initial Cost/MSEK": 4.751,
    "out:Running cost/(Apt SEK y)": 23282,
    "out:Running Cost over RSP/MSEK": 8.741,
    "out:LCP/MSEK": -2.191,
    "out:ROI% old": 7.7,
    "out:Payback discounted": 16,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 329,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138501,
    "out:DH kr savings": 94078,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 136.92,
    "out:Primary Energy": 114.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.04,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 60.02,
    "out:Total CO2 (tons)": 166.07,
    "out:Klimatpaverkan": -0.95,
    "out:Initial Cost/MSEK": 4.901,
    "out:Running cost/(Apt SEK y)": 22760,
    "out:Running Cost over RSP/MSEK": 8.545,
    "out:LCP/MSEK": -2.144,
    "out:ROI% old": 8.04,
    "out:Payback discounted": 15,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 354,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146802,
    "out:DH kr savings": 102379,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 132.92,
    "out:Primary Energy": 111.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.04,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 58.58,
    "out:Total CO2 (tons)": 162.08,
    "out:Klimatpaverkan": -4.94,
    "out:Initial Cost/MSEK": 4.97,
    "out:Running cost/(Apt SEK y)": 22064,
    "out:Running Cost over RSP/MSEK": 8.284,
    "out:LCP/MSEK": -1.952,
    "out:ROI% old": 8.68,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 387,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157870,
    "out:DH kr savings": 113447,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 140.2,
    "out:Primary Energy": 123.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.5,
    "out:CO2 Operational/m2": 46.32,
    "out:Total CO2/m2": 67.82,
    "out:Total CO2 (tons)": 187.67,
    "out:Klimatpaverkan": 20.65,
    "out:Initial Cost/MSEK": 4.348,
    "out:Running cost/(Apt SEK y)": 23337,
    "out:Running Cost over RSP/MSEK": 8.753,
    "out:LCP/MSEK": -1.799,
    "out:ROI% old": 8.37,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 7,
    "out:Return/kSEK/y": 326,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 107913,
    "out:DH kr savings": 107913,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 136.2,
    "out:Primary Energy": 119.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.5,
    "out:CO2 Operational/m2": 44.88,
    "out:Total CO2/m2": 66.38,
    "out:Total CO2 (tons)": 183.68,
    "out:Klimatpaverkan": 16.66,
    "out:Initial Cost/MSEK": 4.417,
    "out:Running cost/(Apt SEK y)": 22641,
    "out:Running Cost over RSP/MSEK": 8.491,
    "out:LCP/MSEK": -1.607,
    "out:ROI% old": 9.09,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 359,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118981,
    "out:DH kr savings": 118981,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 135.2,
    "out:Total Energy Use Post PV": 135.2,
    "out:Primary Energy": 120.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.15,
    "out:CO2 Operational/m2": 44.52,
    "out:Total CO2/m2": 66.68,
    "out:Total CO2 (tons)": 184.49,
    "out:Klimatpaverkan": 17.47,
    "out:Initial Cost/MSEK": 4.567,
    "out:Running cost/(Apt SEK y)": 22467,
    "out:Running Cost over RSP/MSEK": 8.426,
    "out:LCP/MSEK": -1.691,
    "out:ROI% old": 9,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 368,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121748,
    "out:DH kr savings": 121748,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 131.2,
    "out:Primary Energy": 117.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.15,
    "out:CO2 Operational/m2": 43.08,
    "out:Total CO2/m2": 65.24,
    "out:Total CO2 (tons)": 180.51,
    "out:Klimatpaverkan": 13.49,
    "out:Initial Cost/MSEK": 4.636,
    "out:Running cost/(Apt SEK y)": 21771,
    "out:Running Cost over RSP/MSEK": 8.164,
    "out:LCP/MSEK": -1.499,
    "out:ROI% old": 9.67,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 401,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132816,
    "out:DH kr savings": 132816,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 134.92,
    "out:Primary Energy": 113.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.39,
    "out:CO2 Operational/m2": 27.26,
    "out:Total CO2/m2": 58.65,
    "out:Total CO2 (tons)": 162.27,
    "out:Klimatpaverkan": -4.75,
    "out:Initial Cost/MSEK": 4.809,
    "out:Running cost/(Apt SEK y)": 22412,
    "out:Running Cost over RSP/MSEK": 8.415,
    "out:LCP/MSEK": -1.922,
    "out:ROI% old": 8.58,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 370,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152336,
    "out:DH kr savings": 107913,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 130.92,
    "out:Primary Energy": 110.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.39,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 57.21,
    "out:Total CO2 (tons)": 158.29,
    "out:Klimatpaverkan": -8.73,
    "out:Initial Cost/MSEK": 4.878,
    "out:Running cost/(Apt SEK y)": 21716,
    "out:Running Cost over RSP/MSEK": 8.153,
    "out:LCP/MSEK": -1.729,
    "out:ROI% old": 9.22,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163404,
    "out:DH kr savings": 118981,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 135.2,
    "out:Total Energy Use Post PV": 129.92,
    "out:Primary Energy": 111.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.04,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 57.5,
    "out:Total CO2 (tons)": 159.1,
    "out:Klimatpaverkan": -7.92,
    "out:Initial Cost/MSEK": 5.027,
    "out:Running cost/(Apt SEK y)": 21541,
    "out:Running Cost over RSP/MSEK": 8.088,
    "out:LCP/MSEK": -1.813,
    "out:ROI% old": 9.13,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166171,
    "out:DH kr savings": 121748,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 125.92,
    "out:Primary Energy": 108.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.04,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 56.06,
    "out:Total CO2 (tons)": 155.11,
    "out:Klimatpaverkan": -11.91,
    "out:Initial Cost/MSEK": 5.097,
    "out:Running cost/(Apt SEK y)": 20845,
    "out:Running Cost over RSP/MSEK": 7.826,
    "out:LCP/MSEK": -1.621,
    "out:ROI% old": 9.74,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177239,
    "out:DH kr savings": 132816,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 137.26,
    "out:Primary Energy": 127.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.3,
    "out:CO2 Operational/m2": 43.42,
    "out:Total CO2/m2": 68.72,
    "out:Total CO2 (tons)": 190.16,
    "out:Klimatpaverkan": 23.14,
    "out:Initial Cost/MSEK": 4.866,
    "out:Running cost/(Apt SEK y)": 22351,
    "out:Running Cost over RSP/MSEK": 8.378,
    "out:LCP/MSEK": -1.942,
    "out:ROI% old": 8.58,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93770,
    "out:DH kr savings": 138350,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 134.26,
    "out:Primary Energy": 124.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.3,
    "out:CO2 Operational/m2": 42.34,
    "out:Total CO2/m2": 67.64,
    "out:Total CO2 (tons)": 187.17,
    "out:Klimatpaverkan": 20.15,
    "out:Initial Cost/MSEK": 4.935,
    "out:Running cost/(Apt SEK y)": 21829,
    "out:Running Cost over RSP/MSEK": 8.182,
    "out:LCP/MSEK": -1.816,
    "out:ROI% old": 9.03,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102071,
    "out:DH kr savings": 146651,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 131.26,
    "out:Total Energy Use Post PV": 131.26,
    "out:Primary Energy": 125.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.95,
    "out:CO2 Operational/m2": 41.26,
    "out:Total CO2/m2": 67.22,
    "out:Total CO2 (tons)": 185.99,
    "out:Klimatpaverkan": 18.97,
    "out:Initial Cost/MSEK": 5.084,
    "out:Running cost/(Apt SEK y)": 21307,
    "out:Running Cost over RSP/MSEK": 7.986,
    "out:LCP/MSEK": -1.769,
    "out:ROI% old": 9.32,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110372,
    "out:DH kr savings": 154952,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 127.26,
    "out:Primary Energy": 121.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.95,
    "out:CO2 Operational/m2": 39.82,
    "out:Total CO2/m2": 65.78,
    "out:Total CO2 (tons)": 182,
    "out:Klimatpaverkan": 14.98,
    "out:Initial Cost/MSEK": 5.154,
    "out:Running cost/(Apt SEK y)": 20610,
    "out:Running Cost over RSP/MSEK": 7.725,
    "out:LCP/MSEK": -1.576,
    "out:ROI% old": 9.92,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121440,
    "out:DH kr savings": 166020,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 130.64,
    "out:Primary Energy": 115.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.18,
    "out:CO2 Operational/m2": 31,
    "out:Total CO2/m2": 66.18,
    "out:Total CO2 (tons)": 183.12,
    "out:Klimatpaverkan": 16.1,
    "out:Initial Cost/MSEK": 5.326,
    "out:Running cost/(Apt SEK y)": 21387,
    "out:Running Cost over RSP/MSEK": 8.026,
    "out:LCP/MSEK": -2.051,
    "out:ROI% old": 8.79,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140033,
    "out:DH kr savings": 138350,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 127.64,
    "out:Primary Energy": 112.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.18,
    "out:CO2 Operational/m2": 29.92,
    "out:Total CO2/m2": 65.1,
    "out:Total CO2 (tons)": 180.13,
    "out:Klimatpaverkan": 13.11,
    "out:Initial Cost/MSEK": 5.395,
    "out:Running cost/(Apt SEK y)": 20865,
    "out:Running Cost over RSP/MSEK": 7.83,
    "out:LCP/MSEK": -1.924,
    "out:ROI% old": 9.19,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148334,
    "out:DH kr savings": 146651,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 131.26,
    "out:Total Energy Use Post PV": 124.64,
    "out:Primary Energy": 113.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.83,
    "out:CO2 Operational/m2": 28.84,
    "out:Total CO2/m2": 64.67,
    "out:Total CO2 (tons)": 178.95,
    "out:Klimatpaverkan": 11.93,
    "out:Initial Cost/MSEK": 5.545,
    "out:Running cost/(Apt SEK y)": 20343,
    "out:Running Cost over RSP/MSEK": 7.634,
    "out:LCP/MSEK": -1.877,
    "out:ROI% old": 9.45,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156635,
    "out:DH kr savings": 154952,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 120.64,
    "out:Primary Energy": 110,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.83,
    "out:CO2 Operational/m2": 27.4,
    "out:Total CO2/m2": 63.23,
    "out:Total CO2 (tons)": 174.96,
    "out:Klimatpaverkan": 7.94,
    "out:Initial Cost/MSEK": 5.614,
    "out:Running cost/(Apt SEK y)": 19646,
    "out:Running Cost over RSP/MSEK": 7.373,
    "out:LCP/MSEK": -1.685,
    "out:ROI% old": 10,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167703,
    "out:DH kr savings": 166020,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 129.26,
    "out:Total Energy Use Post PV": 129.26,
    "out:Primary Energy": 124.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.3,
    "out:CO2 Operational/m2": 40.54,
    "out:Total CO2/m2": 65.84,
    "out:Total CO2 (tons)": 182.19,
    "out:Klimatpaverkan": 15.17,
    "out:Initial Cost/MSEK": 4.993,
    "out:Running cost/(Apt SEK y)": 20958,
    "out:Running Cost over RSP/MSEK": 7.855,
    "out:LCP/MSEK": -1.546,
    "out:ROI% old": 9.86,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 440,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115906,
    "out:DH kr savings": 160486,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 125.26,
    "out:Total Energy Use Post PV": 125.26,
    "out:Primary Energy": 121.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.3,
    "out:CO2 Operational/m2": 39.1,
    "out:Total CO2/m2": 64.4,
    "out:Total CO2 (tons)": 178.21,
    "out:Klimatpaverkan": 11.19,
    "out:Initial Cost/MSEK": 5.062,
    "out:Running cost/(Apt SEK y)": 20262,
    "out:Running Cost over RSP/MSEK": 7.594,
    "out:LCP/MSEK": -1.354,
    "out:ROI% old": 10.46,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 474,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 171554,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126974,
    "out:DH kr savings": 171554,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 124.26,
    "out:Total Energy Use Post PV": 124.26,
    "out:Primary Energy": 121.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.95,
    "out:CO2 Operational/m2": 38.74,
    "out:Total CO2/m2": 64.7,
    "out:Total CO2 (tons)": 179.01,
    "out:Klimatpaverkan": 11.99,
    "out:Initial Cost/MSEK": 5.211,
    "out:Running cost/(Apt SEK y)": 20088,
    "out:Running Cost over RSP/MSEK": 7.529,
    "out:LCP/MSEK": -1.438,
    "out:ROI% old": 10.34,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 174321,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 129741,
    "out:DH kr savings": 174321,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.2,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 120.26,
    "out:Total Energy Use Post PV": 120.26,
    "out:Primary Energy": 118.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.95,
    "out:CO2 Operational/m2": 37.3,
    "out:Total CO2/m2": 63.26,
    "out:Total CO2 (tons)": 175.03,
    "out:Klimatpaverkan": 8.01,
    "out:Initial Cost/MSEK": 5.28,
    "out:Running cost/(Apt SEK y)": 19392,
    "out:Running Cost over RSP/MSEK": 7.267,
    "out:LCP/MSEK": -1.246,
    "out:ROI% old": 10.92,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185389,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140809,
    "out:DH kr savings": 185389,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.51,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 129.26,
    "out:Total Energy Use Post PV": 122.64,
    "out:Primary Energy": 112.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.18,
    "out:CO2 Operational/m2": 28.12,
    "out:Total CO2/m2": 63.3,
    "out:Total CO2 (tons)": 175.15,
    "out:Klimatpaverkan": 8.13,
    "out:Initial Cost/MSEK": 5.453,
    "out:Running cost/(Apt SEK y)": 19995,
    "out:Running Cost over RSP/MSEK": 7.503,
    "out:LCP/MSEK": -1.655,
    "out:ROI% old": 9.95,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162169,
    "out:DH kr savings": 160486,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 125.26,
    "out:Total Energy Use Post PV": 118.64,
    "out:Primary Energy": 109.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.18,
    "out:CO2 Operational/m2": 26.68,
    "out:Total CO2/m2": 61.86,
    "out:Total CO2 (tons)": 171.17,
    "out:Klimatpaverkan": 4.15,
    "out:Initial Cost/MSEK": 5.522,
    "out:Running cost/(Apt SEK y)": 19298,
    "out:Running Cost over RSP/MSEK": 7.242,
    "out:LCP/MSEK": -1.462,
    "out:ROI% old": 10.5,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 171554,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173237,
    "out:DH kr savings": 171554,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 124.26,
    "out:Total Energy Use Post PV": 117.64,
    "out:Primary Energy": 109.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.83,
    "out:CO2 Operational/m2": 26.32,
    "out:Total CO2/m2": 62.15,
    "out:Total CO2 (tons)": 171.97,
    "out:Klimatpaverkan": 4.95,
    "out:Initial Cost/MSEK": 5.672,
    "out:Running cost/(Apt SEK y)": 19124,
    "out:Running Cost over RSP/MSEK": 7.176,
    "out:LCP/MSEK": -1.546,
    "out:ROI% old": 10.39,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 528,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 174321,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176004,
    "out:DH kr savings": 174321,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.2,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 120.26,
    "out:Total Energy Use Post PV": 113.64,
    "out:Primary Energy": 106.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.83,
    "out:CO2 Operational/m2": 24.88,
    "out:Total CO2/m2": 60.71,
    "out:Total CO2 (tons)": 167.99,
    "out:Klimatpaverkan": 0.97,
    "out:Initial Cost/MSEK": 5.741,
    "out:Running cost/(Apt SEK y)": 18428,
    "out:Running Cost over RSP/MSEK": 6.915,
    "out:LCP/MSEK": -1.354,
    "out:ROI% old": 10.92,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 57,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 562,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185389,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 187072,
    "out:DH kr savings": 185389,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.51,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.21,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 147.2,
    "out:Primary Energy": 124.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.39,
    "out:CO2 Operational/m2": 48.84,
    "out:Total CO2/m2": 63.23,
    "out:Total CO2 (tons)": 174.95,
    "out:Klimatpaverkan": 7.93,
    "out:Initial Cost/MSEK": 2.831,
    "out:Running cost/(Apt SEK y)": 24556,
    "out:Running Cost over RSP/MSEK": 9.21,
    "out:LCP/MSEK": -0.74,
    "out:ROI% old": 10.55,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 267,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88544,
    "out:DH kr savings": 88544,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 235765,
    "out:% savings (space heating)": 27.5,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.21,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.61,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 143.2,
    "out:Primary Energy": 121.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.39,
    "out:CO2 Operational/m2": 47.4,
    "out:Total CO2/m2": 61.79,
    "out:Total CO2 (tons)": 170.97,
    "out:Klimatpaverkan": 3.95,
    "out:Initial Cost/MSEK": 2.9,
    "out:Running cost/(Apt SEK y)": 23860,
    "out:Running Cost over RSP/MSEK": 8.949,
    "out:LCP/MSEK": -0.547,
    "out:ROI% old": 11.59,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 301,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99612,
    "out:DH kr savings": 99612,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 225803,
    "out:% savings (space heating)": 30.56,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.61,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.21,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 140.2,
    "out:Primary Energy": 122.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.04,
    "out:CO2 Operational/m2": 46.32,
    "out:Total CO2/m2": 61.36,
    "out:Total CO2 (tons)": 169.78,
    "out:Klimatpaverkan": 2.76,
    "out:Initial Cost/MSEK": 3.05,
    "out:Running cost/(Apt SEK y)": 23337,
    "out:Running Cost over RSP/MSEK": 8.753,
    "out:LCP/MSEK": -0.501,
    "out:ROI% old": 11.94,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 326,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 107913,
    "out:DH kr savings": 107913,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 235765,
    "out:% savings (space heating)": 27.5,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.21,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.61,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 136.2,
    "out:Primary Energy": 119.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.04,
    "out:CO2 Operational/m2": 44.88,
    "out:Total CO2/m2": 59.92,
    "out:Total CO2 (tons)": 165.8,
    "out:Klimatpaverkan": -1.22,
    "out:Initial Cost/MSEK": 3.119,
    "out:Running cost/(Apt SEK y)": 22641,
    "out:Running Cost over RSP/MSEK": 8.491,
    "out:LCP/MSEK": -0.308,
    "out:ROI% old": 12.87,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 359,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118981,
    "out:DH kr savings": 118981,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 225803,
    "out:% savings (space heating)": 30.56,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.61,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.21,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 141.92,
    "out:Primary Energy": 115.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.27,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 54.05,
    "out:Total CO2 (tons)": 149.55,
    "out:Klimatpaverkan": -17.47,
    "out:Initial Cost/MSEK": 3.292,
    "out:Running cost/(Apt SEK y)": 23630,
    "out:Running Cost over RSP/MSEK": 8.872,
    "out:LCP/MSEK": -0.862,
    "out:ROI% old": 10.55,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 312,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132967,
    "out:DH kr savings": 88544,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 235765,
    "out:% savings (space heating)": 27.5,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.21,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.61,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 137.92,
    "out:Primary Energy": 112.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.27,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 52.61,
    "out:Total CO2 (tons)": 145.57,
    "out:Klimatpaverkan": -21.45,
    "out:Initial Cost/MSEK": 3.361,
    "out:Running cost/(Apt SEK y)": 22934,
    "out:Running Cost over RSP/MSEK": 8.611,
    "out:LCP/MSEK": -0.67,
    "out:ROI% old": 11.44,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 345,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 144035,
    "out:DH kr savings": 99612,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 225803,
    "out:% savings (space heating)": 30.56,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.61,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.21,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 134.92,
    "out:Primary Energy": 112.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.92,
    "out:CO2 Operational/m2": 27.26,
    "out:Total CO2/m2": 52.18,
    "out:Total CO2 (tons)": 144.39,
    "out:Klimatpaverkan": -22.63,
    "out:Initial Cost/MSEK": 3.51,
    "out:Running cost/(Apt SEK y)": 22412,
    "out:Running Cost over RSP/MSEK": 8.415,
    "out:LCP/MSEK": -0.623,
    "out:ROI% old": 11.75,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 370,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152336,
    "out:DH kr savings": 107913,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 235765,
    "out:% savings (space heating)": 27.5,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.21,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.61,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 130.92,
    "out:Primary Energy": 109.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.92,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 50.74,
    "out:Total CO2 (tons)": 140.4,
    "out:Klimatpaverkan": -26.62,
    "out:Initial Cost/MSEK": 3.579,
    "out:Running cost/(Apt SEK y)": 21716,
    "out:Running Cost over RSP/MSEK": 8.153,
    "out:LCP/MSEK": -0.431,
    "out:ROI% old": 12.57,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163404,
    "out:DH kr savings": 118981,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 225803,
    "out:% savings (space heating)": 30.56,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.61,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.21,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 138.2,
    "out:Primary Energy": 121.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.39,
    "out:CO2 Operational/m2": 45.6,
    "out:Total CO2/m2": 59.99,
    "out:Total CO2 (tons)": 165.98,
    "out:Klimatpaverkan": -1.04,
    "out:Initial Cost/MSEK": 2.958,
    "out:Running cost/(Apt SEK y)": 22989,
    "out:Running Cost over RSP/MSEK": 8.622,
    "out:LCP/MSEK": -0.278,
    "out:ROI% old": 12.94,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 343,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113447,
    "out:DH kr savings": 113447,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 235765,
    "out:% savings (space heating)": 27.5,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.21,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.61,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 134.2,
    "out:Primary Energy": 118.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.39,
    "out:CO2 Operational/m2": 44.16,
    "out:Total CO2/m2": 58.55,
    "out:Total CO2 (tons)": 162,
    "out:Klimatpaverkan": -5.02,
    "out:Initial Cost/MSEK": 3.027,
    "out:Running cost/(Apt SEK y)": 22293,
    "out:Running Cost over RSP/MSEK": 8.361,
    "out:LCP/MSEK": -0.086,
    "out:ROI% old": 13.88,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124515,
    "out:DH kr savings": 124515,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 225803,
    "out:% savings (space heating)": 30.56,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.61,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.21,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 133.2,
    "out:Primary Energy": 119.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.04,
    "out:CO2 Operational/m2": 43.8,
    "out:Total CO2/m2": 58.84,
    "out:Total CO2 (tons)": 162.81,
    "out:Klimatpaverkan": -4.21,
    "out:Initial Cost/MSEK": 3.176,
    "out:Running cost/(Apt SEK y)": 22119,
    "out:Running Cost over RSP/MSEK": 8.295,
    "out:LCP/MSEK": -0.17,
    "out:ROI% old": 13.52,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 384,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127282,
    "out:DH kr savings": 127282,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 235765,
    "out:% savings (space heating)": 27.5,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.21,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.61,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 129.2,
    "out:Primary Energy": 116.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.04,
    "out:CO2 Operational/m2": 42.36,
    "out:Total CO2/m2": 57.4,
    "out:Total CO2 (tons)": 158.82,
    "out:Klimatpaverkan": -8.2,
    "out:Initial Cost/MSEK": 3.246,
    "out:Running cost/(Apt SEK y)": 21422,
    "out:Running Cost over RSP/MSEK": 8.034,
    "out:LCP/MSEK": 0.023,
    "out:ROI% old": 14.38,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 418,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138350,
    "out:DH kr savings": 138350,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 225803,
    "out:% savings (space heating)": 30.56,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.61,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.21,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 132.92,
    "out:Primary Energy": 111.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.27,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 50.81,
    "out:Total CO2 (tons)": 140.59,
    "out:Klimatpaverkan": -26.43,
    "out:Initial Cost/MSEK": 3.418,
    "out:Running cost/(Apt SEK y)": 22064,
    "out:Running Cost over RSP/MSEK": 8.284,
    "out:LCP/MSEK": -0.4,
    "out:ROI% old": 12.61,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 387,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157870,
    "out:DH kr savings": 113447,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 235765,
    "out:% savings (space heating)": 27.5,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.21,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.61,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 128.92,
    "out:Primary Energy": 108.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.27,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 49.37,
    "out:Total CO2 (tons)": 136.61,
    "out:Klimatpaverkan": -30.41,
    "out:Initial Cost/MSEK": 3.488,
    "out:Running cost/(Apt SEK y)": 21367,
    "out:Running Cost over RSP/MSEK": 8.022,
    "out:LCP/MSEK": -0.208,
    "out:ROI% old": 13.43,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168938,
    "out:DH kr savings": 124515,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 225803,
    "out:% savings (space heating)": 30.56,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.61,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.21,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 127.92,
    "out:Primary Energy": 109.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.92,
    "out:CO2 Operational/m2": 24.74,
    "out:Total CO2/m2": 49.66,
    "out:Total CO2 (tons)": 137.41,
    "out:Klimatpaverkan": -29.61,
    "out:Initial Cost/MSEK": 3.637,
    "out:Running cost/(Apt SEK y)": 21193,
    "out:Running Cost over RSP/MSEK": 7.957,
    "out:LCP/MSEK": -0.292,
    "out:ROI% old": 13.14,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 429,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171705,
    "out:DH kr savings": 127282,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 235765,
    "out:% savings (space heating)": 27.5,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.21,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.61,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 123.92,
    "out:Primary Energy": 106.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.92,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 48.22,
    "out:Total CO2 (tons)": 133.43,
    "out:Klimatpaverkan": -33.59,
    "out:Initial Cost/MSEK": 3.706,
    "out:Running cost/(Apt SEK y)": 20497,
    "out:Running Cost over RSP/MSEK": 7.696,
    "out:LCP/MSEK": -0.1,
    "out:ROI% old": 13.9,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 182773,
    "out:DH kr savings": 138350,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 225803,
    "out:% savings (space heating)": 30.56,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.61,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.21,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 136.26,
    "out:Primary Energy": 126.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.18,
    "out:CO2 Operational/m2": 43.06,
    "out:Total CO2/m2": 61.25,
    "out:Total CO2 (tons)": 169.47,
    "out:Klimatpaverkan": 2.45,
    "out:Initial Cost/MSEK": 3.475,
    "out:Running cost/(Apt SEK y)": 22177,
    "out:Running Cost over RSP/MSEK": 8.313,
    "out:LCP/MSEK": -0.487,
    "out:ROI% old": 12.29,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96537,
    "out:DH kr savings": 141117,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 235765,
    "out:% savings (space heating)": 27.5,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.21,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.61,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 132.26,
    "out:Total Energy Use Post PV": 132.26,
    "out:Primary Energy": 122.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.18,
    "out:CO2 Operational/m2": 41.62,
    "out:Total CO2/m2": 59.81,
    "out:Total CO2 (tons)": 165.49,
    "out:Klimatpaverkan": -1.53,
    "out:Initial Cost/MSEK": 3.545,
    "out:Running cost/(Apt SEK y)": 21481,
    "out:Running Cost over RSP/MSEK": 8.052,
    "out:LCP/MSEK": -0.294,
    "out:ROI% old": 13.1,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 415,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107605,
    "out:DH kr savings": 152185,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 225803,
    "out:% savings (space heating)": 30.56,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.61,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.21,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 129.26,
    "out:Total Energy Use Post PV": 129.26,
    "out:Primary Energy": 123.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.84,
    "out:CO2 Operational/m2": 40.54,
    "out:Total CO2/m2": 59.38,
    "out:Total CO2 (tons)": 164.31,
    "out:Klimatpaverkan": -2.71,
    "out:Initial Cost/MSEK": 3.694,
    "out:Running cost/(Apt SEK y)": 20958,
    "out:Running Cost over RSP/MSEK": 7.855,
    "out:LCP/MSEK": -0.248,
    "out:ROI% old": 13.33,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 440,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115906,
    "out:DH kr savings": 160486,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 235765,
    "out:% savings (space heating)": 27.5,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.21,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.61,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 125.26,
    "out:Total Energy Use Post PV": 125.26,
    "out:Primary Energy": 120.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.84,
    "out:CO2 Operational/m2": 39.1,
    "out:Total CO2/m2": 57.94,
    "out:Total CO2 (tons)": 160.32,
    "out:Klimatpaverkan": -6.7,
    "out:Initial Cost/MSEK": 3.763,
    "out:Running cost/(Apt SEK y)": 20262,
    "out:Running Cost over RSP/MSEK": 7.594,
    "out:LCP/MSEK": -0.055,
    "out:ROI% old": 14.08,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 474,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 171554,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126974,
    "out:DH kr savings": 171554,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 225803,
    "out:% savings (space heating)": 30.56,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.61,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.21,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 129.64,
    "out:Primary Energy": 114.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.07,
    "out:CO2 Operational/m2": 30.64,
    "out:Total CO2/m2": 58.7,
    "out:Total CO2 (tons)": 162.43,
    "out:Klimatpaverkan": -4.59,
    "out:Initial Cost/MSEK": 3.936,
    "out:Running cost/(Apt SEK y)": 21213,
    "out:Running Cost over RSP/MSEK": 7.961,
    "out:LCP/MSEK": -0.595,
    "out:ROI% old": 12.13,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142800,
    "out:DH kr savings": 141117,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 235765,
    "out:% savings (space heating)": 27.5,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.21,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.61,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 132.26,
    "out:Total Energy Use Post PV": 125.64,
    "out:Primary Energy": 110.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.07,
    "out:CO2 Operational/m2": 29.2,
    "out:Total CO2/m2": 57.26,
    "out:Total CO2 (tons)": 158.45,
    "out:Klimatpaverkan": -8.57,
    "out:Initial Cost/MSEK": 4.005,
    "out:Running cost/(Apt SEK y)": 20517,
    "out:Running Cost over RSP/MSEK": 7.699,
    "out:LCP/MSEK": -0.403,
    "out:ROI% old": 12.85,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 461,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 153868,
    "out:DH kr savings": 152185,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 225803,
    "out:% savings (space heating)": 30.56,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.61,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.21,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 129.26,
    "out:Total Energy Use Post PV": 122.64,
    "out:Primary Energy": 111.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.72,
    "out:CO2 Operational/m2": 28.12,
    "out:Total CO2/m2": 56.84,
    "out:Total CO2 (tons)": 157.26,
    "out:Klimatpaverkan": -9.76,
    "out:Initial Cost/MSEK": 4.154,
    "out:Running cost/(Apt SEK y)": 19995,
    "out:Running Cost over RSP/MSEK": 7.503,
    "out:LCP/MSEK": -0.356,
    "out:ROI% old": 13.06,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162169,
    "out:DH kr savings": 160486,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 235765,
    "out:% savings (space heating)": 27.5,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.21,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.61,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 125.26,
    "out:Total Energy Use Post PV": 118.64,
    "out:Primary Energy": 108.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.72,
    "out:CO2 Operational/m2": 26.68,
    "out:Total CO2/m2": 55.4,
    "out:Total CO2 (tons)": 153.28,
    "out:Klimatpaverkan": -13.74,
    "out:Initial Cost/MSEK": 4.224,
    "out:Running cost/(Apt SEK y)": 19298,
    "out:Running Cost over RSP/MSEK": 7.242,
    "out:LCP/MSEK": -0.164,
    "out:ROI% old": 13.73,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 171554,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173237,
    "out:DH kr savings": 171554,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 225803,
    "out:% savings (space heating)": 30.56,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.61,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.21,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 127.26,
    "out:Primary Energy": 122.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.18,
    "out:CO2 Operational/m2": 39.82,
    "out:Total CO2/m2": 58.01,
    "out:Total CO2 (tons)": 160.51,
    "out:Klimatpaverkan": -6.51,
    "out:Initial Cost/MSEK": 3.602,
    "out:Running cost/(Apt SEK y)": 20610,
    "out:Running Cost over RSP/MSEK": 7.725,
    "out:LCP/MSEK": -0.025,
    "out:ROI% old": 14.19,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121440,
    "out:DH kr savings": 166020,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 235765,
    "out:% savings (space heating)": 27.5,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.21,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.61,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 123.26,
    "out:Total Energy Use Post PV": 123.26,
    "out:Primary Energy": 119.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.18,
    "out:CO2 Operational/m2": 38.38,
    "out:Total CO2/m2": 56.57,
    "out:Total CO2 (tons)": 156.52,
    "out:Klimatpaverkan": -10.5,
    "out:Initial Cost/MSEK": 3.671,
    "out:Running cost/(Apt SEK y)": 19914,
    "out:Running Cost over RSP/MSEK": 7.463,
    "out:LCP/MSEK": 0.167,
    "out:ROI% old": 14.94,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 177088,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132508,
    "out:DH kr savings": 177088,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 225803,
    "out:% savings (space heating)": 30.56,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.61,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.21,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 122.26,
    "out:Total Energy Use Post PV": 122.26,
    "out:Primary Energy": 120.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.84,
    "out:CO2 Operational/m2": 38.02,
    "out:Total CO2/m2": 56.86,
    "out:Total CO2 (tons)": 157.33,
    "out:Klimatpaverkan": -9.69,
    "out:Initial Cost/MSEK": 3.821,
    "out:Running cost/(Apt SEK y)": 19740,
    "out:Running Cost over RSP/MSEK": 7.398,
    "out:LCP/MSEK": 0.083,
    "out:ROI% old": 14.6,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 46.7,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 499,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 179855,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135275,
    "out:DH kr savings": 179855,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 235765,
    "out:% savings (space heating)": 27.5,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.21,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.61,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 118.26,
    "out:Total Energy Use Post PV": 118.26,
    "out:Primary Energy": 116.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.84,
    "out:CO2 Operational/m2": 36.58,
    "out:Total CO2/m2": 55.42,
    "out:Total CO2 (tons)": 153.35,
    "out:Klimatpaverkan": -13.67,
    "out:Initial Cost/MSEK": 3.89,
    "out:Running cost/(Apt SEK y)": 19043,
    "out:Running Cost over RSP/MSEK": 7.136,
    "out:LCP/MSEK": 0.276,
    "out:ROI% old": 15.3,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190923,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146343,
    "out:DH kr savings": 190923,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 225803,
    "out:% savings (space heating)": 30.56,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.61,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.21,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 120.64,
    "out:Primary Energy": 110.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.07,
    "out:CO2 Operational/m2": 27.4,
    "out:Total CO2/m2": 55.46,
    "out:Total CO2 (tons)": 153.47,
    "out:Klimatpaverkan": -13.55,
    "out:Initial Cost/MSEK": 4.063,
    "out:Running cost/(Apt SEK y)": 19646,
    "out:Running Cost over RSP/MSEK": 7.373,
    "out:LCP/MSEK": -0.133,
    "out:ROI% old": 13.82,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167703,
    "out:DH kr savings": 166020,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 235765,
    "out:% savings (space heating)": 27.5,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.21,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.61,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 123.26,
    "out:Total Energy Use Post PV": 116.64,
    "out:Primary Energy": 107.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.07,
    "out:CO2 Operational/m2": 25.96,
    "out:Total CO2/m2": 54.02,
    "out:Total CO2 (tons)": 149.48,
    "out:Klimatpaverkan": -17.54,
    "out:Initial Cost/MSEK": 4.132,
    "out:Running cost/(Apt SEK y)": 18950,
    "out:Running Cost over RSP/MSEK": 7.111,
    "out:LCP/MSEK": 0.059,
    "out:ROI% old": 14.49,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 53,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 177088,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178771,
    "out:DH kr savings": 177088,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 225803,
    "out:% savings (space heating)": 30.56,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.61,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.21,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 122.26,
    "out:Total Energy Use Post PV": 115.64,
    "out:Primary Energy": 108.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.72,
    "out:CO2 Operational/m2": 25.6,
    "out:Total CO2/m2": 54.32,
    "out:Total CO2 (tons)": 150.29,
    "out:Klimatpaverkan": -16.73,
    "out:Initial Cost/MSEK": 4.281,
    "out:Running cost/(Apt SEK y)": 18776,
    "out:Running Cost over RSP/MSEK": 7.046,
    "out:LCP/MSEK": -0.025,
    "out:ROI% old": 14.2,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 54.3,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 545,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 179855,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181538,
    "out:DH kr savings": 179855,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 235765,
    "out:% savings (space heating)": 27.5,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.21,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.61,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 118.26,
    "out:Total Energy Use Post PV": 111.64,
    "out:Primary Energy": 105.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.72,
    "out:CO2 Operational/m2": 24.16,
    "out:Total CO2/m2": 52.88,
    "out:Total CO2 (tons)": 146.31,
    "out:Klimatpaverkan": -20.71,
    "out:Initial Cost/MSEK": 4.35,
    "out:Running cost/(Apt SEK y)": 18080,
    "out:Running Cost over RSP/MSEK": 6.784,
    "out:LCP/MSEK": 0.167,
    "out:ROI% old": 14.83,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 578,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190923,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192606,
    "out:DH kr savings": 190923,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 225803,
    "out:% savings (space heating)": 30.56,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.61,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.09,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 137.2,
    "out:Total Energy Use Post PV": 137.2,
    "out:Primary Energy": 116.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.7,
    "out:CO2 Operational/m2": 45.24,
    "out:Total CO2/m2": 61.94,
    "out:Total CO2 (tons)": 171.39,
    "out:Klimatpaverkan": 4.37,
    "out:Initial Cost/MSEK": 3.278,
    "out:Running cost/(Apt SEK y)": 22815,
    "out:Running Cost over RSP/MSEK": 8.557,
    "out:LCP/MSEK": -0.533,
    "out:ROI% old": 11.96,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 351,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116214,
    "out:DH kr savings": 116214,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 210530,
    "out:% savings (space heating)": 35.26,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.09,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.9,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 134.2,
    "out:Primary Energy": 114.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.7,
    "out:CO2 Operational/m2": 44.16,
    "out:Total CO2/m2": 60.86,
    "out:Total CO2 (tons)": 168.4,
    "out:Klimatpaverkan": 1.38,
    "out:Initial Cost/MSEK": 3.347,
    "out:Running cost/(Apt SEK y)": 22293,
    "out:Running Cost over RSP/MSEK": 8.361,
    "out:LCP/MSEK": -0.406,
    "out:ROI% old": 12.55,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124515,
    "out:DH kr savings": 124515,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201715,
    "out:% savings (space heating)": 37.97,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.9,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.09,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 131.2,
    "out:Primary Energy": 114.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.35,
    "out:CO2 Operational/m2": 43.08,
    "out:Total CO2/m2": 60.43,
    "out:Total CO2 (tons)": 167.22,
    "out:Klimatpaverkan": 0.2,
    "out:Initial Cost/MSEK": 3.496,
    "out:Running cost/(Apt SEK y)": 21771,
    "out:Running Cost over RSP/MSEK": 8.164,
    "out:LCP/MSEK": -0.359,
    "out:ROI% old": 12.82,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 401,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132816,
    "out:DH kr savings": 132816,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 210530,
    "out:% savings (space heating)": 35.26,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.09,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.9,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 128.2,
    "out:Total Energy Use Post PV": 128.2,
    "out:Primary Energy": 111.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.35,
    "out:CO2 Operational/m2": 42,
    "out:Total CO2/m2": 59.35,
    "out:Total CO2 (tons)": 164.23,
    "out:Klimatpaverkan": -2.79,
    "out:Initial Cost/MSEK": 3.566,
    "out:Running cost/(Apt SEK y)": 21248,
    "out:Running Cost over RSP/MSEK": 7.968,
    "out:LCP/MSEK": -0.232,
    "out:ROI% old": 13.36,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 426,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141117,
    "out:DH kr savings": 141117,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201715,
    "out:% savings (space heating)": 37.97,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.9,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.09,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 137.2,
    "out:Total Energy Use Post PV": 131.92,
    "out:Primary Energy": 107.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.58,
    "out:CO2 Operational/m2": 26.18,
    "out:Total CO2/m2": 52.76,
    "out:Total CO2 (tons)": 146,
    "out:Klimatpaverkan": -21.02,
    "out:Initial Cost/MSEK": 3.738,
    "out:Running cost/(Apt SEK y)": 21890,
    "out:Running Cost over RSP/MSEK": 8.218,
    "out:LCP/MSEK": -0.655,
    "out:ROI% old": 11.78,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160637,
    "out:DH kr savings": 116214,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 210530,
    "out:% savings (space heating)": 35.26,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.09,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.9,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 128.92,
    "out:Primary Energy": 104.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.58,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 51.68,
    "out:Total CO2 (tons)": 143.01,
    "out:Klimatpaverkan": -24.01,
    "out:Initial Cost/MSEK": 3.807,
    "out:Running cost/(Apt SEK y)": 21367,
    "out:Running Cost over RSP/MSEK": 8.022,
    "out:LCP/MSEK": -0.528,
    "out:ROI% old": 12.3,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168938,
    "out:DH kr savings": 124515,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201715,
    "out:% savings (space heating)": 37.97,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.9,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.09,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 125.92,
    "out:Primary Energy": 104.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.24,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 51.26,
    "out:Total CO2 (tons)": 141.83,
    "out:Klimatpaverkan": -25.19,
    "out:Initial Cost/MSEK": 3.957,
    "out:Running cost/(Apt SEK y)": 20845,
    "out:Running Cost over RSP/MSEK": 7.826,
    "out:LCP/MSEK": -0.481,
    "out:ROI% old": 12.55,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 177239,
    "out:DH kr savings": 132816,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 210530,
    "out:% savings (space heating)": 35.26,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.09,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.9,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 128.2,
    "out:Total Energy Use Post PV": 122.92,
    "out:Primary Energy": 101.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.24,
    "out:CO2 Operational/m2": 22.94,
    "out:Total CO2/m2": 50.18,
    "out:Total CO2 (tons)": 138.84,
    "out:Klimatpaverkan": -28.18,
    "out:Initial Cost/MSEK": 4.026,
    "out:Running cost/(Apt SEK y)": 20323,
    "out:Running Cost over RSP/MSEK": 7.63,
    "out:LCP/MSEK": -0.354,
    "out:ROI% old": 13.03,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 471,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185540,
    "out:DH kr savings": 141117,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201715,
    "out:% savings (space heating)": 37.97,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.9,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.09,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 129.2,
    "out:Primary Energy": 113.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.7,
    "out:CO2 Operational/m2": 42.36,
    "out:Total CO2/m2": 59.06,
    "out:Total CO2 (tons)": 163.42,
    "out:Klimatpaverkan": -3.6,
    "out:Initial Cost/MSEK": 3.405,
    "out:Running cost/(Apt SEK y)": 21422,
    "out:Running Cost over RSP/MSEK": 8.034,
    "out:LCP/MSEK": -0.136,
    "out:ROI% old": 13.71,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 418,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138350,
    "out:DH kr savings": 138350,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 210530,
    "out:% savings (space heating)": 35.26,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.09,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.9,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 126.2,
    "out:Primary Energy": 110.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.7,
    "out:CO2 Operational/m2": 41.28,
    "out:Total CO2/m2": 57.98,
    "out:Total CO2 (tons)": 160.44,
    "out:Klimatpaverkan": -6.58,
    "out:Initial Cost/MSEK": 3.474,
    "out:Running cost/(Apt SEK y)": 20900,
    "out:Running Cost over RSP/MSEK": 7.838,
    "out:LCP/MSEK": -0.009,
    "out:ROI% old": 14.25,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 443,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146651,
    "out:DH kr savings": 146651,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201715,
    "out:% savings (space heating)": 37.97,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.9,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.09,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 124.2,
    "out:Total Energy Use Post PV": 124.2,
    "out:Primary Energy": 111.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.35,
    "out:CO2 Operational/m2": 40.56,
    "out:Total CO2/m2": 57.91,
    "out:Total CO2 (tons)": 160.25,
    "out:Klimatpaverkan": -6.77,
    "out:Initial Cost/MSEK": 3.623,
    "out:Running cost/(Apt SEK y)": 20552,
    "out:Running Cost over RSP/MSEK": 7.707,
    "out:LCP/MSEK": -0.028,
    "out:ROI% old": 14.17,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 460,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152185,
    "out:DH kr savings": 152185,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 210530,
    "out:% savings (space heating)": 35.26,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.09,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.9,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 120.2,
    "out:Total Energy Use Post PV": 120.2,
    "out:Primary Energy": 108.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.35,
    "out:CO2 Operational/m2": 39.12,
    "out:Total CO2/m2": 56.47,
    "out:Total CO2 (tons)": 156.26,
    "out:Klimatpaverkan": -10.76,
    "out:Initial Cost/MSEK": 3.692,
    "out:Running cost/(Apt SEK y)": 19856,
    "out:Running Cost over RSP/MSEK": 7.445,
    "out:LCP/MSEK": 0.164,
    "out:ROI% old": 14.92,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 493,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163253,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163253,
    "out:DH kr savings": 163253,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201715,
    "out:% savings (space heating)": 37.97,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.9,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.09,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 123.92,
    "out:Primary Energy": 103.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.58,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 49.88,
    "out:Total CO2 (tons)": 138.03,
    "out:Klimatpaverkan": -28.99,
    "out:Initial Cost/MSEK": 3.865,
    "out:Running cost/(Apt SEK y)": 20497,
    "out:Running Cost over RSP/MSEK": 7.696,
    "out:LCP/MSEK": -0.259,
    "out:ROI% old": 13.33,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182773,
    "out:DH kr savings": 138350,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 210530,
    "out:% savings (space heating)": 35.26,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.09,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.9,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 120.92,
    "out:Primary Energy": 101.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.58,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 48.8,
    "out:Total CO2 (tons)": 135.04,
    "out:Klimatpaverkan": -31.98,
    "out:Initial Cost/MSEK": 3.934,
    "out:Running cost/(Apt SEK y)": 19975,
    "out:Running Cost over RSP/MSEK": 7.499,
    "out:LCP/MSEK": -0.132,
    "out:ROI% old": 13.81,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191074,
    "out:DH kr savings": 146651,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201715,
    "out:% savings (space heating)": 37.97,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.9,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.09,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 124.2,
    "out:Total Energy Use Post PV": 118.92,
    "out:Primary Energy": 101.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.24,
    "out:CO2 Operational/m2": 21.5,
    "out:Total CO2/m2": 48.74,
    "out:Total CO2 (tons)": 134.85,
    "out:Klimatpaverkan": -32.17,
    "out:Initial Cost/MSEK": 4.084,
    "out:Running cost/(Apt SEK y)": 19626,
    "out:Running Cost over RSP/MSEK": 7.369,
    "out:LCP/MSEK": -0.15,
    "out:ROI% old": 13.76,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196608,
    "out:DH kr savings": 152185,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 210530,
    "out:% savings (space heating)": 35.26,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.09,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.9,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 120.2,
    "out:Total Energy Use Post PV": 114.92,
    "out:Primary Energy": 98.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.24,
    "out:CO2 Operational/m2": 20.06,
    "out:Total CO2/m2": 47.3,
    "out:Total CO2 (tons)": 130.87,
    "out:Klimatpaverkan": -36.15,
    "out:Initial Cost/MSEK": 4.153,
    "out:Running cost/(Apt SEK y)": 18930,
    "out:Running Cost over RSP/MSEK": 7.107,
    "out:LCP/MSEK": 0.042,
    "out:ROI% old": 14.43,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 537,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 163253,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207676,
    "out:DH kr savings": 163253,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201715,
    "out:% savings (space heating)": 37.97,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.9,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.09,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 126.26,
    "out:Total Energy Use Post PV": 126.26,
    "out:Primary Energy": 118.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.5,
    "out:CO2 Operational/m2": 39.46,
    "out:Total CO2/m2": 59.96,
    "out:Total CO2 (tons)": 165.92,
    "out:Klimatpaverkan": -1.1,
    "out:Initial Cost/MSEK": 3.922,
    "out:Running cost/(Apt SEK y)": 20436,
    "out:Running Cost over RSP/MSEK": 7.659,
    "out:LCP/MSEK": -0.28,
    "out:ROI% old": 13.27,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 168787,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124207,
    "out:DH kr savings": 168787,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 210530,
    "out:% savings (space heating)": 35.26,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.09,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.9,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 123.26,
    "out:Total Energy Use Post PV": 123.26,
    "out:Primary Energy": 115.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.5,
    "out:CO2 Operational/m2": 38.38,
    "out:Total CO2/m2": 58.88,
    "out:Total CO2 (tons)": 162.93,
    "out:Klimatpaverkan": -4.09,
    "out:Initial Cost/MSEK": 3.991,
    "out:Running cost/(Apt SEK y)": 19914,
    "out:Running Cost over RSP/MSEK": 7.463,
    "out:LCP/MSEK": -0.153,
    "out:ROI% old": 13.74,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 177088,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132508,
    "out:DH kr savings": 177088,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201715,
    "out:% savings (space heating)": 37.97,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.9,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.09,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 120.26,
    "out:Total Energy Use Post PV": 120.26,
    "out:Primary Energy": 115.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.15,
    "out:CO2 Operational/m2": 37.3,
    "out:Total CO2/m2": 58.45,
    "out:Total CO2 (tons)": 161.74,
    "out:Klimatpaverkan": -5.28,
    "out:Initial Cost/MSEK": 4.141,
    "out:Running cost/(Apt SEK y)": 19392,
    "out:Running Cost over RSP/MSEK": 7.267,
    "out:LCP/MSEK": -0.106,
    "out:ROI% old": 13.92,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185389,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140809,
    "out:DH kr savings": 185389,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 210530,
    "out:% savings (space heating)": 35.26,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.09,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.9,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 117.26,
    "out:Total Energy Use Post PV": 117.26,
    "out:Primary Energy": 112.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.15,
    "out:CO2 Operational/m2": 36.22,
    "out:Total CO2/m2": 57.37,
    "out:Total CO2 (tons)": 158.76,
    "out:Klimatpaverkan": -8.26,
    "out:Initial Cost/MSEK": 4.21,
    "out:Running cost/(Apt SEK y)": 18869,
    "out:Running Cost over RSP/MSEK": 7.071,
    "out:LCP/MSEK": 0.021,
    "out:ROI% old": 14.36,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 53,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 540,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 193690,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149110,
    "out:DH kr savings": 193690,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201715,
    "out:% savings (space heating)": 37.97,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.9,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.09,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 126.26,
    "out:Total Energy Use Post PV": 119.64,
    "out:Primary Energy": 106.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.38,
    "out:CO2 Operational/m2": 27.04,
    "out:Total CO2/m2": 57.42,
    "out:Total CO2 (tons)": 158.88,
    "out:Klimatpaverkan": -8.14,
    "out:Initial Cost/MSEK": 4.383,
    "out:Running cost/(Apt SEK y)": 19472,
    "out:Running Cost over RSP/MSEK": 7.307,
    "out:LCP/MSEK": -0.388,
    "out:ROI% old": 13.02,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 168787,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 170470,
    "out:DH kr savings": 168787,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 210530,
    "out:% savings (space heating)": 35.26,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.09,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.9,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 123.26,
    "out:Total Energy Use Post PV": 116.64,
    "out:Primary Energy": 103.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.38,
    "out:CO2 Operational/m2": 25.96,
    "out:Total CO2/m2": 56.34,
    "out:Total CO2 (tons)": 155.89,
    "out:Klimatpaverkan": -11.13,
    "out:Initial Cost/MSEK": 4.452,
    "out:Running cost/(Apt SEK y)": 18950,
    "out:Running Cost over RSP/MSEK": 7.111,
    "out:LCP/MSEK": -0.261,
    "out:ROI% old": 13.45,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 53,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 177088,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178771,
    "out:DH kr savings": 177088,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201715,
    "out:% savings (space heating)": 37.97,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.9,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.09,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 120.26,
    "out:Total Energy Use Post PV": 113.64,
    "out:Primary Energy": 103.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.03,
    "out:CO2 Operational/m2": 24.88,
    "out:Total CO2/m2": 55.91,
    "out:Total CO2 (tons)": 154.7,
    "out:Klimatpaverkan": -12.32,
    "out:Initial Cost/MSEK": 4.601,
    "out:Running cost/(Apt SEK y)": 18428,
    "out:Running Cost over RSP/MSEK": 6.915,
    "out:LCP/MSEK": -0.214,
    "out:ROI% old": 13.62,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 57,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 562,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185389,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 187072,
    "out:DH kr savings": 185389,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 210530,
    "out:% savings (space heating)": 35.26,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.09,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.9,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 117.26,
    "out:Total Energy Use Post PV": 110.64,
    "out:Primary Energy": 100.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.03,
    "out:CO2 Operational/m2": 23.8,
    "out:Total CO2/m2": 54.83,
    "out:Total CO2 (tons)": 151.72,
    "out:Klimatpaverkan": -15.3,
    "out:Initial Cost/MSEK": 4.67,
    "out:Running cost/(Apt SEK y)": 17905,
    "out:Running Cost over RSP/MSEK": 6.719,
    "out:LCP/MSEK": -0.087,
    "out:ROI% old": 14.02,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 61.3,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 587,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 193690,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195373,
    "out:DH kr savings": 193690,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201715,
    "out:% savings (space heating)": 37.97,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.9,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.09,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 118.26,
    "out:Total Energy Use Post PV": 118.26,
    "out:Primary Energy": 114.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.5,
    "out:CO2 Operational/m2": 36.58,
    "out:Total CO2/m2": 57.08,
    "out:Total CO2 (tons)": 157.95,
    "out:Klimatpaverkan": -9.07,
    "out:Initial Cost/MSEK": 4.049,
    "out:Running cost/(Apt SEK y)": 19043,
    "out:Running Cost over RSP/MSEK": 7.136,
    "out:LCP/MSEK": 0.117,
    "out:ROI% old": 14.7,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190923,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146343,
    "out:DH kr savings": 190923,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 210530,
    "out:% savings (space heating)": 35.26,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.09,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.9,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 115.26,
    "out:Total Energy Use Post PV": 115.26,
    "out:Primary Energy": 111.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.5,
    "out:CO2 Operational/m2": 35.5,
    "out:Total CO2/m2": 56,
    "out:Total CO2 (tons)": 154.96,
    "out:Klimatpaverkan": -12.06,
    "out:Initial Cost/MSEK": 4.118,
    "out:Running cost/(Apt SEK y)": 18521,
    "out:Running Cost over RSP/MSEK": 6.94,
    "out:LCP/MSEK": 0.243,
    "out:ROI% old": 15.13,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154644,
    "out:DH kr savings": 199224,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201715,
    "out:% savings (space heating)": 37.97,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.9,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.09,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 113.26,
    "out:Total Energy Use Post PV": 113.26,
    "out:Primary Energy": 112.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.15,
    "out:CO2 Operational/m2": 34.78,
    "out:Total CO2/m2": 55.94,
    "out:Total CO2 (tons)": 154.77,
    "out:Klimatpaverkan": -12.25,
    "out:Initial Cost/MSEK": 4.267,
    "out:Running cost/(Apt SEK y)": 18173,
    "out:Running Cost over RSP/MSEK": 6.81,
    "out:LCP/MSEK": 0.225,
    "out:ROI% old": 15.04,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 58.4,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 574,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 204758,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160178,
    "out:DH kr savings": 204758,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 210530,
    "out:% savings (space heating)": 35.26,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.09,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.9,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 109.26,
    "out:Total Energy Use Post PV": 109.26,
    "out:Primary Energy": 109.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.15,
    "out:CO2 Operational/m2": 33.34,
    "out:Total CO2/m2": 54.5,
    "out:Total CO2 (tons)": 150.79,
    "out:Klimatpaverkan": -16.23,
    "out:Initial Cost/MSEK": 4.337,
    "out:Running cost/(Apt SEK y)": 17477,
    "out:Running Cost over RSP/MSEK": 6.548,
    "out:LCP/MSEK": 0.417,
    "out:ROI% old": 15.66,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 64.2,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 607,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 215826,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171246,
    "out:DH kr savings": 215826,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201715,
    "out:% savings (space heating)": 37.97,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.91,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.09,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 118.26,
    "out:Total Energy Use Post PV": 111.64,
    "out:Primary Energy": 102.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.38,
    "out:CO2 Operational/m2": 24.16,
    "out:Total CO2/m2": 54.54,
    "out:Total CO2 (tons)": 150.91,
    "out:Klimatpaverkan": -16.11,
    "out:Initial Cost/MSEK": 4.509,
    "out:Running cost/(Apt SEK y)": 18080,
    "out:Running Cost over RSP/MSEK": 6.784,
    "out:LCP/MSEK": 0.008,
    "out:ROI% old": 14.31,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 578,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190923,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192606,
    "out:DH kr savings": 190923,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 210530,
    "out:% savings (space heating)": 35.26,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.09,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.9,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 115.26,
    "out:Total Energy Use Post PV": 108.64,
    "out:Primary Energy": 99.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.38,
    "out:CO2 Operational/m2": 23.08,
    "out:Total CO2/m2": 53.46,
    "out:Total CO2 (tons)": 147.92,
    "out:Klimatpaverkan": -19.1,
    "out:Initial Cost/MSEK": 4.578,
    "out:Running cost/(Apt SEK y)": 17557,
    "out:Running Cost over RSP/MSEK": 6.588,
    "out:LCP/MSEK": 0.135,
    "out:ROI% old": 14.71,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 64.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 603,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200907,
    "out:DH kr savings": 199224,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201715,
    "out:% savings (space heating)": 37.97,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.9,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.09,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 113.26,
    "out:Total Energy Use Post PV": 106.64,
    "out:Primary Energy": 100.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.03,
    "out:CO2 Operational/m2": 22.36,
    "out:Total CO2/m2": 53.39,
    "out:Total CO2 (tons)": 147.73,
    "out:Klimatpaverkan": -19.29,
    "out:Initial Cost/MSEK": 4.728,
    "out:Running cost/(Apt SEK y)": 17209,
    "out:Running Cost over RSP/MSEK": 6.457,
    "out:LCP/MSEK": 0.117,
    "out:ROI% old": 14.64,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 67.3,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 620,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 204758,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206441,
    "out:DH kr savings": 204758,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 210530,
    "out:% savings (space heating)": 35.26,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.09,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.9,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 109.26,
    "out:Total Energy Use Post PV": 102.64,
    "out:Primary Energy": 97.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.03,
    "out:CO2 Operational/m2": 20.92,
    "out:Total CO2/m2": 51.95,
    "out:Total CO2 (tons)": 143.75,
    "out:Klimatpaverkan": -23.27,
    "out:Initial Cost/MSEK": 4.797,
    "out:Running cost/(Apt SEK y)": 16513,
    "out:Running Cost over RSP/MSEK": 6.196,
    "out:LCP/MSEK": 0.309,
    "out:ROI% old": 15.21,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 73.8,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 653,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 215826,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217509,
    "out:DH kr savings": 215826,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201715,
    "out:% savings (space heating)": 37.97,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.91,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 134.2,
    "out:Primary Energy": 114.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.45,
    "out:CO2 Operational/m2": 44.16,
    "out:Total CO2/m2": 66.61,
    "out:Total CO2 (tons)": 184.32,
    "out:Klimatpaverkan": 17.3,
    "out:Initial Cost/MSEK": 4.372,
    "out:Running cost/(Apt SEK y)": 22293,
    "out:Running Cost over RSP/MSEK": 8.361,
    "out:LCP/MSEK": -1.43,
    "out:ROI% old": 9.61,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124515,
    "out:DH kr savings": 124515,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201957,
    "out:% savings (space heating)": 37.89,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.99,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.94,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 131.2,
    "out:Primary Energy": 111.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.45,
    "out:CO2 Operational/m2": 43.08,
    "out:Total CO2/m2": 65.53,
    "out:Total CO2 (tons)": 181.33,
    "out:Klimatpaverkan": 14.31,
    "out:Initial Cost/MSEK": 4.441,
    "out:Running cost/(Apt SEK y)": 21771,
    "out:Running Cost over RSP/MSEK": 8.164,
    "out:LCP/MSEK": -1.303,
    "out:ROI% old": 10.09,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 401,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132816,
    "out:DH kr savings": 132816,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 193532,
    "out:% savings (space heating)": 40.49,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 69.94,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 128.2,
    "out:Total Energy Use Post PV": 128.2,
    "out:Primary Energy": 111.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.11,
    "out:CO2 Operational/m2": 42,
    "out:Total CO2/m2": 65.11,
    "out:Total CO2 (tons)": 180.15,
    "out:Klimatpaverkan": 13.13,
    "out:Initial Cost/MSEK": 4.59,
    "out:Running cost/(Apt SEK y)": 21248,
    "out:Running Cost over RSP/MSEK": 7.968,
    "out:LCP/MSEK": -1.257,
    "out:ROI% old": 10.37,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 426,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141117,
    "out:DH kr savings": 141117,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201957,
    "out:% savings (space heating)": 37.89,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.99,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.94,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 125.2,
    "out:Total Energy Use Post PV": 125.2,
    "out:Primary Energy": 108.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.11,
    "out:CO2 Operational/m2": 40.92,
    "out:Total CO2/m2": 64.03,
    "out:Total CO2 (tons)": 177.16,
    "out:Klimatpaverkan": 10.14,
    "out:Initial Cost/MSEK": 4.659,
    "out:Running cost/(Apt SEK y)": 20726,
    "out:Running Cost over RSP/MSEK": 7.772,
    "out:LCP/MSEK": -1.13,
    "out:ROI% old": 10.82,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 451,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149418,
    "out:DH kr savings": 149418,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 193532,
    "out:% savings (space heating)": 40.49,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 69.94,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 128.92,
    "out:Primary Energy": 104.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.34,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 57.44,
    "out:Total CO2 (tons)": 158.93,
    "out:Klimatpaverkan": -8.09,
    "out:Initial Cost/MSEK": 4.832,
    "out:Running cost/(Apt SEK y)": 21367,
    "out:Running Cost over RSP/MSEK": 8.022,
    "out:LCP/MSEK": -1.552,
    "out:ROI% old": 9.7,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168938,
    "out:DH kr savings": 124515,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201957,
    "out:% savings (space heating)": 37.89,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.99,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.94,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 125.92,
    "out:Primary Energy": 101.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.34,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 56.36,
    "out:Total CO2 (tons)": 155.94,
    "out:Klimatpaverkan": -11.08,
    "out:Initial Cost/MSEK": 4.901,
    "out:Running cost/(Apt SEK y)": 20845,
    "out:Running Cost over RSP/MSEK": 7.826,
    "out:LCP/MSEK": -1.426,
    "out:ROI% old": 10.13,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177239,
    "out:DH kr savings": 132816,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 193532,
    "out:% savings (space heating)": 40.49,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 69.94,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 128.2,
    "out:Total Energy Use Post PV": 122.92,
    "out:Primary Energy": 101.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.99,
    "out:CO2 Operational/m2": 22.94,
    "out:Total CO2/m2": 55.93,
    "out:Total CO2 (tons)": 154.76,
    "out:Klimatpaverkan": -12.26,
    "out:Initial Cost/MSEK": 5.051,
    "out:Running cost/(Apt SEK y)": 20323,
    "out:Running Cost over RSP/MSEK": 7.63,
    "out:LCP/MSEK": -1.379,
    "out:ROI% old": 10.39,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 471,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185540,
    "out:DH kr savings": 141117,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201957,
    "out:% savings (space heating)": 37.89,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.99,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.94,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 125.2,
    "out:Total Energy Use Post PV": 119.92,
    "out:Primary Energy": 99.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.99,
    "out:CO2 Operational/m2": 21.86,
    "out:Total CO2/m2": 54.85,
    "out:Total CO2 (tons)": 151.77,
    "out:Klimatpaverkan": -15.25,
    "out:Initial Cost/MSEK": 5.12,
    "out:Running cost/(Apt SEK y)": 19800,
    "out:Running Cost over RSP/MSEK": 7.434,
    "out:LCP/MSEK": -1.252,
    "out:ROI% old": 10.79,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 496,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 193841,
    "out:DH kr savings": 149418,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 193532,
    "out:% savings (space heating)": 40.49,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 69.94,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 126.2,
    "out:Primary Energy": 110.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.45,
    "out:CO2 Operational/m2": 41.28,
    "out:Total CO2/m2": 63.73,
    "out:Total CO2 (tons)": 176.35,
    "out:Klimatpaverkan": 9.33,
    "out:Initial Cost/MSEK": 4.498,
    "out:Running cost/(Apt SEK y)": 20900,
    "out:Running Cost over RSP/MSEK": 7.838,
    "out:LCP/MSEK": -1.034,
    "out:ROI% old": 11,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 443,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146651,
    "out:DH kr savings": 146651,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201957,
    "out:% savings (space heating)": 37.89,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.99,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.94,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 123.2,
    "out:Total Energy Use Post PV": 123.2,
    "out:Primary Energy": 107.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.45,
    "out:CO2 Operational/m2": 40.2,
    "out:Total CO2/m2": 62.65,
    "out:Total CO2 (tons)": 173.37,
    "out:Klimatpaverkan": 6.35,
    "out:Initial Cost/MSEK": 4.567,
    "out:Running cost/(Apt SEK y)": 20378,
    "out:Running Cost over RSP/MSEK": 7.642,
    "out:LCP/MSEK": -0.907,
    "out:ROI% old": 11.45,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154952,
    "out:DH kr savings": 154952,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 193532,
    "out:% savings (space heating)": 40.49,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 69.94,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 120.2,
    "out:Total Energy Use Post PV": 120.2,
    "out:Primary Energy": 108.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.11,
    "out:CO2 Operational/m2": 39.12,
    "out:Total CO2/m2": 62.23,
    "out:Total CO2 (tons)": 172.18,
    "out:Klimatpaverkan": 5.16,
    "out:Initial Cost/MSEK": 4.717,
    "out:Running cost/(Apt SEK y)": 19856,
    "out:Running Cost over RSP/MSEK": 7.445,
    "out:LCP/MSEK": -0.86,
    "out:ROI% old": 11.68,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 493,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 163253,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163253,
    "out:DH kr savings": 163253,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201957,
    "out:% savings (space heating)": 37.89,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.99,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.94,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 117.2,
    "out:Total Energy Use Post PV": 117.2,
    "out:Primary Energy": 105.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.11,
    "out:CO2 Operational/m2": 38.04,
    "out:Total CO2/m2": 61.15,
    "out:Total CO2 (tons)": 169.19,
    "out:Klimatpaverkan": 2.17,
    "out:Initial Cost/MSEK": 4.786,
    "out:Running cost/(Apt SEK y)": 19333,
    "out:Running Cost over RSP/MSEK": 7.249,
    "out:LCP/MSEK": -0.733,
    "out:ROI% old": 12.1,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 53,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 518,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 171554,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171554,
    "out:DH kr savings": 171554,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 193532,
    "out:% savings (space heating)": 40.49,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 69.94,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01001200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 120.92,
    "out:Primary Energy": 101.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.34,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 54.56,
    "out:Total CO2 (tons)": 150.96,
    "out:Klimatpaverkan": -16.06,
    "out:Initial Cost/MSEK": 4.959,
    "out:Running cost/(Apt SEK y)": 19975,
    "out:Running Cost over RSP/MSEK": 7.499,
    "out:LCP/MSEK": -1.156,
    "out:ROI% old": 10.95,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191074,
    "out:DH kr savings": 146651,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201957,
    "out:% savings (space heating)": 37.89,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.99,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.94,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 123.2,
    "out:Total Energy Use Post PV": 117.92,
    "out:Primary Energy": 98.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.34,
    "out:CO2 Operational/m2": 21.14,
    "out:Total CO2/m2": 53.48,
    "out:Total CO2 (tons)": 147.97,
    "out:Klimatpaverkan": -19.05,
    "out:Initial Cost/MSEK": 5.028,
    "out:Running cost/(Apt SEK y)": 19452,
    "out:Running Cost over RSP/MSEK": 7.303,
    "out:LCP/MSEK": -1.029,
    "out:ROI% old": 11.36,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 512,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199375,
    "out:DH kr savings": 154952,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 193532,
    "out:% savings (space heating)": 40.49,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 69.94,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 120.2,
    "out:Total Energy Use Post PV": 114.92,
    "out:Primary Energy": 99.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.99,
    "out:CO2 Operational/m2": 20.06,
    "out:Total CO2/m2": 53.05,
    "out:Total CO2 (tons)": 146.79,
    "out:Klimatpaverkan": -20.23,
    "out:Initial Cost/MSEK": 5.177,
    "out:Running cost/(Apt SEK y)": 18930,
    "out:Running Cost over RSP/MSEK": 7.107,
    "out:LCP/MSEK": -0.983,
    "out:ROI% old": 11.57,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 537,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163253,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207676,
    "out:DH kr savings": 163253,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201957,
    "out:% savings (space heating)": 37.89,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.99,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.94,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 117.2,
    "out:Total Energy Use Post PV": 111.92,
    "out:Primary Energy": 96.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.99,
    "out:CO2 Operational/m2": 18.98,
    "out:Total CO2/m2": 51.97,
    "out:Total CO2 (tons)": 143.8,
    "out:Klimatpaverkan": -23.22,
    "out:Initial Cost/MSEK": 5.246,
    "out:Running cost/(Apt SEK y)": 18408,
    "out:Running Cost over RSP/MSEK": 6.911,
    "out:LCP/MSEK": -0.856,
    "out:ROI% old": 11.96,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 563,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 171554,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215977,
    "out:DH kr savings": 171554,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 193532,
    "out:% savings (space heating)": 40.49,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 69.94,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01001201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 123.26,
    "out:Total Energy Use Post PV": 123.26,
    "out:Primary Energy": 115.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.25,
    "out:CO2 Operational/m2": 38.38,
    "out:Total CO2/m2": 64.64,
    "out:Total CO2 (tons)": 178.85,
    "out:Klimatpaverkan": 11.83,
    "out:Initial Cost/MSEK": 5.016,
    "out:Running cost/(Apt SEK y)": 19914,
    "out:Running Cost over RSP/MSEK": 7.463,
    "out:LCP/MSEK": -1.177,
    "out:ROI% old": 10.93,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 177088,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132508,
    "out:DH kr savings": 177088,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201957,
    "out:% savings (space heating)": 37.89,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.99,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.94,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 120.26,
    "out:Total Energy Use Post PV": 120.26,
    "out:Primary Energy": 112.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.25,
    "out:CO2 Operational/m2": 37.3,
    "out:Total CO2/m2": 63.56,
    "out:Total CO2 (tons)": 175.86,
    "out:Klimatpaverkan": 8.84,
    "out:Initial Cost/MSEK": 5.085,
    "out:Running cost/(Apt SEK y)": 19392,
    "out:Running Cost over RSP/MSEK": 7.267,
    "out:LCP/MSEK": -1.05,
    "out:ROI% old": 11.33,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185389,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140809,
    "out:DH kr savings": 185389,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 193532,
    "out:% savings (space heating)": 40.49,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 69.94,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 117.26,
    "out:Total Energy Use Post PV": 117.26,
    "out:Primary Energy": 112.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.9,
    "out:CO2 Operational/m2": 36.22,
    "out:Total CO2/m2": 63.13,
    "out:Total CO2 (tons)": 174.67,
    "out:Klimatpaverkan": 7.65,
    "out:Initial Cost/MSEK": 5.234,
    "out:Running cost/(Apt SEK y)": 18869,
    "out:Running Cost over RSP/MSEK": 7.071,
    "out:LCP/MSEK": -1.004,
    "out:ROI% old": 11.55,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 53,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 540,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193690,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149110,
    "out:DH kr savings": 193690,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201957,
    "out:% savings (space heating)": 37.89,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.99,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.94,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 114.26,
    "out:Total Energy Use Post PV": 114.26,
    "out:Primary Energy": 110.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.9,
    "out:CO2 Operational/m2": 35.14,
    "out:Total CO2/m2": 62.05,
    "out:Total CO2 (tons)": 171.69,
    "out:Klimatpaverkan": 4.67,
    "out:Initial Cost/MSEK": 5.303,
    "out:Running cost/(Apt SEK y)": 18347,
    "out:Running Cost over RSP/MSEK": 6.875,
    "out:LCP/MSEK": -0.877,
    "out:ROI% old": 11.92,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 57,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 565,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 201991,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157411,
    "out:DH kr savings": 201991,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 193532,
    "out:% savings (space heating)": 40.49,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 69.94,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 123.26,
    "out:Total Energy Use Post PV": 116.64,
    "out:Primary Energy": 103.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 36.13,
    "out:CO2 Operational/m2": 25.96,
    "out:Total CO2/m2": 62.09,
    "out:Total CO2 (tons)": 171.81,
    "out:Klimatpaverkan": 4.79,
    "out:Initial Cost/MSEK": 5.476,
    "out:Running cost/(Apt SEK y)": 18950,
    "out:Running Cost over RSP/MSEK": 7.111,
    "out:LCP/MSEK": -1.285,
    "out:ROI% old": 10.93,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 53,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 177088,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178771,
    "out:DH kr savings": 177088,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201957,
    "out:% savings (space heating)": 37.89,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.99,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.94,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 120.26,
    "out:Total Energy Use Post PV": 113.64,
    "out:Primary Energy": 100.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.13,
    "out:CO2 Operational/m2": 24.88,
    "out:Total CO2/m2": 61.01,
    "out:Total CO2 (tons)": 168.82,
    "out:Klimatpaverkan": 1.8,
    "out:Initial Cost/MSEK": 5.545,
    "out:Running cost/(Apt SEK y)": 18428,
    "out:Running Cost over RSP/MSEK": 6.915,
    "out:LCP/MSEK": -1.159,
    "out:ROI% old": 11.3,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 57,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 562,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185389,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 187072,
    "out:DH kr savings": 185389,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 193532,
    "out:% savings (space heating)": 40.49,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 69.94,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 117.26,
    "out:Total Energy Use Post PV": 110.64,
    "out:Primary Energy": 100.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.79,
    "out:CO2 Operational/m2": 23.8,
    "out:Total CO2/m2": 60.58,
    "out:Total CO2 (tons)": 167.63,
    "out:Klimatpaverkan": 0.61,
    "out:Initial Cost/MSEK": 5.695,
    "out:Running cost/(Apt SEK y)": 17905,
    "out:Running Cost over RSP/MSEK": 6.719,
    "out:LCP/MSEK": -1.112,
    "out:ROI% old": 11.5,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 61.3,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 587,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193690,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195373,
    "out:DH kr savings": 193690,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201957,
    "out:% savings (space heating)": 37.89,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.99,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.94,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 114.26,
    "out:Total Energy Use Post PV": 107.64,
    "out:Primary Energy": 98.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.79,
    "out:CO2 Operational/m2": 22.72,
    "out:Total CO2/m2": 59.5,
    "out:Total CO2 (tons)": 164.65,
    "out:Klimatpaverkan": -2.37,
    "out:Initial Cost/MSEK": 5.764,
    "out:Running cost/(Apt SEK y)": 17383,
    "out:Running Cost over RSP/MSEK": 6.523,
    "out:LCP/MSEK": -0.985,
    "out:ROI% old": 11.84,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 65.7,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 612,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 201991,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 203674,
    "out:DH kr savings": 201991,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 193532,
    "out:% savings (space heating)": 40.49,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 69.94,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 115.26,
    "out:Total Energy Use Post PV": 115.26,
    "out:Primary Energy": 111.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.25,
    "out:CO2 Operational/m2": 35.5,
    "out:Total CO2/m2": 61.76,
    "out:Total CO2 (tons)": 170.88,
    "out:Klimatpaverkan": 3.86,
    "out:Initial Cost/MSEK": 5.142,
    "out:Running cost/(Apt SEK y)": 18521,
    "out:Running Cost over RSP/MSEK": 6.94,
    "out:LCP/MSEK": -0.781,
    "out:ROI% old": 12.12,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154644,
    "out:DH kr savings": 199224,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201957,
    "out:% savings (space heating)": 37.89,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.99,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.94,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 112.26,
    "out:Total Energy Use Post PV": 112.26,
    "out:Primary Energy": 109.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.25,
    "out:CO2 Operational/m2": 34.42,
    "out:Total CO2/m2": 60.68,
    "out:Total CO2 (tons)": 167.89,
    "out:Klimatpaverkan": 0.87,
    "out:Initial Cost/MSEK": 5.212,
    "out:Running cost/(Apt SEK y)": 17999,
    "out:Running Cost over RSP/MSEK": 6.744,
    "out:LCP/MSEK": -0.654,
    "out:ROI% old": 12.49,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 207525,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162945,
    "out:DH kr savings": 207525,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 193532,
    "out:% savings (space heating)": 40.49,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 69.94,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 109.26,
    "out:Total Energy Use Post PV": 109.26,
    "out:Primary Energy": 109.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.9,
    "out:CO2 Operational/m2": 33.34,
    "out:Total CO2/m2": 60.25,
    "out:Total CO2 (tons)": 166.71,
    "out:Klimatpaverkan": -0.31,
    "out:Initial Cost/MSEK": 5.361,
    "out:Running cost/(Apt SEK y)": 17477,
    "out:Running Cost over RSP/MSEK": 6.548,
    "out:LCP/MSEK": -0.607,
    "out:ROI% old": 12.67,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 64.2,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 607,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 215826,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171246,
    "out:DH kr savings": 215826,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201957,
    "out:% savings (space heating)": 37.89,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.99,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.94,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 106.26,
    "out:Total Energy Use Post PV": 106.26,
    "out:Primary Energy": 106.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.9,
    "out:CO2 Operational/m2": 32.26,
    "out:Total CO2/m2": 59.17,
    "out:Total CO2 (tons)": 163.72,
    "out:Klimatpaverkan": -3.3,
    "out:Initial Cost/MSEK": 5.43,
    "out:Running cost/(Apt SEK y)": 16954,
    "out:Running Cost over RSP/MSEK": 6.352,
    "out:LCP/MSEK": -0.481,
    "out:ROI% old": 13.02,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 68.9,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 632,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 224127,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 179547,
    "out:DH kr savings": 224127,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 193532,
    "out:% savings (space heating)": 40.49,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 67.95,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01001201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 115.26,
    "out:Total Energy Use Post PV": 108.64,
    "out:Primary Energy": 99.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.13,
    "out:CO2 Operational/m2": 23.08,
    "out:Total CO2/m2": 59.21,
    "out:Total CO2 (tons)": 163.84,
    "out:Klimatpaverkan": -3.18,
    "out:Initial Cost/MSEK": 5.603,
    "out:Running cost/(Apt SEK y)": 17557,
    "out:Running Cost over RSP/MSEK": 6.588,
    "out:LCP/MSEK": -0.889,
    "out:ROI% old": 12.02,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 64.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 603,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 200907,
    "out:DH kr savings": 199224,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201957,
    "out:% savings (space heating)": 37.89,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.99,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.94,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 112.26,
    "out:Total Energy Use Post PV": 105.64,
    "out:Primary Energy": 97.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.13,
    "out:CO2 Operational/m2": 22,
    "out:Total CO2/m2": 58.13,
    "out:Total CO2 (tons)": 160.85,
    "out:Klimatpaverkan": -6.17,
    "out:Initial Cost/MSEK": 5.672,
    "out:Running cost/(Apt SEK y)": 17035,
    "out:Running Cost over RSP/MSEK": 6.392,
    "out:LCP/MSEK": -0.762,
    "out:ROI% old": 12.37,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 68.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 207525,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209208,
    "out:DH kr savings": 207525,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 193532,
    "out:% savings (space heating)": 40.49,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 69.94,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 109.26,
    "out:Total Energy Use Post PV": 102.64,
    "out:Primary Energy": 97.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.79,
    "out:CO2 Operational/m2": 20.92,
    "out:Total CO2/m2": 57.7,
    "out:Total CO2 (tons)": 159.66,
    "out:Klimatpaverkan": -7.36,
    "out:Initial Cost/MSEK": 5.821,
    "out:Running cost/(Apt SEK y)": 16513,
    "out:Running Cost over RSP/MSEK": 6.196,
    "out:LCP/MSEK": -0.716,
    "out:ROI% old": 12.53,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 73.8,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 653,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 215826,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217509,
    "out:DH kr savings": 215826,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 201957,
    "out:% savings (space heating)": 37.89,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.99,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01001201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.94,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 106.26,
    "out:Total Energy Use Post PV": 99.64,
    "out:Primary Energy": 94.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.79,
    "out:CO2 Operational/m2": 19.84,
    "out:Total CO2/m2": 56.62,
    "out:Total CO2 (tons)": 156.68,
    "out:Klimatpaverkan": -10.34,
    "out:Initial Cost/MSEK": 5.891,
    "out:Running cost/(Apt SEK y)": 15990,
    "out:Running Cost over RSP/MSEK": 6,
    "out:LCP/MSEK": -0.589,
    "out:ROI% old": 12.86,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 679,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 224127,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 225810,
    "out:DH kr savings": 224127,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 193532,
    "out:% savings (space heating)": 40.49,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 67.95,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.01,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 144.2,
    "out:Total Energy Use Post PV": 144.2,
    "out:Primary Energy": 122.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.84,
    "out:CO2 Operational/m2": 47.76,
    "out:Total CO2/m2": 62.6,
    "out:Total CO2 (tons)": 173.23,
    "out:Klimatpaverkan": 6.21,
    "out:Initial Cost/MSEK": 2.873,
    "out:Running cost/(Apt SEK y)": 24034,
    "out:Running Cost over RSP/MSEK": 9.014,
    "out:LCP/MSEK": -0.585,
    "out:ROI% old": 11.38,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 292,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96845,
    "out:DH kr savings": 96845,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229693,
    "out:% savings (space heating)": 29.37,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.01,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.51,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 141.2,
    "out:Total Energy Use Post PV": 141.2,
    "out:Primary Energy": 119.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.84,
    "out:CO2 Operational/m2": 46.68,
    "out:Total CO2/m2": 61.52,
    "out:Total CO2 (tons)": 170.24,
    "out:Klimatpaverkan": 3.22,
    "out:Initial Cost/MSEK": 2.942,
    "out:Running cost/(Apt SEK y)": 23511,
    "out:Running Cost over RSP/MSEK": 8.818,
    "out:LCP/MSEK": -0.458,
    "out:ROI% old": 12.06,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 318,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105146,
    "out:DH kr savings": 105146,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 220007,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.51,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.01,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 138.2,
    "out:Primary Energy": 120.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.5,
    "out:CO2 Operational/m2": 45.6,
    "out:Total CO2/m2": 61.1,
    "out:Total CO2 (tons)": 169.06,
    "out:Klimatpaverkan": 2.04,
    "out:Initial Cost/MSEK": 3.091,
    "out:Running cost/(Apt SEK y)": 22989,
    "out:Running Cost over RSP/MSEK": 8.622,
    "out:LCP/MSEK": -0.411,
    "out:ROI% old": 12.38,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 343,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113447,
    "out:DH kr savings": 113447,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229693,
    "out:% savings (space heating)": 29.37,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.01,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.51,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 134.2,
    "out:Primary Energy": 117.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.5,
    "out:CO2 Operational/m2": 44.16,
    "out:Total CO2/m2": 59.66,
    "out:Total CO2 (tons)": 165.07,
    "out:Klimatpaverkan": -1.95,
    "out:Initial Cost/MSEK": 3.16,
    "out:Running cost/(Apt SEK y)": 22293,
    "out:Running Cost over RSP/MSEK": 8.361,
    "out:LCP/MSEK": -0.219,
    "out:ROI% old": 13.3,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124515,
    "out:DH kr savings": 124515,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 220007,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.51,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.01,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 144.2,
    "out:Total Energy Use Post PV": 138.92,
    "out:Primary Energy": 113.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.73,
    "out:CO2 Operational/m2": 28.7,
    "out:Total CO2/m2": 53.43,
    "out:Total CO2 (tons)": 147.83,
    "out:Klimatpaverkan": -19.19,
    "out:Initial Cost/MSEK": 3.333,
    "out:Running cost/(Apt SEK y)": 23108,
    "out:Running Cost over RSP/MSEK": 8.676,
    "out:LCP/MSEK": -0.707,
    "out:ROI% old": 11.25,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141268,
    "out:DH kr savings": 96845,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229693,
    "out:% savings (space heating)": 29.37,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.01,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.51,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 141.2,
    "out:Total Energy Use Post PV": 135.92,
    "out:Primary Energy": 110.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.73,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 52.35,
    "out:Total CO2 (tons)": 144.84,
    "out:Klimatpaverkan": -22.18,
    "out:Initial Cost/MSEK": 3.402,
    "out:Running cost/(Apt SEK y)": 22586,
    "out:Running Cost over RSP/MSEK": 8.48,
    "out:LCP/MSEK": -0.58,
    "out:ROI% old": 11.85,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149569,
    "out:DH kr savings": 105146,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 220007,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.51,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.01,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 132.92,
    "out:Primary Energy": 110.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.38,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 51.92,
    "out:Total CO2 (tons)": 143.66,
    "out:Klimatpaverkan": -23.36,
    "out:Initial Cost/MSEK": 3.552,
    "out:Running cost/(Apt SEK y)": 22064,
    "out:Running Cost over RSP/MSEK": 8.284,
    "out:LCP/MSEK": -0.534,
    "out:ROI% old": 12.14,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 387,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157870,
    "out:DH kr savings": 113447,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229693,
    "out:% savings (space heating)": 29.37,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.01,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.51,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 128.92,
    "out:Primary Energy": 107.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.38,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 50.48,
    "out:Total CO2 (tons)": 139.68,
    "out:Klimatpaverkan": -27.34,
    "out:Initial Cost/MSEK": 3.621,
    "out:Running cost/(Apt SEK y)": 21367,
    "out:Running Cost over RSP/MSEK": 8.022,
    "out:LCP/MSEK": -0.341,
    "out:ROI% old": 12.94,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168938,
    "out:DH kr savings": 124515,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 220007,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.51,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.01,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 136.2,
    "out:Primary Energy": 119.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.84,
    "out:CO2 Operational/m2": 44.88,
    "out:Total CO2/m2": 59.72,
    "out:Total CO2 (tons)": 165.26,
    "out:Klimatpaverkan": -1.76,
    "out:Initial Cost/MSEK": 2.999,
    "out:Running cost/(Apt SEK y)": 22641,
    "out:Running Cost over RSP/MSEK": 8.491,
    "out:LCP/MSEK": -0.189,
    "out:ROI% old": 13.39,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 359,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118981,
    "out:DH kr savings": 118981,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229693,
    "out:% savings (space heating)": 29.37,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.01,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.51,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 132.2,
    "out:Primary Energy": 116.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.84,
    "out:CO2 Operational/m2": 43.44,
    "out:Total CO2/m2": 58.29,
    "out:Total CO2 (tons)": 161.27,
    "out:Klimatpaverkan": -5.75,
    "out:Initial Cost/MSEK": 3.069,
    "out:Running cost/(Apt SEK y)": 21945,
    "out:Running Cost over RSP/MSEK": 8.23,
    "out:LCP/MSEK": 0.003,
    "out:ROI% old": 14.3,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 393,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130049,
    "out:DH kr savings": 130049,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 220007,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.51,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.01,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 130.2,
    "out:Primary Energy": 117.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.5,
    "out:CO2 Operational/m2": 42.72,
    "out:Total CO2/m2": 58.22,
    "out:Total CO2 (tons)": 161.09,
    "out:Klimatpaverkan": -5.93,
    "out:Initial Cost/MSEK": 3.218,
    "out:Running cost/(Apt SEK y)": 21596,
    "out:Running Cost over RSP/MSEK": 8.099,
    "out:LCP/MSEK": -0.015,
    "out:ROI% old": 14.22,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 409,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135583,
    "out:DH kr savings": 135583,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229693,
    "out:% savings (space heating)": 29.37,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.01,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.51,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 127.2,
    "out:Total Energy Use Post PV": 127.2,
    "out:Primary Energy": 114.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.5,
    "out:CO2 Operational/m2": 41.64,
    "out:Total CO2/m2": 57.14,
    "out:Total CO2 (tons)": 158.1,
    "out:Klimatpaverkan": -8.92,
    "out:Initial Cost/MSEK": 3.287,
    "out:Running cost/(Apt SEK y)": 21074,
    "out:Running Cost over RSP/MSEK": 7.903,
    "out:LCP/MSEK": 0.112,
    "out:ROI% old": 14.77,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 435,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 143884,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143884,
    "out:DH kr savings": 143884,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 220007,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.51,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.01,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 130.92,
    "out:Primary Energy": 109.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.73,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 50.55,
    "out:Total CO2 (tons)": 139.86,
    "out:Klimatpaverkan": -27.16,
    "out:Initial Cost/MSEK": 3.46,
    "out:Running cost/(Apt SEK y)": 21716,
    "out:Running Cost over RSP/MSEK": 8.153,
    "out:LCP/MSEK": -0.311,
    "out:ROI% old": 13,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163404,
    "out:DH kr savings": 118981,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229693,
    "out:% savings (space heating)": 29.37,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.01,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.51,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 126.92,
    "out:Primary Energy": 106.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.73,
    "out:CO2 Operational/m2": 24.38,
    "out:Total CO2/m2": 49.11,
    "out:Total CO2 (tons)": 135.88,
    "out:Klimatpaverkan": -31.14,
    "out:Initial Cost/MSEK": 3.529,
    "out:Running cost/(Apt SEK y)": 21019,
    "out:Running Cost over RSP/MSEK": 7.892,
    "out:LCP/MSEK": -0.119,
    "out:ROI% old": 13.8,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 437,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174472,
    "out:DH kr savings": 130049,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 220007,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.51,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.01,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 124.92,
    "out:Primary Energy": 107.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.38,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 49.04,
    "out:Total CO2 (tons)": 135.69,
    "out:Klimatpaverkan": -31.33,
    "out:Initial Cost/MSEK": 3.678,
    "out:Running cost/(Apt SEK y)": 20671,
    "out:Running Cost over RSP/MSEK": 7.761,
    "out:LCP/MSEK": -0.138,
    "out:ROI% old": 13.75,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 454,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180006,
    "out:DH kr savings": 135583,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229693,
    "out:% savings (space heating)": 29.37,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.01,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.51,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 127.2,
    "out:Total Energy Use Post PV": 121.92,
    "out:Primary Energy": 104.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.38,
    "out:CO2 Operational/m2": 22.58,
    "out:Total CO2/m2": 47.96,
    "out:Total CO2 (tons)": 132.7,
    "out:Klimatpaverkan": -34.32,
    "out:Initial Cost/MSEK": 3.748,
    "out:Running cost/(Apt SEK y)": 20149,
    "out:Running Cost over RSP/MSEK": 7.565,
    "out:LCP/MSEK": -0.011,
    "out:ROI% old": 14.25,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 46.7,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 479,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 143884,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188307,
    "out:DH kr savings": 143884,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 220007,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.51,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.01,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 133.26,
    "out:Total Energy Use Post PV": 133.26,
    "out:Primary Energy": 124.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.64,
    "out:CO2 Operational/m2": 41.98,
    "out:Total CO2/m2": 60.63,
    "out:Total CO2 (tons)": 167.75,
    "out:Klimatpaverkan": 0.73,
    "out:Initial Cost/MSEK": 3.517,
    "out:Running cost/(Apt SEK y)": 21655,
    "out:Running Cost over RSP/MSEK": 8.117,
    "out:LCP/MSEK": -0.332,
    "out:ROI% old": 12.94,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 407,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 104838,
    "out:DH kr savings": 149418,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229693,
    "out:% savings (space heating)": 29.37,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.01,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.51,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 130.26,
    "out:Total Energy Use Post PV": 130.26,
    "out:Primary Energy": 121.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.64,
    "out:CO2 Operational/m2": 40.9,
    "out:Total CO2/m2": 59.55,
    "out:Total CO2 (tons)": 164.76,
    "out:Klimatpaverkan": -2.26,
    "out:Initial Cost/MSEK": 3.586,
    "out:Running cost/(Apt SEK y)": 21132,
    "out:Running Cost over RSP/MSEK": 7.921,
    "out:LCP/MSEK": -0.205,
    "out:ROI% old": 13.47,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 432,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113139,
    "out:DH kr savings": 157719,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 220007,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.51,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.01,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 127.26,
    "out:Primary Energy": 121.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.29,
    "out:CO2 Operational/m2": 39.82,
    "out:Total CO2/m2": 59.12,
    "out:Total CO2 (tons)": 163.58,
    "out:Klimatpaverkan": -3.44,
    "out:Initial Cost/MSEK": 3.735,
    "out:Running cost/(Apt SEK y)": 20610,
    "out:Running Cost over RSP/MSEK": 7.725,
    "out:LCP/MSEK": -0.158,
    "out:ROI% old": 13.68,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121440,
    "out:DH kr savings": 166020,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229693,
    "out:% savings (space heating)": 29.37,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.01,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.51,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 123.26,
    "out:Total Energy Use Post PV": 123.26,
    "out:Primary Energy": 118.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.29,
    "out:CO2 Operational/m2": 38.38,
    "out:Total CO2/m2": 57.68,
    "out:Total CO2 (tons)": 159.6,
    "out:Klimatpaverkan": -7.42,
    "out:Initial Cost/MSEK": 3.805,
    "out:Running cost/(Apt SEK y)": 19914,
    "out:Running Cost over RSP/MSEK": 7.463,
    "out:LCP/MSEK": 0.034,
    "out:ROI% old": 14.41,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 177088,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132508,
    "out:DH kr savings": 177088,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 220007,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.51,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.01,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 133.26,
    "out:Total Energy Use Post PV": 126.64,
    "out:Primary Energy": 112.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.52,
    "out:CO2 Operational/m2": 29.56,
    "out:Total CO2/m2": 58.08,
    "out:Total CO2 (tons)": 160.71,
    "out:Klimatpaverkan": -6.31,
    "out:Initial Cost/MSEK": 3.977,
    "out:Running cost/(Apt SEK y)": 20691,
    "out:Running Cost over RSP/MSEK": 7.765,
    "out:LCP/MSEK": -0.44,
    "out:ROI% old": 12.7,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 453,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151101,
    "out:DH kr savings": 149418,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229693,
    "out:% savings (space heating)": 29.37,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.01,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.51,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 130.26,
    "out:Total Energy Use Post PV": 123.64,
    "out:Primary Energy": 109.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.52,
    "out:CO2 Operational/m2": 28.48,
    "out:Total CO2/m2": 57,
    "out:Total CO2 (tons)": 157.72,
    "out:Klimatpaverkan": -9.3,
    "out:Initial Cost/MSEK": 4.047,
    "out:Running cost/(Apt SEK y)": 20169,
    "out:Running Cost over RSP/MSEK": 7.569,
    "out:LCP/MSEK": -0.313,
    "out:ROI% old": 13.18,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159402,
    "out:DH kr savings": 157719,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 220007,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.51,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.01,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 120.64,
    "out:Primary Energy": 109.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.18,
    "out:CO2 Operational/m2": 27.4,
    "out:Total CO2/m2": 56.57,
    "out:Total CO2 (tons)": 156.54,
    "out:Klimatpaverkan": -10.48,
    "out:Initial Cost/MSEK": 4.196,
    "out:Running cost/(Apt SEK y)": 19646,
    "out:Running Cost over RSP/MSEK": 7.373,
    "out:LCP/MSEK": -0.267,
    "out:ROI% old": 13.38,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167703,
    "out:DH kr savings": 166020,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229693,
    "out:% savings (space heating)": 29.37,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.01,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.51,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 123.26,
    "out:Total Energy Use Post PV": 116.64,
    "out:Primary Energy": 106.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.18,
    "out:CO2 Operational/m2": 25.96,
    "out:Total CO2/m2": 55.13,
    "out:Total CO2 (tons)": 152.55,
    "out:Klimatpaverkan": -14.47,
    "out:Initial Cost/MSEK": 4.265,
    "out:Running cost/(Apt SEK y)": 18950,
    "out:Running Cost over RSP/MSEK": 7.111,
    "out:LCP/MSEK": -0.074,
    "out:ROI% old": 14.04,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 53,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 177088,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178771,
    "out:DH kr savings": 177088,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 220007,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.51,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.01,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 125.26,
    "out:Total Energy Use Post PV": 125.26,
    "out:Primary Energy": 120.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.64,
    "out:CO2 Operational/m2": 39.1,
    "out:Total CO2/m2": 57.75,
    "out:Total CO2 (tons)": 159.78,
    "out:Klimatpaverkan": -7.24,
    "out:Initial Cost/MSEK": 3.644,
    "out:Running cost/(Apt SEK y)": 20262,
    "out:Running Cost over RSP/MSEK": 7.594,
    "out:LCP/MSEK": 0.064,
    "out:ROI% old": 14.54,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 474,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 171554,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126974,
    "out:DH kr savings": 171554,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229693,
    "out:% savings (space heating)": 29.37,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.01,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.51,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 121.26,
    "out:Total Energy Use Post PV": 121.26,
    "out:Primary Energy": 117.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.64,
    "out:CO2 Operational/m2": 37.66,
    "out:Total CO2/m2": 56.31,
    "out:Total CO2 (tons)": 155.8,
    "out:Klimatpaverkan": -11.22,
    "out:Initial Cost/MSEK": 3.713,
    "out:Running cost/(Apt SEK y)": 19566,
    "out:Running Cost over RSP/MSEK": 7.333,
    "out:LCP/MSEK": 0.256,
    "out:ROI% old": 15.27,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 182622,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138042,
    "out:DH kr savings": 182622,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 220007,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.51,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.01,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 119.26,
    "out:Total Energy Use Post PV": 119.26,
    "out:Primary Energy": 118.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.29,
    "out:CO2 Operational/m2": 36.94,
    "out:Total CO2/m2": 56.24,
    "out:Total CO2 (tons)": 155.61,
    "out:Klimatpaverkan": -11.41,
    "out:Initial Cost/MSEK": 3.862,
    "out:Running cost/(Apt SEK y)": 19217,
    "out:Running Cost over RSP/MSEK": 7.202,
    "out:LCP/MSEK": 0.238,
    "out:ROI% old": 15.17,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 524,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 188156,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143576,
    "out:DH kr savings": 188156,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229693,
    "out:% savings (space heating)": 29.37,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.02,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.51,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 116.26,
    "out:Total Energy Use Post PV": 116.26,
    "out:Primary Energy": 115.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.29,
    "out:CO2 Operational/m2": 35.86,
    "out:Total CO2/m2": 55.16,
    "out:Total CO2 (tons)": 152.62,
    "out:Klimatpaverkan": -14.4,
    "out:Initial Cost/MSEK": 3.931,
    "out:Running cost/(Apt SEK y)": 18695,
    "out:Running Cost over RSP/MSEK": 7.006,
    "out:LCP/MSEK": 0.365,
    "out:ROI% old": 15.61,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 54.3,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 549,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 196457,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151877,
    "out:DH kr savings": 196457,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 220007,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 77.52,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.01,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 125.26,
    "out:Total Energy Use Post PV": 118.64,
    "out:Primary Energy": 108.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.52,
    "out:CO2 Operational/m2": 26.68,
    "out:Total CO2/m2": 55.2,
    "out:Total CO2 (tons)": 152.74,
    "out:Klimatpaverkan": -14.28,
    "out:Initial Cost/MSEK": 4.104,
    "out:Running cost/(Apt SEK y)": 19298,
    "out:Running Cost over RSP/MSEK": 7.242,
    "out:LCP/MSEK": -0.044,
    "out:ROI% old": 14.13,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 171554,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 173237,
    "out:DH kr savings": 171554,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229693,
    "out:% savings (space heating)": 29.37,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.01,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.51,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 121.26,
    "out:Total Energy Use Post PV": 114.64,
    "out:Primary Energy": 105.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.52,
    "out:CO2 Operational/m2": 25.24,
    "out:Total CO2/m2": 53.76,
    "out:Total CO2 (tons)": 148.76,
    "out:Klimatpaverkan": -18.26,
    "out:Initial Cost/MSEK": 4.173,
    "out:Running cost/(Apt SEK y)": 18602,
    "out:Running Cost over RSP/MSEK": 6.98,
    "out:LCP/MSEK": 0.148,
    "out:ROI% old": 14.79,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 182622,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184305,
    "out:DH kr savings": 182622,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 220007,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.51,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.01,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 119.26,
    "out:Total Energy Use Post PV": 112.64,
    "out:Primary Energy": 106.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.18,
    "out:CO2 Operational/m2": 24.52,
    "out:Total CO2/m2": 53.69,
    "out:Total CO2 (tons)": 148.57,
    "out:Klimatpaverkan": -18.45,
    "out:Initial Cost/MSEK": 4.323,
    "out:Running cost/(Apt SEK y)": 18254,
    "out:Running Cost over RSP/MSEK": 6.85,
    "out:LCP/MSEK": 0.129,
    "out:ROI% old": 14.71,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 58.4,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 570,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 188156,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189839,
    "out:DH kr savings": 188156,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229693,
    "out:% savings (space heating)": 29.37,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.02,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.51,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 116.26,
    "out:Total Energy Use Post PV": 109.64,
    "out:Primary Energy": 103.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.18,
    "out:CO2 Operational/m2": 23.44,
    "out:Total CO2/m2": 52.61,
    "out:Total CO2 (tons)": 145.58,
    "out:Klimatpaverkan": -21.44,
    "out:Initial Cost/MSEK": 4.392,
    "out:Running cost/(Apt SEK y)": 17731,
    "out:Running Cost over RSP/MSEK": 6.654,
    "out:LCP/MSEK": 0.256,
    "out:ROI% old": 15.12,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 62.7,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 196457,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198140,
    "out:DH kr savings": 196457,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 220007,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 77.52,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.84,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 135.2,
    "out:Total Energy Use Post PV": 135.2,
    "out:Primary Energy": 114.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.16,
    "out:CO2 Operational/m2": 44.52,
    "out:Total CO2/m2": 61.68,
    "out:Total CO2 (tons)": 170.67,
    "out:Klimatpaverkan": 3.65,
    "out:Initial Cost/MSEK": 3.319,
    "out:Running cost/(Apt SEK y)": 22467,
    "out:Running Cost over RSP/MSEK": 8.426,
    "out:LCP/MSEK": -0.443,
    "out:ROI% old": 12.38,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 368,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121748,
    "out:DH kr savings": 121748,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 204324,
    "out:% savings (space heating)": 37.17,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.84,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.76,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 132.2,
    "out:Primary Energy": 112.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.16,
    "out:CO2 Operational/m2": 43.44,
    "out:Total CO2/m2": 60.6,
    "out:Total CO2 (tons)": 167.68,
    "out:Klimatpaverkan": 0.66,
    "out:Initial Cost/MSEK": 3.389,
    "out:Running cost/(Apt SEK y)": 21945,
    "out:Running Cost over RSP/MSEK": 8.23,
    "out:LCP/MSEK": -0.317,
    "out:ROI% old": 12.95,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 393,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130049,
    "out:DH kr savings": 130049,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195791,
    "out:% savings (space heating)": 39.79,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.76,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.84,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 129.2,
    "out:Primary Energy": 112.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.81,
    "out:CO2 Operational/m2": 42.36,
    "out:Total CO2/m2": 60.17,
    "out:Total CO2 (tons)": 166.5,
    "out:Klimatpaverkan": -0.52,
    "out:Initial Cost/MSEK": 3.538,
    "out:Running cost/(Apt SEK y)": 21422,
    "out:Running Cost over RSP/MSEK": 8.034,
    "out:LCP/MSEK": -0.27,
    "out:ROI% old": 13.2,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 418,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138350,
    "out:DH kr savings": 138350,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 204324,
    "out:% savings (space heating)": 37.17,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.84,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.76,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 126.2,
    "out:Primary Energy": 109.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.81,
    "out:CO2 Operational/m2": 41.28,
    "out:Total CO2/m2": 59.09,
    "out:Total CO2 (tons)": 163.51,
    "out:Klimatpaverkan": -3.51,
    "out:Initial Cost/MSEK": 3.607,
    "out:Running cost/(Apt SEK y)": 20900,
    "out:Running Cost over RSP/MSEK": 7.838,
    "out:LCP/MSEK": -0.143,
    "out:ROI% old": 13.72,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 443,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146651,
    "out:DH kr savings": 146651,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195791,
    "out:% savings (space heating)": 39.79,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.76,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.84,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 135.2,
    "out:Total Energy Use Post PV": 129.92,
    "out:Primary Energy": 105.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.04,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 52.5,
    "out:Total CO2 (tons)": 145.27,
    "out:Klimatpaverkan": -21.75,
    "out:Initial Cost/MSEK": 3.78,
    "out:Running cost/(Apt SEK y)": 21541,
    "out:Running Cost over RSP/MSEK": 8.088,
    "out:LCP/MSEK": -0.566,
    "out:ROI% old": 12.15,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166171,
    "out:DH kr savings": 121748,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 204324,
    "out:% savings (space heating)": 37.17,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.84,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.76,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 126.92,
    "out:Primary Energy": 102.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.04,
    "out:CO2 Operational/m2": 24.38,
    "out:Total CO2/m2": 51.42,
    "out:Total CO2 (tons)": 142.28,
    "out:Klimatpaverkan": -24.74,
    "out:Initial Cost/MSEK": 3.849,
    "out:Running cost/(Apt SEK y)": 21019,
    "out:Running Cost over RSP/MSEK": 7.892,
    "out:LCP/MSEK": -0.439,
    "out:ROI% old": 12.66,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 437,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174472,
    "out:DH kr savings": 130049,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195791,
    "out:% savings (space heating)": 39.79,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.76,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.84,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 123.92,
    "out:Primary Energy": 102.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.69,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 50.99,
    "out:Total CO2 (tons)": 141.1,
    "out:Klimatpaverkan": -25.92,
    "out:Initial Cost/MSEK": 3.998,
    "out:Running cost/(Apt SEK y)": 20497,
    "out:Running Cost over RSP/MSEK": 7.696,
    "out:LCP/MSEK": -0.392,
    "out:ROI% old": 12.88,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182773,
    "out:DH kr savings": 138350,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 204324,
    "out:% savings (space heating)": 37.17,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.84,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.76,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 120.92,
    "out:Primary Energy": 100.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.69,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 49.91,
    "out:Total CO2 (tons)": 138.11,
    "out:Klimatpaverkan": -28.91,
    "out:Initial Cost/MSEK": 4.068,
    "out:Running cost/(Apt SEK y)": 19975,
    "out:Running Cost over RSP/MSEK": 7.499,
    "out:LCP/MSEK": -0.265,
    "out:ROI% old": 13.35,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191074,
    "out:DH kr savings": 146651,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195791,
    "out:% savings (space heating)": 39.79,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.76,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.84,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 126.2,
    "out:Primary Energy": 111.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.16,
    "out:CO2 Operational/m2": 41.28,
    "out:Total CO2/m2": 58.44,
    "out:Total CO2 (tons)": 161.7,
    "out:Klimatpaverkan": -5.32,
    "out:Initial Cost/MSEK": 3.446,
    "out:Running cost/(Apt SEK y)": 20900,
    "out:Running Cost over RSP/MSEK": 7.838,
    "out:LCP/MSEK": 0.018,
    "out:ROI% old": 14.36,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 443,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146651,
    "out:DH kr savings": 146651,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 204324,
    "out:% savings (space heating)": 37.17,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.84,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.76,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 123.2,
    "out:Total Energy Use Post PV": 123.2,
    "out:Primary Energy": 108.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.16,
    "out:CO2 Operational/m2": 40.2,
    "out:Total CO2/m2": 57.36,
    "out:Total CO2 (tons)": 158.71,
    "out:Klimatpaverkan": -8.31,
    "out:Initial Cost/MSEK": 3.515,
    "out:Running cost/(Apt SEK y)": 20378,
    "out:Running Cost over RSP/MSEK": 7.642,
    "out:LCP/MSEK": 0.145,
    "out:ROI% old": 14.88,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154952,
    "out:DH kr savings": 154952,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195791,
    "out:% savings (space heating)": 39.79,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.76,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.84,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 121.2,
    "out:Total Energy Use Post PV": 121.2,
    "out:Primary Energy": 109.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.81,
    "out:CO2 Operational/m2": 39.48,
    "out:Total CO2/m2": 57.29,
    "out:Total CO2 (tons)": 158.53,
    "out:Klimatpaverkan": -8.49,
    "out:Initial Cost/MSEK": 3.665,
    "out:Running cost/(Apt SEK y)": 20030,
    "out:Running Cost over RSP/MSEK": 7.511,
    "out:LCP/MSEK": 0.126,
    "out:ROI% old": 14.78,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 485,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160486,
    "out:DH kr savings": 160486,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 204324,
    "out:% savings (space heating)": 37.17,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.84,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.76,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 118.2,
    "out:Total Energy Use Post PV": 118.2,
    "out:Primary Energy": 106.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.81,
    "out:CO2 Operational/m2": 38.4,
    "out:Total CO2/m2": 56.21,
    "out:Total CO2 (tons)": 155.54,
    "out:Klimatpaverkan": -11.48,
    "out:Initial Cost/MSEK": 3.734,
    "out:Running cost/(Apt SEK y)": 19507,
    "out:Running Cost over RSP/MSEK": 7.315,
    "out:LCP/MSEK": 0.253,
    "out:ROI% old": 15.25,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 14,
    "out:Return/kSEK/y": 510,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 168787,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168787,
    "out:DH kr savings": 168787,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195791,
    "out:% savings (space heating)": 39.79,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.76,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.84,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 120.92,
    "out:Primary Energy": 101.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.04,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 49.26,
    "out:Total CO2 (tons)": 136.31,
    "out:Klimatpaverkan": -30.71,
    "out:Initial Cost/MSEK": 3.906,
    "out:Running cost/(Apt SEK y)": 19975,
    "out:Running Cost over RSP/MSEK": 7.499,
    "out:LCP/MSEK": -0.104,
    "out:ROI% old": 13.91,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191074,
    "out:DH kr savings": 146651,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 204324,
    "out:% savings (space heating)": 37.17,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.84,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.76,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 123.2,
    "out:Total Energy Use Post PV": 117.92,
    "out:Primary Energy": 99.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.04,
    "out:CO2 Operational/m2": 21.14,
    "out:Total CO2/m2": 48.18,
    "out:Total CO2 (tons)": 133.32,
    "out:Klimatpaverkan": -33.7,
    "out:Initial Cost/MSEK": 3.976,
    "out:Running cost/(Apt SEK y)": 19452,
    "out:Running Cost over RSP/MSEK": 7.303,
    "out:LCP/MSEK": 0.023,
    "out:ROI% old": 14.37,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 512,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199375,
    "out:DH kr savings": 154952,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195791,
    "out:% savings (space heating)": 39.79,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.76,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.84,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 121.2,
    "out:Total Energy Use Post PV": 115.92,
    "out:Primary Energy": 99.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.69,
    "out:CO2 Operational/m2": 20.42,
    "out:Total CO2/m2": 48.11,
    "out:Total CO2 (tons)": 133.13,
    "out:Klimatpaverkan": -33.89,
    "out:Initial Cost/MSEK": 4.125,
    "out:Running cost/(Apt SEK y)": 19104,
    "out:Running Cost over RSP/MSEK": 7.173,
    "out:LCP/MSEK": 0.004,
    "out:ROI% old": 14.3,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 54.3,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 204909,
    "out:DH kr savings": 160486,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 204324,
    "out:% savings (space heating)": 37.17,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.84,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.76,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 118.2,
    "out:Total Energy Use Post PV": 112.92,
    "out:Primary Energy": 97.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.69,
    "out:CO2 Operational/m2": 19.34,
    "out:Total CO2/m2": 47.03,
    "out:Total CO2 (tons)": 130.14,
    "out:Klimatpaverkan": -36.88,
    "out:Initial Cost/MSEK": 4.194,
    "out:Running cost/(Apt SEK y)": 18582,
    "out:Running Cost over RSP/MSEK": 6.977,
    "out:LCP/MSEK": 0.131,
    "out:ROI% old": 14.73,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 58.4,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 554,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 168787,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 213210,
    "out:DH kr savings": 168787,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195791,
    "out:% savings (space heating)": 39.79,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.76,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.84,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 124.26,
    "out:Total Energy Use Post PV": 124.26,
    "out:Primary Energy": 116.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.96,
    "out:CO2 Operational/m2": 38.74,
    "out:Total CO2/m2": 59.7,
    "out:Total CO2 (tons)": 165.19,
    "out:Klimatpaverkan": -1.83,
    "out:Initial Cost/MSEK": 3.964,
    "out:Running cost/(Apt SEK y)": 20088,
    "out:Running Cost over RSP/MSEK": 7.529,
    "out:LCP/MSEK": -0.19,
    "out:ROI% old": 13.6,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 174321,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 129741,
    "out:DH kr savings": 174321,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 204324,
    "out:% savings (space heating)": 37.17,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.84,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.76,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 121.26,
    "out:Total Energy Use Post PV": 121.26,
    "out:Primary Energy": 113.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.96,
    "out:CO2 Operational/m2": 37.66,
    "out:Total CO2/m2": 58.62,
    "out:Total CO2 (tons)": 162.2,
    "out:Klimatpaverkan": -4.82,
    "out:Initial Cost/MSEK": 4.033,
    "out:Running cost/(Apt SEK y)": 19566,
    "out:Running Cost over RSP/MSEK": 7.333,
    "out:LCP/MSEK": -0.064,
    "out:ROI% old": 14.06,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182622,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138042,
    "out:DH kr savings": 182622,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195791,
    "out:% savings (space heating)": 39.79,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.76,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.84,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 118.26,
    "out:Total Energy Use Post PV": 118.26,
    "out:Primary Energy": 113.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.61,
    "out:CO2 Operational/m2": 36.58,
    "out:Total CO2/m2": 58.19,
    "out:Total CO2 (tons)": 161.02,
    "out:Klimatpaverkan": -6,
    "out:Initial Cost/MSEK": 4.182,
    "out:Running cost/(Apt SEK y)": 19043,
    "out:Running Cost over RSP/MSEK": 7.136,
    "out:LCP/MSEK": -0.017,
    "out:ROI% old": 14.23,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190923,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146343,
    "out:DH kr savings": 190923,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 204324,
    "out:% savings (space heating)": 37.17,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.84,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.76,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 115.26,
    "out:Total Energy Use Post PV": 115.26,
    "out:Primary Energy": 110.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.61,
    "out:CO2 Operational/m2": 35.5,
    "out:Total CO2/m2": 57.11,
    "out:Total CO2 (tons)": 158.03,
    "out:Klimatpaverkan": -8.99,
    "out:Initial Cost/MSEK": 4.251,
    "out:Running cost/(Apt SEK y)": 18521,
    "out:Running Cost over RSP/MSEK": 6.94,
    "out:LCP/MSEK": 0.11,
    "out:ROI% old": 14.66,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154644,
    "out:DH kr savings": 199224,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195791,
    "out:% savings (space heating)": 39.79,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.76,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.84,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 124.26,
    "out:Total Energy Use Post PV": 117.64,
    "out:Primary Energy": 104.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.84,
    "out:CO2 Operational/m2": 26.32,
    "out:Total CO2/m2": 57.16,
    "out:Total CO2 (tons)": 158.15,
    "out:Klimatpaverkan": -8.87,
    "out:Initial Cost/MSEK": 4.424,
    "out:Running cost/(Apt SEK y)": 19124,
    "out:Running Cost over RSP/MSEK": 7.176,
    "out:LCP/MSEK": -0.299,
    "out:ROI% old": 13.32,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 528,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 174321,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176004,
    "out:DH kr savings": 174321,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 204324,
    "out:% savings (space heating)": 37.17,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.84,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.76,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 121.26,
    "out:Total Energy Use Post PV": 114.64,
    "out:Primary Energy": 101.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.84,
    "out:CO2 Operational/m2": 25.24,
    "out:Total CO2/m2": 56.08,
    "out:Total CO2 (tons)": 155.16,
    "out:Klimatpaverkan": -11.86,
    "out:Initial Cost/MSEK": 4.493,
    "out:Running cost/(Apt SEK y)": 18602,
    "out:Running Cost over RSP/MSEK": 6.98,
    "out:LCP/MSEK": -0.172,
    "out:ROI% old": 13.74,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 182622,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184305,
    "out:DH kr savings": 182622,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195791,
    "out:% savings (space heating)": 39.79,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.76,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.84,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 118.26,
    "out:Total Energy Use Post PV": 111.64,
    "out:Primary Energy": 101.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.49,
    "out:CO2 Operational/m2": 24.16,
    "out:Total CO2/m2": 55.65,
    "out:Total CO2 (tons)": 153.98,
    "out:Klimatpaverkan": -13.04,
    "out:Initial Cost/MSEK": 4.643,
    "out:Running cost/(Apt SEK y)": 18080,
    "out:Running Cost over RSP/MSEK": 6.784,
    "out:LCP/MSEK": -0.125,
    "out:ROI% old": 13.9,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 578,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190923,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192606,
    "out:DH kr savings": 190923,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 204324,
    "out:% savings (space heating)": 37.17,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.84,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.76,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 115.26,
    "out:Total Energy Use Post PV": 108.64,
    "out:Primary Energy": 98.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.49,
    "out:CO2 Operational/m2": 23.08,
    "out:Total CO2/m2": 54.57,
    "out:Total CO2 (tons)": 150.99,
    "out:Klimatpaverkan": -16.03,
    "out:Initial Cost/MSEK": 4.712,
    "out:Running cost/(Apt SEK y)": 17557,
    "out:Running Cost over RSP/MSEK": 6.588,
    "out:LCP/MSEK": 0.002,
    "out:ROI% old": 14.29,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 64.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 603,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200907,
    "out:DH kr savings": 199224,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195791,
    "out:% savings (space heating)": 39.79,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.76,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.84,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 115.26,
    "out:Total Energy Use Post PV": 115.26,
    "out:Primary Energy": 112.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.96,
    "out:CO2 Operational/m2": 35.5,
    "out:Total CO2/m2": 56.46,
    "out:Total CO2 (tons)": 156.23,
    "out:Klimatpaverkan": -10.79,
    "out:Initial Cost/MSEK": 4.09,
    "out:Running cost/(Apt SEK y)": 18521,
    "out:Running Cost over RSP/MSEK": 6.94,
    "out:LCP/MSEK": 0.271,
    "out:ROI% old": 15.23,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154644,
    "out:DH kr savings": 199224,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 204324,
    "out:% savings (space heating)": 37.17,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.84,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.76,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 112.26,
    "out:Total Energy Use Post PV": 112.26,
    "out:Primary Energy": 109.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.96,
    "out:CO2 Operational/m2": 34.42,
    "out:Total CO2/m2": 55.38,
    "out:Total CO2 (tons)": 153.24,
    "out:Klimatpaverkan": -13.78,
    "out:Initial Cost/MSEK": 4.159,
    "out:Running cost/(Apt SEK y)": 17999,
    "out:Running Cost over RSP/MSEK": 6.744,
    "out:LCP/MSEK": 0.398,
    "out:ROI% old": 15.65,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 207525,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162945,
    "out:DH kr savings": 207525,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195791,
    "out:% savings (space heating)": 39.79,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.76,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.84,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 110.26,
    "out:Total Energy Use Post PV": 110.26,
    "out:Primary Energy": 110.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.61,
    "out:CO2 Operational/m2": 33.7,
    "out:Total CO2/m2": 55.31,
    "out:Total CO2 (tons)": 153.05,
    "out:Klimatpaverkan": -13.97,
    "out:Initial Cost/MSEK": 4.309,
    "out:Running cost/(Apt SEK y)": 17651,
    "out:Running Cost over RSP/MSEK": 6.614,
    "out:LCP/MSEK": 0.379,
    "out:ROI% old": 15.54,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 62.7,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 599,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213059,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168479,
    "out:DH kr savings": 213059,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 204324,
    "out:% savings (space heating)": 37.17,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.85,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.76,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 107.26,
    "out:Total Energy Use Post PV": 107.26,
    "out:Primary Energy": 107.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.61,
    "out:CO2 Operational/m2": 32.62,
    "out:Total CO2/m2": 54.23,
    "out:Total CO2 (tons)": 150.06,
    "out:Klimatpaverkan": -16.96,
    "out:Initial Cost/MSEK": 4.378,
    "out:Running cost/(Apt SEK y)": 17128,
    "out:Running Cost over RSP/MSEK": 6.418,
    "out:LCP/MSEK": 0.506,
    "out:ROI% old": 15.94,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 67.3,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 624,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221360,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176780,
    "out:DH kr savings": 221360,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195791,
    "out:% savings (space heating)": 39.79,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 68.76,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.84,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 115.26,
    "out:Total Energy Use Post PV": 108.64,
    "out:Primary Energy": 100.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.84,
    "out:CO2 Operational/m2": 23.08,
    "out:Total CO2/m2": 53.92,
    "out:Total CO2 (tons)": 149.19,
    "out:Klimatpaverkan": -17.83,
    "out:Initial Cost/MSEK": 4.551,
    "out:Running cost/(Apt SEK y)": 17557,
    "out:Running Cost over RSP/MSEK": 6.588,
    "out:LCP/MSEK": 0.163,
    "out:ROI% old": 14.8,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 64.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 603,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 200907,
    "out:DH kr savings": 199224,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 204324,
    "out:% savings (space heating)": 37.17,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.84,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.76,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 112.26,
    "out:Total Energy Use Post PV": 105.64,
    "out:Primary Energy": 97.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.84,
    "out:CO2 Operational/m2": 22,
    "out:Total CO2/m2": 52.84,
    "out:Total CO2 (tons)": 146.2,
    "out:Klimatpaverkan": -20.82,
    "out:Initial Cost/MSEK": 4.62,
    "out:Running cost/(Apt SEK y)": 17035,
    "out:Running Cost over RSP/MSEK": 6.392,
    "out:LCP/MSEK": 0.29,
    "out:ROI% old": 15.18,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 68.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 207525,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209208,
    "out:DH kr savings": 207525,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195791,
    "out:% savings (space heating)": 39.79,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.76,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.84,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 110.26,
    "out:Total Energy Use Post PV": 103.64,
    "out:Primary Energy": 98.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.49,
    "out:CO2 Operational/m2": 21.28,
    "out:Total CO2/m2": 52.77,
    "out:Total CO2 (tons)": 146.01,
    "out:Klimatpaverkan": -21.01,
    "out:Initial Cost/MSEK": 4.769,
    "out:Running cost/(Apt SEK y)": 16687,
    "out:Running Cost over RSP/MSEK": 6.261,
    "out:LCP/MSEK": 0.271,
    "out:ROI% old": 15.1,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 72.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 645,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 213059,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214742,
    "out:DH kr savings": 213059,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 204324,
    "out:% savings (space heating)": 37.17,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.85,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.76,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 107.26,
    "out:Total Energy Use Post PV": 100.64,
    "out:Primary Energy": 95.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.49,
    "out:CO2 Operational/m2": 20.2,
    "out:Total CO2/m2": 51.69,
    "out:Total CO2 (tons)": 143.02,
    "out:Klimatpaverkan": -24,
    "out:Initial Cost/MSEK": 4.838,
    "out:Running cost/(Apt SEK y)": 16165,
    "out:Running Cost over RSP/MSEK": 6.065,
    "out:LCP/MSEK": 0.398,
    "out:ROI% old": 15.46,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 77.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 670,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 221360,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 223043,
    "out:DH kr savings": 221360,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195791,
    "out:% savings (space heating)": 39.79,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 68.76,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.72,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 132.2,
    "out:Primary Energy": 112.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.91,
    "out:CO2 Operational/m2": 43.44,
    "out:Total CO2/m2": 66.35,
    "out:Total CO2 (tons)": 183.6,
    "out:Klimatpaverkan": 16.58,
    "out:Initial Cost/MSEK": 4.413,
    "out:Running cost/(Apt SEK y)": 21945,
    "out:Running Cost over RSP/MSEK": 8.23,
    "out:LCP/MSEK": -1.341,
    "out:ROI% old": 9.94,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 393,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130049,
    "out:DH kr savings": 130049,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195695,
    "out:% savings (space heating)": 39.82,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.72,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.78,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 129.2,
    "out:Primary Energy": 109.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.91,
    "out:CO2 Operational/m2": 42.36,
    "out:Total CO2/m2": 65.27,
    "out:Total CO2 (tons)": 180.61,
    "out:Klimatpaverkan": 13.59,
    "out:Initial Cost/MSEK": 4.482,
    "out:Running cost/(Apt SEK y)": 21422,
    "out:Running Cost over RSP/MSEK": 8.034,
    "out:LCP/MSEK": -1.214,
    "out:ROI% old": 10.42,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 418,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138350,
    "out:DH kr savings": 138350,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 187554,
    "out:% savings (space heating)": 42.32,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 67.78,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.72,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 126.2,
    "out:Primary Energy": 109.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.56,
    "out:CO2 Operational/m2": 41.28,
    "out:Total CO2/m2": 64.84,
    "out:Total CO2 (tons)": 179.42,
    "out:Klimatpaverkan": 12.4,
    "out:Initial Cost/MSEK": 4.632,
    "out:Running cost/(Apt SEK y)": 20900,
    "out:Running Cost over RSP/MSEK": 7.838,
    "out:LCP/MSEK": -1.167,
    "out:ROI% old": 10.69,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 443,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146651,
    "out:DH kr savings": 146651,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195695,
    "out:% savings (space heating)": 39.82,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.72,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.78,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 123.2,
    "out:Total Energy Use Post PV": 123.2,
    "out:Primary Energy": 106.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.56,
    "out:CO2 Operational/m2": 40.2,
    "out:Total CO2/m2": 63.76,
    "out:Total CO2 (tons)": 176.44,
    "out:Klimatpaverkan": 9.42,
    "out:Initial Cost/MSEK": 4.701,
    "out:Running cost/(Apt SEK y)": 20378,
    "out:Running Cost over RSP/MSEK": 7.642,
    "out:LCP/MSEK": -1.041,
    "out:ROI% old": 11.12,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154952,
    "out:DH kr savings": 154952,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 187554,
    "out:% savings (space heating)": 42.32,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 67.78,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.72,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 126.92,
    "out:Primary Energy": 102.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.79,
    "out:CO2 Operational/m2": 24.38,
    "out:Total CO2/m2": 57.17,
    "out:Total CO2 (tons)": 158.2,
    "out:Klimatpaverkan": -8.82,
    "out:Initial Cost/MSEK": 4.873,
    "out:Running cost/(Apt SEK y)": 21019,
    "out:Running Cost over RSP/MSEK": 7.892,
    "out:LCP/MSEK": -1.463,
    "out:ROI% old": 10,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 437,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 174472,
    "out:DH kr savings": 130049,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195695,
    "out:% savings (space heating)": 39.82,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.72,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.78,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 123.92,
    "out:Primary Energy": 100.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.79,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 56.09,
    "out:Total CO2 (tons)": 155.21,
    "out:Klimatpaverkan": -11.81,
    "out:Initial Cost/MSEK": 4.943,
    "out:Running cost/(Apt SEK y)": 20497,
    "out:Running Cost over RSP/MSEK": 7.696,
    "out:LCP/MSEK": -1.336,
    "out:ROI% old": 10.42,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 182773,
    "out:DH kr savings": 138350,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 187554,
    "out:% savings (space heating)": 42.32,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 67.78,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.72,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 120.92,
    "out:Primary Energy": 100.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.45,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 55.67,
    "out:Total CO2 (tons)": 154.03,
    "out:Klimatpaverkan": -12.99,
    "out:Initial Cost/MSEK": 5.092,
    "out:Running cost/(Apt SEK y)": 19975,
    "out:Running Cost over RSP/MSEK": 7.499,
    "out:LCP/MSEK": -1.29,
    "out:ROI% old": 10.67,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191074,
    "out:DH kr savings": 146651,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195695,
    "out:% savings (space heating)": 39.82,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.72,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.78,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 123.2,
    "out:Total Energy Use Post PV": 117.92,
    "out:Primary Energy": 97.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.45,
    "out:CO2 Operational/m2": 21.14,
    "out:Total CO2/m2": 54.59,
    "out:Total CO2 (tons)": 151.04,
    "out:Klimatpaverkan": -15.98,
    "out:Initial Cost/MSEK": 5.161,
    "out:Running cost/(Apt SEK y)": 19452,
    "out:Running Cost over RSP/MSEK": 7.303,
    "out:LCP/MSEK": -1.163,
    "out:ROI% old": 11.07,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 512,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199375,
    "out:DH kr savings": 154952,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 187554,
    "out:% savings (space heating)": 42.32,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 67.78,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.72,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 123.2,
    "out:Total Energy Use Post PV": 123.2,
    "out:Primary Energy": 108.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.91,
    "out:CO2 Operational/m2": 40.2,
    "out:Total CO2/m2": 63.11,
    "out:Total CO2 (tons)": 174.63,
    "out:Klimatpaverkan": 7.61,
    "out:Initial Cost/MSEK": 4.54,
    "out:Running cost/(Apt SEK y)": 20378,
    "out:Running Cost over RSP/MSEK": 7.642,
    "out:LCP/MSEK": -0.879,
    "out:ROI% old": 11.52,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154952,
    "out:DH kr savings": 154952,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195695,
    "out:% savings (space heating)": 39.82,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.72,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.78,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 120.2,
    "out:Total Energy Use Post PV": 120.2,
    "out:Primary Energy": 106.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.91,
    "out:CO2 Operational/m2": 39.12,
    "out:Total CO2/m2": 62.03,
    "out:Total CO2 (tons)": 171.64,
    "out:Klimatpaverkan": 4.62,
    "out:Initial Cost/MSEK": 4.609,
    "out:Running cost/(Apt SEK y)": 19856,
    "out:Running Cost over RSP/MSEK": 7.445,
    "out:LCP/MSEK": -0.752,
    "out:ROI% old": 11.95,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 493,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163253,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163253,
    "out:DH kr savings": 163253,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 187554,
    "out:% savings (space heating)": 42.32,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 67.78,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.72,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 118.2,
    "out:Total Energy Use Post PV": 118.2,
    "out:Primary Energy": 106.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.56,
    "out:CO2 Operational/m2": 38.4,
    "out:Total CO2/m2": 61.96,
    "out:Total CO2 (tons)": 171.46,
    "out:Klimatpaverkan": 4.44,
    "out:Initial Cost/MSEK": 4.758,
    "out:Running cost/(Apt SEK y)": 19507,
    "out:Running Cost over RSP/MSEK": 7.315,
    "out:LCP/MSEK": -0.771,
    "out:ROI% old": 11.97,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 510,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 168787,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168787,
    "out:DH kr savings": 168787,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195695,
    "out:% savings (space heating)": 39.82,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.72,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.78,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 115.2,
    "out:Total Energy Use Post PV": 115.2,
    "out:Primary Energy": 103.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.56,
    "out:CO2 Operational/m2": 37.32,
    "out:Total CO2/m2": 60.88,
    "out:Total CO2 (tons)": 168.47,
    "out:Klimatpaverkan": 1.45,
    "out:Initial Cost/MSEK": 4.827,
    "out:Running cost/(Apt SEK y)": 18985,
    "out:Running Cost over RSP/MSEK": 7.119,
    "out:LCP/MSEK": -0.644,
    "out:ROI% old": 12.38,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 177088,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177088,
    "out:DH kr savings": 177088,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 187554,
    "out:% savings (space heating)": 42.32,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 67.78,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01002200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.72,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 123.2,
    "out:Total Energy Use Post PV": 117.92,
    "out:Primary Energy": 99.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.79,
    "out:CO2 Operational/m2": 21.14,
    "out:Total CO2/m2": 53.93,
    "out:Total CO2 (tons)": 149.24,
    "out:Klimatpaverkan": -17.78,
    "out:Initial Cost/MSEK": 5,
    "out:Running cost/(Apt SEK y)": 19452,
    "out:Running Cost over RSP/MSEK": 7.303,
    "out:LCP/MSEK": -1.002,
    "out:ROI% old": 11.42,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 512,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 199375,
    "out:DH kr savings": 154952,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195695,
    "out:% savings (space heating)": 39.82,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.72,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.78,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 120.2,
    "out:Total Energy Use Post PV": 114.92,
    "out:Primary Energy": 96.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.79,
    "out:CO2 Operational/m2": 20.06,
    "out:Total CO2/m2": 52.85,
    "out:Total CO2 (tons)": 146.25,
    "out:Klimatpaverkan": -20.77,
    "out:Initial Cost/MSEK": 5.069,
    "out:Running cost/(Apt SEK y)": 18930,
    "out:Running Cost over RSP/MSEK": 7.107,
    "out:LCP/MSEK": -0.875,
    "out:ROI% old": 11.82,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 537,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163253,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207676,
    "out:DH kr savings": 163253,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 187554,
    "out:% savings (space heating)": 42.32,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 67.78,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.72,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 118.2,
    "out:Total Energy Use Post PV": 112.92,
    "out:Primary Energy": 97.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.45,
    "out:CO2 Operational/m2": 19.34,
    "out:Total CO2/m2": 52.79,
    "out:Total CO2 (tons)": 146.06,
    "out:Klimatpaverkan": -20.96,
    "out:Initial Cost/MSEK": 5.219,
    "out:Running cost/(Apt SEK y)": 18582,
    "out:Running Cost over RSP/MSEK": 6.977,
    "out:LCP/MSEK": -0.893,
    "out:ROI% old": 11.84,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 58.4,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 554,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 168787,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 213210,
    "out:DH kr savings": 168787,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195695,
    "out:% savings (space heating)": 39.82,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.72,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.78,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 115.2,
    "out:Total Energy Use Post PV": 109.92,
    "out:Primary Energy": 94.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.45,
    "out:CO2 Operational/m2": 18.26,
    "out:Total CO2/m2": 51.71,
    "out:Total CO2 (tons)": 143.07,
    "out:Klimatpaverkan": -23.95,
    "out:Initial Cost/MSEK": 5.288,
    "out:Running cost/(Apt SEK y)": 18060,
    "out:Running Cost over RSP/MSEK": 6.78,
    "out:LCP/MSEK": -0.767,
    "out:ROI% old": 12.21,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 62.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 579,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 177088,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221511,
    "out:DH kr savings": 177088,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 187554,
    "out:% savings (space heating)": 42.32,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 67.78,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01002201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.72,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 121.26,
    "out:Total Energy Use Post PV": 121.26,
    "out:Primary Energy": 113.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.71,
    "out:CO2 Operational/m2": 37.66,
    "out:Total CO2/m2": 64.37,
    "out:Total CO2 (tons)": 178.12,
    "out:Klimatpaverkan": 11.1,
    "out:Initial Cost/MSEK": 5.057,
    "out:Running cost/(Apt SEK y)": 19566,
    "out:Running Cost over RSP/MSEK": 7.333,
    "out:LCP/MSEK": -1.088,
    "out:ROI% old": 11.21,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182622,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138042,
    "out:DH kr savings": 182622,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195695,
    "out:% savings (space heating)": 39.82,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.72,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.78,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 118.26,
    "out:Total Energy Use Post PV": 118.26,
    "out:Primary Energy": 110.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.71,
    "out:CO2 Operational/m2": 36.58,
    "out:Total CO2/m2": 63.29,
    "out:Total CO2 (tons)": 175.13,
    "out:Klimatpaverkan": 8.11,
    "out:Initial Cost/MSEK": 5.126,
    "out:Running cost/(Apt SEK y)": 19043,
    "out:Running Cost over RSP/MSEK": 7.136,
    "out:LCP/MSEK": -0.961,
    "out:ROI% old": 11.61,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190923,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146343,
    "out:DH kr savings": 190923,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 187554,
    "out:% savings (space heating)": 42.32,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 67.78,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.72,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 115.26,
    "out:Total Energy Use Post PV": 115.26,
    "out:Primary Energy": 110.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.36,
    "out:CO2 Operational/m2": 35.5,
    "out:Total CO2/m2": 62.87,
    "out:Total CO2 (tons)": 173.95,
    "out:Klimatpaverkan": 6.93,
    "out:Initial Cost/MSEK": 5.276,
    "out:Running cost/(Apt SEK y)": 18521,
    "out:Running Cost over RSP/MSEK": 6.94,
    "out:LCP/MSEK": -0.914,
    "out:ROI% old": 11.81,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154644,
    "out:DH kr savings": 199224,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195695,
    "out:% savings (space heating)": 39.82,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.72,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.78,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 112.26,
    "out:Total Energy Use Post PV": 112.26,
    "out:Primary Energy": 108.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.36,
    "out:CO2 Operational/m2": 34.42,
    "out:Total CO2/m2": 61.79,
    "out:Total CO2 (tons)": 170.96,
    "out:Klimatpaverkan": 3.94,
    "out:Initial Cost/MSEK": 5.345,
    "out:Running cost/(Apt SEK y)": 17999,
    "out:Running Cost over RSP/MSEK": 6.744,
    "out:LCP/MSEK": -0.787,
    "out:ROI% old": 12.18,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 207525,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162945,
    "out:DH kr savings": 207525,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 187554,
    "out:% savings (space heating)": 42.32,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 67.78,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.72,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 121.26,
    "out:Total Energy Use Post PV": 114.64,
    "out:Primary Energy": 101.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 36.59,
    "out:CO2 Operational/m2": 25.24,
    "out:Total CO2/m2": 61.83,
    "out:Total CO2 (tons)": 171.08,
    "out:Klimatpaverkan": 4.06,
    "out:Initial Cost/MSEK": 5.518,
    "out:Running cost/(Apt SEK y)": 18602,
    "out:Running Cost over RSP/MSEK": 6.98,
    "out:LCP/MSEK": -1.196,
    "out:ROI% old": 11.19,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182622,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184305,
    "out:DH kr savings": 182622,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195695,
    "out:% savings (space heating)": 39.82,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.72,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.78,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 118.26,
    "out:Total Energy Use Post PV": 111.64,
    "out:Primary Energy": 98.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.59,
    "out:CO2 Operational/m2": 24.16,
    "out:Total CO2/m2": 60.75,
    "out:Total CO2 (tons)": 168.09,
    "out:Klimatpaverkan": 1.07,
    "out:Initial Cost/MSEK": 5.587,
    "out:Running cost/(Apt SEK y)": 18080,
    "out:Running Cost over RSP/MSEK": 6.784,
    "out:LCP/MSEK": -1.069,
    "out:ROI% old": 11.55,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 578,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190923,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192606,
    "out:DH kr savings": 190923,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 187554,
    "out:% savings (space heating)": 42.32,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 67.78,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.72,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 115.26,
    "out:Total Energy Use Post PV": 108.64,
    "out:Primary Energy": 98.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.24,
    "out:CO2 Operational/m2": 23.08,
    "out:Total CO2/m2": 60.32,
    "out:Total CO2 (tons)": 166.91,
    "out:Klimatpaverkan": -0.11,
    "out:Initial Cost/MSEK": 5.736,
    "out:Running cost/(Apt SEK y)": 17557,
    "out:Running Cost over RSP/MSEK": 6.588,
    "out:LCP/MSEK": -1.023,
    "out:ROI% old": 11.74,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 64.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 603,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 200907,
    "out:DH kr savings": 199224,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195695,
    "out:% savings (space heating)": 39.82,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.72,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.78,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 112.26,
    "out:Total Energy Use Post PV": 105.64,
    "out:Primary Energy": 96.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.24,
    "out:CO2 Operational/m2": 22,
    "out:Total CO2/m2": 59.24,
    "out:Total CO2 (tons)": 163.92,
    "out:Klimatpaverkan": -3.1,
    "out:Initial Cost/MSEK": 5.805,
    "out:Running cost/(Apt SEK y)": 17035,
    "out:Running Cost over RSP/MSEK": 6.392,
    "out:LCP/MSEK": -0.896,
    "out:ROI% old": 12.08,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 68.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 207525,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209208,
    "out:DH kr savings": 207525,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 187554,
    "out:% savings (space heating)": 42.32,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 67.78,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.72,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 112.26,
    "out:Total Energy Use Post PV": 112.26,
    "out:Primary Energy": 109.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.71,
    "out:CO2 Operational/m2": 34.42,
    "out:Total CO2/m2": 61.13,
    "out:Total CO2 (tons)": 169.16,
    "out:Klimatpaverkan": 2.14,
    "out:Initial Cost/MSEK": 5.184,
    "out:Running cost/(Apt SEK y)": 17999,
    "out:Running Cost over RSP/MSEK": 6.744,
    "out:LCP/MSEK": -0.626,
    "out:ROI% old": 12.56,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 207525,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162945,
    "out:DH kr savings": 207525,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195695,
    "out:% savings (space heating)": 39.82,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.72,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.78,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 109.26,
    "out:Total Energy Use Post PV": 109.26,
    "out:Primary Energy": 107.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.71,
    "out:CO2 Operational/m2": 33.34,
    "out:Total CO2/m2": 60.05,
    "out:Total CO2 (tons)": 166.17,
    "out:Klimatpaverkan": -0.85,
    "out:Initial Cost/MSEK": 5.253,
    "out:Running cost/(Apt SEK y)": 17477,
    "out:Running Cost over RSP/MSEK": 6.548,
    "out:LCP/MSEK": -0.499,
    "out:ROI% old": 12.93,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 64.2,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 607,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 215826,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171246,
    "out:DH kr savings": 215826,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 187554,
    "out:% savings (space heating)": 42.32,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 67.78,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.72,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 107.26,
    "out:Total Energy Use Post PV": 107.26,
    "out:Primary Energy": 107.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.36,
    "out:CO2 Operational/m2": 32.62,
    "out:Total CO2/m2": 59.99,
    "out:Total CO2 (tons)": 165.98,
    "out:Klimatpaverkan": -1.04,
    "out:Initial Cost/MSEK": 5.403,
    "out:Running cost/(Apt SEK y)": 17128,
    "out:Running Cost over RSP/MSEK": 6.418,
    "out:LCP/MSEK": -0.518,
    "out:ROI% old": 12.92,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 67.3,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 624,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 221360,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176780,
    "out:DH kr savings": 221360,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195695,
    "out:% savings (space heating)": 39.82,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 68.73,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.78,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 104.26,
    "out:Total Energy Use Post PV": 104.26,
    "out:Primary Energy": 104.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.36,
    "out:CO2 Operational/m2": 31.54,
    "out:Total CO2/m2": 58.91,
    "out:Total CO2 (tons)": 162.99,
    "out:Klimatpaverkan": -4.03,
    "out:Initial Cost/MSEK": 5.472,
    "out:Running cost/(Apt SEK y)": 16606,
    "out:Running Cost over RSP/MSEK": 6.221,
    "out:LCP/MSEK": -0.391,
    "out:ROI% old": 13.26,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 72.1,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 649,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 229661,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185081,
    "out:DH kr savings": 229661,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 187554,
    "out:% savings (space heating)": 42.32,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 65.79,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01002201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.72,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 112.26,
    "out:Total Energy Use Post PV": 105.64,
    "out:Primary Energy": 97.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.59,
    "out:CO2 Operational/m2": 22,
    "out:Total CO2/m2": 58.59,
    "out:Total CO2 (tons)": 162.11,
    "out:Klimatpaverkan": -4.91,
    "out:Initial Cost/MSEK": 5.644,
    "out:Running cost/(Apt SEK y)": 17035,
    "out:Running Cost over RSP/MSEK": 6.392,
    "out:LCP/MSEK": -0.735,
    "out:ROI% old": 12.43,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 68.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 207525,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 209208,
    "out:DH kr savings": 207525,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195695,
    "out:% savings (space heating)": 39.82,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.72,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.78,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 109.26,
    "out:Total Energy Use Post PV": 102.64,
    "out:Primary Energy": 95.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.59,
    "out:CO2 Operational/m2": 20.92,
    "out:Total CO2/m2": 57.51,
    "out:Total CO2 (tons)": 159.13,
    "out:Klimatpaverkan": -7.89,
    "out:Initial Cost/MSEK": 5.714,
    "out:Running cost/(Apt SEK y)": 16513,
    "out:Running Cost over RSP/MSEK": 6.196,
    "out:LCP/MSEK": -0.608,
    "out:ROI% old": 12.77,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 73.8,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 653,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 215826,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217509,
    "out:DH kr savings": 215826,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 187554,
    "out:% savings (space heating)": 42.32,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 67.78,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.72,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 107.26,
    "out:Total Energy Use Post PV": 100.64,
    "out:Primary Energy": 95.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.24,
    "out:CO2 Operational/m2": 20.2,
    "out:Total CO2/m2": 57.44,
    "out:Total CO2 (tons)": 158.94,
    "out:Klimatpaverkan": -8.08,
    "out:Initial Cost/MSEK": 5.863,
    "out:Running cost/(Apt SEK y)": 16165,
    "out:Running Cost over RSP/MSEK": 6.065,
    "out:LCP/MSEK": -0.626,
    "out:ROI% old": 12.76,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 77.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 670,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221360,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 223043,
    "out:DH kr savings": 221360,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 195695,
    "out:% savings (space heating)": 39.82,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 68.73,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01002201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.78,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 104.26,
    "out:Total Energy Use Post PV": 97.64,
    "out:Primary Energy": 92.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.24,
    "out:CO2 Operational/m2": 19.12,
    "out:Total CO2/m2": 56.36,
    "out:Total CO2 (tons)": 155.95,
    "out:Klimatpaverkan": -11.07,
    "out:Initial Cost/MSEK": 5.932,
    "out:Running cost/(Apt SEK y)": 15642,
    "out:Running Cost over RSP/MSEK": 5.869,
    "out:LCP/MSEK": -0.5,
    "out:ROI% old": 13.08,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 82.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 695,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 229661,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231344,
    "out:DH kr savings": 229661,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 187554,
    "out:% savings (space heating)": 42.32,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 65.79,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 160.2,
    "out:Total Energy Use Post PV": 160.2,
    "out:Primary Energy": 136.89,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.44,
    "out:CO2 Operational/m2": 53.52,
    "out:Total CO2/m2": 66.96,
    "out:Total CO2 (tons)": 185.27,
    "out:Klimatpaverkan": 18.25,
    "out:Initial Cost/MSEK": 2.681,
    "out:Running cost/(Apt SEK y)": 26819,
    "out:Running Cost over RSP/MSEK": 10.06,
    "out:LCP/MSEK": -1.439,
    "out:ROI% old": 6.62,
    "out:Payback discounted": 18,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 6,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 52573,
    "out:DH kr savings": 52573,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 156.2,
    "out:Primary Energy": 133.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.44,
    "out:CO2 Operational/m2": 52.08,
    "out:Total CO2/m2": 65.52,
    "out:Total CO2 (tons)": 181.28,
    "out:Klimatpaverkan": 14.26,
    "out:Initial Cost/MSEK": 2.75,
    "out:Running cost/(Apt SEK y)": 26123,
    "out:Running Cost over RSP/MSEK": 9.798,
    "out:LCP/MSEK": -1.247,
    "out:ROI% old": 7.81,
    "out:Payback discounted": 15,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 7,
    "out:Return/kSEK/y": 192,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 63641,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 63641,
    "out:DH kr savings": 63641,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 154.2,
    "out:Primary Energy": 134.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.09,
    "out:CO2 Operational/m2": 51.36,
    "out:Total CO2/m2": 65.45,
    "out:Total CO2 (tons)": 181.09,
    "out:Klimatpaverkan": 14.07,
    "out:Initial Cost/MSEK": 2.9,
    "out:Running cost/(Apt SEK y)": 25775,
    "out:Running Cost over RSP/MSEK": 9.668,
    "out:LCP/MSEK": -1.266,
    "out:ROI% old": 8.05,
    "out:Payback discounted": 15,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 7,
    "out:Return/kSEK/y": 209,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69175,
    "out:DH kr savings": 69175,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 150.2,
    "out:Primary Energy": 130.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.09,
    "out:CO2 Operational/m2": 49.92,
    "out:Total CO2/m2": 64.01,
    "out:Total CO2 (tons)": 177.11,
    "out:Klimatpaverkan": 10.09,
    "out:Initial Cost/MSEK": 2.969,
    "out:Running cost/(Apt SEK y)": 25078,
    "out:Running Cost over RSP/MSEK": 9.406,
    "out:LCP/MSEK": -1.074,
    "out:ROI% old": 9.12,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 19.3,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 242,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 80243,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 80243,
    "out:DH kr savings": 80243,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 160.2,
    "out:Total Energy Use Post PV": 154.92,
    "out:Primary Energy": 127.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.32,
    "out:CO2 Operational/m2": 34.46,
    "out:Total CO2/m2": 57.78,
    "out:Total CO2 (tons)": 159.87,
    "out:Klimatpaverkan": -7.15,
    "out:Initial Cost/MSEK": 3.142,
    "out:Running cost/(Apt SEK y)": 25894,
    "out:Running Cost over RSP/MSEK": 9.722,
    "out:LCP/MSEK": -1.562,
    "out:ROI% old": 7.18,
    "out:Payback discounted": 17,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 6,
    "out:Return/kSEK/y": 203,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 52573,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96996,
    "out:DH kr savings": 52573,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 156.2,
    "out:Total Energy Use Post PV": 150.92,
    "out:Primary Energy": 123.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.32,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 56.34,
    "out:Total CO2 (tons)": 155.89,
    "out:Klimatpaverkan": -11.13,
    "out:Initial Cost/MSEK": 3.211,
    "out:Running cost/(Apt SEK y)": 25197,
    "out:Running Cost over RSP/MSEK": 9.46,
    "out:LCP/MSEK": -1.369,
    "out:ROI% old": 8.19,
    "out:Payback discounted": 15,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 237,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 63641,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 108064,
    "out:DH kr savings": 63641,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 154.2,
    "out:Total Energy Use Post PV": 148.92,
    "out:Primary Energy": 124.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.97,
    "out:CO2 Operational/m2": 32.3,
    "out:Total CO2/m2": 56.27,
    "out:Total CO2 (tons)": 155.7,
    "out:Klimatpaverkan": -11.32,
    "out:Initial Cost/MSEK": 3.36,
    "out:Running cost/(Apt SEK y)": 24849,
    "out:Running Cost over RSP/MSEK": 9.33,
    "out:LCP/MSEK": -1.388,
    "out:ROI% old": 8.38,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 69175,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113598,
    "out:DH kr savings": 69175,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 144.92,
    "out:Primary Energy": 121.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.97,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 54.83,
    "out:Total CO2 (tons)": 151.72,
    "out:Klimatpaverkan": -15.3,
    "out:Initial Cost/MSEK": 3.43,
    "out:Running cost/(Apt SEK y)": 24153,
    "out:Running Cost over RSP/MSEK": 9.068,
    "out:LCP/MSEK": -1.196,
    "out:ROI% old": 9.3,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80243,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124666,
    "out:DH kr savings": 80243,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 152.2,
    "out:Primary Energy": 133.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.44,
    "out:CO2 Operational/m2": 50.64,
    "out:Total CO2/m2": 64.08,
    "out:Total CO2 (tons)": 177.3,
    "out:Klimatpaverkan": 10.28,
    "out:Initial Cost/MSEK": 2.808,
    "out:Running cost/(Apt SEK y)": 25426,
    "out:Running Cost over RSP/MSEK": 9.537,
    "out:LCP/MSEK": -1.043,
    "out:ROI% old": 8.98,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 17.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 226,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 74709,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74709,
    "out:DH kr savings": 74709,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 147.2,
    "out:Primary Energy": 129.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.44,
    "out:CO2 Operational/m2": 48.84,
    "out:Total CO2/m2": 62.28,
    "out:Total CO2 (tons)": 172.32,
    "out:Klimatpaverkan": 5.3,
    "out:Initial Cost/MSEK": 2.877,
    "out:Running cost/(Apt SEK y)": 24556,
    "out:Running Cost over RSP/MSEK": 9.21,
    "out:LCP/MSEK": -0.786,
    "out:ROI% old": 10.39,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 267,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88544,
    "out:DH kr savings": 88544,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 146.2,
    "out:Total Energy Use Post PV": 146.2,
    "out:Primary Energy": 131.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.09,
    "out:CO2 Operational/m2": 48.48,
    "out:Total CO2/m2": 62.57,
    "out:Total CO2 (tons)": 173.13,
    "out:Klimatpaverkan": 6.11,
    "out:Initial Cost/MSEK": 3.027,
    "out:Running cost/(Apt SEK y)": 24382,
    "out:Running Cost over RSP/MSEK": 9.145,
    "out:LCP/MSEK": -0.87,
    "out:ROI% old": 10.18,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 276,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91311,
    "out:DH kr savings": 91311,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 142.2,
    "out:Primary Energy": 127.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.09,
    "out:CO2 Operational/m2": 47.04,
    "out:Total CO2/m2": 61.13,
    "out:Total CO2 (tons)": 169.14,
    "out:Klimatpaverkan": 2.12,
    "out:Initial Cost/MSEK": 3.096,
    "out:Running cost/(Apt SEK y)": 23686,
    "out:Running Cost over RSP/MSEK": 8.883,
    "out:LCP/MSEK": -0.677,
    "out:ROI% old": 11.16,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102379,
    "out:DH kr savings": 102379,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 152.2,
    "out:Total Energy Use Post PV": 146.92,
    "out:Primary Energy": 123.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.32,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 54.9,
    "out:Total CO2 (tons)": 151.9,
    "out:Klimatpaverkan": -15.12,
    "out:Initial Cost/MSEK": 3.268,
    "out:Running cost/(Apt SEK y)": 24501,
    "out:Running Cost over RSP/MSEK": 9.199,
    "out:LCP/MSEK": -1.165,
    "out:ROI% old": 9.19,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 270,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 74709,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119132,
    "out:DH kr savings": 74709,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 141.92,
    "out:Primary Energy": 120.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.32,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 53.1,
    "out:Total CO2 (tons)": 146.92,
    "out:Klimatpaverkan": -20.1,
    "out:Initial Cost/MSEK": 3.338,
    "out:Running cost/(Apt SEK y)": 23630,
    "out:Running Cost over RSP/MSEK": 8.872,
    "out:LCP/MSEK": -0.908,
    "out:ROI% old": 10.4,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 312,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132967,
    "out:DH kr savings": 88544,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 146.2,
    "out:Total Energy Use Post PV": 140.92,
    "out:Primary Energy": 121.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.97,
    "out:CO2 Operational/m2": 29.42,
    "out:Total CO2/m2": 53.39,
    "out:Total CO2 (tons)": 147.73,
    "out:Klimatpaverkan": -19.29,
    "out:Initial Cost/MSEK": 3.487,
    "out:Running cost/(Apt SEK y)": 23456,
    "out:Running Cost over RSP/MSEK": 8.807,
    "out:LCP/MSEK": -0.992,
    "out:ROI% old": 10.22,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 320,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 91311,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135734,
    "out:DH kr savings": 91311,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 136.92,
    "out:Primary Energy": 118.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.97,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 51.95,
    "out:Total CO2 (tons)": 143.75,
    "out:Klimatpaverkan": -23.27,
    "out:Initial Cost/MSEK": 3.556,
    "out:Running cost/(Apt SEK y)": 22760,
    "out:Running Cost over RSP/MSEK": 8.545,
    "out:LCP/MSEK": -0.8,
    "out:ROI% old": 11.07,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 354,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146802,
    "out:DH kr savings": 102379,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 149.26,
    "out:Total Energy Use Post PV": 149.26,
    "out:Primary Energy": 138.1,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.23,
    "out:CO2 Operational/m2": 47.74,
    "out:Total CO2/m2": 64.98,
    "out:Total CO2 (tons)": 179.79,
    "out:Klimatpaverkan": 12.77,
    "out:Initial Cost/MSEK": 3.326,
    "out:Running cost/(Apt SEK y)": 24440,
    "out:Running Cost over RSP/MSEK": 9.163,
    "out:LCP/MSEK": -1.186,
    "out:ROI% old": 9.19,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 20.1,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 273,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 60566,
    "out:DH kr savings": 105146,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 145.26,
    "out:Total Energy Use Post PV": 145.26,
    "out:Primary Energy": 134.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.23,
    "out:CO2 Operational/m2": 46.3,
    "out:Total CO2/m2": 63.54,
    "out:Total CO2 (tons)": 175.81,
    "out:Klimatpaverkan": 8.79,
    "out:Initial Cost/MSEK": 3.395,
    "out:Running cost/(Apt SEK y)": 23744,
    "out:Running Cost over RSP/MSEK": 8.901,
    "out:LCP/MSEK": -0.994,
    "out:ROI% old": 10.1,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 306,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71634,
    "out:DH kr savings": 116214,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 143.26,
    "out:Primary Energy": 135.47,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.88,
    "out:CO2 Operational/m2": 45.58,
    "out:Total CO2/m2": 63.47,
    "out:Total CO2 (tons)": 175.62,
    "out:Klimatpaverkan": 8.6,
    "out:Initial Cost/MSEK": 3.544,
    "out:Running cost/(Apt SEK y)": 23396,
    "out:Running Cost over RSP/MSEK": 8.771,
    "out:LCP/MSEK": -1.013,
    "out:ROI% old": 10.2,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77168,
    "out:DH kr savings": 121748,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 139.26,
    "out:Total Energy Use Post PV": 139.26,
    "out:Primary Energy": 131.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.88,
    "out:CO2 Operational/m2": 44.14,
    "out:Total CO2/m2": 62.03,
    "out:Total CO2 (tons)": 171.63,
    "out:Klimatpaverkan": 4.61,
    "out:Initial Cost/MSEK": 3.613,
    "out:Running cost/(Apt SEK y)": 22699,
    "out:Running Cost over RSP/MSEK": 8.509,
    "out:LCP/MSEK": -0.821,
    "out:ROI% old": 11.04,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88236,
    "out:DH kr savings": 132816,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 149.26,
    "out:Total Energy Use Post PV": 142.64,
    "out:Primary Energy": 126.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.12,
    "out:CO2 Operational/m2": 35.32,
    "out:Total CO2/m2": 62.43,
    "out:Total CO2 (tons)": 172.75,
    "out:Klimatpaverkan": 5.73,
    "out:Initial Cost/MSEK": 3.786,
    "out:Running cost/(Apt SEK y)": 23476,
    "out:Running Cost over RSP/MSEK": 8.81,
    "out:LCP/MSEK": -1.295,
    "out:ROI% old": 9.4,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 319,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 106829,
    "out:DH kr savings": 105146,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 145.26,
    "out:Total Energy Use Post PV": 138.64,
    "out:Primary Energy": 122.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.12,
    "out:CO2 Operational/m2": 33.88,
    "out:Total CO2/m2": 60.99,
    "out:Total CO2 (tons)": 168.77,
    "out:Klimatpaverkan": 1.75,
    "out:Initial Cost/MSEK": 3.855,
    "out:Running cost/(Apt SEK y)": 22780,
    "out:Running Cost over RSP/MSEK": 8.549,
    "out:LCP/MSEK": -1.102,
    "out:ROI% old": 10.2,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 353,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117897,
    "out:DH kr savings": 116214,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 143.26,
    "out:Total Energy Use Post PV": 136.64,
    "out:Primary Energy": 123.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.77,
    "out:CO2 Operational/m2": 33.16,
    "out:Total CO2/m2": 60.92,
    "out:Total CO2 (tons)": 168.58,
    "out:Klimatpaverkan": 1.56,
    "out:Initial Cost/MSEK": 4.005,
    "out:Running cost/(Apt SEK y)": 22432,
    "out:Running Cost over RSP/MSEK": 8.418,
    "out:LCP/MSEK": -1.121,
    "out:ROI% old": 10.29,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123431,
    "out:DH kr savings": 121748,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 139.26,
    "out:Total Energy Use Post PV": 132.64,
    "out:Primary Energy": 119.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.77,
    "out:CO2 Operational/m2": 31.72,
    "out:Total CO2/m2": 59.48,
    "out:Total CO2 (tons)": 164.59,
    "out:Klimatpaverkan": -2.43,
    "out:Initial Cost/MSEK": 4.074,
    "out:Running cost/(Apt SEK y)": 21735,
    "out:Running Cost over RSP/MSEK": 8.157,
    "out:LCP/MSEK": -0.929,
    "out:ROI% old": 11.03,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 403,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134499,
    "out:DH kr savings": 132816,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 141.26,
    "out:Total Energy Use Post PV": 141.26,
    "out:Primary Energy": 134.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.23,
    "out:CO2 Operational/m2": 44.86,
    "out:Total CO2/m2": 62.1,
    "out:Total CO2 (tons)": 171.82,
    "out:Klimatpaverkan": 4.8,
    "out:Initial Cost/MSEK": 3.452,
    "out:Running cost/(Apt SEK y)": 23047,
    "out:Running Cost over RSP/MSEK": 8.64,
    "out:LCP/MSEK": -0.79,
    "out:ROI% old": 11.02,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82702,
    "out:DH kr savings": 127282,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 136.26,
    "out:Primary Energy": 130.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.23,
    "out:CO2 Operational/m2": 43.06,
    "out:Total CO2/m2": 60.3,
    "out:Total CO2 (tons)": 166.84,
    "out:Klimatpaverkan": -0.18,
    "out:Initial Cost/MSEK": 3.521,
    "out:Running cost/(Apt SEK y)": 22177,
    "out:Running Cost over RSP/MSEK": 8.313,
    "out:LCP/MSEK": -0.533,
    "out:ROI% old": 12.13,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 96537,
    "out:DH kr savings": 141117,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 135.26,
    "out:Total Energy Use Post PV": 135.26,
    "out:Primary Energy": 132.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.88,
    "out:CO2 Operational/m2": 42.7,
    "out:Total CO2/m2": 60.59,
    "out:Total CO2 (tons)": 167.65,
    "out:Klimatpaverkan": 0.63,
    "out:Initial Cost/MSEK": 3.671,
    "out:Running cost/(Apt SEK y)": 22003,
    "out:Running Cost over RSP/MSEK": 8.248,
    "out:LCP/MSEK": -0.617,
    "out:ROI% old": 11.89,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 390,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 143884,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99304,
    "out:DH kr savings": 143884,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.01,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 131.26,
    "out:Total Energy Use Post PV": 131.26,
    "out:Primary Energy": 128.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.88,
    "out:CO2 Operational/m2": 41.26,
    "out:Total CO2/m2": 59.15,
    "out:Total CO2 (tons)": 163.67,
    "out:Klimatpaverkan": -3.35,
    "out:Initial Cost/MSEK": 3.74,
    "out:Running cost/(Apt SEK y)": 21307,
    "out:Running Cost over RSP/MSEK": 7.986,
    "out:LCP/MSEK": -0.424,
    "out:ROI% old": 12.66,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 110372,
    "out:DH kr savings": 154952,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.79,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 141.26,
    "out:Total Energy Use Post PV": 134.64,
    "out:Primary Energy": 122.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.12,
    "out:CO2 Operational/m2": 32.44,
    "out:Total CO2/m2": 59.55,
    "out:Total CO2 (tons)": 164.78,
    "out:Klimatpaverkan": -2.24,
    "out:Initial Cost/MSEK": 3.913,
    "out:Running cost/(Apt SEK y)": 22084,
    "out:Running Cost over RSP/MSEK": 8.288,
    "out:LCP/MSEK": -0.898,
    "out:ROI% old": 11.01,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128965,
    "out:DH kr savings": 127282,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 99.01,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 129.64,
    "out:Primary Energy": 118.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.12,
    "out:CO2 Operational/m2": 30.64,
    "out:Total CO2/m2": 57.75,
    "out:Total CO2 (tons)": 159.8,
    "out:Klimatpaverkan": -7.22,
    "out:Initial Cost/MSEK": 3.982,
    "out:Running cost/(Apt SEK y)": 21213,
    "out:Running Cost over RSP/MSEK": 7.961,
    "out:LCP/MSEK": -0.641,
    "out:ROI% old": 11.99,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142800,
    "out:DH kr savings": 141117,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 94.78,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.01,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 135.26,
    "out:Total Energy Use Post PV": 128.64,
    "out:Primary Energy": 120.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.77,
    "out:CO2 Operational/m2": 30.28,
    "out:Total CO2/m2": 58.04,
    "out:Total CO2 (tons)": 160.61,
    "out:Klimatpaverkan": -6.41,
    "out:Initial Cost/MSEK": 4.131,
    "out:Running cost/(Apt SEK y)": 21039,
    "out:Running Cost over RSP/MSEK": 7.895,
    "out:LCP/MSEK": -0.725,
    "out:ROI% old": 11.78,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 436,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 143884,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145567,
    "out:DH kr savings": 143884,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 273955,
    "out:% savings (space heating)": 15.75,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 97.01,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.78,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 131.26,
    "out:Total Energy Use Post PV": 124.64,
    "out:Primary Energy": 116.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.77,
    "out:CO2 Operational/m2": 28.84,
    "out:Total CO2/m2": 56.6,
    "out:Total CO2 (tons)": 156.63,
    "out:Klimatpaverkan": -10.39,
    "out:Initial Cost/MSEK": 4.2,
    "out:Running cost/(Apt SEK y)": 20343,
    "out:Running Cost over RSP/MSEK": 7.634,
    "out:LCP/MSEK": -0.533,
    "out:ROI% old": 12.47,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.64,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156635,
    "out:DH kr savings": 154952,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 262257,
    "out:% savings (space heating)": 19.35,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 92.79,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 151.2,
    "out:Total Energy Use Post PV": 151.2,
    "out:Primary Energy": 129.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.75,
    "out:CO2 Operational/m2": 50.28,
    "out:Total CO2/m2": 66.03,
    "out:Total CO2 (tons)": 182.71,
    "out:Klimatpaverkan": 15.69,
    "out:Initial Cost/MSEK": 3.128,
    "out:Running cost/(Apt SEK y)": 25252,
    "out:Running Cost over RSP/MSEK": 9.472,
    "out:LCP/MSEK": -1.298,
    "out:ROI% old": 8.36,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 18.5,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 7,
    "out:Return/kSEK/y": 234,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77476,
    "out:DH kr savings": 77476,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 148.2,
    "out:Primary Energy": 125.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.75,
    "out:CO2 Operational/m2": 49.2,
    "out:Total CO2/m2": 64.95,
    "out:Total CO2 (tons)": 179.72,
    "out:Klimatpaverkan": 12.7,
    "out:Initial Cost/MSEK": 3.197,
    "out:Running cost/(Apt SEK y)": 24730,
    "out:Running Cost over RSP/MSEK": 9.276,
    "out:LCP/MSEK": -1.171,
    "out:ROI% old": 9.05,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 259,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85777,
    "out:DH kr savings": 85777,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 145.2,
    "out:Primary Energy": 126.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.4,
    "out:CO2 Operational/m2": 48.12,
    "out:Total CO2/m2": 64.52,
    "out:Total CO2 (tons)": 178.53,
    "out:Klimatpaverkan": 11.51,
    "out:Initial Cost/MSEK": 3.347,
    "out:Running cost/(Apt SEK y)": 24208,
    "out:Running Cost over RSP/MSEK": 9.079,
    "out:LCP/MSEK": -1.124,
    "out:ROI% old": 9.49,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 94078,
    "out:DH kr savings": 94078,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 141.2,
    "out:Total Energy Use Post PV": 141.2,
    "out:Primary Energy": 123.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.4,
    "out:CO2 Operational/m2": 46.68,
    "out:Total CO2/m2": 63.08,
    "out:Total CO2 (tons)": 174.55,
    "out:Klimatpaverkan": 7.53,
    "out:Initial Cost/MSEK": 3.416,
    "out:Running cost/(Apt SEK y)": 23511,
    "out:Running Cost over RSP/MSEK": 8.818,
    "out:LCP/MSEK": -0.932,
    "out:ROI% old": 10.39,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 318,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105146,
    "out:DH kr savings": 105146,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 151.2,
    "out:Total Energy Use Post PV": 145.92,
    "out:Primary Energy": 119.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.63,
    "out:CO2 Operational/m2": 31.22,
    "out:Total CO2/m2": 56.85,
    "out:Total CO2 (tons)": 157.31,
    "out:Klimatpaverkan": -9.71,
    "out:Initial Cost/MSEK": 3.588,
    "out:Running cost/(Apt SEK y)": 24327,
    "out:Running Cost over RSP/MSEK": 9.133,
    "out:LCP/MSEK": -1.42,
    "out:ROI% old": 8.63,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 22.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 278,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 77476,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121899,
    "out:DH kr savings": 77476,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 142.92,
    "out:Primary Energy": 116.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.63,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 55.77,
    "out:Total CO2 (tons)": 154.32,
    "out:Klimatpaverkan": -12.7,
    "out:Initial Cost/MSEK": 3.658,
    "out:Running cost/(Apt SEK y)": 23805,
    "out:Running Cost over RSP/MSEK": 8.937,
    "out:LCP/MSEK": -1.293,
    "out:ROI% old": 9.23,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130200,
    "out:DH kr savings": 85777,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 139.92,
    "out:Primary Energy": 117.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.28,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 55.35,
    "out:Total CO2 (tons)": 153.14,
    "out:Klimatpaverkan": -13.88,
    "out:Initial Cost/MSEK": 3.807,
    "out:Running cost/(Apt SEK y)": 23282,
    "out:Running Cost over RSP/MSEK": 8.741,
    "out:LCP/MSEK": -1.246,
    "out:ROI% old": 9.61,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 329,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138501,
    "out:DH kr savings": 94078,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 141.2,
    "out:Total Energy Use Post PV": 135.92,
    "out:Primary Energy": 113.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.28,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 53.91,
    "out:Total CO2 (tons)": 149.16,
    "out:Klimatpaverkan": -17.86,
    "out:Initial Cost/MSEK": 3.876,
    "out:Running cost/(Apt SEK y)": 22586,
    "out:Running Cost over RSP/MSEK": 8.48,
    "out:LCP/MSEK": -1.054,
    "out:ROI% old": 10.4,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149569,
    "out:DH kr savings": 105146,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 143.2,
    "out:Primary Energy": 125.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.75,
    "out:CO2 Operational/m2": 47.4,
    "out:Total CO2/m2": 63.15,
    "out:Total CO2 (tons)": 174.74,
    "out:Klimatpaverkan": 7.72,
    "out:Initial Cost/MSEK": 3.255,
    "out:Running cost/(Apt SEK y)": 23860,
    "out:Running Cost over RSP/MSEK": 8.949,
    "out:LCP/MSEK": -0.902,
    "out:ROI% old": 10.33,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 301,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99612,
    "out:DH kr savings": 99612,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 139.2,
    "out:Total Energy Use Post PV": 139.2,
    "out:Primary Energy": 122.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.75,
    "out:CO2 Operational/m2": 45.96,
    "out:Total CO2/m2": 61.71,
    "out:Total CO2 (tons)": 170.75,
    "out:Klimatpaverkan": 3.73,
    "out:Initial Cost/MSEK": 3.324,
    "out:Running cost/(Apt SEK y)": 23163,
    "out:Running Cost over RSP/MSEK": 8.687,
    "out:LCP/MSEK": -0.709,
    "out:ROI% old": 11.24,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 334,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 110680,
    "out:DH kr savings": 110680,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 138.2,
    "out:Primary Energy": 123.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.4,
    "out:CO2 Operational/m2": 45.6,
    "out:Total CO2/m2": 62,
    "out:Total CO2 (tons)": 171.56,
    "out:Klimatpaverkan": 4.54,
    "out:Initial Cost/MSEK": 3.473,
    "out:Running cost/(Apt SEK y)": 22989,
    "out:Running Cost over RSP/MSEK": 8.622,
    "out:LCP/MSEK": -0.793,
    "out:ROI% old": 11.02,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 343,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113447,
    "out:DH kr savings": 113447,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 134.2,
    "out:Primary Energy": 120.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.4,
    "out:CO2 Operational/m2": 44.16,
    "out:Total CO2/m2": 60.56,
    "out:Total CO2 (tons)": 167.58,
    "out:Klimatpaverkan": 0.56,
    "out:Initial Cost/MSEK": 3.542,
    "out:Running cost/(Apt SEK y)": 22293,
    "out:Running Cost over RSP/MSEK": 8.361,
    "out:LCP/MSEK": -0.601,
    "out:ROI% old": 11.86,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124515,
    "out:DH kr savings": 124515,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 137.92,
    "out:Primary Energy": 116.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.63,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 53.97,
    "out:Total CO2 (tons)": 149.34,
    "out:Klimatpaverkan": -17.68,
    "out:Initial Cost/MSEK": 3.715,
    "out:Running cost/(Apt SEK y)": 22934,
    "out:Running Cost over RSP/MSEK": 8.611,
    "out:LCP/MSEK": -1.024,
    "out:ROI% old": 10.35,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 345,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144035,
    "out:DH kr savings": 99612,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 139.2,
    "out:Total Energy Use Post PV": 133.92,
    "out:Primary Energy": 112.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.63,
    "out:CO2 Operational/m2": 26.9,
    "out:Total CO2/m2": 52.53,
    "out:Total CO2 (tons)": 145.36,
    "out:Klimatpaverkan": -21.66,
    "out:Initial Cost/MSEK": 3.784,
    "out:Running cost/(Apt SEK y)": 22238,
    "out:Running Cost over RSP/MSEK": 8.349,
    "out:LCP/MSEK": -0.832,
    "out:ROI% old": 11.15,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 379,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155103,
    "out:DH kr savings": 110680,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 132.92,
    "out:Primary Energy": 114.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.28,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 52.83,
    "out:Total CO2 (tons)": 146.17,
    "out:Klimatpaverkan": -20.85,
    "out:Initial Cost/MSEK": 3.934,
    "out:Running cost/(Apt SEK y)": 22064,
    "out:Running Cost over RSP/MSEK": 8.284,
    "out:LCP/MSEK": -0.916,
    "out:ROI% old": 10.96,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 387,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157870,
    "out:DH kr savings": 113447,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 128.92,
    "out:Primary Energy": 110.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.28,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 51.39,
    "out:Total CO2 (tons)": 142.18,
    "out:Klimatpaverkan": -24.84,
    "out:Initial Cost/MSEK": 4.003,
    "out:Running cost/(Apt SEK y)": 21367,
    "out:Running Cost over RSP/MSEK": 8.022,
    "out:LCP/MSEK": -0.723,
    "out:ROI% old": 11.7,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168938,
    "out:DH kr savings": 124515,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 140.26,
    "out:Total Energy Use Post PV": 140.26,
    "out:Primary Energy": 130.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.55,
    "out:CO2 Operational/m2": 44.5,
    "out:Total CO2/m2": 64.05,
    "out:Total CO2 (tons)": 177.23,
    "out:Klimatpaverkan": 10.21,
    "out:Initial Cost/MSEK": 3.772,
    "out:Running cost/(Apt SEK y)": 22873,
    "out:Running Cost over RSP/MSEK": 8.574,
    "out:LCP/MSEK": -1.045,
    "out:ROI% old": 10.33,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 348,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85469,
    "out:DH kr savings": 130049,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 137.26,
    "out:Primary Energy": 127.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.55,
    "out:CO2 Operational/m2": 43.42,
    "out:Total CO2/m2": 62.97,
    "out:Total CO2 (tons)": 174.24,
    "out:Klimatpaverkan": 7.22,
    "out:Initial Cost/MSEK": 3.841,
    "out:Running cost/(Apt SEK y)": 22351,
    "out:Running Cost over RSP/MSEK": 8.378,
    "out:LCP/MSEK": -0.918,
    "out:ROI% old": 10.87,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93770,
    "out:DH kr savings": 138350,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 134.26,
    "out:Primary Energy": 127.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.2,
    "out:CO2 Operational/m2": 42.34,
    "out:Total CO2/m2": 62.54,
    "out:Total CO2 (tons)": 173.06,
    "out:Klimatpaverkan": 6.04,
    "out:Initial Cost/MSEK": 3.991,
    "out:Running cost/(Apt SEK y)": 21829,
    "out:Running Cost over RSP/MSEK": 8.182,
    "out:LCP/MSEK": -0.871,
    "out:ROI% old": 11.17,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102071,
    "out:DH kr savings": 146651,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 130.26,
    "out:Total Energy Use Post PV": 130.26,
    "out:Primary Energy": 124.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.2,
    "out:CO2 Operational/m2": 40.9,
    "out:Total CO2/m2": 61.1,
    "out:Total CO2 (tons)": 169.07,
    "out:Klimatpaverkan": 2.05,
    "out:Initial Cost/MSEK": 4.06,
    "out:Running cost/(Apt SEK y)": 21132,
    "out:Running Cost over RSP/MSEK": 7.921,
    "out:LCP/MSEK": -0.679,
    "out:ROI% old": 11.9,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 432,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113139,
    "out:DH kr savings": 157719,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 140.26,
    "out:Total Energy Use Post PV": 133.64,
    "out:Primary Energy": 118.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.43,
    "out:CO2 Operational/m2": 32.08,
    "out:Total CO2/m2": 61.51,
    "out:Total CO2 (tons)": 170.19,
    "out:Klimatpaverkan": 3.17,
    "out:Initial Cost/MSEK": 4.233,
    "out:Running cost/(Apt SEK y)": 21910,
    "out:Running Cost over RSP/MSEK": 8.222,
    "out:LCP/MSEK": -1.153,
    "out:ROI% old": 10.39,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131732,
    "out:DH kr savings": 130049,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 130.64,
    "out:Primary Energy": 115.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.43,
    "out:CO2 Operational/m2": 31,
    "out:Total CO2/m2": 60.43,
    "out:Total CO2 (tons)": 167.2,
    "out:Klimatpaverkan": 0.18,
    "out:Initial Cost/MSEK": 4.302,
    "out:Running cost/(Apt SEK y)": 21387,
    "out:Running Cost over RSP/MSEK": 8.026,
    "out:LCP/MSEK": -1.026,
    "out:ROI% old": 10.88,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140033,
    "out:DH kr savings": 138350,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 127.64,
    "out:Primary Energy": 115.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.08,
    "out:CO2 Operational/m2": 29.92,
    "out:Total CO2/m2": 60,
    "out:Total CO2 (tons)": 166.02,
    "out:Klimatpaverkan": -1,
    "out:Initial Cost/MSEK": 4.451,
    "out:Running cost/(Apt SEK y)": 20865,
    "out:Running Cost over RSP/MSEK": 7.83,
    "out:LCP/MSEK": -0.979,
    "out:ROI% old": 11.14,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148334,
    "out:DH kr savings": 146651,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 130.26,
    "out:Total Energy Use Post PV": 123.64,
    "out:Primary Energy": 112.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.08,
    "out:CO2 Operational/m2": 28.48,
    "out:Total CO2/m2": 58.56,
    "out:Total CO2 (tons)": 162.03,
    "out:Klimatpaverkan": -4.99,
    "out:Initial Cost/MSEK": 4.52,
    "out:Running cost/(Apt SEK y)": 20169,
    "out:Running Cost over RSP/MSEK": 7.569,
    "out:LCP/MSEK": -0.787,
    "out:ROI% old": 11.8,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159402,
    "out:DH kr savings": 157719,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 132.26,
    "out:Total Energy Use Post PV": 132.26,
    "out:Primary Energy": 126.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.55,
    "out:CO2 Operational/m2": 41.62,
    "out:Total CO2/m2": 61.17,
    "out:Total CO2 (tons)": 169.26,
    "out:Klimatpaverkan": 2.24,
    "out:Initial Cost/MSEK": 3.899,
    "out:Running cost/(Apt SEK y)": 21481,
    "out:Running Cost over RSP/MSEK": 8.052,
    "out:LCP/MSEK": -0.649,
    "out:ROI% old": 11.91,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 415,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 107605,
    "out:DH kr savings": 152185,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 128.26,
    "out:Total Energy Use Post PV": 128.26,
    "out:Primary Energy": 123.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.55,
    "out:CO2 Operational/m2": 40.18,
    "out:Total CO2/m2": 59.73,
    "out:Total CO2 (tons)": 165.28,
    "out:Klimatpaverkan": -1.74,
    "out:Initial Cost/MSEK": 3.968,
    "out:Running cost/(Apt SEK y)": 20784,
    "out:Running Cost over RSP/MSEK": 7.79,
    "out:LCP/MSEK": -0.456,
    "out:ROI% old": 12.64,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 163253,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118673,
    "out:DH kr savings": 163253,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 127.26,
    "out:Primary Energy": 124.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.2,
    "out:CO2 Operational/m2": 39.82,
    "out:Total CO2/m2": 60.02,
    "out:Total CO2 (tons)": 166.09,
    "out:Klimatpaverkan": -0.93,
    "out:Initial Cost/MSEK": 4.117,
    "out:Running cost/(Apt SEK y)": 20610,
    "out:Running Cost over RSP/MSEK": 7.725,
    "out:LCP/MSEK": -0.54,
    "out:ROI% old": 12.41,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121440,
    "out:DH kr savings": 166020,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 88.18,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 123.26,
    "out:Total Energy Use Post PV": 123.26,
    "out:Primary Energy": 121.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.2,
    "out:CO2 Operational/m2": 38.38,
    "out:Total CO2/m2": 58.58,
    "out:Total CO2 (tons)": 162.1,
    "out:Klimatpaverkan": -4.92,
    "out:Initial Cost/MSEK": 4.187,
    "out:Running cost/(Apt SEK y)": 19914,
    "out:Running Cost over RSP/MSEK": 7.463,
    "out:LCP/MSEK": -0.348,
    "out:ROI% old": 13.1,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 177088,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132508,
    "out:DH kr savings": 177088,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 84.36,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 132.26,
    "out:Total Energy Use Post PV": 125.64,
    "out:Primary Energy": 114.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.43,
    "out:CO2 Operational/m2": 29.2,
    "out:Total CO2/m2": 58.63,
    "out:Total CO2 (tons)": 162.22,
    "out:Klimatpaverkan": -4.8,
    "out:Initial Cost/MSEK": 4.359,
    "out:Running cost/(Apt SEK y)": 20517,
    "out:Running Cost over RSP/MSEK": 7.699,
    "out:LCP/MSEK": -0.757,
    "out:ROI% old": 11.81,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 461,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 153868,
    "out:DH kr savings": 152185,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 90.18,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 128.26,
    "out:Total Energy Use Post PV": 121.64,
    "out:Primary Energy": 111.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.43,
    "out:CO2 Operational/m2": 27.76,
    "out:Total CO2/m2": 57.19,
    "out:Total CO2 (tons)": 158.24,
    "out:Klimatpaverkan": -8.78,
    "out:Initial Cost/MSEK": 4.429,
    "out:Running cost/(Apt SEK y)": 19820,
    "out:Running Cost over RSP/MSEK": 7.438,
    "out:LCP/MSEK": -0.565,
    "out:ROI% old": 12.46,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 46.7,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 495,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163253,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164936,
    "out:DH kr savings": 163253,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.35,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.18,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 120.64,
    "out:Primary Energy": 112.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.08,
    "out:CO2 Operational/m2": 27.4,
    "out:Total CO2/m2": 57.48,
    "out:Total CO2 (tons)": 159.05,
    "out:Klimatpaverkan": -7.97,
    "out:Initial Cost/MSEK": 4.578,
    "out:Running cost/(Apt SEK y)": 19646,
    "out:Running Cost over RSP/MSEK": 7.373,
    "out:LCP/MSEK": -0.649,
    "out:ROI% old": 12.26,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167703,
    "out:DH kr savings": 166020,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 249519,
    "out:% savings (space heating)": 23.27,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 88.18,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.35,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 123.26,
    "out:Total Energy Use Post PV": 116.64,
    "out:Primary Energy": 109.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.08,
    "out:CO2 Operational/m2": 25.96,
    "out:Total CO2/m2": 56.04,
    "out:Total CO2 (tons)": 155.06,
    "out:Klimatpaverkan": -11.96,
    "out:Initial Cost/MSEK": 4.647,
    "out:Running cost/(Apt SEK y)": 18950,
    "out:Running Cost over RSP/MSEK": 7.111,
    "out:LCP/MSEK": -0.456,
    "out:ROI% old": 12.88,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.51,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 53,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 177088,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178771,
    "out:DH kr savings": 177088,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 238931,
    "out:% savings (space heating)": 26.52,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 84.36,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 148.2,
    "out:Primary Energy": 126.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.5,
    "out:CO2 Operational/m2": 49.2,
    "out:Total CO2/m2": 70.7,
    "out:Total CO2 (tons)": 195.64,
    "out:Klimatpaverkan": 28.62,
    "out:Initial Cost/MSEK": 4.222,
    "out:Running cost/(Apt SEK y)": 24730,
    "out:Running Cost over RSP/MSEK": 9.276,
    "out:LCP/MSEK": -2.195,
    "out:ROI% old": 6.86,
    "out:Payback discounted": 18,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 6,
    "out:Return/kSEK/y": 259,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85777,
    "out:DH kr savings": 85777,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 145.2,
    "out:Primary Energy": 123.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.5,
    "out:CO2 Operational/m2": 48.12,
    "out:Total CO2/m2": 69.62,
    "out:Total CO2 (tons)": 192.65,
    "out:Klimatpaverkan": 25.63,
    "out:Initial Cost/MSEK": 4.291,
    "out:Running cost/(Apt SEK y)": 24208,
    "out:Running Cost over RSP/MSEK": 9.079,
    "out:LCP/MSEK": -2.068,
    "out:ROI% old": 7.4,
    "out:Payback discounted": 16,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 23.4,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 7,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 94078,
    "out:DH kr savings": 94078,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 142.2,
    "out:Primary Energy": 123.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.15,
    "out:CO2 Operational/m2": 47.04,
    "out:Total CO2/m2": 69.2,
    "out:Total CO2 (tons)": 191.46,
    "out:Klimatpaverkan": 24.44,
    "out:Initial Cost/MSEK": 4.44,
    "out:Running cost/(Apt SEK y)": 23686,
    "out:Running Cost over RSP/MSEK": 8.883,
    "out:LCP/MSEK": -2.022,
    "out:ROI% old": 7.78,
    "out:Payback discounted": 16,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 7,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102379,
    "out:DH kr savings": 102379,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 138.2,
    "out:Primary Energy": 120.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.15,
    "out:CO2 Operational/m2": 45.6,
    "out:Total CO2/m2": 67.76,
    "out:Total CO2 (tons)": 187.48,
    "out:Klimatpaverkan": 20.46,
    "out:Initial Cost/MSEK": 4.509,
    "out:Running cost/(Apt SEK y)": 22989,
    "out:Running Cost over RSP/MSEK": 8.622,
    "out:LCP/MSEK": -1.83,
    "out:ROI% old": 8.49,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 343,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113447,
    "out:DH kr savings": 113447,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 142.92,
    "out:Primary Energy": 117.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.39,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 61.53,
    "out:Total CO2 (tons)": 170.24,
    "out:Klimatpaverkan": 3.22,
    "out:Initial Cost/MSEK": 4.682,
    "out:Running cost/(Apt SEK y)": 23805,
    "out:Running Cost over RSP/MSEK": 8.937,
    "out:LCP/MSEK": -2.318,
    "out:ROI% old": 7.21,
    "out:Payback discounted": 17,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 6,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130200,
    "out:DH kr savings": 85777,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 139.92,
    "out:Primary Energy": 113.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.39,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 60.45,
    "out:Total CO2 (tons)": 167.25,
    "out:Klimatpaverkan": 0.23,
    "out:Initial Cost/MSEK": 4.751,
    "out:Running cost/(Apt SEK y)": 23282,
    "out:Running Cost over RSP/MSEK": 8.741,
    "out:LCP/MSEK": -2.191,
    "out:ROI% old": 7.7,
    "out:Payback discounted": 16,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 329,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 94078,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138501,
    "out:DH kr savings": 94078,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 136.92,
    "out:Primary Energy": 114.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.04,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 60.02,
    "out:Total CO2 (tons)": 166.07,
    "out:Klimatpaverkan": -0.95,
    "out:Initial Cost/MSEK": 4.901,
    "out:Running cost/(Apt SEK y)": 22760,
    "out:Running Cost over RSP/MSEK": 8.545,
    "out:LCP/MSEK": -2.144,
    "out:ROI% old": 8.04,
    "out:Payback discounted": 15,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 354,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 102379,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146802,
    "out:DH kr savings": 102379,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 132.92,
    "out:Primary Energy": 111.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.04,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 58.58,
    "out:Total CO2 (tons)": 162.08,
    "out:Klimatpaverkan": -4.94,
    "out:Initial Cost/MSEK": 4.97,
    "out:Running cost/(Apt SEK y)": 22064,
    "out:Running Cost over RSP/MSEK": 8.284,
    "out:LCP/MSEK": -1.952,
    "out:ROI% old": 8.68,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 387,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157870,
    "out:DH kr savings": 113447,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 140.2,
    "out:Primary Energy": 123.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.5,
    "out:CO2 Operational/m2": 46.32,
    "out:Total CO2/m2": 67.82,
    "out:Total CO2 (tons)": 187.67,
    "out:Klimatpaverkan": 20.65,
    "out:Initial Cost/MSEK": 4.348,
    "out:Running cost/(Apt SEK y)": 23337,
    "out:Running Cost over RSP/MSEK": 8.753,
    "out:LCP/MSEK": -1.799,
    "out:ROI% old": 8.37,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 7,
    "out:Return/kSEK/y": 326,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 107913,
    "out:DH kr savings": 107913,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 136.2,
    "out:Primary Energy": 119.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.5,
    "out:CO2 Operational/m2": 44.88,
    "out:Total CO2/m2": 66.38,
    "out:Total CO2 (tons)": 183.68,
    "out:Klimatpaverkan": 16.66,
    "out:Initial Cost/MSEK": 4.417,
    "out:Running cost/(Apt SEK y)": 22641,
    "out:Running Cost over RSP/MSEK": 8.491,
    "out:LCP/MSEK": -1.607,
    "out:ROI% old": 9.09,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 359,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118981,
    "out:DH kr savings": 118981,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 135.2,
    "out:Total Energy Use Post PV": 135.2,
    "out:Primary Energy": 120.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.15,
    "out:CO2 Operational/m2": 44.52,
    "out:Total CO2/m2": 66.68,
    "out:Total CO2 (tons)": 184.49,
    "out:Klimatpaverkan": 17.47,
    "out:Initial Cost/MSEK": 4.567,
    "out:Running cost/(Apt SEK y)": 22467,
    "out:Running Cost over RSP/MSEK": 8.426,
    "out:LCP/MSEK": -1.691,
    "out:ROI% old": 9,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 368,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121748,
    "out:DH kr savings": 121748,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 131.2,
    "out:Primary Energy": 117.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.15,
    "out:CO2 Operational/m2": 43.08,
    "out:Total CO2/m2": 65.24,
    "out:Total CO2 (tons)": 180.51,
    "out:Klimatpaverkan": 13.49,
    "out:Initial Cost/MSEK": 4.636,
    "out:Running cost/(Apt SEK y)": 21771,
    "out:Running Cost over RSP/MSEK": 8.164,
    "out:LCP/MSEK": -1.499,
    "out:ROI% old": 9.67,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 401,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132816,
    "out:DH kr savings": 132816,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01010200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 134.92,
    "out:Primary Energy": 113.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.39,
    "out:CO2 Operational/m2": 27.26,
    "out:Total CO2/m2": 58.65,
    "out:Total CO2 (tons)": 162.27,
    "out:Klimatpaverkan": -4.75,
    "out:Initial Cost/MSEK": 4.809,
    "out:Running cost/(Apt SEK y)": 22412,
    "out:Running Cost over RSP/MSEK": 8.415,
    "out:LCP/MSEK": -1.922,
    "out:ROI% old": 8.58,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 370,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 107913,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152336,
    "out:DH kr savings": 107913,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 130.92,
    "out:Primary Energy": 110.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.39,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 57.21,
    "out:Total CO2 (tons)": 158.29,
    "out:Klimatpaverkan": -8.73,
    "out:Initial Cost/MSEK": 4.878,
    "out:Running cost/(Apt SEK y)": 21716,
    "out:Running Cost over RSP/MSEK": 8.153,
    "out:LCP/MSEK": -1.729,
    "out:ROI% old": 9.22,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163404,
    "out:DH kr savings": 118981,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 135.2,
    "out:Total Energy Use Post PV": 129.92,
    "out:Primary Energy": 111.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.04,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 57.5,
    "out:Total CO2 (tons)": 159.1,
    "out:Klimatpaverkan": -7.92,
    "out:Initial Cost/MSEK": 5.027,
    "out:Running cost/(Apt SEK y)": 21541,
    "out:Running Cost over RSP/MSEK": 8.088,
    "out:LCP/MSEK": -1.813,
    "out:ROI% old": 9.13,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166171,
    "out:DH kr savings": 121748,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 125.92,
    "out:Primary Energy": 108.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.04,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 56.06,
    "out:Total CO2 (tons)": 155.11,
    "out:Klimatpaverkan": -11.91,
    "out:Initial Cost/MSEK": 5.097,
    "out:Running cost/(Apt SEK y)": 20845,
    "out:Running Cost over RSP/MSEK": 7.826,
    "out:LCP/MSEK": -1.621,
    "out:ROI% old": 9.74,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177239,
    "out:DH kr savings": 132816,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01010201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 137.26,
    "out:Primary Energy": 127.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.3,
    "out:CO2 Operational/m2": 43.42,
    "out:Total CO2/m2": 68.72,
    "out:Total CO2 (tons)": 190.16,
    "out:Klimatpaverkan": 23.14,
    "out:Initial Cost/MSEK": 4.866,
    "out:Running cost/(Apt SEK y)": 22351,
    "out:Running Cost over RSP/MSEK": 8.378,
    "out:LCP/MSEK": -1.942,
    "out:ROI% old": 8.58,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93770,
    "out:DH kr savings": 138350,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 134.26,
    "out:Primary Energy": 124.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.3,
    "out:CO2 Operational/m2": 42.34,
    "out:Total CO2/m2": 67.64,
    "out:Total CO2 (tons)": 187.17,
    "out:Klimatpaverkan": 20.15,
    "out:Initial Cost/MSEK": 4.935,
    "out:Running cost/(Apt SEK y)": 21829,
    "out:Running Cost over RSP/MSEK": 8.182,
    "out:LCP/MSEK": -1.816,
    "out:ROI% old": 9.03,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102071,
    "out:DH kr savings": 146651,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 131.26,
    "out:Total Energy Use Post PV": 131.26,
    "out:Primary Energy": 125.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.95,
    "out:CO2 Operational/m2": 41.26,
    "out:Total CO2/m2": 67.22,
    "out:Total CO2 (tons)": 185.99,
    "out:Klimatpaverkan": 18.97,
    "out:Initial Cost/MSEK": 5.084,
    "out:Running cost/(Apt SEK y)": 21307,
    "out:Running Cost over RSP/MSEK": 7.986,
    "out:LCP/MSEK": -1.769,
    "out:ROI% old": 9.32,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110372,
    "out:DH kr savings": 154952,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 127.26,
    "out:Primary Energy": 121.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.95,
    "out:CO2 Operational/m2": 39.82,
    "out:Total CO2/m2": 65.78,
    "out:Total CO2 (tons)": 182,
    "out:Klimatpaverkan": 14.98,
    "out:Initial Cost/MSEK": 5.154,
    "out:Running cost/(Apt SEK y)": 20610,
    "out:Running Cost over RSP/MSEK": 7.725,
    "out:LCP/MSEK": -1.576,
    "out:ROI% old": 9.92,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121440,
    "out:DH kr savings": 166020,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 130.64,
    "out:Primary Energy": 115.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.18,
    "out:CO2 Operational/m2": 31,
    "out:Total CO2/m2": 66.18,
    "out:Total CO2 (tons)": 183.12,
    "out:Klimatpaverkan": 16.1,
    "out:Initial Cost/MSEK": 5.326,
    "out:Running cost/(Apt SEK y)": 21387,
    "out:Running Cost over RSP/MSEK": 8.026,
    "out:LCP/MSEK": -2.051,
    "out:ROI% old": 8.79,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140033,
    "out:DH kr savings": 138350,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 134.26,
    "out:Total Energy Use Post PV": 127.64,
    "out:Primary Energy": 112.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.18,
    "out:CO2 Operational/m2": 29.92,
    "out:Total CO2/m2": 65.1,
    "out:Total CO2 (tons)": 180.13,
    "out:Klimatpaverkan": 13.11,
    "out:Initial Cost/MSEK": 5.395,
    "out:Running cost/(Apt SEK y)": 20865,
    "out:Running Cost over RSP/MSEK": 7.83,
    "out:LCP/MSEK": -1.924,
    "out:ROI% old": 9.19,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148334,
    "out:DH kr savings": 146651,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 131.26,
    "out:Total Energy Use Post PV": 124.64,
    "out:Primary Energy": 113.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.83,
    "out:CO2 Operational/m2": 28.84,
    "out:Total CO2/m2": 64.67,
    "out:Total CO2 (tons)": 178.95,
    "out:Klimatpaverkan": 11.93,
    "out:Initial Cost/MSEK": 5.545,
    "out:Running cost/(Apt SEK y)": 20343,
    "out:Running Cost over RSP/MSEK": 7.634,
    "out:LCP/MSEK": -1.877,
    "out:ROI% old": 9.45,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156635,
    "out:DH kr savings": 154952,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 120.64,
    "out:Primary Energy": 110,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.83,
    "out:CO2 Operational/m2": 27.4,
    "out:Total CO2/m2": 63.23,
    "out:Total CO2 (tons)": 174.96,
    "out:Klimatpaverkan": 7.94,
    "out:Initial Cost/MSEK": 5.614,
    "out:Running cost/(Apt SEK y)": 19646,
    "out:Running Cost over RSP/MSEK": 7.373,
    "out:LCP/MSEK": -1.685,
    "out:ROI% old": 10,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167703,
    "out:DH kr savings": 166020,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 129.26,
    "out:Total Energy Use Post PV": 129.26,
    "out:Primary Energy": 124.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.3,
    "out:CO2 Operational/m2": 40.54,
    "out:Total CO2/m2": 65.84,
    "out:Total CO2 (tons)": 182.19,
    "out:Klimatpaverkan": 15.17,
    "out:Initial Cost/MSEK": 4.993,
    "out:Running cost/(Apt SEK y)": 20958,
    "out:Running Cost over RSP/MSEK": 7.855,
    "out:LCP/MSEK": -1.546,
    "out:ROI% old": 9.86,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 440,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115906,
    "out:DH kr savings": 160486,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 125.26,
    "out:Total Energy Use Post PV": 125.26,
    "out:Primary Energy": 121.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.3,
    "out:CO2 Operational/m2": 39.1,
    "out:Total CO2/m2": 64.4,
    "out:Total CO2 (tons)": 178.21,
    "out:Klimatpaverkan": 11.19,
    "out:Initial Cost/MSEK": 5.062,
    "out:Running cost/(Apt SEK y)": 20262,
    "out:Running Cost over RSP/MSEK": 7.594,
    "out:LCP/MSEK": -1.354,
    "out:ROI% old": 10.46,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 474,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 171554,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126974,
    "out:DH kr savings": 171554,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 124.26,
    "out:Total Energy Use Post PV": 124.26,
    "out:Primary Energy": 121.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.95,
    "out:CO2 Operational/m2": 38.74,
    "out:Total CO2/m2": 64.7,
    "out:Total CO2 (tons)": 179.01,
    "out:Klimatpaverkan": 11.99,
    "out:Initial Cost/MSEK": 5.211,
    "out:Running cost/(Apt SEK y)": 20088,
    "out:Running Cost over RSP/MSEK": 7.529,
    "out:LCP/MSEK": -1.438,
    "out:ROI% old": 10.34,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 174321,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 129741,
    "out:DH kr savings": 174321,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.2,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 120.26,
    "out:Total Energy Use Post PV": 120.26,
    "out:Primary Energy": 118.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.95,
    "out:CO2 Operational/m2": 37.3,
    "out:Total CO2/m2": 63.26,
    "out:Total CO2 (tons)": 175.03,
    "out:Klimatpaverkan": 8.01,
    "out:Initial Cost/MSEK": 5.28,
    "out:Running cost/(Apt SEK y)": 19392,
    "out:Running Cost over RSP/MSEK": 7.267,
    "out:LCP/MSEK": -1.246,
    "out:ROI% old": 10.92,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185389,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140809,
    "out:DH kr savings": 185389,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.51,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01010201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 129.26,
    "out:Total Energy Use Post PV": 122.64,
    "out:Primary Energy": 112.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.18,
    "out:CO2 Operational/m2": 28.12,
    "out:Total CO2/m2": 63.3,
    "out:Total CO2 (tons)": 175.15,
    "out:Klimatpaverkan": 8.13,
    "out:Initial Cost/MSEK": 5.453,
    "out:Running cost/(Apt SEK y)": 19995,
    "out:Running Cost over RSP/MSEK": 7.503,
    "out:LCP/MSEK": -1.655,
    "out:ROI% old": 9.95,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162169,
    "out:DH kr savings": 160486,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 87.19,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 125.26,
    "out:Total Energy Use Post PV": 118.64,
    "out:Primary Energy": 109.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.18,
    "out:CO2 Operational/m2": 26.68,
    "out:Total CO2/m2": 61.86,
    "out:Total CO2 (tons)": 171.17,
    "out:Klimatpaverkan": 4.15,
    "out:Initial Cost/MSEK": 5.522,
    "out:Running cost/(Apt SEK y)": 19298,
    "out:Running Cost over RSP/MSEK": 7.242,
    "out:LCP/MSEK": -1.462,
    "out:ROI% old": 10.5,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 171554,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173237,
    "out:DH kr savings": 171554,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.5,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.19,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 124.26,
    "out:Total Energy Use Post PV": 117.64,
    "out:Primary Energy": 109.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.83,
    "out:CO2 Operational/m2": 26.32,
    "out:Total CO2/m2": 62.15,
    "out:Total CO2 (tons)": 171.97,
    "out:Klimatpaverkan": 4.95,
    "out:Initial Cost/MSEK": 5.672,
    "out:Running cost/(Apt SEK y)": 19124,
    "out:Running Cost over RSP/MSEK": 7.176,
    "out:LCP/MSEK": -1.546,
    "out:ROI% old": 10.39,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 528,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 174321,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176004,
    "out:DH kr savings": 174321,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 241259,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.2,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01010201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 120.26,
    "out:Total Energy Use Post PV": 113.64,
    "out:Primary Energy": 106.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.83,
    "out:CO2 Operational/m2": 24.88,
    "out:Total CO2/m2": 60.71,
    "out:Total CO2 (tons)": 167.99,
    "out:Klimatpaverkan": 0.97,
    "out:Initial Cost/MSEK": 5.741,
    "out:Running cost/(Apt SEK y)": 18428,
    "out:Running Cost over RSP/MSEK": 6.915,
    "out:LCP/MSEK": -1.354,
    "out:ROI% old": 10.92,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 57,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 562,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.85,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185389,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 187072,
    "out:DH kr savings": 185389,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 231048,
    "out:% savings (space heating)": 28.95,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 81.51,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.65,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 148.2,
    "out:Primary Energy": 126.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.8,
    "out:CO2 Operational/m2": 49.2,
    "out:Total CO2/m2": 63,
    "out:Total CO2 (tons)": 174.33,
    "out:Klimatpaverkan": 7.31,
    "out:Initial Cost/MSEK": 2.981,
    "out:Running cost/(Apt SEK y)": 24730,
    "out:Running Cost over RSP/MSEK": 9.276,
    "out:LCP/MSEK": -0.955,
    "out:ROI% old": 9.71,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 20.9,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 259,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85777,
    "out:DH kr savings": 85777,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 239758,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.65,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.98,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 144.2,
    "out:Total Energy Use Post PV": 144.2,
    "out:Primary Energy": 122.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.8,
    "out:CO2 Operational/m2": 47.76,
    "out:Total CO2/m2": 61.56,
    "out:Total CO2 (tons)": 170.34,
    "out:Klimatpaverkan": 3.32,
    "out:Initial Cost/MSEK": 3.05,
    "out:Running cost/(Apt SEK y)": 24034,
    "out:Running Cost over RSP/MSEK": 9.014,
    "out:LCP/MSEK": -0.762,
    "out:ROI% old": 10.72,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 24.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 292,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 96845,
    "out:DH kr savings": 96845,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229614,
    "out:% savings (space heating)": 29.39,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.98,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.65,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 141.2,
    "out:Total Energy Use Post PV": 141.2,
    "out:Primary Energy": 123.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.45,
    "out:CO2 Operational/m2": 46.68,
    "out:Total CO2/m2": 61.13,
    "out:Total CO2 (tons)": 169.16,
    "out:Klimatpaverkan": 2.14,
    "out:Initial Cost/MSEK": 3.199,
    "out:Running cost/(Apt SEK y)": 23511,
    "out:Running Cost over RSP/MSEK": 8.818,
    "out:LCP/MSEK": -0.716,
    "out:ROI% old": 11.09,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 318,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105146,
    "out:DH kr savings": 105146,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 239758,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.65,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.98,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 138.2,
    "out:Primary Energy": 120.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.45,
    "out:CO2 Operational/m2": 45.6,
    "out:Total CO2/m2": 60.06,
    "out:Total CO2 (tons)": 166.17,
    "out:Klimatpaverkan": -0.85,
    "out:Initial Cost/MSEK": 3.268,
    "out:Running cost/(Apt SEK y)": 22989,
    "out:Running Cost over RSP/MSEK": 8.622,
    "out:LCP/MSEK": -0.589,
    "out:ROI% old": 11.71,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 343,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113447,
    "out:DH kr savings": 113447,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229614,
    "out:% savings (space heating)": 29.39,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.98,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.65,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 142.92,
    "out:Primary Energy": 116.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.68,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 53.82,
    "out:Total CO2 (tons)": 148.93,
    "out:Klimatpaverkan": -18.09,
    "out:Initial Cost/MSEK": 3.441,
    "out:Running cost/(Apt SEK y)": 23805,
    "out:Running Cost over RSP/MSEK": 8.937,
    "out:LCP/MSEK": -1.077,
    "out:ROI% old": 9.82,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85777,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130200,
    "out:DH kr savings": 85777,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 239758,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.65,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.98,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 144.2,
    "out:Total Energy Use Post PV": 138.92,
    "out:Primary Energy": 113.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.68,
    "out:CO2 Operational/m2": 28.7,
    "out:Total CO2/m2": 52.39,
    "out:Total CO2 (tons)": 144.95,
    "out:Klimatpaverkan": -22.07,
    "out:Initial Cost/MSEK": 3.51,
    "out:Running cost/(Apt SEK y)": 23108,
    "out:Running Cost over RSP/MSEK": 8.676,
    "out:LCP/MSEK": -0.885,
    "out:ROI% old": 10.69,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 96845,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141268,
    "out:DH kr savings": 96845,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229614,
    "out:% savings (space heating)": 29.39,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.98,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.65,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 141.2,
    "out:Total Energy Use Post PV": 135.92,
    "out:Primary Energy": 113.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.34,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 51.96,
    "out:Total CO2 (tons)": 143.77,
    "out:Klimatpaverkan": -23.25,
    "out:Initial Cost/MSEK": 3.66,
    "out:Running cost/(Apt SEK y)": 22586,
    "out:Running Cost over RSP/MSEK": 8.48,
    "out:LCP/MSEK": -0.838,
    "out:ROI% old": 11.02,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149569,
    "out:DH kr savings": 105146,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 239758,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.65,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.98,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 132.92,
    "out:Primary Energy": 110.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.34,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 50.88,
    "out:Total CO2 (tons)": 140.78,
    "out:Klimatpaverkan": -26.24,
    "out:Initial Cost/MSEK": 3.729,
    "out:Running cost/(Apt SEK y)": 22064,
    "out:Running Cost over RSP/MSEK": 8.284,
    "out:LCP/MSEK": -0.711,
    "out:ROI% old": 11.56,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 387,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157870,
    "out:DH kr savings": 113447,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229614,
    "out:% savings (space heating)": 29.39,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.98,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.65,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 139.2,
    "out:Total Energy Use Post PV": 139.2,
    "out:Primary Energy": 122.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.8,
    "out:CO2 Operational/m2": 45.96,
    "out:Total CO2/m2": 59.76,
    "out:Total CO2 (tons)": 165.36,
    "out:Klimatpaverkan": -1.66,
    "out:Initial Cost/MSEK": 3.107,
    "out:Running cost/(Apt SEK y)": 23163,
    "out:Running Cost over RSP/MSEK": 8.687,
    "out:LCP/MSEK": -0.493,
    "out:ROI% old": 12.02,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 334,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110680,
    "out:DH kr savings": 110680,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 239758,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.65,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.98,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 136.2,
    "out:Primary Energy": 119.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.8,
    "out:CO2 Operational/m2": 44.88,
    "out:Total CO2/m2": 58.68,
    "out:Total CO2 (tons)": 162.38,
    "out:Klimatpaverkan": -4.64,
    "out:Initial Cost/MSEK": 3.177,
    "out:Running cost/(Apt SEK y)": 22641,
    "out:Running Cost over RSP/MSEK": 8.491,
    "out:LCP/MSEK": -0.366,
    "out:ROI% old": 12.64,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 359,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118981,
    "out:DH kr savings": 118981,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229614,
    "out:% savings (space heating)": 29.39,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.98,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.65,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 134.2,
    "out:Primary Energy": 120.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.45,
    "out:CO2 Operational/m2": 44.16,
    "out:Total CO2/m2": 58.62,
    "out:Total CO2 (tons)": 162.19,
    "out:Klimatpaverkan": -4.83,
    "out:Initial Cost/MSEK": 3.326,
    "out:Running cost/(Apt SEK y)": 22293,
    "out:Running Cost over RSP/MSEK": 8.361,
    "out:LCP/MSEK": -0.385,
    "out:ROI% old": 12.63,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124515,
    "out:DH kr savings": 124515,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 239758,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.65,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.98,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 130.2,
    "out:Primary Energy": 117.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.45,
    "out:CO2 Operational/m2": 42.72,
    "out:Total CO2/m2": 57.18,
    "out:Total CO2 (tons)": 158.2,
    "out:Klimatpaverkan": -8.82,
    "out:Initial Cost/MSEK": 3.395,
    "out:Running cost/(Apt SEK y)": 21596,
    "out:Running Cost over RSP/MSEK": 8.099,
    "out:LCP/MSEK": -0.192,
    "out:ROI% old": 13.48,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 409,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135583,
    "out:DH kr savings": 135583,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229614,
    "out:% savings (space heating)": 29.39,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.98,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.65,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 139.2,
    "out:Total Energy Use Post PV": 133.92,
    "out:Primary Energy": 113.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.68,
    "out:CO2 Operational/m2": 26.9,
    "out:Total CO2/m2": 50.59,
    "out:Total CO2 (tons)": 139.97,
    "out:Klimatpaverkan": -27.05,
    "out:Initial Cost/MSEK": 3.568,
    "out:Running cost/(Apt SEK y)": 22238,
    "out:Running Cost over RSP/MSEK": 8.349,
    "out:LCP/MSEK": -0.615,
    "out:ROI% old": 11.82,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 379,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155103,
    "out:DH kr savings": 110680,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 239758,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.65,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.98,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 130.92,
    "out:Primary Energy": 109.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.68,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 49.51,
    "out:Total CO2 (tons)": 136.98,
    "out:Klimatpaverkan": -30.04,
    "out:Initial Cost/MSEK": 3.637,
    "out:Running cost/(Apt SEK y)": 21716,
    "out:Running Cost over RSP/MSEK": 8.153,
    "out:LCP/MSEK": -0.488,
    "out:ROI% old": 12.37,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163404,
    "out:DH kr savings": 118981,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229614,
    "out:% savings (space heating)": 29.39,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.98,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.65,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 128.92,
    "out:Primary Energy": 110.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.34,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 49.44,
    "out:Total CO2 (tons)": 136.79,
    "out:Klimatpaverkan": -30.23,
    "out:Initial Cost/MSEK": 3.786,
    "out:Running cost/(Apt SEK y)": 21367,
    "out:Running Cost over RSP/MSEK": 8.022,
    "out:LCP/MSEK": -0.507,
    "out:ROI% old": 12.37,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 124515,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168938,
    "out:DH kr savings": 124515,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 239758,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.65,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.98,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 124.92,
    "out:Primary Energy": 107.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.34,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 48,
    "out:Total CO2 (tons)": 132.81,
    "out:Klimatpaverkan": -34.21,
    "out:Initial Cost/MSEK": 3.856,
    "out:Running cost/(Apt SEK y)": 20671,
    "out:Running Cost over RSP/MSEK": 7.761,
    "out:LCP/MSEK": -0.315,
    "out:ROI% old": 13.12,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 454,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180006,
    "out:DH kr savings": 135583,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229614,
    "out:% savings (space heating)": 29.39,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.98,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.65,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 137.26,
    "out:Primary Energy": 127.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.6,
    "out:CO2 Operational/m2": 43.42,
    "out:Total CO2/m2": 61.02,
    "out:Total CO2 (tons)": 168.85,
    "out:Klimatpaverkan": 1.83,
    "out:Initial Cost/MSEK": 3.625,
    "out:Running cost/(Apt SEK y)": 22351,
    "out:Running Cost over RSP/MSEK": 8.378,
    "out:LCP/MSEK": -0.701,
    "out:ROI% old": 11.52,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93770,
    "out:DH kr savings": 138350,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 239758,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.65,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.98,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 133.26,
    "out:Total Energy Use Post PV": 133.26,
    "out:Primary Energy": 124.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.6,
    "out:CO2 Operational/m2": 41.98,
    "out:Total CO2/m2": 59.58,
    "out:Total CO2 (tons)": 164.87,
    "out:Klimatpaverkan": -2.15,
    "out:Initial Cost/MSEK": 3.694,
    "out:Running cost/(Apt SEK y)": 21655,
    "out:Running Cost over RSP/MSEK": 8.117,
    "out:LCP/MSEK": -0.509,
    "out:ROI% old": 12.32,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 407,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104838,
    "out:DH kr savings": 149418,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229614,
    "out:% savings (space heating)": 29.39,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.98,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.65,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 130.26,
    "out:Total Energy Use Post PV": 130.26,
    "out:Primary Energy": 124.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.25,
    "out:CO2 Operational/m2": 40.9,
    "out:Total CO2/m2": 59.16,
    "out:Total CO2 (tons)": 163.68,
    "out:Klimatpaverkan": -3.34,
    "out:Initial Cost/MSEK": 3.844,
    "out:Running cost/(Apt SEK y)": 21132,
    "out:Running Cost over RSP/MSEK": 7.921,
    "out:LCP/MSEK": -0.463,
    "out:ROI% old": 12.57,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 432,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113139,
    "out:DH kr savings": 157719,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 239758,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.65,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.98,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 127.26,
    "out:Primary Energy": 121.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.25,
    "out:CO2 Operational/m2": 39.82,
    "out:Total CO2/m2": 58.08,
    "out:Total CO2 (tons)": 160.7,
    "out:Klimatpaverkan": -6.32,
    "out:Initial Cost/MSEK": 3.913,
    "out:Running cost/(Apt SEK y)": 20610,
    "out:Running Cost over RSP/MSEK": 7.725,
    "out:LCP/MSEK": -0.336,
    "out:ROI% old": 13.06,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121440,
    "out:DH kr savings": 166020,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229614,
    "out:% savings (space heating)": 29.39,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.98,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.65,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 137.26,
    "out:Total Energy Use Post PV": 130.64,
    "out:Primary Energy": 115.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.48,
    "out:CO2 Operational/m2": 31,
    "out:Total CO2/m2": 58.48,
    "out:Total CO2 (tons)": 161.81,
    "out:Klimatpaverkan": -5.21,
    "out:Initial Cost/MSEK": 4.085,
    "out:Running cost/(Apt SEK y)": 21387,
    "out:Running Cost over RSP/MSEK": 8.026,
    "out:LCP/MSEK": -0.81,
    "out:ROI% old": 11.45,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140033,
    "out:DH kr savings": 138350,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 239758,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.65,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.98,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 133.26,
    "out:Total Energy Use Post PV": 126.64,
    "out:Primary Energy": 112.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.48,
    "out:CO2 Operational/m2": 29.56,
    "out:Total CO2/m2": 57.04,
    "out:Total CO2 (tons)": 157.83,
    "out:Klimatpaverkan": -9.19,
    "out:Initial Cost/MSEK": 4.155,
    "out:Running cost/(Apt SEK y)": 20691,
    "out:Running Cost over RSP/MSEK": 7.765,
    "out:LCP/MSEK": -0.617,
    "out:ROI% old": 12.16,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 453,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151101,
    "out:DH kr savings": 149418,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229614,
    "out:% savings (space heating)": 29.39,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.98,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.65,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 130.26,
    "out:Total Energy Use Post PV": 123.64,
    "out:Primary Energy": 112.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.13,
    "out:CO2 Operational/m2": 28.48,
    "out:Total CO2/m2": 56.61,
    "out:Total CO2 (tons)": 156.64,
    "out:Klimatpaverkan": -10.38,
    "out:Initial Cost/MSEK": 4.304,
    "out:Running cost/(Apt SEK y)": 20169,
    "out:Running Cost over RSP/MSEK": 7.569,
    "out:LCP/MSEK": -0.571,
    "out:ROI% old": 12.39,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159402,
    "out:DH kr savings": 157719,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 239758,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.65,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.98,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 120.64,
    "out:Primary Energy": 109.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.13,
    "out:CO2 Operational/m2": 27.4,
    "out:Total CO2/m2": 55.53,
    "out:Total CO2 (tons)": 153.66,
    "out:Klimatpaverkan": -13.36,
    "out:Initial Cost/MSEK": 4.373,
    "out:Running cost/(Apt SEK y)": 19646,
    "out:Running Cost over RSP/MSEK": 7.373,
    "out:LCP/MSEK": -0.444,
    "out:ROI% old": 12.84,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167703,
    "out:DH kr savings": 166020,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229614,
    "out:% savings (space heating)": 29.39,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.98,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.65,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 128.26,
    "out:Total Energy Use Post PV": 128.26,
    "out:Primary Energy": 123.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.6,
    "out:CO2 Operational/m2": 40.18,
    "out:Total CO2/m2": 57.78,
    "out:Total CO2 (tons)": 159.89,
    "out:Klimatpaverkan": -7.13,
    "out:Initial Cost/MSEK": 3.752,
    "out:Running cost/(Apt SEK y)": 20784,
    "out:Running Cost over RSP/MSEK": 7.79,
    "out:LCP/MSEK": -0.24,
    "out:ROI% old": 13.37,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 163253,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118673,
    "out:DH kr savings": 163253,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 239758,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.65,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.98,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 125.26,
    "out:Total Energy Use Post PV": 125.26,
    "out:Primary Energy": 120.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.6,
    "out:CO2 Operational/m2": 39.1,
    "out:Total CO2/m2": 56.7,
    "out:Total CO2 (tons)": 156.9,
    "out:Klimatpaverkan": -10.12,
    "out:Initial Cost/MSEK": 3.821,
    "out:Running cost/(Apt SEK y)": 20262,
    "out:Running Cost over RSP/MSEK": 7.594,
    "out:LCP/MSEK": -0.113,
    "out:ROI% old": 13.86,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 474,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 171554,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126974,
    "out:DH kr savings": 171554,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229614,
    "out:% savings (space heating)": 29.39,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.98,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.65,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 123.26,
    "out:Total Energy Use Post PV": 123.26,
    "out:Primary Energy": 121.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.25,
    "out:CO2 Operational/m2": 38.38,
    "out:Total CO2/m2": 56.64,
    "out:Total CO2 (tons)": 156.71,
    "out:Klimatpaverkan": -10.31,
    "out:Initial Cost/MSEK": 3.97,
    "out:Running cost/(Apt SEK y)": 19914,
    "out:Running Cost over RSP/MSEK": 7.463,
    "out:LCP/MSEK": -0.132,
    "out:ROI% old": 13.81,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 177088,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132508,
    "out:DH kr savings": 177088,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 239758,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 84.65,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.98,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 119.26,
    "out:Total Energy Use Post PV": 119.26,
    "out:Primary Energy": 118.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.25,
    "out:CO2 Operational/m2": 36.94,
    "out:Total CO2/m2": 55.2,
    "out:Total CO2 (tons)": 152.73,
    "out:Klimatpaverkan": -14.29,
    "out:Initial Cost/MSEK": 4.039,
    "out:Running cost/(Apt SEK y)": 19217,
    "out:Running Cost over RSP/MSEK": 7.202,
    "out:LCP/MSEK": 0.061,
    "out:ROI% old": 14.5,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 524,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 188156,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143576,
    "out:DH kr savings": 188156,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229614,
    "out:% savings (space heating)": 29.39,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 80.99,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.65,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 128.26,
    "out:Total Energy Use Post PV": 121.64,
    "out:Primary Energy": 111.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.48,
    "out:CO2 Operational/m2": 27.76,
    "out:Total CO2/m2": 55.24,
    "out:Total CO2 (tons)": 152.85,
    "out:Klimatpaverkan": -14.17,
    "out:Initial Cost/MSEK": 4.212,
    "out:Running cost/(Apt SEK y)": 19820,
    "out:Running Cost over RSP/MSEK": 7.438,
    "out:LCP/MSEK": -0.348,
    "out:ROI% old": 13.1,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 46.7,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 495,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163253,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164936,
    "out:DH kr savings": 163253,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 239758,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 86.65,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.98,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 125.26,
    "out:Total Energy Use Post PV": 118.64,
    "out:Primary Energy": 108.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.48,
    "out:CO2 Operational/m2": 26.68,
    "out:Total CO2/m2": 54.16,
    "out:Total CO2 (tons)": 149.86,
    "out:Klimatpaverkan": -17.16,
    "out:Initial Cost/MSEK": 4.281,
    "out:Running cost/(Apt SEK y)": 19298,
    "out:Running Cost over RSP/MSEK": 7.242,
    "out:LCP/MSEK": -0.221,
    "out:ROI% old": 13.55,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 171554,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173237,
    "out:DH kr savings": 171554,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229614,
    "out:% savings (space heating)": 29.39,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.98,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.65,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 123.26,
    "out:Total Energy Use Post PV": 116.64,
    "out:Primary Energy": 109.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.13,
    "out:CO2 Operational/m2": 25.96,
    "out:Total CO2/m2": 54.09,
    "out:Total CO2 (tons)": 149.67,
    "out:Klimatpaverkan": -17.35,
    "out:Initial Cost/MSEK": 4.431,
    "out:Running cost/(Apt SEK y)": 18950,
    "out:Running Cost over RSP/MSEK": 7.111,
    "out:LCP/MSEK": -0.24,
    "out:ROI% old": 13.51,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 53,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 177088,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178771,
    "out:DH kr savings": 177088,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 239758,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 84.65,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.98,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 119.26,
    "out:Total Energy Use Post PV": 112.64,
    "out:Primary Energy": 106.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.13,
    "out:CO2 Operational/m2": 24.52,
    "out:Total CO2/m2": 52.65,
    "out:Total CO2 (tons)": 145.69,
    "out:Klimatpaverkan": -21.33,
    "out:Initial Cost/MSEK": 4.5,
    "out:Running cost/(Apt SEK y)": 18254,
    "out:Running Cost over RSP/MSEK": 6.85,
    "out:LCP/MSEK": -0.048,
    "out:ROI% old": 14.13,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.49,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 58.4,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 570,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 188156,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 189839,
    "out:DH kr savings": 188156,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 229614,
    "out:% savings (space heating)": 29.39,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 80.99,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.56,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 139.2,
    "out:Total Energy Use Post PV": 139.2,
    "out:Primary Energy": 118.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.12,
    "out:CO2 Operational/m2": 45.96,
    "out:Total CO2/m2": 62.08,
    "out:Total CO2 (tons)": 171.77,
    "out:Klimatpaverkan": 4.75,
    "out:Initial Cost/MSEK": 3.427,
    "out:Running cost/(Apt SEK y)": 23163,
    "out:Running Cost over RSP/MSEK": 8.687,
    "out:LCP/MSEK": -0.813,
    "out:ROI% old": 10.9,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 334,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110680,
    "out:DH kr savings": 110680,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 214611,
    "out:% savings (space heating)": 34,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 77.56,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.31,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 136.2,
    "out:Primary Energy": 115.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.12,
    "out:CO2 Operational/m2": 44.88,
    "out:Total CO2/m2": 61,
    "out:Total CO2 (tons)": 168.78,
    "out:Klimatpaverkan": 1.76,
    "out:Initial Cost/MSEK": 3.497,
    "out:Running cost/(Apt SEK y)": 22641,
    "out:Running Cost over RSP/MSEK": 8.491,
    "out:LCP/MSEK": -0.686,
    "out:ROI% old": 11.48,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 359,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118981,
    "out:DH kr savings": 118981,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 205611,
    "out:% savings (space heating)": 36.77,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.31,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.56,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 132.2,
    "out:Primary Energy": 115.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.77,
    "out:CO2 Operational/m2": 43.44,
    "out:Total CO2/m2": 60.21,
    "out:Total CO2 (tons)": 166.6,
    "out:Klimatpaverkan": -0.42,
    "out:Initial Cost/MSEK": 3.646,
    "out:Running cost/(Apt SEK y)": 21945,
    "out:Running Cost over RSP/MSEK": 8.23,
    "out:LCP/MSEK": -0.574,
    "out:ROI% old": 12.04,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 393,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130049,
    "out:DH kr savings": 130049,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 214611,
    "out:% savings (space heating)": 34,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 77.56,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.31,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 129.2,
    "out:Primary Energy": 112.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.77,
    "out:CO2 Operational/m2": 42.36,
    "out:Total CO2/m2": 59.13,
    "out:Total CO2 (tons)": 163.61,
    "out:Klimatpaverkan": -3.41,
    "out:Initial Cost/MSEK": 3.715,
    "out:Running cost/(Apt SEK y)": 21422,
    "out:Running Cost over RSP/MSEK": 8.034,
    "out:LCP/MSEK": -0.447,
    "out:ROI% old": 12.57,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 418,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138350,
    "out:DH kr savings": 138350,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 205611,
    "out:% savings (space heating)": 36.77,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.31,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.56,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 139.2,
    "out:Total Energy Use Post PV": 133.92,
    "out:Primary Energy": 108.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26,
    "out:CO2 Operational/m2": 26.9,
    "out:Total CO2/m2": 52.9,
    "out:Total CO2 (tons)": 146.37,
    "out:Klimatpaverkan": -20.65,
    "out:Initial Cost/MSEK": 3.888,
    "out:Running cost/(Apt SEK y)": 22238,
    "out:Running Cost over RSP/MSEK": 8.349,
    "out:LCP/MSEK": -0.935,
    "out:ROI% old": 10.85,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 379,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 110680,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155103,
    "out:DH kr savings": 110680,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 214611,
    "out:% savings (space heating)": 34,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 77.56,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.31,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 130.92,
    "out:Primary Energy": 105.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 51.82,
    "out:Total CO2 (tons)": 143.38,
    "out:Klimatpaverkan": -23.64,
    "out:Initial Cost/MSEK": 3.957,
    "out:Running cost/(Apt SEK y)": 21716,
    "out:Running Cost over RSP/MSEK": 8.153,
    "out:LCP/MSEK": -0.808,
    "out:ROI% old": 11.37,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163404,
    "out:DH kr savings": 118981,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 205611,
    "out:% savings (space heating)": 36.77,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.31,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.56,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 126.92,
    "out:Primary Energy": 105.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.65,
    "out:CO2 Operational/m2": 24.38,
    "out:Total CO2/m2": 51.03,
    "out:Total CO2 (tons)": 141.21,
    "out:Klimatpaverkan": -25.81,
    "out:Initial Cost/MSEK": 4.106,
    "out:Running cost/(Apt SEK y)": 21019,
    "out:Running Cost over RSP/MSEK": 7.892,
    "out:LCP/MSEK": -0.696,
    "out:ROI% old": 11.86,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 437,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 174472,
    "out:DH kr savings": 130049,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 214611,
    "out:% savings (space heating)": 34,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 77.56,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.31,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 123.92,
    "out:Primary Energy": 103.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.65,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 49.95,
    "out:Total CO2 (tons)": 138.22,
    "out:Klimatpaverkan": -28.8,
    "out:Initial Cost/MSEK": 4.176,
    "out:Running cost/(Apt SEK y)": 20497,
    "out:Running Cost over RSP/MSEK": 7.696,
    "out:LCP/MSEK": -0.569,
    "out:ROI% old": 12.34,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 182773,
    "out:DH kr savings": 138350,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 205611,
    "out:% savings (space heating)": 36.77,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.31,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.56,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 130.2,
    "out:Primary Energy": 114.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.12,
    "out:CO2 Operational/m2": 42.72,
    "out:Total CO2/m2": 58.84,
    "out:Total CO2 (tons)": 162.8,
    "out:Klimatpaverkan": -4.22,
    "out:Initial Cost/MSEK": 3.554,
    "out:Running cost/(Apt SEK y)": 21596,
    "out:Running Cost over RSP/MSEK": 8.099,
    "out:LCP/MSEK": -0.351,
    "out:ROI% old": 12.87,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 409,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135583,
    "out:DH kr savings": 135583,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 214611,
    "out:% savings (space heating)": 34,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 77.56,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.31,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 127.2,
    "out:Total Energy Use Post PV": 127.2,
    "out:Primary Energy": 111.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.12,
    "out:CO2 Operational/m2": 41.64,
    "out:Total CO2/m2": 57.76,
    "out:Total CO2 (tons)": 159.81,
    "out:Klimatpaverkan": -7.21,
    "out:Initial Cost/MSEK": 3.623,
    "out:Running cost/(Apt SEK y)": 21074,
    "out:Running Cost over RSP/MSEK": 7.903,
    "out:LCP/MSEK": -0.224,
    "out:ROI% old": 13.4,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 435,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 143884,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143884,
    "out:DH kr savings": 143884,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 205611,
    "out:% savings (space heating)": 36.77,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.31,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.56,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 125.2,
    "out:Total Energy Use Post PV": 125.2,
    "out:Primary Energy": 112.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.77,
    "out:CO2 Operational/m2": 40.92,
    "out:Total CO2/m2": 57.69,
    "out:Total CO2 (tons)": 159.63,
    "out:Klimatpaverkan": -7.39,
    "out:Initial Cost/MSEK": 3.773,
    "out:Running cost/(Apt SEK y)": 20726,
    "out:Running Cost over RSP/MSEK": 7.772,
    "out:LCP/MSEK": -0.243,
    "out:ROI% old": 13.37,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 451,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149418,
    "out:DH kr savings": 149418,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 214611,
    "out:% savings (space heating)": 34,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 77.56,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.31,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 122.2,
    "out:Total Energy Use Post PV": 122.2,
    "out:Primary Energy": 109.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.77,
    "out:CO2 Operational/m2": 39.84,
    "out:Total CO2/m2": 56.61,
    "out:Total CO2 (tons)": 156.64,
    "out:Klimatpaverkan": -10.38,
    "out:Initial Cost/MSEK": 3.842,
    "out:Running cost/(Apt SEK y)": 20204,
    "out:Running Cost over RSP/MSEK": 7.576,
    "out:LCP/MSEK": -0.116,
    "out:ROI% old": 13.85,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 46.7,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157719,
    "out:DH kr savings": 157719,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 205611,
    "out:% savings (space heating)": 36.77,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.31,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.56,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 124.92,
    "out:Primary Energy": 105.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 49.66,
    "out:Total CO2 (tons)": 137.41,
    "out:Klimatpaverkan": -29.61,
    "out:Initial Cost/MSEK": 4.015,
    "out:Running cost/(Apt SEK y)": 20671,
    "out:Running Cost over RSP/MSEK": 7.761,
    "out:LCP/MSEK": -0.474,
    "out:ROI% old": 12.6,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 454,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180006,
    "out:DH kr savings": 135583,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 214611,
    "out:% savings (space heating)": 34,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 77.56,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.31,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 127.2,
    "out:Total Energy Use Post PV": 121.92,
    "out:Primary Energy": 102.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26,
    "out:CO2 Operational/m2": 22.58,
    "out:Total CO2/m2": 48.58,
    "out:Total CO2 (tons)": 134.42,
    "out:Klimatpaverkan": -32.6,
    "out:Initial Cost/MSEK": 4.084,
    "out:Running cost/(Apt SEK y)": 20149,
    "out:Running Cost over RSP/MSEK": 7.565,
    "out:LCP/MSEK": -0.347,
    "out:ROI% old": 13.07,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 46.7,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 479,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 143884,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188307,
    "out:DH kr savings": 143884,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 205611,
    "out:% savings (space heating)": 36.77,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.31,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.56,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 125.2,
    "out:Total Energy Use Post PV": 119.92,
    "out:Primary Energy": 103.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.65,
    "out:CO2 Operational/m2": 21.86,
    "out:Total CO2/m2": 48.51,
    "out:Total CO2 (tons)": 134.23,
    "out:Klimatpaverkan": -32.79,
    "out:Initial Cost/MSEK": 4.233,
    "out:Running cost/(Apt SEK y)": 19800,
    "out:Running Cost over RSP/MSEK": 7.434,
    "out:LCP/MSEK": -0.365,
    "out:ROI% old": 13.05,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 496,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 193841,
    "out:DH kr savings": 149418,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 214611,
    "out:% savings (space heating)": 34,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 77.56,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.31,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 122.2,
    "out:Total Energy Use Post PV": 116.92,
    "out:Primary Energy": 100.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.65,
    "out:CO2 Operational/m2": 20.78,
    "out:Total CO2/m2": 47.43,
    "out:Total CO2 (tons)": 131.24,
    "out:Klimatpaverkan": -35.78,
    "out:Initial Cost/MSEK": 4.302,
    "out:Running cost/(Apt SEK y)": 19278,
    "out:Running Cost over RSP/MSEK": 7.238,
    "out:LCP/MSEK": -0.239,
    "out:ROI% old": 13.49,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 53,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 521,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202142,
    "out:DH kr savings": 157719,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 205611,
    "out:% savings (space heating)": 36.77,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.31,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.56,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 128.26,
    "out:Total Energy Use Post PV": 128.26,
    "out:Primary Energy": 119.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.91,
    "out:CO2 Operational/m2": 40.18,
    "out:Total CO2/m2": 60.1,
    "out:Total CO2 (tons)": 166.29,
    "out:Klimatpaverkan": -0.73,
    "out:Initial Cost/MSEK": 4.072,
    "out:Running cost/(Apt SEK y)": 20784,
    "out:Running Cost over RSP/MSEK": 7.79,
    "out:LCP/MSEK": -0.56,
    "out:ROI% old": 12.32,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 163253,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118673,
    "out:DH kr savings": 163253,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 214611,
    "out:% savings (space heating)": 34,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 77.56,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.31,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 125.26,
    "out:Total Energy Use Post PV": 125.26,
    "out:Primary Energy": 116.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.91,
    "out:CO2 Operational/m2": 39.1,
    "out:Total CO2/m2": 59.02,
    "out:Total CO2 (tons)": 163.3,
    "out:Klimatpaverkan": -3.72,
    "out:Initial Cost/MSEK": 4.141,
    "out:Running cost/(Apt SEK y)": 20262,
    "out:Running Cost over RSP/MSEK": 7.594,
    "out:LCP/MSEK": -0.433,
    "out:ROI% old": 12.79,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 474,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 171554,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126974,
    "out:DH kr savings": 171554,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 205611,
    "out:% savings (space heating)": 36.77,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.31,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.56,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 121.26,
    "out:Total Energy Use Post PV": 121.26,
    "out:Primary Energy": 116.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.57,
    "out:CO2 Operational/m2": 37.66,
    "out:Total CO2/m2": 58.23,
    "out:Total CO2 (tons)": 161.12,
    "out:Klimatpaverkan": -5.9,
    "out:Initial Cost/MSEK": 4.29,
    "out:Running cost/(Apt SEK y)": 19566,
    "out:Running Cost over RSP/MSEK": 7.333,
    "out:LCP/MSEK": -0.321,
    "out:ROI% old": 13.22,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182622,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138042,
    "out:DH kr savings": 182622,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 214611,
    "out:% savings (space heating)": 34,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 77.56,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.31,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 118.26,
    "out:Total Energy Use Post PV": 118.26,
    "out:Primary Energy": 113.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.57,
    "out:CO2 Operational/m2": 36.58,
    "out:Total CO2/m2": 57.15,
    "out:Total CO2 (tons)": 158.14,
    "out:Klimatpaverkan": -8.88,
    "out:Initial Cost/MSEK": 4.359,
    "out:Running cost/(Apt SEK y)": 19043,
    "out:Running Cost over RSP/MSEK": 7.136,
    "out:LCP/MSEK": -0.194,
    "out:ROI% old": 13.65,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190923,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146343,
    "out:DH kr savings": 190923,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 205611,
    "out:% savings (space heating)": 36.77,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.31,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.56,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 128.26,
    "out:Total Energy Use Post PV": 121.64,
    "out:Primary Energy": 107.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.8,
    "out:CO2 Operational/m2": 27.76,
    "out:Total CO2/m2": 57.55,
    "out:Total CO2 (tons)": 159.25,
    "out:Klimatpaverkan": -7.77,
    "out:Initial Cost/MSEK": 4.532,
    "out:Running cost/(Apt SEK y)": 19820,
    "out:Running Cost over RSP/MSEK": 7.438,
    "out:LCP/MSEK": -0.668,
    "out:ROI% old": 12.18,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 46.7,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 495,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163253,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164936,
    "out:DH kr savings": 163253,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 214611,
    "out:% savings (space heating)": 34,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 77.56,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.31,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 125.26,
    "out:Total Energy Use Post PV": 118.64,
    "out:Primary Energy": 104.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.8,
    "out:CO2 Operational/m2": 26.68,
    "out:Total CO2/m2": 56.47,
    "out:Total CO2 (tons)": 156.26,
    "out:Klimatpaverkan": -10.76,
    "out:Initial Cost/MSEK": 4.601,
    "out:Running cost/(Apt SEK y)": 19298,
    "out:Running Cost over RSP/MSEK": 7.242,
    "out:LCP/MSEK": -0.541,
    "out:ROI% old": 12.61,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 171554,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173237,
    "out:DH kr savings": 171554,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 205611,
    "out:% savings (space heating)": 36.77,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.31,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.56,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 121.26,
    "out:Total Energy Use Post PV": 114.64,
    "out:Primary Energy": 104.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.45,
    "out:CO2 Operational/m2": 25.24,
    "out:Total CO2/m2": 55.69,
    "out:Total CO2 (tons)": 154.08,
    "out:Klimatpaverkan": -12.94,
    "out:Initial Cost/MSEK": 4.751,
    "out:Running cost/(Apt SEK y)": 18602,
    "out:Running Cost over RSP/MSEK": 6.98,
    "out:LCP/MSEK": -0.429,
    "out:ROI% old": 12.99,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 182622,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184305,
    "out:DH kr savings": 182622,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 214611,
    "out:% savings (space heating)": 34,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 77.56,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.31,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 118.26,
    "out:Total Energy Use Post PV": 111.64,
    "out:Primary Energy": 101.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.45,
    "out:CO2 Operational/m2": 24.16,
    "out:Total CO2/m2": 54.61,
    "out:Total CO2 (tons)": 151.09,
    "out:Klimatpaverkan": -15.93,
    "out:Initial Cost/MSEK": 4.82,
    "out:Running cost/(Apt SEK y)": 18080,
    "out:Running Cost over RSP/MSEK": 6.784,
    "out:LCP/MSEK": -0.302,
    "out:ROI% old": 13.39,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 578,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190923,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192606,
    "out:DH kr savings": 190923,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 205611,
    "out:% savings (space heating)": 36.77,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.31,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.56,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 119.26,
    "out:Total Energy Use Post PV": 119.26,
    "out:Primary Energy": 115.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.91,
    "out:CO2 Operational/m2": 36.94,
    "out:Total CO2/m2": 56.86,
    "out:Total CO2 (tons)": 157.33,
    "out:Klimatpaverkan": -9.69,
    "out:Initial Cost/MSEK": 4.198,
    "out:Running cost/(Apt SEK y)": 19217,
    "out:Running Cost over RSP/MSEK": 7.202,
    "out:LCP/MSEK": -0.098,
    "out:ROI% old": 13.95,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 524,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 188156,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143576,
    "out:DH kr savings": 188156,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 214611,
    "out:% savings (space heating)": 34,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 77.56,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.31,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 116.26,
    "out:Total Energy Use Post PV": 116.26,
    "out:Primary Energy": 112.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.91,
    "out:CO2 Operational/m2": 35.86,
    "out:Total CO2/m2": 55.78,
    "out:Total CO2 (tons)": 154.34,
    "out:Klimatpaverkan": -12.68,
    "out:Initial Cost/MSEK": 4.268,
    "out:Running cost/(Apt SEK y)": 18695,
    "out:Running Cost over RSP/MSEK": 7.006,
    "out:LCP/MSEK": 0.028,
    "out:ROI% old": 14.38,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 54.3,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 549,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 196457,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151877,
    "out:DH kr savings": 196457,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 205611,
    "out:% savings (space heating)": 36.77,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.31,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.56,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 114.26,
    "out:Total Energy Use Post PV": 114.26,
    "out:Primary Energy": 113.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.57,
    "out:CO2 Operational/m2": 35.14,
    "out:Total CO2/m2": 55.71,
    "out:Total CO2 (tons)": 154.15,
    "out:Klimatpaverkan": -12.87,
    "out:Initial Cost/MSEK": 4.417,
    "out:Running cost/(Apt SEK y)": 18347,
    "out:Running Cost over RSP/MSEK": 6.875,
    "out:LCP/MSEK": 0.01,
    "out:ROI% old": 14.32,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 57,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 565,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 201991,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157411,
    "out:DH kr savings": 201991,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 214611,
    "out:% savings (space heating)": 34,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.57,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.31,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 111.26,
    "out:Total Energy Use Post PV": 111.26,
    "out:Primary Energy": 110.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.57,
    "out:CO2 Operational/m2": 34.06,
    "out:Total CO2/m2": 54.63,
    "out:Total CO2 (tons)": 151.16,
    "out:Klimatpaverkan": -15.86,
    "out:Initial Cost/MSEK": 4.486,
    "out:Running cost/(Apt SEK y)": 17825,
    "out:Running Cost over RSP/MSEK": 6.679,
    "out:LCP/MSEK": 0.137,
    "out:ROI% old": 14.72,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 61.3,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 591,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 210292,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165712,
    "out:DH kr savings": 210292,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 205611,
    "out:% savings (space heating)": 36.77,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.31,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.56,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 119.26,
    "out:Total Energy Use Post PV": 112.64,
    "out:Primary Energy": 103.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.8,
    "out:CO2 Operational/m2": 24.52,
    "out:Total CO2/m2": 54.31,
    "out:Total CO2 (tons)": 150.29,
    "out:Klimatpaverkan": -16.73,
    "out:Initial Cost/MSEK": 4.659,
    "out:Running cost/(Apt SEK y)": 18254,
    "out:Running Cost over RSP/MSEK": 6.85,
    "out:LCP/MSEK": -0.207,
    "out:ROI% old": 13.65,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 58.4,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 570,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 188156,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189839,
    "out:DH kr savings": 188156,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 214611,
    "out:% savings (space heating)": 34,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 77.56,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.31,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 116.26,
    "out:Total Energy Use Post PV": 109.64,
    "out:Primary Energy": 101.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.8,
    "out:CO2 Operational/m2": 23.44,
    "out:Total CO2/m2": 53.23,
    "out:Total CO2 (tons)": 147.3,
    "out:Klimatpaverkan": -19.72,
    "out:Initial Cost/MSEK": 4.728,
    "out:Running cost/(Apt SEK y)": 17731,
    "out:Running Cost over RSP/MSEK": 6.654,
    "out:LCP/MSEK": -0.08,
    "out:ROI% old": 14.04,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 62.7,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 196457,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198140,
    "out:DH kr savings": 196457,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 205611,
    "out:% savings (space heating)": 36.77,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.31,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.56,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 114.26,
    "out:Total Energy Use Post PV": 107.64,
    "out:Primary Energy": 101.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.45,
    "out:CO2 Operational/m2": 22.72,
    "out:Total CO2/m2": 53.17,
    "out:Total CO2 (tons)": 147.11,
    "out:Klimatpaverkan": -19.91,
    "out:Initial Cost/MSEK": 4.877,
    "out:Running cost/(Apt SEK y)": 17383,
    "out:Running Cost over RSP/MSEK": 6.523,
    "out:LCP/MSEK": -0.098,
    "out:ROI% old": 14,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 65.7,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 612,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 201991,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 203674,
    "out:DH kr savings": 201991,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 214611,
    "out:% savings (space heating)": 34,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 75.57,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.31,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 111.26,
    "out:Total Energy Use Post PV": 104.64,
    "out:Primary Energy": 98.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.45,
    "out:CO2 Operational/m2": 21.64,
    "out:Total CO2/m2": 52.09,
    "out:Total CO2 (tons)": 144.12,
    "out:Klimatpaverkan": -22.9,
    "out:Initial Cost/MSEK": 4.947,
    "out:Running cost/(Apt SEK y)": 16861,
    "out:Running Cost over RSP/MSEK": 6.327,
    "out:LCP/MSEK": 0.028,
    "out:ROI% old": 14.37,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 70.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 637,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 210292,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211975,
    "out:DH kr savings": 210292,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 205611,
    "out:% savings (space heating)": 36.77,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.31,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 136.2,
    "out:Primary Energy": 115.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.87,
    "out:CO2 Operational/m2": 44.88,
    "out:Total CO2/m2": 66.75,
    "out:Total CO2 (tons)": 184.7,
    "out:Klimatpaverkan": 17.68,
    "out:Initial Cost/MSEK": 4.521,
    "out:Running cost/(Apt SEK y)": 22641,
    "out:Running Cost over RSP/MSEK": 8.491,
    "out:LCP/MSEK": -1.71,
    "out:ROI% old": 8.88,
    "out:Payback discounted": 14,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 359,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118981,
    "out:DH kr savings": 118981,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 206063,
    "out:% savings (space heating)": 36.63,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.47,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.36,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 133.2,
    "out:Primary Energy": 112.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.87,
    "out:CO2 Operational/m2": 43.8,
    "out:Total CO2/m2": 65.67,
    "out:Total CO2 (tons)": 181.71,
    "out:Klimatpaverkan": 14.69,
    "out:Initial Cost/MSEK": 4.59,
    "out:Running cost/(Apt SEK y)": 22119,
    "out:Running Cost over RSP/MSEK": 8.295,
    "out:LCP/MSEK": -1.584,
    "out:ROI% old": 9.36,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 384,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 127282,
    "out:DH kr savings": 127282,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 197451,
    "out:% savings (space heating)": 39.28,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.36,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 129.2,
    "out:Primary Energy": 112.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.52,
    "out:CO2 Operational/m2": 42.36,
    "out:Total CO2/m2": 64.88,
    "out:Total CO2 (tons)": 179.53,
    "out:Klimatpaverkan": 12.51,
    "out:Initial Cost/MSEK": 4.74,
    "out:Running cost/(Apt SEK y)": 21422,
    "out:Running Cost over RSP/MSEK": 8.034,
    "out:LCP/MSEK": -1.472,
    "out:ROI% old": 9.85,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 418,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138350,
    "out:DH kr savings": 138350,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 206063,
    "out:% savings (space heating)": 36.63,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.47,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.36,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 126.2,
    "out:Primary Energy": 110.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.52,
    "out:CO2 Operational/m2": 41.28,
    "out:Total CO2/m2": 63.8,
    "out:Total CO2 (tons)": 176.54,
    "out:Klimatpaverkan": 9.52,
    "out:Initial Cost/MSEK": 4.809,
    "out:Running cost/(Apt SEK y)": 20900,
    "out:Running Cost over RSP/MSEK": 7.838,
    "out:LCP/MSEK": -1.345,
    "out:ROI% old": 10.29,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 443,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146651,
    "out:DH kr savings": 146651,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 197451,
    "out:% savings (space heating)": 39.28,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.36,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 130.92,
    "out:Primary Energy": 105.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.75,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 57.57,
    "out:Total CO2 (tons)": 159.3,
    "out:Klimatpaverkan": -7.72,
    "out:Initial Cost/MSEK": 4.982,
    "out:Running cost/(Apt SEK y)": 21716,
    "out:Running Cost over RSP/MSEK": 8.153,
    "out:LCP/MSEK": -1.833,
    "out:ROI% old": 9.03,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118981,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163404,
    "out:DH kr savings": 118981,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 206063,
    "out:% savings (space heating)": 36.63,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.47,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.36,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 127.92,
    "out:Primary Energy": 103.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.75,
    "out:CO2 Operational/m2": 24.74,
    "out:Total CO2/m2": 56.49,
    "out:Total CO2 (tons)": 156.31,
    "out:Klimatpaverkan": -10.71,
    "out:Initial Cost/MSEK": 5.051,
    "out:Running cost/(Apt SEK y)": 21193,
    "out:Running Cost over RSP/MSEK": 7.957,
    "out:LCP/MSEK": -1.706,
    "out:ROI% old": 9.46,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 429,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171705,
    "out:DH kr savings": 127282,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 197451,
    "out:% savings (space heating)": 39.28,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.36,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 123.92,
    "out:Primary Energy": 103.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.4,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 55.7,
    "out:Total CO2 (tons)": 154.13,
    "out:Klimatpaverkan": -12.89,
    "out:Initial Cost/MSEK": 5.2,
    "out:Running cost/(Apt SEK y)": 20497,
    "out:Running Cost over RSP/MSEK": 7.696,
    "out:LCP/MSEK": -1.594,
    "out:ROI% old": 9.91,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182773,
    "out:DH kr savings": 138350,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 206063,
    "out:% savings (space heating)": 36.63,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.47,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.36,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 120.92,
    "out:Primary Energy": 100.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.4,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 54.62,
    "out:Total CO2 (tons)": 151.15,
    "out:Klimatpaverkan": -15.87,
    "out:Initial Cost/MSEK": 5.269,
    "out:Running cost/(Apt SEK y)": 19975,
    "out:Running Cost over RSP/MSEK": 7.499,
    "out:LCP/MSEK": -1.467,
    "out:ROI% old": 10.31,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191074,
    "out:DH kr savings": 146651,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 197451,
    "out:% savings (space heating)": 39.28,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.36,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 127.2,
    "out:Total Energy Use Post PV": 127.2,
    "out:Primary Energy": 111.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.87,
    "out:CO2 Operational/m2": 41.64,
    "out:Total CO2/m2": 63.51,
    "out:Total CO2 (tons)": 175.73,
    "out:Klimatpaverkan": 8.71,
    "out:Initial Cost/MSEK": 4.648,
    "out:Running cost/(Apt SEK y)": 21074,
    "out:Running Cost over RSP/MSEK": 7.903,
    "out:LCP/MSEK": -1.249,
    "out:ROI% old": 10.45,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 435,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 143884,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143884,
    "out:DH kr savings": 143884,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 206063,
    "out:% savings (space heating)": 36.63,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.47,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.36,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 124.2,
    "out:Total Energy Use Post PV": 124.2,
    "out:Primary Energy": 109.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.87,
    "out:CO2 Operational/m2": 40.56,
    "out:Total CO2/m2": 62.43,
    "out:Total CO2 (tons)": 172.74,
    "out:Klimatpaverkan": 5.72,
    "out:Initial Cost/MSEK": 4.717,
    "out:Running cost/(Apt SEK y)": 20552,
    "out:Running Cost over RSP/MSEK": 7.707,
    "out:LCP/MSEK": -1.122,
    "out:ROI% old": 10.89,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 460,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152185,
    "out:DH kr savings": 152185,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 197451,
    "out:% savings (space heating)": 39.28,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.36,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 122.2,
    "out:Total Energy Use Post PV": 122.2,
    "out:Primary Energy": 109.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.52,
    "out:CO2 Operational/m2": 39.84,
    "out:Total CO2/m2": 62.36,
    "out:Total CO2 (tons)": 172.56,
    "out:Klimatpaverkan": 5.54,
    "out:Initial Cost/MSEK": 4.866,
    "out:Running cost/(Apt SEK y)": 20204,
    "out:Running Cost over RSP/MSEK": 7.576,
    "out:LCP/MSEK": -1.141,
    "out:ROI% old": 10.94,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 46.7,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157719,
    "out:DH kr savings": 157719,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 206063,
    "out:% savings (space heating)": 36.63,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.47,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.36,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 119.2,
    "out:Total Energy Use Post PV": 119.2,
    "out:Primary Energy": 107.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.52,
    "out:CO2 Operational/m2": 38.76,
    "out:Total CO2/m2": 61.28,
    "out:Total CO2 (tons)": 169.57,
    "out:Klimatpaverkan": 2.55,
    "out:Initial Cost/MSEK": 4.936,
    "out:Running cost/(Apt SEK y)": 19681,
    "out:Running Cost over RSP/MSEK": 7.38,
    "out:LCP/MSEK": -1.014,
    "out:ROI% old": 11.35,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 501,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 166020,
    "out:DH kr savings": 166020,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 197451,
    "out:% savings (space heating)": 39.28,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.36,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01011200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 127.2,
    "out:Total Energy Use Post PV": 121.92,
    "out:Primary Energy": 102.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.75,
    "out:CO2 Operational/m2": 22.58,
    "out:Total CO2/m2": 54.33,
    "out:Total CO2 (tons)": 150.34,
    "out:Klimatpaverkan": -16.68,
    "out:Initial Cost/MSEK": 5.108,
    "out:Running cost/(Apt SEK y)": 20149,
    "out:Running Cost over RSP/MSEK": 7.565,
    "out:LCP/MSEK": -1.371,
    "out:ROI% old": 10.45,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 46.7,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 479,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 143884,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188307,
    "out:DH kr savings": 143884,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 206063,
    "out:% savings (space heating)": 36.63,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.47,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.36,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 124.2,
    "out:Total Energy Use Post PV": 118.92,
    "out:Primary Energy": 99.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.75,
    "out:CO2 Operational/m2": 21.5,
    "out:Total CO2/m2": 53.25,
    "out:Total CO2 (tons)": 147.35,
    "out:Klimatpaverkan": -19.67,
    "out:Initial Cost/MSEK": 5.177,
    "out:Running cost/(Apt SEK y)": 19626,
    "out:Running Cost over RSP/MSEK": 7.369,
    "out:LCP/MSEK": -1.244,
    "out:ROI% old": 10.85,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 196608,
    "out:DH kr savings": 152185,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 197451,
    "out:% savings (space heating)": 39.28,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.36,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 122.2,
    "out:Total Energy Use Post PV": 116.92,
    "out:Primary Energy": 100.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.4,
    "out:CO2 Operational/m2": 20.78,
    "out:Total CO2/m2": 53.18,
    "out:Total CO2 (tons)": 147.16,
    "out:Klimatpaverkan": -19.86,
    "out:Initial Cost/MSEK": 5.327,
    "out:Running cost/(Apt SEK y)": 19278,
    "out:Running Cost over RSP/MSEK": 7.238,
    "out:LCP/MSEK": -1.263,
    "out:ROI% old": 10.9,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 53,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 521,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202142,
    "out:DH kr savings": 157719,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 206063,
    "out:% savings (space heating)": 36.63,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.47,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.36,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 119.2,
    "out:Total Energy Use Post PV": 113.92,
    "out:Primary Energy": 97.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.4,
    "out:CO2 Operational/m2": 19.7,
    "out:Total CO2/m2": 52.1,
    "out:Total CO2 (tons)": 144.17,
    "out:Klimatpaverkan": -22.85,
    "out:Initial Cost/MSEK": 5.396,
    "out:Running cost/(Apt SEK y)": 18756,
    "out:Running Cost over RSP/MSEK": 7.042,
    "out:LCP/MSEK": -1.136,
    "out:ROI% old": 11.28,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 57,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 546,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 210443,
    "out:DH kr savings": 166020,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 197451,
    "out:% savings (space heating)": 39.28,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.36,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01011201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 125.26,
    "out:Total Energy Use Post PV": 125.26,
    "out:Primary Energy": 116.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.67,
    "out:CO2 Operational/m2": 39.1,
    "out:Total CO2/m2": 64.77,
    "out:Total CO2 (tons)": 179.22,
    "out:Klimatpaverkan": 12.2,
    "out:Initial Cost/MSEK": 5.165,
    "out:Running cost/(Apt SEK y)": 20262,
    "out:Running Cost over RSP/MSEK": 7.594,
    "out:LCP/MSEK": -1.458,
    "out:ROI% old": 10.25,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 474,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 171554,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126974,
    "out:DH kr savings": 171554,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 206063,
    "out:% savings (space heating)": 36.63,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.47,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.36,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 122.26,
    "out:Total Energy Use Post PV": 122.26,
    "out:Primary Energy": 113.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.67,
    "out:CO2 Operational/m2": 38.02,
    "out:Total CO2/m2": 63.69,
    "out:Total CO2 (tons)": 176.23,
    "out:Klimatpaverkan": 9.21,
    "out:Initial Cost/MSEK": 5.234,
    "out:Running cost/(Apt SEK y)": 19740,
    "out:Running Cost over RSP/MSEK": 7.398,
    "out:LCP/MSEK": -1.331,
    "out:ROI% old": 10.65,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 46.7,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 499,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 179855,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135275,
    "out:DH kr savings": 179855,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 197451,
    "out:% savings (space heating)": 39.28,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.36,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 118.26,
    "out:Total Energy Use Post PV": 118.26,
    "out:Primary Energy": 114,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.32,
    "out:CO2 Operational/m2": 36.58,
    "out:Total CO2/m2": 62.9,
    "out:Total CO2 (tons)": 174.05,
    "out:Klimatpaverkan": 7.03,
    "out:Initial Cost/MSEK": 5.384,
    "out:Running cost/(Apt SEK y)": 19043,
    "out:Running Cost over RSP/MSEK": 7.136,
    "out:LCP/MSEK": -1.219,
    "out:ROI% old": 11.05,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190923,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146343,
    "out:DH kr savings": 190923,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 206063,
    "out:% savings (space heating)": 36.63,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.47,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.36,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 115.26,
    "out:Total Energy Use Post PV": 115.26,
    "out:Primary Energy": 111.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.32,
    "out:CO2 Operational/m2": 35.5,
    "out:Total CO2/m2": 61.82,
    "out:Total CO2 (tons)": 171.06,
    "out:Klimatpaverkan": 4.04,
    "out:Initial Cost/MSEK": 5.453,
    "out:Running cost/(Apt SEK y)": 18521,
    "out:Running Cost over RSP/MSEK": 6.94,
    "out:LCP/MSEK": -1.092,
    "out:ROI% old": 11.43,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154644,
    "out:DH kr savings": 199224,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 197451,
    "out:% savings (space heating)": 39.28,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.36,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 125.26,
    "out:Total Energy Use Post PV": 118.64,
    "out:Primary Energy": 104.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.55,
    "out:CO2 Operational/m2": 26.68,
    "out:Total CO2/m2": 62.23,
    "out:Total CO2 (tons)": 172.18,
    "out:Klimatpaverkan": 5.16,
    "out:Initial Cost/MSEK": 5.626,
    "out:Running cost/(Apt SEK y)": 19298,
    "out:Running Cost over RSP/MSEK": 7.242,
    "out:LCP/MSEK": -1.566,
    "out:ROI% old": 10.31,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 171554,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 173237,
    "out:DH kr savings": 171554,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 206063,
    "out:% savings (space heating)": 36.63,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.47,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.36,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 122.26,
    "out:Total Energy Use Post PV": 115.64,
    "out:Primary Energy": 102,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.55,
    "out:CO2 Operational/m2": 25.6,
    "out:Total CO2/m2": 61.15,
    "out:Total CO2 (tons)": 169.19,
    "out:Klimatpaverkan": 2.17,
    "out:Initial Cost/MSEK": 5.695,
    "out:Running cost/(Apt SEK y)": 18776,
    "out:Running Cost over RSP/MSEK": 7.046,
    "out:LCP/MSEK": -1.439,
    "out:ROI% old": 10.68,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 54.3,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 545,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 179855,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181538,
    "out:DH kr savings": 179855,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 197451,
    "out:% savings (space heating)": 39.28,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.36,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 118.26,
    "out:Total Energy Use Post PV": 111.64,
    "out:Primary Energy": 102.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 36.2,
    "out:CO2 Operational/m2": 24.16,
    "out:Total CO2/m2": 60.36,
    "out:Total CO2 (tons)": 167.01,
    "out:Klimatpaverkan": -0.01,
    "out:Initial Cost/MSEK": 5.844,
    "out:Running cost/(Apt SEK y)": 18080,
    "out:Running Cost over RSP/MSEK": 6.784,
    "out:LCP/MSEK": -1.327,
    "out:ROI% old": 11.04,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 578,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190923,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192606,
    "out:DH kr savings": 190923,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 206063,
    "out:% savings (space heating)": 36.63,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.47,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.36,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 115.26,
    "out:Total Energy Use Post PV": 108.64,
    "out:Primary Energy": 99.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.2,
    "out:CO2 Operational/m2": 23.08,
    "out:Total CO2/m2": 59.28,
    "out:Total CO2 (tons)": 164.02,
    "out:Klimatpaverkan": -3,
    "out:Initial Cost/MSEK": 5.914,
    "out:Running cost/(Apt SEK y)": 17557,
    "out:Running Cost over RSP/MSEK": 6.588,
    "out:LCP/MSEK": -1.2,
    "out:ROI% old": 11.39,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 64.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 603,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200907,
    "out:DH kr savings": 199224,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 197451,
    "out:% savings (space heating)": 39.28,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.36,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 116.26,
    "out:Total Energy Use Post PV": 116.26,
    "out:Primary Energy": 113.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.67,
    "out:CO2 Operational/m2": 35.86,
    "out:Total CO2/m2": 61.53,
    "out:Total CO2 (tons)": 170.26,
    "out:Klimatpaverkan": 3.24,
    "out:Initial Cost/MSEK": 5.292,
    "out:Running cost/(Apt SEK y)": 18695,
    "out:Running Cost over RSP/MSEK": 7.006,
    "out:LCP/MSEK": -0.996,
    "out:ROI% old": 11.6,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 54.3,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 549,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 196457,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151877,
    "out:DH kr savings": 196457,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 206063,
    "out:% savings (space heating)": 36.63,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.47,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.36,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 113.26,
    "out:Total Energy Use Post PV": 113.26,
    "out:Primary Energy": 110.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.67,
    "out:CO2 Operational/m2": 34.78,
    "out:Total CO2/m2": 60.45,
    "out:Total CO2 (tons)": 167.27,
    "out:Klimatpaverkan": 0.25,
    "out:Initial Cost/MSEK": 5.361,
    "out:Running cost/(Apt SEK y)": 18173,
    "out:Running Cost over RSP/MSEK": 6.81,
    "out:LCP/MSEK": -0.869,
    "out:ROI% old": 11.97,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 58.4,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 574,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 204758,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160178,
    "out:DH kr savings": 204758,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 197451,
    "out:% savings (space heating)": 39.28,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.36,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 111.26,
    "out:Total Energy Use Post PV": 111.26,
    "out:Primary Energy": 110.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.32,
    "out:CO2 Operational/m2": 34.06,
    "out:Total CO2/m2": 60.38,
    "out:Total CO2 (tons)": 167.08,
    "out:Klimatpaverkan": 0.06,
    "out:Initial Cost/MSEK": 5.511,
    "out:Running cost/(Apt SEK y)": 17825,
    "out:Running Cost over RSP/MSEK": 6.679,
    "out:LCP/MSEK": -0.888,
    "out:ROI% old": 11.98,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 61.3,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 591,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 210292,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165712,
    "out:DH kr savings": 210292,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 206063,
    "out:% savings (space heating)": 36.63,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.36,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 108.26,
    "out:Total Energy Use Post PV": 108.26,
    "out:Primary Energy": 107.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.32,
    "out:CO2 Operational/m2": 32.98,
    "out:Total CO2/m2": 59.3,
    "out:Total CO2 (tons)": 164.09,
    "out:Klimatpaverkan": -2.93,
    "out:Initial Cost/MSEK": 5.58,
    "out:Running cost/(Apt SEK y)": 17302,
    "out:Running Cost over RSP/MSEK": 6.483,
    "out:LCP/MSEK": -0.761,
    "out:ROI% old": 12.34,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 65.7,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 616,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 218593,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174013,
    "out:DH kr savings": 218593,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 197451,
    "out:% savings (space heating)": 39.28,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 69.36,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01011201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 116.26,
    "out:Total Energy Use Post PV": 109.64,
    "out:Primary Energy": 101.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.55,
    "out:CO2 Operational/m2": 23.44,
    "out:Total CO2/m2": 58.99,
    "out:Total CO2 (tons)": 163.22,
    "out:Klimatpaverkan": -3.8,
    "out:Initial Cost/MSEK": 5.752,
    "out:Running cost/(Apt SEK y)": 17731,
    "out:Running Cost over RSP/MSEK": 6.654,
    "out:LCP/MSEK": -1.104,
    "out:ROI% old": 11.54,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 62.7,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 196457,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 198140,
    "out:DH kr savings": 196457,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 206063,
    "out:% savings (space heating)": 36.63,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.47,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.36,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 113.26,
    "out:Total Energy Use Post PV": 106.64,
    "out:Primary Energy": 98.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.55,
    "out:CO2 Operational/m2": 22.36,
    "out:Total CO2/m2": 57.91,
    "out:Total CO2 (tons)": 160.23,
    "out:Klimatpaverkan": -6.79,
    "out:Initial Cost/MSEK": 5.822,
    "out:Running cost/(Apt SEK y)": 17209,
    "out:Running Cost over RSP/MSEK": 6.457,
    "out:LCP/MSEK": -0.977,
    "out:ROI% old": 11.89,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 67.3,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 620,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 204758,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 206441,
    "out:DH kr savings": 204758,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 197451,
    "out:% savings (space heating)": 39.28,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.36,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 111.26,
    "out:Total Energy Use Post PV": 104.64,
    "out:Primary Energy": 98.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.2,
    "out:CO2 Operational/m2": 21.64,
    "out:Total CO2/m2": 57.84,
    "out:Total CO2 (tons)": 160.04,
    "out:Klimatpaverkan": -6.98,
    "out:Initial Cost/MSEK": 5.971,
    "out:Running cost/(Apt SEK y)": 16861,
    "out:Running Cost over RSP/MSEK": 6.327,
    "out:LCP/MSEK": -0.996,
    "out:ROI% old": 11.9,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 70.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 637,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 210292,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211975,
    "out:DH kr savings": 210292,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 206063,
    "out:% savings (space heating)": 36.63,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01011201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.36,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 108.26,
    "out:Total Energy Use Post PV": 101.64,
    "out:Primary Energy": 96.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.2,
    "out:CO2 Operational/m2": 20.56,
    "out:Total CO2/m2": 56.76,
    "out:Total CO2 (tons)": 157.05,
    "out:Klimatpaverkan": -9.97,
    "out:Initial Cost/MSEK": 6.04,
    "out:Running cost/(Apt SEK y)": 16339,
    "out:Running Cost over RSP/MSEK": 6.131,
    "out:LCP/MSEK": -0.869,
    "out:ROI% old": 12.23,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 75.5,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 662,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 218593,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220276,
    "out:DH kr savings": 218593,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 197451,
    "out:% savings (space heating)": 39.28,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 69.36,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.75,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 147.2,
    "out:Primary Energy": 125.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.97,
    "out:CO2 Operational/m2": 48.84,
    "out:Total CO2/m2": 62.81,
    "out:Total CO2 (tons)": 173.79,
    "out:Klimatpaverkan": 6.77,
    "out:Initial Cost/MSEK": 3.001,
    "out:Running cost/(Apt SEK y)": 24556,
    "out:Running Cost over RSP/MSEK": 9.21,
    "out:LCP/MSEK": -0.909,
    "out:ROI% old": 9.96,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 21.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 267,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88544,
    "out:DH kr savings": 88544,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 237271,
    "out:% savings (space heating)": 27.03,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.75,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.13,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 143.2,
    "out:Primary Energy": 122.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.97,
    "out:CO2 Operational/m2": 47.4,
    "out:Total CO2/m2": 61.37,
    "out:Total CO2 (tons)": 169.81,
    "out:Klimatpaverkan": 2.79,
    "out:Initial Cost/MSEK": 3.07,
    "out:Running cost/(Apt SEK y)": 23860,
    "out:Running Cost over RSP/MSEK": 8.949,
    "out:LCP/MSEK": -0.717,
    "out:ROI% old": 10.95,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 301,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99612,
    "out:DH kr savings": 99612,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 227241,
    "out:% savings (space heating)": 30.12,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.75,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 141.2,
    "out:Total Energy Use Post PV": 141.2,
    "out:Primary Energy": 122.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.62,
    "out:CO2 Operational/m2": 46.68,
    "out:Total CO2/m2": 61.3,
    "out:Total CO2 (tons)": 169.62,
    "out:Klimatpaverkan": 2.6,
    "out:Initial Cost/MSEK": 3.219,
    "out:Running cost/(Apt SEK y)": 23511,
    "out:Running Cost over RSP/MSEK": 8.818,
    "out:LCP/MSEK": -0.736,
    "out:ROI% old": 11.02,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 318,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105146,
    "out:DH kr savings": 105146,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 237271,
    "out:% savings (space heating)": 27.03,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.75,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.13,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 137.2,
    "out:Total Energy Use Post PV": 137.2,
    "out:Primary Energy": 119.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.62,
    "out:CO2 Operational/m2": 45.24,
    "out:Total CO2/m2": 59.86,
    "out:Total CO2 (tons)": 165.63,
    "out:Klimatpaverkan": -1.39,
    "out:Initial Cost/MSEK": 3.289,
    "out:Running cost/(Apt SEK y)": 22815,
    "out:Running Cost over RSP/MSEK": 8.557,
    "out:LCP/MSEK": -0.543,
    "out:ROI% old": 11.93,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 30.7,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 351,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116214,
    "out:DH kr savings": 116214,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 227241,
    "out:% savings (space heating)": 30.12,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.75,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 147.2,
    "out:Total Energy Use Post PV": 141.92,
    "out:Primary Energy": 115.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.85,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 53.63,
    "out:Total CO2 (tons)": 148.4,
    "out:Klimatpaverkan": -18.62,
    "out:Initial Cost/MSEK": 3.461,
    "out:Running cost/(Apt SEK y)": 23630,
    "out:Running Cost over RSP/MSEK": 8.872,
    "out:LCP/MSEK": -1.032,
    "out:ROI% old": 10.03,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 26.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 312,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 88544,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132967,
    "out:DH kr savings": 88544,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 237271,
    "out:% savings (space heating)": 27.03,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.75,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.13,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 143.2,
    "out:Total Energy Use Post PV": 137.92,
    "out:Primary Energy": 112.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.85,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 52.19,
    "out:Total CO2 (tons)": 144.41,
    "out:Klimatpaverkan": -22.61,
    "out:Initial Cost/MSEK": 3.53,
    "out:Running cost/(Apt SEK y)": 22934,
    "out:Running Cost over RSP/MSEK": 8.611,
    "out:LCP/MSEK": -0.839,
    "out:ROI% old": 10.89,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 345,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 99612,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 144035,
    "out:DH kr savings": 99612,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 227241,
    "out:% savings (space heating)": 30.12,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.75,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 141.2,
    "out:Total Energy Use Post PV": 135.92,
    "out:Primary Energy": 113.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.5,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 52.12,
    "out:Total CO2 (tons)": 144.22,
    "out:Klimatpaverkan": -22.8,
    "out:Initial Cost/MSEK": 3.68,
    "out:Running cost/(Apt SEK y)": 22586,
    "out:Running Cost over RSP/MSEK": 8.48,
    "out:LCP/MSEK": -0.858,
    "out:ROI% old": 10.96,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 105146,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149569,
    "out:DH kr savings": 105146,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 237271,
    "out:% savings (space heating)": 27.03,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.75,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.13,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 137.2,
    "out:Total Energy Use Post PV": 131.92,
    "out:Primary Energy": 109.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.5,
    "out:CO2 Operational/m2": 26.18,
    "out:Total CO2/m2": 50.68,
    "out:Total CO2 (tons)": 140.24,
    "out:Klimatpaverkan": -26.78,
    "out:Initial Cost/MSEK": 3.749,
    "out:Running cost/(Apt SEK y)": 21890,
    "out:Running Cost over RSP/MSEK": 8.218,
    "out:LCP/MSEK": -0.666,
    "out:ROI% old": 11.75,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 116214,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160637,
    "out:DH kr savings": 116214,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 227241,
    "out:% savings (space heating)": 30.12,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.75,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 138.2,
    "out:Primary Energy": 121.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.97,
    "out:CO2 Operational/m2": 45.6,
    "out:Total CO2/m2": 59.57,
    "out:Total CO2 (tons)": 164.83,
    "out:Klimatpaverkan": -2.19,
    "out:Initial Cost/MSEK": 3.127,
    "out:Running cost/(Apt SEK y)": 22989,
    "out:Running Cost over RSP/MSEK": 8.622,
    "out:LCP/MSEK": -0.448,
    "out:ROI% old": 12.24,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 343,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113447,
    "out:DH kr savings": 113447,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 237271,
    "out:% savings (space heating)": 27.03,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.75,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.13,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 135.2,
    "out:Total Energy Use Post PV": 135.2,
    "out:Primary Energy": 118.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.97,
    "out:CO2 Operational/m2": 44.52,
    "out:Total CO2/m2": 58.49,
    "out:Total CO2 (tons)": 161.84,
    "out:Klimatpaverkan": -5.18,
    "out:Initial Cost/MSEK": 3.197,
    "out:Running cost/(Apt SEK y)": 22467,
    "out:Running Cost over RSP/MSEK": 8.426,
    "out:LCP/MSEK": -0.321,
    "out:ROI% old": 12.85,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 368,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121748,
    "out:DH kr savings": 121748,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 227241,
    "out:% savings (space heating)": 30.12,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.75,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 133.2,
    "out:Primary Energy": 119.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.62,
    "out:CO2 Operational/m2": 43.8,
    "out:Total CO2/m2": 58.42,
    "out:Total CO2 (tons)": 161.65,
    "out:Klimatpaverkan": -5.37,
    "out:Initial Cost/MSEK": 3.346,
    "out:Running cost/(Apt SEK y)": 22119,
    "out:Running Cost over RSP/MSEK": 8.295,
    "out:LCP/MSEK": -0.339,
    "out:ROI% old": 12.84,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 384,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127282,
    "out:DH kr savings": 127282,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 237271,
    "out:% savings (space heating)": 27.03,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.75,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.13,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 130.2,
    "out:Primary Energy": 116.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.62,
    "out:CO2 Operational/m2": 42.72,
    "out:Total CO2/m2": 57.34,
    "out:Total CO2 (tons)": 158.66,
    "out:Klimatpaverkan": -8.36,
    "out:Initial Cost/MSEK": 3.415,
    "out:Running cost/(Apt SEK y)": 21596,
    "out:Running Cost over RSP/MSEK": 8.099,
    "out:LCP/MSEK": -0.212,
    "out:ROI% old": 13.4,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 409,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135583,
    "out:DH kr savings": 135583,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 227241,
    "out:% savings (space heating)": 30.12,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.75,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 132.92,
    "out:Primary Energy": 112.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.85,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 50.39,
    "out:Total CO2 (tons)": 139.43,
    "out:Klimatpaverkan": -27.59,
    "out:Initial Cost/MSEK": 3.588,
    "out:Running cost/(Apt SEK y)": 22064,
    "out:Running Cost over RSP/MSEK": 8.284,
    "out:LCP/MSEK": -0.57,
    "out:ROI% old": 12.02,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 387,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157870,
    "out:DH kr savings": 113447,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 237271,
    "out:% savings (space heating)": 27.03,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.75,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.13,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 135.2,
    "out:Total Energy Use Post PV": 129.92,
    "out:Primary Energy": 109.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.85,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 49.31,
    "out:Total CO2 (tons)": 136.44,
    "out:Klimatpaverkan": -30.58,
    "out:Initial Cost/MSEK": 3.657,
    "out:Running cost/(Apt SEK y)": 21541,
    "out:Running Cost over RSP/MSEK": 8.088,
    "out:LCP/MSEK": -0.443,
    "out:ROI% old": 12.55,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 166171,
    "out:DH kr savings": 121748,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 227241,
    "out:% savings (space heating)": 30.12,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.75,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 127.92,
    "out:Primary Energy": 110.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.5,
    "out:CO2 Operational/m2": 24.74,
    "out:Total CO2/m2": 49.24,
    "out:Total CO2 (tons)": 136.26,
    "out:Klimatpaverkan": -30.76,
    "out:Initial Cost/MSEK": 3.807,
    "out:Running cost/(Apt SEK y)": 21193,
    "out:Running Cost over RSP/MSEK": 7.957,
    "out:LCP/MSEK": -0.462,
    "out:ROI% old": 12.55,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 429,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 127282,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171705,
    "out:DH kr savings": 127282,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 237271,
    "out:% savings (space heating)": 27.03,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.75,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.13,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 124.92,
    "out:Primary Energy": 107.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.5,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 48.16,
    "out:Total CO2 (tons)": 133.27,
    "out:Klimatpaverkan": -33.75,
    "out:Initial Cost/MSEK": 3.876,
    "out:Running cost/(Apt SEK y)": 20671,
    "out:Running Cost over RSP/MSEK": 7.761,
    "out:LCP/MSEK": -0.335,
    "out:ROI% old": 13.05,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 454,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 135583,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180006,
    "out:DH kr savings": 135583,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 227241,
    "out:% savings (space heating)": 30.12,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.75,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 136.26,
    "out:Primary Energy": 126.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.76,
    "out:CO2 Operational/m2": 43.06,
    "out:Total CO2/m2": 60.83,
    "out:Total CO2 (tons)": 168.31,
    "out:Klimatpaverkan": 1.29,
    "out:Initial Cost/MSEK": 3.645,
    "out:Running cost/(Apt SEK y)": 22177,
    "out:Running Cost over RSP/MSEK": 8.313,
    "out:LCP/MSEK": -0.656,
    "out:ROI% old": 11.71,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 31.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96537,
    "out:DH kr savings": 141117,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 237271,
    "out:% savings (space heating)": 27.03,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.75,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.13,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 132.26,
    "out:Total Energy Use Post PV": 132.26,
    "out:Primary Energy": 123.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.76,
    "out:CO2 Operational/m2": 41.62,
    "out:Total CO2/m2": 59.39,
    "out:Total CO2 (tons)": 164.33,
    "out:Klimatpaverkan": -2.69,
    "out:Initial Cost/MSEK": 3.714,
    "out:Running cost/(Apt SEK y)": 21481,
    "out:Running Cost over RSP/MSEK": 8.052,
    "out:LCP/MSEK": -0.464,
    "out:ROI% old": 12.5,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 415,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107605,
    "out:DH kr savings": 152185,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 227241,
    "out:% savings (space heating)": 30.12,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.75,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 130.26,
    "out:Total Energy Use Post PV": 130.26,
    "out:Primary Energy": 123.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.42,
    "out:CO2 Operational/m2": 40.9,
    "out:Total CO2/m2": 59.32,
    "out:Total CO2 (tons)": 164.14,
    "out:Klimatpaverkan": -2.88,
    "out:Initial Cost/MSEK": 3.864,
    "out:Running cost/(Apt SEK y)": 21132,
    "out:Running Cost over RSP/MSEK": 7.921,
    "out:LCP/MSEK": -0.483,
    "out:ROI% old": 12.5,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 432,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113139,
    "out:DH kr savings": 157719,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 237271,
    "out:% savings (space heating)": 27.03,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.75,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.13,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 126.26,
    "out:Total Energy Use Post PV": 126.26,
    "out:Primary Energy": 120.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.42,
    "out:CO2 Operational/m2": 39.46,
    "out:Total CO2/m2": 57.88,
    "out:Total CO2 (tons)": 160.16,
    "out:Klimatpaverkan": -6.86,
    "out:Initial Cost/MSEK": 3.933,
    "out:Running cost/(Apt SEK y)": 20436,
    "out:Running Cost over RSP/MSEK": 7.659,
    "out:LCP/MSEK": -0.29,
    "out:ROI% old": 13.23,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 168787,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124207,
    "out:DH kr savings": 168787,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 227241,
    "out:% savings (space heating)": 30.12,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.75,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 136.26,
    "out:Total Energy Use Post PV": 129.64,
    "out:Primary Energy": 114.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.65,
    "out:CO2 Operational/m2": 30.64,
    "out:Total CO2/m2": 58.28,
    "out:Total CO2 (tons)": 161.27,
    "out:Klimatpaverkan": -5.75,
    "out:Initial Cost/MSEK": 4.105,
    "out:Running cost/(Apt SEK y)": 21213,
    "out:Running Cost over RSP/MSEK": 7.961,
    "out:LCP/MSEK": -0.764,
    "out:ROI% old": 11.63,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142800,
    "out:DH kr savings": 141117,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 237271,
    "out:% savings (space heating)": 27.03,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.75,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.13,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 132.26,
    "out:Total Energy Use Post PV": 125.64,
    "out:Primary Energy": 111.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.65,
    "out:CO2 Operational/m2": 29.2,
    "out:Total CO2/m2": 56.84,
    "out:Total CO2 (tons)": 157.29,
    "out:Klimatpaverkan": -9.73,
    "out:Initial Cost/MSEK": 4.175,
    "out:Running cost/(Apt SEK y)": 20517,
    "out:Running Cost over RSP/MSEK": 7.699,
    "out:LCP/MSEK": -0.572,
    "out:ROI% old": 12.33,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 461,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 153868,
    "out:DH kr savings": 152185,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 227241,
    "out:% savings (space heating)": 30.12,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.75,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 130.26,
    "out:Total Energy Use Post PV": 123.64,
    "out:Primary Energy": 111.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.3,
    "out:CO2 Operational/m2": 28.48,
    "out:Total CO2/m2": 56.78,
    "out:Total CO2 (tons)": 157.1,
    "out:Klimatpaverkan": -9.92,
    "out:Initial Cost/MSEK": 4.324,
    "out:Running cost/(Apt SEK y)": 20169,
    "out:Running Cost over RSP/MSEK": 7.569,
    "out:LCP/MSEK": -0.591,
    "out:ROI% old": 12.33,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 157719,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159402,
    "out:DH kr savings": 157719,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 237271,
    "out:% savings (space heating)": 27.03,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.75,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.13,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 126.26,
    "out:Total Energy Use Post PV": 119.64,
    "out:Primary Energy": 108.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.3,
    "out:CO2 Operational/m2": 27.04,
    "out:Total CO2/m2": 55.34,
    "out:Total CO2 (tons)": 153.12,
    "out:Klimatpaverkan": -13.9,
    "out:Initial Cost/MSEK": 4.393,
    "out:Running cost/(Apt SEK y)": 19472,
    "out:Running Cost over RSP/MSEK": 7.307,
    "out:LCP/MSEK": -0.399,
    "out:ROI% old": 12.99,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 168787,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170470,
    "out:DH kr savings": 168787,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 227241,
    "out:% savings (space heating)": 30.12,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.75,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 127.26,
    "out:Primary Energy": 123,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.76,
    "out:CO2 Operational/m2": 39.82,
    "out:Total CO2/m2": 57.59,
    "out:Total CO2 (tons)": 159.35,
    "out:Klimatpaverkan": -7.67,
    "out:Initial Cost/MSEK": 3.772,
    "out:Running cost/(Apt SEK y)": 20610,
    "out:Running Cost over RSP/MSEK": 7.725,
    "out:LCP/MSEK": -0.195,
    "out:ROI% old": 13.55,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121440,
    "out:DH kr savings": 166020,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 237271,
    "out:% savings (space heating)": 27.03,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.75,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.13,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 124.26,
    "out:Total Energy Use Post PV": 124.26,
    "out:Primary Energy": 119.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.76,
    "out:CO2 Operational/m2": 38.74,
    "out:Total CO2/m2": 56.51,
    "out:Total CO2 (tons)": 156.36,
    "out:Klimatpaverkan": -10.66,
    "out:Initial Cost/MSEK": 3.841,
    "out:Running cost/(Apt SEK y)": 20088,
    "out:Running Cost over RSP/MSEK": 7.529,
    "out:LCP/MSEK": -0.068,
    "out:ROI% old": 14.03,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 174321,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 129741,
    "out:DH kr savings": 174321,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 227241,
    "out:% savings (space heating)": 30.12,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.75,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 122.26,
    "out:Total Energy Use Post PV": 122.26,
    "out:Primary Energy": 120.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.42,
    "out:CO2 Operational/m2": 38.02,
    "out:Total CO2/m2": 56.44,
    "out:Total CO2 (tons)": 156.17,
    "out:Klimatpaverkan": -10.85,
    "out:Initial Cost/MSEK": 3.99,
    "out:Running cost/(Apt SEK y)": 19740,
    "out:Running Cost over RSP/MSEK": 7.398,
    "out:LCP/MSEK": -0.086,
    "out:ROI% old": 13.98,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 46.7,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 499,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 179855,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135275,
    "out:DH kr savings": 179855,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 237271,
    "out:% savings (space heating)": 27.03,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.76,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.13,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 119.26,
    "out:Total Energy Use Post PV": 119.26,
    "out:Primary Energy": 117.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.42,
    "out:CO2 Operational/m2": 36.94,
    "out:Total CO2/m2": 55.36,
    "out:Total CO2 (tons)": 153.19,
    "out:Klimatpaverkan": -13.83,
    "out:Initial Cost/MSEK": 4.059,
    "out:Running cost/(Apt SEK y)": 19217,
    "out:Running Cost over RSP/MSEK": 7.202,
    "out:LCP/MSEK": 0.041,
    "out:ROI% old": 14.43,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 524,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 188156,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143576,
    "out:DH kr savings": 188156,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 227241,
    "out:% savings (space heating)": 30.12,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 80.13,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.75,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 120.64,
    "out:Primary Energy": 111.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.65,
    "out:CO2 Operational/m2": 27.4,
    "out:Total CO2/m2": 55.04,
    "out:Total CO2 (tons)": 152.31,
    "out:Klimatpaverkan": -14.71,
    "out:Initial Cost/MSEK": 4.232,
    "out:Running cost/(Apt SEK y)": 19646,
    "out:Running Cost over RSP/MSEK": 7.373,
    "out:LCP/MSEK": -0.303,
    "out:ROI% old": 13.26,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167703,
    "out:DH kr savings": 166020,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 237271,
    "out:% savings (space heating)": 27.03,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 85.75,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.13,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 124.26,
    "out:Total Energy Use Post PV": 117.64,
    "out:Primary Energy": 107.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.65,
    "out:CO2 Operational/m2": 26.32,
    "out:Total CO2/m2": 53.96,
    "out:Total CO2 (tons)": 149.32,
    "out:Klimatpaverkan": -17.7,
    "out:Initial Cost/MSEK": 4.301,
    "out:Running cost/(Apt SEK y)": 19124,
    "out:Running Cost over RSP/MSEK": 7.176,
    "out:LCP/MSEK": -0.176,
    "out:ROI% old": 13.7,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 528,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 174321,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176004,
    "out:DH kr savings": 174321,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 227241,
    "out:% savings (space heating)": 30.12,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 82.13,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.75,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 122.26,
    "out:Total Energy Use Post PV": 115.64,
    "out:Primary Energy": 108.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.3,
    "out:CO2 Operational/m2": 25.6,
    "out:Total CO2/m2": 53.9,
    "out:Total CO2 (tons)": 149.13,
    "out:Klimatpaverkan": -17.89,
    "out:Initial Cost/MSEK": 4.451,
    "out:Running cost/(Apt SEK y)": 18776,
    "out:Running Cost over RSP/MSEK": 7.046,
    "out:LCP/MSEK": -0.195,
    "out:ROI% old": 13.66,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 54.3,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 545,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 179855,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181538,
    "out:DH kr savings": 179855,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 237271,
    "out:% savings (space heating)": 27.03,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.76,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.13,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 119.26,
    "out:Total Energy Use Post PV": 112.64,
    "out:Primary Energy": 105.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.3,
    "out:CO2 Operational/m2": 24.52,
    "out:Total CO2/m2": 52.82,
    "out:Total CO2 (tons)": 146.14,
    "out:Klimatpaverkan": -20.88,
    "out:Initial Cost/MSEK": 4.52,
    "out:Running cost/(Apt SEK y)": 18254,
    "out:Running Cost over RSP/MSEK": 6.85,
    "out:LCP/MSEK": -0.068,
    "out:ROI% old": 14.07,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.48,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 58.4,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 570,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 188156,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 189839,
    "out:DH kr savings": 188156,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 227241,
    "out:% savings (space heating)": 30.12,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 80.13,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 138.2,
    "out:Primary Energy": 117.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.28,
    "out:CO2 Operational/m2": 45.6,
    "out:Total CO2/m2": 61.88,
    "out:Total CO2 (tons)": 171.23,
    "out:Klimatpaverkan": 4.21,
    "out:Initial Cost/MSEK": 3.447,
    "out:Running cost/(Apt SEK y)": 22989,
    "out:Running Cost over RSP/MSEK": 8.622,
    "out:LCP/MSEK": -0.768,
    "out:ROI% old": 11.1,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 343,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113447,
    "out:DH kr savings": 113447,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 212049,
    "out:% savings (space heating)": 34.79,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 135.2,
    "out:Total Energy Use Post PV": 135.2,
    "out:Primary Energy": 114.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.28,
    "out:CO2 Operational/m2": 44.52,
    "out:Total CO2/m2": 60.8,
    "out:Total CO2 (tons)": 168.24,
    "out:Klimatpaverkan": 1.22,
    "out:Initial Cost/MSEK": 3.517,
    "out:Running cost/(Apt SEK y)": 22467,
    "out:Running Cost over RSP/MSEK": 8.426,
    "out:LCP/MSEK": -0.641,
    "out:ROI% old": 11.68,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 368,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121748,
    "out:DH kr savings": 121748,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203165,
    "out:% savings (space heating)": 37.52,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 131.2,
    "out:Primary Energy": 114.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.93,
    "out:CO2 Operational/m2": 43.08,
    "out:Total CO2/m2": 60.02,
    "out:Total CO2 (tons)": 166.06,
    "out:Klimatpaverkan": -0.96,
    "out:Initial Cost/MSEK": 3.666,
    "out:Running cost/(Apt SEK y)": 21771,
    "out:Running Cost over RSP/MSEK": 8.164,
    "out:LCP/MSEK": -0.529,
    "out:ROI% old": 12.23,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 401,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132816,
    "out:DH kr savings": 132816,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 212049,
    "out:% savings (space heating)": 34.79,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 128.2,
    "out:Total Energy Use Post PV": 128.2,
    "out:Primary Energy": 111.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.93,
    "out:CO2 Operational/m2": 42,
    "out:Total CO2/m2": 58.94,
    "out:Total CO2 (tons)": 163.07,
    "out:Klimatpaverkan": -3.95,
    "out:Initial Cost/MSEK": 3.735,
    "out:Running cost/(Apt SEK y)": 21248,
    "out:Running Cost over RSP/MSEK": 7.968,
    "out:LCP/MSEK": -0.402,
    "out:ROI% old": 12.75,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 11,
    "out:Return/kSEK/y": 426,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141117,
    "out:DH kr savings": 141117,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203165,
    "out:% savings (space heating)": 37.52,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 132.92,
    "out:Primary Energy": 107.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.16,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 52.71,
    "out:Total CO2 (tons)": 145.83,
    "out:Klimatpaverkan": -21.19,
    "out:Initial Cost/MSEK": 3.908,
    "out:Running cost/(Apt SEK y)": 22064,
    "out:Running Cost over RSP/MSEK": 8.284,
    "out:LCP/MSEK": -0.89,
    "out:ROI% old": 11.03,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 34.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 387,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 113447,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157870,
    "out:DH kr savings": 113447,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 212049,
    "out:% savings (space heating)": 34.79,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 135.2,
    "out:Total Energy Use Post PV": 129.92,
    "out:Primary Energy": 105,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.16,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 51.63,
    "out:Total CO2 (tons)": 142.85,
    "out:Klimatpaverkan": -24.17,
    "out:Initial Cost/MSEK": 3.977,
    "out:Running cost/(Apt SEK y)": 21541,
    "out:Running Cost over RSP/MSEK": 8.088,
    "out:LCP/MSEK": -0.763,
    "out:ROI% old": 11.55,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 166171,
    "out:DH kr savings": 121748,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203165,
    "out:% savings (space heating)": 37.52,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 125.92,
    "out:Primary Energy": 105.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.82,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 50.84,
    "out:Total CO2 (tons)": 140.67,
    "out:Klimatpaverkan": -26.35,
    "out:Initial Cost/MSEK": 4.126,
    "out:Running cost/(Apt SEK y)": 20845,
    "out:Running Cost over RSP/MSEK": 7.826,
    "out:LCP/MSEK": -0.651,
    "out:ROI% old": 12.03,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 132816,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 177239,
    "out:DH kr savings": 132816,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 212049,
    "out:% savings (space heating)": 34.79,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 128.2,
    "out:Total Energy Use Post PV": 122.92,
    "out:Primary Energy": 102.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.82,
    "out:CO2 Operational/m2": 22.94,
    "out:Total CO2/m2": 49.76,
    "out:Total CO2 (tons)": 137.68,
    "out:Klimatpaverkan": -29.34,
    "out:Initial Cost/MSEK": 4.196,
    "out:Running cost/(Apt SEK y)": 20323,
    "out:Running Cost over RSP/MSEK": 7.63,
    "out:LCP/MSEK": -0.524,
    "out:ROI% old": 12.5,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 471,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185540,
    "out:DH kr savings": 141117,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203165,
    "out:% savings (space heating)": 37.52,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 129.2,
    "out:Primary Energy": 113.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.28,
    "out:CO2 Operational/m2": 42.36,
    "out:Total CO2/m2": 58.64,
    "out:Total CO2 (tons)": 162.26,
    "out:Klimatpaverkan": -4.76,
    "out:Initial Cost/MSEK": 3.574,
    "out:Running cost/(Apt SEK y)": 21422,
    "out:Running Cost over RSP/MSEK": 8.034,
    "out:LCP/MSEK": -0.306,
    "out:ROI% old": 13.06,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 38.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 418,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138350,
    "out:DH kr savings": 138350,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 212049,
    "out:% savings (space heating)": 34.79,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 126.2,
    "out:Primary Energy": 111.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.28,
    "out:CO2 Operational/m2": 41.28,
    "out:Total CO2/m2": 57.56,
    "out:Total CO2 (tons)": 159.28,
    "out:Klimatpaverkan": -7.74,
    "out:Initial Cost/MSEK": 3.643,
    "out:Running cost/(Apt SEK y)": 20900,
    "out:Running Cost over RSP/MSEK": 7.838,
    "out:LCP/MSEK": -0.179,
    "out:ROI% old": 13.58,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 443,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146651,
    "out:DH kr savings": 146651,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203165,
    "out:% savings (space heating)": 37.52,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 124.2,
    "out:Total Energy Use Post PV": 124.2,
    "out:Primary Energy": 111.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.93,
    "out:CO2 Operational/m2": 40.56,
    "out:Total CO2/m2": 57.5,
    "out:Total CO2 (tons)": 159.09,
    "out:Klimatpaverkan": -7.93,
    "out:Initial Cost/MSEK": 3.793,
    "out:Running cost/(Apt SEK y)": 20552,
    "out:Running Cost over RSP/MSEK": 7.707,
    "out:LCP/MSEK": -0.198,
    "out:ROI% old": 13.54,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 12,
    "out:Return/kSEK/y": 460,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152185,
    "out:DH kr savings": 152185,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 212049,
    "out:% savings (space heating)": 34.79,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 121.2,
    "out:Total Energy Use Post PV": 121.2,
    "out:Primary Energy": 108.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.93,
    "out:CO2 Operational/m2": 39.48,
    "out:Total CO2/m2": 56.42,
    "out:Total CO2 (tons)": 156.1,
    "out:Klimatpaverkan": -10.92,
    "out:Initial Cost/MSEK": 3.862,
    "out:Running cost/(Apt SEK y)": 20030,
    "out:Running Cost over RSP/MSEK": 7.511,
    "out:LCP/MSEK": -0.071,
    "out:ROI% old": 14.02,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 13,
    "out:Return/kSEK/y": 485,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160486,
    "out:DH kr savings": 160486,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203165,
    "out:% savings (space heating)": 37.52,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 129.2,
    "out:Total Energy Use Post PV": 123.92,
    "out:Primary Energy": 104.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.16,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 49.47,
    "out:Total CO2 (tons)": 136.87,
    "out:Klimatpaverkan": -30.15,
    "out:Initial Cost/MSEK": 4.035,
    "out:Running cost/(Apt SEK y)": 20497,
    "out:Running Cost over RSP/MSEK": 7.696,
    "out:LCP/MSEK": -0.428,
    "out:ROI% old": 12.77,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 138350,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182773,
    "out:DH kr savings": 138350,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 212049,
    "out:% savings (space heating)": 34.79,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 120.92,
    "out:Primary Energy": 101.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.16,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 48.39,
    "out:Total CO2 (tons)": 133.88,
    "out:Klimatpaverkan": -33.14,
    "out:Initial Cost/MSEK": 4.104,
    "out:Running cost/(Apt SEK y)": 19975,
    "out:Running Cost over RSP/MSEK": 7.499,
    "out:LCP/MSEK": -0.301,
    "out:ROI% old": 13.24,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191074,
    "out:DH kr savings": 146651,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203165,
    "out:% savings (space heating)": 37.52,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 124.2,
    "out:Total Energy Use Post PV": 118.92,
    "out:Primary Energy": 102.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.82,
    "out:CO2 Operational/m2": 21.5,
    "out:Total CO2/m2": 48.32,
    "out:Total CO2 (tons)": 133.69,
    "out:Klimatpaverkan": -33.33,
    "out:Initial Cost/MSEK": 4.253,
    "out:Running cost/(Apt SEK y)": 19626,
    "out:Running Cost over RSP/MSEK": 7.369,
    "out:LCP/MSEK": -0.32,
    "out:ROI% old": 13.21,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 152185,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196608,
    "out:DH kr savings": 152185,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 212049,
    "out:% savings (space heating)": 34.79,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 121.2,
    "out:Total Energy Use Post PV": 115.92,
    "out:Primary Energy": 99.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.82,
    "out:CO2 Operational/m2": 20.42,
    "out:Total CO2/m2": 47.24,
    "out:Total CO2 (tons)": 130.71,
    "out:Klimatpaverkan": -36.31,
    "out:Initial Cost/MSEK": 4.322,
    "out:Running cost/(Apt SEK y)": 19104,
    "out:Running Cost over RSP/MSEK": 7.173,
    "out:LCP/MSEK": -0.193,
    "out:ROI% old": 13.65,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 54.3,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204909,
    "out:DH kr savings": 160486,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203165,
    "out:% savings (space heating)": 37.52,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 127.26,
    "out:Primary Energy": 118.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.08,
    "out:CO2 Operational/m2": 39.82,
    "out:Total CO2/m2": 59.9,
    "out:Total CO2 (tons)": 165.75,
    "out:Klimatpaverkan": -1.27,
    "out:Initial Cost/MSEK": 4.092,
    "out:Running cost/(Apt SEK y)": 20610,
    "out:Running Cost over RSP/MSEK": 7.725,
    "out:LCP/MSEK": -0.515,
    "out:ROI% old": 12.49,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121440,
    "out:DH kr savings": 166020,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 212049,
    "out:% savings (space heating)": 34.79,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 124.26,
    "out:Total Energy Use Post PV": 124.26,
    "out:Primary Energy": 115.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.08,
    "out:CO2 Operational/m2": 38.74,
    "out:Total CO2/m2": 58.82,
    "out:Total CO2 (tons)": 162.76,
    "out:Klimatpaverkan": -4.26,
    "out:Initial Cost/MSEK": 4.161,
    "out:Running cost/(Apt SEK y)": 20088,
    "out:Running Cost over RSP/MSEK": 7.529,
    "out:LCP/MSEK": -0.388,
    "out:ROI% old": 12.95,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 174321,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 129741,
    "out:DH kr savings": 174321,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203165,
    "out:% savings (space heating)": 37.52,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 120.26,
    "out:Total Energy Use Post PV": 120.26,
    "out:Primary Energy": 115.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.73,
    "out:CO2 Operational/m2": 37.3,
    "out:Total CO2/m2": 58.04,
    "out:Total CO2 (tons)": 160.59,
    "out:Klimatpaverkan": -6.43,
    "out:Initial Cost/MSEK": 4.31,
    "out:Running cost/(Apt SEK y)": 19392,
    "out:Running Cost over RSP/MSEK": 7.267,
    "out:LCP/MSEK": -0.276,
    "out:ROI% old": 13.37,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185389,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140809,
    "out:DH kr savings": 185389,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 212049,
    "out:% savings (space heating)": 34.79,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 117.26,
    "out:Total Energy Use Post PV": 117.26,
    "out:Primary Energy": 113.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.73,
    "out:CO2 Operational/m2": 36.22,
    "out:Total CO2/m2": 56.96,
    "out:Total CO2 (tons)": 157.6,
    "out:Klimatpaverkan": -9.42,
    "out:Initial Cost/MSEK": 4.379,
    "out:Running cost/(Apt SEK y)": 18869,
    "out:Running Cost over RSP/MSEK": 7.071,
    "out:LCP/MSEK": -0.149,
    "out:ROI% old": 13.8,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 53,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 540,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193690,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149110,
    "out:DH kr savings": 193690,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203165,
    "out:% savings (space heating)": 37.52,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 127.26,
    "out:Total Energy Use Post PV": 120.64,
    "out:Primary Energy": 106.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.96,
    "out:CO2 Operational/m2": 27.4,
    "out:Total CO2/m2": 57.36,
    "out:Total CO2 (tons)": 158.71,
    "out:Klimatpaverkan": -8.31,
    "out:Initial Cost/MSEK": 4.552,
    "out:Running cost/(Apt SEK y)": 19646,
    "out:Running Cost over RSP/MSEK": 7.373,
    "out:LCP/MSEK": -0.623,
    "out:ROI% old": 12.33,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167703,
    "out:DH kr savings": 166020,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 212049,
    "out:% savings (space heating)": 34.79,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 124.26,
    "out:Total Energy Use Post PV": 117.64,
    "out:Primary Energy": 103.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.96,
    "out:CO2 Operational/m2": 26.32,
    "out:Total CO2/m2": 56.28,
    "out:Total CO2 (tons)": 155.72,
    "out:Klimatpaverkan": -11.3,
    "out:Initial Cost/MSEK": 4.621,
    "out:Running cost/(Apt SEK y)": 19124,
    "out:Running Cost over RSP/MSEK": 7.176,
    "out:LCP/MSEK": -0.496,
    "out:ROI% old": 12.75,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 528,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 174321,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176004,
    "out:DH kr savings": 174321,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203165,
    "out:% savings (space heating)": 37.52,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 120.26,
    "out:Total Energy Use Post PV": 113.64,
    "out:Primary Energy": 103.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.61,
    "out:CO2 Operational/m2": 24.88,
    "out:Total CO2/m2": 55.49,
    "out:Total CO2 (tons)": 153.54,
    "out:Klimatpaverkan": -13.48,
    "out:Initial Cost/MSEK": 4.771,
    "out:Running cost/(Apt SEK y)": 18428,
    "out:Running Cost over RSP/MSEK": 6.915,
    "out:LCP/MSEK": -0.384,
    "out:ROI% old": 13.14,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 57,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 562,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185389,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 187072,
    "out:DH kr savings": 185389,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 212049,
    "out:% savings (space heating)": 34.79,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 117.26,
    "out:Total Energy Use Post PV": 110.64,
    "out:Primary Energy": 101.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.61,
    "out:CO2 Operational/m2": 23.8,
    "out:Total CO2/m2": 54.41,
    "out:Total CO2 (tons)": 150.56,
    "out:Klimatpaverkan": -16.46,
    "out:Initial Cost/MSEK": 4.84,
    "out:Running cost/(Apt SEK y)": 17905,
    "out:Running Cost over RSP/MSEK": 6.719,
    "out:LCP/MSEK": -0.257,
    "out:ROI% old": 13.53,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 61.3,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 587,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 193690,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195373,
    "out:DH kr savings": 193690,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203165,
    "out:% savings (space heating)": 37.52,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 118.26,
    "out:Total Energy Use Post PV": 118.26,
    "out:Primary Energy": 115.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.08,
    "out:CO2 Operational/m2": 36.58,
    "out:Total CO2/m2": 56.66,
    "out:Total CO2 (tons)": 156.79,
    "out:Klimatpaverkan": -10.23,
    "out:Initial Cost/MSEK": 4.218,
    "out:Running cost/(Apt SEK y)": 19043,
    "out:Running Cost over RSP/MSEK": 7.136,
    "out:LCP/MSEK": -0.053,
    "out:ROI% old": 14.11,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190923,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146343,
    "out:DH kr savings": 190923,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 212049,
    "out:% savings (space heating)": 34.79,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 115.26,
    "out:Total Energy Use Post PV": 115.26,
    "out:Primary Energy": 112.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.08,
    "out:CO2 Operational/m2": 35.5,
    "out:Total CO2/m2": 55.58,
    "out:Total CO2 (tons)": 153.8,
    "out:Klimatpaverkan": -13.22,
    "out:Initial Cost/MSEK": 4.288,
    "out:Running cost/(Apt SEK y)": 18521,
    "out:Running Cost over RSP/MSEK": 6.94,
    "out:LCP/MSEK": 0.074,
    "out:ROI% old": 14.53,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154644,
    "out:DH kr savings": 199224,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203165,
    "out:% savings (space heating)": 37.52,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 113.26,
    "out:Total Energy Use Post PV": 113.26,
    "out:Primary Energy": 112.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.73,
    "out:CO2 Operational/m2": 34.78,
    "out:Total CO2/m2": 55.52,
    "out:Total CO2 (tons)": 153.61,
    "out:Klimatpaverkan": -13.41,
    "out:Initial Cost/MSEK": 4.437,
    "out:Running cost/(Apt SEK y)": 18173,
    "out:Running Cost over RSP/MSEK": 6.81,
    "out:LCP/MSEK": 0.055,
    "out:ROI% old": 14.46,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 58.4,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 574,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 204758,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160178,
    "out:DH kr savings": 204758,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 212049,
    "out:% savings (space heating)": 34.79,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.64,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 110.26,
    "out:Total Energy Use Post PV": 110.26,
    "out:Primary Energy": 109.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.73,
    "out:CO2 Operational/m2": 33.7,
    "out:Total CO2/m2": 54.44,
    "out:Total CO2 (tons)": 150.62,
    "out:Klimatpaverkan": -16.4,
    "out:Initial Cost/MSEK": 4.506,
    "out:Running cost/(Apt SEK y)": 17651,
    "out:Running Cost over RSP/MSEK": 6.614,
    "out:LCP/MSEK": 0.182,
    "out:ROI% old": 14.86,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 62.7,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 599,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213059,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168479,
    "out:DH kr savings": 213059,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203165,
    "out:% savings (space heating)": 37.52,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.43,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 118.26,
    "out:Total Energy Use Post PV": 111.64,
    "out:Primary Energy": 103.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.96,
    "out:CO2 Operational/m2": 24.16,
    "out:Total CO2/m2": 54.12,
    "out:Total CO2 (tons)": 149.75,
    "out:Klimatpaverkan": -17.27,
    "out:Initial Cost/MSEK": 4.679,
    "out:Running cost/(Apt SEK y)": 18080,
    "out:Running Cost over RSP/MSEK": 6.784,
    "out:LCP/MSEK": -0.161,
    "out:ROI% old": 13.79,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 578,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190923,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192606,
    "out:DH kr savings": 190923,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 212049,
    "out:% savings (space heating)": 34.79,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 115.26,
    "out:Total Energy Use Post PV": 108.64,
    "out:Primary Energy": 100.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.96,
    "out:CO2 Operational/m2": 23.08,
    "out:Total CO2/m2": 53.04,
    "out:Total CO2 (tons)": 146.76,
    "out:Klimatpaverkan": -20.26,
    "out:Initial Cost/MSEK": 4.748,
    "out:Running cost/(Apt SEK y)": 17557,
    "out:Running Cost over RSP/MSEK": 6.588,
    "out:LCP/MSEK": -0.034,
    "out:ROI% old": 14.18,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 64.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 603,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200907,
    "out:DH kr savings": 199224,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203165,
    "out:% savings (space heating)": 37.52,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 113.26,
    "out:Total Energy Use Post PV": 106.64,
    "out:Primary Energy": 100.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.61,
    "out:CO2 Operational/m2": 22.36,
    "out:Total CO2/m2": 52.97,
    "out:Total CO2 (tons)": 146.57,
    "out:Klimatpaverkan": -20.45,
    "out:Initial Cost/MSEK": 4.897,
    "out:Running cost/(Apt SEK y)": 17209,
    "out:Running Cost over RSP/MSEK": 6.457,
    "out:LCP/MSEK": -0.053,
    "out:ROI% old": 14.13,
    "out:Payback discounted": 9,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 67.3,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 620,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 204758,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206441,
    "out:DH kr savings": 204758,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 212049,
    "out:% savings (space heating)": 34.79,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.64,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 110.26,
    "out:Total Energy Use Post PV": 103.64,
    "out:Primary Energy": 97.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.61,
    "out:CO2 Operational/m2": 21.28,
    "out:Total CO2/m2": 51.89,
    "out:Total CO2 (tons)": 143.58,
    "out:Klimatpaverkan": -23.44,
    "out:Initial Cost/MSEK": 4.967,
    "out:Running cost/(Apt SEK y)": 16687,
    "out:Running Cost over RSP/MSEK": 6.261,
    "out:LCP/MSEK": 0.074,
    "out:ROI% old": 14.5,
    "out:Payback discounted": 8,
    "out:Atemp": 2767,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 72.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 645,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213059,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214742,
    "out:DH kr savings": 213059,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203165,
    "out:% savings (space heating)": 37.52,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.43,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.54,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 135.2,
    "out:Total Energy Use Post PV": 135.2,
    "out:Primary Energy": 114.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.03,
    "out:CO2 Operational/m2": 44.52,
    "out:Total CO2/m2": 66.56,
    "out:Total CO2 (tons)": 184.16,
    "out:Klimatpaverkan": 17.14,
    "out:Initial Cost/MSEK": 4.541,
    "out:Running cost/(Apt SEK y)": 22467,
    "out:Running Cost over RSP/MSEK": 8.426,
    "out:LCP/MSEK": -1.665,
    "out:ROI% old": 9.05,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 8,
    "out:Return/kSEK/y": 368,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121748,
    "out:DH kr savings": 121748,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203472,
    "out:% savings (space heating)": 37.43,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.54,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.47,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 132.2,
    "out:Primary Energy": 111.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.03,
    "out:CO2 Operational/m2": 43.44,
    "out:Total CO2/m2": 65.48,
    "out:Total CO2 (tons)": 181.17,
    "out:Klimatpaverkan": 14.15,
    "out:Initial Cost/MSEK": 4.61,
    "out:Running cost/(Apt SEK y)": 21945,
    "out:Running Cost over RSP/MSEK": 8.23,
    "out:LCP/MSEK": -1.538,
    "out:ROI% old": 9.52,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 35.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 393,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130049,
    "out:DH kr savings": 130049,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 194978,
    "out:% savings (space heating)": 40.04,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.47,
    "out:Etvv": 25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.54,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 128.2,
    "out:Total Energy Use Post PV": 128.2,
    "out:Primary Energy": 111.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.69,
    "out:CO2 Operational/m2": 42,
    "out:Total CO2/m2": 64.69,
    "out:Total CO2 (tons)": 178.99,
    "out:Klimatpaverkan": 11.97,
    "out:Initial Cost/MSEK": 4.76,
    "out:Running cost/(Apt SEK y)": 21248,
    "out:Running Cost over RSP/MSEK": 7.968,
    "out:LCP/MSEK": -1.426,
    "out:ROI% old": 10.01,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 426,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141117,
    "out:DH kr savings": 141117,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203472,
    "out:% savings (space heating)": 37.43,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.54,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.47,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 125.2,
    "out:Total Energy Use Post PV": 125.2,
    "out:Primary Energy": 109.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.69,
    "out:CO2 Operational/m2": 40.92,
    "out:Total CO2/m2": 63.61,
    "out:Total CO2 (tons)": 176,
    "out:Klimatpaverkan": 8.98,
    "out:Initial Cost/MSEK": 4.829,
    "out:Running cost/(Apt SEK y)": 20726,
    "out:Running Cost over RSP/MSEK": 7.772,
    "out:LCP/MSEK": -1.299,
    "out:ROI% old": 10.44,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 43.2,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 451,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149418,
    "out:DH kr savings": 149418,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 194978,
    "out:% savings (space heating)": 40.04,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.47,
    "out:Etvv": 21.25,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.54,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 135.2,
    "out:Total Energy Use Post PV": 129.92,
    "out:Primary Energy": 105.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.92,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 57.38,
    "out:Total CO2 (tons)": 158.76,
    "out:Klimatpaverkan": -8.26,
    "out:Initial Cost/MSEK": 5.002,
    "out:Running cost/(Apt SEK y)": 21541,
    "out:Running Cost over RSP/MSEK": 8.088,
    "out:LCP/MSEK": -1.787,
    "out:ROI% old": 9.18,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 37.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 121748,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166171,
    "out:DH kr savings": 121748,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203472,
    "out:% savings (space heating)": 37.43,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.54,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.47,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 126.92,
    "out:Primary Energy": 102.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.92,
    "out:CO2 Operational/m2": 24.38,
    "out:Total CO2/m2": 56.3,
    "out:Total CO2 (tons)": 155.78,
    "out:Klimatpaverkan": -11.24,
    "out:Initial Cost/MSEK": 5.071,
    "out:Running cost/(Apt SEK y)": 21019,
    "out:Running Cost over RSP/MSEK": 7.892,
    "out:LCP/MSEK": -1.661,
    "out:ROI% old": 9.61,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 40.9,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 437,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 130049,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174472,
    "out:DH kr savings": 130049,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 194978,
    "out:% savings (space heating)": 40.04,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.47,
    "out:Etvv": 25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.54,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 128.2,
    "out:Total Energy Use Post PV": 122.92,
    "out:Primary Energy": 102.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.57,
    "out:CO2 Operational/m2": 22.94,
    "out:Total CO2/m2": 55.51,
    "out:Total CO2 (tons)": 153.6,
    "out:Klimatpaverkan": -13.42,
    "out:Initial Cost/MSEK": 5.22,
    "out:Running cost/(Apt SEK y)": 20323,
    "out:Running Cost over RSP/MSEK": 7.63,
    "out:LCP/MSEK": -1.549,
    "out:ROI% old": 10.05,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 471,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141117,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185540,
    "out:DH kr savings": 141117,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203472,
    "out:% savings (space heating)": 37.43,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.54,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.47,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 125.2,
    "out:Total Energy Use Post PV": 119.92,
    "out:Primary Energy": 99.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.57,
    "out:CO2 Operational/m2": 21.86,
    "out:Total CO2/m2": 54.43,
    "out:Total CO2 (tons)": 150.61,
    "out:Klimatpaverkan": -16.41,
    "out:Initial Cost/MSEK": 5.289,
    "out:Running cost/(Apt SEK y)": 19800,
    "out:Running Cost over RSP/MSEK": 7.434,
    "out:LCP/MSEK": -1.422,
    "out:ROI% old": 10.45,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 496,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149418,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 193841,
    "out:DH kr savings": 149418,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 194978,
    "out:% savings (space heating)": 40.04,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.47,
    "out:Etvv": 21.25,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.54,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 126.2,
    "out:Primary Energy": 111.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.03,
    "out:CO2 Operational/m2": 41.28,
    "out:Total CO2/m2": 63.32,
    "out:Total CO2 (tons)": 175.19,
    "out:Klimatpaverkan": 8.17,
    "out:Initial Cost/MSEK": 4.668,
    "out:Running cost/(Apt SEK y)": 20900,
    "out:Running Cost over RSP/MSEK": 7.838,
    "out:LCP/MSEK": -1.204,
    "out:ROI% old": 10.6,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 42.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 9,
    "out:Return/kSEK/y": 443,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146651,
    "out:DH kr savings": 146651,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203472,
    "out:% savings (space heating)": 37.43,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.54,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.47,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 123.2,
    "out:Total Energy Use Post PV": 123.2,
    "out:Primary Energy": 108.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.03,
    "out:CO2 Operational/m2": 40.2,
    "out:Total CO2/m2": 62.24,
    "out:Total CO2 (tons)": 172.21,
    "out:Klimatpaverkan": 5.19,
    "out:Initial Cost/MSEK": 4.737,
    "out:Running cost/(Apt SEK y)": 20378,
    "out:Running Cost over RSP/MSEK": 7.642,
    "out:LCP/MSEK": -1.077,
    "out:ROI% old": 11.04,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 45.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154952,
    "out:DH kr savings": 154952,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 194978,
    "out:% savings (space heating)": 40.04,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.47,
    "out:Etvv": 20,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.54,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 121.2,
    "out:Total Energy Use Post PV": 121.2,
    "out:Primary Energy": 109,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.69,
    "out:CO2 Operational/m2": 39.48,
    "out:Total CO2/m2": 62.17,
    "out:Total CO2 (tons)": 172.02,
    "out:Klimatpaverkan": 5,
    "out:Initial Cost/MSEK": 4.886,
    "out:Running cost/(Apt SEK y)": 20030,
    "out:Running Cost over RSP/MSEK": 7.511,
    "out:LCP/MSEK": -1.095,
    "out:ROI% old": 11.08,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 485,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160486,
    "out:DH kr savings": 160486,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203472,
    "out:% savings (space heating)": 37.43,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.54,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.47,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 18.2,
    "out:Total Energy Use Pre PV": 118.2,
    "out:Total Energy Use Post PV": 118.2,
    "out:Primary Energy": 106.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.69,
    "out:CO2 Operational/m2": 38.4,
    "out:Total CO2/m2": 61.09,
    "out:Total CO2 (tons)": 169.03,
    "out:Klimatpaverkan": 2.01,
    "out:Initial Cost/MSEK": 4.956,
    "out:Running cost/(Apt SEK y)": 19507,
    "out:Running Cost over RSP/MSEK": 7.315,
    "out:LCP/MSEK": -0.968,
    "out:ROI% old": 11.49,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1274,
    "out:Return %": 10,
    "out:Return/kSEK/y": 510,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 168787,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168787,
    "out:DH kr savings": 168787,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 194978,
    "out:% savings (space heating)": 40.04,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.47,
    "out:Etvv": 17,
    "out:Ef": 18.2
  },
  {
    "ID": "Z01012200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.54,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 126.2,
    "out:Total Energy Use Post PV": 120.92,
    "out:Primary Energy": 101.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.92,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 54.14,
    "out:Total CO2 (tons)": 149.8,
    "out:Klimatpaverkan": -17.22,
    "out:Initial Cost/MSEK": 5.128,
    "out:Running cost/(Apt SEK y)": 19975,
    "out:Running Cost over RSP/MSEK": 7.499,
    "out:LCP/MSEK": -1.326,
    "out:ROI% old": 10.59,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146651,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191074,
    "out:DH kr savings": 146651,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203472,
    "out:% savings (space heating)": 37.43,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.54,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.47,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 123.2,
    "out:Total Energy Use Post PV": 117.92,
    "out:Primary Energy": 98.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.92,
    "out:CO2 Operational/m2": 21.14,
    "out:Total CO2/m2": 53.06,
    "out:Total CO2 (tons)": 146.81,
    "out:Klimatpaverkan": -20.21,
    "out:Initial Cost/MSEK": 5.197,
    "out:Running cost/(Apt SEK y)": 19452,
    "out:Running Cost over RSP/MSEK": 7.303,
    "out:LCP/MSEK": -1.199,
    "out:ROI% old": 10.99,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 512,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154952,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199375,
    "out:DH kr savings": 154952,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 194978,
    "out:% savings (space heating)": 40.04,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.47,
    "out:Etvv": 20,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.54,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 121.2,
    "out:Total Energy Use Post PV": 115.92,
    "out:Primary Energy": 99.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.57,
    "out:CO2 Operational/m2": 20.42,
    "out:Total CO2/m2": 52.99,
    "out:Total CO2 (tons)": 146.62,
    "out:Klimatpaverkan": -20.4,
    "out:Initial Cost/MSEK": 5.347,
    "out:Running cost/(Apt SEK y)": 19104,
    "out:Running Cost over RSP/MSEK": 7.173,
    "out:LCP/MSEK": -1.218,
    "out:ROI% old": 11.03,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 54.3,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 160486,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 204909,
    "out:DH kr savings": 160486,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203472,
    "out:% savings (space heating)": 37.43,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.54,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.47,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 18.2,
    "out:Electricity (inc PV)": 12.92,
    "out:Total Energy Use Pre PV": 118.2,
    "out:Total Energy Use Post PV": 112.92,
    "out:Primary Energy": 96.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.57,
    "out:CO2 Operational/m2": 19.34,
    "out:Total CO2/m2": 51.91,
    "out:Total CO2 (tons)": 143.64,
    "out:Klimatpaverkan": -23.38,
    "out:Initial Cost/MSEK": 5.416,
    "out:Running cost/(Apt SEK y)": 18582,
    "out:Running Cost over RSP/MSEK": 6.977,
    "out:LCP/MSEK": -1.091,
    "out:ROI% old": 11.41,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 58.4,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 913.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 554,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 10121.14,
    "out:PV (% sold (El))": 40.91,
    "out:PV (kWh self-consumed)": 14620.58,
    "out:PV (ratio sold/self-consumed)": 0.69,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 168787,
    "out:EL kWh savings": 14621,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 213210,
    "out:DH kr savings": 168787,
    "out:El kr savings": 29241,
    "out:El kr sold": 15182,
    "out:El kr saved": 29241,
    "out:El kr return": 44423,
    "out:% solar/total": 29,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 194978,
    "out:% savings (space heating)": 40.04,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.47,
    "out:Etvv": 17,
    "out:Ef": 12.92
  },
  {
    "ID": "Z01012201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.54,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 124.26,
    "out:Total Energy Use Post PV": 124.26,
    "out:Primary Energy": 115.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.83,
    "out:CO2 Operational/m2": 38.74,
    "out:Total CO2/m2": 64.58,
    "out:Total CO2 (tons)": 178.68,
    "out:Klimatpaverkan": 11.66,
    "out:Initial Cost/MSEK": 5.185,
    "out:Running cost/(Apt SEK y)": 20088,
    "out:Running Cost over RSP/MSEK": 7.529,
    "out:LCP/MSEK": -1.412,
    "out:ROI% old": 10.4,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 174321,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 129741,
    "out:DH kr savings": 174321,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203472,
    "out:% savings (space heating)": 37.43,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.54,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.47,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 121.26,
    "out:Total Energy Use Post PV": 121.26,
    "out:Primary Energy": 113.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.83,
    "out:CO2 Operational/m2": 37.66,
    "out:Total CO2/m2": 63.5,
    "out:Total CO2 (tons)": 175.69,
    "out:Klimatpaverkan": 8.67,
    "out:Initial Cost/MSEK": 5.255,
    "out:Running cost/(Apt SEK y)": 19566,
    "out:Running Cost over RSP/MSEK": 7.333,
    "out:LCP/MSEK": -1.285,
    "out:ROI% old": 10.79,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182622,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138042,
    "out:DH kr savings": 182622,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 194978,
    "out:% savings (space heating)": 40.04,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.47,
    "out:Etvv": 6,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.54,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 117.26,
    "out:Total Energy Use Post PV": 117.26,
    "out:Primary Energy": 113.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.48,
    "out:CO2 Operational/m2": 36.22,
    "out:Total CO2/m2": 62.71,
    "out:Total CO2 (tons)": 173.51,
    "out:Klimatpaverkan": 6.49,
    "out:Initial Cost/MSEK": 5.404,
    "out:Running cost/(Apt SEK y)": 18869,
    "out:Running Cost over RSP/MSEK": 7.071,
    "out:LCP/MSEK": -1.173,
    "out:ROI% old": 11.18,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 53,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 540,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193690,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149110,
    "out:DH kr savings": 193690,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203472,
    "out:% savings (space heating)": 37.43,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.54,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.47,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 114.26,
    "out:Total Energy Use Post PV": 114.26,
    "out:Primary Energy": 110.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.48,
    "out:CO2 Operational/m2": 35.14,
    "out:Total CO2/m2": 61.63,
    "out:Total CO2 (tons)": 170.53,
    "out:Klimatpaverkan": 3.51,
    "out:Initial Cost/MSEK": 5.473,
    "out:Running cost/(Apt SEK y)": 18347,
    "out:Running Cost over RSP/MSEK": 6.875,
    "out:LCP/MSEK": -1.046,
    "out:ROI% old": 11.55,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 57,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 565,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 201991,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157411,
    "out:DH kr savings": 201991,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 194978,
    "out:% savings (space heating)": 40.04,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.47,
    "out:Etvv": 2.25,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.54,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 124.26,
    "out:Total Energy Use Post PV": 117.64,
    "out:Primary Energy": 103.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.71,
    "out:CO2 Operational/m2": 26.32,
    "out:Total CO2/m2": 62.03,
    "out:Total CO2 (tons)": 171.64,
    "out:Klimatpaverkan": 4.62,
    "out:Initial Cost/MSEK": 5.646,
    "out:Running cost/(Apt SEK y)": 19124,
    "out:Running Cost over RSP/MSEK": 7.176,
    "out:LCP/MSEK": -1.52,
    "out:ROI% old": 10.44,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 528,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 174321,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176004,
    "out:DH kr savings": 174321,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203472,
    "out:% savings (space heating)": 37.43,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.54,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.47,
    "out:DHW (DH)": 24.3,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 121.26,
    "out:Total Energy Use Post PV": 114.64,
    "out:Primary Energy": 101.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.71,
    "out:CO2 Operational/m2": 25.24,
    "out:Total CO2/m2": 60.95,
    "out:Total CO2 (tons)": 168.65,
    "out:Klimatpaverkan": 1.63,
    "out:Initial Cost/MSEK": 5.715,
    "out:Running cost/(Apt SEK y)": 18602,
    "out:Running Cost over RSP/MSEK": 6.98,
    "out:LCP/MSEK": -1.393,
    "out:ROI% old": 10.8,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182622,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184305,
    "out:DH kr savings": 182622,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 194978,
    "out:% savings (space heating)": 40.04,
    "out:Total_Water Heating (DH)": 67251,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.47,
    "out:Etvv": 6,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.54,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 117.26,
    "out:Total Energy Use Post PV": 110.64,
    "out:Primary Energy": 101.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 36.37,
    "out:CO2 Operational/m2": 23.8,
    "out:Total CO2/m2": 60.16,
    "out:Total CO2 (tons)": 166.47,
    "out:Klimatpaverkan": -0.55,
    "out:Initial Cost/MSEK": 5.864,
    "out:Running cost/(Apt SEK y)": 17905,
    "out:Running Cost over RSP/MSEK": 6.719,
    "out:LCP/MSEK": -1.282,
    "out:ROI% old": 11.16,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 61.3,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 587,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193690,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195373,
    "out:DH kr savings": 193690,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203472,
    "out:% savings (space heating)": 37.43,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.54,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.47,
    "out:DHW (DH)": 17.81,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 114.26,
    "out:Total Energy Use Post PV": 107.64,
    "out:Primary Energy": 98.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.37,
    "out:CO2 Operational/m2": 22.72,
    "out:Total CO2/m2": 59.08,
    "out:Total CO2 (tons)": 163.49,
    "out:Klimatpaverkan": -3.53,
    "out:Initial Cost/MSEK": 5.934,
    "out:Running cost/(Apt SEK y)": 17383,
    "out:Running Cost over RSP/MSEK": 6.523,
    "out:LCP/MSEK": -1.155,
    "out:ROI% old": 11.51,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 65.7,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 612,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 201991,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 203674,
    "out:DH kr savings": 201991,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 194978,
    "out:% savings (space heating)": 40.04,
    "out:Total_Water Heating (DH)": 49279,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.47,
    "out:Etvv": 2.25,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.54,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 115.26,
    "out:Total Energy Use Post PV": 115.26,
    "out:Primary Energy": 112.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.83,
    "out:CO2 Operational/m2": 35.5,
    "out:Total CO2/m2": 61.34,
    "out:Total CO2 (tons)": 169.72,
    "out:Klimatpaverkan": 2.7,
    "out:Initial Cost/MSEK": 5.312,
    "out:Running cost/(Apt SEK y)": 18521,
    "out:Running Cost over RSP/MSEK": 6.94,
    "out:LCP/MSEK": -0.951,
    "out:ROI% old": 11.73,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154644,
    "out:DH kr savings": 199224,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203472,
    "out:% savings (space heating)": 37.43,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.54,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.47,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 112.26,
    "out:Total Energy Use Post PV": 112.26,
    "out:Primary Energy": 109.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.83,
    "out:CO2 Operational/m2": 34.42,
    "out:Total CO2/m2": 60.26,
    "out:Total CO2 (tons)": 166.73,
    "out:Klimatpaverkan": -0.29,
    "out:Initial Cost/MSEK": 5.381,
    "out:Running cost/(Apt SEK y)": 17999,
    "out:Running Cost over RSP/MSEK": 6.744,
    "out:LCP/MSEK": -0.824,
    "out:ROI% old": 12.1,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 207525,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162945,
    "out:DH kr savings": 207525,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 194978,
    "out:% savings (space heating)": 40.04,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.47,
    "out:Etvv": 1,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.54,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 110.26,
    "out:Total Energy Use Post PV": 110.26,
    "out:Primary Energy": 109.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.48,
    "out:CO2 Operational/m2": 33.7,
    "out:Total CO2/m2": 60.19,
    "out:Total CO2 (tons)": 166.54,
    "out:Klimatpaverkan": -0.48,
    "out:Initial Cost/MSEK": 5.531,
    "out:Running cost/(Apt SEK y)": 17651,
    "out:Running Cost over RSP/MSEK": 6.614,
    "out:LCP/MSEK": -0.842,
    "out:ROI% old": 12.11,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 62.7,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 599,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 213059,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168479,
    "out:DH kr savings": 213059,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203472,
    "out:% savings (space heating)": 37.43,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.54,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.47,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 26.26,
    "out:Total Energy Use Pre PV": 107.26,
    "out:Total Energy Use Post PV": 107.26,
    "out:Primary Energy": 107.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.48,
    "out:CO2 Operational/m2": 32.62,
    "out:Total CO2/m2": 59.11,
    "out:Total CO2 (tons)": 163.55,
    "out:Klimatpaverkan": -3.47,
    "out:Initial Cost/MSEK": 5.6,
    "out:Running cost/(Apt SEK y)": 17128,
    "out:Running Cost over RSP/MSEK": 6.418,
    "out:LCP/MSEK": -0.716,
    "out:ROI% old": 12.46,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 67.3,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1837.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 624,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 221360,
    "out:EL kWh savings": -22290,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176780,
    "out:DH kr savings": 221360,
    "out:El kr savings": -44580,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 194978,
    "out:% savings (space heating)": 40.04,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 68.47,
    "out:Etvv": 0,
    "out:Ef": 26.26
  },
  {
    "ID": "Z01012201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.54,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 115.26,
    "out:Total Energy Use Post PV": 108.64,
    "out:Primary Energy": 100.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.71,
    "out:CO2 Operational/m2": 23.08,
    "out:Total CO2/m2": 58.79,
    "out:Total CO2 (tons)": 162.68,
    "out:Klimatpaverkan": -4.34,
    "out:Initial Cost/MSEK": 5.773,
    "out:Running cost/(Apt SEK y)": 17557,
    "out:Running Cost over RSP/MSEK": 6.588,
    "out:LCP/MSEK": -1.059,
    "out:ROI% old": 11.67,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 64.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 603,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 200907,
    "out:DH kr savings": 199224,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203472,
    "out:% savings (space heating)": 37.43,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 73.54,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.47,
    "out:DHW (DH)": 15.64,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 112.26,
    "out:Total Energy Use Post PV": 105.64,
    "out:Primary Energy": 97.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.71,
    "out:CO2 Operational/m2": 22,
    "out:Total CO2/m2": 57.71,
    "out:Total CO2 (tons)": 159.69,
    "out:Klimatpaverkan": -7.33,
    "out:Initial Cost/MSEK": 5.842,
    "out:Running cost/(Apt SEK y)": 17035,
    "out:Running Cost over RSP/MSEK": 6.392,
    "out:LCP/MSEK": -0.932,
    "out:ROI% old": 12.01,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 68.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 207525,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209208,
    "out:DH kr savings": 207525,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 194978,
    "out:% savings (space heating)": 40.04,
    "out:Total_Water Heating (DH)": 43289,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 70.47,
    "out:Etvv": 1,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.54,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 110.26,
    "out:Total Energy Use Post PV": 103.64,
    "out:Primary Energy": 97.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.37,
    "out:CO2 Operational/m2": 21.28,
    "out:Total CO2/m2": 57.64,
    "out:Total CO2 (tons)": 159.5,
    "out:Klimatpaverkan": -7.52,
    "out:Initial Cost/MSEK": 5.991,
    "out:Running cost/(Apt SEK y)": 16687,
    "out:Running Cost over RSP/MSEK": 6.261,
    "out:LCP/MSEK": -0.951,
    "out:ROI% old": 12.02,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 72.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 645,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213059,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214742,
    "out:DH kr savings": 213059,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 203472,
    "out:% savings (space heating)": 37.43,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.54,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z01012201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.47,
    "out:DHW (DH)": 10.45,
    "out:Property Electricity": 26.26,
    "out:Electricity (inc PV)": 19.64,
    "out:Total Energy Use Pre PV": 107.26,
    "out:Total Energy Use Post PV": 100.64,
    "out:Primary Energy": 95.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.37,
    "out:CO2 Operational/m2": 20.2,
    "out:Total CO2/m2": 56.56,
    "out:Total CO2 (tons)": 156.51,
    "out:Klimatpaverkan": -10.51,
    "out:Initial Cost/MSEK": 6.06,
    "out:Running cost/(Apt SEK y)": 16165,
    "out:Running Cost over RSP/MSEK": 6.065,
    "out:LCP/MSEK": -0.824,
    "out:ROI% old": 12.34,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 77.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1388.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 670,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 5.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 6440.41,
    "out:PV (% sold (El))": 26.03,
    "out:PV (kWh self-consumed)": 18301.32,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221360,
    "out:EL kWh savings": -3989,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 223043,
    "out:DH kr savings": 221360,
    "out:El kr savings": -7977,
    "out:El kr sold": 9661,
    "out:El kr saved": 36603,
    "out:El kr return": 46263,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 194978,
    "out:% savings (space heating)": 40.04,
    "out:Total_Water Heating (DH)": 28912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 68.47,
    "out:Etvv": 0,
    "out:Ef": 19.64
  },
  {
    "ID": "Z00000000000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.12,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 123.8,
    "out:Total Energy Use Post PV": 123.8,
    "out:Primary Energy": 109.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.75,
    "out:CO2 Operational/m2": 39.37,
    "out:Total CO2/m2": 75.12,
    "out:Total CO2 (tons)": 207.85,
    "out:Klimatpaverkan": 40.83,
    "out:Initial Cost/MSEK": 5.505,
    "out:Running cost/(Apt SEK y)": 20212,
    "out:Running Cost over RSP/MSEK": 7.577,
    "out:LCP/MSEK": -1.78,
    "out:ROI% old": 9.67,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 44.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140569,
    "out:DH kr savings": 166020,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 160817,
    "out:% savings (space heating)": 50.55,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.31,
    "out:Etvv": 25,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000000000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.61,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 120.8,
    "out:Total Energy Use Post PV": 120.8,
    "out:Primary Energy": 106.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.75,
    "out:CO2 Operational/m2": 38.29,
    "out:Total CO2/m2": 74.04,
    "out:Total CO2 (tons)": 204.86,
    "out:Klimatpaverkan": 37.84,
    "out:Initial Cost/MSEK": 5.574,
    "out:Running cost/(Apt SEK y)": 19689,
    "out:Running Cost over RSP/MSEK": 7.381,
    "out:LCP/MSEK": -1.653,
    "out:ROI% old": 10.05,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 47.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 501,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 174321,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148870,
    "out:DH kr savings": 174321,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 151094,
    "out:% savings (space heating)": 53.54,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.79,
    "out:Etvv": 25,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000000000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.12,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 117.8,
    "out:Total Energy Use Post PV": 117.8,
    "out:Primary Energy": 106.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 36.4,
    "out:CO2 Operational/m2": 37.21,
    "out:Total CO2/m2": 73.61,
    "out:Total CO2 (tons)": 203.68,
    "out:Klimatpaverkan": 36.66,
    "out:Initial Cost/MSEK": 5.723,
    "out:Running cost/(Apt SEK y)": 19167,
    "out:Running Cost over RSP/MSEK": 7.185,
    "out:LCP/MSEK": -1.606,
    "out:ROI% old": 10.28,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182622,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157171,
    "out:DH kr savings": 182622,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 160817,
    "out:% savings (space heating)": 50.55,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.31,
    "out:Etvv": 21.25,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000000000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.61,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 113.8,
    "out:Total Energy Use Post PV": 113.8,
    "out:Primary Energy": 103.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 36.4,
    "out:CO2 Operational/m2": 35.77,
    "out:Total CO2/m2": 72.17,
    "out:Total CO2 (tons)": 199.7,
    "out:Klimatpaverkan": 32.68,
    "out:Initial Cost/MSEK": 5.792,
    "out:Running cost/(Apt SEK y)": 18471,
    "out:Running Cost over RSP/MSEK": 6.923,
    "out:LCP/MSEK": -1.414,
    "out:ROI% old": 10.8,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 57,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 559,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193690,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168239,
    "out:DH kr savings": 193690,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 151094,
    "out:% savings (space heating)": 53.54,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.79,
    "out:Etvv": 21.25,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000000010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.12,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 123.8,
    "out:Total Energy Use Post PV": 117.71,
    "out:Primary Energy": 98.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.63,
    "out:CO2 Operational/m2": 24.31,
    "out:Total CO2/m2": 69.94,
    "out:Total CO2 (tons)": 193.53,
    "out:Klimatpaverkan": 26.51,
    "out:Initial Cost/MSEK": 5.965,
    "out:Running cost/(Apt SEK y)": 19263,
    "out:Running Cost over RSP/MSEK": 7.23,
    "out:LCP/MSEK": -1.894,
    "out:ROI% old": 9.75,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 51.7,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 9,
    "out:Return/kSEK/y": 521,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166020,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186101,
    "out:DH kr savings": 166020,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 160817,
    "out:% savings (space heating)": 50.55,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.31,
    "out:Etvv": 25,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000000010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.61,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 120.8,
    "out:Total Energy Use Post PV": 114.71,
    "out:Primary Energy": 95.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.63,
    "out:CO2 Operational/m2": 23.23,
    "out:Total CO2/m2": 68.86,
    "out:Total CO2 (tons)": 190.55,
    "out:Klimatpaverkan": 23.53,
    "out:Initial Cost/MSEK": 6.034,
    "out:Running cost/(Apt SEK y)": 18741,
    "out:Running Cost over RSP/MSEK": 7.034,
    "out:LCP/MSEK": -1.767,
    "out:ROI% old": 10.1,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 9,
    "out:Return/kSEK/y": 547,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 174321,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194402,
    "out:DH kr savings": 174321,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 151094,
    "out:% savings (space heating)": 53.54,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.79,
    "out:Etvv": 25,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000000010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.12,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 117.8,
    "out:Total Energy Use Post PV": 111.71,
    "out:Primary Energy": 95.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.28,
    "out:CO2 Operational/m2": 22.15,
    "out:Total CO2/m2": 68.44,
    "out:Total CO2 (tons)": 189.36,
    "out:Klimatpaverkan": 22.34,
    "out:Initial Cost/MSEK": 6.184,
    "out:Running cost/(Apt SEK y)": 18219,
    "out:Running Cost over RSP/MSEK": 6.838,
    "out:LCP/MSEK": -1.72,
    "out:ROI% old": 10.31,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 9,
    "out:Return/kSEK/y": 572,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182622,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202703,
    "out:DH kr savings": 182622,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 160817,
    "out:% savings (space heating)": 50.55,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.31,
    "out:Etvv": 21.25,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000000010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.61,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 113.8,
    "out:Total Energy Use Post PV": 107.71,
    "out:Primary Energy": 92.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.28,
    "out:CO2 Operational/m2": 20.71,
    "out:Total CO2/m2": 67,
    "out:Total CO2 (tons)": 185.38,
    "out:Klimatpaverkan": 18.36,
    "out:Initial Cost/MSEK": 6.253,
    "out:Running cost/(Apt SEK y)": 17522,
    "out:Running Cost over RSP/MSEK": 6.577,
    "out:LCP/MSEK": -1.528,
    "out:ROI% old": 10.8,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 65.7,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 10,
    "out:Return/kSEK/y": 605,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193690,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 213771,
    "out:DH kr savings": 193690,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 151094,
    "out:% savings (space heating)": 53.54,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.79,
    "out:Etvv": 21.25,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000000100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.12,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 115.8,
    "out:Total Energy Use Post PV": 115.8,
    "out:Primary Energy": 105.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.75,
    "out:CO2 Operational/m2": 36.49,
    "out:Total CO2/m2": 72.24,
    "out:Total CO2 (tons)": 199.88,
    "out:Klimatpaverkan": 32.86,
    "out:Initial Cost/MSEK": 5.631,
    "out:Running cost/(Apt SEK y)": 18819,
    "out:Running Cost over RSP/MSEK": 7.054,
    "out:LCP/MSEK": -1.383,
    "out:ROI% old": 10.78,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 54.3,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 543,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 188156,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162705,
    "out:DH kr savings": 188156,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 160817,
    "out:% savings (space heating)": 50.55,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.31,
    "out:Etvv": 20,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000000100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.61,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 111.8,
    "out:Total Energy Use Post PV": 111.8,
    "out:Primary Energy": 102.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.75,
    "out:CO2 Operational/m2": 35.05,
    "out:Total CO2/m2": 70.8,
    "out:Total CO2 (tons)": 195.9,
    "out:Klimatpaverkan": 28.88,
    "out:Initial Cost/MSEK": 5.701,
    "out:Running cost/(Apt SEK y)": 18123,
    "out:Running Cost over RSP/MSEK": 6.793,
    "out:LCP/MSEK": -1.191,
    "out:ROI% old": 11.3,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 576,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173773,
    "out:DH kr savings": 199224,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 151094,
    "out:% savings (space heating)": 53.54,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.79,
    "out:Etvv": 20,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000000100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.12,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 110.8,
    "out:Total Energy Use Post PV": 110.8,
    "out:Primary Energy": 103.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 36.4,
    "out:CO2 Operational/m2": 34.69,
    "out:Total CO2/m2": 71.09,
    "out:Total CO2 (tons)": 196.71,
    "out:Klimatpaverkan": 29.69,
    "out:Initial Cost/MSEK": 5.85,
    "out:Running cost/(Apt SEK y)": 17948,
    "out:Running Cost over RSP/MSEK": 6.727,
    "out:LCP/MSEK": -1.275,
    "out:ROI% old": 11.17,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 61.3,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 585,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 201991,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176540,
    "out:DH kr savings": 201991,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 160817,
    "out:% savings (space heating)": 50.55,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.31,
    "out:Etvv": 17,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000000100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.61,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 106.8,
    "out:Total Energy Use Post PV": 106.8,
    "out:Primary Energy": 100.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.4,
    "out:CO2 Operational/m2": 33.25,
    "out:Total CO2/m2": 69.65,
    "out:Total CO2 (tons)": 192.72,
    "out:Klimatpaverkan": 25.7,
    "out:Initial Cost/MSEK": 5.919,
    "out:Running cost/(Apt SEK y)": 17252,
    "out:Running Cost over RSP/MSEK": 6.466,
    "out:LCP/MSEK": -1.083,
    "out:ROI% old": 11.67,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 67.3,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 618,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 213059,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 187608,
    "out:DH kr savings": 213059,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 151094,
    "out:% savings (space heating)": 53.54,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.79,
    "out:Etvv": 17,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000000110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.12,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 115.8,
    "out:Total Energy Use Post PV": 109.71,
    "out:Primary Energy": 94.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.63,
    "out:CO2 Operational/m2": 21.43,
    "out:Total CO2/m2": 67.06,
    "out:Total CO2 (tons)": 185.57,
    "out:Klimatpaverkan": 18.55,
    "out:Initial Cost/MSEK": 6.092,
    "out:Running cost/(Apt SEK y)": 17870,
    "out:Running Cost over RSP/MSEK": 6.707,
    "out:LCP/MSEK": -1.497,
    "out:ROI% old": 10.77,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 62.7,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 10,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 188156,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 208237,
    "out:DH kr savings": 188156,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 160817,
    "out:% savings (space heating)": 50.55,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.31,
    "out:Etvv": 20,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000000110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.61,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 111.8,
    "out:Total Energy Use Post PV": 105.71,
    "out:Primary Energy": 91.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.63,
    "out:CO2 Operational/m2": 19.99,
    "out:Total CO2/m2": 65.62,
    "out:Total CO2 (tons)": 181.58,
    "out:Klimatpaverkan": 14.56,
    "out:Initial Cost/MSEK": 6.161,
    "out:Running cost/(Apt SEK y)": 17174,
    "out:Running Cost over RSP/MSEK": 6.446,
    "out:LCP/MSEK": -1.305,
    "out:ROI% old": 11.26,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 68.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 10,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 219305,
    "out:DH kr savings": 199224,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 151094,
    "out:% savings (space heating)": 53.54,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.79,
    "out:Etvv": 20,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000000110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 58.12,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 110.8,
    "out:Total Energy Use Post PV": 104.71,
    "out:Primary Energy": 92.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.28,
    "out:CO2 Operational/m2": 19.63,
    "out:Total CO2/m2": 65.92,
    "out:Total CO2 (tons)": 182.39,
    "out:Klimatpaverkan": 15.37,
    "out:Initial Cost/MSEK": 6.31,
    "out:Running cost/(Apt SEK y)": 17000,
    "out:Running Cost over RSP/MSEK": 6.381,
    "out:LCP/MSEK": -1.389,
    "out:ROI% old": 11.14,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 70.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 10,
    "out:Return/kSEK/y": 630,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 201991,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 222072,
    "out:DH kr savings": 201991,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 160817,
    "out:% savings (space heating)": 50.55,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 83.31,
    "out:Etvv": 17,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000000110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 54.61,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 106.8,
    "out:Total Energy Use Post PV": 100.71,
    "out:Primary Energy": 89.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.28,
    "out:CO2 Operational/m2": 18.19,
    "out:Total CO2/m2": 64.48,
    "out:Total CO2 (tons)": 178.41,
    "out:Klimatpaverkan": 11.39,
    "out:Initial Cost/MSEK": 6.38,
    "out:Running cost/(Apt SEK y)": 16304,
    "out:Running Cost over RSP/MSEK": 6.119,
    "out:LCP/MSEK": -1.197,
    "out:ROI% old": 11.61,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 77.2,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 10,
    "out:Return/kSEK/y": 664,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213059,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233140,
    "out:DH kr savings": 213059,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 151094,
    "out:% savings (space heating)": 53.54,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 79.79,
    "out:Etvv": 17,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000100000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 49.06,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 114.8,
    "out:Total Energy Use Post PV": 114.8,
    "out:Primary Energy": 101.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 38.06,
    "out:CO2 Operational/m2": 36.13,
    "out:Total CO2/m2": 74.19,
    "out:Total CO2 (tons)": 205.29,
    "out:Klimatpaverkan": 38.27,
    "out:Initial Cost/MSEK": 5.951,
    "out:Running cost/(Apt SEK y)": 18645,
    "out:Running Cost over RSP/MSEK": 6.989,
    "out:LCP/MSEK": -1.638,
    "out:ROI% old": 10.35,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190923,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165472,
    "out:DH kr savings": 190923,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 135751,
    "out:% savings (space heating)": 58.25,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.25,
    "out:Etvv": 25,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000100000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.96,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 111.8,
    "out:Total Energy Use Post PV": 111.8,
    "out:Primary Energy": 98.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.06,
    "out:CO2 Operational/m2": 35.05,
    "out:Total CO2/m2": 73.11,
    "out:Total CO2 (tons)": 202.3,
    "out:Klimatpaverkan": 35.28,
    "out:Initial Cost/MSEK": 6.021,
    "out:Running cost/(Apt SEK y)": 18123,
    "out:Running Cost over RSP/MSEK": 6.793,
    "out:LCP/MSEK": -1.511,
    "out:ROI% old": 10.7,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 576,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173773,
    "out:DH kr savings": 199224,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 127167,
    "out:% savings (space heating)": 60.89,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.15,
    "out:Etvv": 25,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000100000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 49.06,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 108.8,
    "out:Total Energy Use Post PV": 108.8,
    "out:Primary Energy": 98.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.72,
    "out:CO2 Operational/m2": 33.97,
    "out:Total CO2/m2": 72.68,
    "out:Total CO2 (tons)": 201.12,
    "out:Klimatpaverkan": 34.1,
    "out:Initial Cost/MSEK": 6.17,
    "out:Running cost/(Apt SEK y)": 17600,
    "out:Running Cost over RSP/MSEK": 6.596,
    "out:LCP/MSEK": -1.465,
    "out:ROI% old": 10.89,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 64.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 601,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 207525,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182074,
    "out:DH kr savings": 207525,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 135751,
    "out:% savings (space heating)": 58.25,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.25,
    "out:Etvv": 21.25,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000100000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.96,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 105.8,
    "out:Total Energy Use Post PV": 105.8,
    "out:Primary Energy": 96.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.72,
    "out:CO2 Operational/m2": 32.89,
    "out:Total CO2/m2": 71.6,
    "out:Total CO2 (tons)": 198.13,
    "out:Klimatpaverkan": 31.11,
    "out:Initial Cost/MSEK": 6.239,
    "out:Running cost/(Apt SEK y)": 17078,
    "out:Running Cost over RSP/MSEK": 6.4,
    "out:LCP/MSEK": -1.338,
    "out:ROI% old": 11.22,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 68.9,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 626,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 215826,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190375,
    "out:DH kr savings": 215826,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 127167,
    "out:% savings (space heating)": 60.89,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.15,
    "out:Etvv": 21.25,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000100010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 49.06,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 114.8,
    "out:Total Energy Use Post PV": 108.71,
    "out:Primary Energy": 90.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.95,
    "out:CO2 Operational/m2": 21.07,
    "out:Total CO2/m2": 69.02,
    "out:Total CO2 (tons)": 190.97,
    "out:Klimatpaverkan": 23.95,
    "out:Initial Cost/MSEK": 6.412,
    "out:Running cost/(Apt SEK y)": 17696,
    "out:Running Cost over RSP/MSEK": 6.642,
    "out:LCP/MSEK": -1.752,
    "out:ROI% old": 10.38,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 64.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 9,
    "out:Return/kSEK/y": 597,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190923,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211004,
    "out:DH kr savings": 190923,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 135751,
    "out:% savings (space heating)": 58.25,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.25,
    "out:Etvv": 25,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000100010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.96,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 111.8,
    "out:Total Energy Use Post PV": 105.71,
    "out:Primary Energy": 87.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.95,
    "out:CO2 Operational/m2": 19.99,
    "out:Total CO2/m2": 67.94,
    "out:Total CO2 (tons)": 187.99,
    "out:Klimatpaverkan": 20.97,
    "out:Initial Cost/MSEK": 6.481,
    "out:Running cost/(Apt SEK y)": 17174,
    "out:Running Cost over RSP/MSEK": 6.446,
    "out:LCP/MSEK": -1.625,
    "out:ROI% old": 10.7,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 68.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 10,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 219305,
    "out:DH kr savings": 199224,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 127167,
    "out:% savings (space heating)": 60.89,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.15,
    "out:Etvv": 25,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000100010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 49.06,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 108.8,
    "out:Total Energy Use Post PV": 102.71,
    "out:Primary Energy": 87.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.6,
    "out:CO2 Operational/m2": 18.91,
    "out:Total CO2/m2": 67.51,
    "out:Total CO2 (tons)": 186.8,
    "out:Klimatpaverkan": 19.78,
    "out:Initial Cost/MSEK": 6.63,
    "out:Running cost/(Apt SEK y)": 16652,
    "out:Running Cost over RSP/MSEK": 6.25,
    "out:LCP/MSEK": -1.578,
    "out:ROI% old": 10.89,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 73.8,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 10,
    "out:Return/kSEK/y": 647,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 207525,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227606,
    "out:DH kr savings": 207525,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 135751,
    "out:% savings (space heating)": 58.25,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.25,
    "out:Etvv": 21.25,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000100010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.96,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 105.8,
    "out:Total Energy Use Post PV": 99.71,
    "out:Primary Energy": 85.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.6,
    "out:CO2 Operational/m2": 17.83,
    "out:Total CO2/m2": 66.43,
    "out:Total CO2 (tons)": 183.81,
    "out:Klimatpaverkan": 16.79,
    "out:Initial Cost/MSEK": 6.7,
    "out:Running cost/(Apt SEK y)": 16129,
    "out:Running Cost over RSP/MSEK": 6.054,
    "out:LCP/MSEK": -1.451,
    "out:ROI% old": 11.19,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 10,
    "out:Return/kSEK/y": 672,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 215826,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 235907,
    "out:DH kr savings": 215826,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 127167,
    "out:% savings (space heating)": 60.89,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.15,
    "out:Etvv": 21.25,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000100100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 49.06,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 106.8,
    "out:Total Energy Use Post PV": 106.8,
    "out:Primary Energy": 97.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.06,
    "out:CO2 Operational/m2": 33.25,
    "out:Total CO2/m2": 71.31,
    "out:Total CO2 (tons)": 197.32,
    "out:Klimatpaverkan": 30.3,
    "out:Initial Cost/MSEK": 6.078,
    "out:Running cost/(Apt SEK y)": 17252,
    "out:Running Cost over RSP/MSEK": 6.466,
    "out:LCP/MSEK": -1.242,
    "out:ROI% old": 11.37,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 67.3,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 618,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 213059,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 187608,
    "out:DH kr savings": 213059,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 135751,
    "out:% savings (space heating)": 58.25,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.25,
    "out:Etvv": 20,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000100100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.96,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 103.8,
    "out:Total Energy Use Post PV": 103.8,
    "out:Primary Energy": 95.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.06,
    "out:CO2 Operational/m2": 32.17,
    "out:Total CO2/m2": 70.23,
    "out:Total CO2 (tons)": 194.33,
    "out:Klimatpaverkan": 27.31,
    "out:Initial Cost/MSEK": 6.147,
    "out:Running cost/(Apt SEK y)": 16730,
    "out:Running Cost over RSP/MSEK": 6.27,
    "out:LCP/MSEK": -1.115,
    "out:ROI% old": 11.69,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 72.1,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 643,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 221360,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195909,
    "out:DH kr savings": 221360,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 127167,
    "out:% savings (space heating)": 60.89,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.15,
    "out:Etvv": 20,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000100100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 49.06,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 101.8,
    "out:Total Energy Use Post PV": 101.8,
    "out:Primary Energy": 95.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.72,
    "out:CO2 Operational/m2": 31.45,
    "out:Total CO2/m2": 70.17,
    "out:Total CO2 (tons)": 194.15,
    "out:Klimatpaverkan": 27.13,
    "out:Initial Cost/MSEK": 6.297,
    "out:Running cost/(Apt SEK y)": 16382,
    "out:Running Cost over RSP/MSEK": 6.139,
    "out:LCP/MSEK": -1.134,
    "out:ROI% old": 11.71,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 75.5,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226894,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201443,
    "out:DH kr savings": 226894,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 135751,
    "out:% savings (space heating)": 58.25,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.25,
    "out:Etvv": 17,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000100100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.96,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 97.8,
    "out:Total Energy Use Post PV": 97.8,
    "out:Primary Energy": 93.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.72,
    "out:CO2 Operational/m2": 30.01,
    "out:Total CO2/m2": 68.73,
    "out:Total CO2 (tons)": 190.16,
    "out:Klimatpaverkan": 23.14,
    "out:Initial Cost/MSEK": 6.366,
    "out:Running cost/(Apt SEK y)": 15685,
    "out:Running Cost over RSP/MSEK": 5.877,
    "out:LCP/MSEK": -0.941,
    "out:ROI% old": 12.17,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 82.7,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 693,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 237962,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212511,
    "out:DH kr savings": 237962,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 127167,
    "out:% savings (space heating)": 60.89,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.15,
    "out:Etvv": 17,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000100110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 49.06,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 106.8,
    "out:Total Energy Use Post PV": 100.71,
    "out:Primary Energy": 87.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.95,
    "out:CO2 Operational/m2": 18.19,
    "out:Total CO2/m2": 66.14,
    "out:Total CO2 (tons)": 183.01,
    "out:Klimatpaverkan": 15.99,
    "out:Initial Cost/MSEK": 6.538,
    "out:Running cost/(Apt SEK y)": 16304,
    "out:Running Cost over RSP/MSEK": 6.119,
    "out:LCP/MSEK": -1.356,
    "out:ROI% old": 11.32,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 77.2,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 10,
    "out:Return/kSEK/y": 664,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213059,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 233140,
    "out:DH kr savings": 213059,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 135751,
    "out:% savings (space heating)": 58.25,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.25,
    "out:Etvv": 20,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000100110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.96,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 103.8,
    "out:Total Energy Use Post PV": 97.71,
    "out:Primary Energy": 84.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.95,
    "out:CO2 Operational/m2": 17.11,
    "out:Total CO2/m2": 65.06,
    "out:Total CO2 (tons)": 180.02,
    "out:Klimatpaverkan": 13,
    "out:Initial Cost/MSEK": 6.608,
    "out:Running cost/(Apt SEK y)": 15781,
    "out:Running Cost over RSP/MSEK": 5.923,
    "out:LCP/MSEK": -1.229,
    "out:ROI% old": 11.63,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 82.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 10,
    "out:Return/kSEK/y": 689,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221360,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 241441,
    "out:DH kr savings": 221360,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 127167,
    "out:% savings (space heating)": 60.89,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.15,
    "out:Etvv": 20,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000100110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 49.06,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 101.8,
    "out:Total Energy Use Post PV": 95.71,
    "out:Primary Energy": 84.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.6,
    "out:CO2 Operational/m2": 16.39,
    "out:Total CO2/m2": 64.99,
    "out:Total CO2 (tons)": 179.83,
    "out:Klimatpaverkan": 12.81,
    "out:Initial Cost/MSEK": 6.757,
    "out:Running cost/(Apt SEK y)": 15433,
    "out:Running Cost over RSP/MSEK": 5.792,
    "out:LCP/MSEK": -1.248,
    "out:ROI% old": 11.65,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 86.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 10,
    "out:Return/kSEK/y": 705,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 226894,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 246975,
    "out:DH kr savings": 226894,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 135751,
    "out:% savings (space heating)": 58.25,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 74.25,
    "out:Etvv": 17,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000100110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.96,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 97.8,
    "out:Total Energy Use Post PV": 91.71,
    "out:Primary Energy": 82.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.6,
    "out:CO2 Operational/m2": 14.95,
    "out:Total CO2/m2": 63.55,
    "out:Total CO2 (tons)": 175.85,
    "out:Klimatpaverkan": 8.83,
    "out:Initial Cost/MSEK": 6.826,
    "out:Running cost/(Apt SEK y)": 14737,
    "out:Running Cost over RSP/MSEK": 5.531,
    "out:LCP/MSEK": -1.055,
    "out:ROI% old": 12.08,
    "out:Payback discounted": 10,
    "out:Atemp": 2767,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 94.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 11,
    "out:Return/kSEK/y": 739,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 237962,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258043,
    "out:DH kr savings": 237962,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 127167,
    "out:% savings (space heating)": 60.89,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.15,
    "out:Etvv": 17,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000200000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.02,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 111.8,
    "out:Total Energy Use Post PV": 111.8,
    "out:Primary Energy": 98.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.82,
    "out:CO2 Operational/m2": 35.05,
    "out:Total CO2/m2": 78.87,
    "out:Total CO2 (tons)": 218.22,
    "out:Klimatpaverkan": 51.2,
    "out:Initial Cost/MSEK": 7.045,
    "out:Running cost/(Apt SEK y)": 18123,
    "out:Running Cost over RSP/MSEK": 6.793,
    "out:LCP/MSEK": -2.536,
    "out:ROI% old": 9.14,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 576,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 173773,
    "out:DH kr savings": 199224,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 127341,
    "out:% savings (space heating)": 60.84,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.21,
    "out:Etvv": 25,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000200000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 43.06,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 108.8,
    "out:Total Energy Use Post PV": 108.8,
    "out:Primary Energy": 96.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.82,
    "out:CO2 Operational/m2": 33.97,
    "out:Total CO2/m2": 77.79,
    "out:Total CO2 (tons)": 215.23,
    "out:Klimatpaverkan": 48.21,
    "out:Initial Cost/MSEK": 7.114,
    "out:Running cost/(Apt SEK y)": 17600,
    "out:Running Cost over RSP/MSEK": 6.596,
    "out:LCP/MSEK": -2.409,
    "out:ROI% old": 9.45,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 64.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 601,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 207525,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 182074,
    "out:DH kr savings": 207525,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 119139,
    "out:% savings (space heating)": 63.36,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 68.25,
    "out:Etvv": 25,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000200000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.02,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 105.8,
    "out:Total Energy Use Post PV": 105.8,
    "out:Primary Energy": 96.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.47,
    "out:CO2 Operational/m2": 32.89,
    "out:Total CO2/m2": 77.36,
    "out:Total CO2 (tons)": 214.05,
    "out:Klimatpaverkan": 47.03,
    "out:Initial Cost/MSEK": 7.264,
    "out:Running cost/(Apt SEK y)": 17078,
    "out:Running Cost over RSP/MSEK": 6.4,
    "out:LCP/MSEK": -2.362,
    "out:ROI% old": 9.64,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 68.9,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 626,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 215826,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 190375,
    "out:DH kr savings": 215826,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 127341,
    "out:% savings (space heating)": 60.84,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.21,
    "out:Etvv": 21.25,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000200000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 43.06,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 102.8,
    "out:Total Energy Use Post PV": 102.8,
    "out:Primary Energy": 93.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.47,
    "out:CO2 Operational/m2": 31.81,
    "out:Total CO2/m2": 76.28,
    "out:Total CO2 (tons)": 211.06,
    "out:Klimatpaverkan": 44.04,
    "out:Initial Cost/MSEK": 7.333,
    "out:Running cost/(Apt SEK y)": 16556,
    "out:Running Cost over RSP/MSEK": 6.204,
    "out:LCP/MSEK": -2.235,
    "out:ROI% old": 9.93,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 73.8,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 651,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 224127,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198676,
    "out:DH kr savings": 224127,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 119139,
    "out:% savings (space heating)": 63.36,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 68.25,
    "out:Etvv": 21.25,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000200010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.02,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 111.8,
    "out:Total Energy Use Post PV": 105.71,
    "out:Primary Energy": 87.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 53.7,
    "out:CO2 Operational/m2": 19.99,
    "out:Total CO2/m2": 73.69,
    "out:Total CO2 (tons)": 203.9,
    "out:Klimatpaverkan": 36.88,
    "out:Initial Cost/MSEK": 7.505,
    "out:Running cost/(Apt SEK y)": 17174,
    "out:Running Cost over RSP/MSEK": 6.446,
    "out:LCP/MSEK": -2.649,
    "out:ROI% old": 9.24,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 68.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 8,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 199224,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 219305,
    "out:DH kr savings": 199224,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 127341,
    "out:% savings (space heating)": 60.84,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.21,
    "out:Etvv": 25,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000200010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 43.06,
    "out:DHW (DH)": 43.3,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 108.8,
    "out:Total Energy Use Post PV": 102.71,
    "out:Primary Energy": 85.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 53.7,
    "out:CO2 Operational/m2": 18.91,
    "out:Total CO2/m2": 72.61,
    "out:Total CO2 (tons)": 200.91,
    "out:Klimatpaverkan": 33.89,
    "out:Initial Cost/MSEK": 7.575,
    "out:Running cost/(Apt SEK y)": 16652,
    "out:Running Cost over RSP/MSEK": 6.25,
    "out:LCP/MSEK": -2.523,
    "out:ROI% old": 9.53,
    "out:Payback discounted": 13,
    "out:Atemp": 2767,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 73.8,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 9,
    "out:Return/kSEK/y": 647,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.3,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 207525,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227606,
    "out:DH kr savings": 207525,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 119139,
    "out:% savings (space heating)": 63.36,
    "out:Total_Water Heating (DH)": 119811,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 68.25,
    "out:Etvv": 25,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000200010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.02,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 105.8,
    "out:Total Energy Use Post PV": 99.71,
    "out:Primary Energy": 85.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 54.35,
    "out:CO2 Operational/m2": 17.83,
    "out:Total CO2/m2": 72.18,
    "out:Total CO2 (tons)": 199.73,
    "out:Klimatpaverkan": 32.71,
    "out:Initial Cost/MSEK": 7.724,
    "out:Running cost/(Apt SEK y)": 16129,
    "out:Running Cost over RSP/MSEK": 6.054,
    "out:LCP/MSEK": -2.476,
    "out:ROI% old": 9.71,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 9,
    "out:Return/kSEK/y": 672,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 215826,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 235907,
    "out:DH kr savings": 215826,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 127341,
    "out:% savings (space heating)": 60.84,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.21,
    "out:Etvv": 21.25,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000200010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 43.06,
    "out:DHW (DH)": 36.8,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 102.8,
    "out:Total Energy Use Post PV": 96.71,
    "out:Primary Energy": 82.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 54.35,
    "out:CO2 Operational/m2": 16.75,
    "out:Total CO2/m2": 71.1,
    "out:Total CO2 (tons)": 196.74,
    "out:Klimatpaverkan": 29.72,
    "out:Initial Cost/MSEK": 7.793,
    "out:Running cost/(Apt SEK y)": 15607,
    "out:Running Cost over RSP/MSEK": 5.858,
    "out:LCP/MSEK": -2.349,
    "out:ROI% old": 9.98,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 84.5,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 9,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.805,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 224127,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 244208,
    "out:DH kr savings": 224127,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 119139,
    "out:% savings (space heating)": 63.36,
    "out:Total_Water Heating (DH)": 101839,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 68.25,
    "out:Etvv": 21.25,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000200100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.02,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 103.8,
    "out:Total Energy Use Post PV": 103.8,
    "out:Primary Energy": 95.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.82,
    "out:CO2 Operational/m2": 32.17,
    "out:Total CO2/m2": 75.99,
    "out:Total CO2 (tons)": 210.25,
    "out:Klimatpaverkan": 43.23,
    "out:Initial Cost/MSEK": 7.172,
    "out:Running cost/(Apt SEK y)": 16730,
    "out:Running Cost over RSP/MSEK": 6.27,
    "out:LCP/MSEK": -2.139,
    "out:ROI% old": 10.02,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 72.1,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 643,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 221360,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195909,
    "out:DH kr savings": 221360,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 127341,
    "out:% savings (space heating)": 60.84,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.21,
    "out:Etvv": 20,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000200100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 43.06,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 100.8,
    "out:Total Energy Use Post PV": 100.8,
    "out:Primary Energy": 92.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.82,
    "out:CO2 Operational/m2": 31.09,
    "out:Total CO2/m2": 74.91,
    "out:Total CO2 (tons)": 207.26,
    "out:Klimatpaverkan": 40.24,
    "out:Initial Cost/MSEK": 7.241,
    "out:Running cost/(Apt SEK y)": 16208,
    "out:Running Cost over RSP/MSEK": 6.074,
    "out:LCP/MSEK": -2.013,
    "out:ROI% old": 10.32,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 77.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 668,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 229661,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204210,
    "out:DH kr savings": 229661,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 119139,
    "out:% savings (space heating)": 63.36,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 68.25,
    "out:Etvv": 20,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000200100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.02,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 97.8,
    "out:Total Energy Use Post PV": 97.8,
    "out:Primary Energy": 93.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.47,
    "out:CO2 Operational/m2": 30.01,
    "out:Total CO2/m2": 74.48,
    "out:Total CO2 (tons)": 206.08,
    "out:Klimatpaverkan": 39.06,
    "out:Initial Cost/MSEK": 7.39,
    "out:Running cost/(Apt SEK y)": 15685,
    "out:Running Cost over RSP/MSEK": 5.877,
    "out:LCP/MSEK": -1.966,
    "out:ROI% old": 10.49,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 82.7,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 693,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 237962,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212511,
    "out:DH kr savings": 237962,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 127341,
    "out:% savings (space heating)": 60.84,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.21,
    "out:Etvv": 17,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000200100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 43.06,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 22.8,
    "out:Total Energy Use Pre PV": 95.8,
    "out:Total Energy Use Post PV": 95.8,
    "out:Primary Energy": 90.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.47,
    "out:CO2 Operational/m2": 29.29,
    "out:Total CO2/m2": 73.76,
    "out:Total CO2 (tons)": 204.09,
    "out:Klimatpaverkan": 37.07,
    "out:Initial Cost/MSEK": 7.459,
    "out:Running cost/(Apt SEK y)": 15337,
    "out:Running Cost over RSP/MSEK": 5.747,
    "out:LCP/MSEK": -1.904,
    "out:ROI% old": 10.64,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 86.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 1595.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 710,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 243496,
    "out:EL kWh savings": -12725,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 218045,
    "out:DH kr savings": 243496,
    "out:El kr savings": -25451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 119139,
    "out:% savings (space heating)": 63.36,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 68.25,
    "out:Etvv": 17,
    "out:Ef": 22.8
  },
  {
    "ID": "Z00000200110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.02,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 103.8,
    "out:Total Energy Use Post PV": 97.71,
    "out:Primary Energy": 84.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 53.7,
    "out:CO2 Operational/m2": 17.11,
    "out:Total CO2/m2": 70.81,
    "out:Total CO2 (tons)": 195.93,
    "out:Klimatpaverkan": 28.91,
    "out:Initial Cost/MSEK": 7.632,
    "out:Running cost/(Apt SEK y)": 15781,
    "out:Running Cost over RSP/MSEK": 5.923,
    "out:LCP/MSEK": -2.253,
    "out:ROI% old": 10.07,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 82.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 9,
    "out:Return/kSEK/y": 689,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 221360,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 241441,
    "out:DH kr savings": 221360,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 127341,
    "out:% savings (space heating)": 60.84,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.21,
    "out:Etvv": 20,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000200110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 43.06,
    "out:DHW (DH)": 34.64,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 100.8,
    "out:Total Energy Use Post PV": 94.71,
    "out:Primary Energy": 81.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 53.7,
    "out:CO2 Operational/m2": 16.03,
    "out:Total CO2/m2": 69.73,
    "out:Total CO2 (tons)": 192.95,
    "out:Klimatpaverkan": 25.93,
    "out:Initial Cost/MSEK": 7.701,
    "out:Running cost/(Apt SEK y)": 15259,
    "out:Running Cost over RSP/MSEK": 5.727,
    "out:LCP/MSEK": -2.126,
    "out:ROI% old": 10.34,
    "out:Payback discounted": 12,
    "out:Atemp": 2767,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 88.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 9,
    "out:Return/kSEK/y": 714,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.64,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 229661,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 249742,
    "out:DH kr savings": 229661,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 119139,
    "out:% savings (space heating)": 63.36,
    "out:Total_Water Heating (DH)": 95849,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 68.25,
    "out:Etvv": 20,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000200110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.02,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 97.8,
    "out:Total Energy Use Post PV": 91.71,
    "out:Primary Energy": 82.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 54.35,
    "out:CO2 Operational/m2": 14.95,
    "out:Total CO2/m2": 69.3,
    "out:Total CO2 (tons)": 191.76,
    "out:Klimatpaverkan": 24.74,
    "out:Initial Cost/MSEK": 7.851,
    "out:Running cost/(Apt SEK y)": 14737,
    "out:Running Cost over RSP/MSEK": 5.531,
    "out:LCP/MSEK": -2.08,
    "out:ROI% old": 10.5,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 94.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 9,
    "out:Return/kSEK/y": 739,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 237962,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 258043,
    "out:DH kr savings": 237962,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 127341,
    "out:% savings (space heating)": 60.84,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 71.21,
    "out:Etvv": 17,
    "out:Ef": 16.71
  },
  {
    "ID": "Z00000200110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 43.06,
    "out:DHW (DH)": 29.44,
    "out:Property Electricity": 22.8,
    "out:Electricity (inc PV)": 16.71,
    "out:Total Energy Use Pre PV": 95.8,
    "out:Total Energy Use Post PV": 89.71,
    "out:Primary Energy": 79.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 54.35,
    "out:CO2 Operational/m2": 14.23,
    "out:Total CO2/m2": 68.58,
    "out:Total CO2 (tons)": 189.77,
    "out:Klimatpaverkan": 22.75,
    "out:Initial Cost/MSEK": 7.92,
    "out:Running cost/(Apt SEK y)": 14389,
    "out:Running Cost over RSP/MSEK": 5.4,
    "out:LCP/MSEK": -2.018,
    "out:ROI% old": 10.65,
    "out:Payback discounted": 11,
    "out:Atemp": 2767,
    "out:Umean": 0.29,
    "out:Roof surface/m2": 553,
    "out:Facade surface/m2": 1221,
    "out:Window surface/m2": 241,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 1181.4,
    "out:Return %": 10,
    "out:Return/kSEK/y": 755,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 72128,
    "out:EAHP (pipework)": 71907,
    "out:EAHP (natural ventilation grills)": 228510,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.444,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.369505,
    "out:PV (m2 panels)": 138,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24741.72,
    "out:PV (kWh sold)": 7903.88,
    "out:PV (% sold (El))": 31.95,
    "out:PV (kWh self-consumed)": 16837.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1010000,
    "out:FTX (Diffusers)": 173672,
    "out:FTX (Dampers)": 52188,
    "out:FTX (S to F improvement cost)": 0,
    "out:FTX (Replacement Cost)": 153015,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 48,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.64,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 592800,
    "out:DH (pipework)": 660000,
    "out:Facade U-value (before)": 0.88,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.85,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 373777.35,
    "out:FTX (Facade)": 3340000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 243496,
    "out:EL kWh savings": 4112,
    "out:Sewage heat recovery cost": 218572.8,
    "out:Balancing hydronic system cost": 69174.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 263577,
    "out:DH kr savings": 243496,
    "out:El kr savings": 8225,
    "out:El kr sold": 11856,
    "out:El kr saved": 33676,
    "out:El kr return": 45532,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 50359,
    "out:Total_Space Heating (DH)": 119139,
    "out:% savings (space heating)": 63.36,
    "out:Total_Water Heating (DH)": 81472,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9.6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:New AHU Cost/SEK": 0,
    "out:Eupp": 68.25,
    "out:Etvv": 17,
    "out:Ef": 16.71
  }
]