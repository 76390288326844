import type { DatabaseOverrides, Datum } from "../types";
import { getFieldData } from "./getFieldData";

interface CalculateOverriddenDatumForFieldParameters {
  fields: string[];
  updatedValues: DatabaseOverrides;
  originalValues: Datum;
}

export const calculateOverriddenDatumForFields = ({
  fields,
  updatedValues,
  originalValues,
}: CalculateOverriddenDatumForFieldParameters) => {
  const overriddenDatum: Datum = {};

  for (const field of fields) {
    const [value, override] = getFieldData({
      updatedValues,
      originalValues,
      fieldName: field,
    });

    overriddenDatum[field] = override ?? value;
  }

  return overriddenDatum;
};
