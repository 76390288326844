import type { InstallationItem } from "packages/installations";

export const installationItemIncompatibilityMap = new Map<
  InstallationItem,
  Set<InstallationItem>
>([
  [
    "Ny aggregat",
    new Set(["Frånluftsvärmepump", "FTX", "FTX (fasadintegrerad)"]),
  ],
  [
    "FTX (fasadintegrerad)",
    new Set([
      "Takisolering",
      "Fasadisolering",
      "Frånluftsvärmepump",
      "FTX",
      "Ny aggregat",
    ]),
  ],
  [
    "FTX",
    new Set(["Frånluftsvärmepump", "FTX (fasadintegrerad)", "Ny aggregat"]),
  ],
  [
    "Frånluftsvärmepump",
    new Set(["Ny aggregat", "FTX", "FTX (fasadintegrerad)"]),
  ],
]);

const expandInstallationItemIncompatibilityMap = (
  map: Map<InstallationItem, Set<InstallationItem>>,
): void => {
  for (const [item, incompatibleItems] of map) {
    for (const incompatibleItem of incompatibleItems) {
      const existingCompatibilityMap =
        installationItemIncompatibilityMap.get(incompatibleItem);

      if (existingCompatibilityMap !== undefined) {
        existingCompatibilityMap.add(item);
      } else {
        installationItemIncompatibilityMap.set(
          incompatibleItem,
          new Set([item]),
        );
      }
    }
  }
};

expandInstallationItemIncompatibilityMap(installationItemIncompatibilityMap);

export const getAvailabilityForHasFilter = (item: InstallationItem) => {
  const availableForDefaultFilter = ![
    "FTX",
    "FTX (fasadintegrerad)",
    "EAHP",
    "Ny aggregat",
  ].includes(item);

  return availableForDefaultFilter;
};

export const getAvailabilityForDefaultFilter = (item: InstallationItem) => {
  return !item.startsWith("FTX") && getAvailabilityForHasFilter(item);
};
