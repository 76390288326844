import { useAtomValue, useSetAtom } from "jotai";
import { companyTargetEnergyClassAtom } from "./companyTargetEnergyClass";
import type { CompanyId } from "packages/companies";
import { useCallback, useMemo } from "react";

export const useCompanyTargetEnergyClass = (
  companyId: CompanyId | undefined,
) => {
  const companyTargetEnergyClass = useAtomValue(companyTargetEnergyClassAtom);

  const targetEnergyClass = useMemo(() => {
    return companyId ? companyTargetEnergyClass.get(companyId) : undefined;
  }, [companyId, companyTargetEnergyClass]);

  return targetEnergyClass;
};

export const useSetCompanyTargetEnergyClass = (
  companyId: CompanyId | undefined,
) => {
  const setCompanyTargetEnergyClass = useSetAtom(companyTargetEnergyClassAtom);
  const setTargetEnergyClass = useCallback(
    (targetEnergyClass: string | undefined) => {
      setCompanyTargetEnergyClass((prev) => {
        const next = new Map([...prev]);
        companyId && next.set(companyId, targetEnergyClass);
        return next;
      });
    },
    [companyId, setCompanyTargetEnergyClass],
  );

  return setTargetEnergyClass;
};
