import BoltIcon from "@mui/icons-material/BoltTwoTone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PaymentsIcon from "@mui/icons-material/PaymentsTwoTone";
import SquareFootIcon from "@mui/icons-material/SquareFootTwoTone";
import TextureIcon from "@mui/icons-material/TextureTwoTone";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { type InputMap, useFieldValue } from "packages/dataAccess";
import { Accordion } from "packages/ui";
import {
  type ChangeEvent,
  type ReactElement,
  type SyntheticEvent,
  useCallback,
  useState,
} from "react";
import type { InstallationDataset, InstallationItem } from "../installations";
import { formatIntegers, formatNumber } from "../utils";
import { CostBreakdown } from "./CostBreakdown";
import { EnergyBreakdown } from "./EnergyBreakdown";
import { IconRenderer } from "./IconRenderer";
import { InstallationConfig } from "./InstallationConfig";
import { InstallationOptions } from "./installation-options";
import { getAvailabilityForHasFilter } from "./installationItemIncompatibilityMap";

interface InstallationProps {
  installationName: InstallationItem;
  installationData: InstallationDataset[number][1] | undefined;
  selected: boolean;
  filterMap: InputMap;
  possibleFilters: Map<string, Set<number>>;
  onToggle: (value: boolean) => void;
  onChange: (installationFilter: Record<string, InputMap>) => void;
}

export const Installation = ({
  installationName,
  installationData,
  filterMap,
  possibleFilters,
  selected,
  onToggle,
  onChange,
}: InstallationProps): ReactElement => {
  const getFieldValue = useFieldValue();
  const isNoInstallment = installationName === "Nuläge";

  const handleInstallationConfigChange = useCallback(
    ([filterName, value]: [string, number]): void => {
      const updatedMap = new Map(filterMap);

      updatedMap.set(filterName, value);

      onChange({ [installationName]: updatedMap });
    },
    [filterMap, installationName, onChange],
  );

  const handleToggle = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onToggle(checked);
    },
    [onToggle],
  );

  const [value, setValue] = useState(isNoInstallment ? "2" : "3");

  const handleChange = (_event: SyntheticEvent, newValue: string): void => {
    setValue(newValue);
  };

  const hasFilters =
    filterMap.size !== 0 && getAvailabilityForHasFilter(installationName);

  return (
    <Accordion defaultExpanded={isNoInstallment}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{
          border: "none",
          display: "flex",
          alignItems: "center",
          flexDirection: "row-reverse",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography style={{ flex: 1 }}>{installationName}</Typography>
          {!isNoInstallment && installationData !== undefined && (
            <Chip
              label={`Payback ${formatIntegers(
                getFieldValue(installationData, "out:Payback Time"),
              )} år`}
            />
          )}
          {!isNoInstallment && (
            <AccordionActions disableSpacing={true}>
              <Switch
                checked={selected}
                onChange={handleToggle}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </AccordionActions>
          )}
        </div>
      </AccordionSummary>

      {installationData !== undefined && (
        <AccordionDetails>
          {isNoInstallment && (
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <IconRenderer icon={TextureIcon} />
                </ListItemAvatar>
                <ListItemText
                  primary="Atemp"
                  secondary={
                    <Typography variant="h4">
                      {formatNumber(
                        getFieldValue(installationData, "out:Atemp"),
                      )}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          )}
          {!isNoInstallment && (
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  centered={true}
                >
                  <Tab icon={<BoltIcon />} aria-label="energy" value="1" />
                  {!isNoInstallment && (
                    <Tab icon={<PaymentsIcon />} aria-label="cost" value="3" />
                  )}
                  {hasFilters && (
                    <Tab
                      icon={<SquareFootIcon />}
                      aria-label="settings"
                      value="4"
                    />
                  )}
                </TabList>
              </Box>
              <TabPanel value="1">
                <EnergyBreakdown
                  installationName={installationName}
                  installationData={installationData}
                />
              </TabPanel>
              {!isNoInstallment && (
                <TabPanel value="3">
                  <CostBreakdown
                    inputMap={filterMap}
                    installationName={installationName}
                    installationData={installationData}
                  />
                </TabPanel>
              )}
              {hasFilters && (
                <TabPanel value="4">
                  {Array.from(filterMap).map(([filterName, value]) => {
                    const optionSet = possibleFilters.get(filterName);

                    if (optionSet === undefined) {
                      throw new Error("optionSet must be defined");
                    }

                    const options = (
                      InstallationOptions[filterName] ?? []
                    ).filter(([_label, value]) => optionSet.has(value));

                    return (
                      <div key={filterName} style={{ marginTop: "20px" }}>
                        <InstallationConfig
                          key={filterName}
                          installationValue={value}
                          label={filterName}
                          options={options}
                          onChange={handleInstallationConfigChange}
                        />
                      </div>
                    );
                  })}
                </TabPanel>
              )}
            </TabContext>
          )}
        </AccordionDetails>
      )}
    </Accordion>
  );
};
